export const PrimaryPlayerData = [
    {
        id: 1,
        title: "General Details",
        value: "generaldetail",
    },
    {
        id: 2,
        title: "Bank Details",
        value: "bankdetails",
    },
    {
        id: 3,
        title: "Player Bet History",
        value: "betting"
    },
    {
        id: 4,
        title: "Payment History",
        value: "payment"
    },
    {
        id: 5,
        title: "Login History",
        value: "loginhistory"
    },
    {
        id: 6,
        title: "Account History",
        value: "accounthistory"
    },
    {
        id: 7,
        title: "Fraud Scrub",
        value: "fraudScrub"
    },
    {
        id: 8,
        title: "Bonuses",
        value: "bonuses"
    },
    {
        id: 9,
        title: "Bonus Transaction",
        value: "bonusTransaction"
    },
    {
        id: 10,
        title: "Tags",
        value: "tags"
    },
    {
        id: 11,
        title: "Notes",
        value: "notes"
    },
    {
        id: 12,
        title: "Responsible Gambling",
        value: "responsibleGambling"
    },
    {
        id: 13,
        title: "Player Limits",
        value: "playerLimits"
    },
    {
        id: 14,
        title: "User Stakes",
        value: "userStakes"
    },
]

export const GamblingLimits = [
    { id: 1, label: "Deposit Limits", value: "DepositLimits" },
    { id: 2, label: "Withdrawal Limits", value: "WithdrawalLimits" },
    { id: 3, label: "Betting Limits", value: "BettingLimits" },
    { id: 4, label: "Self-exclusion", value: "SelfExclusionPeriod" },
    { id: 5, label: "Time Limits", value: "TimeLimits" }
]
export const SelfExclusionPeriod = [
    { id: 1, label: "Daily", value: "Daily" },
    { id: 2, label: "Weekly", value: "Weekly" },
    { id: 3, label: "Monthly", value: "Monthly" },
]
export const timeLimits = [
    { id: 1, label: "15 Mins", value: "15Mins" },
    { id: 2, label: "30 Mins", value: "30Mins" },
    { id: 3, label: "1 Hr", value: "1Hr" },
    { id: 4, label: "2 Hrs", value: "2Hrs" },
    { id: 5, label: "4 Hrs ", value: "4Hrs" },
    { id: 6, label: "8 Hrs ", value: "8Hrs" }
]
export const ExclusionPeriod = [
    { id: 1, label: "Week", value: "WEEK" },
    { id: 2, label: "Month", value: "MONTH" },
    { id: 3, label: "Year", value: "YEAR" },
]

export const paymentLimitOpt = [
    { id: 1, label: "Deposit Limits", value: "DEPOSIT" },
    { id: 2, label: "Withdraw Limits", value: "WITHDRAW" },
    { id: 2, label: "Player Cashout ", value: "PLAYERCASHOUT" },
]
export const cashoutOpt = [
    { id: 1, label: "Enable", value: "ENABLE" },
    { id: 2, label: "Disable", value: "DISABLE" },
]
