import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { generateKey, getDateTimeFormate } from "../../sharedfiles/helper";
import { getBrandConfigKey } from "../Brand config/BrandConfigSlice";
import { useDispatch } from "react-redux";
import BrandsListDialog from "./BrandsListDialog";
import { BRAND_CONFIG_CONSTANTS, COMMON_CONSTANTS, KYC_CONSTANTS, REPORTS_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
const BrandKey = (props) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [brandId, setBrandId] = useState("");

  useEffect(() => {
    const dataExist = Object.keys(props.brandConfig).length > 0
    if (!dataExist) {
      dispatch(getBrandConfigKey());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listHandler = (id) => {
    setBrandId(id);
    setOpenDialog(true);
  };
  const headers = [
    "Id",
    "Brand Name",
    "Currency",
    "Client Id",
    "Created Date",
    "Updated Date",
    "Action"
  ]
  const info = [
    "Showing Id",
    "Provides the name of the brand",
    "Provides the specified currency in accordance with client requirement",
    "Provides the Client's unique ID",
    "Enables access to the date of brand creation",
    "Enables access to the date of brand updation",
    "Allows you to access the list of brand API keys "
  ]
  return (
    <>
      <BrandsListDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        brandId={brandId}
      />
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result'>
            <div className='CMS-box'>
              <div className=' CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      {headers.map((item, index) => (
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item}</p>
                            <Tooltips info={`${info[index]}`} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.brandConfig &&
                      Array.isArray(props.brandConfig) &&
                      Object.keys(props.brandConfig).length > 0 &&
                      props.brandConfig.length > 0 ? (
                      props.brandConfig.map((item, index) => {
                        return (
                          <tr key={generateKey(index)}>
                            <td>{item.id}</td>
                            <td>{item.brandName}</td>
                            <td>{item.brandCurrency}</td>
                            {/* <td>{item.brandApiKey}</td> */}
                            <td>{item.clientId}</td>
                            {/* <td>{item.secretKey}</td> */}
                            <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                            <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                            <td>
                              <button
                                className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                                id="brandKeylist"
                                onClick={() => listHandler(item.id)}
                              >
                                List
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={9} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // brandData: state.brandconfig.brandData,
    brandData: state.dashboard.brandData,
    brandConfig: state.brandconfig.brandConfig,
  };
};
export default connect(mapStateToProps)(BrandKey);
