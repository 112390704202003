import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
  dashboardApi,
  brandApi,
  userApi,
  operationsApi,
  bonusApi,
  setBaseUrl,
} from "../../sharedfiles/EndpointConfig";
import {
  setLoader,
  setHighLoader,
  setLooserLoader,
  setMostLoader,
  setWinnerLoader,
  setBetLoader,
  setCashloader,
  setRevnueLoader,
  setSportLoader,
  setStackLoader,
  setDefaultPage,
} from "../AppSlice";
import { getPlatformUserDetails, refreshTokenApi } from "../Login/loginSlice";
import { pathFinderByPermission, tabManager } from "../sidebar/sidebarSlice";
import { SidebarData, sidebarNavTabs } from "../SidebarData";
import { getPendingNotifications } from "../PendingWithrawals/pendingWithrawalSlice";
import { getAllUsers } from "../CreateUser/createUserSlice";
export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    userData: [],
    winnerData: [],
    looserData: [],
    profitSportData: [],
    revenuBrandData: [],
    revenuSportData: [],
    revenuStackFactor: [],
    brandData: [],
    turnover: [],
    stackRevnuGraph: [],
    highestTurnover: [],
    seasonDropdown: "THIS_MONTH",
    activeTab: "ALL",
    turnOverSuccessMsg: "",
    turnOverErrorMsg: "",
    userPermission: [],
    totalRevenue: 0,
    opTableData: [],
    bonusSystemValue: "",
    userPagePermissions: [],
    permissions: [],
    brandNameList: [],
    betBoardRange: "THIS_MONTH",
    revenueByStakeFactorRange: "THIS_MONTH",
    revenueBySportRange: "THIS_MONTH",
    betCountRange: "THIS_MONTH",
    stakeVsRevenueRange: "THIS_MONTH",
    cashRevenueBySportRange: "THIS_MONTH",
    profitSportsRange: "TODAY" || "YESTERDAY" || "THIS_MONTH",
    turnoverSportsRange: "TODAY" || "YESTERDAY" || "THIS_MONTH",
    topWinnersRange: "TODAY" || "YESTERDAY" || "THIS_MONTH",
    topLosersRange: "TODAY" || "YESTERDAY" || "THIS_MONTH",
    permissionToResetPassword: false,
    defaultHome: JSON.parse(localStorage.getItem("defaultHome")) || "",
    defaultMainPath: JSON.parse(localStorage.getItem("defaultMainPath")) || "",
    defaultMainPage: "",
    permissionToEditUserDetails: false,
    tdsPermission: false,
    platformTaxPermission: false,
    nftTradeFeePremission: false,
    kycPermission: false,
    withdrawReport: false,
    depositReport: false,
    placeBetReport: false,
    manualToggle: false,
    liabilityReport: false,
    createPermission: false,
    affArtWork: false,
    starbetLogoPermission: false,
    stakeTaxPermission: false,
    winTaxPermission: false,
    playerCashoutPermission: false,
    affiliate_GGR_NGR: false,
    affCommission: false,
    mvDepositManual: false,
    adminBonusAction: false,
    isAccountVisible: false,
    afiilatePay: false,
  },
  reducers: {
    setDashboardTurnoverSearch: (state, action) => {
      state.userData = action.payload;
    },
    setWinnerData: (state, action) => {
      state.winnerData = action.payload;
    },
    setLooserdata: (state, action) => {
      state.looserData = action.payload;
    },
    setProfitSportsdata: (state, action) => {
      state.profitSportData = action.payload;
    },
    setRevenuBrandData: (state, action) => {
      state.revenuBrandData = action.payload;
    },
    setrevenuStackFactor: (state, action) => {
      state.revenuStackFactor = action.payload;
    },
    setRevenuSportData: (state, action) => {
      state.revenuSportData = action.payload;
    },
    setBrandData: (state, action) => {
      state.brandData = action.payload;
    },
    setSeasonDropDown: (state, action) => {
      state.seasonDropdown = action.payload;
    },
    setTurnOverData: (state, action) => {
      state.turnover = action.payload;
    },
    setStackRevnuGraph: (state, action) => {
      state.stackRevnuGraph = action.payload;
    },
    setHighestTurnover: (state, action) => {
      state.highestTurnover = action.payload;
    },
    resetMainTableData: (state, action) => {
      state.userData = [];
    },
    resetWinnerData: (state, action) => {
      state.winnerData = [];
    },
    resetLooserData: (state, action) => {
      state.looserData = [];
    },
    resetHighestTurnOver: (state, action) => {
      state.highestTurnover = [];
    },
    resetMostProfit: (state, action) => {
      state.profitSportData = [];
    },
    resetBrandGraph: (state, action) => {
      state.revenuBrandData = [];
    },
    resetSportGraph: (state, action) => {
      state.revenuSportData = [];
    },
    resetStackFactorGraph: (state, action) => {
      state.revenuStackFactor = [];
    },
    resetBetCountGraph: (state, action) => {
      state.turnover = [];
    },
    resetStackRevnuGraph: (state, action) => {
      state.stackRevnuGraph = [];
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    turnOverSuccessMsg: (state, action) => {
      state.turnOverSuccessMsg = action.payload;
    },
    turnOverErrorMsg: (state, action) => {
      state.turnOverErrorMsg = action.payload;
    },
    setUserpermission: (state, action) => {
      state.userPermission = action.payload;
    },
    setTotalRevenue: (state, action) => {
      state.totalRevenue = action.payload;
    },
    setOpTableData: (state, action) => {
      state.opTableData = action.payload;
    },
    setBonusSystemValue: (state, action) => {
      state.bonusSystemValue = action.payload;
    },
    setUserPagePermissions: (state, action) => {
      state.userPagePermissions = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    resetUserPagePermission: (state, action) => {
      state.userPagePermissions = [];
    },
    setBrandNameList: (state, action) => {
      state.brandNameList = action.payload;
    },
    setBetBoardRange: (state, action) => {
      state.betBoardRange = action.payload;
    },
    setRevenueByStakeFactorRange: (state, action) => {
      state.revenueByStakeFactorRange = action.payload;
    },
    setRevenueBySportRange: (state, action) => {
      state.revenueBySportRange = action.payload;
    },
    setBetCountRange: (state, action) => {
      state.betCountRange = action.payload;
    },
    setStakeVsRevenueRange: (state, action) => {
      state.stakeVsRevenueRange = action.payload;
    },
    setCashRevenueBySportRange: (state, action) => {
      state.cashRevenueBySportRange = action.payload;
    },
    setProfitSportsRange: (state, action) => {
      state.profitSportsRange = action.payload;
    },
    setTurnoverSportsRange: (state, action) => {
      state.turnoverSportsRange = action.payload;
    },
    setTopWinnersRange: (state, action) => {
      state.topWinnersRange = action.payload;
    },
    setTopLosersRange: (state, action) => {
      state.topLosersRange = action.payload;
    },
    setPermissionToResetPassword: (state, action) => {
      state.permissionToResetPassword = action.payload;
    },
    setDefaultHome: (state, action) => {
      state.defaultHome = action.payload;
      localStorage.setItem("defaultHome", JSON.stringify(action.payload));
    },
    setDefaultMainPath: (state, action) => {
      state.defaultMainPath = action.payload;
      localStorage.setItem("defaultMainPath", JSON.stringify(action.payload));
    },
    setPermissionToEditUserDetails: (state, action) => {
      state.permissionToEditUserDetails = action.payload;
    },
    setTdsPermission: (state, action) => {
      state.tdsPermission = action.payload;
    },
    setPlatformTaxPermission: (state, action) => {
      state.platformTaxPermission = action.payload;
    },
    setKycPermission: (state, action) => {
      state.kycPermission = action.payload;
    },
    setWithdrawReport: (state, action) => {
      state.withdrawReport = action.payload;
    },
    setDepositReport: (state, action) => {
      state.depositReport = action.payload;
    },
    setPlaceBetReport: (state, action) => {
      state.placeBetReport = action.payload;
    },
    setManualToggle: (state, action) => {
      state.manualToggle = action.payload;
    },
    setLiabilityReport: (state, action) => {
      state.liabilityReport = action.payload;
    },
    setCreatePermission: (state, action) => {
      state.createPermission = action.payload;
    },
    setAffArtWork: (state, action) => {
      state.affArtWork = action.payload;
    },
    setStarbetLogoPermission: (state, action) => {
      state.starbetLogoPermission = action.payload;
    },
    setStakeTaxPermission: (state, action) => {
      state.stakeTaxPermission = action.payload;
    },
    setWinTaxPermission: (state, action) => {
      state.winTaxPermission = action.payload;
    },
    setPlayerCashoutPermission: (state, action) => {
      state.playerCashoutPermission = action.payload;
    },
    setAffiliate_GGR_NGR: (state, action) => {
      state.affiliate_GGR_NGR = action.payload;
    },
    setAffCommission: (state, action) => {
      state.affCommission = action.payload;
    },
    setMvDepositManual: (state, action) => {
      state.mvDepositManual = action.payload;
    },
    setAdminBonusAction: (state, action) => {
      state.adminBonusAction = action.payload;
    },
    setNftTradeFeePremission: (state, action) => {
      state.nftTradeFeePremission = action.payload;
    },
    setIsAccountVisible: (state, action) => {
      state.isAccountVisible = action.payload;
    },
    setAfiilatePay: (state, action) => {
      state.afiilatePay = action.payload;
    },

  },
});
export const {
  setStackRevnuGraph,
  resetLooserData,
  resetMainTableData,
  setDashboardTurnoverSearch,
  setWinnerData,
  setLooserdata,
  resetHighestTurnOver,
  resetMostProfit,
  resetBrandGraph,
  resetSportGraph,
  setProfitSportsdata,
  setRevenuBrandData,
  setRevenuSportData,
  setrevenuStackFactor,
  setBrandData,
  setSeasonDropDown,
  setTurnOverData,
  setHighestTurnover,
  resetWinnerData,
  resetStackFactorGraph,
  resetBetCountGraph,
  resetStackRevnuGraph,
  setActiveTab,
  turnOverSuccessMsg,
  turnOverErrorMsg,
  setUserpermission,
  setTotalRevenue,
  setOpTableData,
  setBonusSystemValue,
  setUserPagePermissions,
  setPermissions,
  resetUserPagePermission,
  setBrandNameList,
  setBetBoardRange,
  setRevenueByStakeFactorRange,
  setRevenueBySportRange,
  setBetCountRange,
  setStakeVsRevenueRange,
  setCashRevenueBySportRange,
  setProfitSportsRange,
  setTurnoverSportsRange,
  setTopWinnersRange,
  setTopLosersRange,
  setPermissionToResetPassword,
  setDefaultHome,
  setDefaultMainPath,
  setDefaultMainPage,
  setPermissionToEditUserDetails,
  setTdsPermission,
  setPlatformTaxPermission,
  setKycPermission,
  setWithdrawReport,
  setDepositReport,
  setPlaceBetReport,
  setManualToggle,
  setLiabilityReport,
  setCreatePermission,
  setAffArtWork,
  setStarbetLogoPermission,
  setStakeTaxPermission,
  setWinTaxPermission,
  setPlayerCashoutPermission,
  setAffiliate_GGR_NGR,
  setAffCommission,
  setMvDepositManual,
  setAdminBonusAction,
  setNftTradeFeePremission,
  setIsAccountVisible,
  setAfiilatePay,
} = DashboardSlice.actions;

export const getTurnovertableList =
  (duration, betType, sportType) =>
    (dispatch) => {
      // ALL, IN_PLAY , PRE_MATCH
      dispatch(resetMainTableData());
      dispatch(setLoader(true));
      let gameType = "";
      if (sportType !== "ALLBETS") {
        gameType = "&gameType=" + sportType
      }
      let url;
      const baseUrl = dashboardApi();
      if (gameType) {
        url = baseUrl?.getTurnoverMain + "?from=" + duration + "&betType=" + betType + gameType;
      } else {
        url = baseUrl?.getTurnoverMain + "?from=" + duration + "&betType=" + betType;
      }
      apiCaller(url, "GET", {})
        .then((response) => {
          if (response && response !== null && response) {
            dispatch(setLoader(false));
            dispatch(setDashboardTurnoverSearch(response.data));
            dispatch(turnOverSuccessMsg(response.message));
            // response.data === null && dispatch(setLoader(true));
          }
        })
        .catch((response) => {
          dispatch(setLoader(false));
          dispatch(setDashboardTurnoverSearch([]));
        });
      // .catch((error) => {
      //   console.log("errorResponse", error);
      //   dispatch(turnOverErrorMsg(error.message));
      // });
    };
export const getTurnover = (duration) => (dispatch) => {
  dispatch(resetBetCountGraph());
  dispatch(setBetLoader(true));
  var url = dashboardApi().getTurnover + "?day=" + duration;
  apiCaller(url, "GET", {}).then((response) => {
    if (response && response !== null && response) {
      dispatch(setBetLoader(false));
      dispatch(setTurnOverData(response.data));
    }
  }).catch(err => {
    dispatch(setBetLoader(false));
    dispatch(setTurnOverData([]));
  });
};

export const getStackRevenuGraph = (duration) => (dispatch) => {
  dispatch(resetStackRevnuGraph());
  dispatch(setStackLoader(true));
  var url = dashboardApi().getTurnover + "?day=" + duration;
  apiCaller(url, "GET", {}).then((response) => {
    if (response && response !== null && response) {
      dispatch(setStackLoader(false));
      dispatch(setStackRevnuGraph(response.data));
    }
  }).catch(err => {
    dispatch(setStackLoader(false));
    dispatch(setStackRevnuGraph([]));
  });
};

export const getHighestTurnover = (duration) => (dispatch) => {
  dispatch(resetHighestTurnOver());
  dispatch(setHighLoader(true));
  let url = dashboardApi().getHighestTurnover + "?from=" + duration;
  apiCaller(url, "GET", {}).then((response) => {
    if (response && response !== null && response) {
      dispatch(setHighLoader(false));
      dispatch(setHighestTurnover(response.data));
    }
  }).catch(err => {
    dispatch(setHighLoader(false));
    dispatch(setHighestTurnover([]));
  });
};

export const getWinners = (duration) => (dispatch) => {
  dispatch(resetWinnerData());
  dispatch(setWinnerLoader(true));
  let url = dashboardApi().getWinner + "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response !== null) {
      dispatch(setWinnerLoader(false));
      dispatch(setWinnerData(response.data));
    }
  }).catch(err => {
    dispatch(setWinnerLoader(false));
    dispatch(setWinnerData([]));
  });
};
export const getLoosers = (duration) => (dispatch) => {
  dispatch(resetLooserData());
  dispatch(setLooserLoader(true));
  let url = dashboardApi().getLoosers + "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response != null) {
      dispatch(setLooserLoader(false));
      dispatch(setLooserdata(response.data));
    }
  }).catch(err => {
    dispatch(setLooserLoader(false));
    dispatch(setLooserdata([]));
  });
};

export const getProfitSports = (duration) => (dispatch) => {
  dispatch(resetMostProfit());
  dispatch(setMostLoader(true));
  let url = dashboardApi().getProfitSports + "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response != null) {
      dispatch(setMostLoader(false));
      dispatch(setProfitSportsdata(response.data));
    }
  }).catch(err => {
    dispatch(setMostLoader(false));
    dispatch(setProfitSportsdata([]));
  });
};

export const getRevenuBrand = (duration) => (dispatch) => {
  dispatch(resetBrandGraph());
  dispatch(setSportLoader(true));
  // let url = dashboardApi().getRevenuSport+ "?from=" + duration;
  let url = dashboardApi().getRevenueBySport + "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response !== null) {
      dispatch(setSportLoader(false));
      const isAllZero = response?.data?.every((item) => item.revenue == 0);
      dispatch(setRevenuBrandData(isAllZero ? [] : response.data));
      dispatch(setTotalRevenue(isAllZero));
    }
  }).catch(err => {
    dispatch(setSportLoader(false));
    dispatch(setRevenuBrandData([]));
  });
};

export const getStackFactor = (duration) => (dispatch) => {
  dispatch(resetStackFactorGraph());
  dispatch(setRevnueLoader(true));
  let url = dashboardApi().getRevenuBrand + "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response != null) {
      dispatch(setRevnueLoader(false));
      dispatch(setrevenuStackFactor(response.data));
    }
  }).catch((err) => {
    dispatch(setRevnueLoader(false));
    dispatch(setrevenuStackFactor([]));
  });
};
//dev.platform-ups.sportsit-tech.net:443/dashboard/revenuesports?from=THIS_MONTH
export const getRevenuSport = (duration) => (dispatch) => {
  dispatch(resetSportGraph());
  dispatch(setCashloader(true));
  let url = dashboardApi().getRevenuSport + "?from=" + duration;
  // let url =dashboardApi().getRevenueBySport+ "?from=" + duration;
  apiCaller(url, "GET").then((response) => {
    if (response && response !== null) {
      dispatch(setCashloader(false));
      dispatch(setRevenuSportData(response.data));
      // response.data &&
    }
  }).catch(err => {
    dispatch(setCashloader(false));
    dispatch(setRevenuSportData([]));
  });
};

export const getBrand = (props, token) => (dispatch) => {
  let url = brandApi.getBrand;
  let executed = false;
  apiCaller(url, "GET").then((response) => {
    if (response && response.data !== null && response.data.length > 0) {
      executed = true
      localStorage.setItem("merchantId", response.data[0].merchantKey);
      localStorage["currency"] === undefined && localStorage.setItem("currency", response.data[0].currency);
      const brandId = response.data.filter(
        (brand) => brand.merchantKey === localStorage.getItem("merchantId")
      )[0].brandId;
      const initialId = localStorage.getItem("brandId");
      if (initialId === null || initialId === undefined || initialId === "") {
        localStorage.setItem("brandId", brandId);
      }
      dispatch(setBrandData(response.data));
      const brandNameList = response.data.map(brand => brand.name);
      dispatch(setBrandNameList(brandNameList));

      props && dispatch(fetchPagePermission(props, token && "dashboard"));
      dispatch(getPlatformUserDetails())
      dispatch(getPendingNotifications())
      const brandID = localStorage.getItem("brandId")
      dispatch(getAllUsers(brandID));
    }
    return executed;
  });
};
export const getUserPermission = () => (dispatch) => {
  let url = userApi.getPermission;
  apiCaller(url, "GET").then((response) => {
    if (response && response.data !== null) {
      dispatch(setUserpermission(response.data));
      const data = [];
      Object.keys(response).length > 0 &&
        response.data.map((item, ind) => {
          data.push({
            id: item.id,
            name: item.permissionName,
            value: item.permissionName,
          });
        });
      dispatch(setPermissions([...new Set(data.sort((a, b) => a.id - b.id))]));
    }
  });
};

export const fetchPagePermission = (props, mainPage) => (dispatch, getState) => {
  const { sidebar } = getState();
  dispatch(resetUserPagePermission());
  let url = userApi.getUserPermission;
  apiCaller(url, "GET")
    .then((response) => {
      if (response && response.data !== null) {
        dispatch(setUserPagePermissions(response.data));
        const permissionFinder = (key) => response.data.some(item => item.permissionName === key);
        const isExist = response.data.find(
          (item) => item.permissionName === "GAMEWORX_PERMISSION"
        );
        const isGameworxExist = permissionFinder("GAMEWORX_PERMISSION");
        const isDashboard = permissionFinder("REPORTING_DASHBOARD");

        const isOperations = permissionFinder("OPERATION_SUMMARY");

        const isMasking = response.data.filter(
          (item) => item.permissionName === "DATA_MASKING"
        )
        const Gambling = response.data.filter(
          (item) => item.permissionName === "ADMIN_RESPONSIBLE_GAMBLING"
        )
        const bankDetails = response.data.filter(
          (item) => item.permissionName === "ADMIN_BANK_DETAILS"
        )
        const TDSpermission = permissionFinder("PAYMENT_TDS")
        dispatch(setTdsPermission(TDSpermission))

        const platformTax = permissionFinder("PAYMENT_GST_PLATFORMTAX")
        dispatch(setPlatformTaxPermission(platformTax))

        const kycAccEnable = permissionFinder("ACCOUNT_KYC_ENABLE")
        dispatch(setKycPermission(kycAccEnable))

        const withdrwalReport = permissionFinder("REPORTS_WITHDRAWAL")
        dispatch(setWithdrawReport(withdrwalReport))

        const depositReport = permissionFinder("REPORTS_DEPOSIT")
        dispatch(setDepositReport(depositReport))

        const placeBetReport = permissionFinder("REPORTS_PLACEBET")
        dispatch(setPlaceBetReport(placeBetReport))

        const manualToggle = permissionFinder("SHOW_MANUAL_ADJUSTMENT_TOGGLE")
        dispatch(setManualToggle(manualToggle))

        const liabilityReport = permissionFinder("REPORTS_LIABILITY")
        dispatch(setLiabilityReport(liabilityReport))

        const createPermission = permissionFinder("ADMIN_PERMISSION")
        dispatch(setCreatePermission(createPermission))

        const affArtworkPermission = permissionFinder("ADMIN_AFFILIATE_ARTWORK")
        dispatch(setAffArtWork(affArtworkPermission))

        const starbetPermission = permissionFinder("ADMIN_STARBET_LOGO")
        dispatch(setStarbetLogoPermission(starbetPermission))

        const stakeTax = permissionFinder("PAYMENT_STAKE_TAX")
        dispatch(setStakeTaxPermission(stakeTax))

        const winningTax = permissionFinder("PAYMENT_WINNING_TAX")
        dispatch(setWinTaxPermission(winningTax))

        const playerCashout = permissionFinder("PLAYER_CASHOUT")
        dispatch(setPlayerCashoutPermission(playerCashout))

        const permissionToResetPassword = permissionFinder("ADMIN_RESET_PASSWORD");
        dispatch(setPermissionToResetPassword(permissionToResetPassword));

        const permissionToEditUserDetails = permissionFinder("ADMIN_EDIT_USER_DETAILS");
        dispatch(setPermissionToEditUserDetails(permissionToEditUserDetails));

        const affGgrNgr = permissionFinder("AFFILIATE_GGR_NGR");
        dispatch(setAffiliate_GGR_NGR(affGgrNgr))

        const affcommission = permissionFinder("AFFILIATE_COMMISSION");
        dispatch(setAffCommission(affcommission))

        const mvDepositManual = permissionFinder("MV_DEPOSIT_TO_WTH_AMT");
        dispatch(setMvDepositManual(mvDepositManual))

        const adminBonusAction = permissionFinder("ADMIN_BONUS_ACTION");
        dispatch(setAdminBonusAction(adminBonusAction))

        const nftTradePremission = permissionFinder("NFT_TRADE_FEE");
        dispatch(setNftTradeFeePremission(nftTradePremission))

        const accountVisible = permissionFinder("SHOW_BANK_ACCOUNT_NUMBER");
        dispatch(setIsAccountVisible(accountVisible))

        const affilatePay = permissionFinder("ADMIN_AFFILIATE_PAY");
        dispatch(setAfiilatePay(affilatePay))

        let isPlayer = "";
        if (isMasking.length > 0) {
          isPlayer = isMasking[0].permissionName;
        } else {
          isPlayer = "notPlayer";
        }
        localStorage.setItem("isMasking", isPlayer);

        let isGameworx = "";
        if (isExist && Object.keys(isExist).length > 0) {
          isGameworx = isExist.permissionName;
        } else {
          isGameworx = "notExist";
        }
        localStorage.setItem("isExist", isGameworx);
        setBaseUrl();

        let isAdmin = "";
        if (Gambling.length && Object.keys(Gambling).length > 0 > 0) {
          isAdmin = Gambling[0].permissionName;
        } else {
          isAdmin = "notAdmin";
        }
        localStorage.setItem("Gambling", isAdmin);

        let isbankDetails = "";
        if (bankDetails.length && Object.keys(bankDetails).length > 0) {
          isbankDetails = bankDetails[0].permissionName;
        } else {
          isbankDetails = "noBankDeatils";
        }
        localStorage.setItem("bankDetails", isbankDetails);

        function pathAlternator() {
          let tabDetails = {};
          const perNames = response.data.map(per => per.permissionName);
          for (let tab of SidebarData) {
            const isExist = tab.subNav.find(key => perNames.includes(key.permissionName));
            if (isExist) {
              tabDetails = isExist;
              break;
            }
          }

          dispatch(setDefaultHome(tabDetails.subtitle));
          dispatch(setDefaultMainPath(tabDetails.path));
          props.history.push(tabDetails.path);
          dispatch(tabManager(tabDetails.path, sidebar.tabStack));
        }

        if (sidebar.tabStack.length === 0) {
          if (props && isDashboard) {
            mainPage && props.history.push("/dashboard");
            dispatch(setDefaultHome("DASHBOARD"));
            dispatch(setDefaultMainPath("/dashboard"));
            dispatch(tabManager("DASHBOARD", sidebar.tabStack));
          }
          else if (props && isOperations) {
            dispatch(setDefaultHome("OPERATION SUMMARY"));
            props.history.push("/operationSummary");
            dispatch(setDefaultPage("Operations Summary"));
            dispatch(setDefaultMainPath("/operationSummary"));
            dispatch(tabManager("OPERATION SUMMARY", sidebar.tabStack));
          }
          else if (isGameworxExist && !isDashboard) {
            const betHistoryExist = permissionFinder("BETTING_HISTORY");
            if (betHistoryExist) {
              dispatch(setDefaultHome("SPORT BET HISTORY"));
              dispatch(setDefaultMainPath("/betHistory"))
              props.history.push("/betHistory");
              dispatch(tabManager("/betHistory", sidebar.tabStack));
            } else {
              pathAlternator();
            }
          }
          else {
            pathAlternator();
          }
        }
      }
      if (response.data === null || response.data === undefined || response.data.length === 0) {
        props.history.push('/selflogout');
      }
    }).catch((err) => {
      console.log("err-monting-operations API: ", err);
      // dispatch(setDefaultMainPath("/login"));
      err.response && props.history.push('/selflogout');
    })
};

export const getOpTableData = (durationFrom) => (dispatch) => {
  const url = operationsApi.opTable(durationFrom);
  if (durationFrom !== "") {
    apiCaller(url, "GET", {})
      .then((res) => {
        if (res) {
          dispatch(setOpTableData(res.data));

          ["400", "500"].includes(res.status) && dispatch(setOpTableData({}));
          if (res.status == 200 || res.status == 400)
            dispatch(setLoader(false));
        } else {
          // console.log("response is undefined");
          dispatch(setOpTableData({}));
        }
      })
      .catch((err) => console.log(err));
  }
};

export const getOptBonus = (durationFrom) => (dispatch) => {
  const url = bonusApi.getSystemBonus(durationFrom);
  apiCaller(url, "GET", {})
    .then((res) => dispatch(setBonusSystemValue(res.data)))
    .catch((err) => console.log(err));
};

export default DashboardSlice.reducer;
