import React from 'react'
import { useSelector } from 'react-redux'
import BiTable from '../Reports/BiReports/BiTable'

const VipReports = () => {
    const vipBiReportData = useSelector((p) => p.bireportSlice.vipBiReportData)

    return (
        <>
            {
                vipBiReportData?.map((report, index) => (
                    <BiTable
                        key={index}
                        category={report.ReportCatagory}
                        reportName={report.Reports}
                        vip
                    />
                ))
            }
        </>
    )
}

export default VipReports