import React from 'react'
import Dialog__Main from './Dialog/Dialog__Main'
import Input from './common_inputs/Input'
import { propTypes } from 'react-bootstrap/esm/Image'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'

const CommentBox = (props) => {
    const { placeholder, value, errorMsg, onIpChange = () => { }, onSave = () => { }, onCancel = () => { }, ...rest } = props
    return (
        <>
            <div className='CMS-box CMS-box-content'>
                <Input
                    placeholder={placeholder}
                    value={value}
                    setValue={(e) => onIpChange(e)}
                    errorMsg={errorMsg}
                />
            </div>
            <div className='CMS-btnContainer'>
                <button
                    className='CMS-btnStatus CMS-statusActive CMS-btnSmall pointer'
                    type='button'
                    id='editplayersave'
                    onClick={(e) => onSave(e)}
                >
                    {COMMON_CONSTANTS.save}
                </button>
                <button
                    className='CMS-btn CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer'
                    type='button'
                    id='editplayercancel'
                    onClick={() => onCancel()}
                >
                    {COMMON_CONSTANTS.cancel}
                </button>
            </div>
        </>
    )
}

export default CommentBox