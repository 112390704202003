import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  formatMoney,
  Currency,
  get2DigitAfterDecimal,
  roundDecimalnum,
} from "../../sharedfiles/helper";
import { getTurnovertableList, setBetBoardRange } from "./dashboardSlice";
import Loading from "../loading/Loading";
import { useDispatch } from "react-redux";
import { DASBOARD_CONSTANTS, REPORTS_CONSTANTS } from "../../sharedfiles/Constants"
import No__Data__Found from "../Common/table/No__Data__Found";
import { Dashboard } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";

const BetsBoard = (props) => {
  const [view, setView] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {
    // let mounter = true;
    const isNoData = props.userData && Array.isArray(props.userData) && props.userData.length === 0;
    const timer = setTimeout(() => {
      if (isNoData) {
        dispatch(getTurnovertableList(props.betBoardRange, props.activeTab, "ALLBETS"));
      } else if (isNoData && props.activeTab === "CASINO") {
        dispatch(getTurnovertableList(props.betBoardRange, "ALL", "CASINO"));
      } else if (isNoData && props.activeTab === "NE_GAMES") {
        dispatch(getTurnovertableList(props.betBoardRange, "ALL", "NE_GAMES"));
      }
      if (localStorage.getItem("isExist") === "GAMEWORX_PERMISSION") {
        setView(false)
      }
    }, 1000)

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (props.activeTab === "CASINO") {
      props.vidator && dispatch(getTurnovertableList(props.betBoardRange, "ALL", "CASINO"));
    } else if (props.activeTab === "ALL" || props.activeTab === "SETTLED") {
      props.vidator && dispatch(getTurnovertableList(props.betBoardRange, props.activeTab, "ALLBETS"));
    } else if (props.activeTab === "NE_GAMES") {
      props.vidator && dispatch(getTurnovertableList(props.betBoardRange, "ALL", "NE_GAMES"));
    }
    else {
      props.vidator && dispatch(getTurnovertableList(props.betBoardRange, props.activeTab, "SPORTS"));
    }

    if (localStorage.getItem("isExist") === "GAMEWORX_PERMISSION") {
      setView(false)
    }
  }, [props.vidator]);

  const overAllValues = () => {
    let overallTurnOver = 0;
    let overallBetCount = 0;
    let overallMargin = 0;
    let overallGGR = 0;
    let overallFreeBetCost = 0;
    let overallNGR = 0;
    let overallReg = 0;
    let overallAmountDeposited = 0;
    let overallWithdrawals = 0;
    let overallStake = 0;
    let overallPayout = 0;
    let overallFreebet = 0;
    props.userData &&
      Array.isArray(props.userData) &&
      props.userData?.length > 0 &&
      props.userData.map((item, index) => {
        overallTurnOver += item.turnOver;
        overallBetCount += item.betCount;
        overallMargin += item.margin;
        overallGGR += item.ggr;
        overallFreeBetCost += item.freeBet;
        overallNGR += item.ngr;
        overallReg += item.totalRegistrations;
        overallAmountDeposited += item.totalAmountDeposited;
        overallWithdrawals += item.totalWithdrawals;
        overallStake += item.totalStake;
        overallPayout += item.totalPayout;
        overallFreebet += item.freeBet;
      });
    return [
      overallTurnOver,
      overallBetCount,
      overallMargin,
      overallGGR,
      overallFreeBetCost,
      overallNGR,
      overallReg,
      overallAmountDeposited,
      overallWithdrawals,
      overallStake,
      overallPayout,
      overallFreebet,
    ];
  };
  const seasonChanged = (e) => {
    dispatch(setBetBoardRange(e.target.value));
    if (props.activeTab === "CASINO") {
      props.vidator && dispatch(getTurnovertableList(e.target.value, "ALL", "CASINO"));
    } else if (props.activeTab === "ALL" || props.activeTab === "SETTLED") {
      props.vidator && dispatch(getTurnovertableList(e.target.value, props.activeTab, "ALLBETS"));
    } else if (props.activeTab === "NE_GAMES") {
      props.vidator && dispatch(getTurnovertableList(e.target.value, "ALL", "NE_GAMES"));
    }
    else {
      props.vidator && dispatch(getTurnovertableList(e.target.value, props.activeTab, "SPORTS"));
    }
  };

  return (
    <>
      <div>
        <div className='CMS-tab-panel active' id='CMS-allBets'>
          <div className='CMS-tabContent'>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className='CMS-select '>
                          <select onChange={(e) => seasonChanged(e)} id='betsboardSeasons' value={props.betBoardRange} className="pointer">
                            <option
                              value='THIS_MONTH'
                            >
                              {DASBOARD_CONSTANTS.ddlOpt_ThisMonth}
                            </option>
                            <option value='LAST_MONTH'>{DASBOARD_CONSTANTS.ddlOpt_PrevMonth}</option>
                            <option value='THIS_WEEK'>{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                            <option value='TODAY'>{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                            <option value='YESTERDAY'>{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                          </select>
                        </div>
                      </th>

                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_turnover}</p>
                          <Tooltips info={Dashboard.Turnover} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{REPORTS_CONSTANTS.header_betCount}</p>
                          <Tooltips info={Dashboard.Bet_Count} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_margin}</p>
                          <Tooltips info={Dashboard.Margin} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_ggr}</p>
                          <Tooltips info={Dashboard.GGR} />
                        </div>
                      </th>
                      {view &&
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_freebetCost}</p>
                            <Tooltips info={Dashboard.Free_Bet_Cost} />
                          </div>
                        </th>
                      }
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_ngr}</p>
                          <Tooltips info={Dashboard.NGR} />
                        </div>
                      </th>

                      {view &&
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_totalReg}</p>
                            <Tooltips info={Dashboard.Total_Registrations} />
                          </div>
                        </th>
                      }
                      {view &&
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_totalDepo}</p>
                            <Tooltips info={Dashboard.Total_Amount_Deposited} />
                          </div>
                        </th>
                      }
                      {view &&
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_totalWithdraw}</p>
                            <Tooltips info={Dashboard.Total_Withdrawals} />
                          </div>
                        </th>
                      }
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_totalStake}</p>
                          <Tooltips info={Dashboard.Total_Stake} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_totalPayout}</p>
                          <Tooltips info={Dashboard.Total_Payout} />
                        </div>
                      </th>
                      {!view ?
                        <th >
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{DASBOARD_CONSTANTS.betsboard_freebet}</p>
                            <Tooltips info={Dashboard.Free_Bet_Cost} />
                          </div>
                        </th>
                        : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {props?.userData &&
                      Array.isArray(props.userData) &&
                      props.userData.length > 0 ? (
                      props.userData.map((item, index) => {
                        return (
                          <tr key={index}>

                            <td style={{ textAlign: "center" }}>
                              {item.brand === "starpunter"
                                ? "StarPunter"
                                : item.brand}
                            </td>
                            <td className='text-right'>
                              {!view ? Currency() + roundDecimalnum(item.turnOver === "NaN" ? 0 : item.turnOver)
                                : Currency() + formatMoney(item.turnOver === "NaN" ? 0 : item.turnOver)}
                            </td>
                            <td>{item.betCount ? item.betCount : "-"}</td>
                            <td>{get2DigitAfterDecimal(item.margin)}%</td>
                            <td>{!view ? Currency() + roundDecimalnum(item.ggr) : Currency() + formatMoney(item.ggr)}</td>
                            {view && <td className='text-right'>
                              {Currency() + formatMoney(item.freeBet)}
                            </td>}
                            <td>{!view ? Currency() + roundDecimalnum(item.ngr) : Currency() + formatMoney(item.ngr)}</td>
                            {view && <td>{item.totalRegistrations}</td>}
                            {view && <td>
                              {Currency() +
                                formatMoney(item.totalAmountDeposited)}
                            </td>}
                            {view && <td>
                              {Currency() + formatMoney(item.totalWithdrawals)}
                            </td>}
                            <td>{!view ? Currency() + roundDecimalnum(item.totalStake) : Currency() + formatMoney(item.totalStake)}</td>
                            <td>
                              {!view ? Currency() + roundDecimalnum(item.totalPayout) : Currency() + formatMoney(item.totalPayout)}
                            </td>
                            {!view ? <td>{Currency() + roundDecimalnum(item.freeBet)}</td> : ""}
                          </tr>
                        );
                      })
                    ) : props.loader ? (
                      <tr>
                        <td colSpan='13' className='text-center'>
                          {props.loader && (
                            <Loading
                              isLoader={props.loader}
                              isNoData={
                                !props.loader && props.userData?.length === 0
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ) : (
                      <No__Data__Found colspan={13} />
                    )}
                  </tbody>
                  <thead>
                    {props?.userData &&
                      Array.isArray(props.userData) &&
                      props.userData?.length > 0 && (
                        <tr>
                          <th style={{ textAlign: "center" }}>{DASBOARD_CONSTANTS.betsboard_overall}</th>
                          <th className='text-right'>
                            {!view ? Currency() + roundDecimalnum(
                              overAllValues()[0] === "NaN" ? 0 : overAllValues()[0]
                            ) : Currency() +
                            formatMoney(
                              overAllValues()[0] === "NaN" ? 0 : overAllValues()[0]
                            )}
                          </th>
                          <th>{overAllValues()[1] ? overAllValues()[1] : "-"}</th>
                          <th>{get2DigitAfterDecimal(overAllValues()[2])}%</th>
                          <th>{!view ? Currency() + roundDecimalnum(overAllValues()[3]) : Currency() + formatMoney(overAllValues()[3])}</th>
                          {view && <th className='text-right'>
                            {Currency() + formatMoney(overAllValues()[4])}
                          </th>}
                          <th>{!view ? Currency() + roundDecimalnum(overAllValues()[5]) : Currency() + formatMoney(overAllValues()[5])}</th>
                          {view && <th>{overAllValues()[6]}</th>}
                          {view && <th>{Currency() + formatMoney(overAllValues()[7])}</th>}
                          {view && <th>{Currency() + formatMoney(overAllValues()[8])}</th>}
                          <th>{!view ? Currency() + roundDecimalnum(overAllValues()[9]) : Currency() + formatMoney(overAllValues()[9])}</th>
                          <th>{!view ? Currency() + roundDecimalnum(overAllValues()[10]) : Currency() + formatMoney(overAllValues()[10])}</th>
                          {!view ? <th>{Currency() + roundDecimalnum(overAllValues()[11])}</th> : ""}
                        </tr>
                      )}
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    userData: state.dashboard.userData,
    seasonDropdown: state.dashboard.seasonDropdown,
    loader: state.appslice.loader,
    activeTab: state.dashboard.activeTab,
    turnOverSuccessMsg: state.dashboard.turnOverSuccessMsg,
    turnOverErrorMsg: state.dashboard.turnOverErrorMsg,
    betBoardRange: state.dashboard.betBoardRange,
  };
}
export default connect(mapStateToProps)(BetsBoard);
