import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { tagApi } from "../../sharedfiles/EndpointConfig";
import { fileCallerApi } from "../../api/apiCallerNew";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

const AssignTagSlice = createSlice({
  name: "assignTagSlice",
  initialState: {
    tableData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    tagPostData: [],
    recordsShow: [1, 25],
    isVisible: false,
  },

  reducers: {
    getTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setTagPostFata: (state, action) => {
      state.tagPostData = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setIsVisibile: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const {
  getTableData,
  setTagPostFata,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisibile,
} = AssignTagSlice.actions;

export const fetchTagsData = (firstValue, secondValue, id) => (dispatch) => {
  if (id !== undefined) {
    const url =
      `${tagApi.postTag}` +
      `${id}` +
      "/tag?pageNumber=" +
      firstValue +
      "&ItemsPerPage=" +
      secondValue;

    apiCaller(url, "GET", {})
      .then((res) => {
        dispatch(getTableData(res.data));
        dispatch(setIsVisibile(true));
      })
      .catch((err) => console.log(err));
  }
};

export const uploadAssignTags = (formData) => (dispatch) => {
  const url = tagApi.uplaodAssignTags
  fileCallerApi(url, formData)
    .then((res) => {
      console.log("response.message: ", res.msg);
      if (res.status === 200) {
        console.log(res.data)
      }
      toaster.success(messageEnhancer(res.msg))
    })
    .catch((err) => {
      toaster.error(messageEnhancer(err.msg))
      console.log(err)
    });

}
//dev.platform-ups.sportsit-tech.net:443/platformtag?pageNumber=0&itemsPerPage=25

export default AssignTagSlice.reducer;
