import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { BiReportsDev } from "./biDataDev"
import { useSelector } from 'react-redux'

const AllReport = () => {
    //const biReportData = useSelector((p) => p.bireportSlice.biReportData)
    const { categoryName, reportName } = useParams();

    const matchingCategory = BiReportsDev.filter(
        (cat) => cat.ReportCatagory.toLowerCase().replaceAll(" ", "-") === categoryName
    )

    const matchingReport = matchingCategory[0]?.Reports?.filter(
        (report) => report.name.toLowerCase().replaceAll(" ", "-") === reportName
    )

    let loadUrl = ""
    if (matchingReport[0].url) {
        loadUrl = matchingReport[0].url;
    } else {
        console.log("not found");
    }

    return (
        <div>
            <iframe title="BackOffice-DailySummary" width={"100%"} height={"800px"}
                src={loadUrl}
                frameborder="0" allowFullScreen="true"></iframe>
        </div>
    )
}

export default AllReport