import React, { useState, useEffect } from "react";
import { MaxipDialog } from "./MaxipDialog";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getLoggedIps, getFraudUsersByIp } from "./fraudSlice";
import IpsDialog from "./IpsDialog";
import { FRAUDSCRUB_CONSTANTS } from "../../sharedfiles/Constants";

const FraudScrub = (props) => {
  const [openMaxipDialog, setOpenMaxipDialog] = useState(false);
  const [ip, setIp] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [openIpDialog, setOpenIpDialog] = useState(false);
  const handlePopUp = (ip) => {
    if (props?.loggedIps?.length > 0) {
      setIp(ip);
      dispatch(getFraudUsersByIp(ip.ip));
      setOpenMaxipDialog(true);
      setOpenIpDialog(false);
    }
  };
  useEffect(() => {
    let mounting = true;

    mounting && dispatch(getLoggedIps(props.userId));

    return () => mounting = false;
  }, []);

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    if (props.userId !== id) {
      dispatch(getLoggedIps(id));
    }
  }, [location]);

  const commonStyles = {
    fontSize: "18px",
    fontWeight: "500",
    padding: "5px 10px",
  };
  const fraudStyle = {
    backgroundColor: "#FFAC48",
    ...commonStyles,
    hover: {
      backgroundColor: "#0091ff",
    },
  };
  const nonFraudStyle = {
    // #192239
    backgroundColor: "transparent",
    PointerEvents: "none",
    ...commonStyles,
  };
  function handleRevertBack() {
    setOpenMaxipDialog(false);
    setOpenIpDialog(true);
  }
  const noFrodIpMsg = {
    fontWeight: 500,
    fontSize: "18px",

  }
  return (
    <>
      <MaxipDialog
        openMaxipDialog={openMaxipDialog}
        setOpenMaxipDialog={setOpenMaxipDialog}
        fraudUsers={props.fraudUsers}
        ip={ip}
        handleRevertBack={handleRevertBack}
      />
      <IpsDialog
        ipList={props.loggedIps}
        openMaxipDialog={openMaxipDialog}
        setOpenMaxipDialog={setOpenMaxipDialog}
        openIpDialog={openIpDialog}
        setOpenIpDialog={setOpenIpDialog}
        handlePopUp={handlePopUp}
      />
      <div className='CMS-tab-panel active' id='CMS-generalDetails'>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='row no-gutters'>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='userLabel'>
                      <a
                        className={
                          props.isHightlight ? "pointer hyperLink" : ""
                        }
                        style={props.isHightlight ? fraudStyle : nonFraudStyle}
                        onClick={() => setOpenIpDialog(props.isHightlight)}
                      >
                        {FRAUDSCRUB_CONSTANTS.maxUserIp}
                      </a>
                    </div>
                  </div>
                  {!props.isHightlight ? (
                    <div className='col-6'>
                      <span style={noFrodIpMsg}>: {props.errorMsg}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIps: state.fraudscrub.loggedIps,
    fraudUsers: state.fraudscrub.fraudUsers,
    errorMsg: state.fraudscrub.errorMsg,
    userId: state.playersearch.userId,
    isHightlight: state.fraudscrub.isHightlight,
  };
};

export default connect(mapStateToProps)(FraudScrub);
