import React, { useEffect, useState } from 'react'
import Table from '../Common/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getBonusTicker, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, setRecordsShow } from './bonusTickerSlice'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Vpagination from '../../sharedfiles/Vpagination'
import { getDateTimeFormate } from '../../sharedfiles/helper'

const BonusTicker = (props) => {
    const [from, setFrom] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [pdfBody, setPdfBody] = useState([]);
    const dispatch = useDispatch()
    const tickerData = useSelector((state) => state.BetTickerSlice.bonusTickerData)
    const pageFirstVal = useSelector(
        (state) => state.BetTickerSlice.paginationFirstValue
    );
    const pageSecondVal = useSelector(
        (state) => state.BetTickerSlice.paginationSecondValue
    );
    const recordsToShow = useSelector(
        (state) => state.BetTickerSlice.recordsToShow
    );
    const isVisible = useSelector(
        (state) => state.BetTickerSlice.isVisible
    );


    const callApi = () => {
        dispatch(
            getBonusTicker(
                pageFirstVal - 1,
                pageSecondVal,
                from,
                tickerData
            )
        );
    };
    const currentdate = tickerData && tickerData?.data && tickerData?.data[0].currentTime
    useEffect(() => {
        if (tickerData !== undefined) {
            setFrom(currentdate);
        }
    }, [tickerData]);

    useEffect(() => {
        callApi();
    }, []);

    const headers = [
        [
            "Date & Time",
            "Customer ID",
            "Bonus Type",
            "Bonus Code",
            "Bonus Awarded "
        ]
    ]
    const headerInfo = [
        "Showing Date & Time",
        "Showing Customer ID",
        "Showing Bonus Type",
        "Showing Bonus Code",
        "Showing Bonus Awarded "
    ]
    const exportPDF = () => {
        const title = "Bonus Ticker Report";
        const styles = {
            4: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, headers, pdfBody, styles);
    };
    useEffect(() => {
        if (tickerData !== undefined && tickerData?.data !== undefined) {
            const data = tickerData?.data !== undefined &&
                tickerData?.data[0].bonusTicker.map((p) => [
                    getDateTimeFormate(p.addedDate),
                    p.userId,
                    p.bonusType,
                    p.bonusCode ? p.bonusCode : "NA",
                    p.totalBonus
                ])
            setPdfBody(data);
            setCsvData([headers[0], headerInfo, ...data]);
        }

    }, [tickerData])
    const callPageApi = (pageNumber, itemsPerPage) => {
        props.dispatch(setIsVisible(false));

        dispatch(
            getBonusTicker(
                pageNumber - 1,
                itemsPerPage,
                from,
                tickerData
            )
        );
    };
    return (
        <React.Fragment>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <div className='CMS-tabContent'>
                        <div className='CMS-filter-result' id='result'>
                            <div className='CMS-pagination '>
                                <div className='CMS-pagination-container d-flex justify-content-end'>
                                    <div className='refresh-page' style={{ cursor: "pointer" }}>
                                        <span
                                            // style={reloadStyle}
                                            onClick={() => callApi()}
                                            className='material-icons md-36'
                                            data-icon='replay_circle_filled'
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div className='CMS-filter-result' id='result'>
                                <div className='CMS-pagination'>
                                    <Vpagination
                                        data={tickerData?.data}
                                        totalRecords={tickerData && tickerData?.totalRecords}
                                        paginationFirstValue={pageFirstVal}
                                        paginationSecondValue={pageSecondVal}
                                        setPaginationFirstValue={setPaginationFirstValue}
                                        setPaginationSecondValue={setPaginationSecondValue}
                                        recordsShow={recordsToShow}
                                        setRecordsShow={setRecordsShow}
                                        isVisible={isVisible}
                                        setIsVisible={setIsVisible}
                                        csvData={csvData}
                                        exportPDF={exportPDF}
                                        callApi={callPageApi}
                                        csvFileName={"Bonus Ticker Report"}
                                    />
                                </div>
                            </div>
                            <Table
                                tableData={csvData}
                                linkIndex={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BonusTicker