import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { updatePW } from "./pendingWithrawalSlice";
import { formatMoney } from "../../sharedfiles/helper";
import { getUserPermission } from "../Dashboard/dashboardSlice";
const ActionDialog = (props) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [verifiedState, setVerifiedState] = useState(false);
  const [isPresent, setIsPresent] = useState(true);
  const [isHoldThere, setIsHoldThere] = useState(false);
  const handleComment = (e) => {
    e.target.value.length > 0 ? setMessageError(false) : setMessageError(true);
    setMessage(e.target.value);
  };

  const handlePostData = (e) => {
    message == "" ? setMessageError(true) : setMessageError(false);
    const payload = {
      comments: message,
      tnxId: props.recordData.id,
      // message,
      status: e.target.name,
      userId: props.recordData.userId,
    };
    if (message !== "") {
      dispatch(updatePW(payload));
      props.setOpenDialog(false);
      setMessage("");
      setMessageError(false);
    }
    setTimeout(() => {
      props.callApi();
    }, 500);
  };

  const clearState = () => {
    setMessage("");
    setMessageError(false);
    props.setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(getUserPermission());
  }, []);
  useEffect(() => {
    if (props.userPagePermissions.length > 0) {
      const isPresent = props.userPagePermissions.find(
        (item) => item.permissionName == "PAYMENT_VERIFY_ONLY"
      );
      if (!isPresent && props.status == "VERIFIED") setIsPresent(false);
      if (isPresent && props.status == "VERIFIED") setVerifiedState(true);
      if (isPresent && props.status == "HOLD") setIsHoldThere(true);
      else setIsHoldThere(false);
      if (props.status != "VERIFIED") setVerifiedState(false);
    }
  }, [props.openDialog]);

  const divStyle = {
    justifyContent: "center",
    display: "grid",
  };
  return (
    <>
      <Dialog open={props.openDialog}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
          }}
        >
          <DialogTitle>Action Needed</DialogTitle>
          <IconButton
            style={{ color: "white" }}
            key='close'
            arial-label='close'
            onClick={clearState}
          >
            x
          </IconButton>
        </div>
        <DialogContent>
          <div className='CMS-msgBox'>
            <div className='CMS-msgBox-container'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>TXN ID</th>
                      <th>Amount</th>
                      <th>Comments</th>
                      {isPresent && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props.recordData.id}</td>
                      <td>{formatMoney(props.recordData.amount)}</td>
                      <td>
                        <input
                          type='text'
                          name='notes'
                          id='AuditNotes'
                          value={message}
                          placeholder='Mention your comments here'
                          onChange={handleComment}
                        />
                        {messageError ? (
                          <p style={{ color: "red" }}>
                            Please mention your comments
                          </p>
                        ) : null}
                      </td>

                      {isPresent && (
                        <td
                          style={
                            verifiedState === "DECLINED"
                              ? {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }
                              : {}
                          }
                        >
                          <div style={divStyle}>
                            {props.status === "VERIFIED" ? (
                              <>
                                <button
                                  name={verifiedState ? "APPROVED" : "VERIFIED"}
                                  onClick={handlePostData}
                                  className='CMS-btnStatus CMS-statusActive'
                                >
                                  {verifiedState ? "APPROVE" : "VERIFY"}
                                </button>
                                <button
                                  name='DECLINED'
                                  onClick={handlePostData}
                                  className='CMS-btnStatus CMS-statusClosed mt-10'
                                >
                                  DECLINED
                                </button>
                              </>
                            ) : (
                              <button
                                name={verifiedState ? "APPROVED" : "VERIFIED"}
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusActive'
                                id="verify"
                              >
                                {verifiedState ? "APPROVE" : "VERIFY"}
                              </button>
                            )}
                            {props.status === "INIT" && (
                              <button
                                name='HOLD'
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusClosed mt-10'
                                id="hold"
                              >
                                HOLD
                              </button>
                            )}
                            {verifiedState === "DECLINED" && (
                              <button
                                name={verifiedState && "DECLINED"}
                                // name={verifiedState ? "DECLINED" : "HOLD"}
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusClosed mt-10'
                              >
                                {verifiedState && "DECLINE"}
                                {/* {verifiedState ? "DECLINE" : "HOLD"} */}
                              </button>
                            )}
                            {props.status === "HOLD" && (
                              <button
                                name='DECLINED'
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusClosed mt-10'
                              >
                                DECLINE
                              </button>
                            )}
                          </div>
                          {/* {isHoldThere && (
                            <div style={(divStyle, { marginLeft: "8px" })}>
                              <button
                                name='APPROVED'
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusActive'
                              >
                                APPROVE
                              </button>
                              <button
                                name='DECLINED'
                                onClick={handlePostData}
                                className='CMS-btnStatus CMS-statusClosed mt-10'
                              >
                                DECLINE
                              </button>
                            </div>
                          )} */}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermission: state.dashboard.userPermission,
    userPagePermissions: state.dashboard.userPagePermissions,
  };
};

export default connect(mapStateToProps)(ActionDialog);
