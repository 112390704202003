import React from 'react'
import { useSelector } from "react-redux"
import { getDepositTransactionReport, setDepositDates } from '../reportSlice'
import ReportsWrapper from "../ReportsWrapper"
import DepositTable from "./DepositTable"
const DepositReport = () => {
    const { depositDates, depositData, paginationFirstValue, paginationSecondValue } = useSelector(state => state.reports)

    return (
        <React.Fragment>
            <ReportsWrapper
                reportTitle={"DEPOSIT REPORT"}
                currentTab={"DEPOSIT REPORT"}
                pageKey={"DepositReport"}
                setDateToStore={setDepositDates}
                persistDate={depositDates}
                sportData={depositData}
                pageNumber={paginationFirstValue}
                itemsPerPage={paginationSecondValue}
                api={getDepositTransactionReport}
                depositPagination={true}
                ReportTable={() => (
                    <DepositTable
                        dates={depositDates}
                        reportsData={depositData}
                        reportPage={"Deposit Report"}
                    />
                )}
            />
        </React.Fragment>
    )
}
export default DepositReport
