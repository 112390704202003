import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { currencyConversionApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const CurrencySlice = createSlice({
  name: "currencyconversion",
  initialState: {
    currencyConversion: [],
  },
  reducers: {
    setCurrencyConversion: (state, action) => {
      state.currencyConversion = action.payload
    },
  },
});

export const { setCurrencyConversion, setPrevTableData, setIsVisible } = CurrencySlice.actions;

export const getCurrencylist = () => (dispatch) => {
  let url = `${currencyConversionApi.getCurrency}`
  apiCaller(url, "GET").then((response) => {
    if (response.status == 200) {
      dispatch(setCurrencyConversion(response.data));
    }
  });
}

export const getAddCurrency = (data) => (dispatch) => {

  var url = `${currencyConversionApi.getCurrency}`;
  apiCaller(url, "POST", data)
    .then((response) => {
      response.status == 200 && toaster.success("SUCCESS");
      dispatch(getCurrencylist());
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
}

export default CurrencySlice.reducer;