import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { brandApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const CreateBrandSlice = createSlice({
  name: "createbrand",
  initialState: {
    brandData: [],
    listBrand: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    brandStatus: [],
  },
  reducers: {
    setBrandData: (state, action) => {
      state.brandData = action.payload;
    },
    setListBrand: (state, action) => {
      state.listBrand = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setBrandStatus: (state, action) => {
      state.brandStatus = action.payload;
    },
  },
});
export const {
  setBrandData,
  setListBrand,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setBrandStatus,
} = CreateBrandSlice.actions;

export const addBrand = (param) => (dispatch) => {
  const obj = {
    brandCurrency: param.brandCurrency,
    brandName: param.brandName,
    clientId: param.clientId,
  };
  var url = `${brandApi.getBrand + "/add"}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response) {
        dispatch(setBrandData(response.data));
        toaster.success("SUCCESS");
      } else {
        toaster.error(response.msg);
      }
    })
    .catch((err) => {
      const response = err.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};

export const getAllBrand = (pageNumber, itemPerPage) => (dispatch) => {
  var url =
    `${brandApi.getAllBrand}` +
    `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  apiCaller(url, "GET", {})
    .then(
      (rep) => dispatch(setListBrand(rep.data)),
      dispatch(setIsVisible(true))
    )
    .catch((err) => {
      const response = err.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};

export const updateBrandStatus = (brandId, status) => (dispatch) => {
  const url = brandApi.brandStatus(brandId, status);
  apiCaller(url, "PUT", {})
    .then((res) => {
      dispatch(setBrandStatus(res));
      if (res && res.msg === "UPDATE_BRAND_SUCCESS")
        toaster.success("Brand Updated Successfully");
    })
    .then((err) => toaster.error("Brand Updation Failed"));
};
export default CreateBrandSlice.reducer;
