import React, { useEffect, useState } from 'react'
import Datepicker from './DatePicker'

const DateTime = ({ title, name, datevalue, errorMsg, setValue, ids, info, minDate, maxDate, onTimeChange, timeName, noInfo }) => {
    const [currentTime, setCurrentTime] = useState('');
    const [time, setTime] = useState('')

    const handleTimeChange = (e) => {
        const { value, name } = e.target;
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        const currentDate = now.getDate();
        const extractedNowDate = `${currentDate}/${currentMonth + 1}/${currentYear}`
        const valueDate = new Date(datevalue);

        const valueDay = valueDate.getDate();
        const valueMonth = valueDate.getMonth();
        const valueYear = valueDate.getFullYear();
        const extractedDateValue = `${valueDay}/${valueMonth + 1}/${valueYear}`

        if (extractedDateValue === extractedNowDate) {
            if (value >= getCurrentTime()) {
                setTime(value);
                onTimeChange(e);
            } else {
                setTime(currentTime);
                console.log("Cannot select past time for today's date.");
            }
        } else if (valueDate > now) {
            setTime(value);
            onTimeChange(e);
        }
    }

    function getCurrentTime() {
        const currentTime = new Date();
        return currentTime.toTimeString().slice(0, 5);
    }
    useEffect(() => {

        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentTimeString = `${hours}:${minutes}`;
        setCurrentTime(currentTimeString);

    }, []);
    const className = `CMS-formControl ml-20 ${!noInfo ? 'mt-28' : ''}`;
    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='width-200'>
                    <Datepicker
                        title={title}
                        name={name}
                        value={datevalue}
                        errorMsg={errorMsg}
                        setValue={(e, name) => setValue(e, name)}
                        ids={ids}
                        info={info}
                        minDate={minDate}
                        maxDate={maxDate}
                        noInfo={noInfo}
                    />
                </div>
                <div className={className}>
                    <input
                        type="time"
                        name={timeName}
                        value={time ? time : currentTime}
                        onChange={(e) => handleTimeChange(e)}
                        className='width-200'
                        id="timeInput"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DateTime