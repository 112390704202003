import React, { useState, useRef, useEffect, memo } from "react";
import Tooltips from "../Tooltip";

const Input = (props) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();
  const {
    title,
    type,
    name,
    value,
    placeholder,
    setValue,
    regex,
    maxLen,
    errorMsg,
    disabled,
    maxValue,
    accept,
    inline,
    inlineStyle,
    defaultValue,
    ids,
    onFocus,
    isTofocus,
    style,
    info,
    className,
    ...rest
  } = props;
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (
      value === "" ||
      (props?.regex !== undefined ? regex.test(value) : true)
    ) {
      //   setInputValue(value);
      setValue(e);
    }
  };




  useEffect(() => {
    let mounting = true;
    if (mounting && isTofocus) {
      inputRef.current.focus();
    };
    return () => mounting = false;
  }, [isTofocus])

  return (
    <>
      {inline ? (
        <div className='CMS-formGroup formGroup-inline'>
          <div className={inlineStyle?.label || "col-6"}>
            <div className='CMS-formLabel'>
              {title}
              &nbsp;&nbsp;
              {props.show ? <Tooltips info={info} /> : ""}
            </div>
          </div>
          <div className={inlineStyle?.input || "col-6"}>
            <div className='CMS-formControl'>
              <input
                type={type}
                id={ids}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => handleChange(e)}
                autoComplete={"off"}
                maxLength={maxLen}
                disabled={disabled}
                accept={accept}
                max={`${maxValue}`}
                defaultValue={defaultValue}
                readOnly={type === "label" ? true : false}
                onFocus={onFocus}
                // className={type==="label"?"form-control":""}
                aria-describedby='button-addon2'
                ref={inputRef}
                style={style}
                className={className}
              />
            </div>
            <div className='CMS-form-errorMsg'>{errorMsg ? errorMsg : ""}</div>
          </div>
        </div>
      ) : (
        <div className='CMS-formGroup'>
          <div className='CMS-formLabel'>
            {title}
            &nbsp;&nbsp;
            {props.show ? < Tooltips info={info} /> : ""}
          </div>
          <div
            className={
              type === "label"
                ? " CMS-formControl input-group d-flex"
                : "CMS-formControl "
            }
          >
            <input
              type={type}
              id={ids}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={(e) => handleChange(e)}
              autoComplete={"off"}
              maxLength={maxLen}
              disabled={disabled}
              accept={accept}
              max={`${maxValue}`}
              defaultValue={defaultValue}
              readOnly={type === "label" ? true : false}
              onFocus={onFocus}
              // className={type==="label"?"form-control":""}
              aria-describedby='button-addon2'
              ref={inputRef}
              style={style}
              className={className}
            />
            {type == "label" && (
              <button
                className='btn btn-outline-secondary'
                type='button'
                id='button-addon2'
                onClick={() => props.generateCode()}
              >
                Generate
              </button>
            )}
          </div>
          <div className='CMS-form-errorMsg'>{errorMsg ? errorMsg : ""}</div>
        </div>
      )}
    </>
  );
};

export default memo(Input);
