import React from 'react'
import BiTable from './BiTable'
import { useSelector } from 'react-redux';

const BiReport = () => {
    const biReportData = useSelector((p) => p.bireportSlice.biReportData)

    return (
        <>
            {biReportData.map((report, index) => (
                <BiTable
                    key={index}
                    category={report.ReportCatagory}
                    reportName={report.Reports}
                />
            ))}
        </>
    )
}

export default BiReport