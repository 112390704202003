import React from 'react'
import AlertBox from "../Common/Dialog/AlertBox"

function AlertMsg({ onAccept, onReject }) {

    return (
        <AlertBox>
            <div className="alert_child_box">
                <div className="alert_content_box">
                    <h3 className="alert_title">Alert</h3>
                    <p className="alert_message">
                        Invalid State Name. Please Enter Valid State Name?
                    </p>
                </div>
                <div className="alert_btn_group">
                    <button className="alert_accept" onClick={onAccept}>Yes</button>
                    <button className="alert_reject" onClick={onReject}>No</button>
                </div>
            </div>
        </AlertBox>
    )
}

export default AlertMsg
