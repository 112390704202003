import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setSocketNotification } from "../components/AppSlice";
import { useDispatch } from "react-redux";

export default function Toaster(props) {
  const dispatch = useDispatch()
  const position = props?.isSocket ? 'bottom-right' : 'top-right';
  useEffect(() => {
    let timer;
    if (props?.isSocket) {
      timer = setTimeout(() => dispatch(setSocketNotification(false)), 2100)
    }
    return
  }, [props?.isSocket])
  return <ToastContainer
    position={position}
    autoClose={2100}
    hideProgressBar={false}
    newestOnTop={false}
    theme='dark'
    closeOnClick
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
  // limit={1}
  />
};
