import React, { Component } from "react";
// import Highcharts from '../../sharedfiles/customhighcharts';
import Highcharts from "highcharts";
import { getDateFormate } from "../../sharedfiles/helper";
class BetCountChart extends Component {
  componentDidMount() {
    if (this.props?.data && this.props?.data?.length > 0) {
      this.loadGraph();
    }
  }

  loadGraph() {
    let stack = [];
    let brand = [];
    let dates = [];

    let arrayData = [...this.props?.data];
    if (this.props?.data) {
      let sortedData = arrayData.sort(
        (a, b) =>
          new Date(...a.date.split("/").reverse()) -
          new Date(...b.date.split("/").reverse())
      );
      for (var item of sortedData) {
        if (!dates.includes(item.date)) {
          stack.push(item.betCount);
          brand.push(item.brand);
          dates.push(getDateFormate(item.date));
        }
      }
    }

    Highcharts.chart("betcount", {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: true,
      },
      xAxis: {
        // type: "datetime",
        categories: dates,

        dateTimeLabelFormats: {
          day: "%e. %b",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
      },

      series: [
        {
          color: "#FFC000",
          name: brand[0] === "starpunter" ? "StarPunter" : brand[0],
          data: stack,
          tooltip: {
            valuePrefix: "",
            followPointer: true,
          },
        },
      ],
    });
  }
  render() {
    return <div id='betcount'></div>;
  }
}

export default BetCountChart;
