import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DateInputField from "react-datepicker";
import { convertStartDatePickerTimeToMySQLTime } from "../../sharedfiles/helper";
import { useDispatch, connect } from "react-redux";
import { getPlayer, updateplayer } from "./PrimaryPlayerSlice";
import AuditLog from "./AuditLog";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";

const EditPlayerDetails = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [selectedDob, setSelectedDob] = useState(
    props.playerData.dob ? new Date(props.playerData.dob) : ""
  );
  const [notification, setNotification] = useState("");
  const [promotionEmail, setPromotionEmail] = useState("");
  const [sms, setSms] = useState("");
  const [vip, setVip] = useState("");
  const [phoneCalls, setPhoneCalls] = useState("");
  const [keepLevel, setKeepLevel] = useState("");
  const dispatch = useDispatch();
  const [dateError, setDateError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dobMsg, setDobMsg] = useState("");
  const [openAuditLog, setOpenAuditLog] = useState(false)
  const [userType, setUserType] = useState("")
  const [userTypeErr, setUserTypeErr] = useState(false)
  const [btag, setBtag] = useState('')
  const [fraud, setFraud] = useState(false)
  const [realUser, setRealUser] = useState(false)

  const updateDetailsHandler = (event) => {
    setEmailError(false);
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { name, value, checked } = event.target;
    if (name == "email") {
      setEmailError(false);
      setEmail(value);
      if (emailRegex.test(value)) {
      } else {
        setEmailError(true);
      }
    }
    if (name === "userType" && value === "FRAUD_USER") {
      setFraud(true)
    }
    if (name === "userType" && value === "REAL_USER") {
      setRealUser(true)
    }
    if (name === "userType" && value === "") {
      setUserTypeErr(true)
    } else {
      setUserTypeErr(false)
    }
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      //   case "email":
      //     setEmail(value);
      //     break;
      //   case "dob":
      //     setDob(event);
      //     break;
      case "notification":
        setNotification(checked);
        break;
      case "promotionEmail":
        setPromotionEmail(checked);
        break;
      case "sms":
        setSms(checked);
        break;
      case "vip":
        setVip(checked);
        break;
      case "phoneCalls":
        setPhoneCalls(checked);
        break;
      case "keepLevel":
        setKeepLevel(checked);
        break;
      case "userType":
        setUserType(value);
        break;
      case "btag":
        setBtag(value)
      default:
        break;
    }
  };
  const validateAge = (e) => {
    e === null ? setDateError(true) : setDateError(false);
    e === null ? setDobMsg("dob cannot be empty") : setDateError("");
    var today = new Date();
    var birthDate = new Date(e);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age >= 18) {
      setDob(e);
      setSelectedDob(e);
    } else {
      setDob(e);
      setDobMsg("Age must be above 18");
      setSelectedDob(e);
      setDateError(true);
    }
  };

  const updateUser = () => {
    // const time = new Date().toTimeString();
    // const date = new Date(
    //   dob.setTime(dob.getTime() + dob.getTimezoneOffset() * 60 * 1000) + time
    // );
    let updateData = {
      dob: convertStartDatePickerTimeToMySQLTime(dob),
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneCalls: phoneCalls,
      promotionalEmail: promotionEmail,
      pushNotificationphone: notification,
      sms: sms,
      vip: vip,
      keepLevelSticky: keepLevel,
      userType: userType,
      affliateBtag: btag
    };
    if (userType === "") {
      setUserTypeErr(true)
    }
    const user_id = localStorage.getItem("userId")
    if (!userTypeErr && !dateError) {
      props.dispatch(
        updateplayer(localStorage.getItem("userId"), updateData)
      );
      setTimeout(() => dispatch(getPlayer({ user_id: user_id })), 300);
      props.setOpenEditBox(false);
    }
  };
  useEffect(() => {
    setUserType(props?.playerData.userType)
  }, [])

  const onCloseFraud = () => {
    setFraud(false)
    setUserType(props?.playerData.userType)
  }
  const onCloseRealuser = () => {
    setRealUser(false)
    setUserType(props?.playerData.userType)
  }
  useEffect(() => {
    console.log(props.playerData.firstName, "props.playerData");
    setFirstName(props.playerData.firstName)
    setLastName(props.playerData.lastName)
    setEmail(props?.playerData?.userOtherInfo?.email)
    const dobValue = props.playerData.dob ? new Date(props.playerData.dob) : null;
    setDob(dobValue)
    setNotification(props?.playerData?.userCommPreference?.pushNotificationPhone)
    setPromotionEmail(props?.playerData?.userCommPreference
      ?.promotionalEmail)
    setSms(props?.playerData?.userCommPreference?.sms)
    setVip(props?.playerData?.vip)
    setPhoneCalls(props?.playerData?.userCommPreference?.phoneCalls)
    setKeepLevel(props?.playerData?.userOtherInfo?.keepLevelSticky)
    // setUserType()
    setBtag(props.playerData?.userOtherInfo?.affliateBtag)
  }, [])

  return (
    <React.Fragment>
      <AuditLog
        openAuditLog={openAuditLog}
        setOpenAuditLog={setOpenAuditLog}
      />

      <ConfirmDialog
        title={"Do you want to Active the user by changing user type?"}
        verify={() => setRealUser(false)}
        cancel={() => onCloseRealuser()}
        open={realUser}
        close={() => onCloseRealuser()}
        key={"realUser"}
        width={"sm"}
        noCloseCall
      />
      <ConfirmDialog
        title={"Do you want to Deactive the user by changing user type to Fraud User?"}
        verify={() => setFraud(false)}
        cancel={() => onCloseFraud()}
        open={fraud}
        close={() => onCloseFraud()}
        key={"fraudUser"}
        width={500}
        noCloseCall
      />

      <Dialog__Main
        dOpen={props.openEditBox}
        dClose={() => props.setOpenEditBox(false)}
        title={"Edit player Details"}
      >
        <div className='dialog-box3'>
          <div className='CMS-box CMS-box-content'>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'> {COMMON_CONSTANTS.header_firstname}</p>
                      <Tooltips info={PLAYER_MODULE.firstName} />
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-formControl'>
                      <input
                        type='text'
                        name='firstName'
                        id='editplayerfirstname'
                        placeholder='First Name'
                        value={firstName}
                        onChange={updateDetailsHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='CMS-formLabel'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.header_lastname}</p>
                        <Tooltips info={PLAYER_MODULE.LastName} />
                      </div>
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-formControl'>
                      <input
                        type='text'
                        name='lastName'
                        id='editplayerlastname'
                        value={lastName}
                        onChange={updateDetailsHandler}
                        placeholder='Last Name'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.header_Email}</p>
                      <Tooltips info={PLAYER_MODULE.Email} />
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-formControl'>
                      <input
                        type='text'
                        name='email'
                        value={email}
                        onChange={updateDetailsHandler}
                        placeholder='example@gmail.com'
                        autoComplete={'off'}
                      />
                    </div>
                    {/* {emailError && (
                        <p style={{ color: "red", fontSize: 16 }}>
                          Enter Proper Email ID
                        </p>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.header_dob}</p>
                      <Tooltips info={PLAYER_MODULE.Edit_DOB} />
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-formControl'>
                      <DateInputField
                        name='dateOfBirth'
                        placeholderText={"DD/MM/YYYY"}
                        autoComplete={'off'}
                        dateFormat='dd/MM/yyyy'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        selected={dob}
                        // withPortal
                        onChange={(v) => validateAge(v)}
                        onSelect={(v) => validateAge(v)}
                        placeholder='DD/MM/YYYY'
                      />
                    </div>
                    {dateError ? (
                      <p style={{ color: "red", fontSize: 16 }}>{dobMsg}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>User Type</p>
                      <Tooltips info={PLAYER_MODULE.Edit_Usertype} />
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-number-of-files CMS-select'>
                      <select
                        type='text'
                        id='userType'
                        name='userType'
                        value={userType}
                        onChange={updateDetailsHandler}
                      >
                        <option value=''>Select</option>
                        <option value='REAL_USER'>Real Player</option>
                        <option value='TEST_USER'>Test user</option>
                        <option value='FRAUD_USER'>Fraud User</option>
                      </select>
                    </div>
                    {userTypeErr && (
                      <p style={{ color: "red", fontSize: 16 }}>
                        User Type is Required
                      </p>
                    )}
                  </div>

                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='CMS-formLabel'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'> {"Btag"}</p>
                      <Tooltips info={PLAYER_MODULE.Btag} />
                    </div>
                  </div>
                  <div className='CMS-formControl-group'>
                    <div className='CMS-formControl'>
                      <input
                        type='text'
                        name='btag'
                        id='editplayerbtag'
                        placeholder='Btag'
                        value={btag}
                        onChange={updateDetailsHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='notification'
                      type='checkbox'
                      name='notification'
                      checked={notification}
                      onChange={updateDetailsHandler}
                    />
                    <label htmlFor='notification'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.header_notification}</strong>
                    </span>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='email'
                      type='checkbox'
                      name='promotionEmail'
                      checked={promotionEmail}
                      onChange={updateDetailsHandler}
                    />
                    <label htmlFor='email'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.header_promoEmail}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='sms'
                      type='checkbox'
                      name='sms'
                      checked={sms}
                      // defaultChecked={
                      //   sms ? sms : props?.playerData?.userCommPreference?.sms
                      // }
                      onChange={updateDetailsHandler}
                    />
                    <label htmlFor='sms'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.header_sms}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='viPP'
                      type='checkbox'
                      name='vip'
                      onChange={updateDetailsHandler}
                      checked={vip}
                    // defaultChecked={vip ? vip : props?.playerData?.vip}
                    />
                    <label htmlFor='viPP'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.header_VIP}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='phonecall'
                      type='checkbox'
                      name='phoneCalls'
                      checked={phoneCalls}
                      // defaultChecked={
                      //   phoneCalls
                      //     ? phoneCalls
                      //     : props?.playerData?.userCommPreference?.phoneCalls
                      // }
                      onChange={updateDetailsHandler}
                    />
                    <label htmlFor='phonecall'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.header_phnCalls}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-form-group'>
                  <div className='CMS-checkbox'>
                    <input
                      id='keeplevel'
                      type='checkbox'
                      name='keepLevel'
                      // defaultChecked={
                      //   keepLevel
                      //     ? keepLevel
                      //     : props?.playerData?.userOtherInfo?.keepLevelSticky
                      // }
                      checked={keepLevel}
                      onChange={updateDetailsHandler}
                    />
                    <label htmlFor='keeplevel'></label>
                    <span>
                      <strong>{COMMON_CONSTANTS.keepLevelSticky}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>

            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-btnContainer'>
                  <button
                    onClick={updateUser}
                    className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                    type='button'
                    id='editplayersave'
                  >
                    {COMMON_CONSTANTS.save}
                  </button>
                  <button
                    onClick={() => props.setOpenEditBox(false)}
                    className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                    type='button'
                    id='editplayercancel'
                  >
                    {COMMON_CONSTANTS.cancel}
                  </button>
                  <button
                    onClick={() => setOpenAuditLog(true)}
                    className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                    type='button'
                    id='editplayerAudit'
                  >
                    {COMMON_CONSTANTS.auditLogBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog__Main>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    playerData: state.primaryplayer.playerData,
  };
};
export default connect(mapStateToProps)(EditPlayerDetails);
