import React from "react";
import Dialog__Main from "./Dialog__Main";

function ConfirmDialog(props) {
  const { title, buttons, verify, cancel, open, close, message, key, width, noCloseCall, isKyc } = props;
  function verifyHandler() {
    verify();
    if (!noCloseCall) {
      close();
    }
  }
  function rejectHandler() {
    cancel();
  }
  return (
    <React.Fragment key={key || Date.now()}>
      <Dialog__Main
        title={title}
        dOpen={open}
        dClose={close}
        width={width}
        key={key || Date.now()}
      >
        <React.Fragment key={key || `1423_${Date.now()}`}>
          {message ?
            <div className="CMS-msgBox mb-10"
              style={{
                fontSize: "18px",
              }}>
              <div className="CMS-msgBox-container">{message}</div>
            </div> : null
          }
          <div className='CMS-btnContainer'>
            <button
              className='CMS-btn CMS-btnStatus CMS-statusActive pointer'
              onClick={() => verifyHandler()}
            >
              {isKyc ? "YES" : " OK"}
            </button>
            <button
              className='CMS-btn CMS-btnStatus CMS-statusClosed pointer'
              onClick={() => rejectHandler()}
            >
              {isKyc ? "NO" : "CANCEL"}
            </button>
          </div>
        </React.Fragment>
      </Dialog__Main>
    </React.Fragment>
  );
}

export default ConfirmDialog;
