import React, { useState, useEffect } from 'react'
import Select from "../Common/common_inputs/Select_DDL";
import Input from "../Common/common_inputs/Input";
import { ExclusionPeriod, GamblingLimits, SelfExclusionPeriod, timeLimits } from "../Common/PrimaryplayerTab/ComTab";
import { updateuserLimit } from "./GamblingLimitsSlice"
import { connect, useDispatch } from 'react-redux';
import { getPlayer } from '../primaryplayer/PrimaryPlayerSlice';
import { getDateFormate, getTimeFormat, getTimeinMinutes, MoneyFormat1, MoneyFormat2 } from '../../sharedfiles/helper';
import { Gambling } from '../../sharedfiles/tooltipMsgs';

const ResponsibleGambling = (props) => {
  const [GambleLimits, setGambleLimits] = useState("")
  const [Timelimit, setTimeLimit] = useState("")
  const [selfTimeLimit, setSelfTimeLimit] = useState("")
  const [Limits, setLimits] = useState("")
  const [LimitErr, setLimitErr] = useState(false)
  const [selfLimitErr, setSelfLimitErr] = useState(false)
  const [timeLimitErr, setTimeLimitErr] = useState(false)
  const [GambleLimitErr, setGambleLimitErr] = useState(false)
  const [ExclusionTime, SetExclusionTime] = useState("")
  const [ExclusionErr, setExclusionErr] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [Timedisabled, setTimeDisabled] = useState(false)
  const [Exdisable, setExDisable] = useState(false)
  const [resetDisable, setResetDisable] = useState(false)
  const [resetExDisable, setResetExDisable] = useState(false)
  const dispatch = useDispatch();


  const handleChange = (e) => {
    const { name, value } = e.target
    if (value === "WithdrawalLimits" || "BettingLimits" || "DepositLimits") {
      setTimeLimit("")
      setLimits("")
    }
    setGambleLimits(value)
    setSelfLimitErr(false)
    setTimeLimitErr(false)
    setLimitErr(false)
    setExclusionErr(false)
    if (value === "") {
      setGambleLimitErr(true)
    } else {
      setGambleLimitErr(false)
    }
  }

  const selectHandler = (e) => {
    const { name, value } = e.target
    if (name === "selfTimeLimit") {
      setSelfTimeLimit(value)
    }
    if (name === "exclusionTime") {
      SetExclusionTime(value)
    }
    if (value === "") {
      setSelfLimitErr(true)
      setTimeLimitErr(true)
    }
    if (value !== "") {
      setSelfLimitErr(false)
      setTimeLimitErr(false)
      setExclusionErr(false)
    }
    if (name === "Timelimit") {
      setTimeLimit(value)
    }

    if (GambleLimits === "DepositLimits" && value === "Daily") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.dailyDepositLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "DepositLimits" && value === "Weekly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.weeklyDepositLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "DepositLimits" && value === "Monthly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.monthlyDepositLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }

    if (GambleLimits === "WithdrawalLimits" && value === "Daily") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.dailyWithdrawalLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "WithdrawalLimits" && value === "Weekly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.weeklyWithdrawalLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "WithdrawalLimits" && value === "Monthly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.monthlyWithdrawalLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }

    if (GambleLimits === "BettingLimits" && value === "Daily") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.dailyStakeLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "BettingLimits" && value === "Weekly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.weeklyStakeLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
    if (GambleLimits === "BettingLimits" && value === "Monthly") {
      const formatValue = MoneyFormat1(props.playerData.userLimit.monthlyStakeLimit)
      setLimits(formatValue)
      setLimitErr(false)
      setDisabled(false)
    }
  }

  const inputHandler = (e) => {
    const { name, value } = e.target
    if (name === "Limits") {
      setLimits(value)
      setDisabled(false)
      setLimitErr(false)
    }
    if (value === "") {
      setLimitErr("Please enter Proper Limits")
    }
    else if (Number(value) === 0) {
      setLimitErr("Value Must be greater than 0")
    } else if (value > 0) {
      setLimitErr("")
    }
  }


  const onSubmit = (e) => {
    if (Timelimit === "") {
      setTimeLimitErr(true)
    }
    if (Limits === "") {
      setLimitErr("Please enter Proper Limits")
    }
    if (GambleLimits !== "" && Timelimit !== "" && Limits !== "") {

      if (GambleLimits === "DepositLimits" && Timelimit === "Daily") {
        let dailyDeposit = { dailyDepositLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyDeposit))
      }
      else if (GambleLimits === "DepositLimits" && Timelimit === "Weekly") {
        let WeeklyDeposit = { weeklyDepositLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyDeposit))
      }
      else if (GambleLimits === "DepositLimits" && Timelimit === "Monthly") {
        let MonthlyDeposit = { monthlyDepositLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyDeposit))
      }

      if (GambleLimits === "WithdrawalLimits" && Timelimit === "Daily") {
        let dailyWithdraw = { dailyWithdrawalLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyWithdraw))
      }
      else if (GambleLimits === "WithdrawalLimits" && Timelimit === "Weekly") {
        let WeeklyWithdraw = { weeklyWithdrawalLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyWithdraw))
      }
      else if (GambleLimits === "WithdrawalLimits" && Timelimit === "Monthly") {
        let MonthlyWithdraw = { monthlyWithdrawalLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyWithdraw))
      }

      if (GambleLimits === "BettingLimits" && Timelimit === "Daily") {
        let dailyBets = { dailyStakeLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyBets))
      }
      else if (GambleLimits === "BettingLimits" && Timelimit === "Weekly") {
        let WeeklyBets = { weeklyStakeLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyBets))
      }
      else if (GambleLimits === "BettingLimits" && Timelimit === "Monthly") {
        let MonthlyBets = { monthlyStakeLimit: MoneyFormat2(Limits) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyBets))
      }

      setDisabled(true)
      setTimeLimitErr(false)
      setLimitErr(false)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)

    }
  }

  useEffect(() => {
    props.playerData?.userLimit?.selfExcludePeriod ? setExDisable(true) : setExDisable(false)
    props.playerData?.userLimit?.timeLimit ? setTimeDisabled(true) : setTimeDisabled(false)
    !props.playerData?.userLimit?.timeLimit ? setResetDisable(true) : setResetDisable(false)
    !props.playerData?.userLimit?.selfExcludePeriod ? setResetExDisable(true) : setResetExDisable(false)
  }, [])

  const onExSave = () => {

    if (ExclusionTime === "") {
      setExclusionErr(true)
    }
    if (ExclusionTime !== "") {
      setExclusionErr(false)
    }
    if (GambleLimits !== "" && ExclusionTime !== "") {
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "WEEK") {
        let weekly = { selfExclusion: "WEEK" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), weekly))
      }
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "MONTH") {
        let monthly = { selfExclusion: "MONTH" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), monthly))
      }
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "YEAR") {
        let yearly = { selfExclusion: "YEAR" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), yearly))
      }

      setExDisable(true)
      setResetExDisable(false)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
  }

  const onTimeSave = () => {

    if (selfTimeLimit === "") {
      setSelfLimitErr(true)
    }
    if (selfTimeLimit !== "") {
      setSelfLimitErr(false)
    }

    if (GambleLimits !== "" && selfTimeLimit !== "") {
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "15Mins") {
        let timeLimit = { timeLimit: 15 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "30Mins") {
        let timeLimit = { timeLimit: 30 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "1Hr") {
        let timeLimit = { timeLimit: getTimeinMinutes(1) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))

      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "2Hrs") {
        let timeLimit = { timeLimit: getTimeinMinutes(2) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))

      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "4Hrs") {
        let timeLimit = { timeLimit: getTimeinMinutes(4) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))

      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "8Hrs") {
        let timeLimit = { timeLimit: getTimeinMinutes(8) }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      setTimeDisabled(true)
      setResetDisable(false)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
  }

  const onReset = () => {
    if (GambleLimits !== "" && Timelimit !== "" && Limits !== "") {
      if (GambleLimits === "DepositLimits" && Timelimit === "Daily") {
        let dailyDeposit = { dailyDepositLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyDeposit))
      }
      else if (GambleLimits === "DepositLimits" && Timelimit === "Weekly") {
        let WeeklyDeposit = { weeklyDepositLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyDeposit))
      }
      else if (GambleLimits === "DepositLimits" && Timelimit === "Monthly") {
        let MonthlyDeposit = { monthlyDepositLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyDeposit))
      }

      if (GambleLimits === "WithdrawalLimits" && Timelimit === "Daily") {
        let dailyWithdraw = { dailyWithdrawalLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyWithdraw))
      }
      else if (GambleLimits === "WithdrawalLimits" && Timelimit === "Weekly") {
        let WeeklyWithdraw = { weeklyWithdrawalLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyWithdraw))
      }
      else if (GambleLimits === "WithdrawalLimits" && Timelimit === "Monthly") {
        let MonthlyWithdraw = { monthlyWithdrawalLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyWithdraw))
      }

      if (GambleLimits === "BettingLimits" && Timelimit === "Daily") {
        let dailyBets = { dailyStakeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), dailyBets))
      }
      else if (GambleLimits === "BettingLimits" && Timelimit === "Weekly") {
        let WeeklyBets = { weeklyStakeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), WeeklyBets))
      }
      else if (GambleLimits === "BettingLimits" && Timelimit === "Monthly") {
        let MonthlyBets = { monthlyStakeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), MonthlyBets))
      }
      setDisabled(false)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
    setLimits("")
    setLimitErr("")
  }

  const reset = (e) => {
    if (GambleLimits !== "" && ExclusionTime !== "") {
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "WEEK") {
        let weekly = { selfExclusion: "NULL" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), weekly))
      }
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "MONTH") {
        let monthly = { selfExclusion: "NULL" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), monthly))
      }
      if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "YEAR") {
        let yearly = { selfExclusion: "NULL" }
        dispatch(updateuserLimit(localStorage.getItem("userId"), yearly))
      }
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
    if (GambleLimits === "SelfExclusionPeriod" && ExclusionTime === "") {
      let reset = { selfExclusion: "NULL" }
      dispatch(updateuserLimit(localStorage.getItem("userId"), reset))
      setResetExDisable(true)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
    setSelfLimitErr("")
    SetExclusionTime("")
    setDisabled(false)
    setExDisable(false)
    setResetExDisable(true)
  }


  const onTymReset = () => {
    if (GambleLimits !== "" && selfTimeLimit !== "") {
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "15Mins") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "30Mins") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "1Hr") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "2Hrs") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "4Hrs") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      if (GambleLimits === "TimeLimits" && selfTimeLimit === "8Hrs") {
        let timeLimit = { timeLimit: 0 }
        dispatch(updateuserLimit(localStorage.getItem("userId"), timeLimit))
      }
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
    if (GambleLimits === "TimeLimits" && selfTimeLimit === "") {
      let reset = { timeLimit: 0 }
      dispatch(updateuserLimit(localStorage.getItem("userId"), reset))
      setResetDisable(true)
      setTimeout(() => {
        dispatch(getPlayer({ user_id: localStorage.getItem("userId") }))
      }, 500)
    }
    setSelfTimeLimit("")
    setTimeDisabled(false)
    setResetDisable(true)
  }

  return (
    <>
      <div className='CMS-tabContent'>
        <div className='CMS-box-content'>
          <div className='CMS-box CMS-box-content'>
            <div className='row' style={{ alignItems: "center" }}>
              <div className='col-md-6 col-lg-4 col-xl-3'>
                <Select
                  title={"Gambling Limits"}
                  name={"GambleLimits"}
                  ids={"GamblingLimits"}
                  value={GambleLimits}
                  setValue={(val) => handleChange(val)}
                  options={GamblingLimits}
                  errorMsg={GambleLimitErr && "Please select your Gambling Limits"}
                  info={Gambling.limits}
                />
              </div>

              {GambleLimits === "DepositLimits"
                || GambleLimits === "WithdrawalLimits"
                || GambleLimits === "BettingLimits"
                ? <div className='col-md-6 col-lg-4 col-xl-3'>
                  <Select
                    title={"Time Limits"}
                    name={"Timelimit"}
                    ids={"Timelimit"}
                    value={Timelimit}
                    setValue={(val) => selectHandler(val)}
                    options={SelfExclusionPeriod}
                    errorMsg={timeLimitErr && "Please select your Time Limits"}
                    info={Gambling.Time_Limits}
                  />
                </div> : GambleLimits === "SelfExclusionPeriod"
                  ? <div className='col-md-6 col-lg-4 col-xl-3'>
                    <Select
                      title={"Exclusion Period"}
                      name={"exclusionTime"}
                      ids={"exclusionTime"}
                      value={ExclusionTime}
                      setValue={(val) => selectHandler(val)}
                      options={ExclusionPeriod}
                      errorMsg={ExclusionErr && "Please select your Exclusion Period"}
                      info={Gambling.Exclusion_Period}
                    />
                  </div> : GambleLimits === "TimeLimits" ? <div className='col-md-6 col-lg-4 col-xl-3'>
                    <Select
                      title={"Exclusion Period"}
                      name={"selfTimeLimit"}
                      ids={"selfTimeLimit"}
                      value={selfTimeLimit}
                      setValue={(val) => selectHandler(val)}
                      options={timeLimits}
                      errorMsg={selfLimitErr && "Please select your Time Limits"}
                      info={Gambling.Exclusion_Period}
                    />
                  </div> : ""}

              {GambleLimits === "DepositLimits"
                || GambleLimits === "WithdrawalLimits"
                || GambleLimits === "BettingLimits" ? <div className='col-md-6 col-lg-4 col-xl-3'>
                <Input
                  title={"Limits"}
                  placeholder={"Limits"}
                  name={"Limits"}
                  type={"text"}
                  ids={"Limits"}
                  value={Limits}
                  setValue={(e) => inputHandler(e)}
                  errorMsg={LimitErr}
                  info={Gambling.bet_Limits}
                  show={true}
                />
              </div> : ""}

              {GambleLimits === "DepositLimits"
                || GambleLimits === "WithdrawalLimits"
                || GambleLimits === "BettingLimits" ? <div className='CMS-btnContainer' style={{ marginInlineStart: "50px", marginBottom: "10px" }}>
                <button
                  className='CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium'
                  style={{ height: "34px" }}
                  onClick={(e) => onSubmit(e)}
                  disabled={disabled}
                >
                  Save
                </button>
                <button
                  className='CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium'
                  onClick={(e) => onReset(e)}
                >
                  Reset
                </button>
              </div> : ""}

              {GambleLimits === "SelfExclusionPeriod" ?
                <div className="col" style={{ marginInlineStart: "50px", marginBottom: "10px" }}>
                  {props.playerData.userLimit.selfExcludePeriod !== null ?
                    <div className="CMS-text__headers">
                      Self-Exclusion period will complete By {getDateFormate(props.playerData.userLimit.selfExcludePeriod)}
                    </div> : ""}
                </div> : ""}

              {GambleLimits === "TimeLimits" ?
                <div className="col" style={{ marginInlineStart: "50px", marginBottom: "10px" }}>
                  {props.playerData.userLimit.timeLimit !== null && props.playerData.userLimit.timeLimit > 0 ?
                    <div className="CMS-text__headers">
                      Time Limit will complete In {getTimeFormat(props.playerData.userLimit.timeLimit)}
                      &nbsp;
                      {props.playerData.userLimit.timeLimit >= 120 ? "Hours" : props.playerData.userLimit.timeLimit >= 60 ? "Hour" : "Minutes"}
                    </div> : ""}
                </div> : ""}

            </div>


            {GambleLimits === "SelfExclusionPeriod"
              ? <div className='CMS-btnContainer' style={{ marginInlineEnd: "676px" }}>
                <button
                  className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${Exdisable ? "disable-button disabled_events" : ""}`}
                  onClick={(e) => onExSave(e)}
                  disabled={Exdisable}
                >
                  Save
                </button>
                <button
                  className={`CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium ${resetExDisable ? "disable-button disabled_events" : ""}`}
                  onClick={() => reset()}
                  disabled={resetExDisable}
                >
                  Reset
                </button>

              </div> : ""}


            {GambleLimits === "TimeLimits" ? <div className='CMS-btnContainer' style={{ marginInlineEnd: "676px" }}>
              <button
                className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${Timedisabled ? "disable-button disabled_events" : ""}`}
                onClick={(e) => onTimeSave(e)}
                disabled={Timedisabled}
              >
                Save
              </button>
              <button
                className={`CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium ${resetDisable ? "disable-button disabled_events" : ""}`}
                onClick={() => onTymReset()}
                disabled={resetDisable}
              >
                Reset
              </button>

            </div> : ""}

          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    playerData: state.primaryplayer.playerData,
  };
};

export default connect(mapStateToProps)(ResponsibleGambling)





