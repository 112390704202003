import React, { useState } from 'react'
import { formatMoney } from '../../../sharedfiles/helper'
import VButtonGroup from '../Buttons/VButtonGroup'
import ConfirmDialog from './ConfirmDialog'

export default function PaymentsAuditor(props) {
    const { data, btnType, actionType, finish, close, amountKey } = props
    const [comments, setComments] = useState('')
    const [error, setError] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const commentsHanler = (e) => {
        const { value } = e.target
        setComments(value)
        setError(value ? '' : 'Please mention your comments')
    }
    function valdiate() {
        if (comments.length < 4) {
            setError('Please enter valid Comments')
            return false
        }
        setError('')
        return true
    }
    const actionHandler = () => {
        const isValid = valdiate()
        if (isValid) {
            setConfirm(true)
        }
    }
    const verifyHandler = () => {
        finish(comments)
        setConfirm(false)
        close()
    }
    const rejectHandler = () => {
        setConfirm(false)
        close()
    }
    return (
        <React.Fragment>
            <ConfirmDialog
                title={'Are you sure you want to continue?'}
                open={confirm}
                close={() => setConfirm(false)}
                verify={verifyHandler}
                cancel={rejectHandler}
                width={"sm"}
            />
            <div className="CMS-msgBox">
                <div className="CMS-msgBox-container">
                    <div className="CMS-table CMS-table-triped">
                        <table>
                            <thead>
                                <tr>
                                    <th>TXN ID</th>
                                    <th>Amount</th>
                                    <th>Comments</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data?.transactionId}</td>
                                    <td>{formatMoney(data[amountKey])}</td>
                                    <td>
                                        <input
                                            type="text"
                                            name="notes"
                                            value={comments}
                                            placeholder="Mention your comments here"
                                            onChange={(e) => commentsHanler(e)}
                                        />
                                        {error ? (
                                            <div className="CMS-form-errorMsg">{error}</div>
                                        ) : null}
                                    </td>
                                    <td>
                                        <VButtonGroup
                                            buttons={btnType}
                                            accept={actionHandler}
                                            decline={actionHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
