import React, { useState } from 'react'
import { KYC_CONSTANTS, PAYMENT_CONSTANTS } from '../../sharedfiles/Constants'
import VButtonGroup from './Buttons/VButtonGroup'
import ConfirmDialog from './Dialog/ConfirmDialog'

const AcceptCmntBox = (props) => {
    const [comments, setComments] = useState("")
    const [error, setError] = useState("")
    const [confirm, setConfirm] = useState(false)

    const { close, finish, declineBox } = props

    const commentsHandler = (e) => {
        const { value } = e.target
        setComments(value)
        setError(value ? '' : 'Please mention your comments')
    }

    function valdiate() {
        if (comments.length < 4) {
            setError('Please enter valid Comments')
            return false
        }
        setError('')
        return true
    }

    const actionHandler = () => {
        const isValid = valdiate()
        if (isValid) {
            setConfirm(true)
        }
    }

    const verifyHandler = () => {
        finish(comments)
        setConfirm(false)
        close()
    }
    const rejectHandler = () => {
        setConfirm(false)
        close()
    }

    return (
        <React.Fragment>
            <ConfirmDialog
                title={'Are you sure you want to continue?'}
                open={confirm}
                close={() => setConfirm(false)}
                verify={verifyHandler}
                cancel={rejectHandler}
                width={"sm"}
            />
            <div className="CMS-msgBox">
                <div className="CMS-msgBox-container">
                    <div className="CMS-table CMS-table-triped">
                        <table>
                            <thead>
                                <tr>
                                    <th>{PAYMENT_CONSTANTS.th_comments}</th>
                                    <th>{KYC_CONSTANTS.header_action}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            name="notes"
                                            value={comments}
                                            placeholder="Mention your comments here"
                                            onChange={(e) => commentsHandler(e)}
                                        />
                                        {error ? (
                                            <div className="CMS-form-errorMsg">{error}</div>
                                        ) : null}
                                    </td>
                                    <td>
                                        <VButtonGroup
                                            buttons={["Accept"]}
                                            accept={actionHandler}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AcceptCmntBox