import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";

export const generalDetailSlice = createSlice({
  name: "generaldetail",
  initialState: {
    playerData: [],
    userCommonPrefrence: {}

  },
  reducers: {
    setPlayerDetail: (state, action) => {
      state.playerData = action.payload;
    },
    setUserCommonPrefrence: (state, action) => {
      state.userCommonPrefrence = action.payload;
    },
    resetUserCommonPrefrence: (state, action) => {
      state.userCommonPrefrence = [];
    },
    resetPlayerDetails: (state, action) => {
      state.playerData = [];
    },

  },
});
export const { setPlayerDetail, setUserCommonPrefrence, resetUserCommonPrefrence, resetPlayerDetails } = generalDetailSlice.actions;

export const getPlayerGeneralDetail = (params) => (dispatch) => {
  dispatch(resetUserCommonPrefrence())
  dispatch(resetPlayerDetails())
  var url = `${playerapi.getGeneralDetail}` + params.user_id;
  if (params.user_id !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data !== undefined) {
        if (response.status == 200) {
          dispatch(setPlayerDetail(response.data))
          dispatch(setUserCommonPrefrence(response.data.userCommPreference))
        }
      }
    });
  }
};

export default generalDetailSlice.reducer;
