import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi, tagApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
const TagsSlice = createSlice({
  name: "tags",
  initialState: {
    tagsData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    getTagsList: [],
    TagPostData: [],
    tableData: [],
    tagId: 0,
    recordsShow: [1, 25],
    viewRecords: false,

  },
  reducers: {
    getTagData: (state, action) => {
      state.tagsData = action.payload;
    },
    getTagsList: (state, action) => {
      state.getTagsList = action.payload;
    },
    getTagPostData: (state, action) => {
      state.TagPostData = action.payload;
    },
    getTagTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setTagId: (state, action) => {
      state.tagId = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setViewRecords: (state, action) => {
      state.viewRecords = action.payload;
    },
  },
});
export const {
  getTagData,
  getTagsList,
  getTagPostData,
  getTagTableData,
  setTagId,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setViewRecords,
} = TagsSlice.actions;

//dev.platform-ups.sportsit-tech.net:443/player/1000019/tag?pageNumber=0&ItemsPerPage=25
//dev.platform-ups.sportsit-tech.net:443/player/1000001/tag?pageNumber=0&ItemsPerPage=25
export const fetchTagData =
  (playerId, pageNumber, itemsPerpage) => (dispatch) => {
    const url =
      `${playerapi.addTags}` +
      playerId +
      "/tag?pageNumber=" +
      pageNumber +
      "&ItemsPerPage=" +
      itemsPerpage;

    apiCaller(url, "GET", {}).then((response) => {
      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(getTagData(response.data));
          dispatch(setViewRecords(true));
        }
      }
    });
  };

export const fetchTagsList = (pageNumber, itemsPerPage) => (dispatch) => {
  const getUrl = `${tagApi.getTag}?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;

  apiCaller(getUrl, "GET", {})
    .then((response) => dispatch(getTagsList(response.data)))
    .catch((err) => console.log(err));
};

export const postTags = (tagsData) => (dispatch) => {
  const postUrl = `${tagApi.getTag}`;

  const postData = {
    description: tagsData.description,
    tagName: tagsData.tagName,
    type: tagsData.type,
  };

  apiCaller(postUrl, "POST", postData)
    .then((res) => console.log("postNotesResponse :", res.data))
    .catch((err) => console.log("postNotesError :", err));
};

//dev.platform-ups.sportsit-tech.net:443/player/1000019/tag/{tagId}?tagId=33
//dev.platform-ups.sportsit-tech.net:443/player/1000019/tag/{tagId}?tagId=46
//dev.platform-ups.sportsit-tech.net:443/player/1000001/tag/26
//dev.platform-ups.sportsit-tech.net:443/player/1000001/tag/26
export const postUserTag = (playerId, tagId) => (dispatch, getState) => {
  const postTagUrl = `${tagApi.postTag}` + playerId + "/tag/" + tagId;
  const { tags } = getState();

  apiCaller(postTagUrl, "POST", {})
    .then((response) => {
      dispatch(fetchTagData(playerId, tags.paginationFirstValue - 1, tags.paginationSecondValue));
      toaster.success("SUCCESS")
    })
    .catch((error) => {
      const response = error.response
      toaster.error(messageEnhancer(response))
    });
};

//dev.platform-ups.sportsit-tech.net:443/player/1000019/tag/{tagId}?tagId=28

export const deleteTag = (playerId, tagId) => (dispatch) => {
  const deleteTagUrl =
    `${tagApi.postTag}` +
    playerId +
    "/tag/" +
    `{${tagId}}` +
    "?tagId=" +
    `${tagId}`;

  apiCaller(deleteTagUrl, "DELETE", {}).then((response) => {
    if (response !== null && response) {
      if (
        response.status == 200 &&
        response.message == "DELETE_USER_TAG_SUCCESS"
      ) {
        toaster.success("SUCCESS");
      } else {
        toaster.error("FAILED");
      }
    }
  });
};


export const deactiveTag = (data) => (dispatch, getState) => {
  const { tags } = getState();
  const { userTagId, playerId, status } = data
  const tagsUrl = tagApi.activeTags
  let obj = {
    userTagId: userTagId,
    status: !status
  }
  apiCaller(tagsUrl, "POST", obj)
    .then((res) => {
      if (res.status == 200) {
        dispatch(fetchTagData(playerId, tags.paginationFirstValue - 1, tags.paginationSecondValue));
        toaster.success(messageEnhancer(res.msg));
      }
    })
    .catch((err) => {
      const response = err.response
      toaster.error(messageEnhancer(response));
    });
}

export default TagsSlice.reducer;

// dispatch(getTagPostData(res.data)
