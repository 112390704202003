import React from "react";
import {  useSelector } from "react-redux";
import PaymentTable from "../PaymentTable";
import { setPaymentDates } from "../reportSlice";
import ReportsWrapper from "../ReportsWrapper";

function PaymentReport(props) {
  const { paymentsReportsData, paymentDates } = useSelector((st) => st.reports);
  return (
    <React.Fragment>
      <ReportsWrapper
        reportTitle={"PAYMENT OVERVIEW"}
        currentTab={"PAYMENT OVERVIEW"}
        pageKey={"PAYMENT"}
        setDateToStore={setPaymentDates}
        persistDate={paymentDates}
        sportData={paymentsReportsData}
        ReportTable={() => (
          <PaymentTable
            dates={paymentDates}
            sportData={paymentsReportsData}
            reportPage={"payment Report"}
          />
        )}
      />
    </React.Fragment>
  );
}

export default PaymentReport;
