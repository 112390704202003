import React from 'react'
import DataBox from '../Common/DataBox'
import SpeedGaugeGraph from './Graphs/SpeedGaugeGraph'
import SingleBarGraph from './Graphs/SingleBarGraph'

const GamesContainer = ({
    cardData,
    header,
    period,
    overall,
    ggrPeriodVal,
    ngrPeriodVal,
    ggrOverall,
    ngrOverall,
    ggrRatio,
    ngrRatio,
    widBarGraph,
    tripleGaugeGp,
    barData,
    DepoStakeRatio,
    withdrawPayoutRatio,
    DepoWithdrawRatio,
    bonusStakeratio,
    noCurrency,
}) => {

    const data = [
        { title: "GGR", Period: ggrPeriodVal, Overall: ggrOverall, ratio: ggrRatio },
        { title: "NGR", Period: ngrPeriodVal, Overall: ngrOverall, ratio: ngrRatio },
    ]
    const multiGaugeData = [
        { title: "Deposit to Stake Ratio", Period: DepoStakeRatio, ratio: DepoStakeRatio, noCurrency: noCurrency },
        { title: "Withdraw to Payout Ratio", Period: withdrawPayoutRatio, ratio: withdrawPayoutRatio, noCurrency: noCurrency },
        { title: "Deposit to Withdraw Ratio", Period: DepoWithdrawRatio, ratio: DepoWithdrawRatio, noCurrency: noCurrency },
    ]
    return (
        <>
            <div className="CMS-DB-categoryItem">
                <div className="CMS-categoryTitle">
                    {header}
                </div>
                <div className={tripleGaugeGp ? "CMS-box CMS-paymentsBox" : "CMS-box"}>
                    <div className="CMS-boxContent">
                        <ul>
                            {cardData.map((box, index) => (
                                <li key={index}>
                                    <DataBox title={box.title} data={box.data} currency={box.currency} info={box.info} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {widBarGraph ?
                        <div className="CMS-box-graphContainer CMS-highCharts-statistics">
                            < SpeedGaugeGraph title={"Bonus Vs Cash Stake"} Period={period} Overall={overall} ratio={bonusStakeratio} />
                            <SingleBarGraph title={"Popular Bonuses"} barData={barData} />
                        </div> : tripleGaugeGp ?
                            <div className="CMS-box-graphContainer">
                                {
                                    multiGaugeData.map((p, index) => (
                                        <SpeedGaugeGraph
                                            title={p.title}
                                            Period={p.Period}
                                            Overall={p.Overall}
                                            ratio={p.ratio}
                                            noCurrency={p.noCurrency}
                                            noPeriodOverall
                                        />
                                    ))
                                }
                            </div>
                            :
                            <div className="CMS-box-graphContainer">
                                {data.map((p, index) => (
                                    <SpeedGaugeGraph title={p.title} Period={p.Period} Overall={p.Overall} ratio={p.ratio} />
                                ))}
                            </div>}
                </div>
            </div>
        </>
    )
}

export default GamesContainer
