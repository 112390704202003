import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import { tabManager } from "../../sidebar/sidebarSlice";
import Skelton from "../skelton/Skelton";
import Loading from "../../loading/Loading";
import { generateKey } from "../../../sharedfiles/helper";
import Tooltips from "../Tooltip";
const Table = (props) => {
  const { tableData,
    linkIndex,
    loader,
    styles,
    hLen,
    vLen,
    skeltonWidth,
    sortables,
    viewBtn,
    onViewBtn, BtnName, cancelBtn, onCancel, CancelbtnName, btnStatus, ...rest } = props;
  const dispatch = useDispatch();
  const [hideLoader, setHideLoader] = useState(true);

  const tabsChange = (item) => {
    dispatch(tabManager(+item, props.tabStack));
    localStorage.setItem("userId", item);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setHideLoader(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, []);
  return (
    <>
      {tableData === null ||
        tableData === undefined ||
        Array.isArray(tableData) &&
        (tableData?.length == 0 && <Skelton hLen={hLen} vLen={vLen} width={skeltonWidth} />)}

      {tableData !== null &&
        tableData !== undefined &&
        Array.isArray(tableData) &&
        tableData?.length > 0 && (
          <div className='CMS-box'>
            <div className='CMS-table CMS-table-triped'>
              <table>
                <thead>
                  <tr>
                    {tableData[0]?.map((item, ind) => (
                      <th
                        key={generateKey(item)}
                        // style={{ whiteSpace: "nowrap" }}
                        className={sortables && Object.keys(sortables).includes(item) ? "pointer" : "text-center"}
                        onClick={() => sortables && Object.keys(sortables).includes(item) ? rest.handleSortFields(sortables[item], item) : null}
                      >
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{item}</p>
                          {<Tooltips info={`${tableData[1][ind]}`} placement={"left"} />}
                          {
                            sortables && Object.keys(sortables).includes(item) &&
                            <span
                              className='material-icons md-18'
                              data-icon={
                                rest.sortingOrder[item] ? "north" : "south"
                              }
                            ></span>
                          }
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData !== null &&
                    tableData !== undefined &&
                    Array.isArray(tableData) &&
                    tableData?.length > 0 &&
                    tableData.slice(2, tableData.length)[0] !== undefined &&
                    tableData.slice(2, tableData.length)[0] !== null &&
                    tableData.slice(2, tableData.length)[0].length > 0 ? (
                    tableData.slice(2, tableData.length).map((item, ind) => {
                      return (
                        <tr key={generateKey(ind)}>
                          {item.map((Cid, tdIndex) =>
                            tdIndex === linkIndex ? (
                              <td key={generateKey(tdIndex)} className={styles ? styles[tdIndex] : ""}>
                                <Link
                                  to={`/customerid/${Cid}`}
                                  onClick={() => tabsChange(Cid)}
                                >
                                  {Cid && typeof Cid === 'object' ? JSON.stringify(Cid) : Cid}
                                </Link>
                              </td>
                            ) : tdIndex === viewBtn ? (
                              <td>
                                <button
                                  className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                  id="onViewBtn"
                                  onClick={() => onViewBtn(item[0])}
                                >
                                  <p>{BtnName}</p>
                                </button>
                              </td>
                            ) : tdIndex === cancelBtn ? (

                              <td>
                                <button
                                  className={`${tdIndex === cancelBtn && item[13] === "INIT" && item[10] === "WITHDRAW" ? 'CMS-btn CMS-btnSecondary active CMS-btnSmall pointer' : 'disable-button'} CMS-btn CMS-btnSecondary active CMS-btnSmall pointer`}
                                  id="onCancelBtn"
                                  onClick={() => onCancel(item[0])}
                                >
                                  <p>{CancelbtnName}</p>
                                </button>
                              </td>
                            ) : (
                              <td
                                className={styles ? styles[tdIndex] : ""}
                                key={generateKey(tdIndex)}
                              >
                                {Cid && typeof Cid === 'object' ? JSON.stringify(Cid) : Cid}
                              </td>
                            )
                          )}
                        </tr>
                      );
                    })
                  ) : loader ? (
                    <tr>
                      <td colSpan={31}>
                        <Loading isLoader={true} />
                      </td>
                    </tr>
                  ) : (
                    <tr className='CMS-msgBox'>
                      <td colSpan={100} className='CMS-msgBox-container text-center'>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tabStack: state.sidebar.tabStack,
  };
};

export default connect(mapStateToProps)(Table);
