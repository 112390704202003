import React from 'react'
import { Switch, Route } from 'react-router-dom';
import SportsReport from '../Reports/sportsReport/SportsReport';
import CustomerReport from '../Reports/customerReport/CustomerReport';
import PaymentReport from '../Reports/paymentReport/PaymentReport';
import ManualReport from '../Reports/manualReport/ManualReport';
import ActivePlayerReport from '../Reports/activePlayerReport/ActivePlayerReport';
import MultipleDeposit from '../Reports/mutipleDepositReport/MultipleDeposit';
import ReferFriend from '../Reports/referFriend/ReferFriend';
import CampaignURL from '../Campaign/CampaignURL';
import ReportDetails from '../Reports/ReportDetails';
import Campaign from '../Campaign/Campaign';
import Channel from '../Channels/Channel';
import PrimaryPlayer from '../primaryplayer/PrimaryPlayer';
import SubAffiliate from '../Affiliate/SubAffiliate';


const NoPermissionRoutes = () => {
    return (
        <Switch>
            <Route
                path="/report/sports"
                render={(props) => (
                    <SportsReport {...props} />
                )}
            />
            <Route
                path="/report/customer"
                render={(props) => (
                    <CustomerReport {...props} />
                )}
            />
            <Route
                path="/report/payment"
                render={(props) => (
                    <PaymentReport {...props} />
                )}
            />
            <Route
                path="/report/manual"
                render={(props) => (
                    <ManualReport {...props} />
                )}
            />
            <Route
                path="/report/active"
                render={(props) => (
                    <ActivePlayerReport {...props} />
                )}
            />
            <Route
                path="/report/deposit"
                render={(props) => (
                    <MultipleDeposit {...props} />
                )}
            />
            <Route
                path="/report/referfriend"
                render={(props) => (
                    <ReferFriend {...props} />
                )}
            />
            <Route
                path="/campaignUrls"
                render={(props) => <CampaignURL {...props} />}
            />
            <Route
                path="/campaign"
                render={(props) => <Campaign {...props} />}
            />
            <Route
                path="/channel"
                render={(props) => <Channel {...props} />}
            />
            <Route
                path="/customerid/:id"
                render={(props) => (
                    <PrimaryPlayer {...props} />
                )}
            />
            <Route
                path="/subaffiliate"
                render={(props) => (
                    <SubAffiliate {...props} />
                )}
            />
            <Route
                path="/bisport"
                render={(props) => (
                    <ReportDetails {...props} />
                )}
            />

        </Switch>

    )
}

export default NoPermissionRoutes