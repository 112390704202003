import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePaymentMethods } from '../PaymentSearch/paymentSearchSlice';

const MethodDDL = ({ options, selectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const DdlCloseRef = useRef(null);
    const [isToggled, setIsToggled] = useState({});
    const dispatch = useDispatch()

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (id) => {
        setIsToggled(prevState => ({ ...prevState, [id]: !prevState[id] }));
        const payload = {
            isActive: !isToggled[id],
            paymentProvideId: id
        }
        dispatch(updatePaymentMethods(payload))

    };
    const handleClickOutside = (e) => {
        if (DdlCloseRef.current && !DdlCloseRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const initialToggleState = {};
        options.forEach(option => {
            initialToggleState[option.payment_provider_id] = option.isActive;
        });
        setIsToggled(initialToggleState);
    }, [options]);

    return (
        <>
            <div className="CMS-formControl">
                <div
                    className={"CMS-dropdown CMS-brands-dropdown" + (isOpen ? " active" : "")}
                    ref={DdlCloseRef}
                >
                    <div
                        className="CMS-dropdown-btn"
                        onClick={handleButtonClick}
                        style={{ overflowX: "hidden", width: "100%" }}
                    >
                        Select Payment Methods
                    </div>
                    <div className="CMS-dropdown-menu CMS-form-group">
                        {options.map((item, ind) => (
                            <label
                                className="d-flex justify-content-start align-items-center ddl-item-hover"
                                style={{ height: "30px", whiteSpace: "nowrap" }}
                            >
                                <div className="CMS-toggleSwitch">
                                    <input
                                        id={`toggleActive${ind}`}
                                        name={`toggleActive${ind}`}
                                        value={item.payment_provider_id}
                                        checked={isToggled[item.payment_provider_id]}
                                        type="checkbox"
                                        onChange={() => handleOptionClick(item.payment_provider_id)}
                                    />
                                    <label htmlFor={`toggleActive${ind}`}></label>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {item.payment_provider_id}
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MethodDDL;