import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi, paymentSearchApi, playerapi } from "../../sharedfiles/EndpointConfig";
import { cleanObject, messageEnhancer, toaster } from "../../sharedfiles/helper";
export const PaymentHistorySlice = createSlice({
  name: "paymentHistorySlice",
  initialState: {
    playerData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    userid: "",
    paymentData: [],
    manauldata: [],
    snackbar: false,
    addmakeid: 0,
    recordsShow: [1, 25],
    playerDetails: [],
    isVisible: false,
    showPayments: true,
    userinfo: [],
    withdrawBonus: [],
    initialPaymentHistoryPage: 1,
    paymentHistoryItems: 25,
    paymentHistoryUserData: [],
    paymentHistoryRecordShow: [1, 25],
    isHistoryVisible: false,
  },
  reducers: {
    setPaymentHistorySearch: (state, action) => {
      state.paymentData = action.payload;
    },
    resetSearch: (state, action) => {
      state.paymentData = [];
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setMakeManual: (state, action) => {
      state.manauldata = action.payload;
    },
    setaddMakeManualId: (state, action) => {
      state.addmakeid = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setPlayerData: (state, action) => {
      state.playerData = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userinfo = action.payload;
    },
    setPlayerDetails: (state, action) => {
      state.playerDetails = action.payload;
    },
    setIsVisisble: (state, action) => {
      state.isVisible = action.payload;
    },
    setShowPayments: (state, action) => {
      state.isVisible = action.payload;
    },
    setWithdrawBonus: (state, action) => {
      state.withdrawBonus = action.payload;
    },
    setInitialPaymentHistoryPage: (state, action) => {
      state.initialPaymentHistoryPage = action.payload;
    },
    setPaymentHistoryItems: (state, action) => {
      state.paymentHistoryItems = action.payload;
    },
    setPaymentHistoryRecordShow: (state, action) => {
      state.paymentHistoryRecordShow = action.payload;
    },
    setPaymentHistoryUserData: (state, action) => {
      state.paymentHistoryUserData = action.payload;
    },
    setIsHistoryVisible: (state, action) => {
      state.isHistoryVisible = action.payload;
    },
  },
});
export const {
  setPaymentHistorySearch,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setSnackbar,
  setMakeManual,
  setaddMakeManualId,
  setRecordsShow,
  setPlayerData,
  setUserInfo,
  resetSearch,
  setPlayerDetails,
  setIsVisisble,
  setShowPayments,
  setWithdrawBonus,
  setBetPaymentsOption,
  setInitialPaymentHistoryPage,
  setPaymentHistoryItems,
  setPaymentHistoryUserData,
  setPaymentHistoryRecordShow,
  setIsHistoryVisible,
} = PaymentHistorySlice.actions;

export const getPaymentHistorytable =
  (pagenumbervalue, itemperpagevalue, params, activeAll) =>
    (dispatch) => {
      let obj = cleanObject(params);
      var url =
        `${paymentSearchApi.getPaymentSearch}` +
        "?pageNumber=" +
        pagenumbervalue +
        "&itemsPerPage=" +
        itemperpagevalue;
      apiCaller(url, "POST", obj)
        .then((response) => {
          const data = {
            ...response.data,
            ["data"]: response?.data?.data?.map(item => {
              return {
                ...item, ["userId"]: `${item.userId}`
              }
            })
          }
          dispatch(setPaymentHistoryUserData(data))
          dispatch(setIsHistoryVisible(true));

        })
        .catch(
          (error) => console.log(error)
          // dispatch(setViewTable(true))
        );
    };


export const getPaymentHistorytableList =
  (
    pagenumbervalue,
    itemperpagevalue,
    { payment = "ALL", id, status = "ALL", paymentApprovalType = "ONLINE", manualAdjustment }
  ) =>
    (dispatch) => {
      //dispatch(resetSearch());
      var obj = {
        paymentType: payment,
        status: status,
        paymentApprovalType: paymentApprovalType,
        manualAdjustment: manualAdjustment
      };

      if (id !== undefined) {
        var url =
          `${playerapi.getPaymentHistory}` +
          id +
          "?&pageNumber=" +
          pagenumbervalue +
          "&itemsPerPage=" +
          itemperpagevalue;
        apiCaller(url, "POST", obj).then((response) => {
          if (response !== null && response) {
            if (response.status == 200) {
              // dispatch(setErrorMessage(response.message));
              dispatch(setPaymentHistorySearch(response.data));
              dispatch(setIsVisisble(true));
            }
          }
        });
      }
    };
export const getAddManualList = (id, params) => (dispatch) => {
  const type = params.paymentType;
  var obj = {
    adjustmentType: params.adjustmentType,
    amount: params.amount,
    description: params.description,
    paymentType: params.paymentType,
    offlinePayment: params.offlinePayment,
    addToWithdrawBucket: params.addToWithdrawBucket
  };
  var url = `${playerapi.getMakeManual}` + id + "/payment/manual";

  if (id !== "") {
    apiCaller(url, "POST", obj)
      .then((response) => {
        // if (response !== null && response) {
        dispatch(setShowPayments(false));
        if (type == "DEPOSIT" || type == "WITHDRAW" || type == "MV_DEPOSIT_TO_WTH_AMT") {
          response.status == 200 && toaster.success("SUCCESS");
          [400, 401, 403, 404, 500].includes(response.status) &&
            toaster.error("FAILED");
        }
        // response.status == 400 &&  dispatch(setErrorMessage(response.message));
        dispatch(setMakeManual(response.data));

        // }
      })
      .catch((err) => {
        const res = err.response
        toaster.error(messageEnhancer(res))
      });
  }
};

export const getWithdrawBonus = (params) => (dispatch) => {
  var obj = {
    amount: params.amount,
    transactionType: params.transactionType,
    userId: params.userId,
    withdrawalBonus: params.withdrawalBonus
  };
  var url = `${bonusApi.getWithdrawBonus}`
  apiCaller(url, "POST", obj)
    .then((res) => {
      if (res.status == 200) {
        dispatch(setWithdrawBonus(res.data))
        toaster.success("SUCCESS");
      }
    })
    .catch((err) => toaster.error("FAILED"))
}

export const getPlayerpayment = (params) => (dispatch) => {
  let url = `${playerapi.getGeneralDetail}` + params.user_id;
  if (params.user_id !== null) {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data) {
        if (response.status == 200) {
          dispatch(setPlayerData(response.data.userOtherInfo));

          dispatch(setUserInfo(response.data));
        }
      }
    });
  }
};

//dev.platform-ups.sportsit-tech.net:443/player/payment/TNX-100356

export const getEventDetails = (id) => (dispatch) => {
  const url = `${playerapi.getGeneralDetail}` + "payment/" + id;

  if (id !== "") {
    apiCaller(url, "GET")
      .then((response) => {
        dispatch(setPlayerDetails(response.data));
      })
      .catch((error) => {
        dispatch(setPlayerDetails([]));
      });
  }
};

export default PaymentHistorySlice.reducer;
