import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarGraph = ({ containerId, categories, description, graphData, monthly }) => {

    const mSeries = [graphData.m1, graphData.m2, graphData.m3, graphData.m4].flat();
    const qSeries = [graphData.q1, graphData.q2, graphData.q3, graphData.q4].flat();
    const options = {
        chart: {
            type: 'column',
            // height: '300',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: categories,
            crosshair: true,
            accessibility: {
                description: description,
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            valueSuffix: '',
        },
        plotOptions: {
            series: {
                pointWidth: 15,
                pointPadding: 0,
                dataLabels: {
                    align: 'left',
                    enabled: true,
                    rotation: 270,
                    x: 0,
                    y: 48,
                    color: '#000000',
                    style: {
                        textOutline: 'false',
                        fontWeight: 'normal',
                        fontSize: '9'
                    }
                },

            },
        },
        series: [
            {
                name: 'Bet Count',
                data: monthly ? mSeries.map(obj => obj?.betCount) : qSeries.map(obj => obj?.betCount),
                color: '#4AACC5',
            },
            // {
            //     name: 'GGR',
            //     data: monthly ? mSeries.map(obj => obj?.ggr) : qSeries.map(obj => obj?.ggr),
            //     color: '#1f487c'
            // },
            // {
            //     name: 'NGR',
            //     data: monthly ? mSeries.map(obj => obj?.ngr) : qSeries.map(obj => obj?.ngr),
            //     color: '#4F81BC'
            // },
            {
                name: 'Total Deposits',
                data: monthly ? mSeries.map(obj => obj?.totalDeposits) : qSeries.map(obj => obj?.totalDeposits),
                color: '#c0504e'
            },
            {
                name: 'Total Withdrawals',
                data: monthly ? mSeries.map(obj => obj?.totalWithdrawals) : qSeries.map(obj => obj?.totalWithdrawals),
                color: '#F79647'
            },
            {
                name: 'Total Registrations',
                data: monthly ? mSeries.map(obj => obj?.totalRegistrations) : qSeries.map(obj => obj?.totalRegistrations),
                color: '#604A7B'
            },
            {
                name: 'FTD',
                data: monthly ? mSeries.map(obj => obj?.firstTimeDeposits) : qSeries.map(obj => obj?.firstTimeDeposits),
                color: '#9BBB58'
            }
        ],
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{ id: containerId }}
            />
        </div>
    );
};

export default BarGraph;
