/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { tabManager } from "../sidebar/sidebarSlice";
import { Link } from "react-router-dom";
import {
  fetchPendingWithrawalData,
  setPaginationSecondValue,
  setPaginationFirstValue,
  setRecordsToShow,
  setIsVisible,
  setDocsData,
  updatePendingWithdraw,
} from "./pendingWithrawalSlice";
import {
  getDateTimeFormate,
  getBrowserDate,
  formatMoney,
  sortingFilter,
  Currency,
} from "../../sharedfiles/helper";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import VButtonGroup from "../Common/Buttons/VButtonGroup";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import PaymentsAuditor from "../Common/Dialog/PaymentsAuditor";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { COMMON_MSGS, Payment } from "../../sharedfiles/tooltipMsgs";
import { requestForDepositProof } from "../DepositProof/DepositproofSlice";

const sortState = {
  "userId": false,
  "createdDate": false,
  "status": false,
  "amount": false,
  key: "",
  sortType: "",
  keyType: "",
}

const PendingWithrawals = (props) => {
  const [data, setData] = useState([]);
  const [actionDialog, setActionDialog] = useState(false);
  const dispatch = useDispatch();
  const [statusType, setStatusType] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [sortables, setSortables] = useState({
    ...sortState
  })
  const [disabledButtons, setDisabledButtons] = useState([]);

  const callApi = (pageNumber = 1, itemsPerPage = 25) => {
    dispatch(setIsVisible(false));
    dispatch(fetchPendingWithrawalData(pageNumber - 1, itemsPerPage));
  };


  const actionDialogHandler = (item, value) => {
    setStatusType(value);
    setActionDialog(true);
    setData(item);
  };

  const headers = [
    [
      "TXN ID",
      "Customer ID",
      "Creation Date",
      "Payment Status",
      "Amount (₹)",
      "Created By",
      "Updated By",
    ],
  ];

  const exportPDF = () => {
    const title = "Pending Withdrawals Report";
    const styles = {
      3: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  }
  const updateTableToDocs = (key = "", sortType = "", keyType = "") => {
    if (Object.keys(props.pendingWithrawal).length > 0) {
      const data =
        props.pendingWithrawal &&
        Array.isArray(props.pendingWithrawal?.data) &&
        props.pendingWithrawal?.data.length > 0 &&
        sortingFilter(props.pendingWithrawal?.data, { key, sortType, keyType }).map((p) => [
          p.id,
          p.userId,
          p.createdDate ? getBrowserDate(p.createdDate) : "-",
          p.status,
          formatMoney(p.amount),
          p.addedBy,
          p.updatedByUserName || "-",
          // getDecimalNumber(p.balance),
        ]);
      setPdfBody(data);
      const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []]
      setCsvData(csvData);
      dispatch(setDocsData(csvData))
    }
  }

  useEffect(() => {
    updateTableToDocs()
  }, [props.pendingWithrawal])


  useEffect(() => {
    const dataExist = Object.keys(props.pendingWithrawal).length > 0
    if (!dataExist) {
      callApi(props.paginationFirstValue, props.paginationSecondValue);
    } else {
      setCsvData(props.docsData)
    }
  }, []);



  const tabsChange = (item) => {
    props.dispatch(setUserId(item));
    props.dispatch(tabManager(+item, props.tabStack));
    localStorage.setItem("userId", item);
  };


  const handleSortFields = (key, keyType) => {
    const intializing = {
      ...sortState
    }

    const sortType = !sortables[key] ? "ASCENDING" : "DESCENDING"

    setSortables((prevState) => {
      return {
        ...intializing,
        [key]: !prevState[key],
        key: key,
        sortType: sortType,
        keyType: keyType
      }
    });
    updateTableToDocs(key, sortType, keyType);

  }
  const sortKeys = {
    key: sortables.key,
    sortType: sortables.sortType,
    keyType: sortables.keyType
  }

  const auditActionHandler = (message) => {
    const payload = {
      id: data.id,
      message: message,
      status: statusType === "Accept" ? "APPROVED" : "DECLINED",
      userId: data.userId,
    }
    dispatch(updatePendingWithdraw(payload));
  }

  const onDepoProof = (id) => {
    dispatch(requestForDepositProof(id))
    setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, id]);
    setTimeout(() => {
      setDisabledButtons((prevDisabledButtons) => prevDisabledButtons.filter((buttonId) => buttonId !== id));
    }, 3000);
  }
  return (
    <>
      <Dialog__Main title={"Action needed"} dOpen={actionDialog} dClose={() => setActionDialog(false)}>
        <PaymentsAuditor
          btnType={[statusType]}
          data={data}
          finish={(msg) => auditActionHandler(msg)}
          close={() => setActionDialog(false)}
          amountKey={"amount"} />
      </Dialog__Main>

      <div className='CMS-tabContent'>
        <div className='CMS-filter-result' id='result'>
          <div className='CMS-pagination '>
            <Vpagination
              data={props.pendingWithrawal.data}
              totalRecords={props.pendingWithrawal.totalRecords}
              paginationFirstValue={props.paginationFirstValue}
              paginationSecondValue={props.paginationSecondValue}
              setPaginationFirstValue={setPaginationFirstValue}
              setPaginationSecondValue={setPaginationSecondValue}
              recordsShow={props.recordsToShow}
              setRecordsShow={setRecordsToShow}
              isVisible={props.isVisible}
              setIsVisible={setIsVisible}
              csvData={csvData}
              exportPDF={exportPDF}
              callApi={callApi}
              csvFileName={"Pending Withdrawals Report"}
            />
          </div>
          <div className='CMS-box'>
            <div className="CMS-table CMS-table-triped">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{PAYMENT_CONSTANTS.th_txnId}</p>
                        <Tooltips info={Payment.txn_Id} />
                      </div>
                    </th>

                    <th className="pointer" onClick={() => handleSortFields("userId", "num")}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.header_CID}</p>
                        <Tooltips info={COMMON_MSGS.cid} />
                        <span
                          className='material-icons md-18'
                          data-icon={
                            sortables.userId ? "north" : "south"
                          }
                        ></span>
                      </div>
                    </th>

                    <th className="pointer" onClick={() => handleSortFields("createdDate", "date")}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.creationDate}</p>
                        <Tooltips info={COMMON_MSGS.createdDate} />
                        <span
                          className='material-icons md-18'
                          data-icon={
                            sortables.createdDate ? "north" : "south"
                          }
                        ></span>
                      </div>
                    </th>

                    <th className="pointer" onClick={() => handleSortFields("status", "str")}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentStatus}</p>
                        <Tooltips info={Payment.paymentSts} />
                        <span
                          className='material-icons md-18'
                          data-icon={
                            sortables.status ? "north" : "south"
                          }
                        ></span>
                      </div>
                    </th>

                    <th className="pointer" onClick={() => handleSortFields("amount", "num")}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{`Amount ${Currency()}`}</p>
                        <Tooltips info={COMMON_MSGS.Amount} />
                        <span
                          className='material-icons md-18'
                          data-icon={
                            sortables.amount ? "north" : "south"
                          }
                        ></span>
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.createdBy}</p>
                        <Tooltips info={COMMON_MSGS.createdBy} />
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.updatedBy}</p>
                        <Tooltips info={COMMON_MSGS.updatedBy} />
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                        <Tooltips info={COMMON_MSGS.Action} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.pendingWithrawal?.data !== undefined &&
                    props.pendingWithrawal?.data.length ? (
                    sortingFilter(props.pendingWithrawal?.data, { ...sortKeys }).map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.id}</td>

                          <td>
                            <Link
                              to={`/customerid/${item.userId}`}
                              onClick={() => tabsChange(item.userId)}
                            >
                              {item.userId}
                            </Link>
                          </td>

                          <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>

                          <td>
                            {item.status}
                          </td>

                          <td>{formatMoney(item.amount)}</td>
                          <td>{item.addedBy}</td>
                          <td>{item.updatedByUserName || '-'}</td>
                          <td>
                            {
                              item.status === "INIT" ?
                                <div style={{
                                  justifyContent: "center",
                                  display: "grid",
                                }}>
                                  <button
                                    onClick={() => actionDialogHandler(item, "Accept")}
                                    className='CMS-btnStatus CMS-statusActive pointer'
                                    id='DepositAcceptbtn'
                                  >
                                    Accept
                                  </button>
                                  <div className="mb-10" ></div>
                                  <button
                                    onClick={() => actionDialogHandler(item, "Decline")}
                                    className='CMS-btnStatus CMS-statusClosed pointerr'
                                    id='DepositDeclinebtn'
                                  >
                                    Decline
                                  </button>
                                  <div className="mb-10" ></div>
                                  <button
                                    onClick={() => onDepoProof(item.id)}
                                    className={`CMS-btn CMS-btnSecondary active CMS-btnSmall pointer ${disabledButtons.includes(item.id) ? 'disable-button' : ''}`}
                                    id={`depositProofbtn_${item.id}`}
                                    disabled={disabledButtons.includes(item.id)}
                                  >
                                    Deposit Proof
                                  </button>
                                </div>
                                : (
                                  item.msg
                                )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <No__Data__Found colspan={100} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    paginationFirstValue: state.pendingWithrawal.paginationFirstValue,
    paginationSecondValue: state.pendingWithrawal.paginationSecondValue,
    pendingWithrawal: state.pendingWithrawal.pendingWithrawal,
    userId: state.playersearch.userId,
    recordsToShow: state.pendingWithrawal.recordsToShow,
    isVisible: state.pendingWithrawal.isVisible,
    docsData: state.pendingWithrawal.docsData,
    tabStack: state.sidebar.tabStack,
    disableBtn: state.DepositproofSlice.disableBtn
  };
}

export default connect(mapStateToProps)(PendingWithrawals);
