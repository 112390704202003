import React, { useState, useRef, createRef, useEffect } from "react";

const MultiSelectDDL = (props) => {
  const { setIds, name, betDDL, sportDDL } = props;
  const DdlCloseRef = useRef(null);
  const selectAllRef = useRef(null);
  const myRefs = useRef([]);
  const [expandDdl, setExpandDdl] = useState(false);
  const [selectedItemNames, setSelectedItemNames] = useState([]);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [allChecks, setAllChecks] = useState({});
  const [isAllSelect, setIsAllSelect] = useState(true);
  const [filterData, setFilterData] = useState("");
  const handleExpandDdl = () => {
    setExpandDdl(!expandDdl);
  };



  const handleSelectAll = (e) => {

    // name !== "sportsSelected" && props.setClear();
    const Ids = [];
    const Names = [];
    const allChecks = {};

    if (sportDDL ? !isAllSelect : isAllSelect) {
      selectAllRef.current.checked = true;
      props.options.map((item) => {
        Ids.push(item.id);
        Names.push(item.name);
        allChecks[item.id] = true;
      });
    } else {
      props.options.map((item) => {
        allChecks[item.id] = false;
      });
    }
    setIsAllSelect(!isAllSelect);
    setSelectedItemNames(Names);
    setSelectedItemIds(betDDL ? Names : Ids);
    setAllChecks(allChecks);
    props.setSelected(Names);
    setIds(betDDL ? Names : Ids);
    // props.setIds(Ids);
  };

  const handleOptionChange = (e) => {

    // name !== "sportsSelected" && props.setClear();
    const { name, value, checked } = e.current;

    setAllChecks({ ...allChecks, [value]: checked });

    if (checked == false) {
      const names = selectedItemNames.filter((item) => item != name);
      const ids = selectedItemIds.filter((item) => item != value);
      setSelectedItemNames(names);
      setSelectedItemIds(betDDL ? names : ids);
      props.setSelected(names);
      setIds(betDDL ? names : ids);
      // props.setIds(ids);
    } else {
      setSelectedItemNames([...selectedItemNames, name]);
      betDDL
        ? setSelectedItemIds([...selectedItemNames, name])
        : setSelectedItemIds([...selectedItemIds, +value]);
      props.setSelected([...props.selectedOnce, name]);
      betDDL
        ? setIds([...props.selectedOnce, name])
        : setIds([...props.ids, +value]);
      // props.setIds([...props.selectedIds, +value]);
    }
    selectAllRef.current.checked = false;
    setIsAllSelect(sportDDL ? false : true);
  };

  const handleClickOutside = (e) => {
    if (DdlCloseRef.current && !DdlCloseRef.current.contains(e.target)) {
      setExpandDdl(false);
    }
  };


  useEffect(() => {
    let mounting = true;

    if (mounting) {
      const ids = [];
      const names = [];
      const actives = {};
      if (props.options.length > 0) {
        props.options.map((item) => {
          if (props.deafaultValues && props.deafaultValues.includes(item.id)) {
            if (!ids.includes(item.id) && !names.includes(item.name)) {
              betDDL ? (actives[item.name] = true) : (actives[item.id] = true);
              ids.push(item.id);
              names.push(item.name);
            }
          }
        });
        // setAllChecks(allChecks);
      }

      props.setSelected([...ids]);
      // setIds && setIds([...ids]);
      betDDL
        ? setSelectedItemIds([...selectedItemNames, ...names])
        : setSelectedItemIds([...selectedItemIds, ...ids]);
      setSelectedItemNames([...new Set([...selectedItemNames, ...names])]);

      setAllChecks({ ...allChecks, ...actives });
    }

    return () => (mounting = false);
  }, [props.deafaultValues]);

  // useEffect(() => { }, [selectedItemIds]);

  useEffect(() => {
    let mounting = true;

    if (mounting) {
      if (props.options.length > 0) {
        myRefs.current = props.options.map(
          (element, i) => myRefs.current[i] ?? createRef()
        );
      }
    }

    return () => (mounting = false);
  }, [props.options]);

  useEffect(() => {
    let mounting = true;

    if (mounting) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => (mounting = false);
  }, []);

  useEffect(() => {
    let mounting = true;

    if (mounting) {
      if (props.clearAll == true) {
        setSelectedItemNames([]);
        setSelectedItemIds([]);
        setAllChecks({});
        setIsAllSelect(true);
        selectAllRef.current.checked = false;
        props.setSelected([]);
        setIds([]);
        // props.setIds([]);
      }
    }

    return () => (mounting = false);
  }, [props.clearAll]);

  return (
    <>
      <div className="CMS-formControl" name="permissionName">
        <div
          className={
            "CMS-dropdown CMS-brands-dropdown" + `${expandDdl ? " active" : ""}`
          }
          ref={DdlCloseRef}
        >
          <div
            className="CMS-dropdown-btn"
            onClick={handleExpandDdl}
            style={{ overflowX: "hidden", width: "100%" }}
          >
            <div
              // className='CMS-page-tabs'
              style={{ with: "80%", overflowX: "hidden" }}
            >
              {sportDDL ? (isAllSelect
                ? "All Selected" : selectedItemNames.length > 0
                  ? selectedItemNames.map((item, ind) =>
                    ind === 0 ? `${item}` : `, ${item}`
                  )
                  : "Select ") : (isAllSelect
                    ? selectedItemNames.length > 0
                      ? selectedItemNames.map((item, ind) =>
                        ind === 0 ? `${item}` : `, ${item}`
                      )
                      : "Select "
                    : "All Selected")
              }

            </div>
          </div>
          <div
            className="CMS-dropdown-menu CMS-form-group "
            style={{
              height: "170px",
              overflowX: "scroll",
              overflowY: "scroll",
            }}
          >
            <input
              type="text"
              placeholder="Search"
              id="myInput"
              value={filterData}
              // style={{
              //   border: "none",
              //   borderBottom: "0.5px solid black",
              //   borderRadius: "0px",
              // }}
              onChange={(e) => setFilterData(e.target.value)}
            />
            <label
              className="d-flex justify-content-start align-items-center ddl-item-hover"
              style={{ height: "30px" }}
            // htmlFor="selectAll"
            >
              <input
                id="selectAll"
                className="CMS-checkbox"
                type="checkbox"
                name="All Selected"
                onChange={(e) => handleSelectAll(e)}
                checked={sportDDL ? isAllSelect : !isAllSelect}
                ref={selectAllRef}
              />
              <span className="SB-checkboxLabel">Select All</span>
            </label>

            {props?.options && Array.isArray(props?.options) && props?.options?.length > 0 && props?.options?.filter((item) =>
              item.name?.toUpperCase().includes(filterData?.toUpperCase())
            )
              .map((item, ind) => {
                const { id } = item;
                return (
                  <label
                    key={item.id}
                    className="d-flex justify-content-start align-items-center ddl-item-hover"
                    // htmlFor={item.id}
                    style={{ height: "30px", whiteSpace: "nowrap" }}
                  >
                    <input
                      id={item.id}
                      className="CMS-checkbox"
                      type="checkbox"
                      name={item.name}
                      checked={allChecks[id]}
                      value={item.id}
                      ref={myRefs.current[ind]}
                      onClick={() => handleOptionChange(myRefs.current[ind])}
                    />
                    {item.name}
                    {/* <span className='SB-checkboxLabel'>{item.name}</span> */}
                  </label>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelectDDL;
