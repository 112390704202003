import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import BrandTable from "./createbrandtable";
import { getClients } from "../CreateClient/createclientslice";
import {
  addBrand,
  getAllBrand,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
} from "./createbrandslice";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { currency } from "../../components/Common/data/CreateBrand/createbrandData";
import { BRAND_CONFIG_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { Typography } from "@material-ui/core";
import Tooltips from "../Common/Tooltip";
import { BrandConfig } from "../../sharedfiles/tooltipMsgs";

const CreateBrand = (props) => {
  const [brandName, setBrandName] = useState("");
  const [brandCurrency, setBrandCurrency] = useState("");
  const [nameError, setNameError] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientError, setClientError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false)
  const dispatch = useDispatch();
  const pageFirstVal = useSelector(
    (state) => state.createbrand.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.createbrand.paginationSecondValue
  );
  const recordsToShow = useSelector((state) => state.createbrand.recordsShow);
  const listBrand = useSelector((state) => state.createbrand.listBrand);

  const handleChangeBrandName = (e) => {
    if (e.target.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
    setBrandName(e.target.value);
  };

  const handleChangeBrandCurrency = (e) => {
    if (e.target.value === "") {
      setCurrencyError(true);
    } else {
      setCurrencyError(false);
    }
    setBrandCurrency(e.target.value);
  };

  const handleChangeClient = (e) => {
    e.target.value === "" ? setClientError(true) : setClientError(false);

    if (e.target.value !== "") {
      setClientName(e.target.value);
    }
  };

  useEffect(() => {
    props.dispatch(
      getAllBrand(props.paginationFirstValue - 1, props.paginationSecondValue)
    );
    props.dispatch(
      getClients(props.paginationFirstValue - 1, props.paginationSecondValue)
    );
  }, []);

  const headers = [
    [
      "Brand Name",
      "Brand ID",
      "Brand Currency",
      "Client Name",
      "created On",
      "Created By",
    ],
  ];

  const exportPDF = () => {
    const title = "Create Brand Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (Object.keys(props.listBrand).length > 0) {
      const data =
        props.listBrand &&
        props.listBrand.data.map((p) => [
          p.name,
          p.brandId,
          p.currency,
          p.clientName,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.createdBy,
        ]);

      setPdfBody(data);
      setCsvData([headers[0], ...data]);
    }
  }, [props.listBrand]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(getAllBrand(pageNumber - 1, itemsPerPage));
  };
  const submitBrand = () => {
    if (brandCurrency != "" && brandName != "" && clientName != "") {
      const brandData = {
        brandCurrency: brandCurrency,
        brandName: brandName,
        clientId: clientName,
      };
      props.dispatch(addBrand(brandData));
      setTimeout(() => {
        props.dispatch(
          getAllBrand(
            props.paginationFirstValue - 1,
            props.paginationSecondValue
          )
        );
      }, 500);
    }
    if (brandCurrency === "") {
      setCurrencyError(true);
    }
    if (brandName === "") {
      setNameError(true);
    }
    if (clientName === "") {
      setClientError(true);
    }
  };
  const handleinfoOpen = () => {
    setInfoOpen(true)
  }

  const handleinfoClose = () => {
    setInfoOpen(false)
  }


  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-12'>
                <div className='CMS-cardBox mb-0'>
                  <div className='row'>
                    <div className='col-lg-2 col-xl-2'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {BRAND_CONFIG_CONSTANTS.header_brandName}
                          &nbsp;&nbsp;
                          <Tooltips info={BrandConfig.Brand_Name} />
                        </div>
                        <div className='CMS-formControl'>
                          <input
                            type='text'
                            id='brandname'
                            name='brandName'
                            value={brandName}
                            onChange={handleChangeBrandName}
                          />

                          {nameError && (
                            <div className='text-danger'>
                              {BRAND_CONFIG_CONSTANTS.brandName_required}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-2 col-xl-2'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {BRAND_CONFIG_CONSTANTS.header_clientName}
                          &nbsp;&nbsp;
                          <Tooltips info={BrandConfig.Brand_Client_Name} />
                        </div>
                        <div className='CMS-formControl'>
                          <div className='CMS-select '>
                            <select
                              name='clientName'
                              id='brandclientname'
                              value={clientName}
                              onChange={handleChangeClient}
                              className="pointer"
                            >
                              <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                              {props.clientList?.data !== undefined &&
                                props.clientList?.data.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.id}
                                      id={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {clientError && (
                          <div className='text-danger'>
                            {BRAND_CONFIG_CONSTANTS.clientName_required}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-2 col-xl-2'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {BRAND_CONFIG_CONSTANTS.header_BrandCurrency}
                          &nbsp;&nbsp;
                          <Tooltips info={BrandConfig.Brand_Currency} />
                        </div>
                        <div className='CMS-formControl'>
                          <div className='CMS-select '>
                            <select
                              name='brandCurrency'
                              id='brandcurrency'
                              value={brandCurrency}
                              onChange={handleChangeBrandCurrency}
                              className="pointer"
                            >
                              <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                              {currency.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.value}
                                    id={item.id}
                                  >
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {currencyError && (
                            <div className='text-danger'>
                              {BRAND_CONFIG_CONSTANTS.brandCurrency_required}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-btnContainer'>
                  <button
                    id='btnBrandCreate'
                    style={{ width: "200px" }}
                    className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                    type='button'
                    onClick={submitBrand}
                  >
                    {COMMON_CONSTANTS.create}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={listBrand?.data}
                totalRecords={listBrand?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Create Brand Report"}
              />
            </div>
            <BrandTable />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    clientList: state.createclient.clientList,
    paginationFirstValue: state.createbrand.paginationFirstValue,
    paginationSecondValue: state.createbrand.paginationSecondValue,
    isVisible: state.createbrand.isVisible,
    listBrand: state.createbrand.listBrand,
  };
};
export default connect(mapStateToProps)(CreateBrand);
