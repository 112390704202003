import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  formatMoney,
  generateKey,
  getDateTimeFormate,
  sortingFilter,
} from "../../sharedfiles/helper";
import SportsBox from "./SportsBox";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { BONUS_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants"
import Tooltips from "../Common/Tooltip";
import { Bonusing, COMMON_MSGS } from "../../sharedfiles/tooltipMsgs"
import Table from "../Common/table/Table";
import { getBonusAwardDetail, setRecordsShow, setUserFirstPage, setUserIsVisible, setUserSecondPage } from "./createbonusnewslice";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
const sortState = {
  "bonusType": false,
  "startDate": false,
  "validUntil": false,
  "wagerBonus": false,
  "instantBonus": false,
  "bonusPercentage": false,
  "depositCriteria": false,
  "wagerCriteria": false,
  "maximumTotalBonus": false,
  // "maxBonus" : false, 
  "minDeposit": false,
  "maxBonus": false,
  "optIn": false,
  "oddsThreshold": false,
  "unlockOddsThreshold": false,
  "applicableType": false,
  "unlockApplicableType": false,
  "tags": false,
  "brand": false,
  "createdDate": false,
  "updatedDate": false,
  "bonusStatus": false,
  "numberOfLegs": false,
  "unlockNumberOfLegs": false,
  "minStake": false,
  "bonusPayoutPercentage": false,
  "recReferrerPercentage": false,
  "refereePerentage": false,
  "referrerPercentage": false,
  key: "",
  sortType: "",
  keyType: "",
}


const CreateBonusTable = (props) => {
  const dispatch = useDispatch()
  const [sortables, setSortables] = useState({
    ...sortState
  })
  const [viewSports, setViewSports] = useState(false);
  const [selectedSports, setSelectedSports] = useState([]);
  const [viewUserTab, setViewUserTab] = useState(false)
  const [tableData, setTableData] = useState('')
  const [id, setId] = useState('')
  const bonusAwardData = useSelector((p) => p.bonus.bonusAwardData)
  const pageOneValue = useSelector((p) => p.bonus.userPaginationFirstValue)
  const pageSecondValue = useSelector((p) => p.bonus.userPaginationSecondValue)
  const recordsShow = useSelector((p) => p.bonus.recordsShow)
  const isVisible = useSelector((p) => p.bonus.userIsVisible)
  const [csvData, setCsvData] = useState([])
  const [pdfBody, setPdfBody] = useState([])
  const [unlockSports, setUnlockSports] = useState([])
  const [unlockSportView, setUnlockSportView] = useState(false)
  const [allowTagBox, setAllowTagBox] = useState(false);
  const [allowTagData, setAllowTagData] = useState([])
  const [denyTagBox, setDenyTagBox] = useState(false);
  const [denyTagData, setDenyTagData] = useState([]);
  const [providerNameBox, setProviderNameBox] = useState(false);
  const [providerName, setProviderName] = useState([]);
  const [gameNameBox, setGameNameBox] = useState(false);
  const [gameName, setGameName] = useState([]);
  const [unlockProviderBox, setUnlockProviderBox] = useState(false);
  const [unlockProvider, setUnlockProvider] = useState([]);
  const [unlockGameNameBox, setUnlockGameNameBox] = useState(false);
  const [unlockGameName, setUnlockGameName] = useState([]);
  // const [props.manageIDs, setManageIDs] = useState({
  //   id: '',
  //   bonusCode: '',
  //   bonusStatus: '',
  // });
  const [dateFilter, setDateFilter] = useState("");

  const handleSortFields = (key, keyType) => {
    const intializing = {
      ...sortState
    }

    const sortType = !sortables[key] ? "ASCENDING" : "DESCENDING"

    setSortables((prevState) => {
      return {
        ...intializing,
        [key]: !prevState[key],
        key: key,
        sortType: sortType,
        keyType: keyType
      }
    });
    props.updateTableToDocs(key, sortType, keyType);

  }
  const sortKeys = {
    key: sortables.key,
    sortType: sortables.sortType,
    keyType: sortables.keyType
  }

  const sportViewHandler = (sports) => {
    const sportsArray = sports && sports.length > 0 ?
      sports.split(",") : [];
    setSelectedSports(sportsArray);
    setViewSports(true);
  }

  const applicablesSortViewHandler = (sports) => {
    const sportsArray = sports && sports.length > 0 ?
      sports.split(",") : [];
    setUnlockSports(sportsArray);
    setUnlockSportView(true);
  }

  const tHeader = [
    [
      "Customer ID",
      "Bonus Awarded",
      "Bonus Awarded Date",
      "Bonus Type",
      "Expiry Date",
      "Bonus Code",
      "Transaction Amount",
      "Amount Won",
    ]
  ]
  const tInfo = [
    "Showing Customer ID",
    "Showing Awarded Amount",
    "Showing Awarded Date",
    "Showing Bonus Type",
    "Showing Expiry Date",
    "Showing Bonus Code",
    "Showing Transaction Amount",
    "Showing Amount Won",
  ]
  const update = () => {
    const tbody = bonusAwardData && bonusAwardData.data ?
      bonusAwardData.data.map((p) =>
        [
          p.customerId,
          p.awardedAmount,
          getDateTimeFormate(p.awardedDate),
          p.bonusType,
          getDateTimeFormate(p.bonusExpiry),
          p.bonusCode ? p.bonusCode : "N/A",
          (p.transactionAmount || p.transactionAmount === 0) ? p.transactionAmount : '-',
          (p.amountWon || p.amountWon === 0) ? p.amountWon : '-',
        ]) : []
    setPdfBody(tbody)
    const tbData = [tHeader[0], tInfo, ...tbody]
    const data = tbody && Array.isArray(tbody) && tbody.length > 0 ? [tHeader[0], ...tbody] : [tHeader[0], []]
    setCsvData(data)
    setTableData(tbData)
  }

  const userTabHandler = (id) => {
    dispatch(setUserIsVisible(true));
    setId(id)
    dispatch(getBonusAwardDetail(id, pageOneValue - 1, pageSecondValue))
    setViewUserTab(true)
  }
  useEffect(() => {
    update()
  }, [bonusAwardData])

  const exportPDF = () => {
    const title = "Bonus Awarded Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, tHeader, pdfBody, styles);
  }

  const callPageApi = (pageNumber, itemsPerPage) => {
    dispatch(setUserIsVisible(true));
    dispatch(getBonusAwardDetail(id, pageNumber - 1, itemsPerPage))
  }
  // const handleEditOpen = (item) => {
  //   setOpenEdit(true)
  //   setManageIDs(
  //     {
  //       id: item.id,
  //       bonusCode: item.bonusCode,
  //       bonusStatus: item.bonusStatus,
  //     }
  //   )
  // };
  const allowTagHandler = (tags) => {
    const allowTagArray = Array.isArray(tags) ? tags : (tags && tags.length > 0 ? tags.split(",") : []);
    setAllowTagData(allowTagArray);
    setAllowTagBox(true);
  }

  const denyTagHandler = (tags) => {
    const denyTagArray = Array.isArray(tags) ? tags : (tags && tags.length > 0 ? tags.split(",") : []);
    setDenyTagData(denyTagArray);
    setDenyTagBox(true);
  }


  const providerNameHandler = (name) => {
    const providerNameArray = Array.isArray(name)
      ? name
      : name && name.length > 0
        ? name.split(",")
        : [];

    setProviderName(providerNameArray);
    setProviderNameBox(true);
  };

  const gameNameHandler = (name) => {
    const gameNameArray = Array.isArray(name)
      ? name
      : name && name.length > 0
        ? name.split(",")
        : [];

    setGameName(gameNameArray);
    setGameNameBox(true);
  };

  const unlockProviderHandler = (provider) => {
    const unlockProviderArray = Array.isArray(provider)
      ? provider
      : provider && provider.length > 0
        ? provider.split(",")
        : [];

    setUnlockProvider(unlockProviderArray);
    setUnlockProviderBox(true);
  };

  const unlockGameNameHandler = (gameName) => {
    const unlockGameNameArray = Array.isArray(gameName)
      ? gameName
      : gameName && gameName.length > 0
        ? gameName.split(",")
        : [];

    setUnlockGameName(unlockGameNameArray);
    setUnlockGameNameBox(true);
  };



  return (
    <>
      <Dialog__Main
        title={"Edit"}
        dOpen={props.openEditBox}
        dClose={props.handleEditClose}
        width={"xs"}
        ids={"editAction"}
      >
        {props.adminBonusAction ?
          <>
            <div className='CMS-formLabel'>
              {"Actions"}
              &nbsp;&nbsp;
              <Tooltips info={"Showing Actions to be taken"} />
            </div>
            <div className='CMS-file-type CMS-select'>
              <select
                id={props.manageIDs.id}
                name={props.manageIDs.bonusCode}
                value={props.statusValue}
                onChange={(e) => props.handleActiveDeactive(e)}
              // disabled={props.manageIDs.bonusStatus == "EXPIRED"}
              >
                <option value=''>Select</option>
                <option
                  value={`activate${props.manageIDs.id}`}
                  selected={props.manageIDs.bonusStatus == "ACTIVE" && true}
                >
                  Activate
                </option>
                {/* <option
                              value={`expired${item.id}`}
                              selected={item.bonusStatus == "EXPIRED" && true}
                            >
                              Expired
                            </option> */}
                <option
                  value={`inactivate${props.manageIDs.id}`}
                  selectedDefault={props.manageIDs.bonusStatus == "IN_ACTIVE"}
                >
                  Inactivate
                </option>
              </select>
            </div></>
          : ''}

        <div className="mt-20">
          <Datepicker
            title={"Select End Date"}
            name={"expireDate"}
            value={dateFilter}
            // errorMsg={formErrors[item.name]}
            minDate={new Date()}
            // maxDate={maxDate[ind]}
            setValue={(date) =>
              props.handleValidDate(date)
            }
            ids={"expire"}
            info={"Showing Expired Date"}
            errorMsg={props.editDateErr && "End Date Should not be more than bonus Start Date"}
          />
        </div>
        <div className="d-flex justify-content-center align-content-center">
          <button
            onClick={(e) => props.onEditSubmit(e)}
            // onChange={(e) => props.handleActiveDeactive(e)}
            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${props.editSaveDisable ? 'disable-button' : ''}`}
            type='button'
            id='searchBetDetails'
          >
            Save
          </button>
        </div>

      </Dialog__Main>
      {
        viewSports &&
        <Dialog__Main
          title={"BONUS SPORTS"}
          dOpen={viewSports}
          dClose={setViewSports}
          width={"sm"}
          dHeight={"280px"}
          ids={"editUserBox"}
        >
          <SportsBox sports={selectedSports} />
        </Dialog__Main>
      }
      {
        unlockSportView &&
        <Dialog__Main
          title={"UNLOCKED APPLICABLE SPORTS"}
          dOpen={unlockSportView}
          dClose={setUnlockSportView}
          width={"sm"}
          dHeight={"280px"}
          ids={"editUserBox"}
        >
          <SportsBox sports={unlockSports} />
        </Dialog__Main>
      }
      {
        allowTagBox &&
        <Dialog__Main
          title={"Allow Tags"}
          dOpen={allowTagBox}
          dClose={setAllowTagBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"allowtagBox"}
        >
          <SportsBox title="Allow Tags" sports={allowTagData} />
        </Dialog__Main>
      }
      {
        denyTagBox &&
        <Dialog__Main
          title={"Deny Tags"}
          dOpen={denyTagBox}
          dClose={setDenyTagBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"denyTagBox"}
        >
          <SportsBox title='Deny Tags' sports={denyTagData} />
        </Dialog__Main>
      }

      {providerNameBox && (
        <Dialog__Main
          title={"Provider Name"}
          dOpen={providerNameBox}
          dClose={setProviderNameBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"providerNameBox"}
        >
          <SportsBox isArray sports={providerName} title="Provider Names" />
        </Dialog__Main>
      )}
      {gameNameBox && (
        <Dialog__Main
          title={"Game Name"}
          dOpen={gameNameBox}
          dClose={setGameNameBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"gameNameBox"}
        >
          <SportsBox isArray sports={gameName} title="Game Names" />
        </Dialog__Main>
      )}
      {unlockProviderBox && (
        <Dialog__Main
          title={"Unlock Provider"}
          dOpen={unlockProviderBox}
          dClose={setUnlockProviderBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"unlockProviderBox"}
        >
          <SportsBox isArray sports={unlockProvider} title="Unlock Providers" />
        </Dialog__Main>
      )}
      {unlockGameNameBox && (
        <Dialog__Main
          title={"Unlock Game Name"}
          dOpen={unlockGameNameBox}
          dClose={setUnlockGameNameBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"unlockGameNameBox"}
        >
          <SportsBox isArray sports={unlockGameName} title="Unlock Game Names" />
        </Dialog__Main>
      )}

      <Dialog__Main
        title={"Users Tab"}
        dOpen={viewUserTab}
        dClose={setViewUserTab}
        dHeight={"280px"}
        ids={"userTab"}
        width={"700px"}
      >
        <div className='CMS-filter-result' id='result'>
          <div className="CMS-pagination">
            <Vpagination
              data={bonusAwardData.data}
              totalRecords={bonusAwardData.totalRecords}
              paginationFirstValue={pageOneValue}
              paginationSecondValue={pageSecondValue}
              setPaginationFirstValue={setUserFirstPage}
              setPaginationSecondValue={setUserSecondPage}
              recordsShow={recordsShow}
              setRecordsShow={setRecordsShow}
              isVisible={isVisible}
              setIsVisible={setUserIsVisible}
              csvData={csvData}
              exportPDF={exportPDF}
              callApi={callPageApi}
              csvFileName={"Bonus Awarded Report"}
              reloadRequired={false}
            />
          </div>
        </div>
        <Table tableData={tableData} linkIndex={0} />
      </Dialog__Main>
      <div className='CMS-box'>
        <div className='CMS-table CMS-table-triped'>
          {/* {Object.keys(props.bonusData).length > 0 && ( */}
          <table>
            <thead>
              <tr style={{ whiteSpace: "nowrap" }}>
                <th className="pointer" onClick={() => handleSortFields("bonusType", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.bonus_type}</p>
                    <Tooltips info={Bonusing.bonusType} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "bonusType" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Bonus Code</p>
                    <Tooltips info={Bonusing.bonusCode} />
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("maxBonus", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Bonus</p>
                    <Tooltips info={Bonusing.bonus} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "maxBonus" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("wagerBonus", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> {BONUS_CONSTANTS.wager_Bonus}</p>
                    <Tooltips info={Bonusing.Wager_Bonus} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "wagerBonus" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("instantBonus", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.instant_Bonus}</p>
                    <Tooltips info={Bonusing.instant_Bonus} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "instantBonus" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("maxWinAmount", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Max Win Amount</p>
                    <Tooltips info={Bonusing.maxWinAmnt} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "maxWinAmount" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("maximumTotalBonus", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Max Bonus</p>
                    <Tooltips info={Bonusing.maxBonus} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "maximumTotalBonus" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("rollOverMultiplier", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Rollover Times</p>
                    <Tooltips info={Bonusing.rollOver} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "rollOverMultiplier" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("bonusCriteria", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Bonus Criteria</p>
                    <Tooltips info={Bonusing.bonusCriteria} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "bonusCriteria" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("bonusStatus", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Bonus Status</p>
                    <Tooltips info={Bonusing.bonusStatus} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "bonusStatus" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("createdDate", "date")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Created Date</p>
                    <Tooltips info={COMMON_MSGS.createdDate} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "createdDate" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Apply Expiry</p>
                    <Tooltips info={Bonusing.applyExpiry} />
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("updatedDate", "date")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Updated Date</p>
                    <Tooltips info={COMMON_MSGS.updatedDate} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "updatedDate" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("startDate", "date")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Start Date</p>
                    <Tooltips info={Bonusing.startDate} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "startDate" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("validUntil", "date")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Valid Until</p>
                    <Tooltips info={Bonusing.validUntil} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "validUntil" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("maxWinPercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Max Winning %</p>
                    <Tooltips info={Bonusing.maxWinPercent} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "maxWinPercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("bonusPercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Deposit Rel %</p>
                    <Tooltips info={Bonusing.depoRelease} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "bonusPercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("depositCriteria", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Deposit Criteria</p>
                    <Tooltips info={Bonusing.depositCriteria} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "depositCriteria" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("wagerCriteria", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Wager Criteria</p>
                    <Tooltips info={Bonusing.wagerCriteria} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "wagerCriteria" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>

                <th className="pointer" onClick={() => handleSortFields("minDeposit", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Min Deposit</p>
                    <Tooltips info={Bonusing.minDeposit} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "minDeposit" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>

                <th className="pointer" onClick={() => handleSortFields("optIn", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Opt-In</p>
                    <Tooltips info={Bonusing.optin_tb} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "optIn" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Bonus Description</p>
                    <Tooltips info={Bonusing.bonusDesc} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Provider Name</p>
                    <Tooltips info={Bonusing.providerName} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Game Name</p>
                    <Tooltips info={Bonusing.gameName} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Unlock Provider Name</p>
                    <Tooltips info={Bonusing.unlockProvider} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Unlock Game Name</p>
                    <Tooltips info={Bonusing.unlockGameName} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Bonus Sports</p>
                    <Tooltips info={Bonusing.sports} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>RC Applicable Sports</p>
                    <Tooltips info={Bonusing.applicable_sports} />
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("oddsThreshold", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Odds Threshold</p>
                    <Tooltips info={Bonusing.oddThreshold} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "oddsThreshold" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("unlockOddsThreshold", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>RC Odds Threshold</p>
                    <Tooltips info={Bonusing.RC_oddThreshold} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "unlockOddsThreshold" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("applicableType", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> Applicable To</p>
                    <Tooltips info={Bonusing.applicableTo} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "applicableType" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("unlockApplicableType", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> RC Applicable Type</p>
                    <Tooltips info={Bonusing.RC_applicableTo} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "unlockApplicableType" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("tags", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Tags</p>
                    <Tooltips info={Bonusing.tags} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "tags" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("allowTags", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Allow Tags</p>
                    <Tooltips info={Bonusing.Allowtags} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "allowTags" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("denyTags", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Deny Tags</p>
                    <Tooltips info={Bonusing.DenyTags} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "denyTags" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("brand", "str")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Brand</p>
                    <Tooltips info={Bonusing.brand} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "brand" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("numberOfLegs", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.number_of_Legs}</p>
                    <Tooltips info={Bonusing.noLegs} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "numberOfLegs" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("numberOfLegs", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.rc_numOfLegs}</p>
                    <Tooltips info={Bonusing.noLegs} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "unlockNumberOfLegs" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("minStake", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.min_stake_Amount}</p>
                    <Tooltips info={Bonusing.minStakeAmnt} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "minStake" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("bonusPayoutPercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.bonus_Payout}</p>
                    <Tooltips info={Bonusing.bonusPayout} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "bonusPayoutPercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("referrerPercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.referrer}</p>
                    <Tooltips info={Bonusing.referrer} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "referrerPercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("refereePercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.referee}</p>
                    <Tooltips info={Bonusing.referee} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "refereePercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                <th className="pointer" onClick={() => handleSortFields("recReferrerPercentage", "num")}>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BONUS_CONSTANTS.recurring_referrer}</p>
                    <Tooltips info={Bonusing.referrer} />
                    <span
                      className='material-icons md-18'
                      data-icon={
                        sortables.key === "recReferrerPercentage" && sortables.sortType === "ASCENDING" ? "north" : "south"
                      }
                    ></span>
                  </div>
                </th>
                {props.adminBonusAction ? <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Action</p>
                    <Tooltips info={COMMON_MSGS.Action} />
                  </div>
                </th> : ''}
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>Bonus Awarded</p>
                    <Tooltips info={COMMON_MSGS.Bonus_Awarded} />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {props.bonusData &&
                Object.keys(props.bonusData).length > 0 &&
                props.bonusData?.data &&
                Array.isArray(props.bonusData?.data) &&
                props.bonusData?.data?.length > 0 ? (
                sortingFilter(props.bonusData?.data, { ...sortKeys }).map((item, index) => {
                  return (
                    <tr key={generateKey(item.id)}>
                      <td>{item.bonusType}</td>
                      <td>
                        {item.bonusType == "COUPONCODE" ||
                          item.bonusType == "FREE_BET" ||
                          item.bonusType == "GOODWILL"
                          ? item.bonusCode
                          : "N/A"}
                      </td>
                      <td>{item.bonusType == "REFERRAL" ? item.maxBonus : 0}</td>
                      <td>{item.wagerBonus}</td>
                      <td>{item.instantBonus}</td>
                      <td>{item.maxWinAmount === 0 ? "N/A" : item.maxWinAmount}</td>
                      <td>{item.bonusType == "REFERRAL" ? item.maximumTotalBonus : item.maxBonus}</td>
                      <td>{item.rollOverMultiplier === 0 ? "N/A" : item.rollOverMultiplier}</td>
                      <td>{item.bonusCriteria}</td>
                      <td>{item.bonusStatus}</td>
                      <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                      <td>{item.applyExpiry}</td>
                      <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                      <td>{item.startDate ? getDateTimeFormate(item.startDate) : "-"}</td>
                      <td>{item.validUntil ? getDateTimeFormate(item.validUntil) : "-"}</td>
                      <td>{item.maxWinPercentage === 0 ? "N/A" : item.maxWinPercentage}</td>
                      <td>{item.bonusPercentage}</td>
                      <td>{item.depositCriteria}</td>
                      <td>{item.wagerCriteria === 0 ? "N/A" : item.wagerCriteria}</td>
                      <td>{item.minDeposit}</td>
                      <td>{item.optIn}</td>
                      <td>{item.bonusDescription}</td>
                      <td>{item.providerName.length !== 0 ? <button onClick={() => providerNameHandler(item.providerName)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                        View 
                      </button> : "N/A"}</td>
                      <td>{item.gameName.length !== 0 ? <button onClick={() => gameNameHandler(item.gameName)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                        View 
                      </button> : "N/A"}</td>
                      <td>{item.unlockProviderName.length !== 0 ? <button onClick={() => unlockProviderHandler(item.unlockProviderName)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                        View 
                      </button> : "N/A"}</td>
                      <td>{item.unlockGameName.length !== 0 ? <button onClick={() => unlockGameNameHandler(item.unlockGameName)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                        View 
                      </button> : "N/A"}</td>
                      <td>{item.applicableType === "ALL" ? "ALL" : <button onClick={() => sportViewHandler(item.applicableSports)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                        View 
                      </button>}
                      </td>
                      <td>
                        {item.unlockApplicableSports === null ? "N/A" : (item.unlockApplicableType === "ALL" ? "ALL" : <button onClick={() => applicablesSortViewHandler(item.unlockApplicableSports)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                          View 
                        </button>)}
                      </td>
                      {/* <td>{item.applicableSports}</td> */}
                      <td>{item.oddsThreshold === 0 ? "N/A" : item.oddsThreshold}</td>
                      <td>{item.unlockOddsThreshold === 0 ? "N/A" : item.unlockOddsThreshold}</td>
                      <td>{item.applicableType}</td>
                      <td>{item.unlockApplicableType}</td>
                      <td>
                        {item?.tags?.length > 0 &&
                          item?.tags != null &&
                          item.tags[0]}
                      </td>
                      <td>
                        {item.allowTags.length === 0 ? "N/A" :
                          (<button onClick={() => allowTagHandler(item.allowTags)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                            View Tags
                          </button>)
                        }
                      </td>
                      <td> {item.denyTags.length === 0 ? "N/A" :
                        (<button onClick={() => denyTagHandler(item.denyTags)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                          View Tags
                        </button>)
                      }</td>
                      <td>
                        {item.brand == "starpunter" ? "StarPunter" : item.brand}
                      </td>
                      <td>{item.numberOfLegs === 0 ? "N/A" : item.numberOfLegs}</td>
                      <td>{item.unlockNumberOfLegs === 0 ? "N/A" : item.unlockNumberOfLegs}</td>
                      <td>{item.minStake}</td>
                      <td>{item.bonusPayoutPercentage}</td>
                      <td>{item.referrerPercentage}</td>
                      <td>{item.refereePercentage}</td>
                      <td>{item.recReferrerPercentage}</td>
                      {
                      props.adminBonusAction ? <td>
                        <button
                          onClick={() => props.handleEditOpen(item)}
                          className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                          type='button'
                          id='searchBetDetails'
                        >
                          Edit
                        </button>
                      </td> : ''
                      }
                      <td>
                        <button onClick={() => userTabHandler(item.id)} className='CMS-btn CMS-btnSecondary active CMS-btnMedium'>
                          View Users
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="no_data_screen">
                  <td colSpan={100}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div >
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    bonusData: state.bonus.bonusData,
    editSaveDisable: state.bonus.editSaveDisable,
    adminBonusAction: state.dashboard.adminBonusAction
  };
};

export default connect(mapStateToProps)(CreateBonusTable);
