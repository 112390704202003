import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { getDateFormate, colorArray, Currency } from "../../sharedfiles/helper";
const AccessibilityLineChart = (props) => {
  useEffect(() => {
    if (props.data && props.data?.length > 0) {
      loadGraph();
    }
  }, [props.data]);
  const dateConvertor = (data) => {
    const dates = [];
    data.filter((c) => {
      if (!dates.includes(c.createdDate)) {
        dates.push(c.createdDate);
        return c.createdDate;
      }
    });

    var sortedDates = dates.sort((a, b) => {
      const c = new Date(a);
      const d = new Date(b);
      return c - d;
    });
    return sortedDates;
  };

  const loadGraph = () => {
    let dates = [];
    const newDates = [];
    let arrayData = [...props.data];
    if (props.data) {
      // let sortedData = arrayData.sort((a, b) => new Date(...a.createdDate.split('/').reverse()) - new Date(...b.createdDate.split('/').reverse()));
      //  console.log(sortedData,"data")
      arrayData.filter((c) => {
        if (!dates.includes(c.createdDate)) {
          dates.push(c.createdDate);
          return c.createdDate;
        }
      });
    }

    const sortedDates = dates.sort((a, b) => {
      const c = new Date(a);
      const d = new Date(b);
      return c - d;
    });
    for (let date of sortedDates) {
      newDates.push(getDateFormate(date));
    }

    let sortedData = arrayData.sort(
      (a, b) =>
        new Date(...a.createdDate.split("/").reverse()) -
        new Date(...b.createdDate.split("/").reverse())
    );
    const getSportsData = () => {
      const allSports = [];
      sortedData.filter((item) => {
        if (!allSports.includes(item.betSport)) {
          allSports.push(item.betSport);
          return item.betSport;
        }
      });
      // console.log(sortedData, "sortedData");

      // FINAL DATA //
      const finalData = [];
      allSports.map((sport, ind) => {
        const uniqueSport = sortedData.filter((sportRec) => sportRec.betSport === sport);
        const uniqueDates = uniqueSport.filter((sport) => sport.createdDate);
        const data = [];
        dateConvertor(arrayData).map((date) => {
          if (uniqueDates.find((d) => d.createdDate === date)) {
            props.isGameWrox ? data.push(
              uniqueSport.find((d) => d.createdDate === date).turnover)
              : data.push(
                uniqueSport.find((d) => d.createdDate === date).turnover / 100
              );
          } else {
            data.push(null);
          }
        });
        finalData.push({
          // name: `${allSports[i]}` === "casinos" ? "Casinos" : allSports[i],
          name: uniqueSport[0].betSport,
          color: colorArray[ind],
          data: data,
          dashStyle: "shortdash",
          tooltip: {
            valuePrefix: Currency(),
          },
        });
      });
      // FINAL DATA //

      // return allarrays;
      return finalData;
    };

    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    Highcharts.chart("cashRevenuebysports", {
      title: {
        text: "",
      },
      chart: {
        zoomType: "xy",
      },
      credits: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: "",
        },
        min: -5,
      },
      tooltip: {
        valueDecimals: 2,
      },
      xAxis: {
        type: "datetime",
        categories: newDates,
        dateTimeLabelFormats: {
          day: "%e. %b",
        },
      },

      legend: {
        enabled: true,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          // pointStart: Date.UTC(2022, 0, 1),
          //   pointInterval: 24 * 3600 * 1000*2
        },
      },
      series: getSportsData(),

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "",
                align: "",
                verticalAlign: "",
              },
            },
          },
        ],
      },
    });
  };

  return <div id='cashRevenuebysports'></div>;
};

export default AccessibilityLineChart;
