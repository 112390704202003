import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from "@material-ui/core";

const Tooltips = (props) => {
    const { info, placement } = props
    const styles = {
        fontSize: "14px",
        color: "white",
    }
    return (
        <React.Fragment>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                options: {
                                    altAxis: true,
                                    padding: 8,
                                },
                            },
                        ],
                    },
                }}
                disableFocusListener
                disableTouchListener
                title={
                    <Typography style={styles}>
                        {info}
                    </Typography>
                }
                arrow
                placement={"right"}
            >
                <InfoOutlinedIcon style={{ fontSize: '18px' }} />
            </Tooltip>
        </React.Fragment>
    )
}

export default Tooltips