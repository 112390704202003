import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { tagApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";

const playerTaggingSlice = createSlice({
  name: "playertagging",
  initialState: {
    tagData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    tagPostData: [],
    recordsShow: [1, 25],
    viewRecords: false,
    formData: {},
    docsData: [],
    tagTypeControl: false,
    tagNameControl: false,
  },
  reducers: {
    getTagData: (state, action) => {
      state.tagData = action.payload;
    },
    setTagPostFata: (state, action) => {
      state.tagPostData = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setViewRecords: (state, action) => {
      state.viewRecords = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setTagTypeControl: (state, action) => {
      state.tagTypeControl = action.payload;
    },
    setTagNameControl: (state, action) => {
      state.tagNameControl = action.payload;
    },
  },
});

export const {
  getTagData,
  setTagPostFata,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setViewRecords,
  setFormData,
  setDocsData,
  setTagTypeControl,
  setTagNameControl,
} = playerTaggingSlice.actions;

export const fetchTagDetails =
  (firstValue, secondValue, data, updateTableToDocs) => (dispatch) => {
    //dev.platform-ups.sportsit-tech.net:443/platformtag?pageNumber=0&itemsPerPage=25
    var url =
      `${tagApi.getTag}` +
      "?pageNumber=" +
      firstValue +
      "&itemsPerPage=" +
      secondValue;

    apiCaller(url, "GET", {}).then((response) => {
      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(getTagData(response.data));
          dispatch(setViewRecords(true));
          updateTableToDocs();
        }
      }
    });
  };

export const postTagDetails = (data) => (dispatch) => {
  var obj = {
    description: data.description,
    tagName: data.tagName,
    type: data.type,
  };
  //   const newData = JSON.stringify(obj);
  var url = `${tagApi.getTag}`;

  apiCaller(url, "POST", obj)
    .then((response) => {
      response.status == 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
    .catch((error) => {
      const response = error.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};

export default playerTaggingSlice.reducer;

