import React, { useState, useEffect } from 'react'
import Input from '../Common/common_inputs/Input'
import { combiStatus } from '../Common/data/mapData'
import Select from '../Common/common_inputs/Select_DDL'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import Tooltips from '../Common/Tooltip'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import Table from '../Common/table/Table'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAllConfigList, getConfigIdPopUp, searchConfig, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, updateConfigEdit, viewUser } from './combiSlice'
import No__Data__Found from '../Common/table/No__Data__Found'
import { cleanObject, formatMoney, getDateTimeFormate } from '../../sharedfiles/helper'
import { setRecordsShow } from '../AppSlice'
import Vpagination from '../../sharedfiles/Vpagination'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'

const ViewCombi = () => {

    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        combiName: '',
        status: ''
    })
    const [openUsers, setOpenUsers] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [userTableData, setUserTableData] = useState('')
    const [viewConfig, setViewConfig] = useState('')
    const [csvData, setCsvData] = useState([])
    const [pdfData, setPdfData] = useState([])
    const {
        allConfigData,
        viewUserList,
        viewConfigData,
        paginationFirstValue,
        paginationSecondValue,
        recordsShow,
        isVisible
    } = useSelector(p => p.combiSlice)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }

    const onSubmit = () => {
        const payload = {
            status: formData.status,
            titleName: formData.combiName
        }
        const searchData = cleanObject(payload)
        dispatch(searchConfig(0, 25, searchData))
    }

    const onReset = () => {
        setFormData({
            combiName: '',
            status: ''
        })
        dispatch(searchConfig(0, 25, {}))
    }

    const onViewConfig = (id) => {
        setOpenConfig(true)
        dispatch(getConfigIdPopUp(id))
    }

    const onViewUser = (id) => {
        setOpenUsers(true)
        dispatch(viewUser(id))
    }

    const tableHeader = [
        "Combi ID",
        "Combi name",
        "All users",
        "Tags",
        "Min odds",
        "Min stake",
        "Max stake",
        "Liability limit",
        "Start date and time range",
        "End date and time range",
        "Actions"
    ]

    const viewCombiInfo = [
        "Showing Combi ID",
        "Showing Combi name",
        "Showing All users",
        "Showing Tags",
        "Showing Min odds",
        "Showing Min stake",
        "Showing Max stake",
        "Showing Liability limit",
        "Showing Start date and time range",
        "Showing End date and time range",
        "Showing Actions"
    ]

    const userTbHeader = [
        "User ID",
        "Bet amount",
        "Bet status",
        "Bet ID",
        "Bet placed date",
        "Payout",
        "Bonus "
    ]
    const userTbInfo = [
        "Showing User ID",
        "Showing Bet amount",
        "Showing Bet status",
        "Showing Bet ID",
        "Showing Bet placed date",
        "Showing Payout",
        "Showing Bonus "
    ]

    const viewConfigTbHeader = [
        "Config Id",
        "Event Name",
        "Market Name",
        "Submarket Name",
        "Odds",
        "Outcome Name",
    ]
    const viewConfigInfo = [
        "Showing Config Id",
        "Showing Event Name",
        "Showing Market Name",
        "Showing Odds",
        "Showing Outcome Name",
        "Showing Submarket Name",
    ]
    const userUpdate = () => {
        const tbody = viewUserList && viewUserList.length > 0 ? viewUserList.map((p) => [
            p.userId,
            formatMoney(p.stake),
            p.betStatus,
            p.betId,
            getDateTimeFormate(p.placedDate),
            formatMoney(p.payout),
            formatMoney(p.bonusAmount),

        ]) : []
        setUserTableData([userTbHeader, userTbInfo, ...tbody])
    }

    const viewConfigUpdate = () => {
        const tbody = viewConfigData && viewConfigData.data && viewConfigData.data.length > 0 ? viewConfigData.data.map((p) => [
            p.id,
            p.eventName,
            p.marketName,
            p.subMarketName,
            p.odds,
            p.outComeName,
        ]) : []
        setViewConfig([viewConfigTbHeader, viewConfigInfo, ...tbody])
    }

    const handleActive = (id) => {
        dispatch(updateConfigEdit({ id: id, status: "IN_ACTIVE" }))
        setTimeout(() => {
            dispatch(searchConfig(0, 25))
        }, 500)
    }

    const handleDeActive = (id) => {
        dispatch(updateConfigEdit({ id: id, status: "ACTIVE" }))
        setTimeout(() => {
            dispatch(searchConfig(0, 25))
        }, 500)
    }

    const callApi = (pageNumber, itemsPerPage) => {
        const payload = {
            status: formData.status,
            titleName: formData.combiName
        }
        const searchData = cleanObject(payload)
        dispatch(searchConfig(
            pageNumber - 1,
            itemsPerPage,
            searchData
        ))
    }

    const combiListTableHeader = [
        [
            "Combi ID",
            "Combi name",
            "All users",
            "Tags",
            "Min odds",
            "Min stake",
            "Max stake",
            "Liability limit",
            "Start date and time range",
            "End date and time range",
        ]
    ]

    const combiListTableUpdate = () => {
        const tbody = allConfigData && allConfigData.data &&
            allConfigData.data.length > 0 ? allConfigData.data.map((p) => [
                p.id,
                p.title,
                p.isAllUsers === true ? "true" : "false",
                p.userTags !== null ? p.userTags[0].replace(/{|}/g, '') : '',
                p.minOdds,
                formatMoney(p.minStake),
                formatMoney(p.maxStake),
                formatMoney(p.liabilityLimit),
                getDateTimeFormate(p.startDate),
                getDateTimeFormate(p.endDate)
            ]) : []
        setPdfData(tbody)

        const data = Array.isArray(tbody) && tbody.length > 0 ? [combiListTableHeader[0], ...tbody] : [combiListTableHeader[0], []];
        setCsvData(data)
    }

    const exportPDF = () => {
        const title = "Combi Suggestions Report";
        const styles = {
            marginTop: "10px"
        };
        exportPDFC(title, combiListTableHeader, pdfData, styles);
    }

    useEffect(() => {
        userUpdate()
    }, [viewUserList])

    useEffect(() => {
        viewConfigUpdate()
    }, [viewConfigData])

    useEffect(() => {
        combiListTableUpdate()
    }, [allConfigData])

    useEffect(() => {
        dispatch(searchConfig(0, 25))
    }, [])

    return (
        <React.Fragment>

            <Dialog__Main
                title={"View Config"}
                dOpen={openConfig}
                dClose={() => setOpenConfig(false)}
                ids={"viewConfigfCombi"}
                width={"700px"}

            >
                <Table
                    tableData={viewConfig}
                // styles={tableStyles}
                />
            </Dialog__Main>

            <Dialog__Main
                title={"View Users"}
                dOpen={openUsers}
                dClose={() => setOpenUsers(false)}
                ids={"viewUserofCombi"}
                width={"700px"}
            >
                <Table
                    tableData={userTableData}
                    linkIndex={0}
                // styles={tableStyles}
                />
            </Dialog__Main>

            <div className='CMS-tab-panel active' id='CMS-playerActivity'>
                <div className='CMS-tabContent'>
                    <div className="CMS-box CMS-box-content">
                        <div className="row">
                            <div className="col-3">
                                <Input
                                    title={"Combi Name"}
                                    name={"combiName"}
                                    value={formData.combiName}
                                    placeholder={"Combi Name"}
                                    setValue={(e) => handleChange(e)}
                                    ids={"combiName"}
                                    info={"Help you to search with Combi Name"}
                                    show={true}
                                />

                                {/* <input
                                    type="text"
                                    placeholder="Combi Name/Status"
                                    id="combiName"
                                    name="combiName"
                                    value={formData.combiName}
                                    onChange={(e) => handleChange(e)}
                                />

                                <span
                                    className="material-icons search search-icon-AFF pointer"
                                    onClick={() => onSubmit()}
                                >
                                    {COMMON_CONSTANTS.search_icon}
                                </span> */}
                            </div>
                            <div className='col-3'>
                                <Select
                                    title={"Status"}
                                    name={"status"}
                                    value={formData.status}
                                    setValue={(val) => handleChange(val)}
                                    options={combiStatus}
                                    // onSubmit={(e) => onSubmit(e)}
                                    ids={"combiStatus"}
                                    info={"Help you to search with status"}
                                />
                            </div>
                        </div>
                        <div className='mt-20'></div>
                        <div className='row'>
                            <ButtonGroup
                                groupNames={["Search", "Reset"]}
                                idsearch={"combiSearch"}
                                idreset={"combiReset"}
                                submit={(e) => onSubmit(e)}
                                reset={(e) => onReset(e)}
                            />
                        </div>
                    </div>

                    <div className='CMS-filter-result' id='result'>
                        <div className="CMS-pagination">
                            <Vpagination
                                data={allConfigData.data}
                                totalRecords={allConfigData.totalRecords}
                                paginationFirstValue={paginationFirstValue}
                                paginationSecondValue={paginationSecondValue}
                                setPaginationFirstValue={setPaginationFirstValue}
                                setPaginationSecondValue={setPaginationSecondValue}
                                recordsShow={recordsShow}
                                setRecordsShow={setRecordsShow}
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                                csvData={csvData}
                                exportPDF={exportPDF}
                                callApi={callApi}
                                csvFileName={"Combi suggestions Report"}
                            />
                        </div>
                    </div>

                    <div className="mt-30"></div>
                    <div className="CMS-box">
                        <div className="CMS-table CMS-table-triped">
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeader.map((item, index) => (
                                            <th>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <p className='mx-3'>{item}</p>
                                                    <Tooltips info={`${viewCombiInfo[index]}`} />
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allConfigData && allConfigData.data && allConfigData.data.length > 0 ? (
                                        allConfigData.data.map((item, ind) => {
                                            const stringWithBrackets = item.userTags?.[0]
                                            const extractedString = stringWithBrackets ? stringWithBrackets.replace(/{|}/g, '') : '';
                                            return (
                                                <tr key={ind}>
                                                    <td>{item.id}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.isAllUsers === true ? "true" : "false"}</td>
                                                    <td>{extractedString}</td>
                                                    <td>{item.minOdds}</td>
                                                    <td>{formatMoney(item.minStake)}</td>
                                                    <td>{formatMoney(item.maxStake)}</td>
                                                    <td>{formatMoney(item.liabilityLimit)}</td>
                                                    <td>{getDateTimeFormate(item.startDate)}</td>
                                                    <td>{getDateTimeFormate(item.endDate)}</td>
                                                    <td>
                                                        <div className='CMS-btnContainer'>
                                                            {item.status === "ACTIVE" ? (
                                                                <button
                                                                    className='CMS-btnStatus CMS-statusActive  CMS-btnSmall pointer'
                                                                    type='button'
                                                                    onClick={() => handleActive(item.id)}
                                                                    id='combiActivate'
                                                                >
                                                                    Active
                                                                </button>
                                                            ) : item.status === "EXPIRED" ? (
                                                                <button
                                                                    className='CMS-btnStatus CMS-btnSecondary active CMS-btnSmall pointer'
                                                                    type='button'
                                                                    // onClick={() => handleExpired(item.id)}
                                                                    id='combiExpired'
                                                                >
                                                                    Expired
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className='CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer'
                                                                    type='button'
                                                                    id='combiDeactivate'
                                                                    onClick={() => handleDeActive(item.id)}
                                                                >
                                                                    Deactive
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div className='mt-10'></div>
                                                        <div className='CMS-btnContainer'>
                                                            <button
                                                                className='CMS-btnStatus CMS-statusSuspended CMS-btnSmall pointer'
                                                                type='button'
                                                                id='useractivity'
                                                                onClick={() => onViewConfig(item.id)}
                                                            >
                                                                View Config
                                                            </button>
                                                        </div>
                                                        <div className='mt-10'></div>
                                                        <div className='CMS-btnContainer'>
                                                            <button
                                                                className='CMS-btnStatus CMS-statusSelf-excluded CMS-btnSmall pointer'
                                                                type='button'
                                                                id='useractivity'
                                                                onClick={() => onViewUser(item.id)}
                                                            >
                                                                View Users
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <No__Data__Found colspan={50} />
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewCombi