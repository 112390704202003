import React from "react";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
const AuditDialog = (props) => {
  return (
    <>
      <Dialog__Main
        dOpen={props.auditDialog}
        dClose={() => props.setAuditDialog(false)}
        title={"Comments"}
        maxWidth='xl'
      >
        <div
          className='CMS-box CMS-table CMS-table-triped'
        >
          <table className='text-center'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Comments</th>
                <th>Added By</th>
                <th>Added By Id</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {props.audits !== undefined && props.audits.length > 0 ? (
                props.audits.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.comments}</td>
                      <td>{item.addedBy}</td>
                      <td>{item.addedById}</td>
                      <td>{getDateTimeFormate(item.createdDate)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='10'>
                    <h3 className='text-center'>No results found</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Dialog__Main>
    </>
  );
};

export default AuditDialog;
