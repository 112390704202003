import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import brandLogoSvg from "../assets/images/new-entertainment-logo.svg";
import WATlogo from "../assets/images/WAT_AFF.d3ab5b68.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SidebarData } from "./SidebarData";
import { setOpenTabs, tabManager } from "../components/sidebar/sidebarSlice";
import { setOpen } from "./AppSlice";
class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: "",
      listItem: [],
      activeIndices: [],
    };
  }

  allCollapsed = (event) => {
    let ele = event.currentTarget.parentNode.parentNode.parentNode.parentNode;

    ReactDOM.findDOMNode(ele).classList.toggle("collapsed");
  };
  handleSettingTabs(item, isSuperSub = false) {
    this.props.dispatch(tabManager(item.subtitle, this.props.tabStack, null, null, isSuperSub))
  }
  handleTabs(activeTab) {
    this.setState({ isActiveTab: false })
    if (this.props.openTabs.length > 0) {
      let newtabs = [];
      this.props.dispatch(setOpenTabs([...this.props.openTabs, activeTab]));
      if (this.props.openTabs.includes(activeTab)) {
        newtabs = this.props.openTabs.filter((tab) => tab !== activeTab);
      } else {
        newtabs = [...this.props.openTabs, activeTab];
      }
      this.props.dispatch(setOpenTabs(newtabs));
    } else {
      this.props.dispatch(setOpenTabs([activeTab]));
    }
  }

  handleActivateTab(item) {
    this.handleTabs(item.title);
  }

  subTitleOpen(index) {
    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.includes(index)
        ? prevState.activeIndices.filter(i => i !== index)
        : [...prevState.activeIndices, index],
    }));
  }

  onClose() {
    this.props.dispatch(setOpen(false))
  }

  render() {
    let dashbordurl = window.location.href.split("/");

    if (
      dashbordurl[dashbordurl.length - 1] === "dashboard" &&
      this.state.collapsed !== ""
    ) {
      ReactDOM.findDOMNode(this.state.collapsed).classList.toggle("collapsed");
    }

    let givenPermissions = this.props.userPagePermissions.map(
      (value) => value.permissionName
    );
    const { activeIndices } = this.state;

    return (
      <>
        {/* <div className="CMS-dashboard-new"> */}
        <div
          className={
            this.props.open
              ? "CMS-side-panel CMS-sideMenu-collapsed"
              : "CMS-side-panel"
          }
        >
          <div className="CMS-sideMenu">
            <div className="CMS-sideMenu-header">
              {
                this.props.starbetPermission ? <Link to="/dashboard">
                  <img
                    className="CMS-header-logo__brand"
                    src={`${WATlogo}`}
                    alt="WAT Logo Brand"
                  />
                </Link> :
                  <Link to="/dashboard">
                    <img
                      className="CMS-header-logo__brand"
                      src={`${brandLogoSvg}`}
                      alt="Logo Brand"
                    />
                  </Link>
              }
              {/* <span className="material-icons" onClick={() => this.onClose()} data-icon="close" id="header-sidemenuClose"></span> */}
            </div>
            <div className="CMS-sideMenu-content">
              {SidebarData.length > 0 &&
                Object.keys(this.props.userPagePermissions).length > 0 &&
                givenPermissions.length > 0 &&
                SidebarData.filter((item) => {
                  for (let i = 0; i < item.children().length; i++) {
                    if (givenPermissions.includes(item.children()[i])) {
                      return item;
                    }
                  }
                }).map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={
                        this.props.openTabs.includes(item.title)
                          ? "CMS-accordion"
                          : "CMS-accordion collapsed"
                      }
                    >
                      <div onClick={() => this.handleActivateTab(item)}>
                        <div className="CMS-categoryListItem CMS-accordion-header CMS-arrowAfter">
                          <div className="CMS-categoryListItem-content">
                            <span
                              className="material-icons"
                              data-icon={item.icon}
                            ></span>
                            <div className="CMS-menuHeading">
                              <div
                                className="CMS-categoryListItem-title"
                                onClick={(event) => this.allCollapsed(event)}
                              >
                                {item.title}

                                {item.title === "PAYMENTS" &&
                                  Object.keys(this.props.notifications).length >
                                  0 &&
                                  this.props.notifications.totalCount > 0 && (
                                    <span className="CMS-badge">
                                      {this.props?.notifications?.totalCount}
                                    </span>
                                  )}
                              </div>
                              <span
                                className="material-icons expand_more"
                                onClick={(event) => this.allCollapsed(event)}
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {item.title === "COMMUNICATION" ?
                        <div className="CMS-accordion-content">
                          {item.subNav.map((item, index) => {
                            return (
                              item.isVisible &&
                              givenPermissions.includes(item.permissionName) && (
                                <div>
                                  <div className={activeIndices.includes(index)
                                    ? "CMS-categoryListItem active"
                                    : "CMS-categoryListItem"} onClick={() => this.subTitleOpen(index)}>
                                    {item.subtitle}
                                  </div>
                                  {activeIndices.includes(index) &&
                                    <div className='CMS-categoryListItem ml-20'>
                                      <div className="tabContainer">
                                        <div>
                                          {item.subTabs &&
                                            item.subTabs.map((p, ind) => {
                                              return (
                                                item.isVisible &&
                                                givenPermissions.includes(p.permissionName) && (
                                                  <Link
                                                    onClick={() => this.handleSettingTabs(p, true)}
                                                    to={p.path}
                                                    key={p.subtitle}
                                                  >
                                                    <div
                                                      key={p.subtitle}
                                                      className={
                                                        this.props.activeTab === p.subtitle
                                                          ? "CMS-categoryListItem active"
                                                          : "CMS-categoryListItem"
                                                      }
                                                    >
                                                      {p.subtitle}
                                                    </div>
                                                  </Link>
                                                )
                                              );
                                            })}
                                        </div>
                                      </div>



                                    </div>
                                  }
                                </div>
                              )
                            );
                          })}
                        </div>
                        : <div className="CMS-accordion-content">
                          {item.subNav.map((item, index) => {
                            return (
                              item.isVisible &&
                              givenPermissions.includes(item.permissionName) && (
                                <Link
                                  onClick={() => this.handleSettingTabs(item)}
                                  to={item.path}
                                  key={item.subtitle}
                                >
                                  <div
                                    key={item.subtitle}
                                    className={
                                      this.props.activeTab === item.subtitle
                                        ? "CMS-categoryListItem active"
                                        : "CMS-categoryListItem"
                                    }
                                  >
                                    {" "}
                                    {item.subtitle}
                                    {[
                                      "PENDING DEPOSITS",
                                      "PENDING WITHDRAWAL",
                                      "PENDING WITHDRAWALS",
                                    ].includes(item.subtitle) &&
                                      Object.keys(this.props.notifications)
                                        .length > 0 &&
                                      this.props?.notifications?.totalCount >
                                      0 && (
                                        <span className="CMS-badge">
                                          {item.subtitle === "PENDING DEPOSITS"
                                            ? this.props?.notifications
                                              ?.pendingDepositCount
                                            : this.props?.notifications
                                              ?.pendingWithdrawals}
                                        </span>
                                      )}
                                  </div>
                                </Link>
                              )
                            );
                          })}
                        </div>}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    open: state.appslice.open,
    userPermission: state.dashboard.userPermission,
    userPagePermissions: state.dashboard.userPagePermissions,
    notifications: state.pendingWithrawal.notifications,
    isAffiliateUser: state.loginSlice.isAffiliateUser,
    isSubAffiliateUser: state.loginSlice.isSubAffiliateUser,
    openTabs: state.sidebar.openTabs,
    activeTab: state.sidebar.activeTab,
    tabStack: state.sidebar.tabStack,
    starbetPermission: state.dashboard.starbetLogoPermission,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
