import React from 'react'
import Tooltips from './Tooltip'
import betslip from '../../assets/images/betslip.png'
import { Currency } from '../../sharedfiles/helper'

const DataBox = ({ title, data, currency, info }) => {
    return (
        <>
            <div class="CMS-DB-dataBox">
                {/* <span class="material-icons" data-icon="info_outline"></span> */}
                <div class="CMS-DB-dataBox-content">
                    <div class="CMS-DB-dataBoxInfo">
                        <div class="CMS-DB-dataBox_label">{title}</div>
                        <div class="CMS-DB-dataBox_value">
                            {title === 'Margin' ? `${data}%` : data}
                            <Tooltips info={info} />
                        </div>
                    </div>
                    <div class="CMS-DB-dataBoxIndicator">
                        {currency ? currency : <img src={betslip} alt="common-icon" />}
                    </div>
                </div>
            </div>

        </>
    )
}

export default DataBox