import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setPaginationSecondValue,
  setPaginationFirstValue,
  setRecordsShow,
  setIsVisible,
  bonusTransaction,
} from "./bonusTransactionSlice.js";
import "jspdf-autotable";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination.js";
import Table from "../Common/table/Table";
const BonusTransaction = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [payload, setPayload] = useState({})

  const callApi = (pageNumber, itemsPerPage) => {
    dispatch(setIsVisible(false));
    dispatch(
      bonusTransaction(
        pageNumber - 1,
        itemsPerPage,
        localStorage.getItem("userId"),
        true    //allTransactions
      )
    );
    setCsvData([]);
  };

  const headers = [
    [
      "Transaction ID",
      "Customer ID",
      "Bonus ID",
      "Released Date",
      "Bonus Code",
      "Locked Bonus",
      "Unlocked Bonus",
      "Transaction Amount",
      "Transaction Reference",
      "Transaction Type",
      "Brand",
    ],
  ];

  const info = [
    "Showing Transaction ID",
    "Showing Customer ID",
    "Showing Bonus ID",
    "Showing Released Date",
    "Showing Bonus Code",
    "Showing Locked Bonus",
    "Showing Unlocked Bonus",
    "Showing Transaction Amount",
    "Showing Transaction Reference",
    "Showing Transaction Type",
    "Showing Brand",
  ]

  const exportPDF = () => {
    const title = "Bonus Transaction Report";

    exportPDFC(title, headers, pdfBody);
  };

  useEffect(() => {
    if (Object.keys(props.bonusTransactionData).length > 0) {
      const data = props.bonusTransactionData &&
        props.bonusTransactionData !== undefined &&
        Object.keys(props.bonusTransactionData).length > 0 &&
        props.bonusTransactionData.data &&
        Array.isArray(props.bonusTransactionData?.data) &&
        props.bonusTransactionData?.data.length > 0 &&
        props.bonusTransactionData?.data.map((p) => [
          p.id,
          p.userId,
          p.userBonusId,
          p.releasedDate ? getDateTimeFormate(p.releasedDate) : "-",
          p.bonusCode,
          p.lockedBonus,
          p.unlockedBonus,
          p.transactionAmount,
          p.transactionReference,
          p.transactionType,
          p.brand,
          
        ]);
      const updatedData = data && data.length > 0 ? [headers[0], info, ...data] : [headers[0], info, []]
      setCsvData(updatedData);
      setPdfBody(data);
    }
  }, [props.bonusTransactionData]);

  useEffect(() => {
    const paylaod = {
      pageNumber: props.activePages,
      itemsPerPage: props.paginationSecondValue,
    }
    setPayload(paylaod)
    callApi(1, 25);

  }, []);

  useEffect(() => {
    let mounting = true;
    const id = location.pathname.split("/")[2];
    const isToCall = props.userId !== id
    if (mounting && isToCall) {
      dispatch(bonusTransaction(0, 25, id, true));
    }
    return () => mounting = false;
  }, [location.pathname]);

  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-bonuses'>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={props.bonusTransactionData?.data}
                  totalRecords={props.bonusTransactionData.totalRecords}
                  paginationFirstValue={props.paginationFirstValue}
                  paginationSecondValue={props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={csvData}
                  exportPDF={exportPDF}
                  callApi={callApi}
                  csvFileName={"Bonus Transaction Report"}
                  payloadData={payload}
                />
              </div>
              <Table tableData={csvData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    activePages: state.bonusTransactionSlice.activePages,
    paginationFirstValue: state.bonusTransactionSlice.paginationFirstValue,
    paginationSecondValue: state.bonusTransactionSlice.paginationSecondValue,
    userId: state.playersearch.userId,
    recordsShow: state.bonusTransactionSlice.recordsShow,
    isVisible: state.bonusTransactionSlice.isVisible,
    bonusTransactionData: state.bonusTransactionSlice.bonusTransactionData,
  };
}
export default connect(mapStateToProps)(BonusTransaction);
