import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import {
  getPlayerBetHistory,
  getBetUserData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsvisible,
} from "./playerBetHistorySlice";
import BettingHistoryTable from "./bettingHistoryTable";
import {
  formatMoney,
  getDateTimeFormate,
  Currency,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Skelton from "../Common/skelton/Skelton";
import { BETHISTORY_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Tooltips from "../Common/Tooltip";
import { Betting } from "../../sharedfiles/tooltipMsgs";
import { BetStatus } from "../Common/data/BettingHistory/BettinghistoryData";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";
const BettingHistory = (props) => {
  const [betStatus, setBetStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [payload, setPayload] = useState({});
  const [userId, setUserId] = useState(
    props.userId ? props.userId : localStorage.getItem("userId")
  );
  const [shouldReset, setShouldReset] = useState(false)
  const showPermission = props.stakeTaxPermit && props.winTaxPermit
  const callApi = (pageNumber, itemsPerPage) => {
    // dispatch(
    //   getBetUserData(pageNumber - 1, props.paginationSecondValue, {
    //     id: localStorage.getItem("userId"),
    //   })
    // );
    dispatch(setIsvisible(false));
    dispatch(
      getPlayerBetHistory(pageNumber - 1, itemsPerPage, {
        betStatus: betStatus,
        userId: userId,
      })
    );
    // recordsShow(pageNumber, props.paginationSecondValue);
  };

  const headers = [
    [
      "Bet ID",
      "Short Bet ID",
      "EventName",
      "Bet Status",
      "Rejection Code",
      "Placed On",
      "Settled On",
      "Betfold",
      "Bet Type",
      `Stake ${Currency()}`,
      `Payout ${Currency()}`,
      `Potential Payout ${Currency()}`,
      showPermission ? `Stake Tax ${Currency()}` : null,
      showPermission ? `Stake After Tax ${Currency()}` : null,
      showPermission ? `Stake Tax Amount ${Currency()}` : null,
      showPermission ? `Winnings Tax ${Currency()}` : null,
      showPermission ? `Winnings Tax Amount ${Currency()}` : null,
      "Total Payout ${Currency()}",
      "Total Payout With Bonus ${Currency()}",
      "Bonus Max Win Amount ${Currency()}",
      "Bonus Max Win Percentage",
      "Place Bet Type"
    ]
  ].filter(Boolean);

  const data =
    props.playerBetHistoryData &&
    Object.keys(props.playerBetHistoryData).length > 0 &&
    props.playerBetHistoryData?.data &&
    Array.isArray(props.playerBetHistoryData?.data) &&
    props.playerBetHistoryData.data.map((item) => [
      item.betId,
      item.shortBetId,
      item.fixtureName,
      item.betStatus,
      item.rejectionCode,
      item.placedDate ? getDateTimeFormate(item.placedDate) : "-",
      item.settledDate ? getDateTimeFormate(item.settledDate) : "-",
      // item.bets && item.bets.length,
      item.betFold,
      item.betType,
      formatMoney(item.stake),
      formatMoney(item.payout),
      formatMoney(item.potentialPayout),
      showPermission ? item.stakeTax : '',
      showPermission ? formatMoney(item.stakeAfterTax) : '',
      showPermission ? formatMoney(item.stakeTaxAmount) : '',
      showPermission ? item.winningsTax : '',
      showPermission ? formatMoney(item.winningsTaxAmount) : '',
      formatMoney(item.totalPayout),
      formatMoney(item.totalPayoutWithBonus),
      formatMoney(item.bonusMaxWinAmount),
      item.bonusMaxWinPercentage,
      item.placeBetType
    ]);
  const csvData =
    Object.keys(props.playerBetHistoryData).length > 0
      ? [headers[0], ...data]
      : [headers[0], []];

  const exportPDF = () => {
    const title = "Player Bet History Report";

    const styles = {
      6: {
        halign: "right",
        valign: "middle",
      },
      7: {
        halign: "right",
        valign: "middle",
      },
      4: {
        halign: "right",
        valign: "middle",
      },
    };

    exportPDFC(title, headers, data, styles);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const paylaod = {
      betStatus: betStatus,
      userId: userId,
    };
    setPayload(paylaod);
    if (betStatus !== "") {
      dispatch(setIsvisible(false));
      if (props.paginationFirstValue == 1) {
        callApi(
          props.paginationFirstValue,
          props.paginationSecondValue,
          paylaod
        );
        props.dispatch(setPaginationFirstValue(1))
        props.dispatch(setPaginationSecondValue(25))
        props.dispatch(setRecordsShow([1, 25]))
      }
      if (props.paginationFirstValue > 1) {
        resetCalls(paylaod);
      }
      // setBetStatus("");
    }
  };

  const onKeyUp = (event) => {
    if (event.charCode === 13) {
      onSubmit(event);
    }
  };
  const emptyState = () => {
    setBetStatus("");
    setShouldReset(true)
  };
  const resetCalls = (paylaod) => {
    dispatch(setPaginationFirstValue(1));
    dispatch(setPaginationSecondValue(25));
    dispatch(setIsvisible(false));
    dispatch(getPlayerBetHistory(0, 25, paylaod));
    dispatch(setRecordsShow([1, 25]));
  };

  const resetButton = () => {
    emptyState();
    resetCalls({ userId: userId });
    // setUserId(localStorage.getItem("userId"));
  };
  const mountingCall = (id) => {
    dispatch(getBetUserData(id));
    dispatch(getPlayerBetHistory(0, 25, { userId: id }));
  };

  useEffect(() => {
    let mounting = true;

    if (mounting) {
      const userId = location.pathname.split("/")[2];
      if (props.userId !== userId) {
        mountingCall(userId);
      }
    }

    return () => mounting = false;
  }, [location]);

  useEffect(() => {
    const id = window.location.pathname.split("/")[2];
    let mounting = true;

    if (mounting) {
      const paylaod = {
        betStatus: betStatus,
        userId: userId,
      };
      setPayload(paylaod);
      dispatch(getBetUserData(id));
      callApi(1, 25, { userId: id });
    }
    return () => mounting = false;
  }, []);

  const netPosition =
    props.userData.settledStakes - props.userData.settledPayout;

  const handleSelectedValues = (selected) => {
    setBetStatus(selected)
    setShouldReset(false)
  }
  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-bettingHistory'>
        {props.errorMessage != "" && props.playerBetHistoryData < 0 ? (
          <div className='CMS-msgBox CMS-noRecords'>
            <div className='CMS-msgBox-container'>{props.errorMessage}</div>
          </div>
        ) : (
          ""
        )}
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='CMS-box CMS-box-content'>
              <div className='row no-gutters'>
                <div className='col-md-9 col-lg-9 col-xl-9'>
                  <div className='row no-gutters'>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div className='CMS-userDetails'>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_unsettledBetCount}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {props.userData?.unSettledBetCount
                              ? props.userData?.unSettledBetCount
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div className='CMS-userDetails'>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_unsettledStake}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {Currency()}{" "}
                            {formatMoney(
                              props.userData?.unSettledStakes
                                ? props.userData?.unSettledStakes
                                : 0
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div
                        className='CMS-userDetails'
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "space-between",
                      // }}
                      >
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_unsetleMaxPayout}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {Currency()}{" "}
                            {formatMoney(
                              props.userData?.unSettledPayout
                                ? props.userData?.unSettledPayout
                                : 0
                            )}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_settleNetPosition}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {Currency()}{" "}
                            {formatMoney(netPosition ? netPosition : 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div className='CMS-userDetails'>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_settleBetCount}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {props.userData?.settledBetCount
                              ? props.userData?.settledBetCount
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div className='CMS-userDetails'>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_settleStake}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {Currency()}{" "}
                            {formatMoney(
                              props.userData?.settledStakes
                                ? props.userData?.settledStakes
                                : 0
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-4'>
                      <div className='CMS-userDetails'>
                        <div className='col-6'>
                          <div className='userLabel'>
                            {BETHISTORY_CONSTANTS.header_settlePayout}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='userData'>
                            {Currency()}{" "}
                            {formatMoney(
                              props.userData?.settledPayout
                                ? props.userData?.settledPayout
                                : 0
                            )}
                          </div>
                        </div>

                        <div className='col-6'>
                          <div className='CMS-formGroup formGroup-inline'>
                            <div className='CMS-formLabel'>
                              {BETHISTORY_CONSTANTS.header_betStatus}
                              &nbsp;&nbsp;
                              <Tooltips info={Betting.Bet_Status} />
                            </div>
                          </div>
                          <MultipleSelectDDL
                            options={BetStatus}
                            onSelect={(value) => handleSelectedValues(value)}
                            shouldReset={shouldReset}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-20'></div>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={'bethistorybtn'}
                idreset={'bethistoryreset'}
                submit={(e) => onSubmit(e)}
                reset={() => resetButton()}
              />
            </div>
            <div className='mt-20'></div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={props.playerBetHistoryData?.data}
                  totalRecords={props.playerBetHistoryData?.totalRecords}
                  paginationFirstValue={props.paginationFirstValue}
                  paginationSecondValue={props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={props.isVisible}
                  setIsVisible={setIsvisible}
                  csvData={csvData}
                  exportPDF={exportPDF}
                  callApi={callApi}
                  csvFileName={"PlayerBetHistory"}
                  payloadData={payload}
                />
              </div>
              {!props.isVisible ? (
                <Skelton />
              ) : (
                <BettingHistoryTable data={props.playerBetHistoryData?.data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userId: state.playersearch.userId,
    paginationFirstValue: state.playerBetHistorySlice.paginationFirstValue,
    paginationSecondValue: state.playerBetHistorySlice.paginationSecondValue,
    playerBetHistoryData: state.playerBetHistorySlice.playerBetHistoryData,
    userData: state.playerBetHistorySlice.userData,
    recordsShow: state.playerBetHistorySlice.recordsShow,
    isVisible: state.playerBetHistorySlice.isVisible,
    stakeTaxPermit: state.dashboard.stakeTaxPermission,
    winTaxPermit: state.dashboard.winTaxPermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BettingHistory);
