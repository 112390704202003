import React from "react";

function No__Data__Found(props) {
  const { colspan, text } = props;
  return (
      <tr>
        <td colSpan={colspan ? colspan : 100}>
          <div className='CMS-msgBox'>
            <div className='CMS-msgBox-container'>
              {text ? text : "No Data Found"}
            </div>
          </div>
        </td>
      </tr>
  );
}

export default No__Data__Found;
