import React, { Component } from "react";
import { connect } from "react-redux";
import Vpagination from "../../sharedfiles/Vpagination";
import {
  getAccountHistory,
  setPaginationSecondValue,
  setPaginationFirstValue,
  setRecordsShow,
  setIsVisible,
  setFormData,
} from "./accountHistoryslice";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
  cleanObject,
  MomentUTCDateTimeEnd,
  MomentUTCDateTimeToday,
  divideAmountBy100,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Table from "../Common/table/Table";
import Input from "../Common/common_inputs/Input";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import { AccountHistoryDates } from "../Common/data/mapData";
import Datepicker from "../Common/common_inputs/DatePicker";
import Skelton from "../Common/skelton/Skelton";
class AccountHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: "",
      data: [],
      totalPages: "",
      document: "",
      pdfBody: [],
      csvData: [],
      payload: {},
      txnid: "",
      startDate: "",
      endDate: "",
      dateError: "",
    };
    this.exportPDF = this.exportPDF.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
  }
  callApi = (pageNumber, itemsPerPage, data) => {
    this.props.dispatch(setIsVisible(false));
    const payload = {
      createdFromDate: MomentUTCDateTimeToday(this.state.startDate),
      createdToDate: MomentUTCDateTimeEnd(this.state.endDate),
      transactionId: this.state.txnid,
      id: localStorage.getItem("userId")
    }
    const filterData = cleanObject(payload)
    this.props.dispatch(
      getAccountHistory(pageNumber - 1, itemsPerPage, filterData)
    );
  };

  headers = [
    [
      "Reference ID",
      "Transaction Type",
      "Selection ID",
      "Created On",
      // "Transaction Type",
      `Amount ${Currency()}`,
      `Balance ${Currency()}`,
      "Description",
    ],
  ];

  info = [
    "Showing Reference ID",
    "Showing Transaction Type",
    "Showing Selection ID",
    "Showing Created On",
    // "Transaction Type",
    `Showing Amount`,
    `Showing users Balance`,
    "Showing Description",
  ];
  tableStyles = {
    3: "text-right",
    4: "text-right",
  };
  updateTableToDocs() {
    setTimeout(() => {
      const data =
        this.props?.accountData &&
        Object.keys(this.props?.accountData).length > 0 &&
        this.props?.accountData?.data.map((p) => {
          let formattedDescription = p.description;
          if (p.description) {
            const descriptionAmountMatch = p.description.match(/(\d+\.\d+)/);
            const descriptionAmount = descriptionAmountMatch ? parseFloat(descriptionAmountMatch[0]) : null;
            if (descriptionAmount !== null) {
              const formattedAmount = (descriptionAmount / 100).toFixed(2);
              formattedDescription = p.description.replace(descriptionAmountMatch[0], formattedAmount);
            }
          }
          return [
            p.transactionId,
            p.transactionType,
            p.selectionId,
            p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
            formatMoney(p.amount.toFixed(2)),
            formatMoney(p.balance.toFixed(2)),
            formattedDescription,
          ];
        });

      const csvData =
        data.length > 0
          ? [this.headers[0], this.info, ...data]
          : [this.headers[0], this.info, []];
      this.setState({ csvData: csvData });
      this.setState({ pdfBody: data });
    }, 500);
  }

  exportPDF() {
    const title = "Account History Report";

    const styles = {
      3: {
        valign: "middle",
        halign: "right",
      },
      4: {
        valign: "middle",
        halign: "right",
      },
    };
    exportPDFC(title, this.headers, this.state.pdfBody, styles);
  }

  handleDatepicker(value, name) {
    if (name === "startDate") {
      this.setState({ startDate: value, dateError: "" });
      this.formDataToSliceSetter("", "", { [name]: value, dateError: "" });
    } else {
      const dateErr =
        this.state.startDate === "" ? "Start Date is required" : "";
      this.setState({
        endDate: value,
        dateError: dateErr,
      });
      this.formDataToSliceSetter("", "", { [name]: value, dateError: dateErr });
    }
  }

  txnidHandler(e) {
    const { name, value } = e.target;
    this.setState({ txnid: value });
    this.formDataToSliceSetter("tnxId", value);
  }

  onSearch(e) {
    const payload = {
      createdFromDate: MomentUTCDateTimeToday(this.state.startDate),
      createdToDate: MomentUTCDateTimeEnd(this.state.endDate),
      transactionId: this.state.txnid,
      id: localStorage.getItem("userId"),
    };
    const payloadData = cleanObject(payload);
    const { paginationFirstValue, paginationSecondValue } = this.props;
    if (!this.state.dateError) {
      // this.props.dispatch(getAccountHistory(0, 25, payloadData));
      this.callApi(paginationFirstValue, paginationSecondValue, payloadData)
    }
    this.props.dispatch(setIsVisible(false));
    //   this.props.dispatch(setPaginationFirstValue(1));
    //   this.props.dispatch(setPaginationSecondValue(25));
    //   this.props.dispatch(setRecordsShow([1, 25]));
  }

  onReset() {
    this.setState({
      txnid: "",
      startDate: "",
      endDate: "",
      dateError: "",
    });
    // this.callApi(1, 25, {});
    this.props.dispatch(
      getAccountHistory(0, 25, { id: localStorage.getItem("userId") })
    );
  }

  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val };
    const newPayload = multiEntries
      ? { ...this.props.formData, ...multiEntries }
      : newEntry;
    this.props.dispatch(setFormData(newPayload));
  }

  componentDidMount() {
    const paylaod = {
      pageNumber: this.props.paginationFirstValue,
      itemsPerPage: this.props.paginationSecondValue,
      startDate: MomentUTCDateTimeToday(this.state.startDate),
      endDate: MomentUTCDateTimeEnd(this.state.endDate),
      transactionId: this.state.txnid,
    };
    const payloadData = cleanObject(paylaod)
    this.setState({ payload: paylaod });
    this.callApi(this.props.paginationFirstValue, this.props.paginationSecondValue, payloadData);
    this.stateSetter();
  }

  componentDidUpdate(prevprops) {
    const id = window.location.pathname.split("/")[2];
    if (prevprops.userId !== id) {
      this.props.dispatch(setIsVisible(false));
      this.callApi(this.props.paginationFirstValue, this.props.paginationSecondValue, { id: id });
    }
    if (prevprops.accountData !== this.props.accountData) {
      this.updateTableToDocs();
    }
    if (prevprops.formData !== this.props.formData) {
    }
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length > 0;
    if (isFormDataExist) {
      Object.keys(this.props.formData).map((key) =>
        this.setState({ [key]: this.props.formData[key] })
      );
    }
  }
  render() {
    return (
      <>
        <div className="CMS-tabContent">
          <div className="CMS-box-content">
            <div className="CMS-box CMS-box-content">
              <div className="row">
                {AccountHistoryDates.map((item, ind) => {
                  const minDate = [null, this.state.startDate];
                  const max = this.state.endDate
                    ? this.state.endDate
                    : new Date();
                  const maxDate = [max, new Date()];
                  const values = [this.state.startDate, this.state.endDate];
                  return (
                    <div className="col-3" key={ind}>
                      <Datepicker
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        setValue={(date, value) =>
                          this.handleDatepicker(date, value)
                        }
                        errorMsg={ind === 0 ? this.state.dateError : null}
                        minDate={minDate[ind]}
                        maxDate={maxDate[ind]}
                        ids={item.ids}
                        info={item.info}
                      />
                    </div>
                  );
                })}
                <div className="col-3">
                  <Input
                    title={"Transaction Id"}
                    name={"tnxId"}
                    value={this.state.txnid}
                    placeholder={"Transaction Id"}
                    setValue={(e) => this.txnidHandler(e)}
                    ids={"TnxdAccHistory"}
                    info={"Help User to Search with Transaction ID"}
                    show={true}
                  // regex={regexType.notZero}
                  />
                </div>

                <div className="ml-15"></div>
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"searchAffiliate"}
                  idreset={"affiliateReset"}
                  submit={(e) => this.onSearch(e)}
                  reset={(e) => this.onReset(e)}
                // freezButton={syndicateDisabled}
                />
              </div>
            </div>
          </div>
          <div className="CMS-box-content">
            <div className="CMS-filter-result" id="result">
              <div className="CMS-pagination">
                <div className="mb-20"></div>
                <Vpagination
                  data={this.props?.accountData?.data}
                  totalRecords={this.props?.accountData?.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"Account History report"}
                  payloadData={this.state.payload}
                />
              </div>
              {!this.props.isVisible ? <Skelton vLen={5} hLen={7} /> : <Table tableData={this.state.csvData} styles={this.tableStyles} />}
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    paginationFirstValue: state.accountHistory.paginationFirstValue,
    paginationSecondValue: state.accountHistory.paginationSecondValue,
    accountData: state.accountHistory.accountData,
    userId: state.playersearch.userId,
    recordsShow: state.accountHistory.recordsShow,
    isVisible: state.accountHistory.isVisible,
    formData: state.playeractivity.formData,
  };
}
export default connect(mapStateToProps)(AccountHistory);
