/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchTagDetails,
  postTagDetails,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setViewRecords,
  setFormData,
  setDocsData,
  setTagTypeControl,
  setTagNameControl,
} from "./PlayerTaggingSlice";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import { createTagData, createTagInfo } from "../Common/data/mapData";
import { CreateTag } from "../Common/data/CreateTagData/createTagData";
import Table from "../Common/table/Table";
import { Tagging_Segmentation } from "../../sharedfiles/tooltipMsgs";
class PlayerTagging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagType: "",
      tagName: "",
      tagDescription: "",
      tagTypeControl: false,
      tagNameControl: false,
      descriptionControl: false,
      itemsperpage: "",
      payloadData: {},
      document: "",
      csvData: [],
      pdfBody: [],
      resetCall: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.testApi = this.testApi.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.callApi = this.callApi.bind(this);
    this.resetCsvData = this.resetCsvData.bind(this);
  }

  changeHandler(e) {
    const { name, value } = e.target;
    this.formDataToSliceSetter(name, value)
    if (name === "tagType" &&
      value.length > 0) {
      this.setState({ tagTypeControl: false });
      this.props.dispatch(setTagTypeControl(false))
    }

    if (name === "tagName" &&
      value.length > 0) {
      this.setState({ tagNameControl: false });
      this.props.dispatch(setTagNameControl(false))
    }
    name === "tagDescription" &&
      value.length > 0 &&
      this.setState({ descriptionControl: false });

    this.setState({ [name]: value });
  }

  callApi(pageNumber, itemPerPage, data) {
    this.props.dispatch(setViewRecords(false));
    this.props.dispatch(
      fetchTagDetails(pageNumber - 1, itemPerPage, data, this.updateTableToDocs)
    );
    this.setState({ resetCall: false });
  }

  submitHandler = (e) => {
    e.preventDefault();
    if (
      this.state.tagType !== "" &&
      this.state.tagName !== ""
      // this.state.tagDescription !== ""
    ) {
      const data = {
        description: this.state.tagDescription,
        tagName: this.state.tagName,
        type: this.state.tagType.toUpperCase(),
      };
      this.setState({ payloadData: data });
      this.props.dispatch(postTagDetails(data));

      setTimeout(() => {
        this.callApi(1, 25, {});
      }, [200]);
      this.resetForm();
    }
    if (this.state.tagType === "") {
      this.setState({
        tagTypeControl: true,
      });
      this.props.dispatch(setTagTypeControl(true));
    }
    if (this.state.tagName === "") {
      this.setState({
        tagNameControl: true,
      });
      this.props.dispatch(setTagNameControl(true));
    }
  };

  resetForm = () => {
    this.setState({
      resetCall: true,
      tagType: "",
      tagName: "",
      tagDescription: "",
      tagTypeControl: false,
      tagNameControl: false,
      descriptionControl: false,
      payloadData: {},
      itemsperpage: "",
    });
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setViewRecords(false));
    this.props.dispatch(setRecordsShow([1, 25]));
    this.callApi(1, 25);
    this.props.dispatch(setFormData(new Object()))
    this.props.dispatch(setTagTypeControl(false));
    this.props.dispatch(setTagNameControl(false));
  };

  headers = [["TAG TYPE", "TAG NAME", "TAG DESCRIPTION", "CREATION DATE", "CREATED BY"]];
  exportPDF() {
    const title = "Create Tags Report";
    exportPDFC(title, this.headers, this.state.pdfBody);
  }

  updateTableToDocs() {
    setTimeout(() => {
      const data =
        this.props?.tagData?.data &&
        Array.isArray(this.props?.tagData?.data) &&
        this.props?.tagData?.data.map((p) => [
          p.tagType,
          p.tagName,
          p.description,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.createdByName,
        ]);
      this.setState({ pdfBody: data });
      const csvData = data && data.length > 0 ? [this.headers[0], createTagInfo, ...data] : [this.headers[0], createTagInfo, []];
      this.setState({ csvData: csvData });
      this.props.dispatch(setDocsData(csvData));
    }, 300);
  }
  resetCsvData() {
    this.setState({ csvData: [], pdfBody: [] });
  }

  componentDidMount() {
    const dataExist = Object.keys(this.props?.tagData).length > 0
    if (!dataExist) {
      this.callApi(
        this.props.paginationFirstValue,
        this.props.paginationSecondValue
      );
    } else {
      const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0
      let pdfData = [];
      if (csvDataExist) {
        pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
      }
      this.setState({
        csvData: this.props.docsData,
        pdfBody: pdfData,
        tagTypeControl: this.props.tagTypeControl,
        tagNameControl: this.props.tagNameControl,
      })
    }
    this.stateSetter();
  }
  testApi() { }

  componentDidUpdate(prevProps) {
    if (prevProps.tagData !== this.props.tagData) {
      this.updateTableToDocs();
    }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
  }

  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }
  formDataToSliceSetter(key, val) {
    const newPayload = { ...this.props.formData, [key]: val }
    this.props.dispatch(setFormData(newPayload));
  }


  render() {
    return (
      <>
        <div className='CMS-tab-panel active' id='CMS-betting'>
          <div className='CMS-tabContent'>
            <div className='CMS-box CMS-box-content'>
              {/* <!-- <div class="CMS-box__header">
                                                <div class="CMS-box__title">Create Player Tag</div>

                                            </div> --> */}
              <div className='row'>
                <div className='col-md-6 col-lg-4 col-xl-4'>
                  <Select
                    title={"Tag Type"}
                    name={"tagType"}
                    value={this.state.tagType}
                    setValue={(val) => this.changeHandler(val)}
                    options={CreateTag}
                    errorMsg={
                      this.state.tagTypeControl
                        ? "Please select proper tag type"
                        : ""
                    }
                    ids={"CreateTagtype"}
                    info={Tagging_Segmentation.Tag_Type}
                  />
                </div>
                {createTagData.map((item, ind) => {
                  const values = [
                    this.state.tagName,
                    this.state.tagDescription,
                  ];
                  return (
                    <div className='col-md-6 col-lg-4 col-xl-4' key={item.id}>
                      <Input
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={(e) => this.changeHandler(e)}
                        errorMsg={this.state.tagNameControl && item?.errorMsg}
                        ids={item.ids}
                        info={item.info}
                        show={true}
                      />
                    </div>
                  );
                })}
              </div>
              <div className='mt-20'></div>

              <div className='row'>
                <ButtonGroup
                  groupNames={["Create", "Reset"]}
                  idsearch={"createTag"}
                  idreset={"createTagreset"}
                  submit={(e) => this.submitHandler(e)}
                  reset={(e) => this.resetForm(e)}
                />
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.tagData.data}
                  totalRecords={this.props.tagData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.viewRecords}
                  setIsVisible={setViewRecords}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"Create Tags report"}
                  resetCall={this.state.resetCall}
                  reloadRequired={true}
                  resetCsvData={this.resetCsvData}
                />
              </div>
              <Table tableData={this.state.csvData} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    tagData: state.playertagging.tagData,
    paginationFirstValue: state.playertagging.paginationFirstValue,
    paginationSecondValue: state.playertagging.paginationSecondValue,
    tagPostData: state.playertagging.tagPostData,
    recordsShow: state.playertagging.recordsShow,
    userId: state.playersearch.userId,
    viewRecords: state.playertagging.viewRecords,
    formData: state.playertagging.formData,
    docsData: state.playertagging.docsData,
    tagTypeControl: state.playertagging.tagTypeControl,
    tagNameControl: state.playertagging.tagNameControl,
  };
}
export default connect(mapStateToProps)(PlayerTagging);
