import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  verifyKyc,
  getPlayer,
  getKycDoc,
  rejectKyc,
} from "./PrimaryPlayerSlice";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { COMMON_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import KycCommentBox from "../KycUsers/KycCommentBox";
export const Kyc = (props) => {
  const [imagePopUp, setImagePopUp] = useState("");
  const [urlPopup, setUrlPopUp] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();
  const [kycID, setKycID] = useState("");
  const [declineBox, setDeclineBox] = useState(false)

  const handleActive = (status) => {
    const userId = localStorage.getItem("userId");
    dispatch(verifyKyc(kycID, status));
    setTimeout(() => {
      dispatch(getPlayer({ user_id: userId }));
    }, 500);
    props.setKycdialog(false);
    setImagePopUp("");
    setUrlPopUp("");
  };
  useEffect(() => {
    let mounting = true;
    const userId = localStorage.getItem("userId");
    if (mounting) {
      if (props.kycdialog) {
        if (props.kycType.length > 0) {
          for (let i in props.kycType) {
            let kycdata = {
              kycType: props.kycType[i],
              //status: "SUBMITTED",
            };
            props.dispatch(
              getKycDoc(
                userId,
                props.paginationFirstValue - 1,
                props.paginationSecondValue,
                kycdata
              )
            );
          }
        }
      }
    }

    return () => mounting = false;
  }, [props.kycdialog]);

  const handleClick = (item, type) => {
    setUrlPopUp("IMAGE");
    setImageUrl(item);
  };
  const handlePopUpClose = () => {
    setImagePopUp("");
    setImageUrl("");
    setUrlPopUp("");
  };
  const handleSetKycID = (id, key, status) => {
    setKycID(id);
    setImagePopUp(status);
  };

  const handleReject = (id) => {
    setDeclineBox(true)
    setKycID(id);
  }
  const rejectApiCall = (msg) => {
    dispatch(rejectKyc(msg, kycID, "REJECTED"));
    props.setKycdialog(false);
  }

  return (
    <>
      <Dialog__Main title={"Action Needed"} dOpen={declineBox} dClose={() => setDeclineBox(false)}>
        <KycCommentBox
          finish={(msg) => rejectApiCall(msg)}
          close={() => setDeclineBox(false)}
        />
      </Dialog__Main>
      <Dialog__Main
        dOpen={urlPopup}
        dClose={handlePopUpClose}
        title={urlPopup == "IMAGE" && "Document"}
        width={"sm"}
      >
        <div
          style={
            urlPopup == "IMAGE"
              ? {
                height: "400px",
                msOverflow: "scroll",
                userSelect: "text",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
              : {}
          }
        >
          {urlPopup == "IMAGE" && <img src={imageUrl} />}
        </div>
      </Dialog__Main>
      <Dialog__Main
        dOpen={imagePopUp}
        dClose={handlePopUpClose}
        title={imagePopUp == "CONFIRM" ? "DO YOU WANT TO CONFIRM DOCUMENT ?" : "DO YOU WANT TO REJECT DOCUMENT ?"}
        width={"sm"}
      >
        <div
          style={
            imagePopUp == "IMAGE"
              ? {
                height: "400px",
                msOverflow: "scroll",
                userSelect: "text",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
              : {}
          }
        >
          <div className='CMS-btnContainer'>
            <button
              className='CMS-btn CMS-btnStatus CMS-statusActive pointer'
              onClick={() => handleActive(imagePopUp == "CONFIRM" ? "KYC_PASSED" : "REJECTED")}
            >
              {COMMON_CONSTANTS.yesBtn}
            </button>
            <button
              className='CMS-btn CMS-btnStatus CMS-statusClosed pointer'
              onClick={() => handlePopUpClose()}
            >
              {COMMON_CONSTANTS.noBtn}
            </button>
          </div>
        </div>
      </Dialog__Main>
      <Dialog__Main
        dOpen={props.kycdialog}
        dClose={() => props.setKycdialog(false)}
        title={"KYC Verification"}
        width={"lg"}
      >
        <div className="CMS-box">
          <div className='CMS-box__Header'>
            <div className='CMS-box__title'>{KYC_CONSTANTS.identity_docs}</div>
          </div>
          {props.identityDocs?.data ? (

            <div className="CMS-box">
              <div className='CMS-table CMS-table-triped custom_height_200'>
                <table>
                  <thead>
                    <tr>
                      <th>{KYC_CONSTANTS.header_id}</th>
                      {props.kycPermission ? <th>{KYC_CONSTANTS.header_documentId}</th> : ""}
                      {props.kycPermission ? <th>{COMMON_CONSTANTS.header_name}</th> : ""}
                      {props.kycPermission ?
                        <th>{COMMON_CONSTANTS.header_firstname}</th> : ""}
                      {props.kycPermission ?
                        <th> {COMMON_CONSTANTS.header_lastname}</th> : ""}
                      {props.kycPermission ? <th>{KYC_CONSTANTS.header_dob}</th> : ""}
                      <th>{KYC_CONSTANTS.header_status}</th>
                      <th>{COMMON_CONSTANTS.updatedDate}</th>
                      <th>{KYC_CONSTANTS.header_docs}</th>
                      <th>{KYC_CONSTANTS.header_action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.identityDocs.data != undefined &&
                      Object.keys(props.identityDocs.data).length > 0 &&
                      props.identityDocs?.data?.length > 0 ? (
                      props.identityDocs.data.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            {props.kycPermission ?
                              <td>{item.docId}</td> : ""}
                            {props.kycPermission ?
                              <td>{`${item.firstName === null ? "" : item.firstName}`} {`${item.lastName === null ? "" : item.lastName}`}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.firstName}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.lastName}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.dob}</td> : ""}
                            <td>{item.docStatus}</td>
                            <td>{getDateTimeFormate(item.updatedDate)}</td>
                            <td>
                              <button
                                className='CMS-btn CMS-btnSecondary active CMS-btnSmall pointer'
                                onClick={() => handleClick(item.docPath)}
                                style={{ marginBottom: "2px" }}
                              >
                                {KYC_CONSTANTS.viewDoc_btn}
                              </button>
                            </td>
                            <td>
                              {item.docStatus !== "KYC_PASSED" &&
                                item.docStatus !== "REJECTED" && (
                                  <>
                                    <button
                                      className='CMS-btn CMS-btnStatus CMS-statusActive pointer'
                                      onClick={() =>
                                        handleSetKycID(
                                          item.id,
                                          "KYC_PASSED",
                                          "CONFIRM"
                                        )
                                      }
                                      style={{ marginBottom: "2px" }}
                                    >
                                      {KYC_CONSTANTS.header_confirm}
                                    </button>
                                    <button
                                      className='CMS-btn CMS-btnStatus CMS-statusClosed pointer'
                                      onClick={() => handleReject(item.id)}
                                    >
                                      {KYC_CONSTANTS.header_reject}
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no_data_screen">
                        <td colSpan={11}>{KYC_CONSTANTS.nokycDocfound}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="CMS-box">
          <div className='CMS-box__Header'>
            <div className='CMS-box__title'>{KYC_CONSTANTS.address_docs}</div>
          </div>
          {props.addressDocs?.data ? (
            <div className="CMS-box">
              <div className='CMS-table CMS-table-triped custom_height_200'>
                <table>
                  <thead>
                    <tr>
                      <th>{KYC_CONSTANTS.header_id}</th>
                      {props.kycPermission ? <th>{KYC_CONSTANTS.header_documentId}</th> : ""}
                      {props.kycPermission ? <th>{COMMON_CONSTANTS.header_name}</th> : ""}
                      {props.kycPermission ?
                        <th>{COMMON_CONSTANTS.header_firstname}</th> : ""}
                      {props.kycPermission ?
                        <th> {COMMON_CONSTANTS.header_lastname}</th> : ""}
                      {props.kycPermission ? <th>{KYC_CONSTANTS.header_dob}</th> : ""}
                      <th>{KYC_CONSTANTS.header_status}</th>
                      <th>{COMMON_CONSTANTS.updatedDate}</th>
                      <th>{KYC_CONSTANTS.header_docs}</th>
                      <th>{KYC_CONSTANTS.header_action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.addressDocs.data != undefined &&
                      Object.keys(props.addressDocs.data).length > 0 &&
                      props.addressDocs?.data?.length > 0 ? (
                      props.addressDocs?.data?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            {props.kycPermission ?
                              <td>{item.docId}</td> : ""}
                            {props.kycPermission ?
                              <td>{`${item.firstName === null ? "" : item.firstName}`} {`${item.lastName === null ? "" : item.lastName}`}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.firstName}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.lastName}</td> : ""}
                            {props.kycPermission ?
                              <td>{item.dob}</td> : ""}
                            <td>{item.docStatus} </td>
                            <td>{getDateTimeFormate(item.updatedDate)}</td>
                            <td>
                              <button
                                className='CMS-btn CMS-btnSecondary active CMS-btnSmall pointer'
                                onClick={() => handleClick(item.docPath)}
                              >
                                {KYC_CONSTANTS.viewDoc_btn}
                              </button>
                            </td>
                            <td>
                              {item.docStatus !== "KYC_PASSED" &&
                                item.docStatus !== "REJECTED" && (
                                  <>
                                    <button
                                      className='CMS-btn CMS-btnStatus CMS-statusActive pointer'
                                      onClick={() =>
                                        handleSetKycID(
                                          item.id,
                                          "KYC_PASSED",
                                          "CONFIRM"
                                        )
                                      }
                                      style={{ marginBottom: "2px" }}
                                    >
                                      {KYC_CONSTANTS.header_confirm}
                                    </button>
                                    <button
                                      className='CMS-btn CMS-btnStatus CMS-statusClosed pointer'
                                      onClick={() => handleReject(item.id)}
                                    >
                                      {KYC_CONSTANTS.header_reject}
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no_data_screen" >
                        <td colSpan={11}>{KYC_CONSTANTS.nokycDocfound}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog__Main>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.playersearch.userId,
    kycData: state.primaryplayer.kycData,
    kycType: state.primaryplayer.kycType,
    kycDoc: state.primaryplayer.kycDoc,
    kycDocAdd: state.primaryplayer.kycDocAdd,
    identityDocs: state.primaryplayer.identityDocs,
    addressDocs: state.primaryplayer.addressDocs,
    paginationFirstValue: state.playersearch.paginationFirstValue,
    paginationSecondValue: state.playersearch.paginationSecondValue,
    kycPermission: state.dashboard.kycPermission,
  };
};
export default connect(mapStateToProps)(Kyc);
