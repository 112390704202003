import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DateInputField from "react-datepicker";
import Tooltips from "../Tooltip";
const Datepicker = (props) => {
  const {
    title,
    name,
    value,
    setValue,
    errorMsg,
    maxDate,
    minDate,
    setSelected,
    inline,
    ids,
    info,
    showTimeSelect,
    disabled,
    minTime,
    maxTime,
    noInfo,
    ...rest
  } = props;
  // const [errorMsg, setErrorMsg] = useState("");
  // useEffect(() => {
  //   setErrorMsg(props.errors);
  // }, [props.errors]);
  const [date, setDate] = useState("");
  const handleChangeRaw = (date, name) => {
    if (date === null) return setValue("", name);
    const newRaw = new Date(date);
    if (newRaw instanceof Date && !isNaN(newRaw)) {
      setValue(newRaw, name);
    }
  };

  const onChange = (selectedDate) => {
    setDate(selectedDate)
    const currentDate = new Date();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    const selectedTime = selectedDate?.getHours() * 60 + selectedDate?.getMinutes();

    if (selectedDate?.toDateString() === currentDate.toDateString() && selectedTime < currentTime) {
      selectedDate.setHours(currentDate.getHours());
      selectedDate.setMinutes(currentDate.getMinutes());
      setDate(selectedDate);
    }
    setValue(selectedDate, name)
  }
  useEffect(() => {
    let mounting = true;

    if (mounting) {
      setDate(value);
    };
    return () => mounting = false;
  }, [value]);

  const updateNewDate = (selected) => {
    setValue(selected ? selected : date, name);
  }

  return (
    <>
      {inline ? (
        <>
          <div className='CMS-formControl-group'>
            <div className='form-group'>
              <DateInputField
                portalId='root-portal'
                id={ids}
                selected={date}
                onSelect={(date) => updateNewDate(date)}
                onChange={(date) => onChange(date)}
                onClickOutside={() => updateNewDate(date)}
                // value={value}
                name={name}
                showTimeSelect={showTimeSelect}
                placeholderText='DD/MM/YYYY'
                dateFormat={showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
                timeFormat={showTimeSelect ? "HH:mm" : ''}
                // timeIntervals={showTimeSelect ? 15 : ''}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                maxDate={maxDate ? maxDate : null}
                minDate={minDate ? minDate : null}
                autoComplete='off'
                // shouldCloseOnSelect={false}
                adjustDateOnChange={false}
              />
            </div>

            <div className='CMS-formAddon'>
              <span
                className='material-icons md-18'
                data-icon='calendar_today'
              ></span>
            </div>
          </div>
          {errorMsg && <div className='CMS-form-errorMsg'>{errorMsg}</div>}
        </>
      ) : (
        <div className='CMS-formGroup'>
          {!noInfo ? <div className='CMS-formLabel'>
            {title}
            &nbsp;&nbsp;
            <Tooltips info={info} />
          </div> : ''}
          <div className='CMS-formControl-group'>
            <div className='form-group'>
              <DateInputField
                portalId='root-portal'
                id={ids}
                selected={date}
                onSelect={(date) => updateNewDate(date)}
                onChange={(date) => onChange(date)}
                onClickOutside={() => updateNewDate(date)}
                // value={value}
                name={name}
                placeholderText='DD/MM/YYYY'
                dateFormat={showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
                timeFormat={showTimeSelect ? "HH:mm" : ''}
                showMonthDropdown
                showYearDropdown
                showTimeSelect={showTimeSelect}
                minTime={minTime}
                maxTime={maxTime}
                dropdownMode='select'
                maxDate={maxDate ? maxDate : null}
                minDate={minDate ? minDate : null}
                autoComplete='off'
                timeIntervals={5}
                // shouldCloseOnSelect={false}
                adjustDateOnChange={false}
                disabled={disabled}
              />
            </div>

            <div className='CMS-formAddon'>
              <span
                className='material-icons md-18'
                data-icon='calendar_today'
              ></span>
            </div>
          </div>
          <div className='CMS-form-errorMsg'>{errorMsg}</div>
        </div>
      )}
    </>
  );
};

export default Datepicker;
