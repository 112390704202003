import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
export const PaymentTickerSlice = createSlice({
  name: "paymentticker",
  initialState: {
    recordsToShow: [1, 25],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    paymentTickerData: [],
    paymentTickerTableData: [],
    isVisible: false,
    loader: true

  },
  reducers: {
    setPaymentTickerData: (state, action) => {
      state.paymentTickerData = action.payload;
    },
    setPaymentTickerTableData: (state, action) => {
      state.paymentTickerTableData = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsToShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    }
  },
});
export const {
  setPaymentTickerData,
  setPaymentTickerTableData,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setLoader,
} = PaymentTickerSlice.actions;

export const fetchPaymentTickerData =
  (paginationFirstValue, paginationSecondValue, params) => (dispatch) => {
    var obj = {
      amount: params.amout,
      endDate: params.endDate,
      paymentType: params.paymentType,
      startDate: params.startDate,
      status: params.status,
      transactionId: params.transactionId,
      transactionRef: params.transactionRef,
      userId: params.userId,
    };
    var url =
      `${paymentSearchApi.getPaymentSearch}` +
      "?pageNumber=" +
      paginationFirstValue +
      "&itemsPerPage=" +
      paginationSecondValue;

    apiCaller(url, "POST", obj).then((response) => {
      // dispatch(setIsVisible(false));
      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(setPaymentTickerData(response.data));
          // setTimeout(() => {
          //   dispatch(setIsVisible(true));
          // }, 400);
        }
      }
    });
  };

export const fetchPaymentTickerTableData =
  (pageNumber, itemPerPage, from, paymentTickerData) => (dispatch) => {
    var obj = { from: from };
    dispatch(setPaymentTickerTableData([]))
    dispatch(setLoader(true))
    var url =
      `${paymentSearchApi.getPaymentTickerData}` +
      "?pageNumber=" + pageNumber + "&itemsPerPage=" + itemPerPage
    apiCaller(url, "POST", obj).then((response) => {

      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(setLoader(false))
          dispatch(setIsVisible(true))
          // dispatch(setPaymentTickerTableData(response.data));
          if (from) {
            if (
              Array.isArray(response.data.data[0].paymentTicker) &&
              response.data.data[0].paymentTicker.length > 0
            ) {
              let copyPaymentTickerData = [
                ...paymentTickerData.data[0].paymentTicker,
              ];
              for (let obj of response.data.data[0].paymentTicker) {
                copyPaymentTickerData.unshift(obj);
              }
              let getCurrentTime = response.data.data[0].currentTime;
              let getNewRecords = response.data.totalRecords;
              let getExistingRecords = paymentTickerData.totalRecords;
              let updatedRecords =
                Number(getNewRecords) + Number(getExistingRecords);
              let formPaymentTickerData = {
                data: [
                  {
                    paymentTicker: response.data.data[0].paymentTicker,
                    currentTime: getCurrentTime,
                  },
                ],
                totalRecords: response.data.totalRecords,
              };
              dispatch(setPaymentTickerTableData(formPaymentTickerData));
            } else {
              let formPaymentTickerData = {
                data: [
                  {
                    paymentTicker: response.data.data[0].paymentTicker,
                    currentTime: response.data.data[0].currentTime,
                  },
                ],
                totalRecords: response.data.totalRecords,
              };
              dispatch(setPaymentTickerTableData(formPaymentTickerData));
            }
          } else {
            dispatch(setPaymentTickerTableData(response.data));
          }
        }
      }
    }).catch(err => {
      dispatch(setPaymentTickerTableData([]));
      dispatch(setLoader(false))
      dispatch(setIsVisible(true))
    });
  };

export default PaymentTickerSlice.reducer;