import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { COMMON_CONSTANTS, PASSWORD_CONSTANTS } from "../../sharedfiles/Constants";
import { setIntervalStopper } from "../AppSlice";

import { resetPassword } from "./PrimaryPlayerSlice";
import Tooltips from "../Common/Tooltip";
import { PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
export const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmpasscondition, setConfirmpasscondition] = useState(false);
  const [passwordCondition, setPasswordCondition] = useState(false);
  const [emptycondition, setEmptyCondition] = useState(false);
  const [validation, setValidation] = useState(false);
  const [success, setSuccess] = useState("");
  const passwordHandler = (e) => {
    setValidation(false);

    setPassword(e.target.value);
    const pass = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{10,}$/;
    if (e.target.value === "") {
      setValidation(true);
    } else if (e.target.value !== "") {
      setValidation(false);
      setSuccess("success");
    }
  };
  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    e.target.value && setPasswordCondition(false);
  };

  const savePassword = () => {
    if (confirmPassword == "" && password !== "") {
      setConfirmpasscondition(true);
      setPasswordCondition(false);
      setEmptyCondition(false);
    } else if (confirmPassword != password) {
      setPasswordCondition(true);
      setEmptyCondition(false);
      setConfirmpasscondition(false);
    }

    if (password !== "" || confirmPassword !== "") {
      // if (confirmPassword != password) {
      //   setPasswordCondition(true);
      //   setEmptyCondition(false);

      // }
      if (confirmPassword == password && success === "success") {
        dispatch(resetPassword(props.userId, password));
        dispatch(setIntervalStopper(false));
        // props.setOpenResetDialog(false);
        setPassword("");
        setConfirmPassword("");
      }
      if (password !== validation) {
        setValidation(true);
      }
    }

    if (password == "" && confirmPassword == "") {
      setEmptyCondition(true);
      setPasswordCondition(false);
      setConfirmpasscondition(false);
    }
  };
  const onCancel = () => {
    setPassword("");
    setConfirmPassword("");
    setPasswordCondition(false);
    setConfirmpasscondition("");
    setValidation(false);
    setEmptyCondition(false);
  };
  return (
    <React.Fragment>
      <div className='dialog-box3'>
        <div className='CMS-box CMS-box-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{PASSWORD_CONSTANTS.header_newPassword}</p>
                    <Tooltips info={PLAYER_MODULE.newPassword} />
                  </div>
                </div>
                <div className='CMS-formControl-group'>
                  <div className='CMS-formControl'>
                    <input
                      type='text'
                      name='password'
                      id='newpassword'
                      value={password}
                      maxLength='10'
                      placeholder='Enter password'
                      onChange={passwordHandler}
                    />
                  </div>
                  {emptycondition && (
                    <div className='text-danger'>
                      {PASSWORD_CONSTANTS.validPassword_ErrMsg}
                    </div>
                  )}
                </div>
              </div>
              {/* {validation  && (
                  <div className="text-danger text-center">
                   <p >password atleast have a uppercase,number</p>
                    <span>special character and minimum length is 10</span>
                </div>
                  )} */}
            </div>
            <div className='mt-20'></div>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'> {PASSWORD_CONSTANTS.header_confirmPassword}</p>
                    <Tooltips info={PLAYER_MODULE.confirmPassword} />
                  </div>
                </div>
                <div className='CMS-formControl-group'>
                  <div className='CMS-formControl'>
                    <input
                      type='text'
                      name='confirmPassword'
                      id='confirmPassword'
                      maxLength='10'
                      value={confirmPassword}
                      placeholder='Confirm Password'
                      onChange={confirmPasswordHandler}
                    />
                  </div>
                  {passwordCondition && (
                    <div className='CMS-form-errorMsg'>
                      {PASSWORD_CONSTANTS.PasswordnotMatch_ErrMsg}
                    </div>
                  )}
                  {emptycondition && (
                    <div className='text-danger'>
                      {PASSWORD_CONSTANTS.validPassword_ErrMsg}
                    </div>
                  )}
                  {confirmpasscondition && (
                    <div className='text-danger'>
                      {PASSWORD_CONSTANTS.confirmPassword_ErrMsg}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='mt-20'></div>
        </div>
      </div>

      <div className='CMS-btnContainer'>
        <button
          className='CMS-btnStatus CMS-statusActive CMS-btnSmall pointer'
          onClick={savePassword}
          id='newpassSave'
        >
          {COMMON_CONSTANTS.save}
        </button>
        <button
          className='CMS-btn CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer'
          onClick={onCancel}
          id='cancelpassword'
        >
          {COMMON_CONSTANTS.cancel}
        </button>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.playersearch.userId,
  };
};
export default connect(mapStateToProps)(ResetPassword);
