import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
// import { ErrorIcon, } from '@mui/icons-material/';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { regexType } from '../../sharedfiles/helper'
import { forceResetPassword } from './loginSlice'
import { useDispatch } from 'react-redux'
import { ErrorStackData } from '../Common/data/LoginHistoryData/LoginData'
import { useEffect } from 'react'
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
function AddNewPassword() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();
    const [errors, setErrors] = useState({
        previousPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [formData, setFormData] = useState({
        previousPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [errorStack, setErrorStack] = useState({
        len: '',
        num: '',
        caps: '',
        small: '',
        special: '',
    })
    const [isAllClear, setIsAllClear] = useState('')
    const loginToken = localStorage.getItem('totpToken')
    const forceResetPasswordData = useSelector(state => state.loginSlice.forceResetPasswordData);
    const onChangeHandler = (e) => {
        const { name, value } = e.target
        // errorSetter(name, value ? "" : "password cannot be empty");
        if (!(/\s/).test(value)) {

            setFormData((prevState) => {
                return {
                    ...prevState,
                    [name]: value,
                }
            })
            if (name === 'newPassword') matchCheker(name, value)
            validate(name, value);
        }
    }
    const errorSetter = (name, val) => {
        setErrors((prevErrors) => {
            return {
                ...prevErrors,
                [name]: val,
            }
        })
    }
    const handleStackErr = (name, err) => {
        setErrorStack((prevErr) => {
            return {
                ...prevErr,
                [name]: err,
            }
        })
    }

    const matchCheker = (name, value) => {
        let err = ''
        if (value === '' || value === undefined) {
            err = 'Password cannot be empty'
            setErrorStack({
                len: false,
                num: false,
                caps: false,
                small: false,
                special: false,
            })
            errorSetter(name, err)
        }
        if (value.length <= 7) {
            err = 'Password must be atleast 8 characters!'
            handleStackErr('len', false)
            errorSetter(name, err)
        } else {
            handleStackErr('len', true)
        }
        if (value.match(/^(?=.*?[A-Z])/)) {
            handleStackErr('caps', true)
        } else {
            err = 'Password must contain 1 Letter in Capital Case!'
            handleStackErr('caps', false)
            errorSetter(name, err)
        }
        if (value.match(/^(?=.*?[a-z])/)) {
            handleStackErr('small', true)
        } else {
            err = 'Password must contain 1 Letter in Small Case!'
            handleStackErr('small', false)
            errorSetter(name, err)
        }
        if (value.match(/^(?=.*?[0-9])/)) {
            handleStackErr('num', true)
        } else {
            err = 'Password must contain atleast 1 Number!'
            handleStackErr('num', false)
            errorSetter(name, err)
        }
        if (value.match(/^(?=.*?[-_@#$%^&*.])/)) {
            handleStackErr('special', true)
        } else {
            err = 'Password must contain 1 special charecter'
            handleStackErr('special', false)
            errorSetter(name, err)
        }
        return err
    }

    const validate = (name, value) => {
        let err = ''
        if (name === 'previousPassword') {
            if (value) {
                err = "";
            } else {
                err = 'Previous password is required'
            }
            errorSetter(name, err);
        }

        if (name === 'newPassword') {
            err = matchCheker(name, value)
            if (value === '') {
                err = 'New password cannot be empty'
            } else {
                if (formData.confirmNewPassword) {
                    if (value !== formData.confirmNewPassword) {
                        errorSetter('confirmNewPassword', 'Password doesnot match')
                    } else {
                        errorSetter('confirmNewPassword', '')
                    }
                }
            }
        }

        if (name === 'confirmNewPassword') {
            if (value === '') {
                err = 'Confirm passaword is required'
                errorSetter(name, err)
            } else {
                err = value !== formData.newPassword ? 'Password doesnot match' : ""
                errorSetter(name, err)
            }
        }
        return err
    }

    const onSubmit = () => {
        const checkPrevPass = validate('previousPassword', formData.previousPassword) === ''
        const checkNewPass = validate('newPassword', formData.newPassword) === ''
        const checkConfirmPass = validate('confirmNewPassword', formData.confirmNewPassword) === ''
        const payload = {
            newPassword: formData.confirmNewPassword,
            oldPassword: formData.previousPassword,
        }
        if (checkPrevPass && checkNewPass && checkConfirmPass) {
            setLoading(true)
            dispatch(forceResetPassword(payload, loginToken));
        }
    }

    useEffect(() => {
        const isAllClear = Object.keys(errorStack).every((item) => errorStack[item])
        setIsAllClear(isAllClear)
        if (isAllClear) {
            // set
            setErrors((prev) => {
                return {
                    ...prev,
                    newPassword: "",
                }
            })
        }
    }, [errorStack]);

    useEffect(() => {
        if (!forceResetPasswordData?.status) {
            setErrors((prev) => {
                return {
                    ...prev,
                    "previousPassword": forceResetPasswordData?.msg
                }
            });
            setLoading(false);
        } else {
            setLoading(false);
            setTimeout(() => history.push("login"), 1000)
        }
    }, [forceResetPasswordData]);

    const handleActivateCall = (e) => {
        if (e?.keyCode === 13) {
            onSubmit()
        }
    }

    useEffect(() => {
        let mounting = true;
        if (mounting) {
            document.addEventListener("keydown", handleActivateCall);
        }
        return () => {
            document.removeEventListener("keydown", handleActivateCall, true);
            mounting = false
        }
    }, [])

    return (
        <React.Fragment>
            <div className="CMS_totp_container">
                <div className="CMS_totp_wrapper">
                    <div className="CMS_resetPassword_box">
                        <div className="reset_password_title">Reset Password</div>
                        <div>
                            <div
                                className={`reset_password_input_title ${errors.previousPassword ? 'error' : ''
                                    }`}
                            >
                                Previous Password
                            </div>
                            <div
                                className={`totp_input_box ${errors.previousPassword ? 'error' : ''
                                    }`}
                            >
                                <input
                                    type="text"
                                    name="previousPassword"
                                    value={formData.previousPassword}
                                    className="totp_input_auth"
                                    placeholder="Enter previous password"
                                    // maxLength={8}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="CMS-form-errorMsg error">
                                {errors.previousPassword}
                            </div>
                        </div>
                        <div>
                            <div
                                className={`reset_password_input_title ${errors.newPassword ? 'error' : ''
                                    }`}
                            >
                                New Password
                            </div>
                            <div
                                className={`totp_input_box ${errors.newPassword ? 'error' : ''
                                    }`}
                            >
                                <input
                                    type="text"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    className="totp_input_auth"
                                    placeholder="Please enter new password"
                                    maxLength={16}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="input_errors_stack">
                                {isAllClear !== '' &&
                                    ErrorStackData.map((item) => {
                                        return (
                                            <span
                                                className={`${errorStack[item.key] === ''
                                                    ? ''
                                                    : errorStack[item.key]
                                                        ? 'success'
                                                        : 'error'
                                                    }`}
                                                key={item.id}
                                            // className="error"
                                            >
                                                {errorStack[item.key] === true ? (
                                                    <CheckCircleIcon className="stack_icon" />
                                                ) : (
                                                    errorStack[item.key] === false && (
                                                        <CancelIcon className="stack_icon" />
                                                    )
                                                )}
                                                {item.label}
                                            </span>
                                        )
                                    })}
                            </div>
                        </div>
                        <div>
                            <div
                                className={`reset_password_input_title ${errors.confirmNewPassword ? 'error' : ''
                                    }`}
                            >
                                Confirm Password
                            </div>
                            <div
                                className={`totp_input_box ${errors.confirmNewPassword ? 'error' : ''
                                    }`}
                            >
                                <input
                                    type="text"
                                    name="confirmNewPassword"
                                    value={formData.confirmNewPassword}
                                    className="totp_input_auth"
                                    placeholder="Confirm your new password"
                                    // maxLength={8}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="CMS-form-errorMsg error">
                                {errors.confirmNewPassword}
                            </div>
                        </div>
                        <div className={`totp_btn_confirm ${forceResetPasswordData?.status && "success"}`}>
                            <button onClick={onSubmit}>
                                {loading ? (
                                    <CircularProgress
                                        className="totp_circular_progress"
                                        size={26}
                                        thickness={4}
                                    />
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                            {/* <span className="totp_forgot_link">Forgot 2FA?</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddNewPassword
