import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserPermission, getBrand } from "../Dashboard/dashboardSlice";
import {
  createPermission,
  getRoleList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setRecordsShow,
  setDocsData,
  setBrandIdData,
  setRoleData,
  setPrePermissionData
} from "./createRoleSlice";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { Edit } from "./Edit";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Skelton from "../Common/skelton/Skelton";
import { COMMON_CONSTANTS, CREATEROLE_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { Admin, RoleInfo } from "../../sharedfiles/tooltipMsgs";
const UserPermission = (props) => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState({});
  const [role, setRole] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [item, setItem] = useState({});
  const [defaultData, setDefaultData] = useState({});
  const [brandName, setBrandName] = useState("");
  const [brandError, setBrandError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  // new state //
  const [selected, setSelected] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [ids, setIds] = useState([]);

  const pageFirstVal = useSelector((state) => state.createRole.paginationFirstValue);
  const pageSecondVal = useSelector((state) => state.createRole.paginationSecondValue);
  const recordsToShow = useSelector((state) => state.createRole.recordsShow);
  const listRole = useSelector((state) => state.createRole.roleList);
  // new state //
  const alreadySelected = props.prePermissionData.value;
  const setSelectedIds = (selectedIds) => {
    const isErr = selectedIds.length > 0
    setIds(selectedIds);
    const preIdErr = ids.length > 0;
    preIdErr && setPermissionError(!isErr);
    isErr && setPermissionError(false);
    dispatch(setPrePermissionData({ value: selectedIds, names: selected, error: !isErr }));
  };


  // MOUNTING //
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const dataExist = Object.keys(props?.roleList).length > 0
      if (!dataExist) {
        props.dispatch(getUserPermission());
        props.dispatch(getBrand());
        props.dispatch(
          getRoleList(props.paginationFirstValue - 1, props.paginationSecondValue)
        );
      } else {
        const csvDataExist = props?.docsData && Array.isArray(props.docsData) && props.docsData.length > 0
        let pdfData = [];
        if (csvDataExist) {
          pdfData = props?.docsData.slice(1, props?.docsData?.length);
        }
        setCsvData(props.docsData);
        setPdfBody(pdfData);

        const brand = props.brandIdData;
        setBrand(brand.brandDetails);
        setBrandName(brand.brandName)
        setBrandError(brand.error);

        const permisson = props.prePermissionData;
        setIds(permisson.value);
        setSelected(permisson.names);
        setPermissionError(permisson.error);
        if (permisson.value.length === 0) setPermissionError(false);
        const roles = props.roleData
        setRole(roles.value);
        setRoleError(roles.error);
        setExistError(roles.roleExistErr);

      }
    }
    return () => mounted = false;
  }, []);
  // MOUNTING //


  const handleChangeBrand = (e) => {
    const { value } = e.target;
    const err = value === "";
    setBrandName(value);
    setBrandError(err);

    const brandDataExist = props.brandData && Array.isArray(props.brandData) && Object.keys(props.brandData).length > 0
    if (value !== "") {
      const Brand = brandDataExist && props.brandData.filter((item) => item.name === value);
      const brandDetails = { brandId: Brand[0].brandId, clientId: Brand[0].clientId };
      setBrand(brandDetails);
      dispatch(setBrandIdData({ brandName: value, brandDetails: brandDetails, error: err }));
    } else {
      dispatch(setBrandIdData({ brandName: value, brandDetails: { brandId: "", clientId: "" }, error: err }));
    }
  };

  const handleChangeRole = (e) => {
    const { value } = e.target;
    const roleErr = value === "";
    setRoleError(roleErr);
    setExistError(false);
    setRole(value);
    dispatch(setRoleData({ value: value, error: roleErr, roleExistErr: false }));
  };

  const validate = () => {
    const brandErr = brandName === "";
    const roleErr = role === "";
    const permissionErr = ids.length > 0
    setBrandError(brandErr);
    setRoleError(roleErr);
    setPermissionError(!permissionErr);

    const isExist = Object.keys(props.roleList).length > 0 &&
      props?.roleList?.data.some((item) => parseInt([item.brandId]) === parseInt(brand.brandId) && item.roleName === role);

    setExistError(isExist);
    dispatch(setRoleData({ value: role, error: roleErr, roleExistErr: isExist }));
    dispatch(setBrandIdData({ brandName: brandName, brandDetails: brand, error: brandErr }));
    dispatch(setPrePermissionData({ value: ids, names: selected, error: !permissionErr }));
    return !brandErr && !roleErr && permissionErr && !isExist;
  }

  const handleAdd = () => {
    var data = {
      brandId: [brand.brandId],
      permissionId: ids,
      roleName: role,
      clientId: brand.clientId,
    };

    if (validate()) {
      dispatch(createPermission(data));
      setTimeout(() => {
        props.dispatch(
          getRoleList(
            props.paginationFirstValue - 1,
            props.paginationSecondValue
          )
        );
      }, 500);
      resetButton();
    }
  };

  const resetButton = () => {
    setBrand({});
    setRole("");
    setItem({});
    setBrandName("");
    // setBrandName("");
    setDefaultData({});
    setIds([]);
    setClearAll(true);
    setSelected([]);
    setBrandError(false);
    setPermissionError(false);
    setRoleError(false);
    setExistError(false);
    // props.dispatch(getRoleList(1, 25));
    // props.dispatch(setPaginationFirstValue(1));
    // props.dispatch(setPaginationSecondValue(25));
    // props.dispatch(setIsVisible(false));
    // props.dispatch(setRecordsShow([1, 25]));

    dispatch(setBrandIdData({ brandName: "", brandDetails: { brandId: "", clientId: "" }, error: "" }));
    dispatch(setRoleData({ value: "", error: false, roleExistErr: false }));

    dispatch(setPrePermissionData({ value: [], names: [], error: false }));
  };

  // const callDelete = (item) => {
  //   const itemData = {
  //     clientId: item.clientId,
  //     id: item.id,
  //   };
  //   setItem(itemData);
  //   setOpenDeleteDialog(true);
  // };

  const callEdit = (item) => {
    const itemData = {
      clientId: item.clientId,
      id: item.id,
      permissionId: item.permissionId,
    };
    setItem(itemData);
    setDefaultData(item);
    setOpenEditDialog(true);
  };

  const headers = [
    ["ID", "Role Name", "Client Id", "created Date", "Updated Date", "created By", "updated By"],
  ];
  const exportPDF = () => {
    const title = "Create Role Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (props.roleList && Object.keys(props.roleList).length > 0) {
      const data =
        props.roleList && Array.isArray(props.roleList.data)
          ? props.roleList?.data?.map((p) => [
            p.id,
            p.roleName,
            p.clientId,
            p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
            p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
            p.addedBy,
            p.updatedBy,
          ])
          : [];
      setPdfBody(data);
      const csvData = data.length > 0 ? [headers[0], ...data] : [headers[0], []];
      setCsvData(csvData);
      dispatch(setDocsData(csvData));
    }
  }, [props.roleList]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));

    dispatch(getRoleList(pageNumber - 1, itemsPerPage));
  };

  const tbHeader = ["ID", "Role Name", "Permission Name", "Client Id", "created Date", "Updated Date", "created By", "updated By", "Action"]
  return (
    <>
      <Dialog__Main
        title={"Edit Role"}
        dOpen={openEditDialog}
        dClose={() => setOpenEditDialog(false)}
        width={"sm"}
        dHeight={"300px"}
        id={"editRoledialog"}
      >
        <Edit
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          brandData={props.brandData}
          userPermission={props.userPermission}
          roleList={props.roleList}
          defaultData={defaultData}
          item={item}
          permissions={props.permissions}
        />
      </Dialog__Main>

      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-12'>
                <div className='CMS-cardBox' style={{ paddingBottom: "20px" }}>
                  <div className='row'>
                    <div className='col-md-6 col-lg-4 col-xl-4'>
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {CREATEROLE_CONSTANTS.brandId}
                            &nbsp;&nbsp;
                            <Tooltips info={Admin.Brand_Id} />
                          </div>
                        </div>
                        <div className='col-10'>
                          <div className='CMS-formControl'>
                            <div className='CMS-number-of-files CMS-select'>
                              <select
                                name='betStatus'
                                id='RoleBrand'
                                value={brandName}
                                onChange={handleChangeBrand}
                                className="pointer"
                              >
                                <option value=''>select</option>
                                {props.brandNameList.map((brand) => {
                                  return (
                                    <option
                                      key={brand}
                                      value={brand}
                                    >
                                      {brand}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {brandError && (
                            <div className='text-danger'>
                              {CREATEROLE_CONSTANTS.brand_ErrMsg}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-xl-4'>
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {CREATEROLE_CONSTANTS.permission_name}
                            &nbsp;&nbsp;
                            <Tooltips info={Admin.Permission_Name} />
                          </div>
                        </div>
                        <div className='col-10'>
                          <MultiSelectDDL
                            options={props.permissions}
                            setSelected={setSelected}
                            setIds={setSelectedIds}
                            ids={ids}
                            selectedOnce={selected}
                            clearAll={clearAll}
                            setClear={setClearAll}
                            deafaultValues={alreadySelected}
                          // name="namePermission"
                          />
                          {permissionError && (
                            <div className='text-danger'>
                              {CREATEROLE_CONSTANTS.permission_ErrMsg}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6 col-lg-4 col-xl-4'>
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {CREATEROLE_CONSTANTS.header_role}
                            &nbsp;&nbsp;
                            <Tooltips info={Admin.role} />
                          </div>
                        </div>
                        <div className='col-10'>
                          <div className='CMS-formControl'>
                            <input
                              type='text'
                              id='role'
                              placeholder='Role'
                              name='role'
                              value={role}
                              onChange={handleChangeRole}
                            />
                          </div>
                          {roleError && (
                            <div className='text-danger'>
                              {CREATEROLE_CONSTANTS.role_ErrMsg}
                            </div>
                          )}
                          {existError && (
                            <div className='text-danger'>
                              {CREATEROLE_CONSTANTS.roleExist_ErrMsg}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-20'></div>

            <div className='row'>
              <ButtonGroup
                groupNames={["Create Role", "Reset"]}
                idsearch={"createRole"}
                idreset={"createRolereset"}
                submit={(e) => handleAdd(e)}
                reset={(e) => resetButton(e)}
              />
            </div>
          </div>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={listRole?.data}
                totalRecords={listRole?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Create Role Report"}
              />
            </div>
            {
              props?.isVisible ?
                (
                  <div className='CMS-box'>
                    <div className='CMS-table CMS-table-triped'>
                      <table>
                        <thead>
                          <tr>
                            {tbHeader.map((item, ind) => (
                              <th>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <p className='mx-3'>{item}</p>
                                  <Tooltips info={`${RoleInfo[ind]}`} />
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props?.roleList &&
                              props?.roleList?.data &&
                              props?.roleList?.data != undefined &&
                              Array.isArray(props?.roleList?.data) &&
                              Object.keys(props?.roleList.data).length > 0 ? (
                              props?.roleList?.data?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.roleName}</td>
                                    <td>
                                      {
                                        props?.roleList?.data !== undefined &&
                                        Object.keys(props?.roleList.data).length > 0 &&
                                        item?.permissions?.length > 0 &&
                                        item?.permissions?.map((item, ind) => {
                                          const renderNumber = ind + 1;
                                          return (
                                            <React.Fragment key={ind}>
                                              {`${item != "" ? item : " - "},  `}
                                              {renderNumber % 3 === 0 ? <br /> : null}
                                            </React.Fragment>
                                          );
                                        })}
                                    </td>
                                    <td>{item.clientId}</td>
                                    <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                                    <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                                    <td>{item.createdBy}</td>
                                    <td>{item.updatedByUserName || '-'}</td>
                                    <td>
                                      <div className='CMS-tblActions'>
                                        <span
                                          className='material-icons edit pointer'
                                          onClick={() => callEdit(item)}
                                        >
                                          edit
                                        </span>
                                        {/* <span className='material-icons error'
                                        onClick={() => callDelete(item)}
                                        >
                                          cancel
                                          
                                        </span> */}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <No__Data__Found colspan={9} />
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <Skelton />
                )
            }

          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.playersearch.userId,
    userPermission: state.dashboard.userPermission,
    brandData: state.dashboard.brandData,
    brandNameList: state.dashboard.brandNameList,
    roleList: state.createRole.roleList,
    permissions: state.dashboard.permissions,
    paginationFirstValue: state.createRole.paginationFirstValue,
    paginationSecondValue: state.createRole.paginationSecondValue,
    isVisible: state.createRole.isVisible,
    docsData: state.createRole.docsData,
    brandIdData: state.createRole.brandIdData,
    roleData: state.createRole.roleData,
    prePermissionData: state.createRole.prePermissionData,
  };
};
export default connect(mapStateToProps)(UserPermission);
