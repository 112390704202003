import React from "react"
export default function Loader({ colspan }) {
    return (
        <div className="CMS_loader_wrapper">
            <div className={`lds-roller ${colspan ? "ml-680" : ''}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}