import React from 'react'
import Input from "../Common/common_inputs/Input";
import { BecomeAfiliateData } from "../Common/data/mapData";
import Tooltips from '../Common/Tooltip';

const AgentEditDialog = (props) => {
    const { filedsHandler, onSave, agentEditError, affiliateInput } = props;
    return (
        <div align="center">
            <div className="dialog-box" align="center">
                <div className="CMS-box CMS-box-content">
                    {BecomeAfiliateData.map((item, ind) => {
                        return (
                            <React.Fragment key={item.id}>
                                <div className="row">
                                    <div className="col-lg-8 col-xl-8">
                                        <div className="CMS-formGroup formGroup-inline">
                                            <div className="CMS-formLabel">
                                                {item.title}
                                                &nbsp;&nbsp;
                                                <Tooltips info={item.info} />
                                            </div>
                                            <div className="CMS-formControl-group">
                                                <div className="CMS-formControl">
                                                    <Input
                                                        errorMsg={
                                                            agentEditError.length > 0 &&
                                                            agentEditError.includes(item.name) &&
                                                            item.errorMsg
                                                        }
                                                        name={item.name}
                                                        value={affiliateInput[item.name]}
                                                        regex={item.regex}
                                                        setValue={(e) => filedsHandler(e, "dialog")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="mt-20"></div>

                    <div
                        className="row"
                        style={{ justifyContent: "center", display: "grid" }}
                    >
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="CMS-btnContainer">
                                <button
                                    onClick={(e) => onSave(e)}
                                    className="CMS-btnStatus CMS-statusActive pointer"
                                    type="button"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentEditDialog
