import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { formatMoney } from "../../sharedfiles/helper";
import { updatePendingDeposit } from "./pendingDepositSlice";

export default function AcceptActionBox(props) {
    const { data, closeDialog } = props;
    const dispatch = useDispatch();
    const [comments, setComments] = useState("");
    const [error, setError] = useState("");

    function commentHandler(e) {
        const { value } = e.target;
        setComments(value);
        setError(value ? "" : "Please enter valid Comments");
    }

    const validate = (msg) => {
        if (msg.length < 4) {
            setError("Please enter valid Comments");
            return false;
        }
        setError("")
        return true;
    }
    const onSubmit = (e) => {
        const isvalid = validate(comments);
        let params = {
            id: data?.id,
            message: comments,
            status: "CONFIRMED",
            amount: data?.amount,
        };
        if (isvalid) {
            dispatch(updatePendingDeposit(params));
            closeDialog();
        }
    }
    return (
        <div>
            <div className="dialog-box">
                <div className="CMS-box CMS-box-content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12 d-flex aligin-items-center">
                            <div className="CMS-formGroup formGroup-inline">
                                <div className="CMS-formLabel">Amount (INR)</div>
                                <div className="CMS-formControl-group">
                                    <div className="CMS-formControl">
                                        {formatMoney(data?.amount)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 d-flex aligin-items-center">
                            <div className="CMS-formGroup formGroup-inline">
                                <div className="CMS-formLabel">Operation</div>
                                <div className="CMS-dropdown CMS-formControl">
                                    <span>ACCEPT</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="CMS-formGroup formGroup-inline">
                                <div className="CMS-formLabel">Comment</div>
                                <div className="CMS-formControl-group">
                                    <div className="CMS-formControl">
                                        <textarea
                                            onChange={commentHandler}
                                            value={comments}
                                            rows="2"
                                            cols="50"
                                            name="comments"
                                            id="ManualpaymentNotes"
                                        ></textarea>
                                        {error ?
                                            <div className='CMS-form-errorMsg' style={{
                                                textAlign: "start",
                                                margin: 0,
                                                padding: 0
                                            }}>
                                                {error}
                                            </div> : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="CMS-btnContainer">
                        <button
                            onClick={(e) => onSubmit(e)}
                            className="CMS-btnStatus CMS-statusActive pointer"
                            type="button"
                            id="Approve"
                        >
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
