import React from 'react'
import { useSelector } from "react-redux";
import ReportsWrapper from "../ReportsWrapper"
import { getLiabiltyEventReport, getLiabiltyMarketReport, setEventLevelDates, setMarketLevelDates } from "../reportSlice";
import EventLevelTable from './EventLevelTable'
import MarketLevelTable from './MarketLevelTable'

const LiabilityReport = () => {
    const {
        eventLevelData,
        eventLevelDates,
        marketLevelData,
        marketLevelDates,
        paginationFirstValue,
        paginationSecondValue,
    } = useSelector((st) => st.reports);
    return (
        <React.Fragment>
            <ReportsWrapper
                reportTitle={"EVENT LEVEL"}
                currentTab={"LIABILITY REPORT"}
                setDateToStore={setEventLevelDates}
                persistDate={eventLevelDates}
                sportData={eventLevelData}
                pageNumber={paginationFirstValue}
                itemsPerPage={paginationSecondValue}
                api={getLiabiltyEventReport}
                eventPagination={true}
                ReportTable={() => (
                    <EventLevelTable
                        dates={eventLevelDates}
                        reportsData={eventLevelData}
                        reportPage={"Event Level Report"}
                    />
                )}
            />

            <ReportsWrapper
                reportTitle={"MARKET/OUTCOME LEVEL"}
                currentTab={"LIABILITY REPORT"}
                setDateToStore={setMarketLevelDates}
                persistDate={marketLevelDates}
                sportData={marketLevelData}
                pageNumber={paginationFirstValue}
                itemsPerPage={paginationSecondValue}
                api={getLiabiltyMarketReport}
                marketLevelPagination={true}
                ReportTable={() => (
                    <MarketLevelTable
                        dates={marketLevelDates}
                        reportsData={marketLevelData}
                        reportPage={"Market/Outcome Level Report"}
                    />
                )}
            />

        </React.Fragment>

    )
}

export default LiabilityReport