import { createSlice } from "@reduxjs/toolkit";
import { CyrusUIApis } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import {
    cleanObject,
    messageEnhancer,
    toaster,
} from "../../sharedfiles/helper";
export const CyrusUiSlice = createSlice({
    name: "cyrusuislice",
    initialState: {
        betSettlementData: [],
        page: 1,
        pageCount: 25,
        recordShow: [1, 25],
        prevPayload: {},
        betSettlementStates: {},
        viewTransactionData: [],
        csvData: [],
        pdfBody: [],
        isVisible: false,
        isTransactionVisible: false,
        betSelection: [],
        isBetDetailsVisible: false,
        manualSettlementData: [],
        ticketId: '',
        viewAstraData: [],
        viewAstraLoading: false,
        viewAstraBetDetails: [],
        viewBetDetailsLoading: false,
        viewBetDetailsPage: 1,
        viewBetdetailsPageCount: 25,
        viewBetDetailsRecordShow: [1, 25],
    },
    reducers: {
        setBetSettlementData: (state, action) => {
            state.betSettlementData = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setPageCount: (state, action) => {
            state.pageCount = action.payload;
        },
        setRecordShow: (state, action) => {
            state.recordShow = action.payload;
        },
        setPrevPayload: (state, action) => {
            state.prevPayload = action.payload;
        },
        setBetSettlementStates: (state, action) => {
            state.betSettlementStates = action.payload;
        },
        setValue: (state, action) => {
            state.value = action.payload;
        },
        setIsvisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setViewTransactionData: (state, action) => {
            state.viewTransactionData = action.payload;
        },
        setCsvData: (state, action) => {
            state.csvData = action.payload;
        },
        setPdfBody: (state, action) => {
            state.pdfBody = action.payload;
        },
        setIsTransactionVisible: (state, action) => {
            state.isTransactionVisible = action.payload;
        },
        setBetSelection: (state, action) => {
            state.betSelection = action.payload;
        },
        setIsBetDetailsVisible: (state, action) => {
            state.isBetDetailsVisible = action.payload;
        },
        setManualSettlementData: (state, action) => {
            state.manualSettlementData = action.payload;
        },
        setTicketId: (state, action) => {
            state.ticketId = action.payload;
        },
        setViewAstraData: (state, action) => {
            state.viewAstraData = action.payload;
        },
        setViewAstraLoading: (state, action) => {
            state.viewAstraLoading = action.payload;
        },
        setViewAstraBetDetails: (state, action) => {
            state.viewAstraBetDetails = action.payload;
        },
        setViewBetDetailsLoading: (state, action) => {
            state.viewBetDetailsLoading = action.payload;
        },
        setViewBetDetailsPage: (state, action) => {
            state.viewBetDetailsPage = action.payload;
        },
        setViewBetdetailsPageCount: (state, action) => {
            state.viewBetdetailsPageCount = action.payload;
        },
        setViewBetDetailsRecordShow: (state, action) => {
            state.viewBetDetailsRecordShow = action.payload;
        },
    },
});
export const {
    setBetSettlementData,
    setPage,
    setPageCount,
    setRecordShow,
    setPrevPayload,
    setIsvisible,
    setBetSettlementStates,
    setViewTransactionData,
    setCsvData,
    setPdfBody,
    setIsTransactionVisible,
    setBetSelection,
    setIsBetDetailsVisible,
    setManualSettlementData,
    setTicketId,
    setViewAstraData,
    setViewAstraLoading,
    setViewAstraBetDetails,
    setViewBetDetailsLoading,
    setViewBetDetailsPage,
    setViewBetdetailsPageCount,
    setViewBetDetailsRecordShow,
} = CyrusUiSlice.actions;

export const getBetSettlementData =
    ({ page, pageCount, params }) =>
        (dispatch) => {
            const url =
                CyrusUIApis.getBetSettlement +
                "?pageNumber=" +
                page +
                "&itemsPerPage=" +
                pageCount;
            let obj = {
                betTypes: params ? params.betTypes : null,
                tId: params ? params.tId : null,
                ticketShortId: params ? params.ticketShortId : null,
                betCount: params ? params.betCount : null,
                competitionId: params ? params.competitionId : null,
                competitionName: params ? params.competitionName : null,
                placedOn: params ? params.placedOn : null,
                settledOn: params ? params.settledOn : null,
                status: params ? params.status : null,
                sportName: params ? params.sportName : null,
            };
            const hydratedFields = cleanObject(obj);

            apiCaller(url, "POST", hydratedFields)
                .then((response) => {
                    if (response !== null && response) {
                        if (response.status == 200) {
                            dispatch(setBetSettlementData(response.data));
                            dispatch(setIsvisible(true));
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

export const viewTransaction = (ticketId) => (dispatch) => {
    const url = `${CyrusUIApis.viewTransaction}?betId=${ticketId}`;
    apiCaller(url, "GET")
        .then((response) => {
            if (response !== null && response) {
                if (response.status == 200) {
                    dispatch(setIsTransactionVisible(true));
                    dispatch(setViewTransactionData(response.data));
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const SyncBet = (ticketId) => () => {
    const url = `${CyrusUIApis.syncBet}?betId=${ticketId}`;
    apiCaller(url, "GET")
        .then((response) => {
            if (response !== null && response) {
                if (response.status == 200) {
                    toaster.success(messageEnhancer(response.msg));
                }
            }
        })
        .catch((err) => {
            console.log(err);
            toaster.error("FAILED");
        });
};
export const viewBetDetails = (ticketID) => (dispatch) => {
    const url = `${CyrusUIApis.betDeatils}?betId=${ticketID}`;
    apiCaller(url, "GET")
        .then((response) => {
            if (response !== null && response) {
                if (response.status == 200) {
                    dispatch(setBetSelection(response.data));
                    dispatch(setIsBetDetailsVisible(true));
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const RollBack = (id, betSelectionID) => (dispatch) => {
    const url = `${CyrusUIApis.rollBack}?betId=${id}&betSelectionId=${betSelectionID}`;
    apiCaller(url, "GET")
        .then((response) => {
            if (response !== null && response) {
                if (response.status == 200) {
                    toaster.success(messageEnhancer(response.msg));

                }
            }
        })
        .catch((err) => {
            console.log(err);
            toaster.error("FAILED");
        });
};

export const manualSettlement = (params) => (dispatch) => {
    const url =
        CyrusUIApis.manualSettlement

    let obj = {
        selectionStatus: params.selectionStatus,
        selectionId: params.selectionId,
        betId: params.betId,
    };
    // const hydratedFields = cleanObject(obj);
    apiCaller(url, "POST", obj)
        .then((response) => {
            if (response !== null && response) {
                if (response.status == 200) {
                    dispatch(setManualSettlementData(response.data));
                    toaster.success(messageEnhancer(response.msg));
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export const viewAstra = ({ betId }) => (dispatch) => {
    const url = CyrusUIApis.viewBetDetails +
        "?betId=" + betId
    apiCaller(url, "GET")
        .then((response) => {
            if (response && response.status >= 200 && response.status < 300) {
                dispatch(setViewAstraData(response.data));
                dispatch(setViewAstraLoading(true));
            } else {
                console.error(`Failed to fetch data: ${response.status}`);
            }
        })
        .catch((err) => {
            console.error('Error occurred while fetching data:', err);
        });
}

export const viewBetDetailsOnAstra = ({ page, pageCount, betId }) => (dispatch) => {
    const url = CyrusUIApis.viewAstra +
        "?betId=" + betId +
        "&pageNumber=" +
        page +
        "&itemsPerPage=" +
        pageCount;
    apiCaller(url, "GET")
        .then((response) => {
            if (response && response.status >= 200 && response.status < 300) {
                dispatch(setViewAstraBetDetails(response.data));
                dispatch(setViewBetDetailsLoading(true));
            } else {
                console.error(`Failed to fetch data: ${response.status}`);
            }
        })
        .catch((err) => {
            console.error('Error occurred while fetching data:', err);
        });
}


export default CyrusUiSlice.reducer;
