import React from 'react'

export default function VButtonGroup(props) {
    const { accept, decline, buttons, depoProof } = props;
    return (
        <div style={{
            justifyContent: "center",
            display: "grid",
        }}>
            {buttons.includes("Accept") ?
                <button
                    onClick={accept}
                    className={`CMS-btnStatus CMS-statusActive pointer ${buttons.length > 1 && "mb-10"}`}
                    id='DepositAcceptbtn'
                >
                    {buttons ? buttons[0] : "Accept"}
                </button> : null
            }
            {buttons.includes("Decline") ?
                <button
                    onClick={decline}
                    className='CMS-btnStatus CMS-statusClosed pointer'
                    id='DepositDeclinebtn'
                >
                    {buttons ? buttons[buttons.length - 1] : "Decline"}
                </button> : null
            }
        </div>
    )
}

