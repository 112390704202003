import React, { useState } from "react";
import { exportPdf } from "../../sharedfiles/jsPdf";
import { formatMoney, Currency } from "../../sharedfiles/helper";
import ReportsTable from "../Common/table/ReportsTable";
const CustomerTable = (props) => {
  const { dates } = props;
  const [fileType, setFileType] = useState("");

  const headers = [
    [
      "BetCount",
      `Payout ${Currency()}`,
      `Revenue ${Currency()}`,
      `Cash staked ${Currency()}`,
      "UserName",
    ],
  ];
  const bodyOne =
    Object.keys(props.sportData).length > 0 &&
    Array.isArray(props.sportData?.customersByRevenue) &&
    props.sportData?.customersByRevenue?.length > 0 &&
    props.sportData?.customersByRevenue?.map((item) => [
      item.betCount,
      formatMoney(item.payout),
      formatMoney(item.revenue),
      formatMoney(item.stake),
      item.userName,
    ]);
  const bodyTwo =
    Object.keys(props.sportData).length > 0 &&
    Array.isArray(props.sportData?.customersByStakes) &&
    props.sportData?.customersByStakes?.length > 0 &&
    props.sportData?.customersByStakes.map((item) => [
      item.betCount,
      formatMoney(item.payout),
      formatMoney(item.revenue),
      formatMoney(item.stake),
      item.userName,
    ]);
  const bodyThree =
    Object.keys(props.sportData).length > 0 &&
    Array.isArray(props.sportData?.customersByWin) &&
    props.sportData?.customersByWin?.length > 0 &&
    props.sportData.customersByWin.map((item) => [
      item.betCount,
      formatMoney(item.payout),
      formatMoney(item.revenue),
      formatMoney(item.stake),
      item.userName,
    ]);
  const csvTableOne =
   bodyOne && bodyOne.length > 0 ? [headers[0], ...bodyOne] : [];
  const csvTableTwo =
   bodyTwo && bodyTwo.length > 0 ? [headers[0], ...bodyTwo] : [];
  const csvTableThree =
   bodyThree && bodyThree.length > 0 ? [headers[0], ...bodyThree] : [];
  const csvData = [...csvTableOne, ...csvTableTwo, ...csvTableThree];

  const title = props.reportPage;
  const subtitle1 = "CUSTOMERS BY REVENUE";
  const subtitle2 = "CUSTOMERS BY STAKES";
  const subtitle3 = "CUSTOMERS BY WIN ";
  const searchDate = {
    fromDate: dates.dateFrom,
    toDate: dates.dateTo,
  };

  const styles = {
    1: {
      halign: "right",
      valign: "middle",
    },
    2: {
      halign: "right",
      valign: "middle",
    },
    3: {
      halign: "right",
      valign: "middle",
    },
  };
  const alignments = ["1", "2", "3"];
  const firstTable = {
    subtitle: subtitle1,
    headers: headers,
    body: bodyOne,
    styles,
    alignments,
  };
  const secondTable = {
    subtitle: subtitle2,
    headers: headers,
    body: bodyTwo,
    styles,
    alignments,
  };
  const thirdTable = {
    subtitle: subtitle3,
    headers: headers,
    body: bodyThree,
    styles,
    alignments,
  };
  const tableData = [firstTable, secondTable, thirdTable];
  const printPdf = () => {
    exportPdf(title, searchDate, tableData);
  };

  return (
    <>
      <ReportsTable
        tableData={tableData}
        fileType={fileType}
        setFileType={(value) => setFileType(value)}
        csvData={csvData}
        fileName={title}
        printPdf={() => printPdf()}
      />
    </>
  );
};

export default CustomerTable;
