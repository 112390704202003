import { CircularProgress, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { regexType } from '../../sharedfiles/helper'
import { enableTotp, getTotpDetatils } from '../Login/loginSlice'
function TotpAuth(props) {
  const [formData, setFormData] = useState({
    totpPin: '',
    passcode: '',
  })
  const [errors, setErrors] = useState({
    totpPin: '',
    passcode: '',
  })
  const [enabled, setEnabled] = useState('Enable')
  const [loading, setLoading] = useState(false)
  const [copy, setCopy] = useState(false)
  const dispatch = useDispatch()
  const totpDetails = useSelector((state) => state.loginSlice.totpData)
  const totpEnabledData = useSelector(
    (state) => state.loginSlice.totpEnabledData,
  )
  const history = useHistory()
  const loginToken = localStorage.getItem('totpToken')
  const authStatus = useSelector((state) => state.loginSlice.authStatus)

  const totpHandler = (e) => {
    const { name, value } = e.target
    if (name === 'totpPin') {
      if (value === '' || regexType.numeric.test(value)) {
        setFormData((prev) => {
          return {
            ...prev,
            [name]: value,
          }
        })
        validator(name, value)
      }
    }
    if (name !== 'totpPin') {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        }
      })
      validator(name, value)
    }
  }

  const errorSetter = (key, err) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: err,
      }
    })
  }
  const validator = (key, val) => {
    if (key === 'totpPin') {
      let err = ''
      if (val === '') err = 'TOTP Pin required'
      else err = ''
      errorSetter(key, err)
    } else {
      let err = ''
      if (val === '') err = 'passcode required'
      else err = ''
      errorSetter(key, err)
    }
  }

  const copyTotpCode = (e) => {
    setCopy(true)
    navigator.clipboard.writeText(totpDetails?.totpSecret)
  }

  const onSubmit = () => {
    validator('totpPin', formData.totpPin)
    validator('passcode', formData.passcode)
    if (formData.totpPin !== '' && formData.passcode !== '') {
      setLoading(true)
      const payload = {
        password: formData.passcode,
        totpPin: formData.totpPin,
        totpToken: totpDetails.totpToken,
      }
      dispatch(enableTotp(payload, loginToken))
    }
  }

  useEffect(() => {
    if (Object.keys(authStatus).length > 0) {
      if (authStatus.status) {
        setEnabled('Enabled')
        setLoading(false)
        setTimeout(() => history.push('login'), 1000)
      } else {
        const isTotpErr = authStatus.field === "totp";
        const key = isTotpErr ? "totpPin" : "passcode";
        const msg = isTotpErr ? "Invalid TOTP Pin" : "Invalid Password";
        errorSetter(key, msg);
        setLoading(false);
      }
    }
  }, [authStatus])

  const copyTimer = () => setTimeout(() => setCopy(false), 1000)

  useEffect(() => {
    if (copy) copyTimer()
  }, [copy])

  useEffect(() => {
    let mounting = true
    if (mounting) {
      if (loginToken) {
        dispatch(getTotpDetatils(props, loginToken))
      } else {
        history.push('login')
      }
    }
    return () => {
      clearTimeout(copyTimer)
      mounting = false
    }
  }, [])

  return (
    <React.Fragment>
      <div className="CMS_totp_container">
        <div className="CMS_totp_Wrapper">
          <div className="CMS_totp_content">
            <div className="totp_title">2 FACTOR AUTHENTICATION</div>
            {/* <div className="totp_body"> */}
            <div className="totp_scanner">
              <img
                className="totp_qr_code"
                src={totpDetails?.totpImg}
                alt="qr-code"
              />
              {/* <span className="totp_qr_text" onClick={copyTotpCode}>
                  Can't scan? Copy the Key
                </span> */}
              <Tooltip
                title="copied"
                arrow
                open={copy}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                {/* <Button>Arrow</Button> */}
                <span className="totp_qr_text" onClick={copyTotpCode}>
                  Can't scan? Copy the Key
                </span>
              </Tooltip>
            </div>
            {/* <div className="totp_auth"> */}
            <div className="totp_info_box">
              <p className="body_title">
                Scan the QR code on your authenticator App
              </p>
              <span className="body_subtitle">
                Once scanned, the app should give you a 6 digit TOTP, Enter it
                here
              </span>
            </div>
            {/* <div className="totp_fileds"> */}
            <div className="fields">
              <div className="totp_code label">TOTP Pin*</div>
              <input
                type="text"
                id="totp_code"
                name="totpPin"
                maxLength={6}
                placeholder="6 digit TOTP"
                className="totp_input text"
                value={formData.totpPin}
                onChange={totpHandler}
              />
              <div className="CMS-form-errorMsg err_msg">{errors.totpPin}</div>
            </div>
            <div className="fields">
              <div className="passcode label">password*</div>
              <input
                type="text"
                placeholder="account password"
                name="passcode"
                className="totp_input password"
                value={formData.passcode}
                onChange={totpHandler}
              />
              <div className="CMS-form-errorMsg err_msg">{errors.passcode}</div>
            </div>
            {/* </div> */}
            <div className="totp_buttons">
              <button
                className={
                  enabled === 'Enabled'
                    ? 'totp_btn_enable success'
                    : 'totp_btn_enable'
                }
                onClick={onSubmit}
              >
                {loading ? (
                  <CircularProgress
                    className="totp_circular_progress"
                    size={24}
                    thickness={4}
                  />
                ) : (
                  enabled
                )}
              </button>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TotpAuth
