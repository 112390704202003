import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { clientApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const CreateClientSlice = createSlice({
  name: "createclient",
  initialState: {
    clientList: [],
    clientData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
  },
  reducers: {
    setClientList: (state, action) => {
      state.clientList = action.payload;
    },
    setClientData: (state, action) => {
      state.clientData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },

  },
});
export const { setClientList, setClientData, setPaginationFirstValue, setPaginationSecondValue, setIsVisible, setRecordsShow } = CreateClientSlice.actions;

export const getClients = (pageNumber, itemPerPage) => (dispatch) => {
  var url = `${clientApi.getClient}` + `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  apiCaller(url, "GET")
    .then((response) => {
      if (response !== null && response) {
        dispatch(setClientList(response.data))
        dispatch(setIsVisible(true))
      }
    })
};

export const AddClient = (param) => (dispatch) => {
  const obj = {
    "clientName": param.clientName
  }
  var url = `${clientApi.getClient}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response) {
        dispatch(setClientData(response.data))
        toaster.success("SUCESS")
      }

    }).catch((error) => {
      const response = error.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      dispatch(toaster.error(hydratedText));
    })
};

export default CreateClientSlice.reducer;
