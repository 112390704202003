import jsPDF from "jspdf";
import "jspdf-autotable";
import { getDateFormate } from "./helper";
import { font } from "./DejaVuSans-Bold-bold";
import { ImageTwo, ImageThree } from "./pdfImages";
export const exportPdf = (title, searchDate, tableData) => {
  // var width = doc.internal.pageSize.getWidth();
  // var height = doc.internal.pageSize.getHeight();
  // console.log(doc.getFontList());
  // ₹
  // herderColor = rgb(25,66,100);
  // textColor = rgb(210,227,234);
  // subHeaderColor = rgb(91,127,155);
  // subHeaderColorlt = rgb(138,168,188);

  // sercond //

  // header = 240,130,52

  // sercond //

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "l"; // portrait or landscape
  // const title = props.reportPage.label;
  //  const format = [canvas.width + 60, canvas.height + 160]
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  const fontRupee = font;
  doc.addFileToVFS("DejaVuSans-Bold-bold.ttf", fontRupee);
  doc.addFont("DejaVuSans-Bold-bold.ttf", "DejaVuSans-Bold", "normal");
  doc.setFont("DejaVuSans-Bold");
  // doc.setFontSize(20);
  // doc.text("vijay = ₹", 20, 20);
  // log available fonts
  // console.log("avaiable fonts :",doc.getFontList());
  doc.setFontSize(23);
  doc.setFont("helvetica");
  const totalWidth = doc.internal.pageSize.getWidth();
  const totalHeight = doc.internal.pageSize.getHeight();
  const titleHead = title.toUpperCase();
  const xOffset =
    doc.internal.pageSize.width / 2 -
    (doc.getStringUnitWidth(titleHead) * doc.internal.getFontSize()) / 2;
  const lineWidth = (input) => doc.getStringUnitWidth(input) * 25 + xOffset;
  //  rgb(14,18,29)
  // main header part //
  doc.addImage(ImageTwo, "JPEG", 0, 0, totalWidth, 85);
  doc.addImage(ImageThree, "JPEG", 0, 80, totalWidth, 55);
  doc.setFontSize(25);
  doc.setFont("Helvetica", "bold");
  doc.setTextColor(255, 255, 255);
  doc.text(titleHead, xOffset, 55);
  doc.setLineWidth(1.5);
  doc.setTextColor(255, 255, 255);
  // doc.line(xOffset, 55, lineWidth(titleHead), 55);
  // title background box //
  // title background box //
  doc.setFontSize(18);
  doc.setTextColor(250, 242, 92);
  doc.text(title.toUpperCase(), 20, 115);
  doc.setFontSize(13);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(210, 227, 234);
  doc.text(
    "Period  : " +
    getDateFormate(searchDate.fromDate) +
    " to  " +
    getDateFormate(searchDate.toDate),
    doc.internal.pageSize.width - 230,
    112
  );

  // main header part //
  // second = 109,142,77
  // 186,209,179
  // subtitle part //

  const subTitleOne = (input, setHeight) => {
    doc.setFontSize(13);
    const title = input.toUpperCase();
    const titleWidth = doc.getStringUnitWidth(title) * 13;
    const boxStart = 40;
    const boxEnd = titleWidth + 30;
    const titleStart = 40 + 15;
    doc.setDrawColor(232, 14, 134);
    doc.setFillColor(232, 14, 134);
    doc.rect(boxStart, setHeight, boxEnd, 20, "F");
    // doc.addImage(ImageOne, "JPEG", boxStart, setHeight, boxEnd, 20);
    doc.setTextColor(254, 245, 255);
    // const heightRange = doc.internal.pageSize.getHeight() - setHeight;
    // let condition = heightRange >= 0 && heightRange <= 70;
    // if (condition) {
    //    doc.addPage(size, orientation);
    //     return doc.text(title, titleStart, condition ? 40 : setHeight + 15);
    // }
    //   condition = false;
    //    return !condition && doc.text(title, titleStart, setHeight + 15);

    return doc.text(title, titleStart, setHeight + 15);
  };
  // subTitleOne(subtitle);
  // subtitle part //

  // table part //
  doc.setFont("DejaVuSans-Bold");
  const bodyContent = (height, headers, body, styles) => {
    //  subTitleOne(subtitle, setHeight);
    //   const heightRange = doc.internal.pageSize.getHeight() - setHeight;
    //  const  condition = heightRange >= 0 && heightRange <= 70
    //    if(heightRange >= 0 && heightRange <= 70 ){
    //   doc.addPage(size, orientation);
    // }

    const content = {
      // startY: condition ? 60 : height + 20,
      startY: height + 20,
      head: headers,
      body: body,
      setFont: "DejaVuSans-Bold",
      theme: "striped",
      tableWidth: "auto",
      pageBreak: "auto",
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        0: {
          halign: "start",
          valign: "middle",
        },
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 10,
        paddingTop: 5,
        paddingBottom: 5,
      },
      columnStyles: styles,
    };
    // if(heightRange >= 0 && heightRange <= 70 ){
    //   doc.addPage(size, orientation);
    // }
    return doc.autoTable(content);
  };
  // doc.autoTable(content);
  // doc.previousAutoTable.finalY
  // const finalY = doc.lastAutoTable.finalY
  // tableData.map((table, ind) => doc.autoTable(bodyContent(ind == 0 ? 185 : doc.lastAutoTable.finalY + 10, table.headers, table.body, table.subtitle,  ind == 0 ? 160 : doc.lastAutoTable.finalY + 10)));
  // var tableEnd = 0;
  // subTitleOne(table.subtitle, ind == 0 ? 160 : doc.autoTableEndPosY() + 20);
  // tableEnd = doc.previousAutoTable.finalY + 10;

  tableData.map((table, ind) => {
    table.subtitle &&
      subTitleOne(
        table.subtitle,
        ind == 0 ? 160 : doc.lastAutoTable.finalY + 30
      );
    bodyContent(
      ind == 0 ? 170 : doc.lastAutoTable.finalY + 40,
      table.headers,
      table.body,
      table.styles
    );
  });

  // table part //

  doc.save(`${title}.pdf`);
};
