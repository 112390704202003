import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Tooltips from '../Common/Tooltip'
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from '../../sharedfiles/Constants'
import { Payment, Payments } from '../../sharedfiles/tooltipMsgs'
import { useDispatch, useSelector } from 'react-redux'
import { acceptDepositProof, getDepositProof, rejectDepositProof, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, setRecordsToShow, setStatusType } from './DepositproofSlice'
import { formatMoney, getDateTimeFormate } from '../../sharedfiles/helper'
import VButtonGroup from '../Common/Buttons/VButtonGroup'
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import KycCommentBox from "../KycUsers/KycCommentBox"
import No__Data__Found from '../Common/table/No__Data__Found'
import AcceptCmntBox from '../Common/acceptCmntBox'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Vpagination from '../../sharedfiles/Vpagination'


const Depositproof = () => {
    const dispatch = useDispatch()
    const depositProofData = useSelector((p) => p.DepositproofSlice.depositProofData)
    console.log("depositProofData: ", depositProofData);
    const paginationFirstValue = useSelector((p) => p.DepositproofSlice.paginationFirstValue)
    const paginationSecondValue = useSelector((p) => p.DepositproofSlice.paginationSecondValue)
    const isVisible = useSelector((p) => p.DepositproofSlice.isVisible)
    const recordsToShow = useSelector((p) => p.DepositproofSlice.recordsToShow)

    const [acceptDialog, setAcceptDialog] = useState(false);
    const [declineDialog, setDeclineDialog] = useState(false);
    const [userId, setUserId] = useState('')
    const [csvData, setCsvData] = useState([]);
    const [pdfBody, setPdfBody] = useState([]);
    const [image, setImage] = useState("")
    const [imagePopup, setImagePopup] = useState(false)
    const statusType = useSelector((state) => state.DepositproofSlice.statusType);
    const [status, setStatus] = useState(statusType)

    const acceptDialogHandler = (id, val) => {
        setUserId(id)
        if (val === "accept") {
            setAcceptDialog(true);
        }
        if (val === 'decline') {
            setDeclineDialog(true);
        }
    };

    const handleReject = (msg) => {
        dispatch(rejectDepositProof(userId, msg))

    }
    const handleAccept = (msg) => {
        dispatch(acceptDepositProof(userId, msg))

    }
    useEffect(() => {
        dispatch(getDepositProof(paginationFirstValue - 1, paginationSecondValue, status))
    }, [])

    useEffect(() => {
        if (status !== "" && status !== statusType) {
            callApi(paginationFirstValue, paginationSecondValue);
        }
    }, [status])

    const headers = [
        [
            "User Id",
            "Pending Withdrawal Amount",
            "Withdrawal Request Date",
            "Payment Reference"
        ]
    ]

    const update = () => {
        const tBody = depositProofData && depositProofData?.data && Array.isArray(depositProofData?.data)
            ? depositProofData?.data.map((p) => [p.userId, formatMoney(p.withdrawalAmount), getDateTimeFormate(p.withdrawalRequestedDate), p.transactionRef])
            : [];
        setPdfBody(tBody);
        const data = Array.isArray(tBody) && tBody.length > 0 ? [headers[0], ...tBody] : [headers[0], []];
        setCsvData(data);
    }
    useEffect(() => {
        update()
    }, [depositProofData])

    const exportPDF = () => {
        const title = "Deposit Proof Report";
        const styles = {
            3: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, headers, pdfBody, styles);
    }
    const callApi = (pageNumber, itemsPerPage) => {
        dispatch(setIsVisible(false));
        dispatch(getDepositProof(pageNumber - 1, itemsPerPage, status))
    }

    const onViewBtn = (img) => {
        setImagePopup(true)
        setImage(img)
    }

    const handleStatusChange = (e) => {
        const state = e.target.value;
        setStatus(e.target.value);
        dispatch(setPaginationFirstValue(1));
        dispatch(setPaginationSecondValue(25));
        dispatch(setRecordsToShow([1, 25]));
        setTimeout(() => dispatch(setStatusType(state)), 1000);
    };

    return (
        <>
            <Dialog__Main
                dOpen={imagePopup}
                dClose={() => setImagePopup(false)}
                title={"Deposit Proof Docs"}
                width={"sm"}
            >
                <div
                    style={
                        {
                            height: "400px",
                            msOverflow: "scroll",
                            userSelect: "text",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    }
                >
                    <img src={image} />
                </div>
            </Dialog__Main>

            <Dialog__Main title={"Action Needed"} dOpen={declineDialog} dClose={() => setDeclineDialog(false)}>
                <KycCommentBox
                    finish={(msg) => handleReject(msg)}
                    close={() => setDeclineDialog(false)}
                />
            </Dialog__Main>
            <Dialog__Main title={"Action Needed"} dOpen={acceptDialog} dClose={() => setAcceptDialog(false)}>
                <AcceptCmntBox
                    finish={(msg) => handleAccept(msg)}
                    close={() => setAcceptDialog(false)}
                />
            </Dialog__Main>
            <div className='CMS-tabContent'>
                <div className='CMS-filter-result' id='result'>
                    <div className='col-md-6 col-lg-4 col-xl-4 mb-20'>
                        <div className='CMS-formGroup formGroup-inline'>
                            <div>
                                <div style={{ padding: "12px" }}>
                                    {KYC_CONSTANTS.header_status}
                                    &nbsp;&nbsp;
                                    <Tooltips info={Payments.depoProofSts} />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='CMS-formControl'></div>
                                <div className='CMS-number-of-files CMS-select'>
                                    <select
                                        id='Depositstatus'
                                        name='status'
                                        value={status}
                                        onChange={(e) => handleStatusChange(e)}
                                        className="pointer"
                                    >
                                        <option value='SUBMITTED'>Submitted</option>
                                        <option value='ACCEPTED'>Accepted</option>
                                        <option value='REJECTED'>Rejected</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='CMS-pagination '>
                        <Vpagination
                            data={depositProofData?.data}
                            totalRecords={depositProofData?.totalRecords}
                            paginationFirstValue={paginationFirstValue}
                            paginationSecondValue={paginationSecondValue}
                            setPaginationFirstValue={setPaginationFirstValue}
                            setPaginationSecondValue={setPaginationSecondValue}
                            recordsShow={recordsToShow}
                            setRecordsShow={setRecordsToShow}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            csvData={csvData}
                            exportPDF={exportPDF}
                            callApi={callApi}
                            csvFileName={"Deposit Proof Report"}
                        />
                    </div>
                </div>
                <div className='CMS-box'>
                    <div className="CMS-table CMS-table-triped">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{COMMON_CONSTANTS.th_userId}</p>
                                            <Tooltips info={"Showing User Id"} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{PAYMENT_CONSTANTS.pending_withdraw_Amnt}</p>
                                            <Tooltips info={Payment.pending_withdraw_Amnt} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{PAYMENT_CONSTANTS.request_Date}</p>
                                            <Tooltips info={Payment.request_Date} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{PAYMENT_CONSTANTS.depoProof_docs}</p>
                                            <Tooltips info={Payment.depoProof_docs} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{PAYMENT_CONSTANTS.payment_reference}</p>
                                            <Tooltips info={Payment.payment_reference} />
                                        </div>
                                    </th>
                                    {status === "SUBMITTED" ? <th>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                                            <Tooltips info={"Showing Actions to be taken"} />
                                        </div>
                                    </th> :
                                        <th>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <p className='mx-3'>{PAYMENT_CONSTANTS.th_comments}</p>
                                                <Tooltips info={"Showing the Comments"} />
                                            </div>
                                        </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    depositProofData && depositProofData?.data &&
                                        Object.keys(depositProofData?.data).length > 0 ?
                                        depositProofData?.data.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Link
                                                            to={`/customerid/${item.userId}`}
                                                            style={{ color: "#2d91fc" }}
                                                        >
                                                            {item.userId}
                                                        </Link>
                                                    </td>
                                                    <td>{formatMoney(item.withdrawalAmount)}</td>
                                                    <td>{getDateTimeFormate(item.withdrawalRequestedDate)}</td>
                                                    <td>
                                                        <button
                                                            className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                                            id="onArtworkViewBtn"
                                                            onClick={() => onViewBtn(item.docPath)}
                                                        >
                                                            <p>View</p>
                                                        </button>
                                                    </td>
                                                    <td>{item.transactionRef}</td>
                                                    {status === "SUBMITTED" ? <td>
                                                        <VButtonGroup
                                                            buttons={["Accept", "Decline"]}
                                                            accept={() => acceptDialogHandler(item.id, "accept")}
                                                            decline={() => acceptDialogHandler(item.id, "decline")}
                                                        />
                                                    </td> : <td>{item.comments}</td>}
                                                </tr>
                                            )
                                        }) :
                                        <No__Data__Found colSpan={11} />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Depositproof