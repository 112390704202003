import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
} from "../../sharedfiles/helper";
import { exportPdf } from "../../sharedfiles/jsPdf";
import ReportsTable from "../Common/table/ReportsTable";
const ManualAdjustment = (props) => {
  const { dates } = props;
  const [fileType, setFileType] = useState("");

  const headers1 = [
    [
      "Id",
      "Customer ID",
      "Customer Name",
      `Amount ${Currency()}`,
      "Currency",
      "Method",
      "Adjustment",
      "Created Date",
    ],
  ];
  const headers2 = [
    [
      "Payment Date",
      "Adjustment Method",
      "Currency",
      `Total Adjustments ${Currency()}`,
    ],
  ];
  const bodyOne =
    Object.keys(props.sportData).length > 0 &&
    Array.isArray(props.sportData?.reportData) &&
    props.sportData?.reportData?.length > 0 &&
    props.sportData?.reportData?.map((item) => [
      item.id,
      item?.customerId,
      item.customerName ? item.customerName : null,
      formatMoney(item.amount),
      item.currency,
      item.adjustmentMethod,
      item.adjustment,
      getDateTimeFormate(item.createdDate),
    ]);

  const bodyTwo =
    Object.keys(props.sportData).length > 0 &&
    Array.isArray(props.sportData?.summaryData) &&
    props.sportData?.summaryData?.length > 0 &&
    props.sportData?.summaryData.map((item) => [
      getDateTimeFormate(item.createdDate),
      item.paymentType,
      item.currency,
      formatMoney(item.amount),
    ]);
  const headers = ["REPORTS DATA"];
  const header2 = ["MANUAL ADJUSTMENTS"];

  const csvTableOne =
    bodyOne && bodyOne.length > 0
      ? [headers, headers1[0], ...bodyOne]
      : [];
  const csvTableTwo =
    bodyTwo && bodyTwo.length > 0
      ? [header2, headers2[0], ...bodyTwo]
      : [];
  const csvData = [...csvTableOne, ...csvTableTwo];

  // const title = props.reportPage;
  const title = "Manual Adjustments Report";
  const subtitle1 = "REPORTS DATA";
  const subtitle2 = "MANUAL ADJUSTMENTS";
  const searchDate = {
    fromDate: dates.dateFrom,
    toDate: dates.dateTo,
  };

  const firstTable = {
    subtitle: subtitle1,
    headers: headers1,
    body: bodyOne,
    styles: {
      3: {
        halign: "right",
        valign: "middle",
      },
    },
    alignments: ["3"],
    linkIndex: 1,
  };

  const secondTable = {
    subtitle: subtitle2,
    headers: headers2,
    body: bodyTwo,
    styles: {
      4: {
        halign: "right",
        valign: "middle",
      },
    },
    alignments: ["4"],
  };
  const tableData = [firstTable, secondTable];

  const printPdf = () => {
    exportPdf(title, searchDate, tableData);
  };
  useEffect(() => { }, [props.sportData]);

  return (
    <>
      <ReportsTable
        tableData={tableData}
        fileType={fileType}
        setFileType={(value) => setFileType(value)}
        csvData={csvData}
        fileName={title}
        printPdf={() => printPdf()}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reports: state.reports.reports,
  };
};

export default connect(mapStateToProps)(ManualAdjustment);
