import React, { useState } from 'react'
import AlertBox from "../Common/Dialog/AlertBox"

const QRpopUp = ({ QRcode, Close, Open }) => {
    return (
        <>
            {Open ? <AlertBox>
                <div className="alert_child_box">
                    <div className="alert_content_box">
                        <div className='mt-10'></div>
                        <h2 style={{ marginLeft: "260px" }} className='pointer' onClick={Close}>X</h2>
                        <div className="view_qr_scanner">
                            <img
                                className="view_qr_code"
                                src={QRcode}
                                alt="qr-code"
                            />
                        </div>
                        <h1 className="QR_alert_title">SCAN ME</h1>
                    </div>
                </div>
            </AlertBox> : ""}
        </>
    )
}
export default QRpopUp