import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { FalsyCleanObject, cleanObject, messageEnhancer, toaster } from "../../sharedfiles/helper";
import { fileCallerApi } from "../../api/apiCallerNew";
export const AffiliateSlice = createSlice({
  name: "affiliateSlice",
  initialState: {
    // AFFILIATE //
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    responseData: [],
    resData: [],
    listPendingApprove: [],
    openDialog: false,
    docsData_aff: [],
    formData_aff: {},
    formErrors_aff: [],
    affiliateUsers: "",
    isAffChannel: false,
    isChecked: true,
    getBalanceAmount: "",
    // AFFILIATE //

    // CAMPAIGN PAGE //
    campaignList: [],
    channelList: [],
    page: 1,
    items: 25,
    visibleRecords: [1, 25],
    isToShow: false,
    campaignURLlist: [],
    updateUser: [],
    docsData_campaign: [],
    campaignField: { value: "", error: false },

    // CAMPAIGN PAGE //

    // BECOME AN AFFILIATE //
    page_becoff: 1,
    items_becoff: 25,
    records_bcoff: [1, 25],
    isVisible_becoff: false,
    docsData_bcoff: [],
    // BECOME AN AFFILIATE //

    // SUB AFFILIATE //
    page_suboff: 1,
    items_suboff: 25,
    records_suboff: [1, 25],
    isVisible_suboff: false,
    resData_suboff: [],
    docsData_subaff: [],
    formData_subaff: {},
    formErrors_subaff: [],
    selected_AffiliateId: "",
    // SUB AFFILIATE //

    // CHANNEL PAGE //
    channel_page: 1,
    channelItems: 25,
    channelRecords: [1, 25],
    chanelVisible: false,
    docsData_channel: [],
    channelField: { value: "", error: false },
    channelPayload: {},
    dateValue: {
      startDate: "",
      endDate: "",
    },
    // CHANNEL PAGE //

    // ARTWORK UPLOAD //
    fileUpload: [],
    getUploadFiles: [],
    artwork_page: 1,
    artworkItems: 25,
    artworkRecords: [1, 25],
    artworkVisible: false,
    disableBtn: false,
    // ARTWORK UPLOAD //
  },
  reducers: {
    // AFFILIATE //
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
    setAffiliateList: (state, action) => {
      state.resData = action.payload;
    },
    setDocsData_aff: (state, action) => {
      state.docsData_aff = action.payload;
    },
    setFormData_aff: (state, action) => {
      state.formData_aff = action.payload;
    },
    setFormErrors_aff: (state, action) => {
      state.formErrors_aff = action.payload;
    },
    setAffiliateUsers: (state, action) => {
      state.affiliateUsers = action.payload;
    },
    setIsChecked: (state, action) => {
      state.isChecked = action.payload;
    },
    setGetBalanceAmount: (state, action) => {
      state.getBalanceAmount = action.payload;
    },
    // AFFILIATE //

    setListApprovePending: (state, action) => {
      state.listPendingApprove = action.payload;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setCampaignList: (state, action) => {
      state.campaignList = action.payload;
    },
    setChannel: (state, action) => {
      state.channelList = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setVisibleRecords: (state, action) => {
      state.visibleRecords = action.payload;
    },
    setIsToShow: (state, action) => {
      state.isToShow = action.payload;
    },
    setCampaignURLlist: (state, action) => {
      state.campaignURLlist = action.payload;
    },
    setUpdateUser: (state, action) => {
      state.updateUser = action.payload;
    },
    setDocsData_campaign: (state, action) => {
      state.docsData_campaign = action.payload;
    },
    setCampaignField: (state, action) => {
      state.campaignField = action.payload
    },
    setIsAffChannel: (state, action) => {
      state.isAffChannel = action.payload
    },
    // BECOME AN AFFILIATE //
    setPage_becoff: (state, action) => {
      state.page_becoff = action.payload;
    },
    setItems_becoff: (state, action) => {
      state.items_becoff = action.payload;
    },
    setRecords_bcoff: (state, action) => {
      state.records_bcoff = action.payload;
    },
    setIsVisible_becoff: (state, action) => {
      state.isVisible_becoff = action.payload;
    },
    setDocsData_bcoff: (state, action) => {
      state.docsData_bcoff = action.payload;
    },
    // BECOME AN AFFILIATE //

    //  SUB AFFILIATE //
    setPage_suboff: (state, action) => {
      state.page_suboff = action.payload;
    },
    setItems_suboff: (state, action) => {
      state.items_suboff = action.payload;
    },
    setRecords_suboff: (state, action) => {
      state.records_suboff = action.payload;
    },
    setIsVisible_suboff: (state, action) => {
      state.isVisible_suboff = action.payload;
    },
    setResData_suboff: (state, action) => {
      state.resData_suboff = action.payload;
    },
    setDocsData_subaff: (state, action) => {
      state.docsData_subaff = action.payload;
    },
    setFormData_subaff: (state, action) => {
      state.formData_subaff = action.payload;
    },
    setFormErrors_subaff: (state, action) => {
      state.formErrors_subaff = action.payload;
    },
    setSelected_AffiliateId: (state, action) => {
      state.selected_AffiliateId = action.payload;
    },
    //  SUB AFFILIATE //

    setChannel_page: (state, action) => {
      state.channel_page = action.payload;
    },
    setChannelItems: (state, action) => {
      state.channelItems = action.payload;
    },
    setChannelRecords: (state, action) => {
      state.channelRecords = action.payload;
    },
    setChanelVisible: (state, action) => {
      state.chanelVisible = action.payload;
    },
    setDocsData_channel: (state, action) => {
      state.docsData_channel = action.payload
    },
    setChannelField: (state, action) => {
      state.channelField = action.payload
    },
    setFileUpload: (state, action) => {
      state.fileUpload = action.payload
    },
    setGetUploadFiles: (state, action) => {
      state.getUploadFiles = action.payload
    },
    setArtwork_page: (state, action) => {
      state.artwork_page = action.payload
    },
    setArtworkItems: (state, action) => {
      state.artworkItems = action.payload
    },
    setArtworkRecords: (state, action) => {
      state.artworkRecords = action.payload
    },
    setArtworkVisible: (state, action) => {
      state.artworkVisible = action.payload
    },
    setDisableBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
    setChannelPayload: (state, action) => {
      state.channelPayload = action.payload;
    },
    setDateValue: (state, action) => {
      state.dateValue = action.payload;
    },
  },
});
export const {
  // AFFILIATE //
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setResponseData,
  setDocsData_aff,
  setAffiliateList,
  setFormData_aff,
  setFormErrors_aff,
  setAffiliateUsers,
  setIsChecked,
  setGetBalanceAmount,
  // AFFILIATE //
  setListApprovePending,
  setOpenDialog,
  setCampaignList,
  setChannel,
  setPage,
  setItems,
  setVisibleRecords,
  setIsToShow,
  setCampaignURLlist,
  setUpdateUser,
  setDocsData_campaign,
  setCampaignField,
  setIsAffChannel,

  // BECOME AN AFFILIATE //
  setPage_becoff,
  setItems_becoff,
  setRecords_bcoff,
  setIsVisible_becoff,
  setDocsData_bcoff,
  // BECOME AN AFFILIATE //

  // SUB AFFILIATE //
  setPage_suboff,
  setItems_suboff,
  setRecords_suboff,
  setIsVisible_suboff,
  setResData_suboff,
  setDocsData_subaff,
  setFormData_subaff,
  setFormErrors_subaff,
  setSelected_AffiliateId,
  // SUB AFFILIATE //

  setChannel_page,
  setChannelItems,
  setChannelRecords,
  setChanelVisible,
  setDocsData_channel,
  setChannelField,
  setFileUpload,
  setGetUploadFiles,
  setArtwork_page,
  setArtworkItems,
  setArtworkRecords,
  setArtworkVisible,
  setDisableBtn,
  setChannelPayload,
  setDateValue,

} = AffiliateSlice.actions;

export const createAffiliate = (data) => (dispatch) => {
  const url = `${userApi.createAffiliate}`;
  apiCaller(url, "POST", data)
    .then((res) => {
      const response = res.msg
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      dispatch(setResponseData(res.data));
      res.status == "200" && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(res.status) &&
        toaster.error(hydratedText)
    })
    .catch((err) => {
      toaster.error("FAILED");
    });
};

export const getAffiliateTable = (pageNumber, itemPerPage, parentId) => (dispatch) => {

  const url =
    `${userApi.getAffiliate}` +
    `/?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  const subAff = parentId ? { parentAffiliateId: parentId } : {}
  apiCaller(url, "POST", subAff)
    .then(
      (res) => {
        if (parentId) {
          dispatch(setResData_suboff(res.data));
          dispatch(setIsVisible_suboff(true));
        } else {
          dispatch(setAffiliateList(res.data));
          dispatch(setIsVisible(true));
        }
      }
    )
    .catch((err) => {
      if (parentId) {
        dispatch(setResData_suboff([]));
        dispatch(setIsVisible_suboff(true));
      } else {
        dispatch(setAffiliateList([]));
        dispatch(setIsVisible(true));
      }
    });
};

export const getSearchAffData = (pageNumber, itemPerPage, params,) => (dispatch) => {
  var obj = {
    endDate: params.endDate,
    startDate: params.startDate,
    userName: params.userName,
    parentAffiliateId: params.parentAffiliateId,
    subAffliate: params.subAffliate,
  }
  const cleanObj = FalsyCleanObject(obj)
  const url =
    `${userApi.getAffiliate}` +
    `/?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`
  apiCaller(url, "POST", cleanObj)
    .then(
      (res) => {
        dispatch(setAffiliateList(res.data));
        dispatch(setResData_suboff(res.data));
        dispatch(setIsVisible(true));
      }
    )
    .catch((err) => {
      dispatch(setAffiliateList([]));
      dispatch(setResData_suboff([]));
      dispatch(setIsVisible(true));
    });
}

export const getApprovePending = (pageNumber, itemPerPage) => (dispatch) => {
  const url =
    `${userApi.getAffiliate}` +
    "/pending" +
    `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  apiCaller(url, "GET", {})
    .then(
      (rep) => dispatch(setListApprovePending(rep)),
      dispatch(setIsVisible_becoff(true))
    )
    .catch((err) => toaster.error("Failed"));
};

export const updateAgent = (params) => (dispatch) => {
  //dev.platform-pbs1.sportsit-tech.net:443/bonus/update
  const url = `${userApi.getAffiliate}` + "/update";
  const obj = {
    affiliateRequestId: params.affiliateRequestId,
    affiliateRequestStatus: params.affiliateStatus,
    commissionPercentage: params.commissionPercentage,
    payoutCycle: params.payoutCycle,
    setupCost: params.setupCost,
  };
  apiCaller(url, "PUT", obj)
    .then((res) => toaster.success("SUCCESS"))
    .catch((err) => toaster.error("FAILED"));
};

export const editAgent = (params) => (dispatch) => {
  const url = `${userApi.getAffiliate}` + "/edit";
  const obj = {
    affiliateUserId: params.affiliateId,
    affiliateStatus: params.affiliateStatus,
    commissionPercentage: params.commissionPercentage,
    payoutCycle: params.payoutCycle,
    setupCost: params.setupCost,
  };
  apiCaller(url, "PUT", obj)
    .then((res) => toaster.success("SUCCESS"))
    .catch((err) => toaster.error("FAILED"));
};

export const AddCampaign = (params) => (dispatch) => {
  const url = userApi.addCampaign(params.userId);
  apiCaller(url, "POST", params.body)
    .then((res) => {
      let msg = "";
      if (res && res?.msg === "AFFILIATE_CAMPAIGN_ADD_SUCCESS") {
        msg = "Affiliate Campaign Added";
      }
      toaster.success(msg);
    })
    .catch((err) => console.log(err));
};

export const listCampaigns = (id, page, items, params) => (dispatch) => {
  const url = userApi.listCampaign(id, page, items);
  apiCaller(url, "POST", params)
    .then((res) => {
      dispatch(setCampaignList(res?.data));
      dispatch(setIsToShow(true));
    })
    .catch((err) => {
      const { response, statusCode, statusText } = err;
      if ([400, 500].includes(statusCode)) {
        dispatch(setCampaignList([]));
        dispatch(setIsToShow(true));
      }
    });
};

export const AddChannel = (params) => (dispatch) => {
  const url = userApi.addChannel(params.userId);
  apiCaller(url, "POST", params.body)
    .then((res) => {
      let msg = "";
      if (res && res?.msg === "AFFILIATE_CHANNEL_ADD_SUCCESS") {
        msg = "Affiliate Channel Added";
      }
      toaster.success(msg);
    })
    .catch((err) => console.log(err));
};

export const listChannels = (page, items, params) => (dispatch) => {
  const url = userApi.listChannels(page, items);
  apiCaller(url, "POST", params)
    .then((res) => {
      dispatch(setChannel(res.data));
      dispatch(setChanelVisible(true));
    })
    .catch((err) => {
      dispatch(setChannel([]));
      dispatch(setChanelVisible(true));
    });
};

export const getCampaignURL = (campaignId) => (dispatch) => {
  const url = userApi.listCapaignURL(campaignId);
  apiCaller(url, "GET")
    .then((res) => dispatch(setCampaignURLlist(res?.data)))
    .catch((err) => console.log(err));
};

export const getUpdateUser = (params) => (dispatch) => {
  const url = userApi.createUser + "/" + `${params.userId}`;
  apiCaller(url, "PUT", params.obj)
    .then((res) => dispatch(setUpdateUser(res?.data)))
    .catch((err) => console.log(err));
};

export const artWorkUpload = (data) => (dispatch) => {
  const url = userApi.artworkUpload

  fileCallerApi(url, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setFileUpload(res.data))
        dispatch(setDisableBtn(true))
        toaster.success(messageEnhancer(res.msg));
      }
    }).catch((err) => {
      dispatch(setDisableBtn(true))
      const msg = err.msg
      toaster.error(messageEnhancer(msg))
      console.log(err)
    });
}

export const getAffArtwork = (pageNumber, itemPerPage) => (dispatch) => {
  const url = `${userApi.getArtwork}` + `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`
  apiCaller(url, "GET", {})
    .then((res) => dispatch(setGetUploadFiles(res.data)))
    .catch((err) => console.log(err));
};

export const AffilatePay = (data) => (dispatch) => {
  let obj = {
    userName: data.userName,
    amount: +data.amount,
    description: data.description,
  };
  const url = `${userApi.getAffiliate}` + "/affiliate/vip/payment";
  apiCaller(url, "POST", obj)
    .then((res) => {
      toaster.success("Payment successful");
    })
    .catch((err) => {
      toaster.error(messageEnhancer(err.response));
      console.log(err);
    });
};

export const GetBalanceAmount = (userName) => (dispatch) => {
  const url = `${userApi.getAffiliate}` + `/affiliate/balance/amount/${userName}`;
  apiCaller(url, "GET", {})
    .then((res) => {
      dispatch(setGetBalanceAmount(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AffiliateSlice.reducer;
