import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RISK_MANAGEMENT_CONSTANTS } from '../../sharedfiles/Constants'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import No__Data__Found from '../Common/table/No__Data__Found'
import Table from '../Common/table/Table'
import { getPlayerbets, getSyndicateBettings, setIsPrevUserRespDupeCids, setSyndicateData, setSyndicateIsVisible, setSyndicatePageOneVal, setSyndicatePageSecondVal, setSyndicatePayload, setSyndicateShowRecord, setIsUserLoading, setUserSyndicatePageOneVal, setUserSyndicateSecondVal, setUserSyndictaeShowRecord, setdisableBtnRiskMgt, setUserSyndicateIsVisible } from './RiskmgmtSlice'
import { RiskMgmnt } from '../../sharedfiles/tooltipMsgs'
import Tooltips from '../Common/Tooltip'
import Vpagination from '../../sharedfiles/Vpagination'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import { riskManagement } from '../../sharedfiles/EndpointConfig'
import { SyndicateBetDates } from '../Common/data/mapData'
import Input from '../Common/common_inputs/Input'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import Datepicker from '../Common/common_inputs/DatePicker'
import { MomentUTCDateTimeEnd, MomentUTCDateTimeToday, SyndicateTimeEnd, SyndicateTimeStart, cleanObject, convertDateTime, getDateTimeFormate, getDateTimeFormateInvalidDate } from '../../sharedfiles/helper'
import Skelton from '../Common/skelton/Skelton'

const SyndicateBet = () => {
    const [openDialog, setOpenDialog] = useState(false)
    const [tableData, setTableData] = useState('')
    const [syndicateCsvData, setSyndicateCsvData] = useState([])
    const [syndicatePdfData, setSyndicatePdfData] = useState([])
    const [userPdfData, setUserPdfData] = useState([])
    const [userCsvData, setUserCsvData] = useState([])
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [event, setEvent] = useState('')
    const [stake, setStake] = useState('')
    const [mounted, setMounted] = useState(false);
    const [syndicateForms, setSyndicateForms] = useState({
        startDate: '',
        endDate: '',
        user: ''
    });
    const [dateErr, setDateErr] = useState({
        startDate: '',
        endDate: '',
    })
    const [syndicateDisabled, setSyndicateDisabled] = useState(false);
    // const [syndicateCall, setsyndicateCall] = useState("")
    const dispatch = useDispatch()
    const getInfo = useSelector((state) => state.riskManagement.syndicateBetting)
    const playerBets = useSelector((state) => state.riskManagement.playerBets)
    const syndicatePageOne = useSelector((state) => state.riskManagement.syndicatePageOneVal)
    const syndicatePageSecond = useSelector((state) => state.riskManagement.syndicatePageSecondVal)
    const syndicateIsVisible = useSelector((state) => state.riskManagement.syndicateIsVisible)
    const syndicateShowRecord = useSelector((state) => state.riskManagement.syndicateShowRecord)
    const userPageOne = useSelector((state) => state.riskManagement.userSyndicatePageOneVal)
    const userSecondPage = useSelector((state) => state.riskManagement.userSyndicateSecondVal)
    const userIsVisible = useSelector((state) => state.riskManagement.userSyndicateIsVisible)
    const userShowRecord = useSelector((state) => state.riskManagement.userSyndictaeShowRecord)
    const { isPrevUserResp, disableBtnRiskMgt, syndicatePayload, syndicateData, isUserLoading } = useSelector((state) => state.riskManagement)
    const ipOpen = (id, name, event, stake) => {
        dispatch(setIsUserLoading(true))
        dispatch(setUserSyndicateIsVisible(true))
        setOpenDialog(true)
        dispatch(getPlayerbets(id, name, event, stake, 0, 25))
        setId(id)
        setName(name)
        setEvent(event)
        setStake(stake)
    }

    const onClose = () => {
        setOpenDialog(false)
    }

    const tHeader = [
        [
            "CUSTOMERS FROM IP",
            "BET ID",
        ],
    ];

    const popInfo = [
        RiskMgmnt.Cid_frmIp,
        RiskMgmnt.betId
    ]

    const tHead = [
        RISK_MANAGEMENT_CONSTANTS.event_name,
        RISK_MANAGEMENT_CONSTANTS.selection_name,
        RISK_MANAGEMENT_CONSTANTS.market_name,
        RISK_MANAGEMENT_CONSTANTS.no_User,
        RISK_MANAGEMENT_CONSTANTS.date_of_event,
    ]

    const theadInfo = [
        RiskMgmnt.Event_Name,
        RiskMgmnt.Selection_name,
        RiskMgmnt.market_name,
        RiskMgmnt.syndicate_users,
        RiskMgmnt.dateofevent,
    ]

    //USER CSVPDF DATA//
    const update = () => {
        const tbody = playerBets && playerBets.data &&
            playerBets.data.length > 0 ? playerBets.data.map((item) => [item.custIds, item.betIds]) : []
        setTableData([tHeader[0], popInfo, ...tbody])
        setUserPdfData(tbody)
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tHeader[0], ...tbody] : [tHeader[0], []];
        setUserCsvData(data);
    }
    const tableStyles = {
        0: "text-center",
    };

    useEffect(() => {
        dispatch(setSyndicateIsVisible(true))
        dispatch(setSyndicateData(syndicateData));
        // dispatch(getSyndicateBettings(0, 25, {}))
        const dataExist = Object.keys(playerBets).length > 0
        if (!dataExist) {
            dispatch(getSyndicateBettings(userPageOne - 1, userSecondPage, syndicatePayload))

        } else {
            setUserPdfData(userPdfData);
            setUserCsvData(userCsvData);
        }
        stateHolder()
    }, [])

    const UserexportPDF = () => {
        const title = "Customer From Ip Report";
        const styles = {
            marginTop: "10px"
        };
        const customHeader = 12
        exportPDFC(title, tHeader, userPdfData, styles, customHeader)
    }

    const callPagePopApi = (pageNumber, itemsPerPage) => {
        dispatch(setIsUserLoading(true))
        dispatch(getPlayerbets(id, name, event, stake, pageNumber - 1, itemsPerPage))
    }
    useEffect(() => {
        update();
    }, [playerBets])
    //USER CSVPDF DATA//

    //SYNDICATE BET CSVPDF//
    const tbHeader = [
        [
            "EVENT NAME",
            "SELECTION NAME",
            "MARKET NAME",
            "NUMBER OF USERS",
            "DATE OF THE EVENT",
        ]
    ]
    const syndicateTableUpdate = () => {
        const tbody = getInfo && getInfo.data &&
            getInfo.data.length > 0 ? getInfo.data.map((item) => [item.eventName, item.selectionName, item.marketName, item.noOfUsers, getDateTimeFormate(item.fixtureStartDate)]) : []
        setSyndicatePdfData(tbody)
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tbHeader[0], ...tbody] : [tbHeader[0], []];
        setSyndicateCsvData(data);
    }

    const syndicateExportPDF = () => {
        const title = "Syndicate Betting Report";
        exportPDFC(title, tbHeader, syndicatePdfData)
    }

    const callPageApi = (pageNumber, itemsPerPage) => {
        dispatch(setSyndicateIsVisible(true))
        dispatch(getSyndicateBettings(pageNumber - 1, itemsPerPage, syndicatePayload))
    }

    useEffect(() => {
        syndicateTableUpdate();
    }, [getInfo])

    useEffect(() => {
        var btnTimer;
        if (disableBtnRiskMgt) {
            btnTimer = setTimeout(() => {
                dispatch(setdisableBtnRiskMgt(false))
                setMounted(true)
            }, 2500)
        };
        return () => clearTimeout(btnTimer);
    }, [disableBtnRiskMgt]);

    // Sreach Option IN Pagination
    // const handlerSyndicate = (value) => {
    //     if (isPrevUserResp && isPrevUserResp === value) return;
    //     dispatch(setIsPrevUserRespDupeCids(value))
    //     if (value.trim().length > 0) {
    //         dispatch(getSyndicateBettings(0, 25, value))
    //     }
    // }
    function errorSetter(name, value) {
        setDateErr((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    }
    function stateHolder() {
        const isFormDataExist = Object.keys(syndicateData).length;
        if (isFormDataExist) {
            Object.entries(syndicateData).map(([key, value]) =>
                setSyndicateForms((prevState) => {
                    return {
                        ...prevState,
                        [key]: value,
                    };
                })
            );
        }
    }

    function formDataToSliceSetter(key, val) {
        const newEntry = { ...syndicateData, [key]: val };
        dispatch(setSyndicateData(newEntry));
    };
    const handleSyndicateUser = (e) => {
        const { name, value } = e.target;
        setSyndicateForms((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        formDataToSliceSetter(name, value);
        const updatedFormData = { ...syndicateData, [name]: value };
        dispatch(setSyndicateData(updatedFormData));
    };
    const handleSyndicateDate = (value, name) => {
        formDataToSliceSetter(name, value);
        setSyndicateForms({ ...syndicateForms, [name]: value })
        if (name === "startDate" && value) {
            errorSetter("startDate", "");
        }
    };
    const syndicateSearchReset = () => {
        setSyndicateForms({
            startDate: '',
            endDate: '',
            user: ''
        })
        setDateErr('')
        dispatch(getSyndicateBettings(0, 25, {}))
        dispatch(setSyndicatePageOneVal(1))
        dispatch(setSyndicatePageSecondVal(25))
        dispatch(setSyndicateShowRecord([1, 25]))
        dispatch(setSyndicateData({}));
        dispatch(setSyndicatePayload({}))
    };

    const syndicateSearch = () => {
        setSyndicateDisabled(true);
        setSyndicateIsVisible(true);
        const plainPayload = {
            createdFromDate: syndicateForms.startDate !== "" ? SyndicateTimeStart(syndicateForms.startDate) : "",
            createdToDate: syndicateForms.endDate !== "" ? SyndicateTimeEnd(syndicateForms.endDate) : '',
            userName: syndicateForms.user
        };
        let payload = cleanObject(plainPayload);
        dispatch(setSyndicatePayload(plainPayload));
        if (syndicateForms.user !== '') {
            setDateErr('');
            dispatch(getSyndicateBettings(0, 25, payload));
        }
        else if (syndicateForms.startDate == '') {
            errorSetter("startDate", "Start Date is required");
        }
        else {
            dispatch(getSyndicateBettings(0, 25, payload));
            errorSetter("startDate", "");
        }
        setTimeout(() => {
            setSyndicateDisabled(false);
        }, 3000);
    }

    return (
        <>
            <Dialog__Main
                title={"CUSTOMERS FROM IP"}
                dOpen={openDialog}
                dClose={() => onClose()}
                width={"700px"}
            >
                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={playerBets.data}
                            totalRecords={playerBets.totalRecords}
                            paginationFirstValue={userPageOne}
                            paginationSecondValue={userSecondPage}
                            setPaginationFirstValue={setUserSyndicatePageOneVal}
                            setPaginationSecondValue={setUserSyndicateSecondVal}
                            recordsShow={userShowRecord}
                            setRecordsShow={setUserSyndictaeShowRecord}
                            isVisible={userIsVisible}
                            setIsVisible={setIsUserLoading}
                            csvData={userCsvData}
                            exportPDF={UserexportPDF}
                            callApi={callPagePopApi}
                            csvFileName={"Customer From Ip Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <Table tableData={tableData} linkIndex={0} styles={tableStyles} />
            </Dialog__Main>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <div className="mt-50"></div>
                    <div className='CMS-tabContent'>
                        <div className="CMS-box CMS-box-content">
                            <div className='row'>
                                {SyndicateBetDates.map((item, ind) => {
                                    const minDate = [null, syndicateForms.startDate];
                                    const max = syndicateForms.endDate ? syndicateForms.endDate : new Date();
                                    const maxDate = [max, null];
                                    const values = [syndicateForms.startDate, syndicateForms.endDate];
                                    return (
                                        <div className='col-3' key={ind}>
                                            <Datepicker
                                                title={item.title}
                                                name={item.name}
                                                value={values[ind]}
                                                setValue={(date, value) => handleSyndicateDate(date, value)}
                                                errorMsg={dateErr[item.name]}
                                                minDate={minDate[ind]}
                                                maxDate={maxDate[ind]}
                                                ids={item.ids}
                                                info={item.info}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="col-3">
                                    <Input
                                        title={"Username"}
                                        name={"user"}
                                        value={syndicateForms.user}
                                        placeholder={"Username"}
                                        setValue={(e) => handleSyndicateUser(e)}
                                        ids={"userIpUserName"}
                                        info={"showing user name"}
                                        show={true}
                                    // regex={regexType.notZero}
                                    />
                                </div>
                                <div className="ml-15"></div>
                                <ButtonGroup
                                    groupNames={["Search", "Reset"]}
                                    idsearch={"searchAffiliate"}
                                    idreset={"affiliateReset"}
                                    submit={(e) => syndicateSearch(e)}
                                    reset={(e) => syndicateSearchReset(e)}
                                    freezButton={syndicateDisabled}
                                />
                            </div>
                        </div>
                        <div className='CMS-filter-result' id='result'>
                            <div className="CMS-pagination">
                                <Vpagination
                                    data={getInfo.data}
                                    totalRecords={getInfo.totalRecords}
                                    paginationFirstValue={syndicatePageOne}
                                    paginationSecondValue={syndicatePageSecond}
                                    setPaginationFirstValue={setSyndicatePageOneVal}
                                    setPaginationSecondValue={setSyndicatePageSecondVal}
                                    recordsShow={syndicateShowRecord}
                                    setRecordsShow={setSyndicateShowRecord}
                                    isVisible={isUserLoading}
                                    setIsVisible={setSyndicateIsVisible}
                                    csvData={syndicateCsvData}
                                    exportPDF={syndicateExportPDF}
                                    callApi={callPageApi}
                                    csvFileName={"Syndicate Betting Report"}
                                    reloadRequired={false}
                                // onclickSearch={handlerSyndicate}
                                // isSearch={true}
                                // isSearchClicked={true}
                                // disableButton={disableBtnRiskMgt}
                                // searchIniMount={mounted}
                                />
                            </div>
                            {!isUserLoading ? (<Skelton vLen={5} hLen={7} />) : (<div className='CMS-box '>
                                <div className='CMS-table CMS-table-triped'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {tHead.map((item, index) => (
                                                    <th>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <p className='mx-3'>{item}</p>
                                                            <Tooltips info={`${theadInfo[index]}`} />
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getInfo && getInfo.data
                                                && getInfo.data.length > 0 ? (
                                                getInfo.data.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.eventName}</td>
                                                            <td>{item.selectionName}</td>
                                                            <td>{item.marketName}</td>
                                                            <td className='link_style pointer' onClick={() => ipOpen(item.selectionId, item.selectionName, item.eventName, item.stake)}>{item.noOfUsers}</td>
                                                            <td>{item.fixtureStartDate ? getDateTimeFormate(item.fixtureStartDate) : '-'}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <No__Data__Found colspan={50} />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SyndicateBet
