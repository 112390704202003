import { createSlice } from "@reduxjs/toolkit";
import { browserPushApis } from "../sharedfiles/EndpointConfig";
import { apiCaller } from "../api/apiCaller";

export const AppSlice = createSlice({
  name: "appslice",
  initialState: {
    open: false,
    loader: true,
    mostLoader: true,
    highLoader: true,
    winnerLoader: true,
    looserLoader: true,
    revenuLoader: true,
    sportLoader: true,
    betLoader: true,
    stackLoader: true,
    cashLoader: true,
    deafaultPage: "",
    successMessage: "",
    errorMessage: "",
    page: 1,
    itemsPerPage: 25,
    recordsShow: [1, 25],
    visible: false,
    intervalStopper: false,
    socketNotification: false,
    socketData: [],
    subscribe: [],
    unSubscribe: []
  },
  reducers: {
    setMainTab: (state, action) => {
      state.selectedMainTab = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMostLoader: (state, action) => {
      state.mostLoader = action.payload;
    },
    setHighLoader: (state, action) => {
      state.highLoader = action.payload;
    },
    setWinnerLoader: (state, action) => {
      state.winnerLoader = action.payload;
    },
    setLooserLoader: (state, action) => {
      state.looserLoader = action.payload;
    },
    setRevnueLoader: (state, action) => {
      state.revenuLoader = action.payload;
    },
    setSportLoader: (state, action) => {
      state.sportLoader = action.payload;
    },
    setBetLoader: (state, action) => {
      state.betLoader = action.payload;
    },
    setStackLoader: (state, action) => {
      state.stackLoader = action.payload;
    },
    setCashloader: (state, action) => {
      state.cashLoader = action.payload;
    },
    setDefaultPage: (state, action) => {
      state.deafaultPage = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setIntervalStopper: (state, action) => {
      state.intervalStopper = action.payload;
    },
    setSocketNotification: (state, action) => {
      state.socketNotification = action.payload;
    },
    setSocketData: (state, action) => {
      state.socketData = action.payload;
    },
    setSubscribe: (state, action) => {
      state.subscribe = action.payload;
    },
    setUnSubscribe: (state, action) => {
      state.unSubscribe = action.payload;
    },
  },
});
export const {
  setMainTab,
  setOpen,
  setLoader,
  setHighLoader,
  setLooserLoader,
  setMostLoader,
  setWinnerLoader,
  setRevnueLoader,
  setSportLoader,
  setBetLoader,
  setCashloader,
  setStackLoader,
  setDefaultPage,
  setSuccessMessage,
  setErrorMessage,
  setPage,
  setItemsPerPage,
  setRecordsShow,
  setVisible,
  setIntervalStopper,
  setSocketNotification,
  setSocketData,
  setSubscribe,
  setUnSubscribe
} = AppSlice.actions;

export const getEvenId = (props) => (dispatch) => {
  var path = window.location.pathname;
  path = path.split("/");
  path = path.join("/");
  props.history.push(path);
};

export const notificationSubscribe = (auth, brandId, endPoint, key, token, id) => (dispatch) => {
  const url = browserPushApis.userPushNotificationSubscribe
  const obj = {
    auth: auth,
    brandId: +brandId,
    notificationEndPoint: endPoint,
    publicKey: key,
    token: token,
    userId: +id
  }
  apiCaller(url, "POST", obj).then((res) => {
    dispatch(setSubscribe(res.data))
  })
}
export const notificationUnSubscribe = (auth, brandId, endPoint, key, token, id) => (dispatch) => {
  const url = browserPushApis.userPushNotificationUnSubscribe
  const obj = {
    auth: auth,
    brandId: +brandId,
    notificationEndPoint: endPoint,
    publicKey: key,
    token: token,
    userId: +id
  }
  apiCaller(url, "POST", obj).then((res) => {
    dispatch(setUnSubscribe(res.data))
  })
}

export default AppSlice.reducer;
