import React from "react";
import { useSelector } from "react-redux";
import ManualAdjustment from "../ManualAdjustment";
import { setManualDates } from "../reportSlice";
import ReportsWrapper from "../ReportsWrapper";

function ManualReport(props) {
  const { manualReportsData, manualDates } = useSelector((st) => st.reports);
  return (
    <React.Fragment>
      <ReportsWrapper
        reportTitle={"MANUAL ADJUSTMENT"}
        currentTab={"MANUAL ADJUSTMENT"}
        pageKey={"MANULAPAYMENT"}
        setDateToStore={setManualDates}
        persistDate={manualDates}
        sportData={manualReportsData}
        ReportTable={() => (
          <ManualAdjustment
            dates={manualDates}
            sportData={manualReportsData}
            reportPage={"manual Report"}
          />
        )}
      />
    </React.Fragment>
  );
}

export default ManualReport;
