import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { communicationApis, tagApi } from "../../sharedfiles/EndpointConfig";
import { fileCallerApi } from "../../api/apiCallerNew";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const CreatePromoSlice = createSlice({
    name: "CreatePromoSlice",
    initialState: {
        createPromoData: [],
        userTags: [],
    },
    reducers: {
        setCreatePromoData: (state, action) => {
            state.createPromoData = action.payload
        },
        setUserTags: (state, action) => {
            state.userTags = action.payload
        },

    }
});

export const {
    setCreatePromoData,
    setUserTags,

} = CreatePromoSlice.actions

export const savePromotionDeails = (params) => (dispatch) => {
    const url = communicationApis.savePromotionDtl
    fileCallerApi(url, params).then((res) => {
        if (res.status == 200) {
            dispatch(setCreatePromoData(res.data))
        }
        toaster.success("SUCCESS");
    }).catch((err) => {
        console.log(err);
    })
}
export const getAllUserTags = () => (dispatch) => {
    const url = tagApi.getAllUserTags
    apiCaller(url, "GET", {}).then((res) => {
        if (res !== null && res) {
            dispatch(setUserTags(res))
        }
    }).catch((err) => {
        console.log(err);
    })
}

export default CreatePromoSlice.reducer