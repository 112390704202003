import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchBonusData,
  assignBonus,
  setResponseData,
  setIsStatusError,
} from "./AssignBonusSlice.js";
import ButtonGroup from "../Common/Buttons/ButtonGroup.js";
import Select from "../Common/common_inputs/Select_DDL";
import Input from "../Common/common_inputs/Input";
import Table from "../Common/table/Table";
import sampleCsvFile from "../../assets/sample_file.csv";
import { applyBonusType } from "../Common/data/mapData.js";
import { COMMON_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants.js";
import Tooltips from "../Common/Tooltip.js";
import { Bonusing } from "../../sharedfiles/tooltipMsgs.js";
const AssignBouns = (props) => {
  const dispatch = useDispatch();
  const [bonusErr, setBonusErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [bonusTypeErr, setBonusTypeErr] = useState(false);
  const [custIdErr, setCustIdErr] = useState(false);
  const [bulkErr, setBulkErr] = useState(false);
  const [formData, setFormData] = useState({
    bonusCode: "",
    fileCsv: "",
    customerId: "",
    uploadType: "",
    applybonusType: "",
  });
  const [id, setId] = useState("")
  const [tableData, setTableData] = useState([]);
  const statusBox = useSelector(
    (state) => state.assignBonusSlice.isStatusError
  );
  const statusMessage = useSelector(
    (state) => state.assignBonusSlice.statusMessage
  );
  useEffect(() => {
    dispatch(fetchBonusData(0, 500));
  }, []);
  const callApi = () => {
    if (formData.uploadType === "bulk") {
      if (formData.fileCsv !== "") {
        const type = "file";
        const fdt = formData.fileCsv;

        var fetchFormData = new FormData();
        fetchFormData.append("bonusCode", id);
        fetchFormData.append("file", formData.fileCsv, "/path/to/file");

        dispatch(assignBonus(fetchFormData, type, true));
      }
    } else {
      if (formData.customerId !== "") {
        const type = "int";
        const payload = {
          freeBetId: id,
          userId: [formData.customerId],
        };
        dispatch(assignBonus(payload, type, true));
      }
    }
  };

  const errorCheck = () => {
    if (formData.applybonusType === "") {
      setBonusTypeErr(true)
    } else {
      setBonusTypeErr(false)
    }
    if (formData.bonusCode === "") {
      setBonusErr(true);
    } else {
      setBonusErr(false);
    }
    if (formData.uploadType === "bulk") {
      if (formData.fileCsv === "") {
        setBulkErr(true);
      } else {
        setBulkErr(false);
      }
    }
    if (formData.uploadType === "single") {
      if (formData.customerId === "") {
        setCustIdErr(true);
      } else {
        setCustIdErr(false);
      }
    }
    if (formData.uploadType === "") {
      setTypeErr(true);
    } else {
      setTypeErr(false);
    }
    const typo =
      formData.uploadType !== ""
        ? formData.uploadType === "bulk"
          ? "fileCsv"
          : "customerId"
        : null;
    const checkArr = ["applybonusType", "bonusCode", "uploadType", typo];
    const chc = checkArr.every((v) => formData[v] !== "");
    return chc;
  };

  const submitForm = (e) => {
    e.preventDefault();
    const isAllClear = errorCheck();
    if (isAllClear) {
      callApi();
      resetForm();
    }

  };

  const resetForm = () => {
    setFormData({
      bonusCode: "",
      uploadType: "",
      fileCsv: "",
      customerId: "",
      applybonusType: "",
    });
    setBonusErr(false);
    setTypeErr(false);
    setCustIdErr(false);
    setBulkErr(false);
    setBonusTypeErr(false)

  };
  const fileOptions = [
    {
      id: 1,
      label: "Single Upload",
      value: "single",
    },
    {
      id: 2,
      label: "Bulk Upload",
      value: "bulk",
    },
  ];

  const setValue = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "fileCsv") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    }
    if (name === "uploadType") {
      setTypeErr(false);
      setBonusErr(false);
      setBulkErr(false);
      setBonusTypeErr(false)
    }
    if (name === "bonusCode") {
      if (value === "") {
        setId(value)
      }
      else {
        const id = props?.bonusData &&
          Object.keys(props.bonusData).length > 0 &&
          props?.bonusData?.data.filter((item) =>
            item.bonusCode === value
          );
        setId(id[0].id)
      }

    }
    name === "bonusCode" && setBonusErr(false);
    name === "fileCsv" && setBulkErr(false);
    name === "customerId" && setCustIdErr(false);
    name === "applybonusType" && setBonusTypeErr(false)
  };
  const updateResponseTable = () => {
    const tbHeader = ["Showing Customer ID ", "Showing Status", "Showing Reason for Failure"]
    const headers = ["Customer ID ", "Status", "Reason for Failure"];
    const body = props.responseData &&
      Array.isArray(props.responseData) && props.responseData.length > 0
      ? props.responseData
        .filter((v) => v.status === "ERROR")
        .map((item, index) => [item.userId, item.status, item.message])
      : [];

    setTableData([headers, tbHeader, ...body]);
  };
  useEffect(() => {
    if (Array.isArray(props.responseData) && props.responseData.length > 0) {
      updateResponseTable();
    }
  }, [props.responseData]);
  const handleSp = () => { };
  useEffect(() => {
    dispatch(setResponseData([]));
    dispatch(setIsStatusError(false));
  }, []);

  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <form onSubmit={submitForm}>
            <div className='CMS-box CMS-box-content'>
              <div className='row '>

                <div className='col-lg-4 col-xl-4'>
                  <Select
                    title={"Bonus Type"}
                    name={"applybonusType"}
                    options={applyBonusType}
                    value={formData.applybonusType}
                    setValue={(v) => setValue(v)}
                    errorMsg={bonusTypeErr && "Please select proper Bonus Type"}
                    ids={"applyBonusType"}
                    info={Bonusing.Apply_Bonustype}
                  />
                </div>


                <div className='col-lg-4 col-xl-4'>
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      {PAYMENT_CONSTANTS.available_Bonus}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.Available_Bonus} />
                    </div>
                    <div className='CMS-dropdown CMS-formControl'>
                      <div className='CMS-select'>
                        <select
                          name="bonusCode"
                          id={id}
                          value={formData.bonusCode}
                          onChange={(v) => setValue(v)}
                          className="pointer"
                        >
                          <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                          {Object.keys(props.bonusData).length >
                            0 &&
                            props?.bonusData?.data
                              .filter((item) =>
                                item.bonusType?.includes(
                                  formData.applybonusType
                                )
                              )
                              .map((item, index) => {
                                return (
                                  item.bonusCode !== null && <option
                                    key={index}
                                    value={item.bonusCode}
                                    id={item.id}
                                    name={item.id}
                                  >
                                    {item.bonusCode}
                                  </option>
                                );
                              })}
                        </select>
                      </div>
                      <div className='CMS-form-errorMsg'>
                        {bonusErr &&
                          "Please select proper Bonus Code"}
                      </div>
                    </div>
                  </div>

                </div>
                <div className='col-lg-4 col-xl-4'>
                  <Select
                    title={"Upload Type"}
                    name={"uploadType"}
                    options={fileOptions}
                    value={formData.uploadType}
                    setValue={(v) => setValue(v)}
                    errorMsg={typeErr && "Please select any of Upload Type"}
                    ids={"UploadType"}
                    info={Bonusing.Upload_Type}
                  />
                </div>
                {formData.uploadType !== "" && (
                  <div className='col-lg-4 col-xl-4'>
                    {formData.uploadType === "bulk" ? (
                      <Input
                        title={"Bulk Upload"}
                        placeholder={"upload file"}
                        name={"fileCsv"}
                        type={"file"}
                        ids={"bulkUpload"}
                        // value={formData.fileCsv}
                        accept={".csv"}
                        setValue={(e) => setValue(e)}
                        errorMsg={bulkErr && "Please choose proper file"}
                      // headlines={"download sample file"}
                      />
                    ) : (
                      <Input
                        title={"Customer Id"}
                        placeholder={"Customer Id"}
                        name={"customerId"}
                        type={"text"}
                        ids={"ApplybonusCID"}
                        regex={/^[1-9][0-9\b]*$/}
                        value={formData.customerId}
                        maxLen={7}
                        setValue={(e) => setValue(e)}
                        errorMsg={
                          custIdErr && "Please enter proper customer id"
                        }
                      />
                    )}
                  </div>
                )}
              </div>

              <div className='row'>
                <ButtonGroup
                  groupNames={
                    formData.uploadType === "bulk"
                      ? ["Upload", "Cancel"]
                      : ["Apply", "Reset"]
                  }
                  spclButtons={
                    formData.uploadType === "bulk"
                      ? [
                        "Sample File",
                        {
                          fileName: "sample file.csv",
                          filePath: sampleCsvFile,
                        },
                      ]
                      : null
                  }
                  idsearch={
                    formData.uploadType === "bulk"
                      ? "ApplyBulkupload"
                      : "ApplyBonus"
                  }
                  idreset={
                    formData.uploadType === "bulk"
                      ? "ApplyBonusCancel"
                      : "ApplyBonusReset"
                  }
                  idspecial={"sampleDownload"}
                  type={"submit"}
                  reset={(e) => resetForm(e)}
                />
              </div>
            </div>
          </form>
          {statusBox && (
            <div className='CMS-box CMS-box-content'>
              <div className='CMS-filter-result' id='result'>
                <div className='CMS-pagination'>
                  <div className='d-flex justify-content-center'>
                    <h4 className='mb-20'>{statusMessage}</h4>

                    {/* <div className='text-right'>
                                  <h4>{props.statusMessage}</h4>
                                  <h4>
                                    Total No of Failed Records :
                                    {tableData.length - 1}
                                  </h4>
                                </div> */}
                  </div>
                  <Table tableData={tableData} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    displayValue: state.sidebar.displayValue,
    userId: state.playersearch.userId,
    tableData: state.assignBonusSlice.tableData,
    paginationFirstValue: state.assignBonusSlice.paginationFirstValue,
    paginationSecondValue: state.assignBonusSlice.paginationSecondValue,
    recordsShow: state.assignBonusSlice.recordsShow,
    tagData: state.playertagging.tagData,
    isVisible: state.assignBonusSlice.isVisible,
    bonusData: state.assignBonusSlice.bonussummery,
    activeFreeBets: state.assignBonusSlice.activeFreeBets,
    responseData: state.assignBonusSlice.responseData,
    goodWillBonus: state.assignBonusSlice.goodWillBonus
  };
};

export default connect(mapStateToProps)(AssignBouns);
