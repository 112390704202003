import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getDateTimeFormate, hideAccNo, showfirst4 } from "../../sharedfiles/helper";
import { fetchBankDetails, changePrimaryAccount, activateBank, deactivateBankAccount } from "./BankDetailSlice";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import { BANKDETAIL_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { BankInfo, COMMON_MSGS } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
const BankDetailtable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [accountId, setAccountId] = useState("");
  const id = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const setPrimaryAccnt = (e) => {
    setAccountId(e.target.value);
    setOpenDialog(true);
  };
  const handlePrimary = () => {
    dispatch(changePrimaryAccount(id, accountId));
    setTimeout(() => {
      dispatch(fetchBankDetails({ id }));
    }, 500);
    setOpenDialog(false);
    // }
  };
  function handleBankActivate(id) {
    dispatch(activateBank(id, props.data))
  }
  function handleBankDeactivate(id) {
    dispatch(deactivateBankAccount(id, props.data))
  }

  return (
    <>
      <ConfirmDialog
        title={"Primary account"}
        message={"Do you want to change your Primary Account ?"}
        open={openDialog}
        cancel={() => setOpenDialog(false)}
        verify={handlePrimary}
        close={() => setOpenDialog(false)}
        key={"bankDetails"}
        width={"sm"}
      />
      <div className='CMS-box '>
        <div className='CMS-table CMS-table-triped'>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_ahn}</p>
                    <Tooltips info={BankInfo.ahn} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_bankName}</p>
                    <Tooltips info={BankInfo.bankName} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_branchName}</p>
                    <Tooltips info={BankInfo.branchName} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_accountNo}</p>
                    <Tooltips info={BankInfo.accountNo} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_ifsc}</p>
                    <Tooltips info={BankInfo.ifsc} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{COMMON_CONSTANTS.createdDate}</p>
                    <Tooltips info={COMMON_MSGS.createdDate} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{COMMON_CONSTANTS.updatedDate}</p>
                    <Tooltips info={COMMON_MSGS.updatedDate} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{COMMON_CONSTANTS.status}</p>
                    <Tooltips info={COMMON_MSGS.Status} />
                  </div>
                </th>
                <th>
                  <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{COMMON_CONSTANTS.actions}</p>
                    <Tooltips info={COMMON_MSGS.Actions} />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {props.data && Array.isArray(props.data) &&
                Object.keys(props.data).length > 0 && props.data.length > 0 ? (
                props.data.map((item, index) => {
                  return (
                    <tr key={`${index}_${item.accountNumber}`}>
                      <td>
                        <input
                          type='radio'
                          name='primaryAccount'
                          value={item.id}
                          // checked={primaryAccnt ? primaryAccnt : item.isPrimary}
                          checked={item.isPrimary}
                          // disabled={
                          //   item.isPrimary === null ||
                          //   (item.isPrimary == false ? true : false)
                          // }
                          onChange={(e) => setPrimaryAccnt(e)}
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                      <td>{item.accountHolderName}</td>
                      <td>{item.bankName}</td>
                      <td>{item.branchName}</td>
                      <td>{!props.masking ? hideAccNo(item.accountNumber) : item.accountNumber}</td>
                      <td>{item.ifscCode}</td>
                      <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                      <td>{item.updatedAt ? getDateTimeFormate(item.updatedAt) : "-"}</td>
                      <td>
                        {item.isActive === false ? <p>INACTIVE</p> : <p>ACTIVE</p>}
                      </td>
                      <td>
                        <div className="CMS-btnContainer">
                          <div>
                            <button
                              className={item.isActive === true ? 'CMS-btnStatus CMS-statusActive CMS-btnSmall pointer disabled-button' : 'CMS-btnStatus CMS-statusActive CMS-btnSmall pointer'}

                              type='button'
                              id='bankactivesave'
                              // disabled={item.isActive === true}
                              onClick={() => handleBankActivate(item.id)}
                            >
                              {COMMON_CONSTANTS.active}
                            </button>
                            <button
                              className={item.isActive === false ? 'mt-10 CMS-btnStatus CMS-btnSmall  CMS-btn CMS-statusClosed disabled-button' : "mt-10 CMS-btnStatus CMS-btnSmall pointer CMS-btn CMS-statusClosed"}
                              type='button'
                              id='bankactivecancel'
                              // disabled={item.isActive === false}
                              onClick={() => handleBankDeactivate(item.id)}
                            >
                              {COMMON_CONSTANTS.deactive}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <No__Data__Found colspan={100} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};


export default BankDetailtable;
