import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  MomentUTCDateTimeEnd,
  MomentUTCDateTimeToday,
  formatMoney,
  getDateTimeFormate,
  getFromLocalStorage,
  getUTCDate,
  setToLocalStorage,
} from "../../sharedfiles/helper";

import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import {
  AffiliateData,
  AffiliateDropdown,
  subAfftbHeader,
  affiliateDates,
} from "../Common/data/mapData";
import {
  createAffiliate,
  getAffiliateTable,
  editAgent,
  setOpenDialog,
  getUpdateUser,
  setRecords_bcoff,
  setIsVisible_suboff,
  setPage_suboff,
  setItems_suboff,
  setDocsData_subaff,
  setFormData_subaff,
  setFormErrors_subaff,
  setSelected_AffiliateId,
  setCampaignList,
  setChannel,
  getAffArtwork,
  setArtworkVisible,
  setIsVisible,
  getSearchAffData,
  setIsAffChannel
} from "../Affiliate/AffiliateSlice";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Select from "../Common/common_inputs/Select_DDL";
import {
  getPlayerSearchList,
  setAffiliateBtag,
  setBtagKey,
  setIsVisible as playersLoader,
  setLoader,
  setPlayerSearch
} from "../playerTable/playerSearchTableSlice";
import Skelton from "../Common/skelton/Skelton";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import EditUser from "../Common/Dialog/EditUser";
import { getUserDetails } from "../CreateUser/createUserSlice";
import AgentEditDialog from "./AgentEditDialog";
import { AFFILIATE_CONSTANTS, COMMON_CONSTANTS, CREATEROLE_CONSTANTS, MANUALDIALOG_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { Admin, AffiliateInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import ArtworkPopup from "./ArtworkPopup";
import Datepicker from "../Common/common_inputs/DatePicker";
const SubAffiliate = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [AffiliateId, setAffiliateId] = useState("");
  const [pdfBody, setPdfBody] = useState([]);
  const [searchData, setSearchData] = useState("")
  const [formdata, setFormData] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    loginPin: "",
    // clientId: "",
    // btag: "",
    // role: "ADMIN",
    commissionPercentage: "",
    payoutCycle: "",
    setupCost: "",
    affiliateType: "",
  });
  const [affiliateInput, setAffiliateInput] = useState({
    payoutCycle: "",
    setupCost: "",
    commissionPercentage: "",
  });
  const [dateErr, setDateErr] = useState("")
  const [subAffDteFields, setsubAffDteFields] = useState({
    regDateFrom: '',
    regDateTo: '',
  })
  const [agentEditError, setAgentEditError] = useState([]);
  const history = useHistory();
  const [editUserBox, setEditUserBox] = useState(false);
  const [seletedItem, setSelectedItem] = useState({});
  const [openArtwork, setOpenArtwork] = useState(false)
  const pageFirstVal = useSelector((state) => state.affiliateSlice.page_suboff);
  const pageSecondVal = useSelector(
    (state) => state.affiliateSlice.items_suboff
  );
  const recordsToShow = useSelector(
    (state) => state.affiliateSlice.records_suboff
  );
  const listAffiliate = useSelector(
    (state) => state.affiliateSlice.resData_suboff
  );
  const afiilatePay = useSelector((p) => p.dashboard.afiilatePay)

  const affGgrNgrPermission = useSelector((p) => p.dashboard.affiliate_GGR_NGR)
  const affCommissionPermission = useSelector((p) => p.dashboard.affCommission)
  const permissionToEditUserDetails = props.permissionToEditUserDetails;

  const filteredHeader = subAfftbHeader.filter((header) => {
    if (header === "Edit User") {
      return permissionToEditUserDetails;
    } else if (header === "NGR" || header === "GGR") {
      return affGgrNgrPermission;
    } else if (header === 'Setup Cost' || header === "Gross Commission" || header === "Net Commission") {
      return affCommissionPermission
    }
    else {
      return true; // Include all other headers
    }
  });

  const subAffTooltip = AffiliateInfo.filter((ele, id) => id !== 9 && id !== 10 && id !== 8)

  const filedsHandler = (e, status) => {
    const { name, value } = e.target;

    // const newError = error.filter((err) => name !== err);
    // setError(newError);
    if (status == "dialog") {
      setAffiliateInput((data) => {
        return { ...data, [name]: value };
      });
      if (value === "") {
        setAgentEditError([...agentEditError, name]);
      } else {
        const isErrorsExist = agentEditError.length > 0;
        if (isErrorsExist) {
          const updated = agentEditError.filter((err) => err !== name);
          setAgentEditError(updated);
        }
      }
    }
    if (status == "input") {
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      dispatch(setFormData_subaff({ ...props.formData_subaff, [name]: value }));

      if (value === "") {
        setError([...error, name]);
        dispatch(setFormErrors_subaff([...props.formErrors_subaff, name]));
      } else {
        const isErrorsExist = error.length > 0;
        if (isErrorsExist) {
          const updatedErrors = error.filter((err) => err !== name);
          setError(updatedErrors);
          dispatch(setFormErrors_subaff(updatedErrors));
        }
      }
    }
  };

  const validator = () => {
    const inputValue = Object.keys(formdata);
    const err = [];
    inputValue.map((value) => formdata[value] === "" && err.push(value));
    setError(err);
    dispatch(setFormErrors_subaff(err));
    return err.length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validator();
    if (validator()) {
      const parentAffiliateId = getFromLocalStorage("AffliateUserId");
      const payload = { ...formdata, parentAffiliateId: parentAffiliateId };
      dispatch(createAffiliate(payload));

      setTimeout(() => {
        const parentAffiliateId = getFromLocalStorage("AffliateUserId");
        dispatch(setIsVisible_suboff(false));
        dispatch(
          getAffiliateTable(pageFirstVal - 1, pageSecondVal, parentAffiliateId)
        );
        resetButton();
      }, 500);
    }
  };

  const resetButton = (e) => {
    setFormData({
      userName: "",
      firstName: "",
      lastName: "",
      loginPin: "",
      // clientId: "",
      // btag: "",
      // role: "ADMIN",
      commissionPercentage: "",
      payoutCycle: "",
      setupCost: "",
      affiliateType: "",
    });
    setError([]);
    setAgentEditError([]);
    dispatch(setFormData_subaff({}));
    dispatch(setFormErrors_subaff([]));
  };

  useEffect(() => {
    const parentAffiliateId = getFromLocalStorage("AffliateUserId");
    const dataExist =
      Object.keys(props?.resData_suboff).length > 0 &&
      props.resData_suboff?.data?.length > 0;
    if (!dataExist) {
      dispatch(setIsVisible_suboff(false));
      dispatch(
        getAffiliateTable(pageFirstVal - 1, pageSecondVal, parentAffiliateId)
      );
      dispatch(setFormData_subaff(formdata));
    } else {
      const csvDataExist =
        props?.docsData_subaff &&
        Array.isArray(props.docsData_subaff) &&
        props.docsData_subaff.length > 0;
      let pdfData = [];
      if (csvDataExist) {
        pdfData = props?.docsData_subaff.slice(
          1,
          props?.docsData_subaff?.length
        );
      }
      setCsvData(props.docsData_subaff);
      setPdfBody(pdfData);

      setFormData(props.formData_subaff);
      setError(props.formErrors_subaff);
    }
  }, []);

  const handleActiveDeactive = (item, status) => {
    const data = {
      userId: item.id,
      obj: {
        firstName: item.firstName,
        lastName: item.lastName,
        // "platFormClient": "string",
        status: status,
        userName: item.userName,
      },
    };
    dispatch(getUpdateUser(data));
    const parentAffiliateId = getFromLocalStorage("AffliateUserId");

    dispatch(
      getAffiliateTable(pageFirstVal - 1, pageSecondVal, parentAffiliateId)
    );
  };

  const handleDialog = (item) => {
    setAffiliateId(item.id);
    props.dispatch(setOpenDialog(true));
    setAffiliateInput({
      payoutCycle: item.payoutCycle,
      setupCost: item.setupCostPercentage,
      commissionPercentage: item.commissionPercentage,
    });
    setAgentEditError([]);
  };

  const dialogFieldValuator = () => {
    const inputValue = Object.keys(affiliateInput);
    const err = [];

    inputValue.map((value) => affiliateInput[value] === "" && err.push(value));
    setAgentEditError(err);
    return err.length === 0;
  };

  const onSave = (e) => {
    if (dialogFieldValuator()) {
      const agentData = {
        affiliateId: AffiliateId,
        commissionPercentage: affiliateInput.commissionPercentage,
        payoutCycle: affiliateInput.payoutCycle,
        setupCost: affiliateInput.setupCost,
      };

      props.dispatch(setOpenDialog(false));
      dispatch(editAgent(agentData));
      setTimeout(() => {
        const parentAffiliateId = getFromLocalStorage("AffliateUserId");

        dispatch(
          getAffiliateTable(pageFirstVal - 1, pageSecondVal, parentAffiliateId)
        );
      }, 500);
    }
  };
  const headers = [
    [
      "ID",
      "User Name",
      "First Name",
      "Last Name",
      "Affiliate Type",
      "Btag",
      "Payout Cycle",
      "Setup Cost %",
      "Setup Cost",
      "Gross Commission",
      "Net Commission",
      "Commission %",
      "Total Registrations",
      "Deposited Customers",
      "Total Deposits",
      "New Customers",
      "Total Stakes",
      "Total Payout",
      "GGR",
      "Total Bonus Amount",
      "Total Number of Bets",
      "Total Withdrawals",
      "Created Date",
      "Updated Date",
      "URL Hits Count",
    ],
  ];
  const headersToExport = headers[0].filter((header) => {
    if (header === "Edit User") {
      return permissionToEditUserDetails;
    } else if (header === "NGR" || header === "GGR") {
      return affGgrNgrPermission;
    } else if (header === 'Setup Cost' || header === "Gross Commission" || header === "Net Commission") {
      return affCommissionPermission
    }
    else {
      return true; // Include all other headers
    }
  });
  const exportPDF = () => {
    const title = "Sub Affiliate Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };

    exportPDFC(title, [headersToExport], pdfBody, styles);
  };
  useEffect(() => {
    if (Object.keys(props.resData_suboff).length > 0) {
      const data =
        props.resData_suboff &&
          props.resData_suboff?.data &&
          Array.isArray(props.resData_suboff?.data)
          ? props.resData_suboff.data.map((p) => [
            p.id,
            p.userName,
            p.firstName,
            p.lastName,
            p.affiliateTypes,
            p.btag,
            p.payoutCycle,
            p.setupCostPercentage,
            ...(affCommissionPermission ? [formatMoney(p.setupCost), formatMoney(p.grossCommission), formatMoney(p.netCommission)] : []),
            p.commissionPercentage,
            p.totalRegistrations,
            p.depositedUserCounts,
            formatMoney(p.totalDeposit),
            formatMoney(p.totalStake),
            formatMoney(p.totalPayout),
            ...(affGgrNgrPermission ? [formatMoney(p.totalGgr), formatMoney(p.totalNgr)] : []),
            p.totalBonus,
            p.betCount,
            formatMoney(p.totalWithdraw),
            p.firstTimeDepositedCustomers,
            p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
            p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
            p.affliateClickCount,
          ])
          : [];

      setPdfBody(data);
      const csvData =
        data && Array.isArray(data) && data.length > 0
          ? [headersToExport, ...data]
          : [headersToExport, []];
      setCsvData(csvData);
      dispatch(setDocsData_subaff(csvData));
    }
  }, [props.resData_suboff]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    const parentAffiliateId = getFromLocalStorage("AffliateUserId");

    dispatch(setIsVisible_suboff(false));

    // const payload = {
    //   endDate: subAffDteFields.regDateTo !== "" ? MomentUTCDateTimeEnd(subAffDteFields.regDateTo) : '',
    //   startDate: subAffDteFields.regDateFrom !== "" ? MomentUTCDateTimeToday(subAffDteFields.regDateFrom) : "",
    //   parentAffiliateId: parentAffiliateId
    // }

    dispatch(
      getAffiliateTable(pageNumber - 1, itemsPerPage, parentAffiliateId)
    );
  };

  function navigateHandler(title, page, item) {
    // setToLocalStorage("AffliateUserId", userId);
    setToLocalStorage("SubAffUserBtag", item.btag);
    const payload = {
      affiliateBtag: item.btag,
    };
    dispatch(playersLoader(false));
    dispatch(setLoader(true));
    dispatch(setPlayerSearch([]));
    dispatch(getPlayerSearchList(0, 25, payload));
    dispatch(setSelected_AffiliateId(item.id));
    localStorage.setItem("subAffUserId", item.id)
    dispatch(setAffiliateBtag(item.btag));
    dispatch(setBtagKey(true));
    title === "CAMPAIGN" && dispatch(setCampaignList([]));
    title === "CHANNEL" && dispatch(setChannel([]));
    history.push(`/${page}`);
    dispatch(setIsAffChannel(true))
  }

  function AffNavigateHandler() {
    dispatch(setBtagKey(true));

    const btag = getFromLocalStorage("AffUserBtag")
    const payload = {
      accountStatus: "ACTIVE",
      affiliateBtag: btag,
    };
    dispatch(getPlayerSearchList(0, 25, payload));
    // dispatch(playersLoader(false));
    // dispatch(setLoader(true));
    // dispatch(setPlayerSearch([]));
    history.push('/playersearch');
  }

  const onDataSearch = value => {
    setSearchData(value)
    let data = []
    data = props.resData_suboff.data !== undefined && props.resData_suboff.data.filter((item) =>
      item.userName.includes(value)
    )
    return data;
  }

  const handleEditUser = (item) => {
    dispatch(getUserDetails(item.id));
    setAffiliateId(item.id);
    setSelectedItem(item);
    setEditUserBox((prev) => !prev);
  }

  const handleArtwork = () => {
    dispatch(setArtworkVisible(true));
    setOpenArtwork(true)
    dispatch(getAffArtwork(0, 25))
  }

  function searchAllRecords() {
    dispatch(
      getAffiliateTable(
        0,
        listAffiliate.totalRecords
      )
    );
  }
  function setDateHandler(value, name) {
    setsubAffDteFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (name === "regDateFrom" && value !== '') {
      setDateErr('')
    }
  }

  const onSearch = () => {
    const parentAffiliateId = getFromLocalStorage("AffliateUserId")
    const payload = {
      endDate: subAffDteFields.regDateTo !== "" ? MomentUTCDateTimeEnd(subAffDteFields.regDateTo) : '',
      startDate: subAffDteFields.regDateFrom !== "" ? MomentUTCDateTimeToday(subAffDteFields.regDateFrom) : "",
      parentAffiliateId: parentAffiliateId
    }
    if (subAffDteFields.regDateFrom === '' || subAffDteFields.regDateFrom === null) {
      setDateErr("Start Date is required");
    } else if (subAffDteFields.regDateFrom !== '') {
      setDateErr('');
      dispatch(setIsVisible(false));
      dispatch(getSearchAffData(0, 25, payload))
    }
  }

  const onResetBtn = () => {
    const parentAffiliateId = getFromLocalStorage("AffliateUserId")
    setsubAffDteFields({
      regDateFrom: '',
      regDateTo: ''
    })
    setDateErr('')
    dispatch(getSearchAffData(0, 25, { parentAffiliateId }))
    dispatch(setPage_suboff(1))
    dispatch(setItems_suboff(25))
    dispatch(setRecords_bcoff([1, 25]))
    dispatch(setIsVisible_suboff(false));
  }

  return (
    <>
      {
        editUserBox &&
        <Dialog__Main
          title={"Edit User Details"}
          dOpen={editUserBox}
          dClose={setEditUserBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"editUserBox"}
        >
          <EditUser
            open={editUserBox}
            close={setEditUserBox}
            userId={AffiliateId}
            userDetails={seletedItem}
            rolesRequired={false}
            page={"subAffiliate"}
          />
        </Dialog__Main>
      }
      <Dialog__Main
        dOpen={props.openDialog}
        dClose={() => props.dispatch(setOpenDialog(false))}
        title={"Edit Agent"}
      >
        <AgentEditDialog
          filedsHandler={filedsHandler}
          onSave={onSave}
          agentEditError={agentEditError}
          affiliateInput={affiliateInput}
        />
      </Dialog__Main>

      <ArtworkPopup
        openArtwork={openArtwork}
        setOpenArtwork={() => setOpenArtwork(false)}
      />

      <div className="CMS-tab-panel active" id="CMS-playerActivity">
        <div className="CMS-tabContent">
          <div className="CMS-box CMS-box-content">
            <div className="row">
              <div className="col-md-4 col-xl">
                <Select
                  title={"Affiliate Type"}
                  name={"affiliateType"}
                  value={formdata.affiliateType}
                  setValue={(val) => filedsHandler(val, "input")}
                  options={AffiliateDropdown}
                  errorMsg={
                    error.length > 0 &&
                    error.includes("affiliateType") &&
                    "select proper Affilate"
                  }
                  ids={"AffiliateType"}
                  info={Admin.Affiliate_Type}
                />
              </div>

              {AffiliateData.map((item, ind) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-3">
                      <Input
                        title={item.title}
                        ids={item.ids}
                        name={item.name}
                        value={formdata[item.name]}
                        placeholder={item.placeholder}
                        setValue={(e) => filedsHandler(e, "input")}
                        regex={item.regex}
                        errorMsg={
                          error.length > 0 &&
                          error.includes(item.name) &&
                          item.errorMsg
                        }
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            <div className="mt-20"></div>
            <div className="row">
              <ButtonGroup
                groupNames={[" Create Sub Affiliate", "Reset"]}
                idsearch={"createSubAff"}
                idreset={"ResetSubAff"}
                submit={(e) => onSubmit(e)}
                reset={(e) => resetButton(e)}
              />
            </div>
          </div>
          <div className="mt-50"></div>
          <div className="CMS-box CMS-box-content">
            <div className='row'>
              {affiliateDates.map((item, ind) => {
                const minDate = [null, subAffDteFields.regDateFrom];
                const max = subAffDteFields.regDateTo ? subAffDteFields.regDateTo : new Date();
                const maxDate = [max, new Date()];
                const values = [subAffDteFields.regDateFrom, subAffDteFields.regDateTo];
                return (
                  <div className='col-3' key={ind}>
                    <Datepicker
                      title={item.title}
                      name={item.name}
                      value={values[ind]}
                      setValue={(date, value) => setDateHandler(date, value)}
                      errorMsg={ind === 0 ? dateErr : ""}
                      minDate={minDate[ind]}
                      maxDate={maxDate[ind]}
                      ids={item.ids}
                      info={item.info}
                    />
                  </div>
                );
              })}
              <div className="ml-15"></div>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={"searchAffiliate"}
                idreset={"affiliateReset"}
                submit={(e) => onSearch(e)}
                reset={(e) => onResetBtn(e)}
              />
            </div>
          </div>

          <div className="CMS-filter-result" id="result">
            <div className="CMS-pagination">
              <Vpagination
                data={listAffiliate.data}
                totalRecords={listAffiliate.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPage_suboff}
                setPaginationSecondValue={setItems_suboff}
                recordsShow={recordsToShow}
                setRecordsShow={setRecords_bcoff}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible_suboff}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Sub Affiliate Report"}
                isSearch={true}
                onDataSearch={onDataSearch}
                focusAction={searchAllRecords}
                placeholder={"Username or Btag"}
              />
            </div>

            <div className="mb-20">
              <div className='CMS-btnContainer' style={{ marginInlineStart: "0px" }}>
                <button className="CMS-btn CMS-btnSecondary active CMS-btnMedium"
                  // id="viewAffUser"
                  onClick={() => AffNavigateHandler()}
                >
                  View Players
                </button>
                <button
                  className="CMS-btn CMS-btnSecondary active CMS-btnMedium viewArtwork-btn"
                  onClick={() => handleArtwork()}
                >
                  View Artwork Details
                </button>
              </div>
            </div>

            {
              !props.isVisible ?
                <Skelton vLen={5} hLen={7} />
                :
                <div className="CMS-box">
                  <div className="CMS-table CMS-table-triped">
                    <table>
                      <thead>
                        <tr>
                          {filteredHeader.map((item, index) => (
                            <th>
                              <div className='d-flex justify-content-center align-items-center'>
                                <p className='mx-3'>{item}</p>
                                <Tooltips info={affCommissionPermission === false ? `${subAffTooltip[index]}` : `${AffiliateInfo[index]}`} />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.resData_suboff &&
                          props.resData_suboff?.data &&
                          props.resData_suboff?.data !== undefined &&
                          Array.isArray(props.resData_suboff?.data) &&
                          props.resData_suboff?.data.length > 0 ? (
                          props.resData_suboff?.data.filter((item) => item.userName.toLowerCase().replace('', '').includes(searchData.toLowerCase().replace(' ', ''))).map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>
                                  <Link
                                    to={{}}
                                    onClick={() =>
                                      navigateHandler(
                                        "PLAYER SEARCH",
                                        "playersearch",
                                        item
                                      )
                                    }
                                  >
                                    {item.userName}
                                  </Link>
                                </td>
                                <td>{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>{item.affiliateTypes}</td>
                                <td>{item.btag}</td>

                                <td>{item.payoutCycle}</td>

                                <td>{item.setupCostPercentage}</td>
                                {affCommissionPermission ? <td>{formatMoney(item.setupCost)}</td> : ""}
                                {affCommissionPermission ? <td>{formatMoney(item.grossCommission)}</td> : ""}
                                {affCommissionPermission ? <td > {formatMoney(item.netCommission)}</td> : ""}
                                <td>{item.commissionPercentage}</td>
                                <td>{item.totalRegistrations}</td>
                                <td>{item.depositedUserCounts}</td>
                                <td>{formatMoney(item.totalDeposit)}</td>
                                <td>{formatMoney(item.totalStake)}</td>
                                <td>{formatMoney(item.totalPayout)}</td>
                                {affGgrNgrPermission ? <td>{formatMoney(item.totalGgr)}</td> : ""}
                                {affGgrNgrPermission ? <td>{formatMoney(item.totalNgr)}</td> : ""}
                                <td>{item.totalBonus}</td>
                                <td>{item.betCount}</td>
                                <td>{formatMoney(item.totalWithdraw)}</td>
                                <td>{item.firstTimeDepositedCustomers}</td>
                                <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                                <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                                <td className="text-center">
                                  <button
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                    onClick={() =>
                                      navigateHandler("CAMPAIGN", "campaign", item)
                                    }
                                  >
                                    <p>{AFFILIATE_CONSTANTS.campaign}</p>
                                  </button>

                                  <div className="mb-10" bis_skin_checked="1"></div>

                                  <button
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                    onClick={() =>
                                      navigateHandler("CHANNEL", "channel", item)
                                    }
                                  >
                                    <p>{AFFILIATE_CONSTANTS.channel}</p>
                                  </button>
                                </td>
                                <td className="text-center">
                                  {item.accountStatus === "SUSPENDED" ? (
                                    <button
                                      className="CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusActive pointer"
                                      onClick={() =>
                                        handleActiveDeactive(item, "ACTIVE")
                                      }
                                    >
                                      <p>{COMMON_CONSTANTS.activate}</p>
                                    </button>
                                  ) : (
                                    <button
                                      className="CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusClosed pointer"
                                      onClick={() =>
                                        handleActiveDeactive(item, "SUSPENDED")
                                      }
                                    >
                                      <p>{COMMON_CONSTANTS.Deactivate}</p>
                                    </button>
                                  )}
                                  <div className="mb-10" bis_skin_checked="1"></div>
                                  <button
                                    className="CMS-btn CMS-btnPrimary active vh_laign_center"
                                    onClick={() => handleDialog(item)}
                                  >
                                    <span className="material-icons edit">
                                      {COMMON_CONSTANTS.span_editIcon}
                                    </span>
                                    &nbsp;{COMMON_CONSTANTS.edit}
                                  </button>
                                </td>
                                <td>{item.affliateClickCount}</td>
                                {props.permissionToEditUserDetails &&
                                  <td>
                                    <button
                                      className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                                      onClick={() => handleEditUser(item)}
                                      id='EditUserBtn'
                                    >
                                      {MANUALDIALOG_CONSTANTS.edit_user}
                                    </button>
                                  </td>
                                }
                              </tr>
                            );
                          })
                        ) : (
                          <No__Data__Found colspan={22} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    resData_suboff: state.affiliateSlice.resData_suboff,
    openDialog: state.affiliateSlice.openDialog,
    isVisible: state.affiliateSlice.isVisible_suboff,
    docsData_subaff: state.affiliateSlice.docsData_subaff,
    formData_subaff: state.affiliateSlice.formData_subaff,
    formErrors_subaff: state.affiliateSlice.formErrors_subaff,
    resData: state.affiliateSlice.resData,
    affiliateUsers: state.affiliateSlice.affiliateUsers,
    userRole: state.loginSlice.userRole,
    permissionToEditUserDetails: state.dashboard.permissionToEditUserDetails
  };
}

export default connect(mapStateToProps)(SubAffiliate);
