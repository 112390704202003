import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Country, regexType } from "../../sharedfiles/helper";
import { getFinancials } from "../primaryplayer/PrimaryPlayerSlice";
import {
  getAddManualList,
  getPaymentHistorytableList,
  getWithdrawBonus,
  setIsVisisble,
  setShowPayments,
} from "./paymentHistoryTableSlice";
import { manualDropdown } from "../Common/data/mapData";
import { COMMON_CONSTANTS, MANUALDIALOG_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { Payments } from "../../sharedfiles/tooltipMsgs";
const ManualDialog = (props) => {
  const { status, DialogClose } = props;
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [userMsg, setUserMsg] = useState("");
  const [adjustmentType, setAdjustmentType] = useState("");
  const [payment, setPayment] = useState("");
  const [wallet, setWallet] = useState("")
  const [allocation, setAllocation] = useState("")
  const [toggle, setToggle] = useState(false)
  const [isToggled, setIsToggle] = useState("");
  const [errors, setErrors] = useState({
    amount: "",
    adjustmentType: "",
    payment: "",
    comments: "",
    wallet: "",
    allocation: ""
  });

  const financialData = useSelector((state) => state.primaryplayer.financialData);
  const manualToggle = useSelector((state) => state.dashboard.manualToggle);
  const mvDepositManual = useSelector((state) => state.dashboard.mvDepositManual)

  const setErrorHandler = (name, val, msg) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [name]: val === "" ? msg : "",
      };
    });
  };
  const handleAmount = (e) => {
    const { name, value } = e.target;
    let regex = regexType.oneDecimal;
    if (value === "" || regex.test(value)) {
      setAmount(value);
    }
    setErrorHandler(name, value, "Please enter valid Amount");
  };
  const handleDdl = (e) => {
    const { name, value } = e.target;
    if (name === "adjustmentType") {
      setAdjustmentType(value);
      setErrorHandler(name, value, "Please enter valid Type");
    } else {
      setPayment(value);
      setErrorHandler(name, value, "Please enter valid Operation");
    }

  };

  const walletDdl = (e) => {
    const { name, value } = e.target;
    if (name === "wallet") {
      setWallet(value)
      setErrorHandler(name, value, "Please enter valid Wallet Type");
    } else {
      setPayment(value);
      setErrorHandler(name, value, "Please enter valid Operation");
    }
  }

  const bonusHandle = (e) => {
    const { name, value } = e.target;
    if (name === "allocation") {
      setAllocation(value)
      setErrorHandler(name, value, "Please enter valid Bonus Type");
    }
    else {
      setPayment(value);
      setErrorHandler(name, value, "Please enter valid Operation");
    }
  }

  const toggled = (e) => {
    setIsToggle(!isToggled)
    isToggled ? setToggle(false) : setToggle(true)

  }
  const handleMessage = (e) => {
    const { value } = e.target;
    setUserMsg(value);
    setErrorHandler("comments", value, "Please enter valid Comments");
  };
  const avaliableBalance = () => {
    let condition = false;
    const balance =
      Object.keys(financialData).length > 0 &&
      financialData.playerSummaryVw?.withdrawls;
    const available = Number(Math.floor(balance / 100));
    // if (payment === "WITHDRAW" && amount > available) {
    //   condition = true;
    // }
    return condition;
  };
  const formValidation = () => {
    let isValid = true;
    if (amount === "") {
      setErrorHandler("amount", amount, "Please enter valid Amount");
      isValid = false;
    }
    if (adjustmentType === "") {
      setErrorHandler("adjustmentType", adjustmentType, "Please enter valid Type");
      isValid = false;
    }
    if (payment === "") {
      setErrorHandler("payment", payment, "Please enter valid Operation");
      isValid = false;
    }
    if (3 > userMsg.trim().length) {
      setErrorHandler("comments", userMsg, "Please enter valid Comments");
      isValid = false;
    }
    if (payment === "WITHDRAW" && wallet === "") {
      setErrorHandler("wallet", wallet, "Please enter valid Wallet Type");
      isValid = false;
    }

    if (wallet === "BONUSBALANCE" && allocation === "") {
      setErrorHandler("allocation", allocation, "Please enter valid Bonus Type");
      isValid = false;
    }
    avaliableBalance() && (isValid = false);
    return isValid;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (avaliableBalance()) {
      setErrorHandler("amount", "", "Insufficient Funds");
    } else {
      var playerserachdata = {
        amount: amount,
        adjustmentType: adjustmentType,
        paymentType: payment,
        description: userMsg,
        status: status,
        offlinePayment: false,
        addToWithdrawBucket: toggle,
        // wallet: wallet,
      };
      var isValid = formValidation();
      if (isValid) {
        dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
        dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
        dispatch(
          getAddManualList(localStorage.getItem("userId"), playerserachdata)
        );
        DialogClose();
        setTimeout(() => {
          dispatch(setShowPayments(true));
        }, 2000);
        dispatch(setIsVisisble(false));
        setTimeout(() => {
          dispatch(
            getPaymentHistorytableList(0, 25, {
              id: localStorage.getItem("userId"),
            })
          );
        }, 1000);
      }
    }

  };

  const realBalance = () => {
    const userId = localStorage.getItem("userId")
    var isValid = formValidation();
    const data = {
      amount: amount,
      adjustmentType: adjustmentType,
      paymentType: payment,
      description: userMsg,
      offlinePayment: false,
    }
    if (isValid) {
      dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
      dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
      dispatch(
        getAddManualList(localStorage.getItem("userId"), data)
      );
      DialogClose();
      setTimeout(() => {
        dispatch(setShowPayments(true));
      }, 2000);
      dispatch(setIsVisisble(false));
      setTimeout(() => {
        dispatch(
          getPaymentHistorytableList(0, 25, {
            id: userId,
          })
        );
      }, 1000);
    }
  }

  const withdrawSubmit = () => {
    const userId = localStorage.getItem("userId")
    console.log(userId, "user")
    var isValid = formValidation();
    var payload = {
      amount: amount,
      transactionType: adjustmentType,
      userId: userId,
      withdrawalBonus: allocation
    }

    if (isValid) {
      dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
      dispatch(getFinancials({ user_id: localStorage.getItem("userId") }));
      dispatch(
        getWithdrawBonus(payload)
      );
      DialogClose();
      setTimeout(() => {
        dispatch(setShowPayments(true));
      }, 2000);
      dispatch(setIsVisisble(false));
      setTimeout(() => {
        dispatch(
          getPaymentHistorytableList(0, 25, {
            id: userId,
          })
        );
      }, 1000);
    }
  }
  const errorStyles = {
    color: "red",
    marginTop: "-13px",
    marginBottom: "20px",
  };
  return (
    <div align='center' style={{ minHeight: "360px" }}>
      <div className='dialog-box' align='center'>
        <div className='CMS-box CMS-box-content'>
          <div className='row' style={{ minHeight: "250px" }}>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {MANUALDIALOG_CONSTANTS.operation}
                  &nbsp;&nbsp;
                  <Tooltips info={Payments.Operation} />
                </div>
                <div className='CMS-dropdown CMS-formControl'>
                  <div className='CMS-select'>
                    <select
                      name='payment'
                      onChange={(e) => handleDdl(e)}
                      value={payment}
                      className="pointer"
                    >
                      <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                      <option value='DEPOSIT'>{COMMON_CONSTANTS.deposit}</option>
                      <option value='WITHDRAW'>{COMMON_CONSTANTS.withDraw}</option>
                      {mvDepositManual ? <option value='MV_DEPOSIT_TO_WTH_AMT'>{COMMON_CONSTANTS.MV_DEPOSIT_TO_WTH_AMT}</option> : ''}
                    </select>
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors.payment}</div>}
            </div>

            {payment === "WITHDRAW" ? <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {MANUALDIALOG_CONSTANTS.wallet}
                  &nbsp;&nbsp;
                  <Tooltips info={Payments.manual_wallet} />
                </div>
                <div className='CMS-dropdown CMS-formControl'>
                  <div className='CMS-select select-width'>
                    <select
                      id='wallet'
                      name='wallet'
                      onChange={(e) => walletDdl(e)}
                      value={wallet}
                      className="pointer"
                    >
                      <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                      <option value='REALBALANCE'>{MANUALDIALOG_CONSTANTS.ddlOpt_realBalance}</option>
                      <option value='BONUSBALANCE'>{MANUALDIALOG_CONSTANTS.ddlOpt_bonusBalance}</option>

                    </select>
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors.wallet}</div>}
            </div> : ""}

            {payment !== "DEPOSIT" && wallet === "BONUSBALANCE" ? <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>{MANUALDIALOG_CONSTANTS.bonus_allocation}</div>
                <div className='CMS-dropdown CMS-formControl'>
                  <div className='CMS-select select-width'>
                    <select
                      id='allocation'
                      name='allocation'
                      onChange={(e) => bonusHandle(e)}
                      value={allocation}
                      className="pointer"
                    >
                      <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                      <option value='unlocked '>{MANUALDIALOG_CONSTANTS.ddlOpt_unlockedBonus}</option>
                      <option value='locked'>{MANUALDIALOG_CONSTANTS.ddlOpt_lockedBonus}</option>

                    </select>
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors.allocation}</div>}
            </div> : ""}

            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {MANUALDIALOG_CONSTANTS.header_type}
                  &nbsp;&nbsp;
                  <Tooltips info={Payments.manual_Type} />
                </div>
                <div className='CMS-dropdown CMS-formControl'>
                  <div className='CMS-select select-width'>
                    <select
                      id='adjustmentType'
                      name='adjustmentType'
                      onChange={(e) => handleDdl(e)}
                      value={adjustmentType}
                      className="pointer"
                    >
                      <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                      {manualDropdown.map((item, index) => {
                        return (
                          (payment === "DEPOSIT" && item.isDeposit) ||
                            (payment === "WITHDRAW" && !item.isDeposit && !item.isMvDeposit) ||
                            (payment === "MV_DEPOSIT_TO_WTH_AMT" && item.isMvDeposit) ?
                            <option key={index} value={item.value}>{item.name}</option> :
                            null
                        );
                      })}

                    </select>
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors.adjustmentType}</div>}
            </div>

            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {COMMON_CONSTANTS.th_amount}
                  &nbsp;&nbsp;
                  <Tooltips info={Payments.Amount} />
                </div>
                <div className='CMS-formControl-group'>
                  <div className='CMS-formControl'>
                    <input
                      name='amount'
                      type='text'
                      // maxLength={5}
                      placeholder=''
                      onChange={handleAmount}
                      value={amount}
                    />
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors?.amount}</div>}
            </div>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {COMMON_CONSTANTS.header_comment}
                  &nbsp;&nbsp;
                  <Tooltips info={Payments.manual_comment} />
                </div>
                <div className='CMS-formControl-group'>
                  <div className='CMS-formControl'>
                    <textarea
                      onChange={handleMessage}
                      value={userMsg}
                      rows='2'
                      cols='50'
                      name='comments'
                      maxlength='70'
                    ></textarea>
                  </div>
                </div>
              </div>
              {<div style={errorStyles}>{errors.comments}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-20'></div>

      {manualToggle && payment === "DEPOSIT" ?
        <div className='row' >
          <div className='col-12 d-flex justify-content-center'>
            <div className='CMS-formGroup formGroup-inline'>
              <div className='CMS-formLabel mb-0 mr-10'>
                {MANUALDIALOG_CONSTANTS.add_withdrwable_balance}
              </div>
              <div className='CMS-toggleSwitch'>
                <input
                  id='toggleActive'
                  name='toggleActive'
                  value={toggle}
                  checked={isToggled}
                  type='checkbox'
                  onChange={toggled}
                />
                <label for='toggleActive'></label>
              </div>
            </div>
          </div>
        </div> : ""}

      <div className='row'>
        <div className='col-md-12 col-lg-12 col-xl-12'>
          <div className='CMS-btnContainer'>
            {wallet === "BONUSBALANCE" ?
              <button
                onClick={withdrawSubmit}
                className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                type='button'
              >
                {COMMON_CONSTANTS.save}
              </button>
              : wallet === "REALBALANCE" ?
                <button
                  onClick={realBalance}
                  className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                  type='button'
                >
                  {COMMON_CONSTANTS.save}
                </button> :
                <button
                  onClick={submitHandler}
                  className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                  type='button'
                >
                  {COMMON_CONSTANTS.save}
                </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualDialog;
