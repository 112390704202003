import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer } from "../../sharedfiles/helper";


export const AffiliateSummarySlice = createSlice({
    name: "AffiliateSummarySlice",
    initialState: {
        affSummaryData: [],
        errMsg: []
    },
    reducers: {
        setAffSummaryData: (state, action) => {
            state.affSummaryData = action.payload
        },
        setErrMsg: (state, action) => {
            state.errMsg = action.payload
        }
    }
});

export const { setAffSummaryData, setErrMsg } = AffiliateSummarySlice.actions

export const getAffiliateSummary = (params) => (dispatch) => {
    const url = userApi.getAffSummaryDetail
    apiCaller(url, "POST", params)
        .then((res) => {dispatch(setAffSummaryData(res.data))})
        .catch((err) => {
            console.log(err)
            const error = err.response
            dispatch(setErrMsg(messageEnhancer(error)))
        });
};

export default AffiliateSummarySlice.reducer