import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setReportPage } from "../Reports/reportSlice";
import { Link } from "react-router-dom";
import { tabManager } from "../sidebar/sidebarSlice";
import { REPORTS_CONSTANTS } from "../../sharedfiles/Constants";
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeIcon: true,
    };
  }

  handleReportClick = (label, parameter) => {
    const Title =
      this.props.reports &&
      this.props?.reports.filter((report) => report.label.includes(parameter));
    let reportTitle = `${Title[0].label.split(" ")[0]} ${Title[0].label.split(" ")[1]
      }`;
    const data = {
      label: label,
      param: parameter.toUpperCase(),
    };
    const reportId = "35e8fafd-248e-467b-bbef-0feaff1d57b4"
    localStorage.setItem("reportId", reportId);
    this.props.dispatch(setReportPage(data));
    this.props.dispatch(tabManager(`/report/${parameter.toLowerCase()}`, this.props.tabStack));
  };

  getAllReports() {
    const props = this.props;
    const privateReport = [
      props.withdrawReport ? "" : "Withdrawal Reports",
      props.depositReport ? "" : "Deposit Reports",
      props.placeBetReport ? "" : "Placebet Reports",
      props.liabilityReport ? "" : "Liability Reports",
    ]
    return props?.reports.filter((p, i) => !privateReport.includes(p.label))
  }

  render() {
    return (
      <>
        <div className='CMS-tab-panel active' id='CMS-reports'>
          <div className='CMS-tabContent'>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>{REPORTS_CONSTANTS.report_name}</th>

                      <th className='details-of-reports'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getAllReports().map((report, index) => {
                      return (
                        <tr key={index}>
                          <td>{report.label}</td>
                          <td>
                            <button
                              className='CMS-btn CMS-btnDefault CMS-btnSecondary active CMS-btnMedium'
                              type='button'
                              onClick={() =>
                                this.handleReportClick(
                                  report.label,
                                  report.label.split(" ")[0]
                                )
                              }
                            >
                              <Link
                                to={report.path}
                                style={{ color: "#fff" }}
                              >
                                {REPORTS_CONSTANTS.report_detailBtn}
                              </Link>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    reports: state.reports.reports,
    userId: state.playersearch.userId,
    tabStack: state.sidebar.tabStack,
    withdrawReport: state.dashboard.withdrawReport,
    depositReport: state.dashboard.depositReport,
    placeBetReport: state.dashboard.placeBetReport,
    liabilityReport: state.dashboard.liabilityReport
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
