import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
    bettingApi,
    setBaseUrl,
} from "../../sharedfiles/EndpointConfig";

export const GamesBetHistorySlice = createSlice({
    name: "GamesBetHistorySlice",
    initialState: {
        gamesBetData: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
        payloadData: {},
        prevFormData: {},
        docsData: [],
        pdfData: [],

    },
    reducers: {
        setGamesBetData: (state, action) => {
            state.gamesBetData = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setPayloadData: (state, action) => {
            state.payloadData = action.payload;
        },
        setPrevFormData: (state, action) => {
            state.prevFormData = action.payload;
        },
        setDocsData: (state, action) => {
            state.docsData = action.payload;
        },
        setGlobalPdfData: (state, action) => {
            state.pdfData = action.payload;
        },
    }
})

export const {
    setGamesBetData,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,
    setPayloadData,
    setPrevFormData,
    setDocsData,
    setGlobalPdfData,
} = GamesBetHistorySlice.actions

const clean = (obj) => {
    for (var propName in obj) {
        if (
            obj[propName] == null ||
            obj[propName] === undefined ||
            obj[propName] === "" ||
            obj[propName] === 0 ||
            obj[propName] == "NaN-aN-aNT23:59:59.517Z" ||
            obj[propName] == "NaN-aN-aNT00:00:00.517Z"
        ) {
            delete obj[propName];
        }
    }
    return obj;
};

export const getGamesBetHistory =
    (pagenumbervalue, itemperpagevalue, params, activeAll) =>
        (dispatch) => {
            setBaseUrl();
            let obj = {};
            if (
                (params && params?.betId) ||
                params?.gameType ||
                params?.betType ||
                params?.betSize ||
                params?.betStatus ||
                params?.sport ||
                params?.userId ||
                params?.placedAfter ||
                params.placedBefore ||
                params.minStake ||
                params.maxStake ||
                params.minPotentialPayout ||
                params?.maxPotentialPayout ||
                params?.settledAfter ||
                params?.settledBefore ||
                params?.role ||
                params?.userName ||
                params?.betSelectionId ||
                params?.parentAgentUserName
            ) {
                var numberUserId = parseInt(params.userId ? params.userId : 0);

                var data = {
                    ...(params.gameType != " " && { gameType: params.gameType }),
                    betType: params.betType,
                    betId: params.betId,
                    betSize: params.betSize,
                    betStatus: params.betStatus,
                    placedAfter: params.placedAfter,
                    placedBefore: params.placedBefore,
                    sport: params.sport,
                    userId: numberUserId,
                    minStake: params.minStake,
                    maxStake: params.maxStake,
                    minPotentialPayout: params.minPotentialPayout,
                    maxPotentialPayout: params.maxPotentialPayout,
                    settledAfter: params.settledAfter,
                    settledBefore: params.settledBefore,
                    category: params.category,
                    gameCode: params.gameCode,
                    gameName: params.gameName,
                    providerName: params.providerName,
                    subProviderName: params.subProviderName,
                    role: params.role,
                    userName: params.userName,
                    betSelectionId: params.betSelectionId,
                    parentAgentUserName: params.parentAgentUserName,
                };

                obj = clean(data);
            }

            var url =
                `${bettingApi().getBetHistoryApi}` +
                "?pageNumber=" +
                pagenumbervalue +
                "&itemPerPage=" +
                itemperpagevalue;
            apiCaller(url, "POST", obj).then((response) => {
                if (response !== null && response) {
                    if (response.status == 200) {
                        dispatch(setGamesBetData(response.data));
                        dispatch(setIsVisible(true));
                    }
                }
            }).catch(err => {
                dispatch(setGamesBetData([]));
                dispatch(setIsVisible(false));
            });
        };

export default GamesBetHistorySlice.reducer