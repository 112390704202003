import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const DepositproofSlice = createSlice({
    name: "DepositproofSlice",
    initialState: {
        recordsToShow: [1, 25],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        depositProofData: [],
        isVisible: false,
        statusType: "SUBMITTED",
    },
    reducers: {
        setRecordsToShow: (state, action) => {
            state.recordsToShow = action.payload;
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setDepositProofData: (state, action) => {
            state.depositProofData = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setStatusType: (state, action) => {
            state.statusType = action.payload;
        },
    }
});

export const {
    setRecordsToShow,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setDepositProofData,
    setIsVisible,
    setStatusType,
} = DepositproofSlice.actions

export const requestForDepositProof = (tnxId) => (dispatch) => {
    const url = paymentSearchApi.requeastDepoProof(tnxId)
    apiCaller(url, "POST", {}).then((res) => {
        if (res.status === 200) {
            toaster.success(messageEnhancer(res.msg));
        }
    }).catch((err) => {
        console.log(err);
        toaster.error(messageEnhancer(err.response));
    })
}

export const getDepositProof = (pageNum, itemsPerPage, status) => (dispatch) => {
    const url = `${paymentSearchApi.getDepositProof}`
        + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemsPerPage + "&docStatus=" + status;
    apiCaller(url, "POST", {}).then((res) => {
        if (res !== null && res) {
            // if (res.status === 200) {
            console.log(res.data.totalRecords, "rec");
            dispatch(setDepositProofData(res.data))
            dispatch(setIsVisible(true));
            // }
        }
    })
        .catch((err) => {
            console.log(err);
            dispatch(setDepositProofData([]));
            dispatch(setIsVisible(true));
        })
}

export const acceptDepositProof = (id, comments) => (dispatch) => {
    const url = paymentSearchApi.acceptDepoProof(id, comments)
    apiCaller(url, "POST", {}).then((res) => {
        if (res.status === 200) {
            toaster.success("SUCCESS")
            dispatch(getDepositProof(0, 25, "SUBMITTED"))
        } else {
            toaster.error("FAILED");
        }
    }).catch((err) => toaster.error("FAILED"))
}

export const rejectDepositProof = (id, comments) => (dispatch) => {
    const url = paymentSearchApi.rejectDepoProof(id, comments)
    apiCaller(url, "POST", {}).then((res) => {
        if (res.status === 200) {
            toaster.success("SUCCESS")
            dispatch(getDepositProof(0, 25, "SUBMITTED"))
        } else {
            toaster.error("FAILED");
        }
    }).catch((err) => toaster.error("FAILED"))
}

export default DepositproofSlice.reducer;