export const TagType = [
    { id: 1, label: "Custom", value: "Custom" },
    { id: 2, label: "System", value: "System" },
];

export const SubType = [
    { id: 1, label: "Promotion", value: "Promotion" },
    { id: 2, label: "Payments", value: "Payment" },
    { id: 3, label: "Content", value: "Content" },
    { id: 4, label: "Customer Support", value: "Customer_Support" },
    { id: 5, label: "VIP", value: "VIP" },
    { id: 6, label: "Marketing", value: "MARKETING" },

];

export const SystemTags = [
    // { id: 1, label: "Deposit", value: "deposit" },
    { id: 2, label: "Deposit_first", value: "deposit_first" },
    { id: 3, label: "Deposit_fourth", value: "deposit_fourth" },
    { id: 4, label: "Deposit_no", value: "deposit_no" },
    { id: 5, label: "Deposit_reload", value: "deposit_reload" },
    { id: 6, label: "Deposit_second", value: "deposit_second" },
    { id: 7, label: "Deposit_third", value: "deposit_third" },
    { id: 8, label: "Deposit_yes", value: "deposit_yes" },
    { id: 9, label: "Register_dir", value: "register_dir" },
    { id: 10, label: "Register_refer", value: "register_refer" },
]