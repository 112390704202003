import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const PaymentLimits = createSlice({
    name: "paymentLimit",
    initialState: {
        paymentsLimit: [],
        updatedLimits: {},
        disableBtn: false,
    },
    reducers: {
        setPaymentsLimit: (state, action) => {
            state.paymentsLimit = action.payload;
        },
        setUpdatedLimits: (state, action) => {
            state.updatedLimits = action.payload;
        },
        setDisableBtn: (state, action) => {
            state.disableBtn = action.payload;
        },
    }
});

export const { setPaymentsLimit, setUpdatedLimits, setDisableBtn } = PaymentLimits.actions;

export const getPaymentLimits = () => dispatch => {
    const url = paymentSearchApi.paymentLimitsUrl;
    apiCaller(url, "GET").then(res => {
        dispatch(setPaymentsLimit(res.data));
    }).catch(err => console.log(err));
}

export const updatePaymentLimits = (payload) => dispatch => {
    const url = paymentSearchApi.paymentLimitsUrl;
    apiCaller(url, "PUT", payload).then(res => {
        dispatch(setDisableBtn(true));
        dispatch(setUpdatedLimits(res.data));
        res.msg && toaster.success("SUCCESS");
    }).catch(err => {
        dispatch(setDisableBtn(true));
        console.log(err);
        toaster.error("ERROR");
    })
}
export default PaymentLimits.reducer;