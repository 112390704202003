import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";

export const createPermissionSlice = createSlice({
  name: "createPermission",
  initialState: {
    permissionData: [],
    permissionList: [],
    clientData: { id: "", name: "", },
    permissionFiledData: { value: "", error: false },
  },
  reducers: {
    setPermissionData: (state, action) => {
      state.permissionData = action.payload;
    },
    setPermissionList: (state, action) => {
      state.permissionList = action.payload;
    },
    setClientData: (state, action) => {
      state.clientData = action.payload;
    },
    setPermissionFieldData: (state, action) => {
      state.permissionFiledData = action.payload;
    },
  },
});
export const {
  setPermissionData,
  setPermissionList,
  setClientData,
  setPermissionFieldData
} = createPermissionSlice.actions;

export const getPermissionCreate = (params) => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net:443/role/permission/create
  //dev.platform-ups.sportsit-tech.net/permission/create
  var obj = {
    permissionName: [params.permissionName],
    clientId: params.clientId,
  };
  var url = `${userApi.getPermissionCreate}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response) {
        dispatch(setPermissionData(response.data));
        toaster.success("SUCCESS");
        [400, 401, 500].includes(response.status) &&
          toaster.error("ERROR");
      }
    })
    .catch((error) => {
      toaster.error("ERROR");
    });
};

export const getRolePermission = () => (dispatch) => {
  let url = userApi.getRolePermission;
  apiCaller(url, "GET").then((response) => {
    if (response && response.data) {
      dispatch(setPermissionList(response.data));
    }
  });
};

export const editRole = (item) => (dispatch) => {
  let url = `${userApi.getAssignRole}`;
  const data = {
    roleId: item.roleId,
    roles: item.permissionId,
    userId: item.userId,
    // perName: item.perName,
  };
  apiCaller(url, "POST", data)
    .then((response) => {
      if (response && response.data) {
        toaster.success("SUCCESS");
      }
    })
    .catch((error) => {
      toaster.error("ERROR");
    });
};

export default createPermissionSlice.reducer;
