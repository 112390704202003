import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";

export const AccountHistorySlice = createSlice({
  name: "accountHistory",
  initialState: {
    accountData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    formData: {},

  },
  reducers: {
    setAccountDetail: (state, action) => {
      state.accountData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});
export const {
  setAccountDetail,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setFormData
} = AccountHistorySlice.actions;

export const getAccountHistory =
  (pagenumbervalue, itemperpagevalue, params) =>
    (dispatch) => {
      var obj = {
        createdFromDate:params.createdFromDate,
        createdToDate:params.createdToDate,
        transactionId:params.transactionId
      };
      var url =
        `${playerapi.getAccountHistory}` +
        params.id +
        "/history" +
        "?pageNumber=" +
        pagenumbervalue +
        "&itemsPerPage=" +
        itemperpagevalue;

      if (params.id !== null) {
        apiCaller(url, "POST", obj).then((response) => {
          if (response !== null && response) {
            if (response.status == 200) {
              dispatch(setAccountDetail(response.data));
              dispatch(setIsVisible(true));
            }
          }
        });
      }
    };

export default AccountHistorySlice.reducer;
