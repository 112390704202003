import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { generateKey } from "../../sharedfiles/helper";
import Skelton from "../Common/skelton/Skelton";
import { FRAUDSCRUB_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";

export const IpsDialog = (props) => {
  return (
    <>
      <Dialog open={props.openIpDialog}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
            flexBasis: "1 0 auto",
          }}
        >
          <DialogTitle
            style={{
              width: "550px",
            }}
          >
            <div style={{ marginLeft: "200px" }}>
              <span>{FRAUDSCRUB_CONSTANTS.maxUserIp}</span>
              <span style={{ marginLeft: "50px" }}>
                {FRAUDSCRUB_CONSTANTS.count} -
                {props.ipList !== null &&
                  props.ipList !== undefined &&
                  props.ipList.length}
              </span>
            </div>
          </DialogTitle>
          <IconButton
            style={{ color: "white" }}
            key='close'
            arial-label='close'
            onClick={() => props.setOpenIpDialog(false)}
          >
            x
          </IconButton>
        </div>
        <DialogContent>
          {props.ipList &&
            props.ipList !== null &&
            props.ipList !== undefined &&
            Array.isArray(props.ipList) &&
            props.ipList.length > 0 ?
            <div className="CMS-box">
              <div
                className='CMS-table CMS-table-triped'
                style={{ maxHeight: "300px" }}
              >
                <table className='text-center'>
                  <thead>
                    <tr>
                      <th>{FRAUDSCRUB_CONSTANTS.th_ip}</th>
                      <th>{FRAUDSCRUB_CONSTANTS.count}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.ipList &&
                      props.ipList !== null &&
                      props.ipList !== undefined &&
                      Array.isArray(props.ipList) &&
                      props.ipList.length > 0 ? (
                      props.ipList.map((item, ind) => {
                        return (
                          <tr
                            className='tableColumnHover pointer'
                            onClick={() => props.handlePopUp(item)}
                            key={generateKey(ind)}
                          >
                            <td className='tableColumnHover'>{item.ip}</td>
                            <td className='tableColumnHover'>{item.count}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={2} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            :
            <Skelton hLen={2} vLen={3} />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IpsDialog;
