import React, { useState } from 'react'
import Input from '../Common/common_inputs/Input'
import Select from '../Common/common_inputs/Select_DDL'
import { TagType } from '../Common/data/PlayerActivityData/ActivityData'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import { communication_module } from '../../sharedfiles/tooltipMsgs'
import PromoListTable from './PromoListTable'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPromotionList, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, setRecordsShow } from './promotionListSlice'
import { promotionListSts } from '../Common/data/mapData'
import Vpagination from '../../sharedfiles/Vpagination'
import { getDateTimeFormate } from '../../sharedfiles/helper'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Skelton from '../Common/skelton/Skelton'

const PromotionList = () => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState('')
    const [promoTitle, setPromoTitle] = useState('')
    const [statusErr, setStatusErr] = useState('')
    const [csvData, setCsvData] = useState([])
    const [pdfBody, setPdfBody] = useState([])
    const promoListData = useSelector((p) => p.PromoListSlice.promoListData)
    const paginationFirstValue = useSelector((p) => p.PromoListSlice.paginationFirstValue)
    const paginationSecondValue = useSelector((p) => p.PromoListSlice.paginationSecondValue)
    const recordsToShow = useSelector((p) => p.PromoListSlice.recordsShow)
    const isVisible = useSelector((p) => p.PromoListSlice.isVisible)

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'promoTitle') {
            setPromoTitle(value)
        }
    }

    const statusHandle = (e) => {
        setStatus(e.target.value)
    }

    const onSubmit = () => {
        const data = {
            notificationStatus: status,
            title: promoTitle
        }
        dispatch(getPromotionList(status, promoTitle, 0, 25))
    }

    const resetButton = () => {
        setStatus('')
        setPromoTitle('')
        dispatch(getPromotionList("", "", 0, 25,))

    }
    const tHeader = [
        [
            "Promo Title",
            "Message Format Type",
            "User Tag",
            "Priority",
            "Status",
            "Creation Date",
            "End Date",
            "Updated By"
        ]
    ]
    const update = () => {
        const tbody = promoListData &&
            Array.isArray(promoListData.data) ?
            promoListData.data.map((item) =>
            
                [
                    item.title,
                    item.messageType,
                    item.userTags[0] && item.userTags[0].replace(/{|}/g, '') ,
                    item.notificationPriority,
                    item.notificationStatus,
                    getDateTimeFormate(item.createdDate),
                    getDateTimeFormate(item.endDate),
                    item.updatedBy
                ]) : []
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tHeader[0], ...tbody] : [tHeader[0], []];
        setCsvData(data);
        setPdfBody(tbody);
    }
    const exportPDF = () => {
        const title = "Promotion List Report";
        const styles = {
            4: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, tHeader, pdfBody, styles);
    }

    useEffect(() => {
        update();
    }, [promoListData])

    useEffect(() => {
        dispatch(setIsVisible(true));
        dispatch(getPromotionList(status, promoTitle, 0, 25))
    }, [])

    const callPageApi = (pageNumber, itemsPerPage) => {
        dispatch(setIsVisible(true));
        const data = {
            notificationStatus: status,
            title: promoTitle
        }
        dispatch(getPromotionList(status, promoTitle, pageNumber - 1, itemsPerPage))
    }


    return (
        <>
            <div className='CMS-tab-panel active' id='CMS-playerActivity'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content'>
                        <div className='row'>
                            <div className='col-3'>
                                <Input
                                    title={"Promo Title"}
                                    name={"promoTitle"}
                                    value={promoTitle}
                                    placeholder={"Promo Title"}
                                    setValue={(e) => handleChange(e)}
                                    ids={"promotittle"}
                                    info={communication_module.promo_title}
                                    show={true}
                                />
                            </div>
                            <div className='col-3'>
                                <Select
                                    title={"Status"}
                                    name={"status"}
                                    value={status}
                                    setValue={(val) => statusHandle(val)}
                                    options={promotionListSts}
                                    onSubmit={(e) => onSubmit(e)}
                                    errorMsg={statusErr}
                                    ids={"promoStatus"}
                                    info={communication_module.promo_sts}
                                />
                            </div>
                        </div>
                        <div className='mt-20'></div>
                        <div className='row'>
                            <ButtonGroup
                                groupNames={["Search", "Reset"]}
                                idsearch={"promoSearch"}
                                idreset={"promoReset"}
                                submit={(e) => onSubmit(e)}
                                reset={(e) => resetButton(e)}
                            />
                        </div>
                    </div>
                    <div className='CMS-filter-result' id='result'>
                        <div className="CMS-pagination">
                            <Vpagination
                                data={promoListData.data}
                                totalRecords={promoListData.totalRecords}
                                paginationFirstValue={paginationFirstValue}
                                paginationSecondValue={paginationSecondValue}
                                setPaginationFirstValue={setPaginationFirstValue}
                                setPaginationSecondValue={setPaginationSecondValue}
                                recordsShow={recordsToShow}
                                setRecordsShow={setRecordsShow}
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                                csvData={csvData}
                                exportPDF={exportPDF}
                                callApi={callPageApi}
                                csvFileName={"Promotion List Report"}
                                reloadRequired={false}
                            />
                        </div>
                    </div>

                    {!isVisible ? (<Skelton vLen={5} hLen={7} />) : (<PromoListTable tableData={promoListData.data} />)}
                </div>
            </div>

        </>
    )
}

export default PromotionList