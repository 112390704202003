import React from 'react'
import { useSelector } from "react-redux"
import { getPlaceBetReport, setPlaceBetDates } from '../reportSlice'
import ReportsWrapper from "../ReportsWrapper"
import PlaceBetTable from "./PlaceBetTable"

const PlaceBetReport = () => {
    const { placeBetDates, placeBetData, paginationFirstValue, paginationSecondValue } = useSelector(state => state.reports)

    return (
        <React.Fragment>
            <ReportsWrapper
                reportTitle={"PLACEBET REPORT"}
                currentTab={"PLACEBET REPORT"}
                pageKey={"PlacebetReport"}
                setDateToStore={setPlaceBetDates}
                persistDate={placeBetDates}
                sportData={placeBetData}
                pageNumber={paginationFirstValue}
                itemsPerPage={paginationSecondValue}
                api={getPlaceBetReport}
                placeBetPagination={true}
                ReportTable={() => (
                    <PlaceBetTable
                        dates={placeBetDates}
                        reportsData={placeBetData}
                        reportPage={"Placebet Report"}
                    />
                )}
            />
        </React.Fragment>
    )
}
export default PlaceBetReport
