import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  getaddLoginHistory,
  setIsVisible,
} from "./loginHistoryTableSlice";

import {
  getDateTimeFormate,
  getDateFormate,
  cleanObject,
  getUTCDate,
  MomentUTCDateTimeToday,
  MomentUTCDateTimeEnd,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import { LoginHistoryData } from "../Common/data/mapData";
import { HistoryData } from "../Common/data/LoginHistoryData/LoginData";
import Table from "../Common/table/Table";
import { COMMON_MSGS } from "../../sharedfiles/tooltipMsgs";
class LoginHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      startDate: "",
      endDate: "",
      actionType: "",
      ipAddress: "",
      data: [],
      activePage: 1,
      totalPages: "",
      startDateFormat: "",
      endDateFormat: "",
      payloadData: {},
      itemsperpage: "",
      csvData: [],
      pdfBody: [],
      dateError: "",
    };
    this.setValues = this.setValues.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.callApi = this.callApi.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.searchedData = this.searchedData.bind(this);
    this.callCheck = this.callCheck.bind(this);
  }

  callApi(pageNumber, itemsPerPage, data) {
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(
      getaddLoginHistory(
        localStorage.getItem("userId"),
        pageNumber - 1,
        itemsPerPage,
        data,
      )
    );
  }

  emptyState() {
    this.setState({
      startDate: "",
      endDate: "",
      startDateFormat: "",
      endDateFormat: "",
      actionType: "",
      ipAddress: "",
      data: [],
      // payloadData: {},
      itemsperpage: "",
      dateError: "",
    });
    this.setState({ data: [] });
  }

  resetCall(data) {
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(setRecordsShow([1, 25]));
    this.callApi(1, 25, data);
  }

  resetButton() {
    this.emptyState();
    this.setState({ payloadData: {} });
    this.resetCall({});
  }

  setDateHandler(value, name) {
    this.setState({ [name]: value });
    // console.log(name + " " + value);
    // name === "startDate" && this.setState({ endDate: new Date() });
    let payload = this.searchedData();
    if (name === "startDate") {
      payload["startDate"] = getUTCDate(value, "start");
      this.setState({ dateError: "" });
    } else {
      payload["endDate"] = getUTCDate(value, "end");
      this.setState({
        dateError: this.state.startDate === "" ? "Start Date is required" : "",
      });
    }
  }
  setValues(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

  }

  headers = [["Time", "Action", "IP Address", "Device Type"]];
  Info = ["Showing Time", "Showing Action", "Showing IP Address", "Showing Device Type"]

  updateTableToDocs() {
    const data = this.props?.userData &&
      Object.keys(this.props.userData).length > 0 &&
      this.props.userData.data.map((elt) => [
        elt.createdDate ? getDateTimeFormate(elt.createdDate) : "-",
        elt.activity,
        elt.ip,
        elt.channelType ? elt.channelType : "-",
      ]);
    const csvData =
      data.length > 0 ? [this.headers[0], this.Info, ...data] : [this.headers[0], this.Info, []];
    this.setState({ csvData: csvData });
    this.setState({ pdfBody: data });
  }
  exportPDF() {
    const title = "Login History Report";
    exportPDFC(title, this.headers, this.state.pdfBody);
  }
  searchedData() {
    return {
      startDate: MomentUTCDateTimeToday(this.state.startDate),
      endDate: MomentUTCDateTimeEnd(this.state.endDate),
      actionType: this.state.actionType === "ALL" ? "" : this.state.actionType,
      ipAddress: this.state.ipAddress,
    };
  }

  mySubmitFunction(e) {
    e.preventDefault();
    const data = cleanObject(this.searchedData());
    this.setState({ payloadData: data });
    if (Object.keys(data).length > 0) {
      if (!this.state.dateError) {
        this.props.dispatch(setIsVisible(false));
        this.callApi(1, 25, data);
        this.props.dispatch(setPaginationFirstValue(1))
        this.props.dispatch(setPaginationSecondValue(25))
        this.props.dispatch(setRecordsShow([1, 25]))
      }
    }
  }
  callCheck(e) {
    if (e.keyCode === 13) {
      this.mySubmitFunction(e);
    }
  }
  componentDidMount() {
    const id = window.location.pathname.split("/")[2];
    document.addEventListener("keydown", this.callCheck);
    const payload = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      actionType: this.state.actionType,
      ipAddress: this.state.ipAddress,
    };
    this.setState({ payloadData: payload });
    this.callApi(1, 25, { id: id });
  }
  componentDidUpdate(prevprops) {
    const id = window.location.pathname.split("/")[2];
    if (prevprops.userId !== id) {
      this.props.dispatch(setIsVisible(false));
      this.callApi(1, 25, { id: id });
    }

    if (prevprops.userData !== this.props.userData) {
      this.updateTableToDocs();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.callCheck);
  }
  render() {
    return (
      <>
        <form onSubmit={(e) => this.mySubmitFunction(e)}>
          <div className='CMS-tabContent'>
            <div className='CMS-box-content'>
              <div className='CMS-box CMS-box-content'>
                <div className='row'>
                  <div className='col-md-6 col-lg-4 col-xl-3'>
                    <Datepicker
                      title={"Start Date"}
                      name={"startDate"}
                      ids={"loginstartdate"}
                      value={this.state.startDate}
                      setValue={(d, n) => this.setDateHandler(d, n)}
                      maxDate={
                        this.state.endDate
                          ? new Date(this.state.endDate)
                          : new Date()
                      }
                      errorMsg={this.state.dateError}
                      info={COMMON_MSGS.Start_Date}
                    />
                  </div>
                  <div className='col-md-6 col-lg-4 col-xl-3'>
                    <Datepicker
                      title={"End Date"}
                      name={"endDate"}
                      ids={"loginEndate"}
                      value={this.state.endDate}
                      setValue={(d, n) => this.setDateHandler(d, n)}
                      minDate={new Date(this.state.startDateFormat)}
                      maxDate={new Date()}
                      info={COMMON_MSGS.End_Date}
                    />
                  </div>
                  <div className='col-md-6 col-lg-4 col-xl-3'>
                    <Select
                      title={"Action Type"}
                      name={"actionType"}
                      ids={"loginactionType"}
                      value={this.state.actionType}
                      setValue={(val) => this.setValues(val)}
                      options={HistoryData}
                      info={COMMON_MSGS.Action_Type}
                    // onSubmit={(e) => this.mySubmitFunction(e)}
                    />
                  </div>
                  {LoginHistoryData.map((item, ind) => {
                    const values = [this.state.ipAddress];
                    return (
                      <React.Fragment key={item.id}>
                        <div className='col' >
                          <Input
                            title={item.title}
                            name={item.name}
                            value={values[ind]}
                            placeholder={item.placeholder}
                            setValue={(e) => this.setValues(e)}
                            regex={item.regex}
                            ids={item.ids}
                            info={item.info}
                            show={true}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className='mt-20'></div>
                <div className='row'>
                  <ButtonGroup
                    groupNames={["Search", "Reset"]}
                    idsearch={"loginHistory"}
                    idreset={"loginhistoryreset"}
                    submit={(e) => this.mySubmitFunction(e)}
                    reset={(e) => this.resetButton(e)}
                  />
                </div>
              </div>
              <div className='CMS-filter-result' id='result'>
                <div className='CMS-pagination'>
                  <Vpagination
                    data={this.props.userData.data}
                    totalRecords={this.props.userData.totalRecords}
                    paginationFirstValue={this.props.paginationFirstValue}
                    paginationSecondValue={this.props.paginationSecondValue}
                    setPaginationFirstValue={setPaginationFirstValue}
                    setPaginationSecondValue={setPaginationSecondValue}
                    recordsShow={this.props.recordsShow}
                    setRecordsShow={setRecordsShow}
                    isVisible={this.props.isVisible}
                    setIsVisible={setIsVisible}
                    csvData={this.state.csvData}
                    exportPDF={this.exportPDF}
                    callApi={this.callApi}
                    csvFileName={"Login History report"}
                    payloadData={this.state.payloadData}
                  />
                </div>

                <Table tableData={this.state.csvData} />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.loginHistorySlice.userData,
    paginationFirstValue: state.loginHistorySlice.paginationFirstValue,
    paginationSecondValue: state.loginHistorySlice.paginationSecondValue,
    recordsShow: state.loginHistorySlice.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.loginHistorySlice.isVisible,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginHistory);
