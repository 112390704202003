import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { riskManagement } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
export const riskManagementSlice = createSlice({
    name: "riskManagement",
    initialState: {
        sameIps: [],
        sameIpUsers: [],
        fraudIpMacAdress: [],
        fraudIpMacByUsers: [],
        syndicateBetting: [],
        playerBets: [],
        arbitageData: [],
        dupeCids: [],
        isPrevUserResp: "",
        disableBtnRiskMgt: false,
        userIpData: {},
        prevPayload: {},
        isUserIpVisible: false,
        userSameIpVisible: false,

        //PAGINATION USER WITH SAMEIP//
        paginationFirstValSameIp: 1,
        paginationSecondValSameIp: 25,
        sameIpIsVisible: false,
        sameIpRecordShow: [1, 25],
        userPageOneValue: 1,
        userPageSecondValue: 25,
        userIsVisible: false,
        userRecordShow: [1, 25],
        openDialouge: false,
        //PAGINATION USER WITH SAMEIP//

        //PAGINATION USER WITH SAME MACIP//
        fraudMacIpPageOneValue: 1,
        fraudMacIpPageSecondValue: 25,
        fraudMacIpIsvisible: false,
        fraudMacIpRecordShow: [1, 25],
        userMacIpPageOneValue: 1,
        userMacIpSecondValue: 25,
        userMacIpIsVisible: false,
        userMacIpShowRecord: [1, 25],
        //PAGINATION USER WITH SAME MACIP//

        //PAGINATION SYNDICATE BETTING//
        syndicatePageOneVal: 1,
        syndicatePageSecondVal: 25,
        syndicateIsVisible: false,
        syndicateShowRecord: [1, 25],
        userSyndicatePageOneVal: 1,
        userSyndicateSecondVal: 25,
        userSyndicateIsVisible: false,
        userSyndictaeShowRecord: [1, 25],
        syndicateData: {},
        syndicatePayload: {},
        isUserLoading: false,
        //PAGINATION SYNDICATE BETTING//

        //PAGINTION ARBITAGE BETTING//
        arbitagePageOneVal:1,
        arbitageSecondVal:25,
        arbitageIsVisible:false,
        arbitageRecordShow:[1,25],
        //PAGINATION ARBITAGE BETTING//

    },
    reducers: {
        setSameIps: (state, action) => {
            state.sameIps = action.payload
        },
        setSameIpUsers: (state, action) => {
            state.sameIpUsers = action.payload
        },
        setFraudIpMacAdress: (state, action) => {
            state.fraudIpMacAdress = action.payload
        },
        setFraudIpMacByUsers: (state, action) => {
            state.fraudIpMacByUsers = action.payload
        },
        setSyndicateBetting: (state, action) => {
            state.syndicateBetting = action.payload
        },
        setPlayerBets: (state, action) => {
            state.playerBets = action.payload
        },
        setArbitageData: (state, action) => {
            state.arbitageData = action.payload
        },
        setDupeCids: (state, action) => {
            state.dupeCids = action.payload
        },
        setIsPrevUserRespDupeCids: (state, action) => {
            state.isPrevUserResp = action.payload
        },
        setdisableBtnRiskMgt: (state, action) => {
            state.disableBtnRiskMgt = action.payload
        },
        setIsUserIpVisible: (state, action) => {
            state.isUserIpVisible = action.payload
        },
        setUserIpData: (state, action) => {
            state.userIpData = action.payload
        },
        setPrevPayload: (state, action) => {
            state.prevPayload = action.payload
        },
        setUserSameIpVisible: (state, action) => {
            state.userSameIpVisible = action.payload
        },


        //PAGINATION USER WITH SAMEIP//
        setPaginationFirstValSameIp: (state, action) => {
            state.paginationFirstValSameIp = action.payload
        },
        setPaginationSecondValSameIp: (state, action) => {
            state.paginationSecondValSameIp = action.payload
        },
        setSameIpIsVisible: (state, action) => {
            state.sameIpIsVisible = action.payload
        },
        setSameIpRecordShow: (state, action) => {
            state.sameIpRecordShow = action.payload
        },

        setUserPageOneValue: (state, action) => {
            state.userPageOneValue = action.payload
        },
        setUserPageSecondValue: (state, action) => {
            state.userPageSecondValue = action.payload
        },
        setUserIsVisible: (state, action) => {
            state.userIsVisible = action.payload
        },
        setUserRecordShow: (state, action) => {
            state.userRecordShow = action.payload
        },
        setOpenDialouge: (state, action) => {
            state.openDialouge = action.payload
        },
        //PAGINATION USER WITH SAMEIP//

        //PAGINATION USER WITH SAME MACIP//
        setFraudMacIpPageOneValue: (state, action) => {
            state.fraudMacIpPageOneValue = action.payload
        },
        setFraudMacIpPageSecondValue: (state, action) => {
            state.fraudMacIpPageSecondValue = action.payload
        },
        setFraudMacIpIsvisible: (state, action) => {
            state.fraudMacIpIsvisible = action.payload
        },
        setFraudMacIpRecordShow: (state, action) => {
            state.fraudMacIpRecordShow = action.payload
        },

        setUserMacIpPageOneValue: (state, action) => {
            state.userMacIpPageOneValue = action.payload
        },
        setUserMacIpSecondValue: (state, action) => {
            state.userMacIpSecondValue = action.payload
        },
        setUserMacIpIsVisible: (state, action) => {
            state.userMacIpIsVisible = action.payload
        },
        setUserMacIpShowRecord: (state, action) => {
            state.userMacIpShowRecord = action.payload
        },
        //PAGINATION USER WITH SAME MACIP//

        //PAGINATION SYNDICATE BETTING//
        setSyndicatePageOneVal: (state, action) => {
            state.syndicatePageOneVal = action.payload
        },
        setSyndicatePageSecondVal: (state, action) => {
            state.syndicatePageSecondVal = action.payload
        },
        setSyndicateIsVisible: (state, action) => {
            state.syndicateIsVisible = action.payload
        },
        setSyndicateShowRecord: (state, action) => {
            state.syndicateShowRecord = action.payload
        },

        setUserSyndicatePageOneVal: (state, action) => {
            state.userSyndicatePageOneVal = action.payload
        },
        setUserSyndicateSecondVal: (state, action) => {
            state.userSyndicateSecondVal = action.payload
        },
        setUserSyndicateIsVisible: (state, action) => {
            state.userSyndicateIsVisible = action.payload
        },
        setUserSyndictaeShowRecord: (state, action) => {
            state.userSyndictaeShowRecord = action.payload
        },
        setSyndicateData: (state, action) => {
            state.syndicateData = action.payload
        },
        setSyndicatePayload: (state, action) => {
            state.syndicatePayload = action.payload
        },
        setIsUserLoading: (state, action) => {
            state.isUserLoading = action.payload
        },

        //PAGINATION SYNDICATE BETTING//

        //PAGINATION ARBITAGE BETTING//
        setArbitagePageOneValue: (state, action) => {
            state.arbitagePageOneVal = action.payload
        },
        setArbitageSecondValue: (state, action) => {
            state.arbitageSecondVal = action.payload
        },
        setArbitageIsVisible: (state, action) => {
            state.arbitageIsVisible = action.payload
        },
        setArbitageShowRecord: (state, action) => {
            state.arbitageRecordShow = action.payload
        },
        //PAGINATION ARBITAGE BETTING//
    },
});

export const {
    setSameIps,
    setSameIpUsers,
    setFraudIpMacAdress,
    setFraudIpMacByUsers,
    setSyndicateBetting,
    setPlayerBets,
    setArbitageData,
    setDupeCids,
    setIsPrevUserRespDupeCids,
    setdisableBtnRiskMgt,
    setUserSameIpVisible,


    //PAGINATION USER WITH SAMEIP//
    setPaginationFirstValSameIp,
    setPaginationSecondValSameIp,
    setSameIpIsVisible,
    setSameIpRecordShow,
    setUserPageOneValue,
    setUserPageSecondValue,
    setUserIsVisible,
    setUserRecordShow,
    setOpenDialouge,
    setIsUserIpVisible,
    setUserIpData,
    setPrevPayload,
    //PAGINATION USER WITH SAMEIP//

    //PAGINATION USER WITH SAME MACIP//
    setFraudMacIpPageOneValue,
    setFraudMacIpPageSecondValue,
    setFraudMacIpIsvisible,
    setFraudMacIpRecordShow,
    setUserMacIpPageOneValue,
    setUserMacIpSecondValue,
    setUserMacIpIsVisible,
    setUserMacIpShowRecord,
    //PAGINATION USER WITH SAME MACIP//

    //PAGINATION SYNDICATE BETTING//
    setSyndicatePageOneVal,
    setSyndicatePageSecondVal,
    setSyndicateIsVisible,
    setSyndicateShowRecord,
    setUserSyndicatePageOneVal,
    setUserSyndicateSecondVal,
    setUserSyndicateIsVisible,
    setUserSyndictaeShowRecord,
    setSyndicateData,
    setSyndicatePayload,
    setIsUserLoading,
    //PAGINATION SYNDICATE BETTING//

    //PAGINATION ARBITAGE BETTING//
    setArbitagePageOneValue,
    setArbitageSecondValue,
    setArbitageIsVisible,
    setArbitageShowRecord,
    
    //PAGINATION ARBITAGE BETTING//


} = riskManagementSlice.actions;

export const getFraudScrubIPs = (pageNum, itemsPerPage, userName, params) => (dispatch) => {
    const payload = {
        // userName: params.userName,
        createdToDate: params.createdToDate,
        createdFromDate: params.createdFromDate
    }
    let url = userName ? riskManagement.getFraudScrubIPs + `?userName=${userName}&pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}` : riskManagement.getFraudScrubIPs + `?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`
    apiCaller(url, "POST", payload).then((res) => {
        dispatch(setSameIps(res.data));
        dispatch(setIsPrevUserRespDupeCids(userName))
        dispatch(setdisableBtnRiskMgt(true))
        dispatch(setUserSameIpVisible(true))
        dispatch(setIsUserIpVisible(true))
        if (res.data.data.length === 0) {
            toaster.success(messageEnhancer(res.msg));
        }

    }).catch((err) => {
        toaster.error(messageEnhancer(err.response));
        // const isResponse = err.response === "NO_FRAUD_IP_TO_LIST" ? userName : false;
        dispatch(setIsPrevUserRespDupeCids(""));
        dispatch(setdisableBtnRiskMgt(true))

    }
    )
}

export const getFraudIpUsers = (ip, pageNum, itemsPerPage) => (dispatch) => {
    let url = riskManagement.getFraudScrubUsersByIP(ip, pageNum, itemsPerPage)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setSameIpUsers(res.data))
        }
    })
}
export const getFraudIPAndMacAddress = (pageNum, itemsPerPage) => (dispatch) => {
    let url = riskManagement.getFraudIPAndMacAddress(pageNum, itemsPerPage)
    apiCaller(url, "POST").then((res) => {
        if (res.status === 200) {
            dispatch(setFraudIpMacAdress(res.data))
        }
    })
}

export const getFraudIPAndMacByUsers = (ip, macAddress, pageNum, itemsPerPage) => (dispatch) => {
    let url = riskManagement.getUsersByIPAndMacAddress(pageNum, itemsPerPage)
    const obj = {
        ip: ip,
        macAddress: macAddress
    }
    apiCaller(url, "POST", obj).then((res) => {
        if (res.status === 200) {
            dispatch(setFraudIpMacByUsers(res.data))
        }
    })
}

export const getSyndicateBettings = (pageNum, itemsPerPage, params) => (dispatch) => {
    // let url = userName ? riskManagement.getSyndicateBetting + `?userName=${userName}&pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}` : riskManagement.getSyndicateBetting + `?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`
    let url = riskManagement.getSyndicateBetting + `?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`;
    const payload = {
        createdToDate: params.createdToDate,
        createdFromDate: params.createdFromDate,
        userName: params.userName,
    }
    apiCaller(url, "POST", payload).then((res) => {
        if (res.status === 200) {
            dispatch(setSyndicateBetting(res.data))
            // dispatch(setIsPrevUserRespDupeCids(userName))
            dispatch(setdisableBtnRiskMgt(true))
            dispatch(setIsUserLoading(true))
            if (res.data.data.length === 0) {
                toaster.success(messageEnhancer(res.msg));
            }
        }
    }).catch((err) => {
        dispatch(setIsPrevUserRespDupeCids(""))
        dispatch(setdisableBtnRiskMgt(true))
    }
    )
}

export const getPlayerbets = (selectionId, selectionName, eventName, stake, pageNum, itemsPerPage) => (dispatch) => {
    let url = riskManagement.getPlayerbets(selectionId, selectionName, eventName, stake, pageNum, itemsPerPage)
    apiCaller(url, "GET").then((res) => {
        if (res.status === 200) {
            dispatch(setPlayerBets(res.data))
        }
    })
}
export const getArbitageBet = (pageNum,itemsPerPage,param) => (dispatch) => {
    let url =  riskManagement.arbitageBet(pageNum,itemsPerPage)
    apiCaller(url, "POST",param).then((res) => {
        if (res.status === 200) {
            dispatch(setArbitageData(res.data[0]));
            dispatch(setIsPrevUserRespDupeCids(param))
            dispatch(setdisableBtnRiskMgt(true))
        }
    }).catch((err) => {
        toaster.error(messageEnhancer(err.response))
        dispatch(setIsPrevUserRespDupeCids(""))
        dispatch(setdisableBtnRiskMgt(true))
    }
    )
}

export const getDupeCids = (countryCode, email, firstName, lastName, phnNumber) => (dispatch) => {
    let url = riskManagement.dupeCids
    const obj = {
        countryCode: countryCode,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phnNumber
    }
    apiCaller(url, "POST", obj).then((res) => {
        if (res.status === 200) {
            dispatch(setDupeCids(res.data))
        }
    })
}

export default riskManagementSlice.reducer;