import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
  userApi,
  roleDeleteApi,
  getAssignRole,
} from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
export const createRoleSlice = createSlice({
  name: "createRole",
  initialState: {
    permissionData: [],
    roleList: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    docsData: [],
    brandIdData: { brandName: "", brandDetails: { brandId: "", clientId: "" }, error: false },
    roleData: { value: "", error: false, roleExistErr: false },
    prePermissionData: { value: [], names: [], error: false },
  },
  reducers: {
    setPermissionData: (state, action) => {
      state.permissionData = action.payload;
    },
    setRoleList: (state, action) => {
      state.roleList = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setBrandIdData: (state, action) => {
      state.brandIdData = action.payload;
    },
    setRoleData: (state, action) => {
      state.roleData = action.payload;
    },
    setPrePermissionData: (state, action) => {
      state.prePermissionData = action.payload;
    },
  },
});
export const {
  setPermissionData,
  setRoleList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setDocsData,
  setBrandIdData,
  setRoleData,
  setPrePermissionData
} = createRoleSlice.actions;

export const createPermission = (params) => (dispatch) => {
  var obj = {
    brandId: params.brandId,
    permissionId: params.permissionId,
    roleName: params.roleName,
    clientId: params.clientId,
  };
  var url = `${userApi.createPermission}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response) {
        dispatch(setPermissionData(response.data));
        response.status == 200 && toaster.success("SUCCESS");
        [400, 401, 403, 404, 500].includes(response.status) &&
          toaster.error(messageEnhancer(response.msg));
      }
    })
    .catch((err) => toaster.error(messageEnhancer(err.response)));
};

export const getRoleList = (pageNumber, itemPerPage) => (dispatch) => {
  let url =
    userApi.getRoleList +
    `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  apiCaller(url, "GET").then((response) => {
    if (response && response.data) {
      dispatch(setRoleList(response.data));
      dispatch(setIsVisible(true));
    }
  }).catch((err) => {
    dispatch(setRoleList([]));
    dispatch(setIsVisible(true));
  });
};

export const deleteRole = (item) => (dispatch) => {
  let url = `${roleDeleteApi.getRoleDelete}`;
  const data = {
    roleId: item.clientId,
    userId: item.id,
  };
  apiCaller(url, "POST", data).then((response) => {
    if (response && response.data) {
      toaster.success(response.data);
    }
  });
};

export const editRole = (item) => (dispatch, getState) => {
  let url = userApi.editRole(item.id);
  const data = {
    brandId: item.brandId,
    clientId: item.clientId,
    permissionId: item.permissionId,
    roleName: item.roleName,
  };

  const { createRole } = getState();
  const page = createRole.paginationFirstValue - 1;
  const record = createRole.paginationSecondValue
  apiCaller(url, "PUT", data)
    .then((response) => {
      if (response && response.data) {
        response.status == 200 && toaster.success("SUCCESS");
        dispatch(getRoleList(page, record));
        [400, 401, 403, 404, 500].includes(response.status) &&
          toaster.error("FAILED");
      }
    })
    .catch((err) => toaster.error("FAILED"));
};

export default createRoleSlice.reducer;
