import React from 'react'

function SportsBox(props) {
    const { sports, isArray, title } = props;
    const values = isArray ? sports.flatMap(str => str.split(',')) : sports;
    return (
        <div className="CMS-box">
            <ul className="sports_list_box">
                {
                    values && Array.isArray(values) && values.length > 0 ?
                        values.map((sport, ind) => <li className="sports_list_item" key={`${sport}_${ind}`}>{sport}</li>)
                        : <li className="sports_list_item"> No {title || 'Sports'} Selected</li>
                }
            </ul>
        </div>
    )
}

export default SportsBox