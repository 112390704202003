import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
import { boostedOddsApis } from "../../sharedfiles/EndpointConfig";
export const BoostedOddsSlice = createSlice({
    name: "BoostedOddsSlice",
    initialState: {
        sportByBrand: [],
        countryBySport: [],
        leagueBySportCountry: [],
        eventBySportCountryLeague: [],
        marketByEvent: [],
        subMarket: [],
        outComes: [],
        oddsByOutcome: [],
        allBoostedOdds: [],
        viewBoostedOddUser: [],

        paginationFirstValue: 1,
        paginationSecondValue: 25,
        isVisible: false,
        recordToShow: [1, 25],
        totalRecord: ''
    },
    reducers: {
        setSportByBrand: (state, action) => {
            state.sportByBrand = action.payload
        },
        setCountryBySport: (state, action) => {
            state.countryBySport = action.payload
        },
        setLeagueBySportCountry: (state, action) => {
            state.leagueBySportCountry = action.payload
        },
        setEventBySportCountryLeague: (state, action) => {
            state.eventBySportCountryLeague = action.payload
        },
        setMarketByEvent: (state, action) => {
            state.marketByEvent = action.payload
        },
        setSubMarket: (state, action) => {
            state.subMarket = action.payload
        },
        setOutComes: (state, action) => {
            state.outComes = action.payload
        },
        setOddsByOutcome: (state, action) => {
            state.oddsByOutcome = action.payload
        },
        setAllBoostedOdds: (state, action) => {
            state.allBoostedOdds = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload
        },
        setRecordToShow: (state, action) => {
            state.recordToShow = action.payload
        },
        setTotalRecord: (state, action) => {
            state.totalRecord = action.payload
        },
        setViewBoostedOddUser: (state, action) => {
            state.viewBoostedOddUser = action.payload
        },
    }
})

export const {
    setSportByBrand,
    setCountryBySport,
    setLeagueBySportCountry,
    setEventBySportCountryLeague,
    setMarketByEvent,
    setSubMarket,
    setOutComes,
    setOddsByOutcome,
    setAllBoostedOdds,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setIsVisible,
    setRecordToShow,
    setTotalRecord,
    setViewBoostedOddUser

} = BoostedOddsSlice.actions

//DDL APIS //
export const getAllSportsByBrand = () => (dispatch) => {
    let url = boostedOddsApis.getAllSportsByBrand
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setSportByBrand(res.data))
        }
    })
}
export const getAllCountriesBySport = (sportsId) => (dispatch) => {
    let url = boostedOddsApis.getAllCountriesBySport(sportsId)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setCountryBySport(res.data))
        }
    })
}

export const getLeaguesBysportAndCountry = (sportId, countryId) => (dispatch) => {
    let url = boostedOddsApis.getLeaguesBysportAndCountry(sportId, countryId)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setLeagueBySportCountry(res.data))
        }
    })
}

export const getEventsBySportCountryLeague = (sport, country, league) => (dispatch) => {
    let url = boostedOddsApis.getEventsBySportCountryLeague(sport, country, league)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setEventBySportCountryLeague(res.data))
        }
    })
}

export const getAllMarketsByEvent = (eventId) => (dispatch) => {
    let url = boostedOddsApis.getAllMarketsByEvent(eventId)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setMarketByEvent(res.data))
        }
    })
}

export const getSubMarketByMarketEvent = (event, market) => (dispatch) => {
    let url = boostedOddsApis.getSubMarketByMarketEvent(event, market)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setSubMarket(res.data))
        }
    })
}

export const getOutcomesByMarketOrSubmarket = (event, market, subMarket) => (dispatch) => {
    let url = subMarket ? boostedOddsApis.getOutcomesByMarketOrSubmarket(event, market) + `&subMarketId=${subMarket}`
        : boostedOddsApis.getOutcomesByMarketOrSubmarket(event, market)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setOutComes(res.data))
        }
    })
}

export const getOddsByOutcomeId = (event, market, outcome, subMarket) => (dispatch) => {
    let url = subMarket ? boostedOddsApis.getOddsByOutcomeId(event, market) + `&subMarketId=${subMarket}&outComeId=${outcome}`
        : boostedOddsApis.getOddsByOutcomeId(event, market) + `&outComeId=${outcome}`
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setOddsByOutcome(res.data))
        }
    })
}
//DDL APIS //

//CREATE UPDATE APIS//
export const createBoostedOdds = (params) => (dispatch) => {
    let url = boostedOddsApis.createBoostedOdds
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            toaster.success("SUCCESS")
            dispatch(boostedOddsSearchApi(null, 0, 25))
        }
    }).catch(() => toaster.error("FAILED"))
}

export const getAllBoostedOdds = (pageNum, itemsPerPage) => (dispatch) => {
    let url = boostedOddsApis.getAllBoostedOdds(pageNum, itemsPerPage)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setAllBoostedOdds(res.data))
        }
    })
}

export const updateBoostedOddsStatus = (params) => (dispatch) => {
    const url = boostedOddsApis.updateBoostedOddsStatus
    const obj = {
        id: params.id,
        status: params.status,
    };
    apiCaller(url, "PUT", obj)
        .then((res) => {
            toaster.success("SUCCESS")
            dispatch(boostedOddsSearchApi(null, 0, 25))
        })
        .catch((err) => toaster.error("FAILED"));
};

export const boostedOddsSearchApi = (param, pageNum, itemsPerPage) => (dispatch) => {
    let url = param ? boostedOddsApis.boostedOddsSearchApi + `?searchParam=${param}&pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`
        : boostedOddsApis.boostedOddsSearchApi + `?pageNumber=${pageNum}&itemsPerPage=${itemsPerPage}`
    apiCaller(url, "POST", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setAllBoostedOdds(res.data))
            dispatch(setTotalRecord(res.totalRecords))
        }
    })
}

export const viewBoostedOddsUsers = (id) => (dispatch) => {
    let url = boostedOddsApis.viewBoostedOddsUsers(id)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setViewBoostedOddUser(res.data))
        }
    })
}

//CREATE UPDATE APIS//
export default BoostedOddsSlice.reducer