import React, { useEffect, useState } from "react";
import { Dialog, IconButton, DialogTitle } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  setErrorMessage,
  setSucessMessage,
  updatePendingWithdraw,
  fetchPendingWithrawalData,
} from "../PendingWithrawals/pendingWithrawalSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  updatePendingDeposit,
  fetchPendingDepositData,
} from "../PendingDeposits/pendingDepositSlice";

const ConfirmPaymentModal = (props) => {

  const dispatch = useDispatch();
  const [toastDisplay, setToastDisplay] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  // const errorFunction = () => {
  //   toast.error(props.errorMessage);
  //   dispatch(setErrorMessage(""));
  // };
  // const successFunction = () => {
  //   toast.success(props.sucessMessage);
  //   dispatch(setSucessMessage(""));
  // };
  const callDeclineFun = () => {
    let params = {
      amount: props?.data?.amount,
      message: props?.message,
      status: props?.status,
      id: props?.data?.id,
    };
    dispatch(updatePendingDeposit(params));
  };

  // const updateWithDrawl = () => {
  //   let params = {
  //     id: props.data.id,
  //     userId: props.data.userId,
  //     comment: props.message,
  //     status: props.status,
  //     // status: statusCode != "decline" ? "APPROVED" : "DECLINED",
  //   };
  //   if (props.data !== null && props.type === "withrawal") {
  //     console.log("withrawal");
  //     dispatch(updatePendingWithdraw(params));
  //   }
  // };
  const callWithdrawalsTable = () => {
    setTimeout(() => {
      dispatch(fetchPendingWithrawalData(0, 25));
    }, 500);
  };

  const toastDisplayFun = (e) => {
    if (props.paymentType == "Accept" && props.type == "withrawal") {
      //dev.platform-ups.sportsit-tech.net:443/player/withdraw/1000019
      const params = {
        id: props.data.id,
        message: props.message,
        status: "APPROVED",
        userId: props.data.userId,
      };
      dispatch(updatePendingWithdraw(params));
      callWithdrawalsTable();
    }
    if (props.paymentType == "Decline" && props.type == "withrawal") {
      const params = {
        id: props.data.id,
        message: props.message,
        status: "DECLINED",
        userId: props.data.userId,
      };
      dispatch(updatePendingWithdraw(params));
      callWithdrawalsTable();
    }
    setStatusCode("decline");
    props.type == "deposit" && callDeclineFun();
    // props.type == "withrawal" && updateWithDrawl();
    // setTimeout(() => {
    // }, 300);
    setTimeout(() => {
      props.type == "deposit" &&
        dispatch(fetchPendingDepositData(0, 25, "INIT")); // duspatch(setToastDisplay(false));
    }, 500);
    props.setSubmitForm && props.setSubmitForm(true);
    props.handlePopUpClose(props.popUp);

    if (props.type === "pendingDeposit") {
      props.handleRemoveRecord();
    }
    // if (props.updateRecord) {
    //   props.updateRecord(e);
    // }
  };



  return (
    <>
      <Dialog
        open={props.popUp}
        style={{ zIndex: 99999 }}
        onClose={() => props.handlePopUpClose(props.popUp)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
          }}
        >
          <DialogTitle>{props.modalTitle}</DialogTitle>
          <IconButton
            style={{ color: "white" }}
            key='close'
            arial-label='close'
            onClick={() => props.handlePopUpClose(props.popUp)}
          >
            x
          </IconButton>
        </div>
        <div className='CMS-msgBox'>
          <div className='CMS-msgBox-container'>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-btnContainer d-flex justify-content-center'>
                <button
                  onClick={(e) => toastDisplayFun(e)}
                  className='CMS-btnStatus CMS-statusActive CMS-btnSecondary CMS-btnSmall pointer'
                  type='button'
                >
                  Yes

                </button>

                <div>
                  {/* {props.errorMessage && errorFunction()}
                  {props.sucessMessage && successFunction()} */}
                </div>
                <button
                  onClick={() => props.handlePopUpClose(props.popUp)}
                  className='CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer'
                  type='button'
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

function mapStateToProps(state) {
  return {
    errorMessage: state.pendingWithrawal.errorMessage,
    sucessMessage: state.pendingWithrawal.sucessMessage,
  };
}

export default connect(mapStateToProps)(ConfirmPaymentModal);
