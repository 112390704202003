import { createSlice } from "@reduxjs/toolkit";
import { reportApi } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import { setLoader } from "../AppSlice";
import { getUTCDate, toaster } from "../../sharedfiles/helper";
export const reportSlice = createSlice({
  name: "reports",
  initialState: {
    displayValue: false,
    reportsPDFData: {},
    sportsReportData: {},
    customerReportData: {},
    paymentsReportsData: {},
    manualReportsData: {},
    activeReportsData: {},
    individualReportsData: {},
    multipleReportsData: {},
    viewTable: false,
    multipleRPTable: false,
    tableShouldView: [],
    sportsDates: {
      dateFrom: "",
      dateTo: "",
    },
    customerDates: {
      dateFrom: "",
      dateTo: "",
    },
    paymentDates: {
      dateFrom: "",
      dateTo: "",
    },
    manualDates: {
      dateFrom: "",
      dateTo: "",
    },
    activeDates: {
      dateFrom: "",
      dateTo: "",
    },
    individualDates: {
      dateFrom: "",
      dateTo: "",
    },
    multipleDepositData: {
      depositType: "",
      startDate: "",
      endDate: "",
      oddThreshold: "",
      minDeposit: "",
    },
    referFriendDates: {
      dateFrom: "",
      dateTo: "",
    },
    withdrawDates: {
      dateFrom: "",
      dateTo: "",
    },
    depositDates: {
      dateFrom: "",
      dateTo: "",
    },
    placeBetDates: {
      dateFrom: "",
      dateTo: "",
    },
    eventLevelDates: {
      dateFrom: "",
      dateTo: "",
    },
    marketLevelDates: {
      dateFrom: "",
      dateTo: "",
    },

    reports: [
      { label: "Sports Overview Report", path: "/report/sports" },
      { label: "Customer Overview Report", path: "/report/customer", },
      { label: "Payment Overview Report", path: "/report/payment", },
      { label: "Manual Adjustment Report", path: "/report/manual", },
      { label: "Multiple Deposit Reports", path: "/report/deposit", },
      // { label: "Refer a Friend Report", path: "/report/referfriend", },
      { label: "Withdrawal Reports", path: "/report/withdrawalreport", },
      { label: "Deposit Reports", path: "/report/depositreport", },
      { label: "Placebet Reports", path: "/report/placebetreport", },
      { label: "Liability Reports", path: "/report/liabilityreport", },
    ],
    reportPage: {},
    depositReport: [],
    referFriendData: [],
    withdrawalData: [],
    depositData: [],
    placeBetData: [],
    eventLevelData: [],
    marketLevelData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    visible: false,
    disableBtn: false,
  },
  reducers: {
    setdisplayValue: (state, action) => {
      state.displayValue = action.payload;
    },
    setReports: (state, action, type) => {
      state.reportsPDFData = action.payload;
    },
    setSportsReportData: (state, action) => {
      state.sportsReportData = action.payload;
    },
    setCustomerReportData: (state, action) => {
      state.customerReportData = action.payload;
    },
    setPaymentsReportsData: (state, action) => {
      state.paymentsReportsData = action.payload;
    },
    setManualReportsData: (state, action) => {
      state.manualReportsData = action.payload;
    },
    setActiveReportsData: (state, action) => {
      state.activeReportsData = action.payload;
    },
    setIndividualReportsData: (state, action) => {
      state.individualReportsData = action.payload;
    },
    setMultipleReportsData: (state, action) => {
      state.multipleReportsData = action.payload;
    },
    setReportPage: (state, action) => {
      state.reportPage = action.payload;
    },
    setTableView: (state, action) => {
      state.viewTable = action.payload;
    },
    setDepositReport: (state, action) => {
      state.depositReport = action.payload;
    },
    setSportsDates: (state, action) => {
      state.sportsDates = action.payload;
    },
    setCustomerDates: (state, action) => {
      state.customerDates = action.payload;
    },
    setPaymentDates: (state, action) => {
      state.paymentDates = action.payload;
    },
    setManualDates: (state, action) => {
      state.manualDates = action.payload;
    },
    setActiveDates: (state, action) => {
      state.activeDates = action.payload;
    },
    setIndividualDates: (state, action) => {
      state.individualDates = action.payload;
    },
    setMultipleDepositData: (state, action) => {
      state.multipleDepositData = action.payload;
    },
    setReferFriendDates: (state, action) => {
      state.referFriendDates = action.payload;
    },
    setWithdrawDates: (state, action) => {
      state.withdrawDates = action.payload;
    },
    setDepositDates: (state, action) => {
      state.depositDates = action.payload;
    },
    setPlaceBetDates: (state, action) => {
      state.placeBetDates = action.payload;
    },
    setEventLevelDates: (state, action) => {
      state.eventLevelDates = action.payload;
    },
    setMarketLevelDates: (state, action) => {
      state.marketLevelDates = action.payload;
    },
    setMultipleRPTable: (state, action) => {
      state.multipleRPTable = action.payload;
    },
    setTableShouldView: (state, action) => {
      state.tableShouldView = action.payload;
    },
    setReferFriendData: (state, action) => {
      state.referFriendData = action.payload;
    },
    setWithdrawalData: (state, action) => {
      state.withdrawalData = action.payload;
    },
    setDepositData: (state, action) => {
      state.depositData = action.payload;
    },
    setPlaceBetData: (state, action) => {
      state.placeBetData = action.payload;
    },
    setEventLevelData: (state, action) => {
      state.eventLevelData = action.payload;
    },
    setMarketLevelData: (state, action) => {
      state.marketLevelData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setDisableBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
  },
});

export const {
  setdisplayValue,
  setReports,
  setSportsReportData,
  setCustomerReportData,
  setPaymentsReportsData,
  setManualReportsData,
  setActiveReportsData,
  setIndividualReportsData,
  setMultipleReportsData,
  setReportPage,
  setTableView,
  setDepositReport,
  setSportsDates,
  setCustomerDates,
  setPaymentDates,
  setManualDates,
  setActiveDates,
  setIndividualDates,
  setMultipleDepositData,
  setReferFriendDates,
  setWithdrawDates,
  setDepositDates,
  setPlaceBetDates,
  setEventLevelDates,
  setMarketLevelDates,
  setMultipleRPTable,
  setTableShouldView,
  setReferFriendData,
  setWithdrawalData,
  setDepositData,
  setPlaceBetData,
  setEventLevelData,
  setMarketLevelData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setVisible,
  setDisableBtn
} = reportSlice.actions;

export const handleState = (prevState, value, type) => {
  let data = [];
  if (type === "ADD") {
    data = [...prevState, value];
  } else {
    data = prevState.filter((val) => val !== type);
  }
  return [...new Set(data)]
};

export const fetchReportData = (params, tableShouldView) => (dispatch) => {
  var obj = {
    from: getUTCDate(params.from, "start"),
    reportType: params.reportType,
    to: getUTCDate(params.to, "end"),
  };
  const key = obj?.reportType;
  var url = `${reportApi.getReport}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      if (response !== null && response !== undefined && response) {
        dispatch(setTableView(false));
        if (response.status == 200) {
          switch (key) {
            case "SPORT":
              dispatch(setSportsReportData(response.data));
              break;
            case "CUSTOMER":
              dispatch(setCustomerReportData(response.data));
              break;
            case "PAYMENT":
              dispatch(setPaymentsReportsData(response.data));
              break;
            case "MANULAPAYMENT":
              dispatch(setManualReportsData(response.data));
              break;
            case "ACTIVE":
              dispatch(setActiveReportsData(response.data));
              break;
            case "INDIVIDUAL":
              dispatch(setIndividualReportsData(response.data));
              break;
            default:
              return;
          }
        }
        const updated = handleState(tableShouldView, key, "ADD");
        dispatch(setTableShouldView(updated));
        setTimeout(() => dispatch(setTableView(true)), 300);
      }
    })
    .catch((err) => {
      if (err?.statusCode) {
        if ([400, 500].includes(err?.statusCode)) {
          switch (key) {
            case "SPORT":
              dispatch(setSportsReportData([]));
              break;
            case "CUSTOMER":
              dispatch(setCustomerReportData([]));
              break;
            case "PAYMENT":
              dispatch(setPaymentsReportsData([]));
              break;
            case "MANUAL":
              dispatch(setManualReportsData([]));
              break;
            case "ACTIVE":
              dispatch(setActiveReportsData([]));
              break;
            case "INDIVIDUAL":
              dispatch(setIndividualReportsData([]));
              break;
            default:
              return;
          }
        }
        const updated = handleState(tableShouldView, key, "ADD");
        dispatch(setTableShouldView(updated));
        setTimeout(() => dispatch(setTableView(true)), 300);
      }
    });
};

export const getDepositReport = (param, tableShouldView, keyCheck) => (dispatch) => {
  dispatch(setLoader(true));
  dispatch(setDepositReport([]));
  let obj = {
    depositType: param.depositType,
    endDate: getUTCDate(param.endDate, "end"),
    minimumDepositAmount: param.minDeposit,
    oddsThreshold: param.oddThroshold,
    startDate: getUTCDate(param.startDate, "start"),
  };

  let url = reportApi.geMultipleDepositReport;
  apiCaller(url, "POST", obj)
    .then((response) => {
      dispatch(setTableView(false));
      if (response && response != null) {
        dispatch(setLoader(false));
        dispatch(setDepositReport(response.data));
        toaster.success("SUCCESS");
        dispatch(setDisableBtn(true));
        dispatch(setMultipleRPTable(true));
        const updated = handleState(tableShouldView, keyCheck, "ADD");
        dispatch(setTableShouldView(updated));
        setTimeout(() => dispatch(setTableView(true)), 300);
      }
    })
    .catch((err) => {
      dispatch(setDisableBtn(true));
      dispatch(setDepositReport([]));
      toaster.error("FAILED");
      dispatch(setMultipleRPTable(true));
      const updated = handleState(tableShouldView, keyCheck, "ADD");
      dispatch(setTableShouldView(updated));
      setTimeout(() => dispatch(setTableView(true)), 300);
    });
};


export const getRefferFriendReports = (payload, tableShouldView) => (dispatch, getState) => {
  const url = reportApi.referFriend;
  const requestPayload = {
    startDate: getUTCDate(payload.from, "start"),
    endDate: getUTCDate(payload.to, "end")
  }
  const { reports } = getState();

  apiCaller(url, "POST", requestPayload).then((res) => {
    dispatch(setReferFriendData(res.data));
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}

export const getWithdrawTransactionReorts = (payload, pageNum, itemPerPage) => (dispatch, getState) => {
  var url = `${reportApi.getWithdrawReport}` + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemPerPage;
  const { reports } = getState();
  const data = {
    startDate: getUTCDate(payload.from, "start"),
    endDate: getUTCDate(payload.to, "end")
  }
  apiCaller(url, "POST", data).then((res) => {
    dispatch(setWithdrawalData(res.data));
    dispatch(setVisible(true))

    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}

export const getDepositTransactionReport = (payload, pageNum, itemPerPage) => (dispatch, getState) => {
  var url = `${reportApi.getDepositReport}` + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemPerPage;
  const { reports } = getState();
  const data = {
    startDate: getUTCDate(payload.from, "start"),
    endDate: getUTCDate(payload.to, "end")
  }
  apiCaller(url, "POST", data).then((res) => {
    dispatch(setDepositData(res.data));
    dispatch(setVisible(true))

    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}

export const getPlaceBetReport = (payload, pageNum, itemPerPage) => (dispatch, getState) => {
  var url = `${reportApi.getPlaceBetReport}` + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemPerPage;
  const { reports } = getState();
  const data = {
    startDate: getUTCDate(payload.from, "start"),
    endDate: getUTCDate(payload.to, "end")
  }
  apiCaller(url, "POST", data).then((res) => {
    dispatch(setPlaceBetData(res.data));
    dispatch(setVisible(true))

    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}

export const getLiabiltyEventReport = (payload, pageNum, itemPerPage) => (dispatch, getState) => {
  var url = `${reportApi.liabiltyEventReport}` + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemPerPage;
  const { reports } = getState();
  const data = {
    from: getUTCDate(payload.from, "start"),
    to: getUTCDate(payload.to, "end"),
  }
  apiCaller(url, "POST", data).then((res) => {
    dispatch(setEventLevelData(res.data));
    dispatch(setVisible(true))

    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}

export const getLiabiltyMarketReport = (payload, pageNum, itemPerPage) => (dispatch, getState) => {
  var url = `${reportApi.liabiltyMarketReport}` + "?pageNumber=" + pageNum + "&itemsPerPage=" + itemPerPage;
  const { reports } = getState();
  const data = {
    from: getUTCDate(payload.from, "start"),
    to: getUTCDate(payload.to, "end"),
  }
  apiCaller(url, "POST", data).then((res) => {
    dispatch(setMarketLevelData(res.data));
    dispatch(setVisible(true))

    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    setTimeout(() => dispatch(setTableView(true)), 300);
    dispatch(setTableView(true));
  }).catch((err) => {
    const updated = handleState(reports.tableShouldView, payload.reportType, "ADD");
    dispatch(setTableShouldView(updated));
    dispatch(setTableView(true));
  })
}
export default reportSlice.reducer;
