import { createSlice } from "@reduxjs/toolkit";

export const bireportSlice = createSlice({
    name: "bireportSlice",
    initialState: {
        biReportData: [],
        vipBiReportData: []
    },
    reducers: {
        setBiReportData: (state, action) => {
            state.biReportData = action.payload;
        },
        setVipBiReportData: (state, action) => {
            state.vipBiReportData = action.payload;
        },
    }
})

export const { setBiReportData, setVipBiReportData } = bireportSlice.actions

export default bireportSlice.reducer;
