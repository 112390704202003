import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userWatchListApis } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const UserWatchListSlice = createSlice({
    name: "UserWatchListSlice",
    initialState: {
        watchListUsers: [],
        watchListFirstPage: 1,
        watchListSecondPage: 25,
        watchLIstIsVisible: false,
        watchListRecord: [1, 25],

        commentHistoryData: [],
        historyFirstPage: 1,
        historySecondPage: 25,
        historyRecordShow: [1, 25],
        historyIsVisible: false,

        userActivity: [],
        activityFirstPage: 1,
        activitySecondPage: 25,
        activityRecordShow: [1, 25],
        activityIsVisible: false,
        prevFormData:{},
        docsData: [],
        pdfData: [],
    },
    reducers: {
        setWatchListUsers: (state, action) => {
            state.watchListUsers = action.payload
        },
        setWatchListFirstPage: (state, action) => {
            state.watchListFirstPage = action.payload
        },
        setWatchListSecondPage: (state, action) => {
            state.watchListSecondPage = action.payload
        },
        setWatchListIsVisible: (state, action) => {
            state.watchLIstIsVisible = action.payload
        },
        setWatchListRecord: (state, action) => {
            state.watchListRecord = action.payload
        },

        setCommentHistoryData: (state, action) => {
            state.commentHistoryData = action.payload
        },
        setHistoryFirstPage: (state, action) => {
            state.historyFirstPage = action.payload
        },
        setHistorySecondPage: (state, action) => {
            state.historySecondPage = action.payload
        },
        setHistoryRecordShow: (state, action) => {
            state.historyRecordShow = action.payload
        },
        setHistoryIsVisible: (state, action) => {
            state.historyIsVisible = action.payload
        },


        setUserActivity: (state, action) => {
            state.userActivity = action.payload
        },
        setActivityFirstPage: (state, action) => {
            state.activityFirstPage = action.payload
        },
        setActivitySecondPage: (state, action) => {
            state.activitySecondPage = action.payload
        },
        setActivityRecordShow: (state, action) => {
            state.activityRecordShow = action.payload
        },
        setActivityIsVisible: (state, action) => {
            state.activityIsVisible = action.payload
        },
        setPrevFormData: (state, action) => {
            state.prevFormData = action.payload;
        },
        setDocsData: (state, action) => {
            state.docsData = action.payload;
        },
        setPdfData: (state, action) => {
            state.pdfData = action.payload;
        },
    }
})

export const {
    setWatchListUsers,
    setWatchListFirstPage,
    setWatchListSecondPage,
    setWatchListIsVisible,
    setWatchListRecord,

    setCommentHistoryData,
    setHistoryFirstPage,
    setHistorySecondPage,
    setHistoryRecordShow,
    setHistoryIsVisible,

    setUserActivity,
    setActivityFirstPage,
    setActivitySecondPage,
    setActivityRecordShow,
    setActivityIsVisible,
    setPrevFormData,
    setDocsData,
    setPdfData,

} = UserWatchListSlice.actions

export const getUserWatchList = (params, pageNum, itemsPerPage) => (dispatch) => {
    let url = userWatchListApis.getUserWatchList(pageNum, itemsPerPage)
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            dispatch(setWatchListUsers(res.data))
        }
    })
} // sidebar watchlist

export const addToUserWatchList = (params) => (dispatch) => {
    let url = userWatchListApis.addToUserWatchList
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            toaster.success(messageEnhancer(res.msg))
        }
    })
} //individual player watchlist 

export const removeUserWatchList = (userId, comments) => (dispatch) => {
    let url = userWatchListApis.removeUserWatchList(userId)
    apiCaller(url, "PUT", comments).then((res) => {
        toaster.success(messageEnhancer(res.msg))
        dispatch(getUserWatchList({ isWatched: true }, 0, 25))
    })
}
export const getCommentHistory = (userId, pageNum, itemsPerPag) => (dispatch) => {
    let url = userWatchListApis.getCommentHistory(userId, pageNum, itemsPerPag)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setCommentHistoryData(res.data))
        }
    })
}
export const getWatchUsersActivity = (params, pageNum, itemsPerPag) => (dispatch) => {
    let url = userWatchListApis.getWatchUsersActivity(pageNum, itemsPerPag)
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            dispatch(setUserActivity(res.data))
        }
    })
}


export default UserWatchListSlice.reducer;