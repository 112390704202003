import React, { useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { generateKey, getDateTimeFormate } from "../../sharedfiles/helper";
import { getAllUsers, getUserDetails } from "./createUserSlice";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import EditUser from "../Common/Dialog/EditUser";
import { COMMON_CONSTANTS, MANUALDIALOG_CONSTANTS, REPORTS_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { CreateUserInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";

const CreateUserTable = (props) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [editUserBox, setEditUserBox] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const userRolesList = useSelector((state) => state.createuserSlice.userRolesList);

  const textWrap = {
    whiteSpace: "nowrap",
  };

  const handleEditUser = (item) => {
    const brandId = localStorage.getItem("brandId")
    dispatch(getUserDetails(item.userId));
    dispatch(getAllUsers(brandId));
    setUserId(item.userId);
    setSelectedUser(item);
    setEditUserBox((prev) => !prev);
  }

  const headers = [
    "User Id",
    "User Name ",
    "First Name",
    "Last Name",
    "Created Date",
    "Updated Date",
    "Created By",
    "Updated By",
    "Role",
    "Btag",
    "Edit User"
  ]


  return (
    <>
      {
        editUserBox &&
        <Dialog__Main
          title={"Edit User Details"}
          dOpen={editUserBox}
          dClose={setEditUserBox}
          width={"sm"}
          dHeight={"280px"}
          ids={"editUserBox"}
        >
          <EditUser
            open={editUserBox}
            close={setEditUserBox}
            userId={userId}
            userDetails={selectedUser}
            rolesList={userRolesList}
            rolesRequired={true}
            page={"createUser"}
          />
        </Dialog__Main>
      }
      <div className='CMS-box'>
        <div className='CMS-table CMS-table-triped'>
          <table>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{item}</p>
                      <Tooltips info={`${CreateUserInfo[index]}`} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.allUsersList &&
                Object.keys(props.allUsersList).length > 0 &&
                props.allUsersList?.data &&
                Array.isArray(props.allUsersList?.data) &&
                props.allUsersList?.data?.length > 0 ? (
                props.allUsersList?.data?.map((item, index) => {
                  return (
                    <tr key={generateKey(index)}>
                      <td>{item.userId}</td>
                      <td>{item.userName}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                      <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                      <td>{item.createdBy}</td>
                      <td>{item.updatedBy}</td>
                      <td>
                        {item.roles.map((value, index) => {
                          return value.roleName + `,`;
                        })}
                      </td>
                      <td>{item.btag}</td>
                      <td>
                        <button
                          className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                          onClick={() => handleEditUser(item)}
                          id='EditUserBtn'
                        >
                          {MANUALDIALOG_CONSTANTS.edit_user}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <No__Data__Found colspan={100} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rolesList: state.createuserSlice.rolesList,
    userRolesList: state.createuserSlice.userRolesList,
    userRole: state.loginSlice.userRole,
  };
};

export default connect(mapStateToProps)(CreateUserTable);
