import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import BankDetailtable from "./BankDetailtable";
import { useLocation } from "react-router-dom";
import {
  fetchBankDetails,
  addBankDetails,
  setIsVisible,
  clearDataFetched,
  setDisableBtn,
} from "./BankDetailSlice";
import { useDispatch } from "react-redux";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import { BankDetailsData, bankErrors } from "../Common/data/mapData";
import Skelton from "../Common/skelton/Skelton";
const BankDetails = (props) => {
  const isAccountVisible = useSelector((state) => state.dashboard.isAccountVisible);
  const [bankName, setBankName] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accntNo, setAccntNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [error, setError] = useState([]);
  const [masking, setMasking] = useState(false)
  const [disableBtn, setDisablebtn] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const id = props.userId ? props.userId : localStorage.getItem("userId");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accountHolderName") {
      setAccountHolder(value);
      setError(error.filter((item) => item !== name));
    }
    if (name === "bankName") {
      setBankName(value);
      setError(error.filter((item) => item !== name));
    }
    if (name === "branchName") {
      setBranchName(value);
      setError(error.filter((item) => item !== name));
    }
    if (name === "accountNumber") {
      setAccntNo(value);
      setError(error.filter((item) => item !== name));
    }
    if (name === "ifscCode") {
      setIfscCode(value);
      setError(error.filter((item) => item !== name));
    }
  };

  const submitDetails = (e) => {
    e.preventDefault();
    if (
      bankName !== "" &&
      branchName !== "" &&
      accntNo !== "" &&
      accountHolder !== ""
    ) {
      const bank = {
        accountHolderName: accountHolder,
        bankName: bankName,
        branchName: branchName,
        accntNo: accntNo,
        ifscCode: ifscCode,
      };
      dispatch(addBankDetails(bank, id));
      setDisablebtn(true)
    }
    else {
      setError(bankErrors)
    }
  };
  const resetDetails = () => {
    setBankName("");
    setBranchName("");
    setAccntNo("");
    setIfscCode("");
    setAccountHolder("");
    setError([]);
  };

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      dispatch(fetchBankDetails({ id: localStorage.getItem("userId") }));
      if (localStorage.getItem("isMasking") === "DATA_MASKING") {
        setMasking(true)
      } else {
        setMasking(false)
      }
    }
    return () => mounting = false;

  }, []);

  useEffect(() => {
    const userID = location.pathname.split("/")[2];
    if (props.userId !== userID) {
      dispatch(fetchBankDetails({ id: userID }));
      dispatch(setIsVisible(false));
    }
  }, [location]);

  useEffect(() => {
    if (props.dataFetched) {
      dispatch(clearDataFetched(false));
      resetDetails();
    }
  }, [props.dataFetched])

  useEffect(() => {
    if (props.disableBtn) {
      setTimeout(() => {
        dispatch(setDisableBtn(false))
        setDisablebtn(false)
      }, 3000)
    }
  }, [props.disableBtn])

  return (
    <>
      <div className="CMS-tabContent">
        <div className="CMS-box-content">
          <div className="CMS-box CMS-box-content">
            <div className="row">
              {BankDetailsData.map((item, ind) => {
                const values = [
                  accountHolder,
                  bankName,
                  branchName,
                  accntNo,
                  ifscCode,
                ];
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-3" >
                      <Input
                        title={item.title}
                        name={item.name}
                        ids={item.ids}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={handleChange}
                        errorMsg={error.includes(item.name) && item.errorMsg}
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="mt-20"></div>
            <div className="col-12">
              {/* <ButtonGroup
                groupNames={["Add Bank", "Reset"]}
                idsearch={"addBank"}
                idreset={"bankreset"}
                submit={(e) => submitDetails(e)}
                reset={(e) => resetDetails(e)}
              /> */}
              <div className="CMS-btnContainer">
                <button
                  id="addBank"
                  style={{ width: "200" }}
                  className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${disableBtn ? 'disable-button' : ''}`}
                  type="button"
                  onClick={(e) => submitDetails(e)}
                >
                  {"Add Bank"}
                </button>
                <button
                  id="bankreset"
                  onClick={(e) => resetDetails(e)}
                  className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
                  type='reset'
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {!props.isVisible ? (
            <Skelton />
          ) : (
            <BankDetailtable data={props.bankDetails} masking={isAccountVisible} />
          )}
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    bankDetails: state.bankDetails.bankData,
    userId: state.playersearch.userId,
    isVisible: state.bankDetails.isVisible,
    dataFetched: state.bankDetails.dataFetched,
    disableBtn: state.bankDetails.disableBtn,
    isAccountVisible: state.dashboard.isAccountVisible
  };
}

export default connect(mapStateToProps)(BankDetails);
