import { createSlice } from "@reduxjs/toolkit";

export const bidashboardSlice = createSlice({
    name: "bidashboardSlice",
    initialState: {
        biDashboardData: []
    },
    reducers: {
        setBiDashboardData: (state, action) => {
            state.biDashboardData = action.payload;
        },
    }
})

export const { setBiDashboardData } = bidashboardSlice.actions

export default bidashboardSlice.reducer;