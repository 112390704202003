import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
import { paymentTypes } from "../Common/data/Payments/PaymentsearchData";
export const PaymentSearchSlice = createSlice({
  name: "paymentSearchSlice",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    userData: [],
    recordsShow: [1, 25],
    viewTable: false,
    isVisible: false,
    formData: {},
    docsData: [],
    reportsData: [],
    reportDocs: {
      pdf: [],
      csv: []
    },
    reportsLoader: false,
    paymentProvider: [],
    paymentLogs: [],
    paymentsOption: paymentTypes,
  },
  reducers: {
    setPaymentSearch: (state, action) => {
      state.userData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setViewTable: (state, action) => {
      state.viewTable = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setReportsData: (state, action) => {
      state.reportsData = action.payload;
    },
    setReportDocs: (state, action) => {
      state.reportDocs = action.payload;
    },
    setReportsLoader: (state, action) => {
      state.reportsLoader = action.payload;
    },
    setPaymentProvider: (state, action) => {
      state.paymentProvider = action.payload;
    },
    setPaymentLogs: (state, action) => {
      state.paymentLogs = action.payload;
    },
    setBetPaymentsOption: (state, action) => {
      state.paymentsOption = action.payload;
    },
  },
});
export const {
  setPaymentSearch,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setErrorMessage,
  setRecordsShow,
  setViewTable,
  setIsVisible,
  setFormData,
  setDocsData,
  setReportsData,
  setReportDocs,
  setReportsLoader,
  setPaymentProvider,
  setPaymentLogs,
  setBetPaymentsOption
} = PaymentSearchSlice.actions;
export const getPaymentSearchtableList =
  (pagenumbervalue, itemperpagevalue, params, activeAll) =>
    (dispatch) => {
      const clean = (obj) => {
        for (var propName in obj) {
          if (
            obj[propName] == null ||
            obj[propName] === undefined ||
            obj[propName] === "" ||
            obj[propName] == "NaN-aN-aNT23:59:59.517Z" ||
            obj[propName] == "NaN-aN-aNT00:00:00.517Z"
          ) {
            delete obj[propName];
          }
        }
        return obj;
      };

      let obj = {};
      if (params) {
        obj = clean(params);
      }

      var url =
        `${paymentSearchApi.getPaymentSearch}` +
        "?pageNumber=" +
        pagenumbervalue +
        "&itemsPerPage=" +
        itemperpagevalue;
      apiCaller(url, "POST", obj)
        .then((response) => {
          if (activeAll) {
            // dispatch(setReportsData(response.data.data));
            // console.log("response.data.data: ", response.data.data);
            const data = response?.data?.data.map(item => {
              return {
                ...item, ["userId"]: `${item.userId}`
              }
            })
            dispatch(setReportsData(data));
            dispatch(setReportsLoader(false));
          }
          else {
            // dispatch(setPaymentSearch(response.data));
            const data = {
              ...response.data,
              ["data"]: response?.data?.data?.map(item => {
                return {
                  ...item, ["userId"]: `${item.userId}`
                }
              })
            }
            dispatch(setPaymentSearch(data));
            setTimeout(() => {
              dispatch(setViewTable(true));
            }, 100);
            dispatch(setIsVisible(true));
          }
          // dispatch(setErrorMessage(""));
        })
        .catch(
          (error) => dispatch(setErrorMessage(error.message)),
          // dispatch(setPaymentSearch({ data: [], totalRecords: 0 })),
          dispatch(setViewTable(true))
        );
    };

export const getPaymetProviders = () => (dispatch) => {
  let url = paymentSearchApi.paymentProviders
  apiCaller(url, "GET").then((res) => {
    if (res?.data?.length > 0) {
      dispatch(setPaymentProvider(res.data))
    }
  })
}

export const getPaymentLogs = (tnxId) => (dispatch) => {
  let url = paymentSearchApi.getEventsListById(tnxId)
  apiCaller(url, "GET").then((res) => {
    dispatch(setPaymentLogs(res.data))
  })
}

export const updatePaymentMethods = (param) => (dispatch) => {
  let url = paymentSearchApi.updatePaymnetProviderSts
  const obj = {
    isActive: param.isActive,
    paymentProvideId: param.paymentProvideId
  }
  apiCaller(url, "POST", obj).then((res) => {
    dispatch(setPaymentLogs(res.data))
    if (res.status === 200) {
      toaster.success("SUCCESS")
    }
    else {
      toaster.error(messageEnhancer(res.msg));
    }
  }).catch((err) => {
    const res = err.response
    console.log(res);
  })
}

export const cancelWithdrawal = (txnId, param) => (dispatch) => {
  let url = paymentSearchApi.cancelWithdrawa(txnId)
  apiCaller(url, "PUT", param).then((res) => {
    dispatch(setPaymentLogs(res.data))
    if (res.status === 200) {
      toaster.success(messageEnhancer(res.msg))
    }
  }).catch((err) => {
    const res = err.response
    console.log(res);
    toaster.error(messageEnhancer(res));
  })
}
export default PaymentSearchSlice.reducer;
