import React from "react";
import { useSelector } from "react-redux";
import ActivePlayerTable from "../ActivePlayerTable";
import { setActiveDates } from "../reportSlice";
import ReportsWrapper from "../ReportsWrapper";

function ActivePlayerReport(props) {
  const { activeReportsData, activeDates } = useSelector((st) => st.reports);
  return (
    <React.Fragment>
      <ReportsWrapper
        reportTitle={"ACTIVE PLAYERS"}
        currentTab={"ACTIVE PLAYERS"}
        pageKey={"ACTIVE"}
        setDateToStore={setActiveDates}
        persistDate={activeDates}
        sportData={activeReportsData}
        ReportTable={() => (
          <ActivePlayerTable
            dates={activeDates}
            sportData={activeReportsData}
            reportPage={"active players report"}
          />
        )}
      />
    </React.Fragment>
  );
}

export default ActivePlayerReport;
