import React from "react";
import ReportsWrapper from "../ReportsWrapper";
import CustomerTable from "../CustomerTable";
import { setCustomerDates } from "../reportSlice";
import { useSelector } from "react-redux";
function CustomerReport(props) {
  const { customerReportData, customerDates } = useSelector((st) => st.reports);
  return (
    <React.Fragment>
      <ReportsWrapper
        reportTitle={"CUSTOMER OVERVIEW"}
        currentTab={"CUSTOMER OVERVIEW"}
        pageKey={"CUSTOMER"}
        // setDateToStore={(dates) => setDates(dates)}
        setDateToStore={setCustomerDates}
        persistDate={customerDates}
        sportData={customerReportData}
        ReportTable={() => (
          <CustomerTable
            dates={customerDates}
            sportData={customerReportData}
            reportPage={"customer Report"}
          />
        )}
      />
    </React.Fragment>
  );
}

export default CustomerReport;
