import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { ResponsibleGamblingApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const ResponsibleGambling = createSlice({
    name: "responsiblegambling",
    initialState: {
        updateUserLimit: [],
    },
    reducers: {
        setUpateUserLimit: (state, action) => {
            state.updateUserLimit = action.payload
        },
    },
});

export const {
    setUpateUserLimit,
} = ResponsibleGambling.actions;

export const updateuserLimit = (id, params) => (dispatch) => {
    let obj = {
        dailyDepositLimit: params.dailyDepositLimit,
        dailyStakeLimit: params.dailyStakeLimit,
        dailyWithdrawalLimit: params.dailyWithdrawalLimit,
        monthlyDepositLimit: params.monthlyDepositLimit,
        monthlyStakeLimit: params.monthlyStakeLimit,
        monthlyWithdrawalLimit: params.monthlyWithdrawalLimit,
        weeklyDepositLimit: params.weeklyDepositLimit,
        weeklyStakeLimit: params.weeklyStakeLimit,
        weeklyWithdrawalLimit: params.weeklyWithdrawalLimit,
        selfExclusion: params.selfExclusion,
        timeLimit: params.timeLimit,
    };
    var url = `${ResponsibleGamblingApi.updateUserLimits}` + id;
    apiCaller(url, "PUT", obj)
        .then((response) => {
            if (response.status == 200) {
                dispatch(setUpateUserLimit(response.data));
                toaster.success("SUCCESS")
            }
        })
        .catch((err) => {
            const res = err.response
            toaster.error(messageEnhancer(res));
        });
}
export default ResponsibleGambling.reducer;