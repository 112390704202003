import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BRAND_CONFIG_CONSTANTS, COMMON_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { getAllBrand, updateBrandStatus } from "./createbrandslice";
import { COMMON_MSGS, brandInfo } from "../../sharedfiles/tooltipMsgs";
const CreateBrandTable = (props) => {
  const dispatch = useDispatch();
  const [statusVals, setStatusVals] = useState([]);
  const handleBrandStatus = (e, item) => {
    const id = item?.brandId;
    const status = e.target.value;
    dispatch(updateBrandStatus(id, status));
    setTimeout(() => {
      props.dispatch(
        getAllBrand(props.paginationFirstValue - 1, props.paginationSecondValue)
      );
    }, 500);
  };
  useEffect(() => {
    const updatedVals =
      props.listBrand &&
        props.listBrand.data != undefined &&
        props.listBrand?.data.length > 0
        ? props?.listBrand?.data.map((item) => item.status)
        : [];

    setStatusVals(updatedVals);
  }, [props.listBrand]);
  const headers = [
    "Brand Name",
    "Brand ID",
    "Brand Currency",
    "Client Name",
    "created On",
    "Created By",
    "Action"
  ]
  const info = [
    "Showing Brand Name",
    "Showing Brand ID",
    "Showing Brand Currency",
    "Showing Client Name",
    "Showing created On",
    "Showing Created By",
    "Showing Action"
  ]
  return (
    <>
      <div className='CMS-box'>
        <div className='CMS-table CMS-table-triped'>
          <table>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{item}</p>
                      <Tooltips info={`${info[index]}`} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.listBrand &&
                props.listBrand?.data &&
                props.listBrand?.data !== undefined &&
                Array.isArray(props.listBrand?.data) &&
                props.listBrand?.data.length > 0 ? (
                props?.listBrand?.data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.brandId}</td>
                      <td>{item.currency}</td>
                      <td>{item.clientName}</td>
                      <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                      <td>{item.createdBy}</td>
                      <td>
                        <div className='CMS-select'>
                          <select
                            id='brandActions'
                            className="pointer"
                            value={
                              statusVals.length > 0 ? statusVals[index] : ""
                            }
                            onChange={(e) => handleBrandStatus(e, item)}
                          >
                            <option value='ACTIVE'>{COMMON_CONSTANTS.activate}</option>
                            <option value='IN_ACTIVE'>{COMMON_CONSTANTS.inactivate}</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <No__Data__Found />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    listBrand: state.createbrand.listBrand,
    paginationFirstValue: state.createbrand.paginationFirstValue,
    paginationSecondValue: state.createbrand.paginationSecondValue,
  };
};

export default connect(mapStateToProps)(CreateBrandTable);
