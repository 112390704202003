import React from 'react'
import Tooltips from '../Tooltip'

const AmountRangeIp = ({ amountMin, amountMax, rangeHandle, rangeErr, title, minName, maxName, info }) => {
    return (
        <div div className='CMS-formGroup' >
            <div className='CMS-formLabel'>
                {title}
                &nbsp;&nbsp;
                <Tooltips info={info} />
            </div>
            <div className="CMS-amountRange">
                <div className='CMS-formControl CMS-minAmountRange'>
                    <input
                        name={minName}
                        value={amountMin}
                        onChange={(e) => rangeHandle(e)}
                        placeholder='min'
                        id="amountMin"
                    />
                </div>
                <div className='CMS-formControl'>
                    <input
                        name={maxName}
                        value={amountMax}
                        onChange={(e) => rangeHandle(e)}
                        placeholder='max'
                        id="amountMax"
                    />
                </div>
            </div>
            <div className='CMS-form-errorMsg'>{rangeErr}</div>
        </div>
    )
}

export default AmountRangeIp