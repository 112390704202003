import React, { useState } from 'react'
import { formatMoney, getDateTimeFormate } from '../../../sharedfiles/helper';
import { exportPdf } from "../../../sharedfiles/jsPdf";
import ReportsTable from "../../Common/table/ReportsTable";

const DepositTable = (props) => {
    const { dates } = props;
    const [fileType, setFileType] = useState("");

    const headers = [
        [
            "Player Name",
            "PAN Card Number",
            "Deposit Amount",
            "Transaction Date & Time",
            "TDS Amount",
        ],
    ];
    const data = props.reportsData &&
        Object.keys(props.reportsData) &&
        Array.isArray(props?.reportsData?.data) &&
        props?.reportsData?.data?.length > 0 &&
        props?.reportsData?.data?.map((item) => [
            item.userName,
            item.panNumber,
            formatMoney(item.amount),
            getDateTimeFormate(item.transactionDate),
            formatMoney(item.tdsAmount),

        ]);

    const csvData =
        data && data.length > 0
            ? [...headers, ...data]
            : [];

    const title = props.reportPage;
    const subtitle = "Deposit Report";
    const searchDate = {
        fromDate: dates.dateFrom,
        toDate: dates.dateTo,
    };

    const body =
        props.reportsData &&
            Object.keys(props.reportsData) &&
            Array.isArray(props?.reportsData?.data) &&
            props?.reportsData?.data?.length > 0
            ? data : [];
    const styles = {
        3: {
            valign: "middle",
            halign: "right",
        },
        4: {
            valign: "middle",
            halign: "right",
        },
    };
    const firstTable = {
        subtitle,
        headers,
        body,
        // styles,
        // alignments: ["3", "4"],
    };

    const tableData = [firstTable];
    const printPdf = () => {
        exportPdf(title, searchDate, tableData, styles);
    };

    return (
        <>
            <ReportsTable
                tableData={tableData}
                fileType={fileType}
                setFileType={(value) => setFileType(value)}
                csvData={csvData}
                fileName={title}
                printPdf={() => printPdf()}
            />
        </>
    )
}

export default DepositTable