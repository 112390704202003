import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../Common/table/Table'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Vpagination from '../../sharedfiles/Vpagination'
import { getDateTimeFormate } from '../../sharedfiles/helper'
import { getRegistrationTicker, setIsVisible, setPaginationFirstValue, setPaginationSecondValue, setRecordsShow } from './RegTickerSlice'

const RegistrationTicker = () => {
    const [from, setFrom] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [pdfBody, setPdfBody] = useState([]);
    const dispatch = useDispatch()
    const tickerData = useSelector((state) => state.registrationTicker.regTickerData)
    console.log("tickerData: ", tickerData);
    const pageFirstVal = useSelector(
        (state) => state.registrationTicker.paginationFirstValue
    );
    const pageSecondVal = useSelector(
        (state) => state.registrationTicker.paginationSecondValue
    );
    const recordsToShow = useSelector(
        (state) => state.registrationTicker.recordsToShow
    );
    const isVisible = useSelector(
        (state) => state.registrationTicker.isVisible
    );
    const currentdate = tickerData && tickerData?.data && tickerData?.data[0].currentTime

    //"2024-01-18T10:05:24.243Z"
    console.log(from, "from");


    const callApi = () => {
        dispatch(
            getRegistrationTicker(
                pageFirstVal - 1,
                pageSecondVal,
                from,
                tickerData
            )
        );
    };
    useEffect(() => {
        if (tickerData !== undefined) {
            setFrom(currentdate);
        }
    }, [tickerData]);

    useEffect(() => {
        callApi();
    }, []);

    const headers = [
        [
            "Customer ID",
            "Phone Number",
            "Email",
            "Registration Date",
            "State",
            "Country",
        ]
    ]

    const headerInfo = [
        "Showing Customer ID",
        "Showing Phone Number",
        "Showing Email",
        "Showing Registration Date",
        "Showing State",
        "Showing Country",
    ]

    useEffect(() => {
        if (tickerData !== undefined && tickerData?.data !== undefined) {
            const data = tickerData?.data !== undefined &&
                tickerData?.data[0].registrationTicker.map((p) => [
                    p.userId,
                    p.phoneNumber,
                    p.email,
                    getDateTimeFormate(p.registrationDate),
                    p.state,
                    p.country,
                ])
            setPdfBody(data);
            setCsvData([headers[0], headerInfo, ...data]);
        }

    }, [tickerData])

    const exportPDF = () => {
        const title = "Registration Ticker Report";
        const styles = {
            4: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, headers, pdfBody, styles);
    };

    const callPageApi = (pageNumber, itemsPerPage) => {
        dispatch(setIsVisible(false));

        dispatch(
            getRegistrationTicker(
                pageNumber - 1,
                itemsPerPage,
                from,
                tickerData
            )
        );
    };
    return (
        <React.Fragment>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <div className='CMS-tabContent'>
                        <div className='CMS-filter-result' id='result'>
                            <div className='CMS-pagination '>
                                <div className='CMS-pagination-container d-flex justify-content-end'>
                                    <div className='refresh-page' style={{ cursor: "pointer" }}>
                                        <span
                                            // style={reloadStyle}
                                            onClick={() => callApi()}
                                            className='material-icons md-36'
                                            data-icon='replay_circle_filled'
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div className='CMS-filter-result' id='result'>
                                <div className='CMS-pagination'>
                                    <Vpagination
                                        data={tickerData?.data}
                                        totalRecords={tickerData && tickerData?.totalRecords}
                                        paginationFirstValue={pageFirstVal}
                                        paginationSecondValue={pageSecondVal}
                                        setPaginationFirstValue={setPaginationFirstValue}
                                        setPaginationSecondValue={setPaginationSecondValue}
                                        recordsShow={recordsToShow}
                                        setRecordsShow={setRecordsShow}
                                        isVisible={isVisible}
                                        setIsVisible={setIsVisible}
                                        csvData={csvData}
                                        exportPDF={exportPDF}
                                        callApi={callPageApi}
                                        csvFileName={"Registration Ticker Report"}
                                    />
                                </div>
                            </div>
                            <Table
                                tableData={csvData}
                                linkIndex={0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RegistrationTicker