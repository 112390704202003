export const betTypeOptions = [
  { id: 1, label: "Combi", value: "combi" },
  { id: 2, label: "Custom", value: "custom" },
  { id: 3, label: "Multi", value: "multi" },
  { id: 4, label: "Single", value: "single" },
  { id: 5, label: "Split", value: "split" },
  { id: 6, label: "System", value: "system" },
  { id: 7, label: "Fancy", value: "fancy" },
];

export const betStatusCyrus = [
  // { id: 1, label: "Half Won", value: "HalfWon" },
  // { id: 2, label: "half Lost", value: "HalfLost" },
  // { id: 12, label: "All", value: "All" },
  // { id: 14, label: "CashOut", value: "CashOut" },
  // { id: 7, label: "Running", value: "Running" },
  { id: 3, label: "Rejected", value: "REJECTED" },
  { id: 4, label: "Cancelled", value: "Cancelled" },
  { id: 5, label: "Void", value: "Void" },
  { id: 6, label: "Cashed Out", value: "CashedOut" },
  { id: 8, label: "Won", value: "Won" },
  { id: 9, label: "Lost", value: "Lost" },
  { id: 10, label: "InitFailed", value: "Init_fail" },
  { id: 11, label: "Accepted", value: "ACCEPTED" },
  { id: 13, label: "Initialized", value: "Initialized" },
  // { id: 15, label: "Open", value: "Open" },
  { id: 16, label: "Pre init", value: "Pre_init" },
  { id: 17, label: "Settled", value: "Settled" },
];

export const betFoldCyrus = [
  { id: 2549, label: "1", value: "SINGLE" },
  { id: 9243, label: "2", value: "DOUBLE" },
  { id: 9324, label: "3", value: "TREBLE" },
  { id: 9757, label: "Multiple 4+", value: "MULTIPLE" },
];
export const sportCyrus = [
  { id: 957, label: "Aussie Rules", value: "AussieRules" },
  { id: 97867, label: "Badminton", value: "Badminton" },
  { id: 987967, label: "Baseball", value: "Baseball" },
  { id: 9796, label: "Basket ball", value: "Basketball" },
  { id: 97697, label: "Basketball 3x3", value: "Basketball3x3" },
  { id: 379, label: "Beach VolleyBall", value: "BeachVolleyBall" },
  { id: 4897, label: "Casinos", value: "Casinos" },
  { id: 243, label: "Cricket", value: "Cricket" },
  { id: 4678, label: "Darts", value: "Darts" },
  { id: 9076, label: "Field Hockey", value: "FieldHockey" },
  { id: 976, label: "Football", value: "Football" },
  { id: 5689, label: "Formula 1", value: "Formula1" },
  { id: 79, label: "Futsal", value: "Futsal" },
  { id: 47369, label: "Golf", value: "Golf" },
  { id: 439, label: "Handball", value: "Handball" },
  { id: 900, label: "Horse Racing", value: "HorseRacing" },
  { id: 639, label: "Ice Hockey", value: "Ice Hockey" },
  { id: 7969, label: "Tennis", value: "Tennis" },
  { id: 7679, label: "Kabaddi", value: "Kabaddi" },
  { id: 979, label: "Snooker", value: "Snooker" },
  { id: 68739, label: "Soccer", value: "Soccer" },
  { id: 769, label: "Squash", value: "Squash" },
  { id: 7369, label: "American Football", value: "AmericanFootball" },
  { id: 75739, label: "Rugby", value: "Rugby" },
  { id: 7379, label: "ESports", value: "ESports" },
  { id: 453, label: "Volley Ball", value: "VolleyBall" },
  { id: 7699, label: "Table Tennis", value: "Table Tennis" },
];
export const betSettlementFromData = [
  {
    id: 1,
    ids: "betSettlementDateFrom",
    title: "Placed On",
    placeholder: "DD/MM/YYYY",
    name: "placedOn",
    info: "Showing Placed on",
  },
  {
    id: 2,
    ids: "betSettlementDateTo",
    title: "Settled On",
    placeholder: "DD/MM/YYYY",
    name: "settledOn",
    info: "Showing Settled on",
  },
];
export const BetSettlementInfo = {
  bet_type: "Showing Bet Type",
  bet_id: "Showing Bet Id",
  short_bet_id: "Showing Short Bet Id",
  bet_fold: "Showing Bet Fold",
  event_id: "Showing Event Id",
  event_name: "Showing Event name",
  bet_status: "Showing Bet Status",
  sport: "Showing Sport",
};

export const TransactionListHeader = [
  "Bet Id",
  "Status",
  // "Transaction Date",
  "Payout",
  "Updated On",
  "Updated by",
];

export const BetSettlementTransaction = [
  "Showing Bet Id",
  "Showing Status",
  // "Showing Transaction Date",
  "Showing Payout",
  "Showing Updated On",
  "Showing Updated by",
];

export const BetSettlementTableInfo = {
  ticketId: "Showing Bet Id",
  oddsChangeType: "Showing oddsChangeType",
  betType: "Showing betType",
  betCount: "Showing betCount",
  currency: "Showing currency",
  odds: "Showing odds",
  stakeReal: "Showing stakeReal",
  bonusWinnings: "Showing bonusWinnings",
  bonusType: "Showing bonusType",
  isFreeBet: "Showing isFreeBet",
  freeBetId: "Showing freeBetId",
  status: "Showing status",
  system: "Showing system",
  potentialPayout: "Showing potentialPayout",
  bonusWinnings: "Showing bonusWinnings",
  bonusPercentage: "Showing bonusPercentage",
  maxWinPercentage: "Showing maxWinPercentage",
  maxWinAmount: "Showing maxWinAmount",
  payout: "Showing payout",
  cashoutStatus: "Showing cashoutStatus",
  createdDate: "Showing createdDate",
  resultedDate: "Showing resultedDate",
  cashOutAmount: "Showing cashOutAmount",
  isPrinted: "Showing isPrinted",
};

export const manualSettleOptions = [
  { id: 344, label: "Won", value: "Won" },
  { id: 542, label: "Lost", value: "Lost" },
  { id: 533, label: "Void", value: "Void" },
  { id: 766, label: "HalfWon", value: "HalfWon" },
  { id: 993, label: "HalfLost", value: "HalfLost" },
  { id: 785, label: "Cancelled", value: "Cancelled" },
];

export const BetSettlementMainHeader = [
  "Bet Id",
  "Short Id",
  "Odds Change Type",
  "Bet Type",
  "Bet Count",
  "Currency",
  "Odds",
  "Stake Bonus",
  "Real stake",
  "Bonus Amount",
  "Bonus Type",
  "Free Bet",
  "Free bet id",
  "Bet Status",
  "System Groups",
  "Potential Payout",
  "Bonus Winnings",
  "Bonus Percentage",
  "Max Win Percentage",
  "Max Win Amount",
  "Payout",
  "Cashout Status",
  "Placed On",
  "Settled On",
  "Cash Out Amount",
  "Is Printed",
  // "Taxes any new columns",
  "Actions",
];

export const csvInfo = [
  "Showing Bet Id",
  "Showing Short Bet Id",
  "Showing Odds Change Type",
  "Showing Bet Type ",
  "Showing Bet Count ",
  "Showing Currency",
  "Showing Odds",
  "Showing Stake Bonus",
  "Showing Real Stake",
  "Showing Bonus Winnings",
  "Showing Bonus Type",
  "Showing Free Bet",
  "Showing Free Bet Id",
  "Showing Bet Status",
  "Showing System Groups",
  "Showing Potential Payout",
  "Showing Bonus Winnings",
  "Showing Bonus Percentage",
  "Showing Max Winpercentage",
  "Showing Max WinAmount",
  "Showing Payout",
  "Showing Cashout Status",
  "Showing Placed On",
  "Showing Settled On",
  "Showing CashOut Amount",
  "Showing Is Printed",
  // "Showing Taxes any new columns",
  "Showing Actions",
];

export const BetSettlementTableHeader = [
  [
    "ticketId",
    "oddsChangeType",
    "betType",
    "betCount",
    "currency",
    "odds",
    "Stake Bonus",
    "stakeReal",
    "bonusWinnings",
    "bonusType",
    "isFreeBet",
    "freeBetId",
    "status",
    "system",
    "potentialPayout",
    "bonusWinnings",
    "bonusPercentage",
    "maxWinPercentage",
    "maxWinAmount",
    "payout",
    "cashoutStatus",
    "createdDate",
    "resultedDate",
    "cashOutAmount",
    "isPrinted",
  ],
];

export const remainHeaders = [
  "Outcome ID",
  "Outcome Name",
  "Outcome Result",
  "Odds",
  "Sport name",
  "Country",
  "Tournament Name",
  "Market Name",
  "Competition Name",
  "Competition Start Date",
];
export const remainHeadersTooltip = [
  "Showing Outcome ID",
  "Showing Outcome Name",
  "Showing Outcome Result",
  "Showing Odds",
  "Showing Sport name",
  "Showing Country",
  "Showing Tournamnet Name",
  "Showing Market Name",
  "Showing Competition Name",
  "Showing Competition Start Date",
];
export const headers = [
  "Selection Id",
  "Outcome ID",
  "Outcome Name",
  "Outcome Result",
  "Odds",
  "Sport name",
  "Country",
  "Tournament Name",
  "Marketing id (UUID)",
  "Market Name",
  "Competition Name",
  "Competition Id",
  "Competition Start Date",
  "Competition status",
  "Is outrights",
  "Match Status",
  "Current Match Status",
  "Ticket time Score ",
  "Current time Score",
  "Ticket time probability",
  "Current time Probability",
  "Settlement Type",
  "Updated By",
  "Updated On",
  "Actions",
];
export const toolTipsBetDetails = [
  "Showing Selection Id",
  "Showing Outcome ID",
  "Showing Outcome Name",
  "Showing Outcome Result",
  "Showing Odds",
  "Showing Sport Name",
  "Showing Country",
  "Showing Tournamnet Name",
  "Showing Marketing id(UUID)",
  "Showing Market Name",
  "Showing Competition Name",
  "Showing Competition Id",
  "Showing Competition Start Date",
  "Showing Competition status",
  "Showing is outrights",
  "Showing Match Status",
  "Showing Current Match Status",
  "Showing Ticket time Score ",
  "Showing Current time Score",
  "Showing Ticket time probability",
  "Showing Current time Probability",
  "Showing Settlement Type",
  "Showing Updated By",
  "Showing Updated On",
  "Showing Actions",
];

export const ViewAstraBetDeatilsHeader = [
  "Selection Id",
  "Outcome Name",
  "Outcome Results",
  "Odds",
  "Sports Name",
  "Country",
  "Tournament Name",
  "Market Name",
  "Competition Name",
  "Competition Start Date",
]
export const ViewAstraBetDeatilsPDF = [[
  "Selection Id",
  "Outcome Name",
  "Outcome Results",
  "Odds",
  "Sports Name",
  "Country",
  "Tournament Name",
  "Market Name",
  "Competition Name",
  "Competition Start Date",
]]
export const ViewAstraBetDetailsTooltips = [
  "Showing Selection Id",
  "Showing Outcome Name",
  "Showing Outcome Results",
  "Showing Odds",
  "Showing Sports Name",
  "Showing Country",
  "Showing Tournament Name",
  "Showing Market Name",
  "Showing Competition Name",
  "Showing Competition Start Date",
]

export const ViewAstraHeader = [
  "Bet Id",
  "Status",
  "Rejection Code",
  "Bet Type",
  "Odds",
  "Stake",
  "Placed Bet Types",
  "Bonus Amount",
  "Bonus Type",
  "Potential Payout",
  "Payout",
  "Placed On",
  "Settled On",
  "Total Payout",
  "Total Payout with Bonus",
  "Bonus Max win Amount",
  "Bonus Max win Percentage",
]
export const ViewAstraTooltips = [
  "Showing Bet Id",
  "Showing Status",
  "Showing Rejection Code",
  "Showing Bet Type",
  "Showing Odds",
  "Showing Stake",
  "Showing Placed Bet Types",
  "Showing Bonus Amount",
  "Showing Bonus Type",
  "Showing Potential Payout",
  "Showing Payout",
  "Showing Placed On",
  "Showing Settled On",
  "Showing Total Payout",
  "Showing Total Payout with Bonus",
  "Showing Bonus Max win Amount",
  "Showing Bonus Max win Percentage",
]