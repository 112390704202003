import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { communicationApis } from "../../sharedfiles/EndpointConfig";

export const PromoListSlice = createSlice({
    name: "PromoListSlice",
    initialState: {
        promoListData: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
        editListData: [],
        isApiImg: false,
        editId: ''

    },
    reducers: {
        setPromoListData: (state, action) => {
            state.promoListData = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setEditListData: (state, action) => {
            state.editListData = action.payload;
        },
        setEditId: (state, action) => {
            state.editId = action.payload
        },
        setIsApiImg: (state, action) => {
            state.isApiImg = action.payload
        }
    }
})

export const {
    setPromoListData,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,
    setEditListData,
    setEditId,
    setIsApiImg

} = PromoListSlice.actions

export const getPromotionList = (notyStatus, title, pageNumber, itemPerPage) => (dispatch) => {
    const url = `${communicationApis.getPromotionList}` + `?notificationStatus=${notyStatus}&title=${title}&pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`
    apiCaller(url, "POST", {}).then((res) => {
        if (res !== null && res) {
            dispatch(setPromoListData(res.data))
            // dispatch(setIsVisible(true));
        }
    }).catch((err) => {
        console.log(err);
    })

}

export const editPromotionList = (id) => (dispatch) => {
    const url = communicationApis.editPromotionList(id)
    apiCaller(url, "GET").then((res) => {
        dispatch(setEditListData(res.data))
    })
}

export default PromoListSlice.reducer
