import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import PrimaryTabs from "./PrimaryTabs";
import {
  formatMoney,
  getDateTimeFormate,
  hideInfo,
  Currency,
  hideMobile,
} from "../../sharedfiles/helper";

import { getPlayerGeneralDetail } from "../GeneralDetail/GeneralDetailSlice";
import { setaddMakeManualId } from "../paymentHistory/paymentHistoryTableSlice";
import {
  getFinancials,
  getPlayer,
  getBonuswidgetTableList,
  getKycType,
  userControl,
  setAddressDocs,
  setIdedntityDocs,
  uploadKycDetails,
} from "../primaryplayer/PrimaryPlayerSlice";
import { setAddNoteValue } from "../AddNote/addNoteSlice";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import Kyc from "./Kyc";
import ResetPassword from "./ResetPassword";
import EditPlayerDetails from "./EditPlayerDetails";
import PrimaryTable from "../Common/table/PrimaryTable";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { setIntervalStopper } from "../AppSlice";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import { COMMON_CONSTANTS, PASSWORD_CONSTANTS, PRIMARYPLAYER_CONSTANTS, kycOptions } from "../../sharedfiles/Constants";
import { PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
import { userStatus } from "../Common/data/Players/PlayersData";
import Select from "../Common/common_inputs/Select_DDL";
import { getUpdatedLimits } from "../PaymentLimitTab/LimitsTabSlice";
import { getUserStakes } from "../UserStakeSetting/UserStakeSlice";
import Input from "../Common/common_inputs/Input";
import { addToUserWatchList } from "../UsersWatchlist/userWatchListSlice";
import CommentBox from "../Common/CommentBox";
const PrimaryPlayer = (props) => {
  const dispatch = useDispatch();
  const [kycdialog, setKycdialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openEditBox, setOpenEditBox] = useState(false);
  const [openconfirmModal, setOpenConfirmModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [masking, setMasking] = useState(false);
  const [userSts, setUserSts] = useState('')
  const [openWatchlist, setOpenWatchlist] = useState(false)
  const [watchListCmnt, setWatchListCmnt] = useState('')
  const [emptyErr, setEmptyErr] = useState(false)
  const [uploadKyc, setUploadKyc] = useState(false);
  const [kycType, setKycType] = useState("");
  const [idType, setIdType] = useState("");
  const [fileValue, setFileValue] = useState("");
  const [formValid, setFormValid] = useState(false);
  const playerStatus = props.playerInfo.accountStatus
  const intervalStopper = useSelector(
    (state) => state.appslice.intervalStopper
  );
  const { id } = useParams();
  const onEditplayer = (e) => {
    e.preventDefault();
    setOpenEditBox(true);
    dispatch(getPlayerGeneralDetail({ user_id: id }));
  };
  const kycHandler = (val) => {
    setKycdialog(val);
    val && dispatch(getKycType());
  };
  const resetPasswordHandler = (value) => {
    setOpenResetDialog(value);
    props.dispatch(setIntervalStopper(value));
  };

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      if (localStorage.getItem("isMasking") === "DATA_MASKING") {
        setMasking(true)
      } else {
        setMasking(false)
      }
    }
    return () => mounting = false;

  }, []);

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      setUserSts(playerStatus)
    }
  },)

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      localStorage.setItem("userId", id);
      dispatch(setAddNoteValue(id));
      dispatch(setaddMakeManualId(id));
      dispatch(getKycType());
      dispatch(setAddressDocs([]))
      dispatch(setIdedntityDocs([]))
      dispatch(setUserId(id));
      dispatch(getPlayerGeneralDetail({ user_id: id }));
      dispatch(getFinancials({ user_id: id }));
      dispatch(getPlayer({ user_id: id }));
      dispatch(getUpdatedLimits(id))
      dispatch(getUserStakes(id))
      dispatch(getBonuswidgetTableList({ user_id: id }));
    }
    return () => mounting = false;
  }, [id])


  const update = (id) => {
    setTimeout(() => props.dispatch(getPlayer({ user_id: id })), 300);
  };

  const onChange = (e) => {
    const id = localStorage.getItem("userId");
    const value = e.target.value
    setUserSts(value)
    switch (value) {
      case "ACTIVE":
        dispatch(userControl(id, "ACTIVE"));
        update(id);
        break;
      case "SUSPENDED":
        dispatch(userControl(id, "SUSPENDED"));
        update(id);
        break;
      case "WITHDRAW_DEACTIVATED":
        dispatch(userControl(id, "WITHDRAW_DEACTIVATED"));
        update(id);
      default:
        break;
    }
  }

  const onSave = (status) => {
    const id = localStorage.getItem("userId");
    dispatch(userControl(id, "SUSPENDED"));
    update(id);
  };

  const onActivate = () => {
    const id = localStorage.getItem("userId");
    dispatch(userControl(id, "ACTIVE"));
    update(id);
  };

  function intervalHandler() {
    const userId = localStorage.getItem("userId");
    props.dispatch(getFinancials({ user_id: userId }));
    props.dispatch(getBonuswidgetTableList({ user_id: userId }));
  }

  useEffect(() => {
    if (!intervalStopper) {
      setOpenResetDialog(false);
    }
  }, [intervalStopper]);

  const getBettingData = () => {
    const data = [
      {
        title: "LT Bet Stakes",
        value: formatMoney(
          props.financialData.betDetails &&
            props.financialData.betDetails.stakes !== null
            ? props.financialData.betDetails.stakes
            : "0"
        ),
      },
      {
        title: "LT Free Bet Cost",
        value: formatMoney(
          props.financialData.betDetails &&
            props.financialData.betDetails.freeBet
            ? props.financialData.betDetails.freeBet
            : "0"
        ),
      },
      {
        title: "Primary Channel",
        value:
          props.financialData.betDetails &&
          props.financialData.betDetails.primaryChannel.replace(/^\w/, (c) =>
            c.toUpperCase()
          ),
      },
      {
        title: "LT Bet Returns",
        value: formatMoney(
          props.financialData.betDetails &&
            props.financialData.betDetails.payout !== null
            ? props.financialData.betDetails.payout
            : "0"
        ),
      },
      {
        title: "LT GGR",
        value: formatMoney(
          props.financialData.betDetails &&
            props.financialData.betDetails.ggr !== null
            ? props.financialData.betDetails.ggr
            : "0"
        ),
      },
      {
        title: "Primary Sport",
        value:
          props.financialData.betDetails &&
          props.financialData.betDetails.primarySport,
      },
    ];
    return data;
  };
  const handleWatchlistOpen = () => {
    setOpenWatchlist(true)
  }
  const handleWatchlistClose = () => {
    setOpenWatchlist(false)
    setWatchListCmnt('')
  }

  const onIpChange = (e) => {
    const { name, value } = e.target
    setWatchListCmnt(value)
    value === '' ? setEmptyErr(true) : setEmptyErr(false)
  }

  const onCmntSave = () => {
    const userID = localStorage.getItem("userId")
    const payload = {
      "comments": watchListCmnt,
      "watchUserId": userID
    }
    if (watchListCmnt === "") {
      setEmptyErr(true)
    } else {
      dispatch(addToUserWatchList(payload))
      setOpenWatchlist(false)
      setEmptyErr(false)
    }
    setTimeout(() => dispatch(getPlayer({ user_id: userID })), 300);
    setTimeout(() => {
      setWatchListCmnt('')
    }, 500)
  }

  useEffect(() => {
    const isFormValid = !!(kycType && idType && fileValue);
    setFormValid(isFormValid);
  }, [kycType, idType, fileValue]);

  const openKyc = () => {
    setUploadKyc(true)
  }
  const handleKycClose = () => {
    setUploadKyc(false)
  }
  const handleChange = (e) => {
    setIdType(e.target.value)
  }
  const handleUpload = (e) => {
    const { name } = e.target
    if (name === "uploadTag") {
      setFileValue(e.target.files[0])
    }
  }
  const onCancel = () => {
    setKycType("")
    setIdType("")
    setFileValue("")
  }

  const handleKycChange = (e) => {
    const value = e.target.value
    setKycType(value)
  }

  const onUpload = () => {
    const formData = new FormData();
    setUploadKyc(false)
    formData.append("file", fileValue);
    const userId = localStorage.getItem("userId")
    dispatch(uploadKycDetails(idType, kycType, userId, formData))
    onCancel()
  }
  return (
    <>

      <Dialog__Main
        title={"Show Watchlist"}
        dOpen={openWatchlist}
        dClose={handleWatchlistClose}
        width={"xs"}
        ids={"showwatchlist"}
      >
        <CommentBox
          placeholder={"Mention Your Comment Here"}
          value={watchListCmnt}
          errorMsg={emptyErr && "Please Mention Your commnet"}
          onIpChange={(e) => onIpChange(e)}
          onSave={(e) => onCmntSave(e)}
          onCancel={() => handleWatchlistClose()}

        />
      </Dialog__Main>
      <Dialog__Main
        title={"Upload Kyc"}
        dOpen={uploadKyc}
        dClose={handleKycClose}
        width={"xs"}
        ids={"uplaodkyc"}
      >
        <div className=''>
          <Select
            title={"Kyc Type"}
            name={"kycType"}
            value={kycType}
            setValue={(e) => handleKycChange(e)}
            options={kycOptions}
            ids={"kyctype"}
            info={PRIMARYPLAYER_CONSTANTS.kyc_type}
          />
        </div>
        <div>
          <Input
            title={"Id Type"}
            name={"idtype"}
            value={idType}
            placeholder={"Id Type"}
            setValue={(e) => handleChange(e)}
            ids={"idtype"}
            info={"Showing Id Type"}
          // show={true}
          />
        </div>
        <div>
          <Input
            title={"Upload File"}
            placeholder={"upload file"}
            name={"uploadTag"}
            type={"file"}
            ids={"uploadFile"}
            show={true}
            info={"Uplaod File"}
            accept={"image/jpeg,image/png,image/svg,image/tiff"}
            setValue={(e) => handleUpload(e)}
          />
        </div>
        <div className="CMS-btnContainer">
          <button
            onClick={(e) => onUpload(e)}
            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${!formValid ? 'disable-button' : ''} `}
            type={"button"}
            id={"updateLimit"}
          >
            {COMMON_CONSTANTS.Upload}
          </button>
          <button
            onClick={(e) => onCancel(e)}
            className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
            type={"button"}
            id={"updateLimit"}
          >
            {COMMON_CONSTANTS.cancel}
          </button>
        </div>
      </Dialog__Main>
      <Kyc
        kycdialog={kycdialog}
        setKycdialog={kycHandler}
      />
      <Dialog__Main
        title={"Reset Password"}
        dOpen={openResetDialog}
        dClose={resetPasswordHandler}
        width={"sm"}
        ids={"ResetPassword"}
      >
        <ResetPassword />
      </Dialog__Main>
      <ConfirmDialog
        title={"Do you want to Deactivate ?"}
        verify={() => onSave()}
        cancel={() => setOpenConfirmModal(false)}
        open={openconfirmModal}
        close={() => setOpenConfirmModal(false)}
        key={"deactivateUser"}
        width={"sm"}
      />
      <ConfirmDialog
        title={"Do you want to Activate ?"}
        verify={() => onActivate()}
        cancel={() => setActivateModal(false)}
        open={activateModal}
        close={() => setActivateModal(false)}
        key={"activateUser"}
        width={"sm"}
      />
      {openEditBox && (
        <EditPlayerDetails
          openEditBox={openEditBox}
          setOpenEditBox={setOpenEditBox}
        />
      )}
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='refresh-page mb-10'>
            <span
              className='material-icons md-36 pointer'
              data-icon='replay_circle_filled'
              onClick={() => intervalHandler()}
            ></span>
          </div>
          <div className='CMS-box CMS-table CMS-table-triped'>
            <table>
              <thead>
                <tr>
                  <th>
                    {masking && props.playerInfo && Object.keys(props.playerInfo).length > 0 &&
                      Number(props.playerInfo?.firstName) ? hideMobile(props.playerInfo?.firstName) : props.playerInfo?.firstName}
                    &nbsp;&nbsp;
                    {masking && props.playerInfo && Object.keys(props.playerInfo).length > 0 &&
                      Number(props.playerInfo?.lastName) ? hideMobile(props.playerInfo?.lastName) : props.playerInfo?.lastName}
                  </th>
                  {masking ? "" : <th className='text-left'>
                    {props.playerInfo && Object.keys(props.playerInfo).length > 0 &&
                      hideInfo(props.playerInfo?.userName, 1, true)}
                  </th>}
                  {/* <th>
                  {props?.playerInfo?.accountStatus === "ACTIVE" ? (
                    <div className='CMS-btnContainer'>
                      <div
                        className='CMS-statusInfo CMS-offline pointer'
                        id='accDeactivate'
                        onClick={() => setOpenConfirmModal(true)}
                      >
                        {" "}
                        {PRIMARYPLAYER_CONSTANTS.player_deactiveBtn}{" "}
                      </div>
                    </div>
                  ) : (
                    <div className='CMS-btnContainer'>
                      <div
                        className='CMS-btnStatus CMS-statusActive pointer'
                        onClick={() => setActivateModal(true)}
                        id='accActivate'
                      >
                        {" "}
                        {PRIMARYPLAYER_CONSTANTS.player_activeBtn}{" "}
                      </div>
                    </div>
                  )}
                </th> */}
                  <th>
                    <div className='mt-20'>
                      <Select
                        title={"User Status"}
                        name={"userStatus"}
                        value={userSts}
                        setValue={(e) => onChange(e)}
                        options={userStatus}
                        ids={"PlayerCurrency"}
                        info={PLAYER_MODULE.userStatus}
                      />
                    </div>
                  </th>
                  <th>
                    <div className='CMS-btnContainer'>
                      <button
                        className={
                          props.playerInfo.kycStatus === "KYC_PASSED"
                            ? "CMS-btn CMS-btnStatus  CMS-statusActive pointer"
                            : "CMS-btn CMS-btnStatus CMS-statusClosed pointer"
                        }
                        id='KYCbtn'
                        onClick={() => kycHandler(true)}
                      >
                        {PRIMARYPLAYER_CONSTANTS.player_kyc} {props.playerInfo.kycStatus === "KYC_PASSED" ? "VERIFIED" : "VERIFY"}
                      </button>
                    </div>
                    {props.playerInfo.kycStatus !== "KYC_PASSED" && (
                      <div
                        className='pointer'
                        onClick={() => kycHandler(true)}
                      >
                        {PRIMARYPLAYER_CONSTANTS.kyc_verifyMsg}
                      </div>
                    )}
                    <div className='CMS-btnContainer'>
                      <button
                        className={
                          "CMS-btn CMS-btnStatus CMS-statusSelf-excluded pointer mt-10"
                        }
                        id='kycUplaod'
                        onClick={openKyc}
                      >
                        {PRIMARYPLAYER_CONSTANTS.kyc_upload}
                      </button>
                    </div>
                  </th>

                  <th>
                    <div className='CMS-btnContainer'>
                      <button
                        onClick={() => resetPasswordHandler(true)}
                        // className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                        className='CMS-btn CMS-btnStatus CMS-statusActive CMS-btnMedium '
                        type='button'
                        id='resetPassword'
                        style={{
                          padding: "0px 40px",
                          cursor: "pointer",
                          backgroundColor: "#000000",
                        }}
                      >
                        {PASSWORD_CONSTANTS.resetPassBtn}
                      </button>
                    </div>
                  </th>
                  <th >
                    <div className='CMS-btnContainer'>
                      <button
                        onClick={(e) => onEditplayer(e)}
                        className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                        type='button'
                        id='editPlayer'
                      >
                        {PRIMARYPLAYER_CONSTANTS.editPlayerBtn}
                      </button>

                    </div>
                    <div className='CMS-btnContainer mt-10'>
                      <button onClick={handleWatchlistOpen} className={`CMS-btn CMS-btnSecondary active CMS-btnMedium pointer ${props.playerInfo?.isWatched === true ? "disable-button" : ''}`}>
                        {PRIMARYPLAYER_CONSTANTS.add_to_watchlist}
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>

          <div className='row'>
            <div className='col-5 plr-10'>
              <div className='CMS-box'>
                <div className='CMS-box__Header'>
                  <div className='CMS-box__title'>{PRIMARYPLAYER_CONSTANTS.header_Financials}</div>
                </div>
                <div className='CMS-table CMS-table-triped no-wrap'>
                  <table>
                    <tbody>
                      <tr>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_Deposits}</th>
                        <td className='textLeft'>
                          <span className='CMS-positive-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0
                                ? props.financialData?.playerSummaryVw?.totalDeposit
                                : "0"
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_Withdrawals}</th>
                        <td className='textLeft'>
                          <span className='CMS-negative-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.playerSummaryVw?.netWithdrawal
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_lastDepoAmount}</th>
                        <td className='textLeft'>
                          <span className='CMS-positive-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.financials?.lastDeposit
                            )}
                          </span>
                          <br />
                          <small>
                            {props.financialData?.financials?.lastDepositedDate === null ? "N/A"
                              : getDateTimeFormate(
                                Object.keys(props.financialData).length > 0 &&
                                props.financialData?.financials?.lastDepositedDate
                              )}
                          </small>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_manualDeposits}</th>
                        <td className='textLeft'>
                          <span className='CMS-positive-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.playerSummaryVw?.totalManualDeposit
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_lastManualDepo}</th>
                        <td className='textLeft'>
                          <span className='CMS-positive-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.financials?.lastManualDeposit
                            )}
                          </span>
                          <br />
                          <small>
                            {props.financialData?.financials?.lastManualDepositedDate === null ? "N/A"
                              : getDateTimeFormate(
                                Object.keys(props.financialData).length > 0 &&
                                props.financialData?.financials?.lastManualDepositedDate
                              )}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_withdrawableAmnt}</th>
                        <td className='textLeft'>
                          <span className='CMS-negative-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.playerSummaryVw?.withdrawls
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_pendingWithdraw}</th>
                        <td className='textLeft'>
                          <span className='CMS-negative-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.playerSummaryVw?.pendingWithdrawals
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_stakeAfterlastDepo}</th>
                        <td className='textLeft'>
                          <span className='CMS-positive-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.financials?.totalStakeAfterDeposit
                            )}
                          </span>
                        </td>
                        <th className='textLeft'>{PRIMARYPLAYER_CONSTANTS.header_withdrawals}</th>
                        <td className='textLeft'>
                          <span className='CMS-negative-value'>
                            {Currency()}
                            {formatMoney(
                              Object.keys(props.financialData).length > 0 &&
                              props.financialData?.playerSummaryVw?.netManualWithdrawal
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='col-7 plr-10 '>
              <div className='CMS-box CMS-box-h-100'>
                <div className='CMS-box__Header'>
                  <div className='CMS-box__title'>{PRIMARYPLAYER_CONSTANTS.header_betting}</div>
                </div>
                <PrimaryTable data={getBettingData()} />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-5 plr-10'>
              <div className='CMS-box CMS-box-h-100'>
                <div className='CMS-box__Header'>
                  <div className='CMS-box__title'>{PRIMARYPLAYER_CONSTANTS.header_balance}</div>
                </div>
                <div className='CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_real}</th>
                        <th
                          colSpan='2'
                          scope='colgroup'
                          className='text-center'
                        >
                          {PRIMARYPLAYER_CONSTANTS.th_sports}
                        </th>
                        <th colSpan='2' scope='colgroup'>
                          {PRIMARYPLAYER_CONSTANTS.th_casino}
                        </th>
                        <th colSpan='2' scope='colgroup'>
                          {PRIMARYPLAYER_CONSTANTS.ne_games}
                        </th>
                        <th colSpan='2' scope='colgroup'>
                          {PRIMARYPLAYER_CONSTANTS.th_common}
                        </th>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_total}</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      </tr>
                      <tr>
                        <th></th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_locked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_unLocked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_locked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_unLocked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_locked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_unLocked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_locked}</th>
                        <th scope='col'>{PRIMARYPLAYER_CONSTANTS.th_unLocked}</th>
                        <th></th>
                      </tr>
                      {Object.keys(props.BonusData).length > 0 && (
                        <tr>
                          <td>
                            {Currency()}
                            {props.financialData?.playerSummaryVw
                              ? formatMoney(
                                props.financialData?.playerSummaryVw?.deposit +
                                props.financialData?.playerSummaryVw?.withdrawls
                              )
                              : "0"}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.BonusData?.sportLockedBonus * 100
                            )}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.BonusData?.sportUnLockedBonus * 100
                            )}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.BonusData?.casinoLockedBonus * 100
                            )}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.BonusData?.casinoUnLockedBonus * 100
                            )}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(props.BonusData?.neGameLockedBonus * 100)}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(props.BonusData?.neGameUnLockedBonus * 100)}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(props.BonusData?.lockedBonus * 100)}
                          </td>
                          <td>
                            {Currency()}
                            {formatMoney(props.BonusData?.unLockedBonus * 100)}
                          </td>
                          <td>
                            {Currency()}
                            {props.financialData?.playerSummaryVw
                              ? formatMoney(
                                props.financialData?.playerSummaryVw?.deposit +
                                props.financialData?.playerSummaryVw?.withdrawls +
                                props.bonusWidgetData?.unLockedBonus * 100 +
                                props?.bonusWidgetData?.lockedBonus * 100 +
                                props.BonusData?.sportLockedBonus * 100 +
                                props.BonusData?.sportUnLockedBonus * 100 +
                                props.BonusData?.casinoLockedBonus * 100 +
                                props.BonusData?.casinoUnLockedBonus * 100 +
                                props.BonusData?.neGameLockedBonus * 100 +
                                props.BonusData?.neGameUnLockedBonus * 100
                              )
                              : "0"}
                          </td>

                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className='col-5 plr-10'>
                        <div className='CMS-box'>
                          <div className='CMS-box__Header'>
                            <div className='CMS-box__title'>Balance</div>
                          </div>
                          <div className='CMS-table CMS-table-triped'>
                            <table>
                                <tbody>
                                  <tr>
                                    {/* <th></th> */}
            {/* <th className='textLeft'>Real</th>
                                    <th>Bonus</th>
                                    <th>Locked Bonus</th>
                                    <th>Total</th>
                                  </tr> */}
            {/* <tr>  */}
            {/* <td>Sport</td> */}
            {/* <td className='textLeft'>
                                       &#8377;
                                      <span>
                                      {props.financialData.playerSummaryVw
                                          
                                          ? formatMoney(props.financialData
                                              .playerSummaryVw.deposit + props.financialData
                                              .playerSummaryVw.withdrawls)
                                          : "0"} */}
            {/* {
                                      formatMoney(
                                        
                                      )
                                      formatMoney(real)} */}
            {/* </span>
                                    </td>

                                    <td>
                                      <span className='CMS-positive-value'>
                                        &#8377;
                                        
                                         {props.bonusWidgetData ? props.bonusWidgetData.unLockedBonus : "0"}
                                      
                                      </span>
                                    </td> */}

            {/* <td>
                                      <span className='CMS-negative-value'>
                                        &#8377;
                                        {props?.bonusWidgetData ? props?.bonusWidgetData.lockedBonus : "0"}
                                      </span>
                                    </td>

                                    <td>
                                       &#8377;
                                      <span>{
                                      props.financialData.playerSummaryVw?formatMoney(props.financialData
                                              .playerSummaryVw.deposit + props.financialData
                                              .playerSummaryVw.withdrawls +
                                              props.bonusWidgetData.unLockedBonus *100+ 
                                              props?.bonusWidgetData.lockedBonus *100
                                              ) : "0"
                                              }
                                              </span>
                                    </td>
                                  </tr>
                                  <tr> */}
            {/* <td></td> */}
            {/* <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr> */}
            {/* <tr>
                                    <td>Casino</td>
                                    <td className='textLeft'>
                                      {props.balanceData.casinoBalance !==
                                        undefined && (
                                        <span className='CMS-positive-value'>
                                          &#8377;
                                          {formatMoney(
                                            props.balanceData.casinoBalance
                                              .deposit
                                              ? props.balanceData.casinoBalance
                                                  .deposit
                                              : "0"
                                          )}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {props.balanceData.casinoBalance !==
                                        undefined && (
                                        <span className='CMS-positive-value'>
                                          &#8377;
                                          {formatMoney(
                                            props.balanceData.casinoBalance
                                              .bonus
                                              ? props.balanceData.casinoBalance
                                                  .bonus
                                              : "0"
                                          )}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {props?.balanceData.casinoBalance !==
                                        undefined && (
                                        <span className='CMS-negative-value'>
                                          &#8377;
                                          {formatMoney(
                                            props?.balanceData.casinoBalance
                                              .lockedBonus
                                              ? props?.balanceData.casinoBalance
                                                  .lockedBonus
                                              : "0"
                                          )}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {props?.balanceData.casinoBalance !==
                                        undefined && (
                                        <span className='CMS-negative-value'>
                                          &#8377;
                                          {formatMoney(
                                            props?.financialData.casinoBalance
                                              ?.withdrawls +
                                              props.balanceData.casinoBalance
                                                .deposit
                                          )}
                                        </span>
                                      )}
                                    </td>
                                  </tr> */}
            {/* </tbody>
                            </table>
                          </div>
                        </div>
                      </div> */}
            <div className='col-7 plr-10'>
              <div className='CMS-box CMS-box-h-100'>
                <div className='CMS-box__Header'>
                  <div className='CMS-box__title'>{PRIMARYPLAYER_CONSTANTS.header_risk}</div>
                </div>
                <div className='CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_openBets}</th>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_risk}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_singleBets}</th>

                        {props.financialData?.risk !== undefined && (
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.financialData?.risk?.singleBet?.openBets
                                ? props.financialData?.risk?.singleBet?.openBets
                                : 0
                            )}
                          </td>
                        )}

                        {props.financialData?.risk !== undefined && (
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.financialData?.risk?.singleBet?.risk
                                ? props.financialData?.risk?.singleBet?.risk
                                : 0
                            )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th>{PRIMARYPLAYER_CONSTANTS.th_comboBets}</th>
                        {props.financialData?.risk !== undefined && (
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.financialData?.risk?.comboBet?.openBets
                                ? props.financialData?.risk?.comboBet?.openBets
                                : 0
                            )}
                          </td>
                        )}

                        {props.financialData?.risk !== undefined && (
                          <td>
                            {Currency()}
                            {formatMoney(
                              props.financialData?.risk?.comboBet?.risk
                                ? props.financialData?.risk?.comboBet?.risk
                                : 0
                            )}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className='col-4 plr-10'>
              <div className='CMS-box'>
                <div className='CMS-box__Header'>
                  <div className='CMS-box__title'>{PRIMARYPLAYER_CONSTANTS.header_bonuses}</div>
                </div>
                <div className='CMS-table CMS-table-triped'>
                  <table>
                    {props.bonusData?.data && props.bonusData?.data?.length ? (
                      <>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_bonusType}</th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_currentOffer}</th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_appliedOn}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{PRIMARYPLAYER_CONSTANTS.th_sport}</td>
                            <td>
                              {String(props.bonusData?.data[0].bonusRestricted)}
                            </td>
                            <td>
                              &#8377;{props.bonusData?.data[0].totalBonus}
                            </td>
                            <td>
                              {getDateFormate(props.bonusData?.data[0].addedDate)}
                            </td>
                          </tr>
                          <tr>
                            <td>{PRIMARYPLAYER_CONSTANTS.th_casino}</td>
                            <td></td>
                            <td></td>

                            <td></td>
                          </tr>
                        </tbody>
                      </>
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_bonusType}</th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_currentOffer}</th>
                            <th>{PRIMARYPLAYER_CONSTANTS.th_appliedOn}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{PRIMARYPLAYER_CONSTANTS.th_sport}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{PRIMARYPLAYER_CONSTANTS.th_casino}</td>
                            <td></td>
                            <td></td>

                            <td></td>
                          </tr>
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div> */}
            {/* <div className="col-4 plr-10">
                        <div className="CMS-box">
                          <div className="CMS-box__Header">
                            <div className="CMS-box__title">Bonuses</div>
                          </div>
                          <div
                            className="CMS-table CMS-table-triped"
                             style={{ height: "131px", overflowY: "auto" }}
                          >
                            <table>
                              <thead>
                                <tr>
                                  <th>Bonus Restricted</th>
                                  <th>Current Offer</th>
                                  <th>Applied On</th>
                                </tr>
                              </thead>
                              {props.bonusData.data && props.bonusData.data.length ?
                                props.bonusData?.data.map((item, index) => {
                                  return (
                                    <tbody>
                                      <tr>
                                       
                                        <td>{String(item.bonusRestricted)}</td>
                                        <td>&#8377;{item.totalBonus}</td>
                                        <td>
                                          {getDateFormate(item.addedDate)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                                :
                                <tbody>
                                  <tr>
                                  <td colSpan={3}>
                                      <h2 style={{ textAlign: "center" }}>No Result</h2>
                                  </td>
                                   
                                  </tr>
                                </tbody>
                                }
                            </table>
                          </div>
                        </div>
                      </div> */}
          </div>

          <PrimaryTabs />
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    financialData: state.primaryplayer.financialData,
    dialogopen: state.primaryplayer.dialogopen,
    firstName: state.generaldetail.firstName,
    userId: state.playersearch.userId,
    playerInfo: state.primaryplayer.playerData,
    balanceData: state.primaryplayer.balanceData,
    bonusWidgetData: state.primaryplayer.bonusData,
    tagValue: state.primaryplayer.tagValue,
    paginationFirstValue: state.playersearch.paginationFirstValue,
    paginationSecondValue: state.playersearch.paginationSecondValue,
    // bonusData: state.bonusSlice.bonusData,
    BonusData: state.primaryplayer.bonusData,
    // successMessage: state.appslice.successMessage,
    // errorMessage: state.appslice.errorMessage,
    kycData: state.primaryplayer.kycData,
    kycType: state.primaryplayer.kycType,
    isPlayerExist: state.playersearch.isPlayerExist,
  };
}
export default connect(mapStateToProps)(PrimaryPlayer);
