import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bankApi } from "../../sharedfiles/EndpointConfig";
import { cleanObject, messageEnhancer, toaster } from "../../sharedfiles/helper";
const bankDetailsSlice = createSlice({
  name: "bankDetailsSlice",
  initialState: {
    bankData: [],
    viewRecords: false,
    isVisible: false,
    dataFetched: false,
    disableBtn: false,
  },
  reducers: {
    setBankData: (state, action) => {
      state.bankData = action.payload;
    },
    setViewRecords: (state, action) => {
      state.viewRecords = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    clearDataFetched: (state, action) => {
      state.dataFetched = action.payload;
    },
    setDisableBtn: (state, action) => {
      state.disableBtn = action.payload;
    },
  },
});

export const { setBankData, setViewRecords, setIsVisible, clearDataFetched, setDisableBtn } = bankDetailsSlice.actions;

export const fetchBankDetails = (params) => (dispatch) => {
  var url = `${bankApi.getBank}` + parseInt(params.id);
  if (params.id !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data !== undefined) {
        if (response.status == 200) {
          dispatch(setBankData(response.data));
          dispatch(setIsVisible(true));
        }
      }
    });
  }
};

//dev.platform-ups.sportsit-tech.net:443/player/bank/details/1000042
export const addBankDetails = (bank, userId) => (dispatch) => {
  if (userId !== "" || userId !== null || userId !== undefined) {
    const url = `${bankApi.getBank}` + parseInt(userId);
    const obj = {
      accountHolderName: bank.accountHolderName,
      accountNumber: bank.accntNo,
      bankName: bank.bankName,
      branchName: bank.branchName,
      isPrimary: false,
      ifscCode: bank.ifscCode,
    };
    apiCaller(url, "POST", obj)
      .then((res) => {
        dispatch(fetchBankDetails({ id: userId }));
        toaster.success(messageEnhancer(res.msg));
        dispatch(setDisableBtn(true))
        dispatch(clearDataFetched(true));
      })
      .catch((err) => {
        dispatch(setDisableBtn(true))
        const response = err.response
        toaster.error(messageEnhancer(response));
      });
  }
};

export const activateBank = (id, data) => (dispatch) => {
  const url = bankApi.activeBank + id;
  apiCaller(url, "POST")
    .then((res) => {
      const index = data.findIndex((ele) => ele.id == id)
      let tempData = [...data]
      tempData[index] = { ...tempData[index], isActive: true }
      dispatch(setBankData(tempData));
      const userId = localStorage.getItem("userId")
      dispatch(fetchBankDetails({ id: userId }))
      toaster.success(messageEnhancer(res.msg));
    })
    .catch((err) => {
      const response = err.response
      toaster.error(messageEnhancer(response));
    });
}

export const deactivateBankAccount = (id, data) => (dispatch) => {
  const url = bankApi.getBank + id;
  apiCaller(url, "DELETE")
    .then((res) => {
      const index = data.findIndex((ele) => ele.id == id)
      let tempData = [...data]
      tempData[index] = { ...tempData[index], isActive: false }
      dispatch(setBankData(tempData));
      const userId = localStorage.getItem("userId")
      dispatch(fetchBankDetails({ id: userId }))
      toaster.success(messageEnhancer(res.msg));
    })
    .catch((err) => {
      const response = err.response
      toaster.error(messageEnhancer(response));
    });
}

//dev.platform-ups.sportsit-tech.net:443/player/1000042/bank/details/27
export const changePrimaryAccount = (userId, accountId) => (dispatch) => {
  if (userId !== "" || userId !== null || userId !== undefined) {
    const url = `${bankApi.setPrimary(userId)}` + parseInt(accountId);
    const obj = {
      // isPrimary: true,
    };
    apiCaller(url, "PUT", obj)
      .then((res) => {
        toaster.success("SUCCESS");
      })
      .catch((err) => toaster.error("FAILED"));
  }
};

export default bankDetailsSlice.reducer;
