import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi, playerapi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const PendingWithrawalSlice = createSlice({
  name: "pendingwithrawal",
  initialState: {
    recordsToShow: [1, 25],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    pendingWithrawal: [],
    isVisible: false,
    statusSelected: "",
    notifications: {},
    docsData: [],
    statusType: "INIT",
    recordsToShowSP: [1, 25],
    paginationFirstValueSP: 1,
    paginationSecondValueSP: 25,
    pendingWithrawalSP: [],
    isVisibleSP: false,
    statusSelectedSP: "",
    notificationsSP: {},
    docsDataSP: [],
    statusTypeSP: "INIT",
    viewAudit: true,

  },
  reducers: {
    setPendingWithrawal: (state, action) => {
      state.pendingWithrawal = action.payload;
    },
    setRecordsToShow: (state, action) => {
      state.recordsToShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setStatusSelected: (state, action) => {
      state.statusSelected = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setStatusType: (state, action) => {
      state.statusType = action.payload;
    },
    setPendingWithrawalSP: (state, action) => {
      state.pendingWithrawalSP = action.payload;
    },
    setRecordsToShowSP: (state, action) => {
      state.recordsToShowSP = action.payload;
    },
    setPaginationFirstValueSP: (state, action) => {
      state.paginationFirstValueSP = action.payload;
    },
    setPaginationSecondValueSP: (state, action) => {
      state.paginationSecondValueSP = action.payload;
    },
    setIsVisibleSP: (state, action) => {
      state.isVisibleSP = action.payload;
    },
    setStatusSelectedSP: (state, action) => {
      state.statusSelectedSP = action.payload;
    },
    setNotificationsSP: (state, action) => {
      state.notificationsSP = action.payload;
    },
    setDocsDataSP: (state, action) => {
      state.docsDataSP = action.payload;
    },
    setStatusTypeSP: (state, action) => {
      state.statusTypeSP = action.payload;
    },
    setViewAudit: (state, action) => {
      state.viewAudit = action.payload;
    },
  },
});
export const {
  setPendingWithrawal,
  setRecordsToShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setStatusSelected,
  setNotifications,
  setDocsData,
  setStatusType,
  setPendingWithrawalSP,
  setRecordsToShowSP,
  setPaginationFirstValueSP,
  setPaginationSecondValueSP,
  setIsVisibleSP,
  setStatusSelectedSP,
  setNotificationsSP,
  setDocsDataSP,
  setStatusTypeSP,
  setViewAudit
} = PendingWithrawalSlice.actions;

export const fetchPendingWithrawalData = (pagenumbervalue, itemperpagevalue) => (dispatch) => {
  var url =
    `${paymentSearchApi.getPendingWithrawals}` +
    "?pageNumber=" +
    pagenumbervalue +
    "&itemsPerPage=" +
    itemperpagevalue;

  apiCaller(url, "GET").then((response) => {
    if (response !== null && response) {
      if (response.status == 200) {
        dispatch(setPendingWithrawal(response.data));
        dispatch(setIsVisible(true));
      }
    }
  });
};

// https://dev.platform-ups.sportsit-tech.net:443/player/withdraw/1000019
export const updatePendingWithdraw = (params) => (dispatch) => {
  if (params.userId != null) {
    var data = {
      id: params.id,
      comments: params.message,
      status: params.status,
    };

    var url = `${paymentSearchApi.getPendingWithrawals}` + "/" + params.userId;

    apiCaller(url, "PUT", data)
      .then((response) => {
        if (response !== null && response) {
          dispatch(fetchPendingWithrawalData(0, 25));
          dispatch(getPendingNotifications())
          toaster.success(params.status)
        }
      })
      .catch((err) => toaster.error("Failed"));
  }
};


// ===pending withdrawal SP === //
export const pendingwithdrawals = (pagenumbervalue, itemperpagevalue, status) => (dispatch) => {
  const params = {
    status,
    pageNum: pagenumbervalue,
    noItems: itemperpagevalue,
  };
  let url =
    status !== ""
      ? paymentSearchApi.fetchPendingWithdrawalsStatus(params)
      : paymentSearchApi.fetchPedingWithdrawals(params);

  apiCaller(url, "GET").then((response) => {
    if (response !== null && response) {
      if (response.status == 200) {
        dispatch(setPendingWithrawalSP(response.data));
        dispatch(setIsVisibleSP(true));
        dispatch(getPendingNotifications())
      }
    }
  }).catch(err => {
    dispatch(setPendingWithrawalSP([]));
    dispatch(setIsVisibleSP(true));
  });
};

// ===pending withdrawals sp === //
export const updatePW = (params) => (dispatch) => {
  const url = paymentSearchApi.updateWithdrawal(params.userId);

  const payload = {
    comments: params.comments,
    id: params.tnxId,
    status: params.status,
  };
  apiCaller(url, "PUT", payload)
    .then((res) => {
      if (res !== null && res) {
        if (res.status == 200) {
          toaster.success("Success");
          dispatch(getPendingNotifications());
          dispatch(pendingwithdrawals(0, 25));
        }
      }
    })
    .catch((err) => toaster.error("Failed"));
};

export const getPendingNotifications = () => (dispatch) => {
  const url = playerapi.getNotifications;
  apiCaller(url, "GET").then((res) => {
    dispatch(setNotifications(res?.data?.[0]));
  });
};

export default PendingWithrawalSlice.reducer;
