import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getBrandList,
  addBrandKey,
  updateKey,
} from "../Brand config/BrandConfigSlice";
import { generateKey, getDateTimeFormate } from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Tooltips from "../Common/Tooltip";
import { BRAND_KEY, COMMON_CONSTANTS, CREATEROLE_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { BrandData, BrandKeyList } from "../../sharedfiles/tooltipMsgs";
const BrandsListDialog = (props) => {
  const dispatch = useDispatch();
  const [keyName, setKeyName] = useState("");
  const [keyStatus, setKeyStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [nameError, setNameError] = useState(false);
  const [statusError, setStatusError] = useState(false);

  const handleKeyName = (e) => {
    const { value } = e.target;
    value !== "" ? setNameError(false) : setNameError(true);
    setKeyName(e.target.value);
  };

  const handleKeyStatus = (e) => {
    const { value } = e.target;
    value !== "" ? setStatusError(false) : setStatusError(true);
    setKeyStatus(e.target.value);
  };

  const submitBrandDetails = (e) => {
    e.preventDefault();
    if (keyName != "" && keyStatus != "") {
      const data = {
        brandId: props.brandId,
        name: keyName,
        status: keyStatus,
      };
      const params = {
        pageNum: 0,
        noItems: 10,
      };
      dispatch(addBrandKey(data));
      dispatch(getBrandList(params));
      setKeyName("")
      setKeyStatus("")
    }
    if (keyName == "") {
      setNameError(true)
    }
    if (keyStatus == "") {
      setStatusError(true)
    }
  };

  const keyUpdate = (e, item) => {
    setUpdateStatus(e.target.value);
    let status = "";
    if (e.target.value == `ACTIVE${item.apiSecretKey}`) {
      status = "ACTIVE";
    } else if (e.target.value == `IN_ACTIVE${item.apiSecretKey}`) {
      status = "IN_ACTIVE";
    }
    const data = {
      apiKey: item.apiKey,
      name: item.keyName,
      status,
    };
    const params = {
      pageNum: 0,
      noItems: 10,
    };
    dispatch(updateKey(data));
    dispatch(getBrandList(params));
  };

  useEffect(() => {
    const params = {
      pageNum: 0,
      noItems: 10,
    };
    const dataExist = Object(props.brandList).length > 0

    // if (props.openDialog == true) {
    //   dispatch(getBrandList(params));
    // }
    if (!dataExist) {
      dispatch(getBrandList(params));
    }
  }, []);

  const headers = [
    "Api Key",
    "Key Name",
    "Brand Id",
    "Added By",
    "Api Secret Key",
    "Key Status",
    "Created Date",
    "Updated Date",
    "Created By",
    "Updated By",
    "Action"
  ]
  return (
    <>
      <Dialog__Main dOpen={props.openDialog} dClose={props.setOpenDialog} width={"xl"} title={"Brand Key"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="CMS-formGroup">
                  <div className="CMS-formLabel">
                    {BRAND_KEY.keyName}
                    &nbsp;&nbsp;
                    <Tooltips info={BrandData.key_Name} />
                  </div>
                  <div className="CMS-formControl-group">
                    <input
                      type="text"
                      className="form-control"
                      id="keyname"
                      name="KeyName"
                      placeholder="Key Name"
                      value={keyName}
                      onInput={handleKeyName}
                    />
                    {nameError && (
                      <div className="text-danger">
                        {BRAND_KEY.empty_keyName_msg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="CMS-formGroup">
                  <div className="CMS-formLabel">
                    {BRAND_KEY.key_Status}
                    &nbsp;&nbsp;
                    <Tooltips info={BrandData.key_Status} />
                  </div>
                  <div className="CMS-dropdown CMS-formControl">
                    <div className="CMS-select">
                      <select
                        name="keyStatus"
                        id="keystatus"
                        value={keyStatus}
                        onChange={handleKeyStatus}
                        className="pointer"
                      >
                        <option value="">Select</option>
                        <option value="ACTIVE">active</option>
                        <option value="IN_ACTIVE">inactive</option>
                      </select>
                      {statusError && (
                        <div className="text-danger">
                          {BRAND_KEY.empty_keyErr_msg}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2">
            <button
              className="CMS-btn CMS-btnSecondary active CMS-btnMedium "
              id="keyAdd"
              onClick={submitBrandDetails}
            >
              Add
            </button>
          </div>
        </div>
        <div className="mt-20"></div>
        <div className="dialog-box3" style={{ minHeight: "250px" }}>
          <div className="CMS-box CMS-table CMS-table-triped">
            <table className="text-center">
              <thead>
                <tr>
                  {headers.map((item, index) => (
                    <th>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{item}</p>
                        <Tooltips info={`${BrandKeyList[index]}`} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.brandList &&
                  Array.isArray(props.brandList) &&
                  Object(props.brandList).length > 0 &&
                  props.brandList.length > 0 ? (
                  props.brandList.map((item, ind) => {
                    return (
                      <tr key={generateKey(ind)}>
                        <td>{item.apiKey}</td>
                        <td>{item.keyName}</td>
                        <td>{item.brandId}</td>
                        <td>{item.addedBy}</td>
                        <td>{item.apiSecretKey}</td>
                        <td>{item.keyStatus}</td>
                        <td>{getDateTimeFormate(item.createdDate)}</td>
                        <td>{getDateTimeFormate(item.updatedDate)}</td>
                        <td>{item.addedBy}</td>
                        <td>{item.updatedBy}</td>
                        <td>
                          <div className="CMS-number-of-files CMS-select">
                            <select
                              id="accountStatus"
                              name="accountStatus"
                              key={item.apiSecretKey}
                              value={updateStatus}
                              onChange={(e) => keyUpdate(e, item)}
                            >
                              <option value="">Select</option>
                              <option value={`ACTIVE${item.apiSecretKey}`}>
                                ACTIVE
                              </option>
                              <option value={`IN_ACTIVE${item.apiSecretKey}`}>
                                IN_ACTIVE
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <No__Data__Found colspan={8} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog__Main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    brandList: state.brandconfig.brandList,
  };
};

export default connect(mapStateToProps)(BrandsListDialog);
