import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

function Dialog__Main(props) {
  const { title, dOpen, dClose, width, dHeight, Body, ids, children, closable = true, key } = props;
  return (
    <React.Fragment>
      <Dialog open={dOpen} maxWidth={width}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
          }}
          id={ids}
        >
          <DialogTitle>{title}</DialogTitle>
          {closable ?
            <IconButton
              style={{ color: "white" }}
              key='close'
              arial-label='close'
              onClick={() => dClose(false)}
            >
              x
            </IconButton> : null
          }
        </div>
        <DialogContent>
          <div
            // className='CMS-box CMS-table CMS-table-triped'
            style={{
              maxHieght: "600px",
              minHeight: dHeight,
              // minHeight: dHeight ? dHeight : "280px",
              msOverflowY: "scroll",
              userSelect: "text",
              padding: "10px"
            }}
          >
            {Body ? <Body /> : null}
            {children ? children : null}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Dialog__Main;
