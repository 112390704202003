import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { tabManager } from "../sidebar/sidebarSlice";
export const playerSlice = createSlice({
  name: "playersearch",
  initialState: {
    playerData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    userId: "",
    recordsShow: [1, 25],
    viewTable: true,
    isVisible: false,
    formData: {},
    docsData: [],
    affiliateBtag: "",
    btagKey: false,
    loader: false,
    isPlayerExist: "",
    alertPopper: false,
    btagAff: '',
    tagName: []
  },
  reducers: {
    setPlayerSearch: (state, action) => {
      state.playerData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setViewTable: (state, action) => {
      state.viewTable = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setAffiliateBtag: (state, action) => {
      state.affiliateBtag = action.payload;
      state.formData = { ...state.formData, ["affiliateBtag"]: action.payload }
    },
    setBtagKey: (state, action) => {
      state.btagKey = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setIsPlayerExist: (state, action) => {
      state.isPlayerExist = action.payload
    },
    setAlertPopper: (state, action) => {
      state.alertPopper = action.payload;
    },
    setBtagAff: (state, action) => {
      state.btagAff = action.payload;
    },
    setTagName: (state, action) => {
      state.tagName = action.payload;
    },
  },
});
export const {
  setPlayerSearch,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setErrorMessage,
  setUserId,
  setRecordsShow,
  setViewTable,
  setIsVisible,
  setFormData,
  setDocsData,
  setAffiliateBtag,
  setBtagKey,
  setLoader,
  setIsPlayerExist,
  setAlertPopper,
  setBtagAff,
  setTagName,
} = playerSlice.actions;

export const getPlayerSearchList =
  (itemperpagevalue, pagenumbervalue, params) => (dispatch) => {
    var obj = params;
    const arr = [];
    for (let key in obj) {
      if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
        arr.push(obj[key]);
      }
    }
    const isTrue = false; //arr.length >= 2;
    const pageNumber = isTrue ? 0 : itemperpagevalue;
    var url =
      `${playerapi.getPlayerSearch}` +
      "?itemsPerPage=" +
      pagenumbervalue +
      "&pageNumber=" +
      pageNumber;
    const isState = obj.hasOwnProperty("state"); // is State Exist In Payload
    apiCaller(url, "POST", obj).then((response) => {
      if (response !== null && response) {
        const total = response.data.totalRecords;
        isTrue && dispatch(setPaginationFirstValue(1));
        if (isState && response.data?.data?.length === 0) {
          dispatch(setAlertPopper(true));
        } else {
          dispatch(setPlayerSearch(response.data));
        }
        if (response.status == 200) {
          isTrue && dispatch(setRecordsShow([1, total]));
          dispatch(setErrorMessage(response.message));
        }
        dispatch(setIsVisible(true));
        dispatch(setLoader(false));
      }
    }).catch(err => {
      dispatch(setPlayerSearch([]));
      dispatch(setIsVisible(true));
      dispatch(setLoader(false));
    });
  };

export const getPlayer = (pid, history) => (dispatch, getState) => {
  const url = `${playerapi.getPlayer}/${pid}`;
  const { sidebar, playersearch, dashboard } = getState();

  if (!isNaN(pid)) {
    apiCaller(url, "GET").then(res => {

      if (res.data === null) {
        history.push(dashboard.defaultMainPath);
      }
      if (res.data !== null) {
        dispatch(tabManager(pid, sidebar.tabStack));
        dispatch(setIsPlayerExist(pid));
      }


    }).catch((err) => {
      if (err.statusCode == 400) {
        history.push(dashboard.defaultMainPath);
      }
    })
  } else {
    // window.location.pathname = dashboard.defaultMainPath;
    history.push(dashboard.defaultMainPath);
  }
}

export const getTags = (tags) => (dispatch) => {
  const url = playerapi.getTags
  apiCaller(url, "GET")
    .then((response) => {
      if (response) {
        const transformedResponse = response.slice(0, 41).map((value, index) => ({
          id: index + 1,
          label: value.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()), // Convert underscore to space and capitalize each word
          value: value
        }));
        dispatch(setTagName(transformedResponse));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export default playerSlice.reducer;
