export const HistoryData = [
  { id: 1, label: "LOGIN", value: "LOGIN" },
  { id: 2, label: "LOGOUT", value: "LOGOUT" },
  { id: 3, label: "SIGNUP", value: "ACCOUNT_OPEN" },
  { id: 4, label: "Account Status Change", value: "ALL" },
];


export const ErrorStackData = [
  {
    id: 1,
    label: "Must be atleast 8 chanracters!",
    key: "len"
  },
  {
    id: 2,
    label: "Must contain atleast 1 number",
    key: "num"
  },
  {
    id: 3,
    label: "Must contain atleast 1 Letter in Capital Case!",
    key: "caps"
  },
  {
    id: 4,
    label: "Must contain atleast 1 Letter in Small Case!",
    key: "small"
  },
  {
    id: 5,
    label: "Must contain atleast 1 Special Character!",
    key: "special"
  }
]