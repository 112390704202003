import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Input from "../Common/common_inputs/Input";
import { currency } from '../../components/Common/data/CreateBrand/createbrandData'
import { getCurrencylist, getAddCurrency } from "./Currencyslice"
import No__Data__Found from "../Common/table/No__Data__Found";
import { regexType } from "../../sharedfiles/helper"
import { COMMON_CONSTANTS, CURRENCY_CONVERT_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { CurrencyConvertInfo, CurrencyIP } from "../../sharedfiles/tooltipMsgs";
const CurrencyConvert = (props) => {
  const dispatch = useDispatch();
  const [currencyFrom, setCurrencyFrom] = useState("")
  const [currencyTo, setCurrencyTo] = useState("")
  const [conversionrate, setConversionrate] = useState("")
  const [fromError, setFromError] = useState(false)
  const [toError, setToError] = useState(false)
  const [conversionErr, setConversionErr] = useState("")
  const [editable, setEditable] = useState(false)
  const [rowKey, setRowKey] = useState("")
  const [selectedItem, setSelectedItem] = useState({})
  const [updateCoversionRate, setUpdateCoversionRate] = useState("")
  const [updateDataErr, setUpdateDataErr] = useState("")


  const handleFrom = (e) => {
    if (e.target.value === "") {
      setFromError(true)
    } else {
      setFromError(false)
    }
    setCurrencyFrom(e.target.value)
  }

  const handleTo = (e) => {
    e.target.value === "" ? setToError(true) : setToError(false);
    if (e.target.value !== "") {
      setCurrencyTo(e.target.value)
    }

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    validationHandler(value)
    if (name == "conversionrate") {
      setConversionrate(e.target.value)
    }
  }

  const validationHandler = (value) => {
    const absValue = `${Math.abs(value)}`
    const key = value === "" ? value : absValue
    switch (key) {
      case "":
        setConversionErr("Conversion rate is required")
        break;
      case "0":
        setConversionErr("Value Must be greater than 0")
        break;
      default:
        setConversionErr("")
    }
  }

  const editValidation = (value) => {
    if (value === "") {
      setUpdateDataErr("Value should not be empty")
    }
    else if (Number(value) === 0) {
      setUpdateDataErr("Value Must be greater than 0")
    } else if (value > 0) {
      setUpdateDataErr("")
    }
  }

  const emptystate = () => {
    setCurrencyFrom("")
    setCurrencyTo("")
    setConversionrate("")

  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    editValidation(value)
    const regEx = regexType.decimalafternum;
    if (value === "" || regEx.test(value)) {
      setUpdateCoversionRate(value);
    }
  }
  const onSubmit = () => {
    if (currencyFrom == "") {
      setFromError(true)
    }
    if (currencyTo == "") {
      setToError(true)
    }
    ;
    validationHandler(conversionrate);
    const coversionAccvept = conversionrate !== "" && Math.abs(conversionrate) !== 0;
    if (currencyFrom !== "" && currencyTo !== "" && coversionAccvept) {
      const data = {
        currencyFrom: currencyFrom,
        currencyTo: currencyTo,
        rateOfExchange: conversionrate,
      }
      dispatch(getAddCurrency(data));
      emptystate()
    }


  }

  const enableEdit = (item, id) => {
    setRowKey(id)
    setEditable(true)
    setSelectedItem(item);
    setUpdateCoversionRate(item.rateOfExchange)
    editValidation(item.rateOfExchange)
  }


  const updateRecords = (e) => {
    e.preventDefault();
    editValidation(updateCoversionRate)
    const updatedPayload = {
      currencyFrom: selectedItem.currencyFrom,
      currencyId: selectedItem.id,
      currencyTo: selectedItem.currencyTo,
      rateOfExchange: +updateCoversionRate
    }
    dispatch(getAddCurrency(updatedPayload));
    setTimeout(() => dispatch(getCurrencylist()), 200);
    setEditable(false);
  }


  useEffect(() => {
    dispatch(getCurrencylist())
  }, [])

  const headers = [
    "Currency From",
    "Currency To",
    "Conversion Rate",
    "Edit"
  ]

  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-12'>
                <div className='CMS-cardBox' style={{ paddingBottom: "20px" }}>
                  <div className='row'>
                    <div className='col-3'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {CURRENCY_CONVERT_CONSTANTS.header_currencyFrom}
                          &nbsp;&nbsp;
                          <Tooltips info={CurrencyIP.Currency_From} />
                        </div>
                        <div className='CMS-formControl'>
                          <div className='CMS-number-of-files CMS-select'>
                            <select
                              name='currencyFrom'
                              id="currencyfrom"
                              value={currencyFrom}
                              onChange={handleFrom}
                            >
                              <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                              <option value='EUR'>{CURRENCY_CONVERT_CONSTANTS.EUR}</option>
                            </select>
                          </div>
                        </div>
                        {fromError && (
                          <div className='text-danger'>
                            {CURRENCY_CONVERT_CONSTANTS.currency_ErrMsg}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-3'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {CURRENCY_CONVERT_CONSTANTS.header_currencyTo}
                          &nbsp;&nbsp;
                          <Tooltips info={CurrencyIP.Currency_To} />
                        </div>
                        <div className='CMS-formControl'>
                          <div className='CMS-number-of-files CMS-select'>
                            <select
                              name='currencyTo'
                              id="currencyTo"
                              value={currencyTo}
                              onChange={handleTo}
                            >
                              <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                              {currency.sort((a, b) => a.label.localeCompare(b.label)).map(
                                (item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.value}
                                      id={item.id}
                                    >
                                      {item.label}
                                    </option>
                                  );
                                }
                              )}

                            </select>
                          </div>

                        </div>
                        {toError && (
                          <div className='text-danger'>
                            {CURRENCY_CONVERT_CONSTANTS.currency_ErrMsg}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-3'>
                      <Input
                        title={"Conversion Rate"}
                        name={"conversionrate"}
                        value={conversionrate}
                        setValue={(e) => handleChange(e)}
                        placeholder={"Conversion Rate"}
                        ids={"conversionrate"}
                        regex={regexType.decimalafternum}
                        errorMsg={conversionErr}
                        info={CurrencyIP.Conversion_Rate}
                        show={true}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className='CMS-btnContainer'>
              <button
                onClick={() => onSubmit()}
                className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
                id="convertSubmit"
                type='submit'
              >
                {COMMON_CONSTANTS.submit}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='CMS-box '>
        <div className='CMS-table CMS-table-triped'>
          <table>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{item}</p>
                      <Tooltips info={`${CurrencyConvertInfo[index]}`} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {Object.keys(props.currencyConversion).length > 0 && props.currencyConversion.length > 0 ? (
                [...props.currencyConversion].sort((a, b) => {
                  const c = a.id;
                  const d = b.id;
                  return c - d;
                })?.map((item, index) => {
                  return (
                    <tr key={`${item.currencyFrom}_${index}`}>
                      <td>{item.currencyFrom}</td>
                      <td>{item.currencyTo}</td>

                      <td>
                        {editable && rowKey === index ?
                          <input
                            type='text'
                            name='rateOfExchange'
                            id='rateOfExchange'
                            className='table_form_input custom_input'
                            defaultValue={item.rateOfExchange}
                            value={updateCoversionRate}
                            onChange={(e) => handleInput(e, index)}
                          /> : item.rateOfExchange}
                        {rowKey === index ? <div className='text-danger'>{updateDataErr}</div> : ""}

                      </td>


                      <td>
                        {editable && rowKey === index ? (
                          <button
                            className={`CMS-btn CMS-btnQuaternary active vh_laign_center success_repl mx_center ${updateDataErr ? "disable-button disabled_events" : ""}`}
                            onClick={(e) => updateRecords(e)}
                            id='currencySave'
                          >
                            <span className='material-icons save'>{COMMON_CONSTANTS.span_saveIcon}</span>
                            &nbsp;{COMMON_CONSTANTS.save}
                          </button>
                        ) : (
                          <button
                            className='CMS-btn CMS-btnQuaternary active vh_laign_center mx_center'
                            onClick={() => enableEdit(item, index)}
                            id='currencyEdit'
                          >
                            <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                            &nbsp;{COMMON_CONSTANTS.edit}
                          </button>
                        )}
                      </td>
                    </tr>

                  );
                })

              ) : (
                <No__Data__Found colspan={4} />
              )}
            </tbody>
          </table>
        </div>

      </div>





    </React.Fragment>





  )
}
const mapStateToProps = (state) => {
  return {
    currencyConversion: state.currencyconversion.currencyConversion,
    successMessage: state.currencyconversion.successMessage,

  };
};
export default connect(mapStateToProps)(CurrencyConvert);