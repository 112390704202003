import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RISK_MANAGEMENT_CONSTANTS } from '../../sharedfiles/Constants'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import No__Data__Found from '../Common/table/No__Data__Found'
import Table from '../Common/table/Table'
import { getFraudIpUsers, getFraudScrubIPs, setIsPrevUserRespDupeCids, setIsUserIpVisible, setOpenDialouge, setPaginationFirstValSameIp, setPaginationSecondValSameIp, setPrevPayload, setSameIpIsVisible, setSameIpRecordShow, setUserIpData, setUserIsVisible, setUserPageOneValue, setUserPageSecondValue, setUserRecordShow, setUserSameIpVisible, setdisableBtnRiskMgt } from './RiskmgmtSlice'
import Tooltips from '../Common/Tooltip'
import { RiskMgmnt } from '../../sharedfiles/tooltipMsgs'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Vpagination from '../../sharedfiles/Vpagination'
import { userIpDates } from '../Common/data/mapData'
import Datepicker from '../Common/common_inputs/DatePicker'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import { MomentUTCDateTimeEnd, MomentUTCDateTimeToday, cleanObject, getDateTimeFormateInvalidDate } from '../../sharedfiles/helper'
import Input from '../Common/common_inputs/Input'
import Skelton from '../Common/skelton/Skelton'

const UsersIp = (props) => {
    // const [openDialog, setOpenDialog] = useState(false)
    const [tableData, setTableData] = useState('')
    const [csvSameIpData, setCsvSameIpData] = useState([])
    const [pdfSameIpBody, setPdfSameIpBody] = useState([])
    const [csvUserData, setCsvUserData] = useState([])
    const [pdfUserBody, setPdfUserBody] = useState([])
    const [mounted, setMounted] = useState(false);
    const [formFields, setFormFields] = useState({
        startDate: '',
        endDate: '',
        user: ''
    });
    const [dateErr, setDateErr] = useState({
        startDate: '',
        endDate: '',
    })
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [ip, setIp] = useState('')
    const dispatch = useDispatch()
    const sameIps = useSelector((state) => state.riskManagement.sameIps)
    const sameIpUsers = useSelector((state) => state.riskManagement.sameIpUsers)
    const pageOneValue = useSelector((p) => p.riskManagement.paginationFirstValSameIp)
    const pageSecondValue = useSelector((p) => p.riskManagement.paginationSecondValSameIp)
    const isUserIpVisible = useSelector((p) => p.riskManagement.sameIpIsVisible)
    const recordShow = useSelector((p) => p.riskManagement.sameIpRecordShow)
    const userPageOneValue = useSelector((p) => p.riskManagement.userPageOneValue)
    const userPageSecondValue = useSelector((p) => p.riskManagement.userPageSecondValue)
    const userIsVisible = useSelector((p) => p.riskManagement.userIsVisible)
    const userRecordShow = useSelector((p) => p.riskManagement.userRecordShow)
    const openDialouge = useSelector((p) => p.riskManagement.openDialouge)
    const userIpData = useSelector((p) => p.riskManagement.userIpData)
    const prevPayload = useSelector((p) => p.riskManagement.prevPayload)
    const { isPrevUserResp, disableBtnRiskMgt, userSameIpVisible } = useSelector((state) => state.riskManagement);

    const ipOpen = (ip) => {
        dispatch(setUserIsVisible(true));
        dispatch(getFraudIpUsers(ip, 0, 25))
        dispatch(setOpenDialouge(true))
        setIp(ip)
    }

    const onClose = () => {
        dispatch(setOpenDialouge(false))
    }

    const theader = [
        [
            "CUSTOMERS FROM IP"
        ],
    ];

    const tbHeader = [
        [
            "IP ADDRESS",
            "NUMBER OF USERS",
            "CREATED DATE"
        ]
    ]
    const info = [
        RiskMgmnt.Cid_frmIp
    ]


    //SAME IP TABLE PDF//
    const onIpTableUpdate = () => {
        const tbody = sameIps && sameIps.data &&
            Array.isArray(sameIps.data) ? sameIps.data.map((item) => [item.ip, item.count, getDateTimeFormateInvalidDate(item.createdDate)]) : []
        setPdfSameIpBody(tbody);
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tbHeader[0], ...tbody] : [tbHeader[0], []];
        setCsvSameIpData(data);
    }

    const SameIpexportPDF = () => {
        const title = "Users With Same Ip Report";
        const styles = {
            marginTop: "10px"
        };
        const customHeader = 12
        exportPDFC(title, tbHeader, pdfSameIpBody, {});
    }
    //SAME IP TABLE PDF//


    //NUM OF USER POPUP DATA//
    const update = () => {
        const tbody = sameIpUsers && sameIpUsers.data &&
            sameIpUsers.data.length > 0 ? sameIpUsers.data.map((item) => [item.custIds]) : []
        setTableData([theader, info, ...tbody])
        setPdfUserBody(tbody);

        const data = Array.isArray(tbody) && tbody.length > 0 ? [theader[0], ...tbody] : [theader[0], []];
        setCsvUserData(data);
    }
    const UserexportPDF = () => {
        const title = "Customer From Ip Report";
        const styles = {
            marginTop: "10px"
        };
        const customHeader = 12
        exportPDFC(title, theader, pdfUserBody, styles, customHeader);
    }
    //NUM OF USER POPUP DATA//
    const tableStyles = {
        0: "text-center"
    };

    useEffect(() => {
        dispatch(setUserIpData(userIpData));
        if (sameIpUsers?.length === 0) {
            dispatch(getFraudScrubIPs(0, 25, formFields.user, {}))

        } else update(sameIpUsers);
        stateHolder();
    }, [])

    useEffect(() => {
        update();
    }, [sameIpUsers])

    useEffect(() => {
        onIpTableUpdate()
    }, [sameIps])

    const callPageApi = (pageNumber, itemsPerPage) => {
        const payload = {
            ...prevPayload,
            createdToDate: formFields.endDate !== "" ? MomentUTCDateTimeEnd(formFields.endDate) : '',
            createdFromDate: formFields.startDate !== "" ? MomentUTCDateTimeToday(formFields.startDate) : "",
        }
        dispatch(setSameIpIsVisible(true));
        dispatch(setUserSameIpVisible(false));
        dispatch(getFraudScrubIPs(pageNumber - 1, itemsPerPage, formFields.user ? formFields.user : '', payload))
    }

    const callPagePopApi = (pageNumber, itemsPerPage) => {
        dispatch(setUserIsVisible(true));
        dispatch(getFraudIpUsers(ip, pageNumber - 1, itemsPerPage))
    }

    // useEffect(() => {
    //     var btnTimer;
    //     if (disableBtnRiskMgt) {
    //         btnTimer = setTimeout(() => {
    //             setMounted(true)
    //             dispatch(setdisableBtnRiskMgt(false))
    //         }, 2500)
    //     };
    //     return () => clearTimeout(btnTimer);
    // }, [disableBtnRiskMgt]);

    function stateHolder() {
        const isFormDataExist = Object.keys(userIpData).length;
        if (isFormDataExist) {
            Object.entries(userIpData).map(([key, value]) =>
                setFormFields((prevState) => {
                    return {
                        ...prevState,
                        [key]: value,
                    };
                })
            );
        }
    }

    function formDataToSliceSetter(key, val) {
        const newEntry = { ...userIpData, [key]: val };
        dispatch(setUserIpData(newEntry));
    };

    // const searchHandler = (value) => {
    //     if (isPrevUserResp && isPrevUserResp === value) return;
    //     dispatch(setIsPrevUserRespDupeCids(value))
    //     if (value.trim().length > 0) {
    //         dispatch(getFraudScrubIPs(0, 25, value, {}))
    //     }
    // }
    const setDateHandler = (value, name) => {
        formDataToSliceSetter(name, value);
        setFormFields({ ...formFields, [name]: value })
        if (name === "startDate" && value) {
            errorSetter("startDate", "");
        }
    };

    const handleUser = (e) => {
        const { name, value } = e.target;
        setFormFields((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        formDataToSliceSetter(name, value);
        const updatedFormData = { ...userIpData, [name]: value };
        dispatch(setUserIpData(updatedFormData));
    }

    function errorSetter(name, value) {
        setDateErr((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    }


    const onResetBtn = () => {
        setFormFields({
            startDate: '',
            endDate: '',
            user: ''
        })
        setDateErr('')
        dispatch(getFraudScrubIPs(0, 25, '', {}))
        dispatch(setPaginationFirstValSameIp(1))
        dispatch(setPaginationSecondValSameIp(25))
        dispatch(setSameIpRecordShow([1, 25]))
        dispatch(setPrevPayload({}))
        dispatch(setUserIpData({}));
        dispatch(setUserSameIpVisible(false));
    }

    const onSearch = () => {
        setButtonDisabled(true);
        const plainPayload = {
            createdFromDate: formFields.startDate !== "" ? MomentUTCDateTimeToday(formFields.startDate) : "",
            createdToDate: formFields.endDate !== "" ? MomentUTCDateTimeEnd(formFields.endDate) : '',
        }
        let payload = cleanObject(plainPayload)
        dispatch(setPrevPayload(plainPayload))
        if (formFields.user !== '') {
            setDateErr('')
            dispatch(getFraudScrubIPs(0, 25, formFields.user ? formFields.user : '', payload))
        }
        else if ( formFields.startDate === '' ) {
            errorSetter("startDate", "Start Date is required");
        }
        else {
            dispatch(getFraudScrubIPs(0, 25, formFields.user ? formFields.user : '', payload))
            errorSetter("startDate", "");
        }

        setTimeout(() => {
            setButtonDisabled(false);
        }, 3000);
    }

    return (
        <>
            <Dialog__Main
                title={"CUSTOMERS FROM IP"}
                dOpen={openDialouge}
                dClose={() => onClose()}
                width={'700px'}
            >
                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={sameIpUsers.data}
                            totalRecords={sameIpUsers.totalRecords}
                            paginationFirstValue={userPageOneValue}
                            paginationSecondValue={userPageSecondValue}
                            setPaginationFirstValue={setUserPageOneValue}
                            setPaginationSecondValue={setUserPageSecondValue}
                            recordsShow={userRecordShow}
                            setRecordsShow={setUserRecordShow}
                            isVisible={userIsVisible}
                            setIsVisible={setUserIsVisible}
                            csvData={csvUserData}
                            exportPDF={UserexportPDF}
                            callApi={callPagePopApi}
                            csvFileName={"Customer From Ip Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <Table
                    tableData={tableData}
                    linkIndex={0}
                    styles={tableStyles}
                />
            </Dialog__Main>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <div className='CMS-tabContent'>
                        <div className="mt-50"></div>
                        <div className="CMS-box CMS-box-content">
                            <div className='row'>
                                {userIpDates.map((item, ind) => {
                                    const minDate = [null, formFields.startDate];
                                    const max = formFields.endDate ? formFields.endDate : new Date();
                                    const maxDate = [max, new Date()];
                                    const values = [formFields.startDate, formFields.endDate];
                                    return (
                                        <div className='col-3' key={ind}>
                                            <Datepicker
                                                title={item.title}
                                                name={item.name}
                                                value={values[ind]}
                                                setValue={(date, value) => setDateHandler(date, value)}
                                                errorMsg={dateErr[item.name]}
                                                minDate={minDate[ind]}
                                                maxDate={maxDate[ind]}
                                                ids={item.ids}
                                                info={item.info}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="col-3">
                                    <Input
                                        title={"Username"}
                                        name={"user"}
                                        value={formFields.user}
                                        placeholder={"Username"}
                                        setValue={(e) => handleUser(e)}
                                        ids={"userIpUserName"}
                                        info={"showing user name"}
                                        show={true}
                                    // regex={regexType.notZero}
                                    />
                                </div>
                                <div className="ml-15"></div>
                                <ButtonGroup
                                    groupNames={["Search", "Reset"]}
                                    idsearch={"searchAffiliate"}
                                    idreset={"affiliateReset"}
                                    submit={(e) => onSearch(e)}
                                    reset={(e) => onResetBtn(e)}
                                    freezButton={buttonDisabled}
                                />
                            </div>
                        </div>
                        <div className='CMS-filter-result' id='result'>
                            <div className="CMS-pagination">
                                <Vpagination
                                    data={sameIps.data}
                                    totalRecords={sameIps.totalRecords}
                                    paginationFirstValue={pageOneValue}
                                    paginationSecondValue={pageSecondValue}
                                    setPaginationFirstValue={setPaginationFirstValSameIp}
                                    setPaginationSecondValue={setPaginationSecondValSameIp}
                                    recordsShow={recordShow}
                                    setRecordsShow={setSameIpRecordShow}
                                    isVisible={userSameIpVisible}
                                    setIsVisible={setUserSameIpVisible}
                                    csvData={csvSameIpData}
                                    exportPDF={SameIpexportPDF}
                                    callApi={callPageApi}
                                    csvFileName={"Users With Same Ip Report"}
                                    reloadRequired={false}
                                // onclickSearch={searchHandler}
                                // isSearchClicked={true}
                                // isSearch={true}
                                // disableButton={disableBtnRiskMgt}
                                // searchIniMount={mounted}
                                />
                            </div>
                            {!userSameIpVisible ? (<Skelton vLen={5} hLen={7} />) : (<div className='CMS-box '>
                                <div className='CMS-table CMS-table-triped'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <p className='mx-3'>{RISK_MANAGEMENT_CONSTANTS.ipAddress}</p>
                                                        <Tooltips info={RiskMgmnt.IP_Address} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <p className='mx-3'>{RISK_MANAGEMENT_CONSTANTS.no_User}</p>
                                                        <Tooltips info={RiskMgmnt.No_Users} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <p className='mx-3'>{"CREATED DATE"}</p>
                                                        <Tooltips info={RiskMgmnt.createdDate} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sameIps && sameIps.data
                                                && sameIps.data.length > 0 ? (
                                                sameIps.data.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.ip}</td>
                                                            <td className='link_style pointer' onClick={() => ipOpen(item.ip)}>{item.count}</td>
                                                            <td>{item.createdDate ? getDateTimeFormateInvalidDate(item.createdDate) : '-'}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <No__Data__Found colspan={50} />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UsersIp