import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SingleBarGraph = ({ title, barData }) => {
    const options = {
        chart: {
            type: 'column',
            height: 209
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: ['Deposit', 'Freebet', 'Coupon Code'],
            crosshair: true,
            accessibility: {
                description: 'Statistics'
            },
            labels: {
                enabled: false
            }

        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                enabled: false
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        plotOptions: {
            // column: {
            //     pointPadding:0, 
            // },
            series: {
                pointWidth: 30,
                pointPadding: 0,
                dataLabels: {
                    align: 'left',
                    enabled: true,
                    rotation: 270,
                    x: 0,
                    y: -5
                }
            },
        },

        series: [
            {
                name: 'Deposit',
                data: [getUserCount('DEPOSIT')],
                color: '#89375F'
            },
            {
                name: 'Freebet',
                data: [getUserCount('FREE_BET')],
                color: '#CE5959'
            },
            {
                name: 'Coupon Code',
                data: [getUserCount('COUPONCODE')],
                color: '#A6CF98'
            },

        ]
    }
    function getUserCount(bonusType) {
        const userData = barData.find(item => item?.bonusType === bonusType);
        return userData ? userData.userCount : 0;
    }
    return (
        <>
            <div class="CMS-box-graphItem">
                <div class="CMS-box-graphTitle">{title}</div>
                <div class="CMS-graph">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        containerProps={{ id: "CMS-highCharts-statistics" }}
                    />
                </div>
            </div>
        </>
    )
}

export default SingleBarGraph