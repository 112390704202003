import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import BetId from "../BetId/BetId";
import { setUserId } from "../playerTable/playerSearchTableSlice";

import Loading from "../loading/Loading";
import { setOpenDialog, betIdRecords } from "../BetHistory/betHistorySlice";
import { tabManager } from "../sidebar/sidebarSlice";
import {
  fetchBettingTickerData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setRecordsShow,
  setDocsData,
  setPdfBodyData,
} from "./bettingTickerSlice";
import {
  getDateTimeFormate,
  getDecimalNumber,
  formatMoney,
  Currency,
  roundDecimalnum,
} from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { Link } from "react-router-dom";
import Tooltips from "../Common/Tooltip";
import { KYC_CONSTANTS } from "../../sharedfiles/Constants";
import { Betting } from "../../sharedfiles/tooltipMsgs";

const Betticker = (props) => {
  // const [currency, setCurrency] = useState("");
  const [conditon, setCondition] = useState(true);
  const [data, setData] = useState([]);
  const [from, setFrom] = useState("");
  const dispatch = useDispatch();
  const [userId, setUserid] = useState("");
  const SidebarData = { subtitle: "v", path: "/customerId" };
  const [pathId, setPathId] = useState("");
  const [pathName, setPathName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [view, setView] = useState(false)
  const statusType = useSelector((state) => state.bettingticker.statusType);
  const [status, setStatus] = useState("All")
  const [potentialPayout, setPotentiaPayout] = useState('')
  const pageFirstVal = useSelector(
    (state) => state.bettingticker.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.bettingticker.paginationSecondValue
  );
  const recordsToShow = useSelector(
    (state) => state.bettingticker.recordsToShow
  );
  const handleStatusChange = (e) => {
    const value = e.target.value
    setStatus(value)
    dispatch(setPaginationFirstValue(1));
    dispatch(setPaginationSecondValue(25));
    dispatch(setRecordsShow([1, 25]));

  }

  const callApi = () => {
    dispatch(
      fetchBettingTickerData(
        props.paginationFirstValue - 1,
        props.paginationSecondValue,
        from,
        status,
        props.btData
      )
    );
  };
  const tabsChange = (item) => {
    props.dispatch(setUserId(item));
    localStorage.setItem("userId", item);
    dispatch(tabManager(+item, props.tabStack));
  };

  const openDialogHandler = (id, potentialPayout) => {
    props.dispatch(setOpenDialog(true));
    props.dispatch(betIdRecords(id));
    setPotentiaPayout(potentialPayout)
  };

  useEffect(() => {
    if (status !== "") {
      callApi(pageFirstVal, pageSecondVal);
    }
  }, [status]);

  useEffect(() => {
    if (props.btData.data !== undefined) {
      setStatus(props.btData.data[0].betStatus)
      setData(props.btData.data[0].betTicker);
      setFrom(props.btData.data[0].currentTime);
    }
  }, [props.btData]);

  useEffect(() => {

    const dataExist = Object.keys(props.btData).length > 0
    if (!dataExist) {
      callApi();
    } else {
      setPdfBody(props.pdfBodyData);
      setCsvData(props.docsData);
    }
    if (localStorage.getItem("isExist") === "GAMEWORX_PERMISSION") {
      setView(true)
    }
  }, [data])
  useEffect(() => {
    const userId = props.userId ? props.userId : localStorage.getItem("userId");
    setUserid(userId);
  }, [props.userId]);
  const headers = [
    [
      "Placed On",
      "Settled On",
      "Bet Status",
      "BetID",
      "Short Bet ID",
      "Customer ID",
      "Age of Account",
      "Game type",
      "Event Name",
      "Bet Fold",
      "Currency",
      `Stake ${Currency()}`,
      "Odds",
      `Potential Payout${Currency()}`,
      `Payout${Currency()}`,
      "Stake Type"
    ],
  ];

  const info = [
    "Showing The Bet Placed On Dates",
    "Showing The Bet Settled On Dates",
    "Showing Bet Status",
    "Showing BetID",
    "Showing Short Bet Id",
    "Showing Customer ID",
    "Showing Age of Account",
    "Showing Game type",
    "Showing the Event Name",
    "Showing Bet Fold",
    "Showing Currency",
    "Showing Stake",
    "Showing the Odds",
    "Showing Potential Payout",
    "Showing Payout",
    "Showing Stake Type",
    // "Showing Bet Status",
    // "Showing Stake Factor",
    // "Showing Bet Detail",
  ]

  const exportPDF = () => {
    const title = "Betting Ticker Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    let mounting = true;

    if (mounting && props.btData.data !== undefined) {
      const bettingData =
        props.btData.data !== undefined &&
        props.btData.data[0].betTicker.map((p) => [
          p.placedOn,
          p.settledDate,
          p.betStatus,
          p.betId,
          p.shortBetId,
          p.userId,
          p.accountAge,
          p.sportName,
          p.eventName,
          p.betFold,
          p.currencyCode,
          view ? roundDecimalnum(p.stake) : formatMoney(p.stake),
          p.odds,
          view ? roundDecimalnum(p.potentialPayout) : formatMoney(p.payout),
          view ? roundDecimalnum(p.payout) : formatMoney(p.payout),
          p.placeBetType
        ]);

      setPdfBody(bettingData);
      dispatch(setPdfBodyData(bettingData));
      const data = bettingData && Array.isArray(bettingData) && bettingData.length > 0 ? [headers[0], ...bettingData] : [headers[0], []]
      setCsvData(data);
      dispatch(setDocsData(data));
    }

    return () => mounting = false;
  }, [props.btData]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(
      fetchBettingTickerData(pageNumber - 1, itemsPerPage, from, status, props.btData)
    );
  };
  return (
    <React.Fragment>
      <BetId
        data={props.betIdRecords && props.betIdRecords.length > 0 ? props.betIdRecords : []}
        potentialPayout={potentialPayout}
      />
      <div className='CMS-tab-panel active'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result' id='result'>
            <div className='d-flex justify-content-between'>
              <div className='col-md-6 col-lg-4 col-xl-4 mb-20'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div>
                    <div style={{ padding: "12px" }}>
                      {KYC_CONSTANTS.header_status}
                      &nbsp;&nbsp;
                      <Tooltips info={Betting.bet_Status} />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='CMS-formControl'></div>
                    <div className='CMS-number-of-files CMS-select'>
                      <select
                        id='bettickerstatus'
                        name='status'
                        value={status}
                        onChange={(e) => handleStatusChange(e)}
                        className="pointer"
                      >
                        <option value='All'>All</option>
                        <option value='Running'>Running Bets </option>
                        <option value='Settled'>Settled Bets</option>
                        <option value='CashedOut'>Cashed out Bets</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='refresh-page' style={{ cursor: "pointer", marginTop: "2px" }}>
                <span
                  // style={reloadStyle}
                  onClick={() => callApi(props.paginationFirstValue, {})}
                  className='material-icons md-36'
                  data-icon='replay_circle_filled'
                ></span>
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={data}
                  totalRecords={props.btData?.totalRecords}
                  paginationFirstValue={pageFirstVal}
                  paginationSecondValue={pageSecondVal}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={recordsToShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={csvData}
                  exportPDF={exportPDF}
                  callApi={callPageApi}
                  csvFileName={"Betting Ticker Report"}
                />
              </div>
            </div>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped CMS-betTicker'>
                <table>
                  <thead>
                    <tr>
                      {props.betColumn.map((item, index) => {
                        return <th key={item.key}>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item.column}</p>
                            <Tooltips info={info[index]} />
                          </div>
                        </th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!props.loader &&
                      data &&
                      Array.isArray(data) &&
                      data.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.placedOn ? getDateTimeFormate(item.placedOn) : "-"}</td>
                            <td>{item.settledDate ? getDateTimeFormate(item.settledDate) : "-"}</td>
                            <td>{item.betStatus}</td>
                            <td>
                              <Link
                                to={{}}
                                onClick={() => openDialogHandler(item.betId, formatMoney(item.potentialPayout))}
                                style={{ color: "#2d91fc" }}
                              >
                                {item.betId}
                              </Link>
                            </td>
                            <td>{item.shortBetId}</td>
                            <td>
                              <Link
                                to={`/customerid/${item.userId}`}
                                onClick={() => tabsChange(item.userId)}
                                style={{ color: "#2d91fc" }}
                              >
                                {item.userId}
                              </Link>
                            </td>
                            <td>{item.accountAge}</td>
                            <td>{item.sportName}</td>
                            <td>{item.eventName}</td>
                            <td>{item.betFold}</td>
                            <td>{item.currencyCode}</td>
                            <td className='CMS-biggerBets text-right'>
                              {view ? roundDecimalnum(item.stake) : formatMoney(item.stake)}
                            </td>
                            <td>{item.odds}</td>
                            <td className='text-right'>
                              {view ? roundDecimalnum(item.potentialPayout) : formatMoney(item.potentialPayout)}
                            </td>
                            <td className='text-right'>
                              {view ? roundDecimalnum(item.payout) : formatMoney(item.payout)}
                            </td>
                            <td>{item.placeBetType}</td>
                            {/* <td>{item.betStatus}</td>
                            <td className='CMS-stakeFactor-more-than-1'>
                              {getDecimalNumber(item.stakeFactor)}
                            </td>
                            <td>{item.betDetails}</td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={15}>
                          <Loading
                            isLoader={props.loader}
                            isNoData={!props.loader && data.length === 0}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    paginationFirstValue: state.bettingticker.paginationFirstValue,
    paginationSecondValue: state.bettingticker.paginationSecondValue,
    btData: state.bettingticker.bettingTickerData,
    userId: state.playersearch.userId,
    betIdRecords: state.betHistorySliceReducer.betIdRecords,
    betColumn: state.bettingticker.betColumn,
    loader: state.bettingticker.loader,
    isVisible: state.bettingticker.isVisible,
    docsData: state.bettingticker.docsData,
    pdfBodyData: state.bettingticker.pdfBodyData,
    tabStack: state.sidebar.tabStack
  };
}
export default connect(mapStateToProps)(Betticker);
