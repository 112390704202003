import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer } from "../../sharedfiles/helper";

export const FraudScrubSlice = createSlice({
  name: "fraudscrub",
  initialState: {
    loggedIps: [],
    fraudUsers: [],
    errorMsg: "",
    isHightlight: false,
  },
  reducers: {
    setLoggedIps: (state, action) => {
      state.loggedIps = action.payload;
    },
    setFraudUsers: (state, action) => {
      state.fraudUsers = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
    setIsHighlight: (state, action) => {
      state.isHightlight = action.payload;
    },
  },
});

export const { setLoggedIps, setFraudUsers, setErrorMsg, setIsHighlight } =
  FraudScrubSlice.actions;

export const getLoggedIps = (userId) => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net:443/player/fraud/1000054
  if (userId !== undefined && userId !== null) {
    dispatch(setErrorMsg(""));
    const url = playerapi.fraudScrub(userId);
    apiCaller(url, "GET", null)
      .then((res) => {
        dispatch(setLoggedIps(res.data));
        const isFraud =
          res.data !== null &&
          res.data !== undefined &&
          res.data.length > 0 &&
          res.data.some((item) => item.count > 1);
        dispatch(setIsHighlight(isFraud));
        if (res.status == 400) {
          dispatch(setErrorMsg(res.msg));
        }
      })
      .catch((err) => {
        dispatch(setErrorMsg(messageEnhancer(err.response)));
        dispatch(setIsHighlight(false));
      });
  }
};

export const getFraudUsersByIp = (ip) => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net/player/fraud/122.183.52.170/players
  //dev.platform-ups.sportsit-tech.net:443/player/fraud/122.183.52.170/players/
  if (ip !== undefined && ip !== null) {
    const url = playerapi.fraudScrubIp(ip);
    apiCaller(url, "GET", null)
      .then((res) => dispatch(setFraudUsers(res.data)))
      .catch((err) => console.log(err));
  }
};

export default FraudScrubSlice.reducer;
