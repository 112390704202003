import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
  playerapi,
  betApi,
  bettingApi,
  setBaseUrl,
} from "../../sharedfiles/EndpointConfig";

export const PlayerBetHistorySlice = createSlice({
  name: "playerBetHistorySlice",
  initialState: {
    playerBetHistoryData: [],
    userData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    recordsShow: [1, 25],
    isVisible: false,
    betAudit: [],
  },
  reducers: {
    setplayerBetHistoryData: (state, action) => {
      state.playerBetHistoryData = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsvisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setBetAudit: (state, action) => {
      state.betAudit = action.payload;
    },
  },
});

export const {
  setplayerBetHistoryData,
  setUserData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsvisible,
  setBetAudit,
} = PlayerBetHistorySlice.actions;

//dev.platform-ups.sportsit-tech.net:443/bet/1000019/betdetails
export const getBetUserData = (playerId) => (dispatch) => {
  const url = `${playerapi.getPlayerBetHistory}` + playerId + "/betdetails";

  if (playerId !== "") {
    apiCaller(url, "POST", {})
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch((err) => console.log(err));
  }
};

//dev.platform-ups.sportsit-tech.net:443/bet/history?pageNumber=0&itemPerPage=25
export const getPlayerBetHistory =
  (pageNum, itemPerPage, { betStatus, userId }) =>
    (dispatch) => {
      setBaseUrl();
      let obj =
        betStatus != ""
          ? {
            betStatus: betStatus,
            userId: userId,
          }
          : {
            userId: userId,
          };

      const url =
        `${bettingApi().getBetHistoryApi}` +
        "?pageNumber=" +
        pageNum +
        "&itemPerPage=" +
        itemPerPage;

      apiCaller(url, "POST", obj)
        .then((res) => {
          dispatch(setplayerBetHistoryData(res.data));
          dispatch(setIsvisible(true));
        })
        .catch((err) => console.log(err));
    };

export const getBetAuditDetails = (params) => (dispatch) => {
  var url = `${betApi.getBetAudit}` + params + "/audit";
  // var url = `${betApi.getBetAudit}` +"92a6b45e-d4c8-413a-9cbf-a1818131a66b"+"/audit";
  if (params !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data !== undefined) {
        if (response.status == 200) {
          dispatch(setBetAudit(response.data));
        }
      }
    });
  }
};
export default PlayerBetHistorySlice.reducer;
