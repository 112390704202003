import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { communicationApis } from "../../sharedfiles/EndpointConfig";
import { fileCallerPUTApi } from "../../api/apiCallerNew";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const TemplateSlice = createSlice({
    name: "TemplateSlice",
    initialState: {
        templateData: [],
    },
    reducers: {
        setTemplateData: (state, action) => {
            state.templateData = action.payload
        }
    }
});

export const {
    setTemplateData,
} = TemplateSlice.actions

export const getNotyTemplateByType = (params) => (dispatch) => {
    const url = communicationApis.getNotyTemplateByType
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            dispatch(setTemplateData(res.data))
        }
    }).catch((err) => {
        console.log(err)
    })
}

export const updateNotyTemplate = (params) => (dispatch) => {
    const url = communicationApis.updateNotyTemplate
    apiCaller(url, "PUT", params)
        .then((res) => {
            if (res.status === 200) {
                toaster.success("SUCCESS")
            }
        }).catch((err) => toaster.error("FAILED"))
}

export const updateWelcomeNotyTemplate = (params) => (dispatch) => {
    const url = communicationApis.updateWelcomeNotyTemplate
    fileCallerPUTApi(url, params)
        .then((res) => {
            if (res.status === 200) {
                toaster.success("SUCCESS")
            }
        }).catch((err) => toaster.error("FAILED"))
}

export default TemplateSlice.reducer
