import { createSlice } from "@reduxjs/toolkit";
import { SidebarData, extractedSubTabs, sidebarNavTabs } from "../SidebarData"
export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    displayValue: false,
    sidebarTabs: JSON.parse(localStorage.getItem("tab")) || [],
    openTabs: JSON.parse(localStorage.getItem("openTabs")) || ["MANAGEMENT REPORTING"],
    tabStack: JSON.parse(localStorage.getItem("tabStack")) || [],
    activeTab: JSON.parse(localStorage.getItem("activeTab")) || "DASHBOARD",
  },
  reducers: {
    setdisplayValue: (state, action) => {
      state.displayValue = action.payload;
    },
    setSidebarTabs: (state, action) => {
      state.sidebarTabs = action.payload;
      localStorage.setItem("tab", JSON.stringify(state.sidebarTabs));
    },
    setOpenTabs: (state, action) => {
      state.openTabs = action.payload;
      localStorage.setItem("openTabs", JSON.stringify(action.payload));
    },
    setTabStack: (state, action) => {
      state.tabStack = action.payload;
      localStorage.setItem("tabStack", JSON.stringify(action.payload));
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      localStorage.setItem("activeTab", JSON.stringify(action.payload));
    },
  },
});

export const {
  setdisplayValue,
  setSidebarTabs,
  setOpenTabs,
  setTabStack,
  setActiveTab
} = sidebarSlice.actions;


export const Accords = (searchKey, tabStack) => (dispatch, getState) => {
  const { sidebar } = getState();
  const openTabs = sidebar.openTabs || [];
  const sidebarAccordions = SidebarData.map(item => {
    return {
      [item["title"]]: item.subNav
    }
  })
  let finalData = {}
  const keys = [];
  let values = [];
  let keyTitle = "";
  let openAccordions = [];
  for (let item of sidebarAccordions) {
    keys.push(Object.keys(item));
    values.push(Object.values(item));
    finalData = { ...finalData, [Object.keys(item)[0]]: Object.values(item)[0] }
  }
  Object.entries(finalData).map(([key, keyVal]) => {
    if (keyVal.some(keys => keys.subtitle === searchKey)) {
      keyTitle = key
    } else {
      return;
    }
  })
  if (openTabs.includes(keyTitle)) {
    openAccordions = openTabs;
  } else {
    openAccordions = [...openTabs, keyTitle];
  }
  // const subtitleKeys = tabStack.length > 0 ? tabStack.map(key => key.subtitle) : [];
  // let tabsExistInAccordion = tabStack.length > 0 && keyTitle ? finalData[keyTitle].some(item => subtitleKeys.includes(item.subtitle)) : false;
  // STATE UPDATION //
  dispatch(setOpenTabs(openAccordions));
  // STATE UPDATION //

  return finalData;
}

export const pathFinderByPermission = (permission) => {
  const subTab = sidebarNavTabs.find(tab => tab.permissionName === permission);
  return subTab;
}


// export const tabManager = (key, activeTabs, close, history) => (dispatch, getState) => {
//   console.log("key: ", key);
//   debugger
//   if (key !== "/selflogout") {
//     const { sidebar } = getState();
//     const dataType = typeof key === typeof "hello";
//     const keyType = key && dataType ? key[0] === "/" ? "path" : "subtitle" : "subtitle";
//     const pathsList = sidebarNavTabs.map(tab => tab.path);
//     const isValidPath = () => {
//       return keyType === "subtitle" ? keyType === "subtitle" : keyType === "path" && pathsList.includes(key);
//     }

//     if (isValidPath()) {
//       const tabSource = sidebarNavTabs.length > 0 ? [...sidebarNavTabs] : [];
//       let tabStack = [];
//       let activeTabInStack = "";
//       if (Array.isArray(activeTabs) && activeTabs.length > 0) {
//         const checkTabExist = activeTabs.some(tab => tab[keyType] === key);
//         if (checkTabExist) {
//           tabStack = [...activeTabs];
//         } else {
//           const newTab = dataType ? tabSource.filter(tab => tab[keyType] === key) : [{ subtitle: key, path: `/customerid/${key}` }];
//           tabStack = [...activeTabs, ...newTab];
//         }
//       } else {
//         tabStack = dataType ? tabSource.filter(tab => tab[keyType] === key) : [{ subtitle: key, path: `/customerid/${key}` }];
//       }
//       if (close) {
//         tabStack = activeTabs.filter(tab => tab[keyType] !== key);
//         const tabTobeRemoved = activeTabs.find(tab => tab[keyType] === key);
//         const tabToBeRemovedTitle = tabTobeRemoved[keyType];
//         const previousActiveTab = sidebar.activeTab;
//         if (tabStack.length > 0) {
//           if (previousActiveTab === tabToBeRemovedTitle) {
//             activeTabInStack = tabStack.at(tabStack.length - 1)["subtitle"]
//             let incomingTabPath = tabStack.at(tabStack.length - 1)["path"];
//             history && history.push(incomingTabPath)
//           } else {
//             activeTabInStack = previousActiveTab;
//           }
//         } else {
//           const defaultPage = JSON.parse(localStorage.getItem("defaultHome")) || "DASHBOARD";
//           activeTabInStack = defaultPage;
//           tabStack = [tabSource.find(tab => tab.subtitle === activeTabInStack)];
//           const defaultMainPath = JSON.parse(localStorage.getItem("defaultMainPath"));

//           history && history.push(defaultMainPath);
//         }
//       } else {
//         activeTabInStack = dataType ? keyType && keyType ? tabSource.find(tab => tab[keyType] === key)["subtitle"] : "" : key;
//         history && history.push(key);
//       }
//       dispatch(Accords(activeTabInStack, tabStack));
//       dispatch(setTabStack(tabStack));
//       dispatch(setActiveTab(activeTabInStack));
//     } else {
//       const defaultMainPath = localStorage["defaultMainPath"] ? JSON.parse(localStorage.getItem("defaultMainPath")) : "/dashboard"
//       history && history.push(defaultMainPath);
//     }

//   }
// }

export const tabManager = (key, activeTabs, close, history, isSuperSub = false) => (dispatch, getState) => {
  if (key !== "/selflogout") {
    const { sidebar } = getState();
    const dataType = typeof key === typeof "hello";
    const keyType = key && dataType ? key[0] === "/" ? "path" : "subtitle" : "subtitle"
    // const pathsList = sidebarNavTabs.map(tab => tab.path);
    const pathsList = []
    sidebarNavTabs.filter((tab) => {
      if (tab.path) {
        pathsList.push(tab.path)
      } else {
        tab.subTabs.filter(subTab => {
          if (subTab.path) {
            pathsList.push(subTab.path)
          }
        })
      }
    })
    const isValidPath = () => {
      const switchKeyType = isSuperSub ? "subtitle" : "subtitle";
      return keyType === switchKeyType ? keyType === switchKeyType : keyType === "path" && pathsList.includes(key);
    }

    if (isValidPath()) {
      const tabSource = sidebarNavTabs.length > 0 ? [...sidebarNavTabs, ...extractedSubTabs] : []
      let tabStack = [];
      let activeTabInStack = "";
      if (Array.isArray(activeTabs) && activeTabs.length > 0) {
        const checkTabExist = activeTabs.some(tab => tab[keyType] === key);
        if (checkTabExist) {
          tabStack = [...activeTabs];
        } else {
          const newTab = dataType ? tabSource.filter(tab => tab[keyType] === key) : [{ subtitle: key, path: `/customerid/${key}` }];
          tabStack = [...activeTabs, ...newTab];
        }
      } else {
        tabStack = dataType ? tabSource.filter(tab => tab[keyType] === key) : [{ subtitle: key, path: `/customerid/${key}` }];
      }
      if (close) {
        tabStack = activeTabs.filter(tab => tab[keyType] !== key);
        const tabTobeRemoved = activeTabs.find(tab => tab[keyType] === key);
        const tabToBeRemovedTitle = tabTobeRemoved[keyType];
        const previousActiveTab = sidebar.activeTab;
        if (tabStack.length > 0) {
          if (previousActiveTab === tabToBeRemovedTitle) {
            activeTabInStack = tabStack.at(tabStack.length - 1)["subtitle"]
            let incomingTabPath = tabStack.at(tabStack.length - 1)["path"];
            history && history.push(incomingTabPath)
          } else {
            activeTabInStack = previousActiveTab;
          }
        } else {
          const defaultPage = JSON.parse(localStorage.getItem("defaultHome")) || "DASHBOARD";
          activeTabInStack = defaultPage;
          tabStack = [tabSource.find(tab => tab.subtitle === activeTabInStack)];
          const defaultMainPath = JSON.parse(localStorage.getItem("defaultMainPath"));

          history && history.push(defaultMainPath);
        }
      } else {
        activeTabInStack = dataType ? keyType && keyType ? tabSource.find(tab => tab[keyType] === key)["subtitle"] : "" : key;
        history && history.push(key);
      }
      dispatch(Accords(activeTabInStack, tabStack));
      dispatch(setTabStack(tabStack));
      dispatch(setActiveTab(activeTabInStack));
    } else {
      const defaultMainPath = localStorage["defaultMainPath"] ? JSON.parse(localStorage.getItem("defaultMainPath")) : "/dashboard"
      history && history.push(defaultMainPath);
    }

  }
}

export default sidebarSlice.reducer;
