export const currency = [
  { id: 1, label: "EUR", value: "EUR" },
  { id: 2, label: "INR", value: "INR" },
  { id: 3, label: "USD", value: "USD" },
  { id: 4, label: "GBP", value: "GBP" },
  { id: 5, label: "KSH", value: "KSH" },
  { id: 6, label: "UGX", value: "UGX" },
  { id: 7, label: "NGN", value: "NGN" },
  { id: 8, label: "TZS", value: "TZS" },
  { id: 9, label: "XWM", value: "XWM" },
  { id: 10, label: "AFN", value: "AFN" },
  { id: 11, label: "ZWL", value: "ZWL" },
  { id: 12, label: "ZMW", value: "ZMW" },
  { id: 13, label: "YER", value: "YER" },
  { id: 14, label: "MAD", value: "MAD" },
  { id: 15, label: "XPF", value: "XPF" },
  { id: 16, label: "VND", value: "VND" },
  { id: 17, label: "VED", value: "VED" },
  { id: 18, label: "VEF", value: "VEF" },
  { id: 19, label: "VUV", value: "VUV" },
  { id: 20, label: "UYI", value: "UYI" },
  { id: 21, label: "USN", value: "USN" },
  { id: 22, label: "NZD", value: "NZD" },

  { id: 23, label: "AUD", value: "AUD" },
  { id: 24, label: "PKR", value: "PKR" },
  { id: 25, label: "LKR", value: "LKR" },
  { id: 26, label: "NOK", value: "NOK" },
  { id: 27, label: "SRD", value: "SRD" },
  { id: 28, label: "SGD", value: "SGD" },

  { id: 29, label: "CHF", value: "CHF" },
  { id: 30, label: "FKP", value: "FKP" },
  { id: 31, label: "FJD", value: "FJD" },
  { id: 32, label: "COP", value: "COP" },
  { id: 33, label: "BRL", value: "BRL" },

  { id: 34, label: "ALL", value: "ALL" },
  { id: 35, label: "DZD", value: "DZD" },
  { id: 36, label: "AOA", value: "AOA" },
  { id: 37, label: "XCD", value: "XCD" },
  { id: 38, label: "ARS", value: "ARS" },

  { id: 39, label: "AMD", value: "AMD" },
  { id: 40, label: "AWG", value: "AWG" },
  { id: 41, label: "SHP", value: "SHP" },
  { id: 42, label: "AZN", value: "AZN" },
  { id: 43, label: "BSD", value: "BSD" },
  { id: 39, label: "BHD", value: "BHD" },
  { id: 40, label: "BDT", value: "BDT" },
  { id: 41, label: "BBD", value: "BBD" },
  { id: 42, label: "BYN", value: "BYN" },
  { id: 43, label: "BZD", value: "BZD" },

  { id: 44, label: "XOF", value: "XOF" },
  { id: 45, label: "BMD", value: "BMD" },
  { id: 47, label: "BTN", value: "BTN" },
  { id: 48, label: "BOB", value: "BOB" },
  { id: 49, label: "BAM", value: "BAM" },
  { id: 50, label: "BWP", value: "BWP" },
  { id: 51, label: "BND", value: "BND" },
  { id: 52, label: "BGN", value: "BGN" },
  { id: 53, label: "BIF", value: "BIF" },
  { id: 59, label: "CVE", value: "CVE" },
  { id: 51, label: "KHR", value: "KHR" },
  { id: 53, label: "XAF", value: "XAF" },
  { id: 54, label: "CAD", value: "CAD" },
  { id: 55, label: "KYD", value: "KYD" },
  { id: 56, label: "CLP", value: "CLP" },
  { id: 57, label: "CNY", value: "CNY" },
  { id: 58, label: "KMF", value: "KMF" },
  { id: 59, label: "CDF", value: "CDF" },

  { id: 60, label: "CRC", value: "CRC" },
  { id: 61, label: "HRK", value: "HRK" },
  { id: 62, label: "CUP", value: "CUP" },
  { id: 63, label: "ANG", value: "ANG" },
  { id: 64, label: "CZK", value: "CZK" },
  { id: 65, label: "DKK", value: "DKK" },
  { id: 66, label: "DJF", value: "DJF" },
  { id: 67, label: "DOP", value: "DOP" },
  { id: 68, label: "EGP", value: "EGP" },
  { id: 69, label: "ERN", value: "ERN" },

  { id: 70, label: "SZL", value: "SZL" },
  { id: 71, label: "ETB", value: "ETB" },
  { id: 72, label: "GMD", value: "GMD" },
  { id: 73, label: "GEL", value: "GEL" },
  { id: 74, label: "GHS", value: "GHS" },
  { id: 75, label: "GIP", value: "GIP" },
  { id: 76, label: "GTQ", value: "GTQ" },
  { id: 77, label: "GGP", value: "GGP" },
  { id: 78, label: "GNF", value: "GNF" },
  { id: 79, label: "GYD", value: "GYD" },

  { id: 80, label: "HTG", value: "HTG" },
  { id: 81, label: "HNL", value: "HNL" },
  { id: 82, label: "HKD", value: "HKD" },
  { id: 83, label: "HUF", value: "HUF" },
  { id: 84, label: "ISK", value: "ISK" },
  { id: 85, label: "IDR", value: "IDR" },
  { id: 86, label: "XDR", value: "XDR" },
  { id: 87, label: "IRR", value: "IRR" },
  { id: 88, label: "IQD", value: "IQD" },
  { id: 89, label: "IMP", value: "IMP" },

  { id: 90, label: "ILS", value: "ILS" },
  { id: 91, label: "JMD", value: "JMD" },
  { id: 92, label: "JPY", value: "JPY" },
  { id: 93, label: "JOD", value: "JOD" },
  { id: 94, label: "KZT", value: "KZT" },
  { id: 95, label: "KES", value: "KES" },
  { id: 96, label: "KWD", value: "KWD" },
  { id: 97, label: "KGS", value: "KGS" },
  { id: 98, label: "LAK", value: "LAK" },
  { id: 99, label: "LBP", value: "LBP" },

  { id: 100, label: "LSL", value: "LSL" },
  { id: 101, label: "LRD", value: "LRD" },
  { id: 102, label: "LYD", value: "LYD" },
  { id: 103, label: "MOP", value: "MOP" },
  { id: 104, label: "MGA", value: "MGA" },
  { id: 105, label: "MWK", value: "MWK" },
  { id: 106, label: "MYR", value: "MYR" },
  { id: 107, label: "MVR", value: "MVR" },
  { id: 108, label: "MRU", value: "MRU" },
  { id: 109, label: "MUR", value: "MUR" },


  { id: 110, label: "MXN", value: "MXN" },
  { id: 111, label: "MDL", value: "MDL" },
  { id: 112, label: "MNT", value: "MNT" },
  { id: 113, label: "MZN", value: "MZN" },
  { id: 114, label: "MMK", value: "MMK" },
  { id: 115, label: "NAD", value: "NAD" },
  { id: 116, label: "NPR", value: "NPR" },
  { id: 117, label: "NIO", value: "NIO" },
  { id: 118, label: "KPW", value: "KPW" },
  { id: 119, label: "MKD", value: "MKD" },

  { id: 120, label: "OMR", value: "OMR" },
  { id: 121, label: "PGK", value: "PGK" },
  { id: 122, label: "PYG", value: "PYG" },
  { id: 123, label: "PEN", value: "PEN" },
  { id: 125, label: "PHP", value: "PHP" },
  { id: 126, label: "PLN", value: "PLN" },
  { id: 127, label: "QAR", value: "QAR" },
  { id: 128, label: "RON", value: "RON" },
  { id: 129, label: "RUB", value: "RUB" },

  { id: 130, label: "STN", value: "STN" },
  { id: 131, label: "SAR", value: "SAR" },
  { id: 132, label: "RSD", value: "RSD" },
  { id: 133, label: "SCR", value: "SCR" },
  { id: 134, label: "SLL", value: "SLL" },
  { id: 136, label: "SBD", value: "SBD" },
  { id: 137, label: "SOS", value: "SOS" },
  { id: 138, label: "ZAR", value: "ZAR" },
  { id: 139, label: "KRW", value: "KRW" },

  { id: 140, label: "SSP", value: "SSP" },
  { id: 141, label: "SDG", value: "SDG" },
  { id: 142, label: "SEK", value: "SEK" },
  { id: 143, label: "SYP", value: "SYP" },
  { id: 144, label: "TWD", value: "TWD" },
  { id: 145, label: "TJS", value: "TJS" },
  { id: 146, label: "THB", value: "THB" },
  { id: 147, label: "TOP", value: "TOP" },
  { id: 148, label: "TTD", value: "TTD" },
  { id: 149, label: "TND", value: "TND" },

  { id: 150, label: "TRY", value: "TRY" },
  { id: 151, label: "TMT", value: "TMT" },
  { id: 152, label: "UAH", value: "UAH" },
  { id: 153, label: "AED", value: "AED" },
  { id: 154, label: "UYU", value: "UYU" },
  { id: 155, label: "UZS", value: "UZS" },
  { id: 156, label: "VES", value: "VES" },
  { id: 156, label: "JEP", value: "JEP" },
  { id: 157, label: "RWF", value: "RWF" },
  { id: 158, label: "WST", value: "WST" },
];