import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { fileCallerApi } from "../../api/apiCallerNew";
import { bonusApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
const AssignBonusSlice = createSlice({
  name: "assignBonusSlice",
  initialState: {
    tableData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    tagPostData: [],
    recordsShow: [1, 25],
    isVisible: false,
    bonussummery: [],
    activeFreeBets: [],
    responseData: [],
    statusMessage: "",
    isStatusError: false,
    goodWillBonus: [],
  },

  reducers: {
    getTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setTagPostFata: (state, action) => {
      state.tagPostData = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setIsVisibile: (state, action) => {
      state.isVisible = action.payload;
    },
    setbonussummery: (state, action) => {
      state.bonussummery = action.payload;
    },
    setActiveFreeBets: (state, action) => {
      state.activeFreeBets = action.payload;
    },
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
    setStatusMessage: (state, action) => {
      state.statusMessage = action.payload;
    },
    setIsStatusError: (state, action) => {
      state.isStatusError = action.payload;
    },
    setGoodWillBonus: (state, action) => {
      state.goodWillBonus = action.payload;
    },
  },
});

export const {
  getTableData,
  setTagPostFata,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisibile,
  setbonussummery,
  setActiveFreeBets,
  setResponseData,
  setStatusMessage,
  setIsStatusError,
  setGoodWillBonus,
} = AssignBonusSlice.actions;

//dev.platform-ups.sportsit-tech.net:443/player/1000019/tag?pageNumber=0&ItemsPerPage=25

//dev.platform-pbs.sportsit-tech.net:443/bonus?pageNumber=0&itemsPerPage=25

export const fetchBonusData = (pageNumber, itemPerPage) => (dispatch) => {
  var url =
    `${bonusApi.getBonusummery}` +
    "?pageNumber=" +
    pageNumber +
    "&itemsPerPage=" +
    itemPerPage;
  apiCaller(url, "GET", {}).then((response) => {
    dispatch(setbonussummery(response.data));
    const activeFreeBets = [];
    const goodWillBonus = []
    response.data.data.filter((v) => {
      if (v.bonusStatus === "ACTIVE" && v.bonusType === "FREE_BET") {
        activeFreeBets.push({
          id: v.id,
          label: v.bonusCode,
          value: v.id,
        });
      }
      if (v.bonusStatus === "ACTIVE" && v.bonusType === "GOODWILL") {
        goodWillBonus.push({
          id: v.id,
          label: v.bonusCode,
          value: v.id,
        });
      }
    });
    dispatch(setActiveFreeBets(activeFreeBets));
    dispatch(setGoodWillBonus(goodWillBonus));
  });
};

export const assignBonus = (data, type, errMsg) => (dispatch) => {
  const url = type === "file" ? bonusApi.bulkUpload : bonusApi.updateBonusCode;

  if (type === "file") {
    fileCallerApi(url, data)
      .then((response) => {
        if (response.status == 200) {
          dispatch(setResponseData(response.data));
          const success = response.data.filter((v) => v.status === "SUCCESS");
          const isErr = response.data.some((v) => v.status === "ERROR");
          dispatch(setIsStatusError(isErr));
          const message = `${success.length}/ ${response.data.length} records have been updated successfully`;
          dispatch(setStatusMessage(message));
          toaster.success("SUCCESS");
        }
        [400, 401, 403, 404, 500].includes(response.status) &&
          toaster.error("FAILED");
      })
      .catch((error) => toaster.error(messageEnhancer(error.message)));
  } else {
    apiCaller(url, "POST", data, null, errMsg)
      .then((response) => {
        if (response.status == 200 && response.data !== null) {
          toaster.success("SUCCESS");
          dispatch(setResponseData(response.data));
          const isErr = response.data.some((v) => v.status === "ERROR");
          const success = response.data.filter((v) => v.status === "SUCCESS");
          const message = `${success.length}/ ${response.data.length} records have been updated successfully`;

          dispatch(setStatusMessage(message));
          dispatch(setIsStatusError(isErr));
        } else {
          toaster.error(messageEnhancer(response.message))
        }
        [400, 401, 403, 404, 500].includes(response.status) &&
          toaster.error("FAILED");
      })
      .catch((error) => {
        toaster.error(messageEnhancer(error.response))
      });
  }
};
export default AssignBonusSlice.reducer;
