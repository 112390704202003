import React from "react";
import { connect } from "react-redux";
import {
  fetchTagData,
  fetchTagsList,
  postUserTag,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setViewRecords,
  setRecordsShow,
} from "../Tags/TagSlice";
import { bindActionCreators } from "redux";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Skelton from "../Common/skelton/Skelton";
import { COMMON_CONSTANTS, TAG_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Select from "../Common/common_inputs/Select_DDL";
import Tooltips from "../Common/Tooltip";
import {
  PLAYER_MODULE,
  TagInfo,
  Tagging_Segmentation,
} from "../../sharedfiles/tooltipMsgs";
import { currency } from "../Common/data/Players/PlayersData";
import { betConfigData } from "../Common/data/mapData";
import Input from "../Common/common_inputs/Input";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";

const BetConfg = () => {
  return (
    <div>
      <div className="CMS-tabContent">
        <div className="CMS-tabs-content">
          <div className="CMS-tab-panel active" id="CMS-codedBonus">
            <div className="CMS-box CMS-box-content">
              <div className="CMS-formGroup">
                <div className="CMS-formControl row d-flex justify-content-around">
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Select
                      title={"Currency"}
                      name={"currency"}
                      // value={this.state.currency}
                      // setValue={(val) => this.handleChange(val)}
                      options={currency}
                      // onSubmit={(e) => this.onSubmit(e)}
                      ids={"PlayerCurrency"}
                      info={PLAYER_MODULE.Currency}
                    />
                     {/* <MultiSelectDDL
                      options={currency}
                        // options={this.props.sportsList}
                        // setSelected={(sport) =>
                        //   this.setSelected(sport)
                        // }
                        // ids={this.state.sportsSelected}
                        // setIds={(sport) =>
                        //   this.setSelected(sport)}
                        // selectedOnce={this.state.sportsSelected}
                        // clearAll={this.state.clearAll}
                        // setClear={() => this.setClear()}
                        // betDDL
                      /> */}
                  </div>
                  {betConfigData.map((item, ind) => {
                    return (
                      <React.Fragment key={item.id}>
                        <div className="col-md-4 col-lg-3 col-xl-2">
                          <Input
                            title={item.title}
                            name={item.name}
                            // value={values[ind]}
                            placeholder={item.placeholder}
                            // setValue={(e) => this.handleChange(e)}
                            // regex={item.regex}
                            // maxLen={item.maxLength}
                            ids={item.ids}
                          // info={item.info}
                          // show={true}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>

              <div className="row">
                <ButtonGroup
                  groupNames={["Create", "Reset"]}
                  idsearch={"AddTag"}
                  idreset={"Tagreset"}
                // submit={(e) => this.addTag(e)}
                // reset={(e) => this.reset(e)}
                />
              </div>
            </div>
            <div className="CMS-filter-result" id="result">
              <div className="CMS-pagination">
                {/* <Vpagination
                data={this.props.playerData.data}
                totalRecords={this.props.playerData.totalRecords}
                paginationFirstValue={this.props.paginationFirstValue}
                paginationSecondValue={this.props.paginationSecondValue}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={this.props.recordsShow}
                setRecordsShow={setRecordsShow}
                isVisible={this.props.isVisible}
                setIsVisible={setIsVisible}
                csvData={this.state.csvData}
                exportPDF={this.exportPDF}
                callApi={this.callApi}
                csvFileName={"Player Search Report"}
                payloadData={this.state.payloadData}
                resetCsvData={this.resetCsvData}
              /> */}
              </div>
              <div className="CMS-box">
                <div className="CMS-table CMS-table-triped">
                  <table>
                    <thead>
                      <tr>
                        <th>Currency</th>
                        <th>Min Stake</th>
                        <th>Max Stake</th>
                        <th>Max Payout</th>
                        <th>Created Date</th>
                        <th>Created By</th>
                        <th>Updated Date</th>
                        <th>Updated By</th>
                        <th>Update Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>20</td>
                        <td>20</td>
                        <td>2000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          
                            <button
                              className="CMS-btn CMS-btnPrimary active vh_laign_center"
                              id="BetConfigEdit"
                            >
                              <span className="material-icons edit">
                                {COMMON_CONSTANTS.span_editIcon}
                              </span>
                              {COMMON_CONSTANTS.edit}
                            </button>

                            <button
                              className="CMS-btn CMS-btnQuaternary active vh_laign_center pointer mt-10"
                              id="BetConfigEdit"
                            >
                              <span className='material-icons delete'>
                                {COMMON_CONSTANTS.span_deleteIcon}
                              </span>
                             {COMMON_CONSTANTS.delete}
                            </button>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetConfg;
