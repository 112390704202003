import React, { Component } from "react";
import {Currency} from "../../sharedfiles/helper"
// import Highcharts from '../../sharedfiles/customhighcharts';

import Highcharts from "highcharts";
import { getDateFormate } from "../../sharedfiles/helper";

class BasicLineChart extends Component {
  componentDidMount() {
    if (this.props?.data && this.props.data?.length > 0) {
      this.loadGraph();
    }
  }

  loadGraph() {
    let stack = [];
    let dates = [];
    const rawDates = [];
    let restrictedData = [];
    let data = [...this.props?.data];
    if (this.props.data) {
      let sortedData = data.sort(
        (a, b) =>
          new Date(...a.createdDate.split("-")) -
          new Date(...b.createdDate.split("-"))
      );
      for (var item of sortedData) {
        if (!rawDates.includes(item.createdDate)) {
          if (item.stakeFactor === "Stake_Factor_1") {
            stack.push(item.revenue / 100);
            rawDates.push(item.createdDate);
            dates.push(getDateFormate(item.createdDate));
          }
          if (item.stakeFactor === "Restricted_Customer") {
            restrictedData.push(item.revenue / 100);
          }
        }
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    Highcharts.chart("basiclinechart", {
      title: {
        text: "",
      },
      credits: {
        enabled: true,
      },

      yAxis: {
        title: {
          text: "",
        },
      },

      xAxis: {
        type: "datetime",
        categories: dates,
        dateTimeLabelFormats: {
          day: "%e. %b",
        },
      },
      legend: {
        enabled: true,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          // pointStart: Date.UTC(date[0]),
          // pointInterval: 24 * 3600 * 1000 * 1
        },
      },

      series: [
        {
          name: "Restricted Customers",
          data: restrictedData,
          tooltip: {
            valuePrefix: Currency(),
            valueDecimals: 2,
          },
        },
        {
          name: "Stake_Factor_1",
          data: stack,
          tooltip: {
            valuePrefix: Currency(),
            valueDecimals: 2,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
        ],
      },
    });
  }

  render() {
    return <div id='basiclinechart'></div>;
  }
}

export default BasicLineChart;
