import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi, ResponsibleGamblingApi } from "../../sharedfiles/EndpointConfig";
import { cleanObject, messageEnhancer, toaster } from "../../sharedfiles/helper";

const LimitTabSlice = createSlice({
    name: "paymentLimitTab",
    initialState: {
        limitData: [],
        disableBtn: false,
        updatedLimits: [],
        casoutUpdate: []
    },
    reducers: {
        setLimitDta: (state, action) => {
            state.limitData = action.payload
        },
        setDisableBtn: (state, action) => {
            state.disableBtn = action.payload;
        },
        setUpdatedLimits: (state, action) => {
            state.updatedLimits = action.payload;
        },
        setCasoutUpdate: (state, action) => {
            state.casoutUpdate = action.payload;
        },

    }
});

export const { setLimitDta, setDisableBtn, setUpdatedLimits, setCasoutUpdate } = LimitTabSlice.actions

export const userPaymentLimits = (param) => (dispatch) => {
    const url = `${paymentSearchApi.playerPaymentLimit}`
    const obj = {
        configType: param.configType,
        maxLimit: param.maxLimit,
        minLimit: param.minLimit,
        userId: param.userId,
    }
    apiCaller(url, "POST", obj)
        .then((response) => {
            if (response.status == 200) {
                dispatch(setLimitDta(response.data));
                dispatch(setDisableBtn(true));
                toaster.success("SUCCESS")
            }
        })
        .catch((err) => {
            const res = err.response
            dispatch(setDisableBtn(true));
            toaster.error(messageEnhancer(res));
        });
}

export const getUpdatedLimits = (id) => (dispatch) => {
    const url = paymentSearchApi.getUserPaymentLimit(id)
    apiCaller(url, "GET", {}).then((response) => {
        dispatch(setUpdatedLimits(response.data));
    });
};

export const updateUserCashoutAvailability = (params) => (dispatch) => {
    const url = `${paymentSearchApi.UserCashoutAvailability}`
    apiCaller(url, "POST", params)
        .then((response) => {
            if (response.status == 200) {
                setCasoutUpdate(response.data)
                dispatch(setDisableBtn(true));
                toaster.success(messageEnhancer(response.msg));
            }
        })
        .catch((err) => {
            const res = err.response
            dispatch(setDisableBtn(true));
            toaster.error(messageEnhancer(res));
        });
}

export const updatePlayertLimit = (id, val) => (dispatch) => {
    const url = ResponsibleGamblingApi.getPlayertLimit(id);
    let payload = {
        withdrawEnabled: val.withdrawEnabled,
        depositEnabled: val.depositEnabled,
    }
    let data = cleanObject(payload)
    apiCaller(url, "PUT", data)
        .then((response) => {
            if (response.status === 200) {
                toaster.success(messageEnhancer(response.msg));
            }
        })
        .catch((err) => {
            const res = err.response;
            toaster.error(messageEnhancer(res));
        });
};

export default LimitTabSlice.reducer;