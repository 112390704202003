import React, { useState } from "react";
import { connect } from "react-redux";
import ReportsTable from "../Common/table/ReportsTable";

const ActivePlayerTable = (props) => {
  const [fileType, setFileType] = useState("");

  const headers = [
    ["Report Name", "Start Date", "End Date", "Brand", "File Type", "Margin"],
  ];
  const data = [];
  const csvData = [...headers, data];
  const title = props.reportPage;
  const body = [];

  const firstTable = {
    subtitle: "Active Players",
    headers,
    body: body,
  };
  const tableData = [firstTable];
  const printPdf = () => {};
  return (
    <>
      <ReportsTable
        tableData={tableData}
        fileType={fileType}
        setFileType={(value) => setFileType(value)}
        csvData={csvData}
        fileName={title}
        printPdf={() => printPdf()}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reports: state.reports.reports,
  };
};

export default connect(mapStateToProps)(ActivePlayerTable);
