import React, { useState } from 'react'
import Select from "../Common/common_inputs/Select_DDL";
import Input from "../Common/common_inputs/Input";
import { withdrawLimitOP } from '../Common/data/mapData';
import { MoneyFormat1, MoneyFormat2, regexType } from '../../sharedfiles/helper';
import { addConfig, getBrandConfig } from "../Brand config/BrandConfigSlice"
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useEffect } from 'react';
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants';
import { Payments } from '../../sharedfiles/tooltipMsgs';

const WithdrawalLimit = (props) => {
    const dispatch = useDispatch();
    const [withdrawaLimit, setWithdrawalLimit] = useState("")
    const [autoWithdraw, setAutoWithdraw] = useState("")
    const [perDay, setPerDay] = useState("")
    const [maxWithdraw, setMaxWithdraw] = useState("")
    const [limitErr, setLimitErr] = useState(false)
    const [withdrawErr, setWithdrawErr] = useState(false)
    const [perDayErr, setPerDayErr] = useState(false)
    const [maxWithdrawErr, setMaxWithdrawErr] = useState(false)
    const [amountHigh, setAmountHigh] = useState(false)
    const [dayHigh, setDayHigh] = useState(false)
    const [withdrawHigh, setWithdrawHigh] = useState(false)
    const [amntDisable, setAmntDisable] = useState(false)
    const [perdayDisable, setPerdayDisable] = useState(false)
    const [maxWithdrawDisable, setMaxWithdrawDisable] = useState(false)
    const [ip1Disable, setIp1Disable] = useState(false)
    const [ip2Disable, setIp2Disable] = useState(false)
    const [ip3Disable, setIp3Disable] = useState(false)

    const EmptyMsg = "Please Enter Valid Input"
    const ZeroMsg = "Value Must be greater than 0"
    const LimitMsg = "Withdrawal limit shouldn't be more than 10"

    const withdraw = props.brandData.find((p) => p.key === "WITHDRAW_ADMIN_APPROVAL")
    const data = withdraw ? withdraw.value : ''

    const perday = props.brandData.find((p) => p.key === "AUTO_WITHDRAW_COUNT")

    const maxwithdrawAmnt = props.brandData.find((p) => p.key === "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION")

    const handleChange = (e) => {
        const { value } = e.target;
        setWithdrawalLimit(value)
        if (value === "") {
            setLimitErr(true)
        } else {
            setLimitErr(false)
        }
        if (value === "AUTOWITHDRAW") {
            const value = MoneyFormat1(withdraw.value)
            setAutoWithdraw(value)
            setIp1Disable(true)
            setAmntDisable(true)
        }
        if (value === "WITHDRAWPERDAY") {
            setPerDay(perday.value)
            setIp2Disable(true)
            setPerdayDisable(true)
        }
        if (value === "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION") {
            // setMaxWithdraw(maxwithdrawAmnt.value/100)
            const finalValue = MoneyFormat1(maxwithdrawAmnt.value)
            setMaxWithdraw(finalValue)
            setIp3Disable(true)
            setMaxWithdrawDisable(true)
        }
        setAmountHigh(false)
        setDayHigh(false)
        setWithdrawHigh(false)
        setWithdrawErr(false)
        setPerDayErr(false)
        setMaxWithdrawErr(false)
    }

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === "autoWithdraw") {
            setAutoWithdraw(value)
            setAmountHigh(false)
        }
        if (name === "perDay") {
            setPerDay(value)
            setDayHigh(false)
        }

        if (name === "maxWithdraw") {
            console.log(typeof (value), "val");
            setMaxWithdraw(value)
            setWithdrawHigh(false)
            setMaxWithdrawErr("")

        }

        if (value === "" && name === "autoWithdraw") {
            setWithdrawErr(EmptyMsg)
        }
        else if (Number(value) === 0 && name === "autoWithdraw") {
            setWithdrawErr(ZeroMsg)
        } else if (value > 0 && name === "autoWithdraw") {
            setWithdrawErr("")
        }

        if (value === "" && name === "perDay") {
            setPerDayErr(EmptyMsg)
        }
        else if (Number(value) === 0 && name === "perDay") {
            setPerDayErr(ZeroMsg)
        }
        else if (value > 10 && name === "perDay") {
            setPerDayErr(LimitMsg)
        } else {
            setPerDayErr("")
        }

        if (value === "" && name === "maxWithdraw") {
            setMaxWithdrawErr(EmptyMsg)
        }
        else if (Number(value) === 0 && name === "maxWithdraw") {
            setMaxWithdrawErr(ZeroMsg)
        } else if (value > 0 && name === "maxWithdraw") {
            setMaxWithdrawErr("")
        }

    }
    const onSaveWithdraw = () => {
        const absValue = autoWithdraw !== "" && Math.abs(autoWithdraw) !== 0
        if (autoWithdraw === "") {
            setWithdrawErr(EmptyMsg)
        }
        if (absValue) {
            var data = {
                key: "WITHDRAW_ADMIN_APPROVAL",
                value: MoneyFormat2(autoWithdraw),
            };
            dispatch(addConfig(data));
            setAmntDisable(true)
            setIp1Disable(true)
            setTimeout(() => dispatch(getBrandConfig()), 100)
        }
    }

    const onSavePerDay = () => {
        const absValue = perDay !== "" && Math.abs(perDay) !== 0
        if (perDay === "") {
            setPerDayErr(EmptyMsg)
        }
        if (absValue && !perDayErr) {
            var data = {
                key: "AUTO_WITHDRAW_COUNT",
                value: perDay,
            };
            dispatch(addConfig(data));
            setPerdayDisable(true)
            setIp2Disable(true)
            setTimeout(() => dispatch(getBrandConfig()), 100)
        }
    }

    const onSaveMaxWithdraw = () => {
        const absValue = maxWithdraw !== "" && Math.abs(maxWithdraw) !== 0
        if (maxWithdraw === "") {
            setMaxWithdrawErr(EmptyMsg)
        }
        if (absValue && !maxWithdrawErr) {
            var data = {
                key: "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION",
                value: maxWithdraw * 100,
            };
            dispatch(addConfig(data));
            setMaxWithdrawDisable(true)
            setIp3Disable(true)
            setTimeout(() => dispatch(getBrandConfig()), 100)
        }
    }

    const onEditWithdraw = () => {
        setAmountHigh(true)
        setAmntDisable(false)
        setIp1Disable(false)
    }

    const onEditPerDay = () => {
        setDayHigh(true)
        setPerdayDisable(false)
        setIp2Disable(false)
    }

    const onEditMaxWithdraw = () => {
        setWithdrawHigh(true)
        setMaxWithdrawDisable(false)
        setIp3Disable(false)
    }

    useEffect(() => {
        dispatch(getBrandConfig())
    }, [])

    return (
        <React.Fragment>
            <div className='CMS-tab-panel active' id='CMS-betting'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content mb-10'>
                        <div className='row'>
                            <div className='col-md-6 col-lg-4 col-xl-3' style={{ marginLeft: "15px" }}>
                                <Select
                                    title={"Withdrawal Limit"}
                                    name={"withdrawaLimit"}
                                    ids={"withdrawaLimit"}
                                    value={withdrawaLimit}
                                    setValue={(val) => handleChange(val)}
                                    options={withdrawLimitOP}
                                    errorMsg={limitErr && "Please select your Limits"}
                                    info={Payments.Withdrawal_Limit}
                                />
                            </div>
                            {withdrawaLimit === "AUTOWITHDRAW" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Automated withdrawal Amount"}
                                        placeholder={"Enter the Amount"}
                                        name={"autoWithdraw"}
                                        type={"text"}
                                        ids={"autoWithdraw"}
                                        value={autoWithdraw}
                                        setValue={(e) => inputHandler(e)}
                                        regex={regexType.decimalNumeric}
                                        errorMsg={withdrawErr}
                                        style={amountHigh ? { border: " solid #000" } : {}}
                                        isTofocus={amountHigh}
                                        disabled={ip1Disable}
                                    />
                                </div> : ""}
                            {withdrawaLimit === "WITHDRAWPERDAY" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"No. of Automated withdrawals Per Day"}
                                        placeholder={"Enter the Amount"}
                                        name={"perDay"}
                                        type={"text"}
                                        ids={"perDay"}
                                        value={perDay}
                                        setValue={(e) => inputHandler(e)}
                                        regex={regexType.numeric}
                                        errorMsg={perDayErr}
                                        style={dayHigh ? { border: " solid #000" } : {}}
                                        isTofocus={dayHigh}
                                        disabled={ip2Disable}
                                    />
                                </div> : ""}

                            {withdrawaLimit === "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Max Withdrawal Amount per transaction"}
                                        placeholder={"Enter the Amount"}
                                        name={"maxWithdraw"}
                                        type={"text"}
                                        ids={"maxWithdraw"}
                                        value={maxWithdraw}
                                        setValue={(e) => inputHandler(e)}
                                        regex={regexType.numeric}
                                        errorMsg={maxWithdrawErr}
                                        style={withdrawHigh ? { border: " solid #000" } : {}}
                                        isTofocus={withdrawHigh}
                                        disabled={ip3Disable}
                                    />
                                </div> : ""}
                        </div>

                        {withdrawaLimit === "AUTOWITHDRAW" ?
                            <div className='CMS-btnContainer' style={{ marginInlineEnd: "685px" }}>
                                <button
                                    className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${amntDisable ? "disable-button disabled_events" : ""}`}
                                    onClick={(e) => onSaveWithdraw(e)}
                                    disabled={amntDisable}
                                >
                                    {COMMON_CONSTANTS.save}
                                </button>
                                <button
                                    className="CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium"
                                    onClick={() => onEditWithdraw()}
                                >
                                    <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                                    &nbsp;{COMMON_CONSTANTS.edit}
                                </button>
                            </div> : ""}

                        {withdrawaLimit === "WITHDRAWPERDAY" ?
                            <div className='CMS-btnContainer' style={{ marginInlineEnd: "685px" }}>
                                <button
                                    className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${perdayDisable ? "disable-button disabled_events" : ""}`}
                                    onClick={(e) => onSavePerDay(e)}
                                    disabled={perdayDisable}
                                >
                                    {COMMON_CONSTANTS.save}
                                </button>
                                <button
                                    className="CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium"
                                    onClick={() => onEditPerDay()}
                                >
                                    <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                                    &nbsp;{COMMON_CONSTANTS.edit}
                                </button>
                            </div> : ""}

                        {withdrawaLimit === "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION" ?
                            <div className='CMS-btnContainer' style={{ marginInlineEnd: "685px" }}>
                                <button
                                    className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${maxWithdrawDisable ? "disable-button disabled_events" : ""}`}
                                    onClick={(e) => onSaveMaxWithdraw(e)}
                                    disabled={maxWithdrawDisable}
                                >
                                    {COMMON_CONSTANTS.save}
                                </button>
                                <button
                                    className="CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium"
                                    onClick={() => onEditMaxWithdraw()}
                                >
                                    <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                                    &nbsp;{COMMON_CONSTANTS.edit}
                                </button>
                            </div> : ""}

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        brandData: state.brandconfig.brandData,
    };
};

export default connect(mapStateToProps)(WithdrawalLimit)