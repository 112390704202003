import React, { useState, useRef, useEffect } from 'react';

const MultipleSelectDDL = ({
    options,
    onSelect,
    shouldReset,
    onlyOpt,
    initialSelected,
    onlyOneSelection,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const DdlCloseRef = useRef(null);
    const [isChecked, setIsChecked] = useState(options.length && Array(options?.length).fill(false));
    const [selectedValues, setSelectedValues] = useState(initialSelected || []);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (e) => {
        if (DdlCloseRef.current && !DdlCloseRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const handleOptionClick = (index) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);
        const newSelectedValues = newIsChecked.reduce((acc, checked, i) => {
            if (checked) {
                acc.push(options[i].value);
            }
            return acc;
        }, []);

        const optValue = newIsChecked.reduce((acc, checked, i) => {
            if (checked) {
                acc.push(options[i]);
            }
            return acc;
        }, []);

        const finalValue = onlyOpt ? optValue : newSelectedValues;
        onSelect(finalValue);
        setSelectedValues(finalValue);
    };

    const handleSelectAll = () => {
        const allChecked = isChecked.every((val) => val);
        const newIsChecked = Array(options.length).fill(!allChecked);
        setIsChecked(newIsChecked);
        const newSelectedValues = newIsChecked.reduce((acc, checked, i) => {
            if (checked) {
                acc.push(options[i].value);
            }
            return acc;
        }, []);

        const optValue = newIsChecked.reduce((acc, checked, i) => {
            if (checked) {
                acc.push(options[i]);
            }
            return acc;
        }, []);

        const finalValue = onlyOpt ? optValue : newSelectedValues;
        onSelect(finalValue);
        setSelectedValues(finalValue);
    };

    useEffect(() => {
        setSelectedValues(initialSelected || []);
        const initialCheckedState = options.map((option) => initialSelected && initialSelected.includes(option.value));
        setIsChecked(initialCheckedState);
    }, [options, initialSelected]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filteredOptions = options.filter((option) => {
        return option.label?.toLowerCase().includes(searchTerm.toLowerCase())
    }
    );

    const selectedLabels = onlyOpt
        ? selectedValues.filter((value) => options.includes(value))
        : options
            .filter((item) => selectedValues.includes(item.value))
            .map((item) => item.label);

    const onReset = () => {
        setIsChecked(new Array(options.length).fill(false))
        onSelect([])
        setSelectedValues([])
    }

    useEffect(() => {
        if (shouldReset) {
            onReset();
        }
    }, [shouldReset]);
    function truncateString(inputString) {
        if (inputString.length > 35) {
            return inputString.slice(0, 35) + '....';
        }
        return inputString;
    }
    return (
        <>
            <div className="CMS-formControl">
                <div
                    className={"CMS-dropdown CMS-brands-dropdown" + (isOpen ? " active" : "")}
                    ref={DdlCloseRef}
                >
                    <div
                        className="CMS-dropdown-btn"
                        onClick={handleButtonClick}
                        style={{ overflowX: 'hidden', width: '100%' }}
                    >
                        {truncateString(selectedLabels.length > 0 ? selectedLabels.join(', ') : 'Select')}
                    </div>
                    {isOpen && (
                        <div
                            className="CMS-dropdown-menu CMS-form-group"
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                        >
                            <input
                                type="text"
                                className="search-box"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <label
                                className="d-flex justify-content-start align-items-center ddl-item-hover"
                            // style={{ height: '30px', whiteSpace: 'nowrap' }}
                            >
                                <input
                                    type="checkbox"
                                    checked={isChecked.every((val) => val)}
                                    onChange={handleSelectAll}
                                />
                                Select All
                            </label>
                            {filteredOptions &&
                                filteredOptions.map((item, index) => (
                                    <label
                                        className="d-flex justify-content-start ddl-item-hover"
                                        // style={{ height: '30px', whiteSpace: 'nowrap' }}
                                        key={item.id}
                                    >
                                        <input
                                            id={item.id}
                                            name={item.id}
                                            value={onlyOpt ? item : item.value}
                                            checked={isChecked[options.indexOf(item)]}
                                            type="checkbox"
                                            onChange={() => handleOptionClick(options.indexOf(item))}
                                        />
                                        {onlyOpt ? item : item.label}
                                    </label>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default MultipleSelectDDL;
