import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bettingApi, setBaseUrl } from "../../sharedfiles/EndpointConfig";
import { Currency } from "../../sharedfiles/helper";
export const BettingTickerSlice = createSlice({
  name: "bettingticker",
  initialState: {
    recordsToShow: [1, 25],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    bettingTickerData: [],
    isVisible: false,
    betColumn: [
      { column: "Placed On", key: "placedOn" },
      { column: "Settled On", key: "settledOn" },
      { column: "Bet Status", key: "betStatus" },
      { column: "BetID", key: "betId" },
      { column: "Short Bet ID", key: "shortbetId" },
      { column: "Customer ID", key: "CustomerId" },
      { column: "Age of Account", key: "ageOfAcc" },
      { column: "Game type", key: "gameType" },
      { column: "Event Name", key: "eventName" },
      { column: "Bet Fold", key: "betFold" },
      { column: "Currency", key: "currency" },
      { column: `Stake ${Currency()}`, key: "stack" },
      { column: "Odds", key: "odds" },
      { column: `Potential Payout ${Currency()}`, key: "potentialPayout" },
      { column: `Payout ${Currency()}`, key: "payout" },
      { column: "Stake Type", key: "stakeType" },
      // { column: "Bet Status", key: "betStatus" },
      // { column: "Stake Factor", key: "stakeFactor" },
      // { column: "Bet Detail", key: "betDetail" },
      
    ],
    loader: true,
    docsData: [],
    pdfBodyData: [],
  },
  reducers: {
    setBettingTickerData: (state, action) => {
      state.bettingTickerData = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsToShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    resetData: (state, action) => {
      state.bettingTickerData = [];
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setPdfBodyData: (state, action) => {
      state.pdfBodyData = action.payload;
    },
  },

});

export const {
  setBettingTickerData,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  resetData,
  setLoader,
  setIsVisible,
  setDocsData,
  setPdfBodyData,
} = BettingTickerSlice.actions;

export const fetchBettingTickerData =
  (paginationFirstValue, paginationSecondValue, from, Status, bettingTickerData) =>
    (dispatch, getState) => {
      setBaseUrl();
      dispatch(resetData());
      let prevState = getState().bettingticker.bettingTickerData;
      // const date = "2021-02-08T08:34:32.013Z"
      var obj = { from: from, betStatus: Status };
      // const form = {params}
      // var obj = { form: params.params };
      dispatch(setLoader(true));
      var url =
        `${bettingApi().getBettingTicker}` +
        "?pageNumber=" +
        paginationFirstValue +
        "&itemPerPage=" +
        paginationSecondValue;

      apiCaller(url, "POST", obj).then((response) => {
        if (response !== null && response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            dispatch(setIsVisible(true))
            if (from && Status) {
              if (Array.isArray(response.data.data[0].betTicker) && response.data.data[0].betTicker.length > 0) {
                let copyBettingTickerData = [
                  ...bettingTickerData.data[0].betTicker,
                ];
                for (let obj of response.data.data[0].betTicker) {
                  copyBettingTickerData.unshift(obj);
                }
                let getCurrentTime = response.data.data[0].currentTime;
                let getNewRecords = response.data.totalRecords;
                let getExistingRecords = bettingTickerData.totalRecords;
                let getBetStatus = response.data.data[0].betStatus
                let updatedRecords =
                  Number(getNewRecords) + Number(getExistingRecords);
                let formBettingTickerData = {
                  data: [
                    {
                      betStatus: getBetStatus,
                      betTicker: response.data.data[0].betTicker,
                      currentTime: getCurrentTime,
                    },
                  ],
                  totalRecords: response.data.totalRecords,
                };
                dispatch(setBettingTickerData(formBettingTickerData));
              } else {
                let formBettingTickerData = {
                  data: [
                    {
                      betStatus: response.data.data[0].betStatus,
                      betTicker: response.data.data[0].betTicker,
                      currentTime: response.data.data[0].currentTime,
                    },
                  ],
                  totalRecords: response.data.totalRecords,
                };
                dispatch(setBettingTickerData(formBettingTickerData));
              }
            } else {
              dispatch(setBettingTickerData(response.data));
            }
          }
        }
      }).catch(err => {
        dispatch(setBettingTickerData([]));
        dispatch(setLoader(false));
        dispatch(setIsVisible(true));
      });
    };

export default BettingTickerSlice.reducer;