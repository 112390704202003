import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";

export const regTickerSlice = createSlice({
    name: "registrationTicker",
    initialState: {
        regTickerData: [],
        recordsToShow: [1, 25],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        isVisible: false,
        loader: true

    },
    reducers: {
        setRegTickerData: (state, action) => {
            state.regTickerData = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsToShow = action.payload;
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        }
    }
});

export const {
    setRegTickerData,
    setRecordsShow,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setIsVisible,
    setLoader,
} = regTickerSlice.actions

export const getRegistrationTicker = (pageNumber, itemPerPage, from, regTickerData) => (dispatch) => {
    var obj = { from: from };
    dispatch(setRegTickerData([]))
    dispatch(setLoader(true))
    var url = playerapi.getRegistrationTicker(pageNumber, itemPerPage)

    apiCaller(url, "POST", obj).then((response) => {

        if (response !== null && response) {
            if (response.status == 200) {
                dispatch(setLoader(false))
                dispatch(setIsVisible(true))
                console.log("response.data: ", response.data);

                if (from) {
                    if (
                        Array.isArray(response.data.data[0].registrationTicker) &&
                        response.data.data[0].registrationTicker.length > 0
                    ) {
                        let copyTickerData = [
                            ...regTickerData.data[0].registrationTicker,
                        ];
                        for (let obj of response.data.data[0].registrationTicker) {
                            copyTickerData.unshift(obj);
                        }
                        let getCurrentTime = response.data.data[0].currentTime;
                        let getNewRecords = response.data.totalRecords;
                        let getExistingRecords = regTickerData.totalRecords;
                        let updatedRecords =
                            Number(getNewRecords) + Number(getExistingRecords);
                        let formRegTickerData = {
                            data: [
                                {
                                    registrationTicker: response.data.data[0].registrationTicker,
                                    currentTime: getCurrentTime,
                                },
                            ],
                            totalRecords: response.data.totalRecords,
                        };
                        dispatch(setRegTickerData(formRegTickerData));

                    } else {
                        let formRegTickerData = {
                            data: [
                                {
                                    registrationTicker: response.data.data[0].registrationTicker,
                                    currentTime: response.data.data[0].currentTime,
                                },
                            ],
                            totalRecords: regTickerData.totalRecords,
                        };
                        dispatch(setRegTickerData(formRegTickerData));
                    }
                } else {
                    dispatch(setRegTickerData(response.data));
                }
            }
        }
    }).catch(err => {
        dispatch(setRegTickerData([]));
        dispatch(setLoader(false))
        dispatch(setIsVisible(true))
    });
}

export default regTickerSlice.reducer