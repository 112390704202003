/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  pendingwithdrawals,
  setPaginationSecondValueSP,
  setPaginationFirstValueSP,
  setRecordsToShowSP,
  setIsVisibleSP,
  setStatusSelected,
  setDocsData,
  setStatusType,
  setViewAudit
} from "./pendingWithrawalSlice";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
  generateKey,
  hideMobile,
  hideEmail,
  sortingFilter,
} from "../../sharedfiles/helper";
import "jspdf-autotable";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import ActionDialog from "./ActionDialog";
import AuditDialog from "./AuditDialog";
import Vpagination from "../../sharedfiles/Vpagination";
import { tabManager } from "../sidebar/sidebarSlice";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import Skelton from "../Common/skelton/Skelton";
import { BANKDETAIL_CONSTANTS, COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { BankInfo, COMMON_MSGS, Payments } from "../../sharedfiles/tooltipMsgs";

const sortState = {
  "userId": false,
  "amount": false,
  "createdDate": false,
  key: "",
  sortType: "",
  keyType: "",
}

const PendingWithrawalsSP = (props) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [auditDialog, setAuditDialog] = useState(false);
  const [audits, setAudits] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);

  const { statusType, viewAudit } = useSelector((state) => state.pendingWithrawal);
  const [viewAction, setViewAction] = useState(viewAudit);
  const [status, setStatus] = useState(statusType);
  const [masking, setMasking] = useState(false)
  const [sortables, setSortables] = useState({
    ...sortState
  })


  const callApi = (pageNumber = 1, itemsPerPage = 25) => {
    dispatch(setIsVisibleSP(false));
    dispatch(pendingwithdrawals(pageNumber - 1, itemsPerPage, status));
  };

  useEffect(() => {
    if (status !== "" && status !== statusType) {
      callApi(props.paginationFirstValue, props.paginationSecondValue);
    }
  }, [status]);

  useEffect(() => {
    const dataExist = Object.keys(props.pendingWithrawal).length > 0
    if (!dataExist) {
      callApi(props.paginationFirstValue, props.paginationSecondValue);
    } else {
      setCsvData(props.docsData)
    }
    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      setMasking(true)
    } else {
      setMasking(false)
    }
  }, []);


  const headers = [
    [
      "Customer ID",
      "Beneficiary Name",
      "Account Number",
      "IFSC",
      `Amount ${Currency()}`,
      "Mobile Number",
      "Email",
      "Created Date",
      "Comments",
    ],
  ];

  const exportPDF = () => {
    const title = "Pending Withdrawal Report";
    const styles = {
      3: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  const updateTableToDocs = (key = "", sortType = "", keyType = "") => {
    if (Object.keys(props.pendingWithrawal).length > 0) {
      const data =
        props.pendingWithrawal &&
        props.pendingWithrawal?.data &&
        Array.isArray(props.pendingWithrawal?.data) &&
        sortingFilter(props.pendingWithrawal?.data, { key, sortType, keyType }).map((p) => [
          p.userId,
          p.accountHolderName,
          p.accountNumber,
          p.ifscCode,
          formatMoney(p.amount),
          p.phone,
          p.email,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.comments,
        ]);
      setPdfBody(data);
      const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []]
      setCsvData(csvData);
      dispatch(setDocsData(csvData));

    }
  }

  useEffect(() => {
    updateTableToDocs();
  }, [props.pendingWithrawal]);

  const tabsChange = (item) => {
    props.dispatch(setUserId(item));
    props.dispatch(tabManager(+item, props.tabStack));
    localStorage.setItem("userId", item);
  };


  const statusHandler = (e) => {
    const state = e.target.value;
    const isPresent =
      Object.keys(props.userPagePermissions).length > 0 &&
      props.userPagePermissions.find(
        (item) => item.permissionName == "PAYMENT_VERIFY_ONLY"
      );
    if (e.target.value === "VERIFIED" && !isPresent) {
      setViewAction(false);
      dispatch(setViewAudit(false));
    }
    if (e.target.value !== "VERIFIED") {
      setViewAction(true);
      dispatch(setViewAudit(true));
    }
    dispatch(setStatusSelected(e.target.value));
    setStatus(e.target.value);

    setTimeout(() => dispatch(setStatusType(state)), 1000);
  };
  const handleAudit = (item) => {
    setRecordData(item);
    setOpenDialog(true);
  };

  const openAuditDialog = (audits) => {
    setAudits(audits);
    setAuditDialog(true);
  };

  const handleSortFields = (key, keyType) => {
    const intializing = {
      ...sortState
    }

    const sortType = !sortables[key] ? "ASCENDING" : "DESCENDING"

    setSortables((prevState) => {
      return {
        ...intializing,
        [key]: !prevState[key],
        key: key,
        sortType: sortType,
        keyType: keyType
      }
    });
    updateTableToDocs(key, sortType, keyType);

  }
  const sortKeys = {
    key: sortables.key,
    sortType: sortables.sortType,
    keyType: sortables.keyType
  }
  return (
    <>
      <>
        <ActionDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          recordData={recordData}
          status={status}
          callApi={callApi}
        />
        <AuditDialog
          auditDialog={auditDialog}
          setAuditDialog={setAuditDialog}
          audits={audits}
        />
      </>

      <div className='CMS-tab-panel active'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result' id='result'>
            <div className='col-md-6 col-lg-4 col-xl-4 mb-20'>
              <div className='CMS-formGroup formGroup-inline'>
                <div>
                  <div style={{ padding: "12px" }}>
                    {KYC_CONSTANTS.header_status}
                    &nbsp;&nbsp;
                    <Tooltips info={Payments.payment_status} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className='CMS-formControl'></div>
                  <div className='CMS-number-of-files CMS-select'>
                    <select
                      id='Withdrwalstatus'
                      name='status'
                      value={status}
                      onChange={statusHandler}
                      className="pointer"
                    >
                      {/* <option value=''>All</option> */}
                      <option value='INIT'>Pending</option>
                      <option value='VERIFIED'>Verified</option>
                      <option value='HOLD'>Hold</option>
                      {/* <option value='ACCEPTED'>Accepted</option>
                            <option value='DECLINED'>Declined</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='CMS-pagination '>
              <Vpagination
                data={props.pendingWithrawal.data}
                totalRecords={props.pendingWithrawal.totalRecords}
                paginationFirstValue={props.paginationFirstValue}
                paginationSecondValue={props.paginationSecondValue}
                setPaginationFirstValue={setPaginationFirstValueSP}
                setPaginationSecondValue={setPaginationSecondValueSP}
                recordsShow={props.recordsToShow}
                setRecordsShow={setRecordsToShowSP}
                isVisible={props.isVisible}
                setIsVisible={setIsVisibleSP}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callApi}
                csvFileName={"Pending Withdrawal Report"}
              />
            </div>
            {
              props?.isVisible ?
                (<div className='CMS-box'>
                  <div className='CMS-table CMS-table-triped'>
                    <table>
                      <thead>
                        <tr>
                          <th className="pointer" onClick={() => handleSortFields("userId", "num")}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.header_CID}</p>
                              <Tooltips info={COMMON_MSGS.cid} />
                              <span
                                className='material-icons md-18'
                                data-icon={
                                  sortables.userId ? "north" : "south"
                                }
                              ></span>
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.header_userName}</p>
                              <Tooltips info={COMMON_MSGS.userName} />
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_accountNo}</p>
                              <Tooltips info={BankInfo.accountNo} />
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{BANKDETAIL_CONSTANTS.th_ifsc}</p>
                              <Tooltips info={BankInfo.ifsc} />
                            </div>
                          </th>
                          <th className="pointer" onClick={() => handleSortFields("amount", "num")}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{`Amount  ${Currency(
                                localStorage.getItem("currency")
                              )}`
                              }</p>
                              <Tooltips info={COMMON_MSGS.Amount} />
                              <span
                                className='material-icons md-18'
                                data-icon={
                                  sortables.amount ? "north" : "south"
                                }
                              ></span>
                            </div>
                          </th>
                          <th>{COMMON_CONSTANTS.header_mobileNo}</th>
                          <th>{COMMON_CONSTANTS.header_email}</th>
                          <th className="pointer" onClick={() => handleSortFields("createdDate", "date")}>
                            {COMMON_CONSTANTS.createdDate}
                            <span
                              className='material-icons md-18'
                              data-icon={
                                sortables.createdDate ? "north" : "south"
                              }
                            ></span>
                          </th>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.createdBy}</p>
                              <Tooltips info={COMMON_MSGS.createdBy} />
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.updatedBy}</p>
                              <Tooltips info={COMMON_MSGS.updatedBy} />
                            </div>
                          </th>
                          {/* <th className="pointer" onClick={() => handleSortFields("updatedDate", "date")}>
                        Updated Time
                        <span
                            className='material-icons md-18'
                            data-icon={
                            sortables.updatedDate ? "north" : "south"
                            }
                        ></span>
                      </th> */}
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_comments}</p>
                              <Tooltips info={COMMON_MSGS.comment} />
                            </div>
                          </th>
                          {viewAction && <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                              <Tooltips info={COMMON_MSGS.Action} />
                            </div>
                          </th>}
                        </tr>
                      </thead>
                      <tbody>
                        {props.pendingWithrawal &&
                          props.pendingWithrawal?.data &&
                          props.pendingWithrawal?.data !== undefined &&
                          Array.isArray(props.pendingWithrawal?.data) &&
                          props.pendingWithrawal?.data?.length ? (
                          sortingFilter(props.pendingWithrawal?.data, { ...sortKeys })?.map((item, index) => {
                            return (
                              <tr key={generateKey(index)}>
                                <td>
                                  <Link
                                    to={`/customerid/${item.userId}`}
                                    onClick={() => tabsChange(item.userId)}
                                  >
                                    {item.userId}
                                  </Link>
                                </td>
                                <td>{masking && Number(item.accountHolderName)
                                  ? hideMobile(item.accountHolderName) : item.accountHolderName}</td>
                                <td>{item.accountNumber}</td>
                                <td>{item.ifscCode}</td>
                                <td className='text-right'>
                                  {formatMoney(item.amount)}
                                </td>
                                <td>{masking ? hideMobile(item.phone) : item.phone}</td>
                                <td>{masking ? hideEmail(item.email) : item.email}</td>
                                <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                                <td>{item.addedBy}</td>
                                <td>{item.updatedBy}</td>
                                <td>
                                  <button
                                    className='CMS-btn CMS-btnSecondary active CMS-btnSmall CMS-btnView'
                                    style={{ cursor: "pointer" }}
                                    id='WithdrwView'
                                    onClick={() => openAuditDialog(item.tnxAudits)}
                                  >
                                    {COMMON_CONSTANTS.view_btn}
                                  </button>
                                </td>
                                {viewAction && (
                                  <td>
                                    <button
                                      className='CMS-btn CMS-btnSecondary active CMS-btnSmall CMS-btnView'
                                      id='WithdrawAudit'
                                      onClick={() => handleAudit(item)}
                                    >
                                      {PAYMENT_CONSTANTS.audit_btn}
                                    </button>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <No__Data__Found colspan={100} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>)
                : (
                  <Skelton />
                )
            }
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    paginationFirstValue: state.pendingWithrawal.paginationFirstValueSP,
    paginationSecondValue: state.pendingWithrawal.paginationSecondValueSP,
    pendingWithrawal: state.pendingWithrawal.pendingWithrawalSP,
    userId: state.playersearch.userId,
    recordsToShow: state.pendingWithrawal.recordsToShowSP,
    isVisible: state.pendingWithrawal.isVisibleSP,
    userPagePermissions: state.dashboard.userPagePermissions,
    docsData: state.pendingWithrawal.docsDataSP,
    tabStack: state.sidebar.tabStack
  };
}

export default connect(mapStateToProps)(PendingWithrawalsSP);
