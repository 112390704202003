export const COMMON_MSGS = {
    Start_Date: "Helps you to filter out the players by certain time period",
    End_Date: "Helps you to filter out the players by certain time period",
    User_Name: "Helps you to filter out the players by their User Name",
    Action_Type: "Helps you to filter out the results as per the action type Eg:Login,Logout..etc",
    createdDate: "Showing creation date",
    updatedDate: "Showing updated date",
    createdBy: "Showing created By",
    Amount: "Showing the amount",
    state: "Showing the state",
    Action: "Showing Action to be Taken",
    cid: "Showing customer Id",
    updatedBy: "Showing Updated By",
    comment: "Showing all comments",
    userName: "Showing User Name",
    Aff_artWork: "Allow user to upload Images/Artwork for promotional offers and marketing purpose as per business requirement",
    Bonus_Awarded: "Showing bonus awarded for all bonus types",
    AllUsers: "By Enabling the toggle it will applicable for all Users",
    Actions: "Showing Actions to be taken",
    Actions: "Showing Actions to be taken",
    Status: "Showing Status of Bank Details"
}

export const PLAYER_MODULE = {
    roles: "Helps you to filter out the list as per the Role",
    Currency: "Gives you access to a list of players using the chosen currency",
    Account_Status: "Helps you to filter out the players by the status of account based on user activity  Eg: Active",
    DOB: "Helps you to filter out the players by their DOB",
    Reg_Date: "Helps you to filter out the players by their Registration Date",
    Tag_Name: "Provides access to a list of players based on Tag Type Eg: Register Dir",
    Btag: "Specifies a code that gives a user base who have registered through an affiliate",
    KYC_Status: "Search users based on KYC status Eg: Verified Users/Unverified Users",
    Sports: "Helps you to filter out the players based on most betted sports",
    State: "Helps you to filter out the players based on their states",
    Tag_type: "Search users based on Tag type Eg: Custom/System",
    Sub_type: "Enables for the selection of subtypes based on tag type.  Eg: VIP (Custom Tag)",
    Tag_Name_plActivity: "Allows you to choose a created/generated tag  Eg: VIP2023 (Custom - VIP), Deposit First (System Tag -Automatically generated tags)",
    User_Type: "Helps You filter out as per User Type Eg:Real/Test/Fraud",
    firstName: "You can edit the User's First Name Here",
    LastName: "You can edit the User's Last Name Here",
    Email: "You can edit the User's Email Id Here",
    Edit_DOB: "You can edit the User's DOB Here",
    Edit_Usertype: "You can edit the User Type Here",
    newPassword: "Update User's New Password",
    confirmPassword: "Confirm User's New Password",
    userStatus: "Gives You access for changing the User Account Status Eg:Activate,Deactivate,Pending Deactivate",
    maxCount: "Showing Max Bet count Range",
    minCount: "Showing Min Bet count Range",
}

export const Tagging_Segmentation = {
    Tag_Type: "Type of Tag which attributes to the user",
    Upload_Tags: "Upload list of usernames to which tag will apply",
    Assign_tagName: "List of all available tag names"
}

export const Bonusing = {
    Opt_In: "Bonus Type Assigned to the User Eg: Auto/Manual",
    bonus_search: "Filter out the bonuses based on their names",
    Apply_Bonustype: "Choose the bonus type that will apply to the user. Eg: Freebet/Coupon code",
    Available_Bonus: "Select the bonus name created  Eg:NEWYEAR2023",
    Upload_Type: "Select the Upload Type  Eg: Single upload/Bulk upload(Bulk Upload-  Upload the list of users to which bonus will apply)",
    sports: "Select the list of sports Eg:Soccer,Cricket..etc",
    bonusType: "Showing Bonus Type Choosen",
    bonusCode: "Showing Bonus Code Used",
    bonus: "Showing Bonus",
    Wager_Bonus: "Showing wager Bonus",
    instant_Bonus: "Showing instant Bonus",
    maxWinAmnt: "Max amount user wins using bonus amount",
    maxBonus: "Showing Max Bonus",
    rollOver: "Showing Rollover Times",
    bonusCriteria: "Showing Bonus Criteria",
    bonusStatus: "Showing Bonus Status",
    applyExpiry: "Showing how long the bonus is valid",
    bonusCriteria: "Showing Bonus Criteria",
    minStakeAmount: "Showing Minimum Stake Amount",
    minCashBackOdds: "Showing Minimum Cashback Odds",
    minCashBackLegs: "Showing Minimum Cashback Legs",
    cashBackPercentage: "Showing Cashback Percentage",
    maxCashBackAmount: "Showing Maximum Cashback Amount",
    cashBackBetType: "Showing Cashback Bet Type",
    cashBackBonusApplicable: "Showing Cashback bonus Applicable For",
    bettingType: "Showing Betting Type",
    bettingType: "Showing Cashback Frequency",
    cashBackType: "Showing Cashback Type",
    addToWithdrawableAmount: "Showing Add to Withdrawable Amount",
    bonusSportSelection: "Showing Bonus Sport selection for",
    startDate: "Showing bonus applicable start date",
    validUntil: "Showing bonus valid till date",
    maxWinPercent: "Max % user can win using bonus amount",
    depoRelease: "Showing the Deposit Release",
    depositCriteria: "Showing Deposit Criteria",
    wagerCriteria: "Showing Wager Criteria",
    minDeposit: "Minimum amount to deposited for the bonus",
    referrer: "The % bonus to be released to Referrer",
    referee: "The % bonus to be released to Referee",
    bonusPayout: "The % of settlement amount user will get",
    minStakeAmnt: "Minimum amount wagered for the bonus",
    noLegs: "Number of selections added to the Betslip",
    affiliate: "Showing Affiliate UserName",
    affiliateBtag: "Showing Affiliate User Name",
    bonusDesc: "Showing Bonus Description",
    providerName: "Showing Provider Name",
    gameName: "Showing Game Name",
    unlockProvider: "Showing Unlock Provider Name",
    unlockGameName: "Showing Unlock Game Name",
    sports: "Showing bonus applicable for certain sports",
    applicable_sports: "Showing applicable sport(RC:Release Criteria)",
    oddThreshold: "odds to be added to the bet selections",
    RC_oddThreshold: "Showing odds Threshold (RC:Release Criteria)",
    applicableTo: "bonus categories Eg:Sports/Casino/Global",
    RC_applicableTo: "bonus categories (RC:Release Criteria)",
    tags: "Type of Tag which attributes to the user",
    Allowtags: "Type of Allow Tags which attributes to the user",
    DenyTags: "Type of Deny Tags which attributes to the user",
    brand: "Showing Brand",
    optin_tb: "Bonus Type Assigned to the User",
    provider: "Showing Aggregator Name",
    game_name: "Showing Game Name",
}

export const Payments = {
    Opt_In: "Select the payment type Eg: Auto/Manual",
    Transaction_Type: 'Select the Transaction type Eg: Deposit/Withdrawal',
    Adjustment_Type: 'Helps you to filter out the transactions that are adjusted manually',
    Status: 'Helps you to filter out the transactions based on the Transaction status Eg: Confirmed',
    start_date: 'Filter out the results as per start date of Transaction',
    end_date: "Filter out the results as per end date of Transaction",
    payment_method: "Select the Payment methods Eg:Gpay,paystack..etc",
    payment_status: "Gives you a list of transactions that need manual approval",
    Withdrawal_Limit: "Allows you to set the automated withdrawal limits for the user",
    Payment_limits: "Allows you to set the Payment limits for the user. Eg: Deposit Limits/Withdrawal Limits",
    Operation: "Select Operation Type Eg:Deposit/Withdraw",
    manual_Type: "Select the Type",
    Amount: "Enter the amount for manual adjustment",
    manual_comment: "Enter your comment for the manual payment",
    manual_wallet: "Select your wallet type",
    TDS_Percent: "Specifies the tax percentage that should be collected from the user on transactions (Deposit/Withdrawal)",
    Platform_Fee: "It is a fee that a platform charges to their users for using their site",
    TAX_Percent: "Specifies the percentage should be collected from the user which is applicable on Platform Fee",
    PF_Tax_Toggle: "Allows you to enable or disable PF & Tax based on business requirement",
    payment_type: "Select the Payment Type Eg:Card,USSD,Bank Transfer etc..",
    TNX_status: "Select transaction status Eg:Failed,success etc..",
    DepoMinLimit: "Enter user's minimum deposit limit",
    DepoMaxLimit: "Enter user's maximum deposit limit",
    WithdrawMinLimit: "Enter user's minimum withdrawal limit",
    WithdrawMaxLimit: "Enter user's maximum withdrawal limit",
    paymentType: "Enter the Payment Method Type Eg:Card,Ussd Etc",
    depoProofSts: "Helps you to filter out the Deposit proof records based on the status Eg: accepted",
    amountRange: "Helps you to filter the transaction as per given range",
    psearchBtag: "Help you to filterout Transaction as per BTag",
    totalDepo: "Total Deposit Amount",
    totalWithdraw: "Total withdrawal Amount",
    winning_Tax: 'This tax is levied on the profits paid out to the punters, for all the winning bets on the platform',
    stake_Tax: ' This tax is levied on the stake for all the bets on the platform for a region',
    playerCasout: 'Update Player Cashout either Enable or Disable',
    paymentTypes: 'Payment Type',
    nftTradeFee: 'Showing NFT Trade Fee',
    bettingTax: "Shwoing Betting Tax",
}

export const Betting = {
    Game_Type: "Helps you to filter out the records based on the game type  Eg: Sports/Casino",
    Bet_Type: "Helps you to filter out the records based on the bet type  Eg: Single/Multi",
    Bet_Status: "Helps you to filter out the records based on the bet status  Eg: Won/Half Won",
    Bet_fold: "Helps you to filter out the records based on the bet fold  Eg: 1,2,3",
    dates: "Helps you to filter out the results as per selected Dates",
    settledDates: "Helps you to filter out the results as per selected Settlement Dates",
    sport: "Helps you to filter out the results as per selected sports",
    multi_Bonus_type: "Helps in calculating the bonus amount that was paid out based on the choice you made Eg: Multibet/One cut",
    bet_Status: "Helps you to filter out Bets as per Bet Status Eg:Settled,Running etc..",
    stakeRange: "Helps you to filter out Bets as per Stake Range",
    payoutRange: "Helps you to filter out Bets as per Potential Payout Range",
    userRole: "Helps you to filter out Bets as per User Role",
    userName: "Helps you to filter out Bets as per User Name"
}

export const Admin = {
    Password: "Need to create a appropriate password for the certain user",
    Assign_Role: "Assigning specific roles to the user",
    Brand_Id: "Select the brand for creation of role Eg: Starbet",
    Permission_Name: "Specifies the user's required permissions/access based on the role  Eg:Reports",
    role: "Defines the role of the user",
    Client_Id: "Select the brand for creation of permission Eg: Starbet",
    Create_Permission_Name: "Allows the creation of site specific permissions Eg: Players/Report",
    Affiliate_Type: "Allows you to provide the type of affiliate based on revenue to be allocated to the affiliate Eg: Fixed revenue/Revenue share"
}

export const BrandConfig = {
    Client_Name: "Facilitates the creation of new clients to access the site",
    Brand_Name: "Provides the option to create a unique brand name for each client to visit the website",
    Brand_Client_Name: "Enter the client name that was created for site access",
    Brand_Currency: "Allows for the specification of the currency in accordance with client needs"
}

export const Notes = {
    Category: "Select what kind of Note you wanted to add",
    Note: "Enter your Notes"
}

export const Gambling = {
    limits: "Select the Gambling Limits as per requirment",
    Time_Limits: "Select the time limits Eg:Daily/Weekly/Monthly",
    bet_Limits: "Enter the number of Gambling limits",
    Exclusion_Period: "Select the self exclusion period for the user",
}

export const betTickerInfo = {
    cid: "Showing Customer Id",
    TID: "Showing the Transaction Id",
    Amount: "Showing Amount",
    Status: "Showing Status",
    PaymentDesc: "Showing Payment Description",
    PaymentMethod: "Showing Payment Method",
    TransRef: "Showing Transaction Ref",
    TransType: "Showing Transaction Type",
    TransDatee: "Showing Transaction Date",
}

export const NoteInfo = [
    "Showing Created By", "Showing ID", "Showing Create Time", "Showing the Category", "Showing the Text you have added"
]

export const BankInfo = {
    ahn: "Showing AHN",
    bankName: "Showing Bank Name",
    branchName: "Showing Branch Name",
    accountNo: "Showing Account Number",
    ifsc: "Showing Bank code",
}

export const betHistoryInfo = {
    BetId: "Showing BetId",
    shorbetId: "Showing Short Bet ID",
    eventName: "Showing Event Name",
    rejection: "Showing Rejection Code",
    betStatus: " Showing Bet Status",
    Placed_On: "Showing the date bet placed on",
    Settled_On: "Showing the date bet settled On",
    Betfold: "Showing BetFold info",
    BetType: "Showing Bet Type",
    Stake: "Showing Stake info",
    Payout: "Showing Payout info",
    Audit: "Showing Audit info",
    Potential_Payout: "Showing Potential Payout",
    Stake_Tax: "Showing Stake Tax",
    Stake_AfterTax: "Showing Stake After Tax",
    Stake_TaxAmount: "Showing Stake Tax Amount",
    Winnings_Tax: "Showing Winnings Tax",
    Winnings_TaxAmount: "Showing Winnings Tax Amount",
    Total_Payout: "Showing Total Payout",
    Total_PayoutWithBonus: "Showing Total Payout With Bonus",
    Bonus_MaxWinAmount: "Showing Bonus Max Win Amount",
    Bonus_MaxWinPercentage: "Showing Bonus Max Win Percentage",
    Place_BetType: "Showing Place Bet Type"
}

export const paymentHistoryInfo = {
    txId: "Showing Transaction Id",
    tdsAmount: "Showing Tds amount",
    tdspercent: "Showing Tds percentage",
    method: "Showing payment methods",
    paymentType: "Showing payment Type",
    paymentDesc: "Showing payment description",
    bankName: "Showing Bank Name",
    transRef: "Showing Transaction Ref",
    action: "Showing Action taken"
}

export const TagInfo = {
    tagType: "Showing Tag Types",
    tagName: "Showing Tag Name",
    Applied_On: "Showing Applied on",
    status: "Showing Status",
    action: "Showing Actions",
}

export const brandInfo = {
    brandName: "Showing Brand Name",
    brandId: "Showing Brand Id",
    Brand_Currency: "Showing respective currencies",
    Client_Name: "Showing Client Names",
}

export const Payment = {
    paymentSts: "Showing payment Status",
    UTR_no: "Showing UTR numbers",
    txn_Id: "Showing TXN Id",
    betSlip_No: "Showing Number of Events on betslip",
    bonus_Perecnt: "Showing Bonus %",
    pending_withdraw_Amnt: "Showing Pending Withdrawal Amount",
    request_Date: "Showing Withdrawal Request Date",
    depoProof_docs: "Showing Deposit Proof Document View",
    payment_reference: "Showing Payment Reference"
}

export const BrandData = {
    Config_Key: "Configuration keys allow admins to enable or disable features in the site for all users based on the business requirement",
    Config_Value: "Allows you to provide values for the configured keys",
    key_Name: "Provides the name of the brand key Eg: Wallet, MTS, FEAPI",
    key_Status: "provides the access on Key Status in terms of Active/Deactive",
    multibet_toggle: "Allows you to enable or disable multibet configuration for awarding bonus % to the user on multiple bet selections"
}

export const BrandKeyList = [
    "Provides the access to the API key",
    "Provides the name of the brand key Eg: Wallet, MTS, FEAPI",
    "Provides the Brand's unique ID",
    "Provides th ID who has added brand key to the brand",
    "Provides the access to the API Secret Key",
    "provides the access on Key Status in terms of Active/Deactive",
    "Showing Key created Date",
    "Showing key updated Date",
    "Showing Created By",
    "Showing Updated By",
    "Showing Update Status"
]

export const CurrencyConvertInfo = [
    "Gives you the option to specify the currency you need converting from",
    "Enables you to indicate the currency to which conversion is needed",
    "Allows you  to specify the ratio between two different currencies",
    "Allows you to Edit the conversion rates"
]

export const CurrencyIP = {
    Currency_From: "Gives you the option to specify the currency you need converting from",
    Currency_To: "Enables you to indicate the currency to which conversion is needed",
    Conversion_Rate: "Allows you  to specify the ratio between two different currencies",
}

export const CreateUserInfo = [
    "Provide User Ids",
    "Provides User Name ",
    "Provides First Name",
    "Provides Last Name",
    "Provides User Created Date",
    "Provides user info Updated Date",
    "Provides user Created By",
    "Provides user Updated By",
    "Provides user Role assigned",
    "Provides user Btag",
    "Provide Edit User Actions"
]

export const RoleInfo = [
    "Showing IDs",
    "Showing Role Name",
    "Showing All permissions assigned to the role",
    "Showing Client Id",
    "Showing created Data",
    "Showing Updated Date",
    "Showing role created By",
    "Showing role updated By",
    "Showing role Action to be edit"
]

export const PermissionInfo = [
    "Showing ID",
    "Showing Permission Name",
    "Showing Client ID",
    "Showing Created Date",
    "Showing Updated Date",
    "Showing permission Created By",
]

export const BecomeAffInfo = [
    "Showing First Name",
    "Showing Last Name",
    "Showing Phone Number",
    "Showing Whatsapp Number",
    "Showing Email Address",
    "Showing Location City",
    "Showing Gender",
    "Showing DOB",
    "Showing Created Date",
    "Showing Updated Date",
    "Showing Updated By",
    "Showing User Name",
    "Showing Channel",
    "Showing Action"

]

export const channelInfo = [
    "Showing Id",
    "Showing Channel Name",
    "Showing AffiliateId",
    "Showing Created Date",
    "Showing Updated Date",
    "Gives the total number of registrations made by the specific affiliate.",
    "Gives the total number of users who has made deposits",
    "Gives the total amount of deposits made by users.",
    "Showing New Customers",
    "Gives the total amount wagered by the users",
    "Gives the total amount paid to users who have won the bets.",
    "It is the revenue that the company generates from the betting",
    "Gives the total bonus amount awarded to the users",
    "Showing Total Number of Bets",
    "Showing Total Withdrawals",
    "Showing Number of Clicks",
]

export const CampaignInfo = [
    "Showing Id",
    "Showing Campaign Name",
    "Showing Created Date",
    "Showing Updated Date",
    "providing all Campaign",
    "Gives the total number of registrations made by the specific affiliate.",
    "Gives the total number of users who has made deposits",
    "Gives the total amount of deposits made by users.",
    "Showing New Customers",
    "Gives the total amount wagered by the users",
    "Gives the total amount paid to users who have won the bets.",
    "It is the revenue that the company generates from the betting ",
    "Gives the total bonus amount awarded to the users",
    "Showing Total Number of Bets",
    "Showing Total Withdrawals",
    "Showing Number of Clicks",
]

export const AffiliateInfo = [
    "Showing ID",
    "Showing User Name",
    "Showing First Name",
    "Showing Last Name",
    "provide the type of affiliate based on revenue to be allocated to the affiliate Eg: Fixed revenue/Revenue share",
    "Showing Btag",
    "Define the time period for making payout",
    "The percentage to be collected for site setup",
    "Showing Setup Cost",
    "Showing Gross Commission",
    "Showing Net Commission",
    "The percentage to be given to the affiliate in accordance with the revenue generated",
    "Gives the total number of registrations made by the specific affiliate.",
    "Gives the total number of users who has made deposits",
    "Gives the total amount of deposits made by users.",
    "Gives the total amount wagered by the users",
    "Gives the total amount paid to users who have won the bets",
    "It is the revenue that the company generates from the betting ",
    "Gives the revenue excluding Free Bet costs",
    "Gives the total bonus amount awarded to the users",
    "Showing Total Number of Bets",
    "Showing Total Withdrawals",
    "Showing First Time Deposited Customer",
    "Showing Created Date",
    "Showing Updated Date",
    "Allows you to add campign name / channel name for marketing the site Media",
    "Allows you to Activate/Deactivate the affiliate and edit the Commission %, Payout Cycle & Setup cost based on the business requirenents",
    "Showing Number of Clicks",
    "Allows you to edit the user details",
    "Showing Pay"
]

export const Dashboard = {
    Turnover: "Gives the total amount wagered by the users",
    Bet_Count: "Gives the total number of bets placed by the users on the site",
    Margin: "This is the percentage of Gross Gaming Revenue received from all the bets to the amount wagered by the players",
    GGR: "Gives the revenue that the company generates from the betting",
    Free_Bet_Cost: "The free bets provided by the operator to the players at free of cost",
    NGR: "Gives the revenue excluding Free Bet costs",
    Total_Registrations: "Gives the total number of registrations done by the users on the site",
    Total_Amount_Deposited: "Gives the total amount of deposits made by users",
    Total_Withdrawals: "Gives the total amount of withdrawals made by users",
    Total_Stake: "Gives the total amount wagered by the users",
    Total_Payout: "Gives the total amount paid to users who have won the bets"
}

export const RiskMgmnt = {
    IP_Address: "Provides the Address of IP from which the user has logged in to the site",
    No_Users: "Provides the total number of users who has logged into the site from the same IP",
    Cid_frmIp: "Provides Customers Ids who logged though same Ip",
    Mac_Address: "Provides the Mac Address from which the user has logged in to the site",
    Event_Name: "Provides the list of events that the user placed bets on",
    Selection_name: "Provides the list of Selections that the user has made while placing a bet",
    market_name: "Showing Market Name",
    syndicate_users: "Provides the total number of users who have wagered on the respective events with the same selections",
    betId: "Showing Bet Id",
    createdDate: "Showing Created Date",
    dateofevent: "Showing Date of The Event"
}

export const betAudit = [
    "Showing User ID",
    "Showing Bet Selection Info",
    "Showing Bet Status",
    "Showing Odds",
    "Showing Payouts",
    "Showing Updated Dates"
]

export const communication_module = {
    promo_title: "Helps you to filter with Promo Title",
    promo_sts: "Helps you to filter with status",
    promo_title: "Enter the promo title with max 50 Characters",
    msgformat_type: "Select Message Format Type",
    promoBanner: "Select the desired Banner to upload (accept only Jpeg, png file format)",
    shortMsg: "Enter the short message with max 144 Characters",
    longMsg: "Enter the short message with max 320 Characters",
    longMsgIp: "Long Message (320 Word)",
    shortMsg: "Short Message (144 Characters)",
    userTag: "Select User Tag",
    priority: "Select the Priority",
    promostartDate: "Select Start Date and Time",
    promoEndDate: "Select Start Date and Time",
    promo_schedule: "Select schedule send Date and Time",
    actionbtn_Name: "Enter Action Button Name",
    action_Link: 'Enter the Action Link',
    accountMsg_Tittle: 'Enter the Tittle within 50 Characters',
    content_type: "Select the content type",
    priority: "Select the Priority"
}

export const boosted_odds = {
    Clients: "Showing List of Clients",
    Tags: "Tag which attributes to the user",
    Sports: "List of all available sports for that client.",
    Countries: "List of all countries for the selected sports.",
    Leagues: "List of all leagues for the selected sport wise country.",
    Events: "List of all Pre events for the selected league.",
    Markets: "A specific type of a bet on a particular event.",
    Outcomes: "A possible outcomes of a market.",
    Odds: "measure of the likelihood of a outcome.",
    Maximum_Stake: "Maximum amount of money on a selection",
    Boosted_percent: "The percentage of odds boosted value.",
    Boosted_odds: "measure of the likelihood of a boosted outcome.",
    Minimum_limit: "Minimum Boosted odds value",
    Maximum_limit: "Maximum Boosted odds value",
    Liability_limit: "Maximum amount of liability for that outcome.",
    Start_DateTime: "Boosted odds start date and time",
    End_DateTime: "Boosted odds end date and time.",
    Time_limit: "This gives the amount of time the boosted odds needs to be restricted.",
}