import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer } from "../../sharedfiles/helper";


export const FailedReportSlice = createSlice({
    name: "FailedReportSlice",
    initialState: {
        failedData: [],
        errMsg: []
    },
    reducers: {
        setFailedData: (state, action) => {
            state.failedData = action.payload
        },
        setErrMsg: (state, action) => {
            state.errMsg = action.payload
        }
    }
});

export const { setFailedData, setErrMsg } = FailedReportSlice.actions

export const getFailedData = (params) => (dispatch) => {
    const url = userApi.failedData
    apiCaller(url, "POST", params)
        .then((res) => { dispatch(setFailedData(res.data)) })
        .catch((err) => {
            console.log(err)
            const error = err.response
            dispatch(setErrMsg(messageEnhancer(error)))
        });
};

export default FailedReportSlice.reducer