import React, { useState, useEffect } from 'react'
import BarGraph from './Graphs/BarGraph'
import DataBox from '../Common/DataBox'
import GamesContainer from './GamesContainer';
import { Currency, MomentUTCDateTime, MomentUTCDateTimeEnd, MomentUTCDateTimeToday, formatNumberWithK, getnumberWithCommas } from '../../sharedfiles/helper';
import DashBoardTable from './DashBoardTable';
import LineChart from './Graphs/LineChart';
import DonutChart from './Graphs/DonutChart';
import PieChart from './Graphs/PieChart';
import UserActiveTimeChart from './Graphs/UserActiveTimeChart';
import { DASBOARD_CONSTANTS } from '../../sharedfiles/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getBetReportByGameType, getBonusReport, getOverallReviewReportByPeriod, getPaymnetReport, getSportsBetsReport, getTopTenSportsNeGamesCasino, getTopTenWinnerLooser, getTopTenWithdrawableUsers, getUserActiveTimesReportByType, getUserActivityReport, setPrevFormData } from './newDashboardSlice';
import Loading from '../loading/Loading';
import RegVsConversionChart from './Graphs/RegVsConversionChart';
import Tooltips from '../Common/Tooltip';
import "react-datepicker/dist/react-datepicker.css";
import DateInputField from "react-datepicker";
import ClrDataBox from '../Common/ClrDataBox';

const NewDashboard = () => {
    const dispatch = useDispatch()
    const [activeTime, setActiveTime] = useState('LOGIN')
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const prevFormData = useSelector((p) => p.NewDashboardSlice.prevFormData)
    const [isActive, setIsActive] = useState(false);

    const formDataToSliceSetter = (key, val) => {
        const newEntry = { ...prevFormData, [key]: val }
        dispatch(setPrevFormData(newEntry));
    }

    const stateSetter = () => {
        const isFormDataExist = Object.keys(prevFormData).length
        if (isFormDataExist) {
            Object.entries(prevFormData).map(([key, value]) => setDateRange((prevState) => {
                return {
                    ...prevState,
                    [key]: value,
                }
            }))
        }
    }
    const handleDateChange = (date, field) => {
        setIsActive(true)
        formDataToSliceSetter(field, date);
        setDateRange((prevDateRange) => ({
            ...prevDateRange,
            [field]: date,
        }));
        if (field === 'startDate' && dateRange.endDate) {
            const payload = {
                fromDate: MomentUTCDateTimeToday(date),
                toDate: MomentUTCDateTimeEnd(dateRange.endDate),
            };
            dispatch(getSportsBetsReport(payload));
            dispatch(getBetReportByGameType(payload))
            dispatch(getBonusReport(payload))
            dispatch(getPaymnetReport(payload))
            dispatch(getUserActivityReport(payload))
            dispatch(getUserActiveTimesReportByType(payload, 'LOGIN'))
        } else if (field === 'endDate' && dateRange.startDate) {
            const payload = {
                fromDate: MomentUTCDateTimeToday(dateRange.startDate),
                toDate: MomentUTCDateTimeEnd(date),
            };
            dispatch(getSportsBetsReport(payload));
            dispatch(getBetReportByGameType(payload))
            dispatch(getBonusReport(payload))
            dispatch(getPaymnetReport(payload))
            dispatch(getUserActivityReport(payload))
            dispatch(getUserActiveTimesReportByType(payload, 'LOGIN'))
        }
    };
    const maxDate = new Date();
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 3);

    const topTenWithdrawableUsers = useSelector((d) => d.NewDashboardSlice.topTenWithdrwableUsers)
    const topWinnerLooserList = useSelector((d) => d.NewDashboardSlice.topWinnerLooserList)
    const topTenSportsNeGamesCasino = useSelector((d) => d.NewDashboardSlice.topTenSportsNeGamesCasino)
    const overallReviewReport = useSelector((d) => d.NewDashboardSlice.overallReviewReport)
    const sportsBetReport = useSelector((d) => d.NewDashboardSlice.sportsBetReport)
    const sportCasinoNeGameData = useSelector((d) => d.NewDashboardSlice.sportCasinoNeGameData)
    const bonusData = useSelector((d) => d.NewDashboardSlice.bonusingData)
    const paymentAPIData = useSelector((d) => d.NewDashboardSlice.paymentData)
    const userActivity = useSelector((d) => d.NewDashboardSlice.userActivity)
    const activeTimeData = useSelector((d) => d.NewDashboardSlice.activeTimeData)

    const sportextractedDt = [sportCasinoNeGameData.sportsDetails].flat()
    const casinoExtractDt = [sportCasinoNeGameData.casinoDetails].flat()
    const neGameExtractedDt = [sportCasinoNeGameData.neGamesDetails].flat()

    const bonusMetrics = [bonusData.bonusMetrics].flat()
    const bonusVsCashStake = [bonusData.bonusVsCashStake].flat()
    const popularBonuses = [bonusData.popularBonuses].flat()

    const activeUserDetail = [userActivity.activeUsersDetails].flat()
    const loginChannelDetails = [userActivity.loginChannelDetails].flat()
    const regVsConvDetails = [userActivity.regVsConvDetails].flat()


    const sportBetsData = [
        { title: "Total Rejected Bets", data: sportsBetReport.map(obj => obj?.totalRejectedBets), info: DASBOARD_CONSTANTS.Total_Rejected_bets },
        { title: "Total Rejected Bets Amount", data: sportsBetReport.map(obj => getnumberWithCommas(obj?.totalRejectedBetAmount)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Rejected_bets_Amount },
        { title: "Total Cancelled Bets", data: sportsBetReport.map(obj => obj?.totalCancelledBets), info: DASBOARD_CONSTANTS.Total_Cancelled_bets },
        { title: "Total Cancelled Bets Amount", data: sportsBetReport.map(obj => getnumberWithCommas(obj?.totalCancelledBetAmount)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Cancelled_bets_Amount },
        { title: "Total Open Bets", data: sportsBetReport.map(obj => obj?.totalOpenBets), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Open_bets },
        { title: "Total Open Bets Amount", data: sportsBetReport.map(obj => getnumberWithCommas(obj?.totalOpenBetAmount)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Open_bets_Amount },
        { title: "Total Cashed out Bets", data: sportsBetReport.map(obj => obj?.totalCashedoutBets), info: DASBOARD_CONSTANTS.Total_Cashed_out_bets },
        { title: "Total Cashed out Bets Amount", data: sportsBetReport.map(obj => getnumberWithCommas(obj?.totalCashedoutBetAmount)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Cashedout_bets_Amount },
    ];

    const sportData = [
        { title: "Total Stake", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.totalStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Stake },
        { title: "Total payout without bonus", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.payoutWithoutBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout },
        { title: "Total payout with bonus", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.payoutWithBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout_with_Cashbonus },
        { title: "Bonus Stake", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.bonusStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Bonus_Stake },
        { title: "Average Stake", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.averageStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Average_Stake },
        { title: "Margin", data: sportextractedDt.map(obj => obj?.margin), currency: Currency(), info: DASBOARD_CONSTANTS.Margin },
        { title: "Total Liability", data: sportextractedDt.map(obj => getnumberWithCommas(obj?.totalLiability)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Liability },
        { title: "Bet Count", data: sportextractedDt.map(obj => obj?.betCount), info: DASBOARD_CONSTANTS.bet_count },
    ]
    const casinoData = [
        { title: "Total Stake", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.totalStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Stake },
        { title: "Total payout without bonus", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.payoutWithoutBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout },
        { title: "Total payout with bonus", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.payoutWithBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout_with_Cashbonus },
        { title: "Bonus Stake", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.bonusStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Bonus_Stake },
        { title: "Average Stake", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.averageStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Average_Stake },
        { title: "Margin", data: casinoExtractDt.map(obj => obj?.margin), currency: Currency(), info: DASBOARD_CONSTANTS.Margin },
        { title: "Total Liability", data: casinoExtractDt.map(obj => getnumberWithCommas(obj?.totalLiability)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Liability },
        { title: "Bet Count", data: casinoExtractDt.map(obj => obj?.betCount), info: DASBOARD_CONSTANTS.bet_count },
    ]
    const neGamesData = [
        { title: "Total Stake", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.totalStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Stake },
        { title: "Total payout without bonus", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.payoutWithoutBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout },
        { title: "Total payout with bonus", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.payoutWithBonus)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Payout_with_Cashbonus },
        { title: "Bonus Stake", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.bonusStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Bonus_Stake },
        { title: "Average Stake", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.averageStake)), currency: Currency(), info: DASBOARD_CONSTANTS.Average_Stake },
        { title: "Margin", data: neGameExtractedDt.map(obj => obj?.margin), currency: Currency(), info: DASBOARD_CONSTANTS.Margin },
        { title: "Total Liability", data: neGameExtractedDt.map(obj => getnumberWithCommas(obj?.totalLiability)), currency: Currency(), info: DASBOARD_CONSTANTS.Total_Liability },
        { title: "Bet Count", data: neGameExtractedDt.map(obj => obj?.betCount), info: DASBOARD_CONSTANTS.bet_count },
    ]
    const bonusingData = [
        { title: "Total Bonus Awarded", data: bonusMetrics.map(obj => getnumberWithCommas(obj?.totalBonusAwarded)) },
        { title: "Total Bonus Redeemed", data: bonusMetrics.map(obj => getnumberWithCommas(obj?.totalBonusRedeemed)), currency: Currency() },
        { title: "User Active Bonuses", data: bonusMetrics.map(obj => obj?.userActiveBonuses) },
        { title: "Total Unlocked Bonus", data: bonusMetrics.map(obj => obj?.totalUnlockedBonus), currency: Currency() },
        { title: "Total Rollover Amount", data: bonusMetrics.map(obj => getnumberWithCommas(obj?.totalRolloverAmount)), currency: Currency() },
        { title: "Total Converted Rollover Amount", data: bonusMetrics.map(obj => getnumberWithCommas(obj?.totalConvertedRolloverAmount)), currency: Currency() },
        { title: "Total Multibet Bonus Awarded", data: bonusMetrics.map(obj => getnumberWithCommas(obj?.totalMultibetBonusAwarded)), currency: Currency() },
        { title: "Total CUT1/CUT 2 Awarded", data: bonusMetrics.map(obj => obj?.totalCut1Cut2Awarded), currency: Currency() }
    ]

    const paymentData = [
        { title: "Total Deposits", data: paymentAPIData.map(obj => getnumberWithCommas(obj?.totalDeposits)), currency: Currency() },
        { title: "Total Withdrawals", data: paymentAPIData.map(obj => getnumberWithCommas(obj?.totalWithdrawals)), currency: Currency() },
        { title: "Unique Depositors", data: paymentAPIData.map(obj => obj?.uniqueDepositors) },
        { title: "Unique Withdrawers", data: paymentAPIData.map(obj => obj?.uniqueWithdrawals) },
        { title: "Pending Withdrawals", data: paymentAPIData.map(obj => obj?.pendingWithdrawals) },
        { title: "Total Withdrawable Balance", data: paymentAPIData.map(obj => getnumberWithCommas(obj?.totalWithdrawableBalance)), currency: Currency() },
        { title: "Average Deposit", data: paymentAPIData.map(obj => getnumberWithCommas(obj?.averageDeposit)), currency: Currency() },
        { title: "Average Withdrawals", data: paymentAPIData.map(obj => getnumberWithCommas(obj?.averageWithdrawal)), currency: Currency() },
    ]

    const top10WitdrwableUser = topTenWithdrawableUsers.data
        ? [
            ["Player Id", "Total Deposit", "Total Withdrawals", "GGR", "Total Withdrawable Balance"],
            ...topTenWithdrawableUsers.data.map(user => [
                user.playerId,
                getnumberWithCommas(user.totalDeposits),
                getnumberWithCommas(user.totalWithdrawals),
                getnumberWithCommas(user.ggr),
                getnumberWithCommas(user.totalWithdrawableBalance)
            ])
        ]
        : [];


    const top10Winner = topWinnerLooserList.winnersDetails ?
        [
            ["Player Id", "Total Stake", "Total Payout", "Total Bonus Stake", "GGR", "NGR", "Liability", "Most Played"],
            ...topWinnerLooserList.winnersDetails.map(item => [
                item.playerId,
                getnumberWithCommas(item.totalStake),
                getnumberWithCommas(item.totalPayout),
                getnumberWithCommas(item.totalBonusStake),
                getnumberWithCommas(item.ggr),
                getnumberWithCommas(item.ngr),
                getnumberWithCommas(item.liability),
                item.mostPlayedSport
            ])
        ] : []


    const top10Looser = topWinnerLooserList.losersDetails ?
        [
            ["Player Id", "Total Stake", "Total Payout", "Total Bonus Stake", "GGR", "NGR", "Liability", "Most Played"],
            ...topWinnerLooserList.losersDetails.map(item => [
                item.playerId,
                getnumberWithCommas(item.totalStake),
                getnumberWithCommas(item.totalPayout),
                getnumberWithCommas(item.totalBonusStake),
                getnumberWithCommas(item.ggr),
                getnumberWithCommas(item.ngr),
                getnumberWithCommas(item.liability),
                item.mostPlayedSport
            ])
        ] : []

    const top10Sports = topTenSportsNeGamesCasino.sportsDetails ?
        [
            [
                "Sport Name",
                "Total Stake",
                "Total Payout",
                "Total Bonus Stake",
                "GGR",
                "NGR",
                "Liability",
                "Margin"
            ],
            ...topTenSportsNeGamesCasino.sportsDetails.map(item => [
                item.sportName,
                getnumberWithCommas(item.totalStake),
                getnumberWithCommas(item.totalPayout),
                getnumberWithCommas(item.totalBonusStake),
                getnumberWithCommas(item.ggr),
                getnumberWithCommas(item.ngr),
                item.liability,
                item.margin,
            ])
        ] : []


    const top10CasinoGames = topTenSportsNeGamesCasino.neGamesCasinoDetails ?
        [
            ["Provider Name", "Game Name", "Total Stake", "Total Payout", "Total Bonus Stake", "GGR", "NGR", "Margin"],
            ...topTenSportsNeGamesCasino.neGamesCasinoDetails.map(item => [
                item.providerName,
                item.gameName,
                getnumberWithCommas(item.totalStake),
                getnumberWithCommas(item.totalPayout),
                getnumberWithCommas(item.totalBonusStake),
                getnumberWithCommas(item.ggr),
                getnumberWithCommas(item.ngr),
                item.margin,
            ])
        ] : []

    const fourTableData = [
        { tableTitle: "Top 10 Sports", tableData: top10Sports },
        { tableTitle: "Top 10 Casino/Games", tableData: top10CasinoGames }
    ]

    const onActiveTimeChange = (e) => {
        setActiveTime(e.target.value)
        const today = new Date();
        const utcToday = MomentUTCDateTime(today)
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const payload = {
            fromDate: MomentUTCDateTimeToday(yesterday),
            toDate: MomentUTCDateTimeEnd(utcToday)
        }
        dispatch(getUserActiveTimesReportByType(payload, e.target.value))

    }
    useEffect(() => {
        const lengthCheck = Object.keys(prevFormData).length > 0
        // dispatch(setPrevFormData(prevFormData));
        if (!lengthCheck) {
            dispatch(getTopTenWithdrawableUsers())
            dispatch(getTopTenWinnerLooser())
            dispatch(getTopTenSportsNeGamesCasino())
            // dispatch(getOverallReviewReportByPeriod())
            const today = new Date();
            const utcToday = MomentUTCDateTimeEnd(today)
            const yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            const payload = {
                fromDate: MomentUTCDateTimeToday(yesterday),
                toDate: utcToday
            }
            dispatch(getSportsBetsReport(payload))
            dispatch(getBetReportByGameType(payload))
            dispatch(getBonusReport(payload))
            dispatch(getPaymnetReport(payload))
            dispatch(getUserActivityReport(payload))
            dispatch(getUserActiveTimesReportByType(payload, 'LOGIN'))

            setDateRange({
                startDate: yesterday,
                endDate: today,
            });
        }

        stateSetter()
    }, [])


    return (
        <React.Fragment>
            <div className="CMS-platform CMS-dashboard-new">

                <div className="CMS-layout-innerContent">
                    <div className="CMS-page">
                        <div className="CMS-page-header">
                            {DASBOARD_CONSTANTS.dashboard}
                        </div>
                        <div className="CMS-page-content">
                            {/* <div className="CMS-row CMS-graphReview">
                                <div className="CMS-col">
                                    <div className="CMS-box CMS-quarterlyReview">
                                        <div className="CMS-box__header">
                                            <div className="CMS-box__header-left">
                                                <div className="CMS-box__title CMS-black">{DASBOARD_CONSTANTS.quaterly_Review}</div>
                                            </div>
                                        </div>
                                        <div className="CMS-graph">
                                            <BarGraph
                                                containerId={'CMS-highCharts-quarterly'}
                                                categories={['Q1', 'Q2', 'Q3', 'Q4']}
                                                description={'Quarterly'}
                                                graphData={overallReviewReport}
                                            />
                                        </div>
                                    </div>
                                    <ClrDataBox graphData={overallReviewReport} />
                                </div>
                                <div className="CMS-col">
                                    <div className="CMS-box CMS-quarterlyReview">
                                        <div className="CMS-box__header">
                                            <div className="CMS-box__header-left">
                                                <div className="CMS-box__title CMS-black">{DASBOARD_CONSTANTS.monthly_Review}</div>
                                            </div>
                                        </div>
                                        <div className="CMS-graph">
                                            <BarGraph
                                                containerId={'CMS-highCharts-monthly'}
                                                categories={['M1', 'M2', 'M3', 'M4']}
                                                description={'Monthly'}
                                                graphData={overallReviewReport}
                                                monthly
                                            />
                                        </div>
                                    </div>
                                    <ClrDataBox graphData={overallReviewReport} monthly />
                                </div>
                            </div> */}
                            <div className="CMS-DB-dateFilters">
                                <div className="CMS-dateFilters">
                                    <div className="CMS-formGroup">
                                        <div className="CMS-formControl-group">
                                            <div className="CMS-formControl">
                                                <DateInputField
                                                    selected={dateRange.startDate}
                                                    onChange={(date) => handleDateChange(date, 'startDate')}
                                                    placeholderText="From Date"
                                                    className="CMS-datepicker hasDatepicker"
                                                    dateFormat="dd/MM/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    minDate={null}
                                                    maxDate={dateRange.endDate ? dateRange.endDate : new Date()
                                                    }
                                                />
                                            </div>
                                            <div className="CMS-formAddon">
                                                <span className="material-icons md-18" data-icon="calendar_today"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="CMS-formGroup">
                                        <div className="CMS-formControl-group">
                                            <div className="CMS-formControl">
                                                <DateInputField
                                                    selected={dateRange.endDate}
                                                    onChange={(date) => handleDateChange(date, 'endDate')}
                                                    placeholderText="To Date"
                                                    className="CMS-datepicker hasDatepicker"
                                                    dateFormat="dd/MM/yyyy"
                                                    dropdownMode='select'
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    minDate={dateRange.startDate}
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <div className="CMS-formAddon">
                                                <span className="material-icons md-18" data-icon="calendar_today"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="CMS-row CMS-DB-row">
                                <div className="CMS-col CMS-sports">
                                    <GamesContainer
                                        cardData={sportData}
                                        header={"Sports"}
                                        ggrPeriodVal={sportextractedDt.map(obj => getnumberWithCommas(obj?.periodGGR))}
                                        ngrPeriodVal={sportextractedDt.map(obj => getnumberWithCommas(obj?.periodNGR))}
                                        ggrOverall={sportextractedDt.map(obj => getnumberWithCommas(obj?.overallGGR))}
                                        ngrOverall={sportextractedDt.map(obj => getnumberWithCommas(obj?.overallNGR))}
                                        ggrRatio={sportextractedDt.map(obj => obj?.ggrRatio)}
                                        ngrRatio={sportextractedDt.map(obj => obj?.ngrRatio)}
                                    />
                                </div>
                                <div className="CMS-col CMS-casino">
                                    <GamesContainer
                                        cardData={casinoData}
                                        header={"Casino"}
                                        ggrPeriodVal={casinoExtractDt.map(obj => getnumberWithCommas(obj?.periodGGR))}
                                        ngrPeriodVal={casinoExtractDt.map(obj => getnumberWithCommas(obj?.periodNGR))}
                                        ggrOverall={casinoExtractDt.map(obj => getnumberWithCommas(obj?.overallGGR))}
                                        ngrOverall={casinoExtractDt.map(obj => getnumberWithCommas(obj?.overallNGR))}
                                        ggrRatio={casinoExtractDt.map(obj => obj?.ggrRatio)}
                                        ngrRatio={casinoExtractDt.map(obj => obj?.ngrRatio)}
                                    />
                                </div>
                            </div>
                            <div className="CMS-row CMS-DB-row">
                                <div className="CMS-col CMS-neGames">
                                    <GamesContainer
                                        cardData={neGamesData}
                                        header={"NE Games"}
                                        ggrPeriodVal={neGameExtractedDt.map(obj => getnumberWithCommas(obj?.periodGGR))}
                                        ngrPeriodVal={neGameExtractedDt.map(obj => getnumberWithCommas(obj?.periodNGR))}
                                        ggrOverall={neGameExtractedDt.map(obj => getnumberWithCommas(obj?.overallGGR))}
                                        ngrOverall={neGameExtractedDt.map(obj => getnumberWithCommas(obj?.overallNGR))}
                                        ggrRatio={neGameExtractedDt.map(obj => obj?.ggrRatio)}
                                        ngrRatio={neGameExtractedDt.map(obj => obj?.ngrRatio)}
                                    />
                                </div>
                                <div className="CMS-col CMS-bonusing">
                                    <GamesContainer
                                        cardData={bonusingData}
                                        barData={popularBonuses}
                                        header={"Bonusing"}
                                        period={bonusVsCashStake.map(obj => getnumberWithCommas(obj?.bonusStake))}
                                        overall={bonusVsCashStake.map(obj => getnumberWithCommas(obj?.cashStake))}
                                        bonusStakeratio={bonusVsCashStake.map(obj => obj?.bonusVsCashStakeRatio)}
                                        widBarGraph
                                    />
                                </div>
                            </div>
                            <div className="CMS-row">
                                <div className="CMS-col">
                                    <div className="CMS-DB-categoryItem">
                                        <div className="CMS-categoryTitle">
                                            {DASBOARD_CONSTANTS.sportsBets}
                                        </div>
                                        <div className="CMS-box">
                                            <ul>
                                                {sportBetsData.map((box, index) => (
                                                    <li key={index}>
                                                        <DataBox title={box.title} data={box.data} currency={box.currency} info={box.info} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="CMS-row CMS-DB-row">
                                <div className="CMS-col CMS-payments">
                                    <GamesContainer
                                        cardData={paymentData}
                                        header={"Payments"}
                                        noCurrency
                                        DepoStakeRatio={paymentAPIData.map(obj => obj?.depositToStakeRatio)}
                                        withdrawPayoutRatio={paymentAPIData.map(obj => obj?.withdrawalsToPayoutRatio)}
                                        DepoWithdrawRatio={paymentAPIData.map(obj => obj?.depositToWithdrawalsRatio)}
                                        tripleGaugeGp />
                                </div>
                                <DashBoardTable
                                    tableTitle={"Top 10 Withdrawable Users"}
                                    tableData={top10WitdrwableUser}
                                    linkIndex={0}
                                    paymentTb
                                />
                            </div>
                            <div className="CMS-row CMS-DB-row CMS-DB-userRow">
                                <div className="CMS-col CMS-userActivity">
                                    <div className="CMS-DB-categoryItem">
                                        <div className="CMS-categoryTitle">
                                            {DASBOARD_CONSTANTS.userActivity}
                                        </div>
                                        <div className="CMS-box">
                                            <div className="CMS-box-graphContainer CMS-userActivity-graph CMS-highCharts-statistics">
                                                <RegVsConversionChart title={"Registraton Vs Conversion"} barData={regVsConvDetails} />
                                                <DonutChart title={"Login Channels"} chatData={loginChannelDetails} />
                                                <PieChart title={"Active Users"} chartData={activeUserDetail} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="CMS-col CMS-userActiveTimes">
                                <div className="CMS-DB-categoryItem">
                                    <div className="CMS-box">
                                        <div className="CMS-boxContent CMS-boxContent-userActiveTimes">
                                            <div className="CMS-box-graphContainer">
                                                <div className="CMS-box-graphItem">
                                                    <div className="CMS-box-graphTitle">{"User Active Times"}</div>
                                                    <Tooltips info={"info"} />
                                                    <div className="CMS-select">
                                                        <select
                                                            onChange={(e) => onActiveTimeChange(e)}
                                                            value={activeTime}
                                                        >
                                                            {/* <option value={""}>Select</option> */}
                                                            <option value={"LOGIN"}>Login Based</option>
                                                            <option value={"BETTING"}>Betting Based</option>
                                                            <option value={"DEPOSIT"}>Deposit</option>
                                                            <option value={"WITHDRAW"}>Withdrawal</option>
                                                        </select>
                                                    </div>
                                                    <UserActiveTimeChart
                                                        ddlValue={activeTime}
                                                        chartData={activeTimeData}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="CMS-row CMS-DB-row CMS-DB-equalHeight">
                                <DashBoardTable
                                    tableTitle={"Top 10 Winners"}
                                    tableData={top10Winner}
                                    linkIndex={0}
                                />
                                <DashBoardTable
                                    tableTitle={"Top 10 Losers"}
                                    tableData={top10Looser}
                                    linkIndex={0}
                                />
                            </div>
                            <div className="CMS-row CMS-DB-row CMS-DB-equalHeight">
                                {fourTableData.map((item, ind) => (
                                    <DashBoardTable
                                        tableTitle={item.tableTitle}
                                        tableData={item.tableData}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NewDashboard