import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getMultiplebet,
  MultibetAccount,
  setPrevTableData,
} from "./MultibetSlice";
import { regexType } from "../../sharedfiles/helper";
import { COMMON_CONSTANTS, KYC_CONSTANTS, MULTIBETCONFIG_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { BrandData, COMMON_MSGS, Payment } from "../../sharedfiles/tooltipMsgs";
function MultibetConfig(props) {
  const dispatch = useDispatch();
  const [isToggled, setToggle] = useState(false);
  const [unit, setUnit] = useState("IN_ACTIVE");
  const [Threshold, setThreshold] = useState("");
  const [editable, setEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [odderror, setOderError] = useState(false);
  const [add, setAdd] = useState("");
  const myRef = useRef();

  const prevTableData = useSelector(
    (state) => state.multibetconfig?.prevTableData
  );

  const toggle = () => {
    setToggle(!isToggled);
    setEditable(true);
    isToggled ? setUnit("IN_ACTIVE") : setUnit("ACTIVE");
  };

  const handleChange = (e) => {
    setEditable(true);
    const { value } = e.target;
    if (value === "" || regexType.decimalNumeric.test(value)) {
      setThreshold(e.target.value);
    }
    value.length > 0 ? setOderError(false) : setOderError(true);
  };

  const addRowHandler = () => {
    const newRows = [...prevTableData, { bonusPercentage: "", eventCount: "" }];
    dispatch(setPrevTableData(newRows));
    setEditable(true);
    setAdd(Date.now());
  };

  const handleDelete = (index) => {
    setIsDelete(true);
    const updated = prevTableData.filter((item, ind) => index !== ind);
    dispatch(setPrevTableData(updated));
    setIsDelete(false);
  };

  const setValues = (e, index) => {
    const { name, value } = e.target;
    const updatedValues = prevTableData.map((item, ind) => {
      if (ind === index) {
        if (name === "bonus") {
          return {
            bonusPercentage: value,
            eventCount: item.eventCount,
          };
        } else {
          return {
            bonusPercentage: item.bonusPercentage,
            eventCount: value,
          };
        }
      }
      return item;
    });
    dispatch(setPrevTableData(updatedValues));
  };

  const save = () => {
    let err = false;
    if (Threshold === "" || Threshold === undefined) {
      setOderError(true);
      err = true;
    }
    const config = prevTableData.filter(
      (item) => item.bonusPercentage !== "" || item.eventCount !== ""
    );
    const payload = {
      bonusStatus: unit,
      configs: config,
      oddsThreshold: Threshold,
    };
    if (!err) {
      setEditable(false);
      dispatch(MultibetAccount(payload));
      setTimeout(() => dispatch(getMultiplebet()), 200);
    }
  };
  useEffect(() => {
    dispatch(getMultiplebet());
  }, []);

  useEffect(() => {
    const value = props.multibet?.oddsThreshold;
    const isActive = props.multibet?.bonusStatus;
    let toggleActive = isActive === "ACTIVE" ? true : false;
    setThreshold(value);
    setUnit(isActive);
    setToggle(toggleActive);
  }, [props.multibet]);

  function getFocus() {
    const latestField = myRef.current;
    latestField.scrollIntoView({
      behavior: "smooth",
    });
    latestField.focus();
  }

  useEffect(() => {
    if (editable) {
      getFocus();
    }
  }, [add]);
  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-4'>
                <div className='row no-gutters'>
                  <div className='col-12'>
                    <div className='CMS-formGroup formGroup-inline'>
                      <div className='col-6'>
                        <div className='CMS-formLabel'>
                          {MULTIBETCONFIG_CONSTANTS.multibetConfig}
                          &nbsp;&nbsp;
                          <Tooltips info={BrandData.multibet_toggle} />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='CMS-toggleSwitch'>
                          <input
                            id='toggleActive'
                            name='toggleActive'
                            value={unit}
                            checked={isToggled}
                            type='checkbox'
                            onChange={toggle}
                          />
                          <label for='toggleActive'></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row no-gutters'>
                  <div className='col-12'>
                    <div className='CMS-formGroup formGroup-inline'>
                      <div className='col-6'>
                        <div className='CMS-formLabel'>
                          {MULTIBETCONFIG_CONSTANTS.oddThresholdLimit}
                          &nbsp;&nbsp;
                          <Tooltips info={"Setting the Threshold Limit"} />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='CMS-formControl'>
                          <input
                            type='text'
                            name='Threshold'
                            id='multibetThreshold'
                            placeholder='Odds greater than 1.2'
                            // className='custom_input'
                            // autocomplete='off'
                            value={Threshold}
                            onChange={handleChange}
                          />
                          {odderror && (
                            <div className='CMS-form-errorMsg'>
                              {MULTIBETCONFIG_CONSTANTS.oddEmpty_ErrMsg}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='CMS-box__header row'>
                  <div className='CMS-box__title col-9'>{MULTIBETCONFIG_CONSTANTS.bonusRequirement}</div>
                  <div className='CMS-btnContainer col-3'>
                    {editable ? (
                      <button
                        className='btn_success_md vh_laign_center'
                        onClick={() => save()}
                        id='multibrtSave'
                      >
                        <span className='material-icons save'>{COMMON_CONSTANTS.span_saveIcon}</span>
                        &nbsp;{COMMON_CONSTANTS.save}
                      </button>
                    ) : (
                      <button
                        className='CMS-btn CMS-btnQuaternary active vh_laign_center'
                        onClick={() => setEditable(true)}
                        id='multibetEdit'
                      >
                        <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                        &nbsp;{COMMON_CONSTANTS.edit}
                      </button>
                    )}
                    <button
                      className='CMS-btn CMS-btnPrimary active vh_laign_center'
                      onClick={() => addRowHandler()}
                      id='multibrtADD'
                    >
                      <span className='material-icons edit'>{COMMON_CONSTANTS.span_addIcon}</span>
                      &nbsp;{COMMON_CONSTANTS.add}
                    </button>
                  </div>
                </div>

                <div
                  className='CMS-table CMS-table-triped'
                  style={{ maxHeight: "600px" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{MULTIBETCONFIG_CONSTANTS.No_Events_On_Betslip}</p>
                            <Tooltips info={Payment.betSlip_No} />
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{MULTIBETCONFIG_CONSTANTS.bonus_Percentage}</p>
                            <Tooltips info={Payment.bonus_Perecnt} />
                          </div>
                        </th>
                        {editable && <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                            <Tooltips info={COMMON_MSGS.Action} />
                          </div>
                        </th>}
                      </tr>
                    </thead>
                    {prevTableData &&
                      Array.isArray(prevTableData) &&
                      prevTableData.length > 0 ? (
                      prevTableData.map((item, index) => {
                        return (
                          <>
                            {editable ? (
                              <tr>
                                <td className='mean_td_height'>
                                  <input
                                    type='number'
                                    name='eventCount'
                                    id='eventCount'
                                    className='table_form_input custom_input'
                                    defaultValue={isDelete && item.eventCount}
                                    value={!isDelete && item.eventCount}
                                    onChange={(e) => {
                                      setValues(e, index);
                                    }}
                                    ref={myRef}
                                  />
                                </td>
                                <td className='mean_td_height'>
                                  <input
                                    type='number'
                                    name='bonus'
                                    id='bonuscount'
                                    className='table_form_input custom_input'
                                    defaultValue={item.bonusPercentage}
                                    value={!isDelete && item.bonusPercentage}
                                    onChange={(e) => {
                                      setValues(e, index);
                                    }}
                                  />
                                </td>
                                <td className='mean_td_height'>
                                  <div className='CMS-btnContainer'>
                                    <button
                                      className='CMS-btn CMS-btnStatus CMS-statusClosed pointer'
                                      onClick={() => {
                                        handleDelete(index);
                                      }}
                                      id='betdelete'
                                    >
                                      <span className='material-icons delete'>
                                        {COMMON_CONSTANTS.span_deleteIcon}
                                      </span>
                                      {COMMON_CONSTANTS.delete}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <>
                                <tr className='table_static text_start'>
                                  <td className='text_start mean_td_height'>
                                    {item.eventCount}
                                  </td>
                                  <td className='mean_td_height'>
                                    {item.bonusPercentage} %
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={22} />
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    multibet: state.multibetconfig?.multibet,
    prevTableData: state.multibetconfig?.prevTableData,
  };
};
export default connect(mapStateToProps)(MultibetConfig);
