import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi } from "../../sharedfiles/EndpointConfig";

export const BonusTransactionSlice = createSlice({
  name: "bonusTransactionSlice",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    userid: "",
    recordsShow: [1, 25],
    isVisible: false,
    bonusTransactionData: [],
  },
  reducers: {
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setBonusTransactionData: (state, action) => {
      state.bonusTransactionData = action.payload;
    },
  },
});
export const {
  setPaginationFirstValue,
  setPaginationSecondValue,
  setErrorMessage,
  setRecordsShow,
  setIsVisible,
  setBonusTransactionData,
} = BonusTransactionSlice.actions;

export const bonusTransaction =
  (pageNumber, itemsPerPage, id, allTransactions) => (dispatch) => {
    const url =
      `${bonusApi.getPlayerBonus}` +
      id +
      "/transactions?" +
      "pageNumber=" +
      pageNumber +
      "&itemsPerPage=" +
      itemsPerPage + "&allTransactions=" + allTransactions;
    if (id !== null || id !== "" || id !== undefined) {
      apiCaller(url, "GET")
        .then((response) => {
          dispatch(setBonusTransactionData(response.data));
          dispatch(setIsVisible(true));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

export default BonusTransactionSlice.reducer;
