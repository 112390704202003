import React from 'react';
import Loading from "../loading/Loading"
import { Link } from "react-router-dom";

const DashBoardTable = ({ tableTitle, tableData, linkIndex, styles, paymentTb }) => {
    const isLoading = !tableData || tableData.length === 0;

    if (isLoading) {
        return (
            <div className={paymentTb ? "CMS-col CMS-topTenWithdraw" : "CMS-col"}>
                <div className="CMS-box CMS-tableBox">
                    <div className="CMS-box__title">{tableTitle}</div>
                    <div className="CMS-table CMS-table-triped">
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={20}>
                                        <Loading isLoader={true} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    const headerRow = tableData[0];

    return (
        <div className={paymentTb ? "CMS-col CMS-topTenWithdraw" : "CMS-col"}>
            <div className="CMS-box CMS-tableBox">
                <div className="CMS-box__title">{tableTitle}</div>
                <div className="CMS-table CMS-table-triped">
                    <table>
                        <thead>
                            <tr>
                                {headerRow &&
                                    headerRow.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.length <= 1 ? (
                                <tr className='CMS-msgBox'>
                                    <td colSpan={headerRow ? headerRow.length : 1} className='CMS-msgBox-container text-center'>No Data Found</td>
                                </tr>
                            ) : (
                                tableData.slice(1).map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} className={styles ? styles[cellIndex] : ""}>
                                                {linkIndex !== undefined && linkIndex === cellIndex ? (
                                                    <Link to={`/customerid/${cell}`}
                                                    // onClick={() => tabsChange(cell)}
                                                    >
                                                        {cell && typeof cell === 'object' ? JSON.stringify(cell) : cell}
                                                    </Link>
                                                ) : (
                                                    cell
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default DashBoardTable;