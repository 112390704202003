import React from 'react'
import { useSelector } from "react-redux"
import { getRefferFriendReports, setReferFriendDates } from "../reportSlice"
import ReportsWrapper from "../ReportsWrapper"
import ReferFriendTable from "./ReferFriendTable"

function ReferFriend() {
    const { referFriendDates, referFriendData } = useSelector(state => state.reports)
    return (
        <React.Fragment>
            <ReportsWrapper
                reportTitle={"REFER FRIEND"}
                currentTab={"REFER FRIEND"}
                pageKey={"ReferFriend"}
                setDateToStore={setReferFriendDates}
                persistDate={referFriendDates}
                sportData={referFriendData}
                api={getRefferFriendReports}
                ReportTable={() => (
                    <ReferFriendTable
                        dates={referFriendDates}
                        reportsData={referFriendData}
                        reportPage={"Refer a Friend"}
                    />
                )}
            />
        </React.Fragment>
    )
}

export default ReferFriend
