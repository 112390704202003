/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAddNotestableList,
  getNotesList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
} from "./addNoteSlice";
import { bindActionCreators } from "redux";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Select from "../Common/common_inputs/Select_DDL";
import { AddNotesDDL } from "../Common/data/mapData";
import Table from '../Common/table/Table'
import { NOTE_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { NoteInfo, Notes } from "../../sharedfiles/tooltipMsgs";
class Note extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      note: "",
      id: "",
      data: {},
      error: false,
      // condition: false,
      categoryError: false,
      activePage: 1,
      totalPages: "",
      itemsPerPage: "",
      pdfBody: [],
      csvData: [],
    };
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.mySubmitFunction = this.mySubmitFunction.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
  }

  myDocument(e) {
    this.setState({ document: e.target.value });
  }

  headers = [["Created By", "ID", "Create Time", "Category", "Text"]];


  exportPDF() {
    const title = "Add Notes Report";
    exportPDFC(title, this.headers, this.state.pdfBody);
  }


  onChangeCategory(e) {
    if (e.target.value.length > 0) {
      this.setState({ categoryError: false });
    }
    this.setState({ category: e.target.value });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeNote(e) {
    if (e.target.value.length > 0) {
      this.setState({ error: false });
    }
    this.setState({ note: e.target.value });
  }

  callApi = (pageNumber, itemsPerPage, data) => {
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(
      getNotesList(pageNumber - 1, itemsPerPage, data)

    );
  }

  mySubmitFunction(e) {
    e.preventDefault();
    const userID = this.props.userId
      ? this.props.userId
      : localStorage.getItem("userId");
    if (this.state.note !== "" && this.state.category !== "") {
      var playerserachdata = {
        category: this.state.category,
        note: this.state.note,
      };
      this.props.dispatch(setIsVisible(false));
      this.props.dispatch(
        getAddNotestableList(localStorage.getItem("userId"), playerserachdata)
      );
      this.props.dispatch(setPaginationFirstValue(1))
      this.props.dispatch(setPaginationSecondValue(25))
      this.props.dispatch(setRecordsShow([1, 25]))
      this.setState({ category: "", note: "" });
    }
    if (this.state.note === "") {
      this.setState({ error: true });
    }
    if (this.state.category === "") {
      this.setState({ categoryError: true });
    }
  }

  componentDidMount() {
    this.props.dispatch(
      getNotesList(
        this.props.paginationFirstValue - 1,
        this.props.paginationSecondValue,
      )
    );
  }
  componentDidUpdate(prevprops) {
    const id = window.location.pathname.split("/")[2];
    if (prevprops.userId !== id) {
      this.props.dispatch(setIsVisible(false));
      this.callApi(1, 25, { id: id });
    }
    if (prevprops.notesData !== this.props.notesData) {
      this.updateTableToDocs();
    }
  }
  updateTableToDocs() {
    setTimeout(() => {
      const data = this.props?.notesData &&
        Object.keys(this.props?.notesData).length > 0 &&
        this.props.notesData?.data &&
        this.props.notesData?.data.map((elt) => [
          elt.platFormUser,
          elt.playerId,
          elt.createdDate ? getDateTimeFormate(elt.createdDate) : "-",
          elt.category,
          elt.note,
        ]);
      this.setState({ pdfBody: data });
      const cv = data.length > 0 ? [this.headers[0], NoteInfo, ...data] : [this.headers[0], NoteInfo, []];
      this.setState({ csvData: cv });
    }, 300);
  }

  render() {
    return (
      <>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='CMS-box CMS-box-content'>
              <div className='row'>
                <div className='col-md-6 col-lg-6 col-xl-3'>
                  <Select
                    title={"Category"}
                    name={"category"}
                    ids={"noteCategory"}
                    value={this.state.category}
                    setValue={(val) => this.onChangeCategory(val)}
                    options={AddNotesDDL}
                    errorMsg={this.state.categoryError ? "Please select category" : ""}
                    info={Notes.Category}
                  />
                </div>

                <div className='col-md-6 col-lg-6 col-xl-9'>
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      {NOTE_CONSTANTS.notes}
                      &nbsp;&nbsp;
                      <Tooltips info={Notes.Note} />
                    </div>
                    <div className='CMS-formControl-group'>
                      <div className='CMS-formControl'>
                        <textarea
                          rows='2'
                          cols='50'
                          name='note'
                          id='Addnote'
                          value={this.state.note}
                          onChange={this.onChangeNote}
                        ></textarea>
                      </div>
                      {this.state.error && (
                        <div className='CMS-form-errorMsg'>
                          {NOTE_CONSTANTS.noteErr_Msg}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-lg-4 col-xl-3'></div>
                <div className='col-md-6 col-lg-4 col-xl-3'></div>

                <div className='col-md-6 col-lg-4 col-xl-3'></div>
              </div>
              <div className='mt-20'></div>
              <div className='row'>
                <div className='col-3'>
                  <div className='CMS-btnContainer'>
                    <button
                      onClick={(e) => this.mySubmitFunction(e)}
                      className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                      type='button'
                      id='addnotebtn'
                    >
                      {NOTE_CONSTANTS.addNote}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.notesData.data}
                  totalRecords={this.props.notesData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"Add notes report"}
                  payloadData={this.state.data}
                />
              </div>

              <Table tableData={this.state.csvData} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    notesData: state.addNotesSlice.notesData,
    addnoteid: state.addNotesSlice.addnoteid,
    paginationFirstValue: state.addNotesSlice.paginationFirstValue,
    paginationSecondValue: state.addNotesSlice.paginationSecondValue,
    recordsShow: state.addNotesSlice.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.addNotesSlice.isVisible,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Note);
