import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Dashboard from "../Dashboard/dashboard";

const OperationSummary = (props) => {
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (location.pathname === "/operationSummary") {
  //       setSendTitle(true);
  //     }
  //     console.log("Operating summary page is rendering");
  //   }, []);

  return (
    <>
      <Dashboard preventTab={true} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userId: state.playersearch.userId,
  };
}

export default connect(mapStateToProps)(OperationSummary);
