import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getDateFormate,
  formatMoney,
  getDob,
  Currency,
} from "../../sharedfiles/helper";
import { ImageTwo, ImageThree } from "../../sharedfiles/pdfImages";
import { font } from "../../sharedfiles/DejaVuSans-Bold-bold";
import { connect } from "react-redux";
import Skelton from "../Common/skelton/Skelton";
import No__Data__Found from "../Common/table/No__Data__Found";
import { REPORTS_CONSTANTS } from "../../sharedfiles/Constants";
const PaymentTable = (props) => {
  const { dates } = props;
  const [fileType, setFileType] = useState("");

  const depositHeaders = [
    ["Currency", "Payment Count", `Payment Value ${Currency()}`],
  ];

  const depositData = Object.keys(props.sportData).length > 0 &&
    props.sportData.deposits != undefined && [
      props.sportData.deposits.currency,
      props.sportData.deposits.paymentCount,
      formatMoney(props.sportData.deposits.paymentValue),
    ];
  // useEffect(() => {
  //   console.log("depositData", Object.values(props?.sportData?.deposits));
  // }, [props.sportData.deposits]);

  const withdrawlHeaders = [
    ["Currency", "Payment Count", `Payment Value ${Currency()}`],
  ];

  const withdrawlData = Object.keys(props.sportData).length > 0 && [
    [
      props.sportData.withdrawals.currency,
      props.sportData.withdrawals.paymentCount,
      formatMoney(props.sportData.withdrawals.paymentValue),
    ],
  ];

  const depSummeryHeaders = [
    [
      "Currency",
      "Payment Count",
      `Payment Value ${Currency()}`,
      "Number Of Customers",
    ],
  ];

  const depSummeryData = Object.keys(props.sportData).length > 0 && [
    [
      props.sportData.depositsSummary.currency,
      props.sportData.depositsSummary.paymentCount,
      formatMoney(props.sportData.depositsSummary.paymentValue),
      props.sportData.depositsSummary.numberOfCustomers,
    ],
  ];

  const witSummeryHeaders = [
    [
      "Currency",
      "Payment Count",
      `Payment Value ${Currency()}`,
      "Number Of Customers",
    ],
  ];

  const witSummeryData = Object.keys(props.sportData).length > 0 && [
    [
      props.sportData.withdrawlSummary.currency,
      props.sportData.withdrawlSummary.paymentCount,
      formatMoney(props.sportData.withdrawlSummary.paymentValue),
      props.sportData.withdrawlSummary.numberOfCustomers,
    ],
  ];
  const balanceHeaders = [
    [
      `Net Deposits ${Currency()}`,
      "Revenue In Period",
      `Free Bet Costs ${Currency()}`,
    ],
  ];

  const balanceData = Object.keys(props.sportData).length > 0 && [
    [
      formatMoney(props.sportData.accountBalance.netDeposits),
      props.sportData.accountBalance.revenueInPeriod,
      formatMoney(props.sportData.accountBalance.freeBetCosts),
    ],
  ];

  const cndHeaders = [
    [
      "User Name",
      `All Deposits ${Currency()}`,
      "All Profit & Loss",
      "Bet Count",
      `Cash Revenue ${Currency()}`,
      `Deposits ${Currency()}`,
      "First Deposit",
    ],
  ];

  const cndData =
    Object.keys(props.sportData).length > 0
      ? props.sportData.customerNetDeposit &&
      props.sportData.customerNetDeposit.map((item) => [
        item.userName,
        formatMoney(item.allDeposits),
        item.allProfitLoss,
        item.betCount,
        formatMoney(item.cashRevenue),
        formatMoney(item.deposits),
        getDob(item.firstDeposit),
      ])
      : ["", "", "", "NO DATA FOUND", "", "", ""];

  const csvTable1 = [depositHeaders[0], depositData];
  const csvTable2 = [withdrawlHeaders[0], withdrawlData[0]];
  const csvTable3 = [depSummeryHeaders[0], depSummeryData[0]];
  const csvTable4 = [witSummeryHeaders[0], witSummeryData[0]];
  const csvTable5 = [balanceHeaders[0], balanceData[0]];
  const csvTable6 =
    Object.keys(props.sportData).length > 0 ? [cndHeaders[0], ...cndData] : [];

  const csvData = [
    ...csvTable1,
    ...csvTable2,
    ...csvTable3,
    ...csvTable4,
    ...csvTable5,
    ...csvTable6,
  ];

  const printPdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "l"; // portrait or landscape
    // const title = props.reportPage.label;
    //  const format = [canvas.width + 60, canvas.height + 160]
    const marginLeft = 40;
    const title = props.reportPage;
    const doc = new jsPDF(orientation, unit, size);

    const fontRupee = font;
    doc.addFileToVFS("DejaVuSans-Bold-bold.ttf", fontRupee);
    doc.addFont("DejaVuSans-Bold-bold.ttf", "DejaVuSans-Bold", "normal");
    doc.setFont("DejaVuSans-Bold");
    // doc.setFontSize(20);
    // doc.text("vijay = ₹", 20, 20);
    // log available fonts

    doc.setFontSize(23);
    doc.setFont("helvetica");
    const totalWidth = doc.internal.pageSize.getWidth();
    const totalHeight = doc.internal.pageSize.getHeight();
    const titleHead = title.toUpperCase();
    const xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(titleHead) * doc.internal.getFontSize()) / 2;
    const lineWidth = (input) => doc.getStringUnitWidth(input) * 25 + xOffset;
    //  rgb(14,18,29)
    // main header part //
    doc.addImage(ImageTwo, "JPEG", 0, 0, totalWidth, 85);
    doc.addImage(ImageThree, "JPEG", 0, 80, totalWidth, 55);
    doc.setFontSize(25);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(255, 255, 255);
    doc.text(titleHead, xOffset, 55);
    doc.setLineWidth(1.5);
    doc.setTextColor(255, 255, 255);
    // doc.line(xOffset, 55, lineWidth(titleHead), 55);
    doc.setFontSize(18);
    doc.setTextColor(250, 242, 92);
    doc.text(title.toUpperCase(), 20, 115);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(210, 227, 234);
    doc.text(
      "Period  : " +
      getDateFormate(dates.dateFrom) +
      " to  " +
      getDateFormate(dates.dateTo),
      doc.internal.pageSize.width - 230,
      112
    );

    // main header part //

    const subTitleOne = (input, boxHeight) => {
      doc.setFontSize(13);
      const title = input.toUpperCase();
      const titleWidth = doc.getStringUnitWidth(title) * 13;
      const boxStart = 40;
      const boxEnd = titleWidth + 30;
      const titleStart = 40 + 15;
      doc.setDrawColor(232, 14, 134);
      doc.setFillColor(232, 14, 134);
      doc.rect(boxStart, boxHeight, boxEnd, 20, "F");
      doc.setTextColor(254, 245, 255);
      return doc.text(title, titleStart, boxHeight + 15);
    };
    // title part //
    // main header part //

    subTitleOne("DEPOSITS", 160);

    const withDepStyles = {
      2: {
        halign: "right",
        valign: "middle",
      },
    };

    const depositContent = {
      startY: 185,
      head: depositHeaders,
      body: Object.keys(props.sportData).length > 0 ? [depositData] : [],
      theme: "striped",
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      tableWidth: "auto",
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: withDepStyles,
    };

    doc.autoTable(depositContent);

    // table 1 title //

    // table 2 title // withdrawals
    const heightDt = doc.autoTableEndPosY() + 10;

    subTitleOne("WITHDRAWLS", heightDt + 25);

    const withdrawlContent = {
      startY: heightDt + 50,
      head: withdrawlHeaders,
      body: Object.keys(props.sportData).length > 0 ? withdrawlData : [],
      theme: "striped",
      tableLineColor: [210, 227, 234],
      tableWidth: "auto",
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: withDepStyles,
    };

    doc.autoTable(withdrawlContent);

    // table 2 title //

    // table 3 title //

    const heightWt = doc.autoTableEndPosY() + 10;

    subTitleOne("DEPOSIT SUMMARY", heightWt + 25);

    const depSummeryContent = {
      startY: heightWt + 50,
      head: depSummeryHeaders,
      body: Object.keys(props.sportData).length > 0 ? depSummeryData : [],
      theme: "striped",
      tableWidth: "auto",
      tableLineColor: [210, 227, 234],
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: withDepStyles,
    };

    doc.autoTable(depSummeryContent);

    // table 3 title //

    // table 4 title //

    const heightDs = doc.autoTableEndPosY() + 10;

    subTitleOne("WITHDRAWL SUMMARY", heightDs + 25);

    const witSummeryContent = {
      startY: heightDs + 50,
      head: witSummeryHeaders,
      body: Object.keys(props.sportData).length > 0 ? witSummeryData : [],
      theme: "striped",
      tableWidth: "auto",
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: withDepStyles,
    };

    doc.autoTable(witSummeryContent);

    // table 4 title //

    // table 5 title //

    doc.addPage(size, orientation);

    subTitleOne("ACCOUNT BALANCE", 40);

    const balanceContent = {
      startY: 65,
      head: balanceHeaders,
      body: Object.keys(props.sportData).length > 0 ? balanceData : [],
      theme: "striped",
      tableWidth: "auto",
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: {
        0: { halign: "right", valign: "middle" },
        2: { halign: "right", valign: "middle" },
      },
    };

    doc.autoTable(balanceContent);
    // table 5 title //

    // table 6 title //
    const balanceHeight = doc.autoTableEndPosY() + 10;
    // doc.setDrawColor(42, 78, 145);
    // doc.setFillColor(42, 78, 145);
    // doc.rect(40, balanceHeight + 25, 230, 20, "F");
    // doc.setFontSize(16);
    // doc.setTextColor(255, 255, 255);
    // doc.text("CUSTOMER NET DEPOSIT", 57, balanceHeight + 40);
    subTitleOne("CUSTOMER NET DEPOSIT", balanceHeight + 25);

    const cndContenet = {
      startY: balanceHeight + 50,
      head: cndHeaders,
      body:
        Object.keys(props.sportData).length > 0 &&
          props.sportData.customerNetDeposit.length > 0
          ? cndData
          : [],
      theme: "striped",
      tableWidth: "auto",
      pageBreak: "avoid",
      pageBreak: "auto",
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      addFont: "DejaVuSans-Bold",
      styles: {
        font: "DejaVuSans-Bold",
      },
      headStyles: {
        halign: "center",
        valign: "middle",
        fontSize: 11,
        height: 25,
        fillColor: [141, 53, 150],
        textColor: [210, 227, 234],
        tableLineColor: [210, 227, 234],
        tableLineWidth: 0.75,
      },
      bodyStyles: { halign: "center", valign: "middle", fontSize: 10 },
      columnStyles: {
        1: {
          halign: "right",
          valign: "middle",
        },
        4: {
          halign: "right",
          valign: "middle",
        },
        5: {
          halign: "right",
          valign: "middle",
        },
      },
    };
    doc.autoTable(cndContenet);
    doc.save(`${title}.pdf`);
  };
  return (
    <>
      <>{!props.viewTable && <Skelton />}</>
      {props.viewTable && (
        <>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.payment_deposit}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_currency}</th>
                        <th>{REPORTS_CONSTANTS.header_count}</th>
                        <th>{REPORTS_CONSTANTS.header_pmValue}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.deposits).length > 0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        <tr>
                          <td>{props.sportData.deposits.currency}</td>
                          <td>{props.sportData.deposits.paymentCount}</td>
                          <td className='text-right'>
                            {formatMoney(props.sportData.deposits.paymentValue)}
                          </td>
                        </tr>
                      ) : (
                        <No__Data__Found colspan={3} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.payment_withdraw}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_currency}</th>
                        <th>{REPORTS_CONSTANTS.header_count}</th>
                        <th>{REPORTS_CONSTANTS.header_pmValue}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.withdrawals).length > 0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        <tr>
                          <td>{props.sportData.withdrawals.currency}</td>
                          <td>{props.sportData.withdrawals.paymentCount}</td>
                          <td className='text-right'>
                            {formatMoney(
                              props.sportData.withdrawals.paymentValue
                            )}
                          </td>
                        </tr>
                      ) : (
                        <No__Data__Found colspan={3} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.deposit_summary}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_currency}</th>
                        <th>{REPORTS_CONSTANTS.header_count}</th>
                        <th>{REPORTS_CONSTANTS.header_pmValue}</th>
                        <th>{REPORTS_CONSTANTS.header_noCustomer}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.depositsSummary).length > 0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        <tr>
                          <td>{props.sportData.depositsSummary.currency}</td>
                          <td>
                            {props.sportData.depositsSummary.paymentCount}
                          </td>
                          <td className='text-right'>
                            {formatMoney(
                              props.sportData.depositsSummary.paymentValue
                            )}
                          </td>
                          <td>
                            {props.sportData.depositsSummary.numberOfCustomers}
                          </td>
                        </tr>
                      ) : (
                        <No__Data__Found colspan={4} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.withdraw_summary}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_currency}</th>
                        <th>{REPORTS_CONSTANTS.header_count}</th>
                        <th>{REPORTS_CONSTANTS.header_pmValue}</th>
                        <th>{REPORTS_CONSTANTS.header_noCustomer}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.withdrawlSummary).length >
                        0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        <tr>
                          <td>{props.sportData.withdrawlSummary.currency}</td>
                          <td>
                            {props.sportData.withdrawlSummary.paymentCount}
                          </td>
                          <td className='text-right'>
                            {formatMoney(
                              props.sportData.withdrawlSummary.paymentValue
                            )}
                          </td>
                          <td>
                            {props.sportData.withdrawlSummary.numberOfCustomers}
                          </td>
                        </tr>
                      ) : (
                        <No__Data__Found colspan={4} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.acc_balance}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_netDepo}</th>
                        <th>{REPORTS_CONSTANTS.header_revenueTime}</th>
                        <th>{REPORTS_CONSTANTS.header_freeBetCost}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.accountBalance).length > 0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        <tr>
                          <td className='text-right'>
                            {formatMoney(
                              props.sportData.accountBalance.netDeposits
                            )}
                          </td>
                          <td>
                            {props.sportData.accountBalance.revenueInPeriod}
                          </td>
                          <td className='text-right'>
                            {formatMoney(
                              props.sportData.accountBalance.freeBetCosts
                            )}
                          </td>
                        </tr>
                      ) : (
                        <No__Data__Found colspan={3} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title' style={{ textAlign: "center" }}>
                {REPORTS_CONSTANTS.customer_netDepo}
              </div>
            </div>
            <div className='row no-gutters g-0'>
              <div className='col-md-12'>
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>{REPORTS_CONSTANTS.header_userName}</th>
                        <th>{REPORTS_CONSTANTS.header_allDepo}</th>
                        <th>{REPORTS_CONSTANTS.header_profitloss}</th>
                        <th>{REPORTS_CONSTANTS.header_betCount} </th>
                        <th>{REPORTS_CONSTANTS.header_cashRevenue}</th>
                        <th>{REPORTS_CONSTANTS.header_deposit}</th>
                        <th>{REPORTS_CONSTANTS.header_firstDepo}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.customerNetDeposit).length >
                        0 &&
                        props.sportData.customerNetDeposit.length > 0 ? (
                        props.sportData.customerNetDeposit.map((item, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{item.userName}</td>
                              <td className='text-right'>
                                {formatMoney(item.allDeposits)}
                              </td>
                              <td>{item.allProfitLoss}</td>
                              <td>{item.betCount}</td>
                              <td className='text-right'>
                                {formatMoney(item.cashRevenue)}
                              </td>
                              <td className='text-right'>
                                {formatMoney(item.deposits)}
                              </td>
                              <td>{getDob(item.firstDeposit)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <No__Data__Found colspan={7} />
                      )}
                      {Object.keys(props.sportData).length > 0 &&
                        Object.keys(props.sportData.customerNetDeposit).length >
                        0 &&
                        props.sportData.customerNetDeposit.length > 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className='CMS-filter-result' id='result'>
                                <div className='CMS-pagination'>
                                  <div className='CMS-pagination-container d-flex justify-content-center'>
                                    <div className='CMS-page-slection '>
                                      <div className='CMS-file-type CMS-select select-width'>
                                        <select
                                          id='country'
                                          name='File'
                                          onChange={(event) =>
                                            setFileType(event.target.value)
                                          }
                                        >
                                          <option value='PDF'>PDF</option>
                                          <option value='CSV'>CSV</option>
                                        </select>
                                      </div>
                                      <div className='CMS-download-icon'>
                                        {fileType === "CSV" ? (
                                          <CSVLink
                                            style={{ color: "#fff" }}
                                            // data={props.sportData.customerNetDeposit}
                                            data={csvData}
                                            filename={`${props.reportPage}.csv`}
                                          >
                                            <span
                                              className='material-icons'
                                              data-icon='file_download'
                                            ></span>
                                          </CSVLink>
                                        ) : (
                                          <Link
                                            to={{}}
                                            onClick={() => printPdf()}
                                            style={{ color: "#fff" }}
                                          >
                                            <span
                                              className='material-icons'
                                              data-icon='file_download'
                                            ></span>
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    viewTable: state.reports.viewTable,
  };
};
export default connect(mapStateToProps)(PaymentTable);
