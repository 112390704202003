import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Datepicker from "../../Common/common_inputs/DatePicker";
import Input from "../../Common/common_inputs/Input";
import { multipleDepositReport } from "../../Common/data/mapData";
import {
  getDepositReport,
  setMultipleDepositData,
  setMultipleRPTable,
  setDisableBtn
} from "../reportSlice";
import ReportsTable from "../../Common/table/ReportsTable";
import { exportPdf } from "../../../sharedfiles/jsPdf";
import { cleanObject } from "../../../sharedfiles/helper";
import ButtonGroup from "../../Common/Buttons/ButtonGroup";
import { COMMON_CONSTANTS, REPORTS_CONSTANTS } from "../../../sharedfiles/Constants";
import Tooltips from "../../Common/Tooltip";
const MultipleDeposit = (props) => {
  const dispatch = useDispatch();
  const [fileType, setFileType] = useState("");
  const [error, setError] = useState([]);
  const [formData, setFormData] = useState({
    depositType: "",
    startDate: "",
    endDate: "",
    oddThreshold: "",
    minDeposit: "",
  });

  const updateToStore = (key, value) => {
    const toStore = { ...props.multipleDepositData, [key]: value };
    dispatch(setMultipleDepositData(toStore));
  };

  const setDates = (value, name) => {
    const updatedErrors = error.filter((err) => err !== name);
    setError(updatedErrors);
    updateToStore(name, value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (value === "") {
      setError([...error, name]);
    }
  };

  const filedsHandler = (e) => {
    const { name, value } = e.target;
    const newError = error.filter((err) => name !== err);
    setError(newError);
    updateToStore(name, value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (value === "") {
      setError([...error, name]);
    }
  };

  const onSubmit = () => {
    const inputValue = Object.keys({ ...formData });
    const err = [];
    inputValue.map((value) => formData[value] === "" && err.push(value));
    setError(err);
    const isAllGiven = Object.keys(cleanObject({ ...formData })).length === 5
    if (
      isAllGiven
    ) {
      let depositData = {
        depositType: formData.depositType,
        startDate: formData.startDate,
        endDate: formData.endDate,
        oddThroshold: formData.oddThreshold,
        minDeposit: formData.minDeposit * 100,
      };
      dispatch(
        getDepositReport(depositData, props.tableShouldView, "multipleDeposit")
      );
      dispatch(setDisableBtn(true))
    }
    // if (depositType === "") {
    //   setDepositError(true);
    // }
  };
  const resetData = () => {
    const data = {
      depositType: "",
      startDate: "",
      endDate: "",
      oddThreshold: "",
      minDeposit: "",
    };
    setFormData(data);
    setError([])
    dispatch(setMultipleDepositData(data));
    dispatch(setMultipleRPTable(false));
    // dispatch(setTableView(false));
  };
  const headers = [["Customer Id", "Desposit Amount"]];
  const data =
    props.depositReport &&
    Object.keys(props.depositReport).length > 0 &&
    props.depositReport.map((item) => [
      item.customerId,
      item.depositAmount / 100,
    ]);

  const csvData =
    Object.keys(props.depositReport).length > 0 ? [...headers, ...data] : [];

  const body =
    props.depositReport &&
      Object.keys(props.depositReport).length > 0 &&
      props.depositReport.length > 0
      ? [...data]
      : [];
  const styles = {
    3: {
      valign: "middle",
      halign: "right",
    },
    4: {
      valign: "middle",
      halign: "right",
    },
  };
  const subtitle = "";
  const firstTable = {
    subtitle,
    headers,
    body,
    styles,
    alignments: ["3", "4"],
  };
  const title = "Multiple Deposit Report";
  const searchDate = {
    fromDate: formData.startDate,
    toDate: formData.endDate,
  };
  const tableData = [firstTable];
  const printPdf = () => {
    exportPdf(title, searchDate, tableData, styles);
  };

  useEffect(() => {
    setFormData(props.multipleDepositData);
  }, [props.multipleDepositData]);

  useEffect(() => {
    handlePersist();
  }, []);

  useEffect(() => {
    var btnTimer;
    if (props.disableBtn) {
      btnTimer = setTimeout(() => {
        dispatch(setDisableBtn(false))
      }, 2500)
    };
    return () => clearTimeout(btnTimer);
  }, [props.disableBtn]);

  function handlePersist() {
    const isExist = props.tableShouldView &&
      Array.isArray(props.tableShouldView) &&
      props.tableShouldView?.length > 0 &&
      props.tableShouldView.some((v) => v === "multipleDeposit");
    if (!isExist) {
      resetData();
    }
  }
  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box__header'>
              <div className='CMS-box__title'>{props.reportTitle}</div>
            </div>
            <div className='row no-gutters'>
              {multipleDepositReport.map((item, index) => {
                const maxDate = formData.endDate
                  ? new Date(formData.endDate)
                  : new Date();
                const maxDateArr = [maxDate, new Date()];
                const minDateArr = [null, formData.startDate];
                return (
                  item.type === "date" && (
                    <div className='col-md-6 col-lg-6 col-xl-6'>
                      <div className='CMS-formGroup formGroup-inline'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {item.title}
                            &nbsp;&nbsp;
                            <Tooltips info={item.title} />
                          </div>
                        </div>

                        <div className='col-6'>
                          <div className='CMS-formControl-group'>
                            <Datepicker
                              //  title={item.title}
                              name={item.name}
                              inline={true}
                              errorMsg={
                                error.length > 0 &&
                                error.includes(item.name) &&
                                item.errorMsg
                              }
                              value={formData[item.name]}
                              setValue={(date, value) => setDates(date, value)}
                              maxDate={maxDateArr[index]}
                              minDate={minDateArr[index]}
                              ids={item.ids}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='col-12'></div>
                </div>
              </div>
            </div>

            <div className='row no-gutters'>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                {multipleDepositReport.map((item, index) => {
                  return (
                    item.type === "text" && (
                      <Input
                        inline={true}
                        // title={item.title}
                        name={item.name}
                        value={formData[item.name]}
                        placeholder={item.placeholder}
                        setValue={(e) => filedsHandler(e)}
                        regex={item.regex}
                        title={item.title}
                        errorMsg={
                          error.length > 0 &&
                          error.includes(item.name) &&
                          item.errorMsg
                        }
                        ids={item.ids}
                        info={item.name}
                        show={true}
                      />
                    )
                  );
                })}
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6'>
                <div className='CMS-formGroup formGroup-inline'>
                  <div className='col-6'>
                    <div className='CMS-formLabel'>
                      {REPORTS_CONSTANTS.multipleOverView_deposit}
                      &nbsp;&nbsp;
                      <Tooltips info={REPORTS_CONSTANTS.multipleOverView_deposit} />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='CMS-select'>
                      <select
                        name='depositType'
                        value={formData.depositType}
                        onChange={filedsHandler}
                        id='depositType'
                      >
                        <option value=''>Select</option>
                        <option id='1' value='deposit_first'>
                          1st Deposit
                        </option>
                        <option id='2' value='deposit_second'>
                          2nd Deposit
                        </option>
                        <option id='3' value='deposit_third '>
                          3rd Deposit
                        </option>
                      </select>
                      {error.length > 0 &&
                        error.includes("depositType") && (
                          <div className='CMS-form-errorMsg'>
                            {REPORTS_CONSTANTS.multipleOverView_ErrMsg}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>

            <div className='row'>
              <div className='col-12'>
                <div className="CMS-btnContainer">
                  <button
                    onClick={(e) => onSubmit(e)}
                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${props.disableBtn ? 'disable-button' : ''}`}
                    type={"button"}
                    id={"MDsearch"}
                  >
                    {COMMON_CONSTANTS.searchBtn}
                  </button>
                  <button
                    onClick={(e) => resetData(e)}
                    className={'CMS-btn CMS-btnQuaternary active CMS-btnMedium'}
                    type={"button"}
                    id={"MDsearch"}
                  >
                    {COMMON_CONSTANTS.resetBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-20'></div>
          <div className='CMS-filter-result' id='result'>
            {props.tableShouldView.includes("multipleDeposit") && (
              <>
                <ReportsTable
                  tableData={tableData}
                  fileType={fileType}
                  viewTable={props.multipleRPTable}
                  setFileType={(value) => setFileType(value)}
                  csvData={csvData}
                  fileName={title}
                  printPdf={() => printPdf()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    depositReport: state.reports.depositReport,
    loader: state.appslice.loader,
    reportPage: state.reports.reportPage,
    multipleRPTable: state.reports.multipleRPTable,
    tableShouldView: state.reports.tableShouldView,
    multipleDepositData: state.reports.multipleDepositData,
    disableBtn: state.reports.disableBtn
  };
}

export default connect(mapStateToProps)(MultipleDeposit);
