import React from 'react'

function AlertBox({ children }) {
    return (
        <div className="CMS_alert_wrapper">
            {children}
        </div>
    )
}

export default AlertBox;
