import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
import { getUserTable, setErrorMsg, setSuccessMsg } from "../CreateUser/createUserSlice";
export const adminUserSlice = createSlice({
  name: "adminuser",
  initialState: {
    adminData: [],
    dialogOpen: false,
    assignData: [],
  },
  reducers: {
    setAdminData: (state, action) => {
      state.adminData = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload;
    },
    setAssignData: (state, action) => {
      state.assignData = action.payload;
    },
  },
});
export const { setAdminData, setDialogOpen, setAssignData } =
  adminUserSlice.actions;

export const getAdminUser = (pageNumber, itemPerPage) => (dispatch) => {
  var url =
    `${userApi.getAdminUser}` +
    "?pageNumber=" +
    pageNumber +
    "&itemsPerPage=" +
    itemPerPage;
  apiCaller(url, "GET").then((response) => {
    if (response && response != null) {
      dispatch(setAdminData(response.data));
    }
  });
};

export const getAssignRole = (params, isRequired) => (dispatch, getState) => {
  if (params.roles.length > 0) {
    var obj = {
      // roleId: params.roleId[params.roleId.length - 1],
      userId: params.userId,
      roles: params.roles,
      // roles: [],
    };
    const { createuserSlice } = getState();
    var url = `${userApi.getAssignRole}`;
    apiCaller(url, "POST", obj)
      .then((response) => {
        !isRequired && toaster.success(messageEnhancer(response.msg));
        isRequired && dispatch(setSuccessMsg(messageEnhancer(response.msg)))
        if (response && response != null) {
          dispatch(setAssignData(response.data));
        }
        if (isRequired) {
          dispatch(getUserTable(createuserSlice.paginationFirstValue - 1, createuserSlice.paginationSecondValue))
        }
      })
      .catch((err) => {
        !isRequired && toaster.error("Failed")
        isRequired && dispatch(setErrorMsg(messageEnhancer(err.response)))
      });
  }
};

export default adminUserSlice.reducer;
