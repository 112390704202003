import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { exportPDFC } from '../../sharedfiles/jsPdfCommon';
import Vpagination from '../../sharedfiles/Vpagination';
import KycUserTable from './KycUserTable';
import { getDateFormate } from '../../sharedfiles/helper';
import {
  getKycUserList,
  setIsVisible,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow
} from './KycUserSlice';

const KycUsers = () => {
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);

  const dispatch = useDispatch();
  const kycPendingList = useSelector((state) => state.kycusers.kycUserData);

  useEffect(() => {
    updateTableToDocs();
  }, [kycPendingList])

  const callPageApi = (pageNumber, itemsPerPage) => {
    dispatch(setIsVisible(false));
    dispatch(getKycUserList(pageNumber - 1, itemsPerPage, { kycStatus: "SUBMITTED" }));
  };

  const headers = [
    [
      "Customer ID",
      "User Name",
      "First Name",
      "Last Name",
      "Email",
      "Phone",
      "State",
      "Country",
      "Registration Date",
    ],
  ];

  function updateTableToDocs() {
    const data =
      kycPendingList?.data &&
        Array.isArray(kycPendingList?.data) ?
        kycPendingList?.data.map((p, index) => [
          p.userId,
          p.userName,
          p.firstName,
          p.lastName,
          p.email,
          p.phone,
          p.state,
          p.country,
          getDateFormate(p.registrationDate),
        ]) : [];
    setPdfBody(data);
    const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []]
    setCsvData(csvData);
  }

  const exportPDF = () => {
    const title = "KYC User Report";
    exportPDFC(title, headers, pdfBody);
  };

  const pageFirstVal = useSelector(
    (state) => state.kycusers.paginationFirstValue
  );

  const pageSecondVal = useSelector(
    (state) => state.kycusers.paginationSecondValue
  );
  const recordsToShow = useSelector(
    (state) => state.kycusers.recordsShow
  );
  const isVisible = useSelector(
    (state) => state.kycusers.isVisible
  );
  const UserList = useSelector((state) => state.kycusers.kycUserData);

  return (
    <div className='CMS-tab-panel active' id='CMS-betting'>
      <div className='CMS-tabContent'>

        <div className='CMS-filter-result' id='result'>
          <div className='CMS-pagination'>
            <Vpagination
              data={UserList?.data}
              totalRecords={UserList?.totalRecords}
              paginationFirstValue={pageFirstVal}
              paginationSecondValue={pageSecondVal}
              setPaginationFirstValue={setPaginationFirstValue}
              setPaginationSecondValue={setPaginationSecondValue}
              recordsShow={recordsToShow}
              setRecordsShow={setRecordsShow}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              csvData={csvData}
              exportPDF={exportPDF}
              callApi={callPageApi}
              csvFileName={"KYC User Report"}
            />
          </div>
          <KycUserTable />
        </div>
      </div>
    </div>
  );
};

export default KycUsers;