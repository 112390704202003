import { createSlice } from '@reduxjs/toolkit'
import { apiCaller } from '../../api/apiCaller'
import { loginapi, userApi } from '../../sharedfiles/EndpointConfig'
import { messageEnhancer, setToLocalStorage } from '../../sharedfiles/helper'
import { fetchPagePermission, getBrand } from '../Dashboard/dashboardSlice'
export const LoginSlice = createSlice({
  name: 'loginSlice',
  initialState: {
    userData: [],
    password: '',
    userName: '',
    token: false,
    errorMessage: '',
    successMessage: '',
    message: '',
    platformUserDetails: {},
    isAffiliateUser: false,
    isSubAffiliateUser: '',
    totpEnabled: '',
    totpAuthorized: false,
    totpData: {},
    loginToken: '',
    totpEnabledData: {},
    totpLoginData: {},
    authStatus: {},
    totpLoginStatus: {},
    userRole: "",
    forceResetPasswordData: {},
    loggedUserId: "",
    affBtag: ''
  },
  reducers: {
    setLogin: (state, action) => {
      state.userData = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
    setPlatformUserDetails: (state, action) => {
      state.platformUserDetails = action.payload
    },
    setIsAffiliateUser: (state, action) => {
      state.isAffiliateUser = action.payload
    },
    setIsSubAffiliateUser: (state, action) => {
      state.isSubAffiliateUser = action.payload
    },
    setTotpEnabled: (state, action) => {
      state.totpEnabled = action.payload
    },
    setTotpAuthorized: (state, action) => {
      state.totpAuthorized = action.payload
    },
    setTotpData: (state, action) => {
      state.totpData = action.payload
    },
    setLoginToken: (state, action) => {
      state.loginToken = action.payload
    },
    setTotpEnabledData: (state, action) => {
      state.totpEnabledData = action.payload
    },
    setTotpLoginData: (state, action) => {
      state.totpLoginData = action.payload
    },
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload
    },
    setTotpLoginStatus: (state, action) => {
      state.totpLoginStatus = action.payload
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setForceResetPasswordData: (state, action) => {
      state.forceResetPasswordData = action.payload;
    },
    setLoggedUserId: (state, action) => {
      state.loggedUserId = action.payload;
    },
    setAffBtag: (state, action) => {
      state.affBtag = action.payload;
    }
  },
})
export const {
  setLogin,
  setMessage,
  setToken,
  setSuccessMessage,
  setErrorMessage,
  setPlatformUserDetails,
  setIsAffiliateUser,
  setIsSubAffiliateUser,
  setTotpEnabled,
  setTotpAuthorized,
  setTotpData,
  setLoginToken,
  setTotpEnabledData,
  setTotpLoginData,
  setAuthStatus,
  setTotpLoginStatus,
  setUserRole,
  setForceResetPasswordData,
  setLoggedUserId,
  setAffBtag
} = LoginSlice.actions

export const getLoginAccess = (params, props) => (dispatch) => {
  let obj = {
    password: params.password,
    userName: params.userName,
  }

  let url = `${loginapi.getLogin}`
  apiCaller(url, 'POST', obj)
    .then((response) => {
      if (response.status == 400) {
        dispatch(setErrorMessage(response.msg))
      }
      dispatch(setTotpEnabled(response.data.totpEnabled))
      localStorage.setItem("totpToken", response.data.accessToken)
      localStorage.setItem("refreshToken", response.data.refreshToken)
      localStorage.setItem("userName", obj.userName);
      localStorage.setItem("isAffiliate", response.data.btag)
      dispatch(setAffBtag(response.data.btag))
      if (response.data.btag !== null) {
        dispatch(setIsAffiliateUser(true))
        dispatch(setIsSubAffiliateUser(true))
      }
      if (response.data.forceChangePin) {
        props.history.push("pinchange");
      } else {
        if (!response.data.forceTotp && !response.data.totpEnabled) {
          dispatch(setToken(true));
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("userId", response.data.userId);
          dispatch(getBrand(props, response.data.accessToken));
        } else {
          if (!response.data.totpEnabled) {
            props.history.push("enabletotp");
          }
          else {
            props.history.push("authenticate");
          }
        }
      }
    })
    .catch((error) => {
      const { response, statusCode, statusText } = error
      dispatch(setErrorMessage(response))
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      if (!['INVALID_USER_NAME_OR_PASSWORD'].includes(response)) {
        dispatch(setErrorMessage(hydratedText))
      }
    })
}


export const getPlatformUserDetails = () => (dispatch) => {
  const url = userApi.platformUserProfile

  apiCaller(url, 'GET')
    .then((res) => {
      dispatch(setPlatformUserDetails(res.data))
      const logUserId = res.data.platformUsers.id
      localStorage.setItem("logUserId", logUserId);
      dispatch(setLoggedUserId(logUserId))
      const isAffliate = res && res.data.affiliateUsers !== null
      dispatch(setUserRole(res.data.platformUsers.userRole));
      if (res && res.data.affiliateUsers !== null) {
        const affliateID = res.data.affiliateUsers?.platformUserId
        const affBtag = res.data.affiliateUsers?.btag
        setToLocalStorage('affBtag', affBtag)
        setToLocalStorage('AffliateUserId', affliateID)
      }

      if (res && res.data.affiliateUsers.parentAffiliateId !== null) {
        const subAffliateID = res.data.affiliateUsers?.platformUserId
        setToLocalStorage('SubAffliateUserId', subAffliateID)
      }
    })
    .catch(
      (err) => {
        dispatch(setErrorMessage(err))
      },
    )
}

export const getTotpDetatils = (props, token) => (dispatch) => {
  const url = userApi.totpDetails

  apiCaller(url, 'GET', {}, token)
    .then((res) => {
      dispatch(setTotpData(res.data))
      if (res.msg === "TOTP_ALREADY_ENABLED") {
        props.history.push("authenticate");
      }
    })
    .catch((err) => console.log('totpDetails', err))
}

export const enableTotp = (payload, token) => (dispatch) => {
  const url = userApi.totpDetails
  // const
  apiCaller(url, 'POST', payload, token)
    .then((res) => {
      dispatch(setTotpEnabledData(res))
      if (res.msg === 'ENABLE_TOTP_SUCCESS') {
        dispatch(setAuthStatus({ status: true, msg: res.msg }))
      } else {
        dispatch(setAuthStatus({ status: false, msg: res.msg }))
      }
    })
    .catch((err) => {
      dispatch(setAuthStatus({ status: false, msg: err.response, field: err.response === "INVALID_PASSWORD" ? "password" : "totp" }))
    })
}

export const totpLogin = (payload, token, props) => (dispatch) => {
  const url = userApi.totpLogin
  apiCaller(url, 'POST', payload, token)
    .then((res) => {
      if (res.msg === 'LOGIN_SUCCESS') {
        dispatch(setTotpLoginStatus({ status: true, msg: res.msg }));
        dispatch(setToken(true));
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("userId", res.data.userId);
        dispatch(getBrand(props, res.data.accessToken));
      }
      else {
        dispatch(setTotpLoginStatus({ status: false, msg: res.msg }));
      }

    })
    .catch((err) => {
      dispatch(setTotpLoginStatus({ status: false, msg: err.response }));
    })
}


export const forceResetPassword = (payload, token) => (dispatch) => {
  // const url = `${userApi.resetPassword}/force`;
  const url = userApi.forcePasswordChange;
  apiCaller(url, "POST", payload, token).then((res) => {
    dispatch(setForceResetPasswordData(
      {
        status: res.msg === "RESET_PASSWORD_SUCCESS",
        msg: messageEnhancer(res.msg),
        field: ""
      }
    )
    )
  }).catch(err => {
    dispatch(setForceResetPasswordData({
      status: false,
      msg: messageEnhancer(err.response),
      field: "previousPassword"
    }))
  })
}

export const changeNewPassword = (payload, userId) => dispatch => {
  const url = userApi.resetPassword(userId);

  apiCaller(url, "POST", payload).then(res => {
    // dispatch(setSuccessMessage("SUCCESS"));
    // dispatch(setPasswordMsg(messageEnhancer(res.msg)));
  }).catch(err => messageEnhancer(err.response))
}

export default LoginSlice.reducer
