import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../sharedfiles/helper";
import { setLoader } from "../AppSlice";
import Loading from "../loading/Loading";
import { getOpTableData, getOptBonus, setOpTableData } from "./dashboardSlice";

const OpTable = (props) => {
  const [durationFrom, setDurationFrom] = useState("TODAY");
  const brandChage = useSelector((state) => state.dashboard.brandData);
  const dispatch = useDispatch();
  function callAPi(filter) {
    dispatch(setLoader(true));
    dispatch(getOptBonus(filter));
    dispatch(getOpTableData(filter));
  }
  const seasonChanged = (e) => {
    dispatch(setOpTableData([]));
    setDurationFrom(e.target.value);
    callAPi(e.target.value);
  };
  useEffect(() => {
    setTimeout(() => {
      if (Array.isArray(brandChage) && brandChage.length > 0) {
        callAPi(durationFrom);
      }
    }, 300);
  }, [brandChage]);

  return (
    <>
      <div
        className='CMS-tab-panel active '
        id='CMS-allBets'
        style={{ transform: "translateY(-20px)" }}
      >
        <div className='CMS-tabContent'>
          <div className='CMS-box'>
            <div className='CMS-table CMS-table-triped'>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className='CMS-select '>
                        <select onChange={seasonChanged} value={durationFrom} className="pointer">
                          <option value='TODAY'>Today</option>
                          <option value='YESTERDAY'>Yesterday</option>
                          <option value='DAY_BEFORE_YESTERDAY'>
                            Day before Yesterday
                          </option>
                          <option value='LAST_WEEK'>Last Week</option>
                          <option value='THIS_WEEK'>This Week</option>
                          <option value='LAST_MONTH'>Last Month</option>
                          <option value='THIS_MONTH'>This Month</option>
                        </select>
                      </div>
                    </th>
                    <th> Money In</th>
                    <th>Money Out</th>
                    <th># of Players</th>
                    <th># of Players Deposited</th>
                    <th># of First Deposits</th>
                    <th># of Second Deposits</th>
                    <th># Bonus Issued</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.opTableData &&
                  Object.keys(props.opTableData).length > 0 ? (
                    <tr>
                      <td></td>
                      <th>&#8377; {formatMoney(props.opTableData?.moneyIn)}</th>
                      <td>&#8377; {formatMoney(props.opTableData?.moneyOut)}</td>
                      <td>{props.opTableData.noOfWagering}</td>
                      <td>
                        &#8377;
                        {formatMoney(props.opTableData?.totalAmountWagered)}
                      </td>
                      <td>
                        &#8377;{" "}
                        {formatMoney(props.opTableData?.totalFirstDeposit)}
                      </td>
                      <td>
                        &#8377;{" "}
                        {formatMoney(props.opTableData?.totalSecondDeposit)}
                      </td>
                      <td>
                        {props.bonusSystemValue !== ""
                          ? props.bonusSystemValue
                          : "0"}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={8} className='text-center'>
                        <Loading
                          isLoader={props.loader}
                          isNoData={
                            !props.loader &&
                            Object.keys(props.opTableData).length === 0
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    opTableData: state.dashboard.opTableData,
    loader: state.appslice.loader,
    bonusSystemValue: state.dashboard.bonusSystemValue,
  };
};

export default connect(mapStateToProps)(OpTable);
