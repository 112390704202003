import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { getUTCDate } from '../../../sharedfiles/helper'
import { getWithdrawTransactionReorts, setWithdrawDates } from "../reportSlice"
import ReportsWrapper from "../ReportsWrapper"
import WithdrawalTable from "./WithdrawalTable"

const Withdrawal = () => {
    const { withdrawDates, withdrawalData, paginationFirstValue, paginationSecondValue } = useSelector(state => state.reports)
    const dispatch = useDispatch()
    const callApi = (formData) => {
        const requestPayload = {
            startDate: getUTCDate(withdrawDates.dateFrom, "start"),
            endDate: getUTCDate(withdrawDates.dateTo, "end")
        }
        return getWithdrawTransactionReorts(requestPayload);

    }


    return (
        <React.Fragment>
            <ReportsWrapper
                reportTitle={"WITHDRAWAL REPORT"}
                currentTab={"WITHDRAWAL REPORT"}
                pageKey={"WithdrawalReport"}
                setDateToStore={setWithdrawDates}
                persistDate={withdrawDates}
                sportData={withdrawalData}
                pageNumber={paginationFirstValue}
                itemsPerPage={paginationSecondValue}
                api={getWithdrawTransactionReorts}
                paginationRequired={true}
                ReportTable={() => (
                    <WithdrawalTable
                        dates={withdrawDates}
                        reportsData={withdrawalData}
                        reportPage={"Withdrawal Report"}
                    />
                )}
            />
        </React.Fragment>
    )
}

export default Withdrawal
