import React from 'react'
import Tooltips from '../../Common/Tooltip'
import Highcharts from 'highcharts';
import boost from 'highcharts/modules/boost';
import HighchartsReact from 'highcharts-react-official';

boost(Highcharts);

const UserActiveTimeChart = ({ ddlValue, chartData }) => {
    const loginBasedDataResp = [chartData.loginBasedDataResp].flat()
    const bettingBasedDataResp = [chartData.bettingBasedDataResp].flat()
    const depositBasedDataResp = [chartData.depositBasedDataResp].flat()
    const withdrawalBasedDataResp = [chartData.withdrawalBasedDataResp].flat()


    let elevationData;

    switch (ddlValue) {
        case 'LOGIN':
            elevationData = loginBasedDataResp.map(obj => [obj?.hour, obj?.userCount]);
            break;
        case 'BETTING':
            elevationData = bettingBasedDataResp.map(obj => [obj?.hour, obj?.userCount]);
            break;
        case 'DEPOSIT':
            elevationData = depositBasedDataResp.map(obj => [obj?.hour, obj?.userCount]);
            break;
        case 'WITHDRAW':
            elevationData = withdrawalBasedDataResp.map(obj => [obj?.hour, obj?.userCount]);
            break;
        default:
            elevationData = [];
            break;
    }

    const chartOptions = {
        chart: {
            type: 'area',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            scrollablePlotArea: {
                minWidth: 100
            }
        },

        caption: {
            text: ''
        },

        title: {
            text: ''
        },

        accessibility: {
            description: '',
            landmarkVerbosity: 'one'
        },
        plotOptions: {
            series: {
                pointWidth: 30,
                pointPadding: 0,
                dataLabels: {
                    align: 'left',
                    enabled: true,
                    rotation: 0,
                    x: -15,
                    y: -2
                }
            },
        },
        lang: {
            accessibility: {
                screenReaderSection: {
                    annotations: {
                        descriptionNoPoints: '{annotationText}, at distance {annotation.options.point.x}km, elevation {annotation.options.point.y} meters.'
                    }
                }
            }
        },

        credits: {
            enabled: false
        },

        annotations: [{
            draggable: '',
            labelOptions: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                verticalAlign: 'top',
                y: 15
            },
            labels: [{
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 27.98,
                    y: 255
                },
                text: 'Arbois'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 45.5,
                    y: 611
                },
                text: 'Montrond'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 63,
                    y: 651
                },
                text: 'Mont-sur-Monnet'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 84,
                    y: 789
                },
                x: -10,
                text: 'Bonlieu'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 129.5,
                    y: 382
                },
                text: 'Chassal'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 159,
                    y: 443
                },
                text: 'Saint-Claude'
            }]
        }, {
            draggable: '',
            labels: [{
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 101.44,
                    y: 1026
                },
                x: -30,
                text: 'Col de la Joux'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 138.5,
                    y: 748
                },
                text: 'Côte de Viry'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 176.4,
                    y: 1202
                },
                text: ''
            }]
        }, {
            draggable: '',
            labelOptions: {
                shape: 'connector',
                align: 'right',
                justify: false,
                crop: true,
                style: {
                    fontSize: '10px',
                    textOutline: '1px white'
                }
            },
            labels: [{
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 96.2,
                    y: 783
                },
                text: '6.1 km climb <br>4.6% on avg.'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 134.5,
                    y: 540
                },
                text: '7.6 km climb <br>5.2% on avg.'
            }, {
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 172.2,
                    y: 925
                },
                text: '11.7 km climb <br>6.4% on avg.'
            }]
        }],

        xAxis: {
            labels: {
                format: '',
            },
            minRange: 2,
            title: {
                text: ''
            },

        },

        yAxis: {
            startOnTick: true,
            endOnTick: false,
            maxPadding: 0.35,
            title: {
                text: ''
            },
            labels: {
                format: '',
                enabled: true,
            },
            accessibility: {
                description: '',
                rangeDescription: ''
            }
        },

        tooltip: {
            headerFormat: '',
            shared: true
        },

        legend: {
            enabled: false
        },

        series: [{
            data: elevationData,
            lineColor: '#544fc5',
            color: '#00e272',
            fillOpacity: 0.5,
            name: 'Users',
            marker: {
                enabled: true
            },
            threshold: null
        }]
    }
    return (
        <>
            <div className="CMS-graph CMS-highCharts-userActiveTimes">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    containerProps={{ id: 'CMS-highCharts-userActiveTimes' }}
                />
            </div>
        </>
    )
}

export default UserActiveTimeChart