import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi } from "../../sharedfiles/EndpointConfig";
import { setVisible } from "../AppSlice";
export const MultibetSlice = createSlice({
  name: "multibetconfig",
  initialState: {
    multibet: [],
    multibetAccount: [],
    prevTableData: [],
  },
  reducers: {
    setMultipleConfig: (state, action) => {
      state.multibet = action.payload;
    },
    setMultibetAccount: (state, action) => {
      state.multibetAccount = action.payload;
    },
    setPrevTableData: (state, action) => {
      state.prevTableData = action.payload;
    },
  },
});
export const { setMultipleConfig, setMultibetAccount, setPrevTableData } =
  MultibetSlice.actions;

export const getMultiplebet = () => (dispatch) => {
  let url = `${bonusApi.MultibetConfig}`;
  apiCaller(url, "GET").then((response) => {
    if (response.status === 200) {
      dispatch(setMultipleConfig(response.data));
      const data = response.data?.configs?.map((item) => ({
        bonusPercentage: item.bonusPercentage,
        eventCount: item.eventCount,
      }));
      dispatch(setPrevTableData(data || []));
    }
  }).catch((err) => {
    console.log(err);
  });
};

export const getMultibetAccount = (page, items, type) => (dispatch) => {
  let url = `${bonusApi.MultibetAccount}?pageNumber=${page}&itemsPerPage=${items}&type=${type}`;
  apiCaller(url, "GET").then((response) => {
    if (response.data !== null && response.data !== undefined) {
      if (response.status == 200) {
        dispatch(setMultibetAccount(response.data));
        dispatch(setVisible(true));
      }
    }
  });
};

export const MultibetAccount = (data) => (dispatch) => {
  var url = `${bonusApi.MultibetConfig}`;
  apiCaller(url, "POST", data).then((response) => {
    if (response.data !== null && response.data !== undefined) {
      if (response.status == 200) {
        dispatch(setMultibetAccount(response.data));
      }
    }
  });
};
export default MultibetSlice.reducer;
