import React, { Component } from "react";
import { connect } from "react-redux";
import HiLoGraph from "./revenueByBrand";
import CashRevenuChart from "./cashRevenuChart";
import BasicLineChart from "./revenuByStackChart";
import StackRevnueChart from "./stackvrevenuchart";
import BetCountChart from "./betcountchart";
import Timestampmsg from "./Timestampmsg";
// import AllBet from "./allBet";
import BetsBoard from "./BetsBoard";
import {
  getWinners,
  getLoosers,
  getProfitSports,
  getRevenuBrand,
  getRevenuSport,
  getTurnover,
  getHighestTurnover,
  getStackFactor,
  getStackRevenuGraph,
  setActiveTab,
  setRevenueByStakeFactorRange,
  setBetCountRange,
  setRevenueBySportRange,
  setStakeVsRevenueRange,
  setCashRevenueBySportRange,
  setProfitSportsRange,
  setTopWinnersRange,
  setTopLosersRange,
  setTurnoverSportsRange,
} from "./dashboardSlice";
import Loading from "../loading/Loading";
import OpTable from "./OpTable";
import DS__Table from "../Common/table/DS__Table";
import { sideTableData } from "../Common/data/mapData";
import { Link } from "react-router-dom";
import { setBaseUrl } from "../../sharedfiles/EndpointConfig";
import { DASBOARD_CONSTANTS } from "../../sharedfiles/Constants";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      brand: false,
      brandOpen: false,
      activeTab: "ALL",
      profitSports: "",
      turnoverSports: "",
      topWinners: "",
      topLosers: "",
      isGameWrox: false,
      loadOp: false,
    };
    this.handleChangeDdl = this.handleChangeDdl.bind(this);
  }

  handleTabClickChange = (e) => {
    this.setState({ activeTab: e.target.value });
    this.props.dispatch(setActiveTab(e.target.value));
  };

  callerFunction() {
    const activeBrand = localStorage.getItem("isExist");
    const active = activeBrand === "GAMEWORX_PERMISSION";
    this.setState({
      isGameWrox: active,
    });

    const computeData = (data) => {
      const exist = data && Array.isArray(data) && data.length > 0;
      return !exist;
    };

    const props = this.props;

    computeData(props.profitSportData) &&
      props.dispatch(getProfitSports(props.profitSportsRange)); //Most Profitable Sports
    computeData(props.highestTurnover) &&
      props.dispatch(getHighestTurnover(props.turnoverSportsRange)); //Highest Turnover Sports
    computeData(props.winnerData) &&
      props.dispatch(getWinners(props.topWinnersRange)); //Top 10 Winners
    computeData(props.looserData) &&
      props.dispatch(getLoosers(props.topLosersRange)); //Top 10 Losers

    !active &&
      computeData(props.revenuStackFactor) &&
      props.dispatch(getStackFactor(props.revenueByStakeFactorRange)); //Revenue by Stake Factor
    computeData(props.revenuBrandData) &&
      props.dispatch(getRevenuBrand(props.revenueBySportRange)); //Revenue by Sport
    computeData(props.turnover) &&
      props.dispatch(getTurnover(props.betCountRange)); //Bet Count
    !active &&
      computeData(props.stackRevnuGraph) &&
      props.dispatch(getStackRevenuGraph(props.stakeVsRevenueRange)); //Stakes Vs Revenue
    computeData(props.revenuSportData) &&
      props.dispatch(getRevenuSport(props.cashRevenueBySportRange)); //Cash Revenue by Sports
  }

  componentDidMount() {
    setBaseUrl();
    this.callerFunction();
  }

  handleChangeDdl(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    switch (name) {
      case "profitSports":
        this.props.dispatch(setProfitSportsRange(value));
        this.props.dispatch(getProfitSports(value));
        break;
      case "turnoverSports":
        this.props.dispatch(setTurnoverSportsRange(value));
        this.props.dispatch(getHighestTurnover(value));
        break;
      case "topWinners":
        this.props.dispatch(setTopWinnersRange(value));
        this.props.dispatch(getWinners(value));
        break;
      case "topLosers":
        this.props.dispatch(setTopLosersRange(value));
        this.props.dispatch(getLoosers(value));
        break;
      default:
        break;
    }
  }

  stackChange = (event) => {
    this.props.dispatch(getStackFactor(event.target.value));
  };

  componentDidUpdate(prevProps) {
    if (this.props?.deafaultPage !== prevProps?.deafaultPage) {
      this.setState({ loadOp: true });
    }
  }

  handleChangeRange(event) {
    const { value, name } = event.target;
    switch (name) {
      case "revenueByStakeFactor":
        this.props.dispatch(getStackFactor(value));
        this.props.dispatch(setRevenueByStakeFactorRange(value));
        break;
      case "revenueBySport":
        this.props.dispatch(getRevenuBrand(value));
        this.props.dispatch(setRevenueBySportRange(value));
        break;
      case "betCount":
        this.props.dispatch(getTurnover(value));
        this.props.dispatch(setBetCountRange(value));
        break;
      case "stakeVsRevenue":
        this.props.dispatch(getStackRevenuGraph(value));
        this.props.dispatch(setStakeVsRevenueRange(value));
        break;
      case "cashRevenueBySports":
        this.props.dispatch(getRevenuSport(value));
        this.props.dispatch(setCashRevenueBySportRange(value));
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="CMS-layout-innerContent">
          <div className="CMS-page CMS-dashboard">
            {this.state.loadOp && (
              <div className="CMS-page-header">{DASBOARD_CONSTANTS.operation_summary}</div>
            )}
            <div className="CMS-page-content">
              <div className="CMS-page-content__left">
                {this.state.loadOp ? (
                  <OpTable />
                ) : (
                  <>
                    <div className='col-lg-13 col-xl-13 mb-0 d-flex justify-content-end'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formControl'>
                          <div className='CMS-select'>
                            <select
                              name='activeTab'
                              id='betTabs'
                              value={this.state.activeTab}
                              onChange={(e) => this.handleTabClickChange(e)}
                              className="pointer"
                            >
                              <option value="ALL">{DASBOARD_CONSTANTS.ddlOpt_allBets}</option>
                              <option value="PRE_MATCH">{DASBOARD_CONSTANTS.ddlOpt_preMatch}</option>
                              <option value="IN_PLAY">{DASBOARD_CONSTANTS.ddlOpt_InPlay}</option>
                              <option value="CASINO">{DASBOARD_CONSTANTS.ddlOpt_Casino}</option>
                              <option value="NE_GAMES">{DASBOARD_CONSTANTS.ddlOpt_NEgames}</option>
                              <option value="SETTLED">{DASBOARD_CONSTANTS.ddlOpt_Settled}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CMS-tabs-content">
                      <BetsBoard vidator={this.state.activeTab} />
                    </div>
                  </>
                )}

                <div className="row">
                  {!this.state.isGameWrox && (
                    <div className="col-6">
                      <div className="CMS-box CMS-graph">
                        <div className="CMS-box__header">
                          <div className="CMS-box__header-left">
                            <div className="CMS-box__title CMS-yellow">
                              {DASBOARD_CONSTANTS.graph_stakeFactor}
                            </div>
                          </div>
                          <div className="CMS-box__header-right">
                            <div className="CMS-box__actionIcons"></div>
                            <div className="CMS-select">
                              <select
                                id="revenuebystake"
                                name="revenueByStakeFactor"
                                onChange={(event) =>
                                  this.handleChangeRange(event)
                                }
                                value={this.props.revenueByStakeFactorRange}
                                className="pointer"
                              >
                                <option value="THIS_MONTH">{DASBOARD_CONSTANTS.ddlOpt_ThisMonth}</option>
                                <option value="LAST_MONTH">
                                  {DASBOARD_CONSTANTS.ddlOpt_PrevMonth}
                                </option>
                                <option value="THIS_WEEK">{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                                <option value="TODAY">{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                                <option value="YESTERDAY">{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="CMS-graph-content">
                          {/* <!-- <img src="images/graphs/small-graph.JPG" alt="Graph"> --> */}
                          <div id="revenueBy-StakeFactor">
                            {Array.isArray(this.props?.revenuStackFactor) &&
                              this.props?.revenuStackFactor?.length > 0 ? (
                              <BasicLineChart
                                data={this.props?.revenuStackFactor}
                              />
                            ) : (
                              <Loading
                                isLoader={this.props?.revenuLoader}
                                isNoData={
                                  !this.props?.revenuLoader &&
                                  this.props?.revenuStackFactor?.length === 0
                                }
                              />
                            )}
                            {this.props?.revenuStackFactor?.length === 0 ? (
                              ""
                            ) : (
                              <Timestampmsg />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-6">
                    <div className="CMS-box CMS-graph">
                      <div className="CMS-box__header">
                        <div className="CMS-box__header-left">
                          <div className="CMS-box__title CMS-red">
                            {DASBOARD_CONSTANTS.graph_sportRevenue}
                          </div>
                        </div>
                        <div className="CMS-box__header-right">
                          <div className="CMS-box__actionIcons"></div>
                          <div className="CMS-select">
                            <select
                              id="revenuebySport"
                              name="revenueBySport"
                              onChange={(event) =>
                                this.handleChangeRange(event)
                              }
                              value={this.props.revenueBySportRange}
                              className="pointer"
                            >
                              <option value={this.props?.seasonDropdown}>
                                {DASBOARD_CONSTANTS.ddlOpt_ThisMonth}
                              </option>
                              <option value="LAST_MONTH">{DASBOARD_CONSTANTS.ddlOpt_PrevMonth}</option>
                              <option value="THIS_WEEK">{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                              <option value="TODAY">{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                              <option value="YESTERDAY">{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="CMS-graph-content">
                        {/* <!-- <img src="images/graphs/small-graph.JPG" alt="Graph"> --> */}
                        <div id="revenueBy-brand">
                          {Array.isArray(this.props?.revenuBrandData) &&
                            this.props?.revenuBrandData?.length > 0 ? (
                            <HiLoGraph data={this.props?.revenuBrandData} isGameWrox={this.state.isGameWrox} />
                          ) : (
                            <Loading
                              isLoader={this.props?.sportLoader}
                              isNoData={
                                !this.props?.sportLoader &&
                                this.props?.revenuBrandData?.length === 0
                              }
                            // unCondition={this.props.totalRevenue}
                            />
                          )}
                          {this.props?.revenuBrandData?.length === 0 ? (
                            ""
                          ) : (
                            <Timestampmsg />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
                <div className='row'> */}
                  <div className="col-6">
                    <div className="CMS-box CMS-graph">
                      <div className="CMS-box__header">
                        <div className="CMS-box__header-left">
                          <div className="CMS-box__title CMS-green">
                            {DASBOARD_CONSTANTS.graph_betCount}
                          </div>
                        </div>
                        <div className="CMS-box__header-right">
                          <div className="CMS-box__actionIcons"></div>
                          <div className="CMS-select">
                            <select
                              id="betCount"
                              name="betCount"
                              onChange={(event) =>
                                this.handleChangeRange(event)
                              }
                              value={this.props.betCountRange}
                              className="pointer"
                            >
                              <option value="THIS_MONTH"> {DASBOARD_CONSTANTS.ddlOpt_ThisMonth}</option>
                              <option value="LAST_MONTH">{DASBOARD_CONSTANTS.ddlOpt_PrevMonth}</option>
                              <option value="THIS_WEEK">{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                              <option value="TODAY">{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                              <option value="YESTERDAY">{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="CMS-graph-content">
                        <div id="bet-count">
                          {Array.isArray(this.props?.turnOver) &&
                            this.props?.turnOver?.length > 0 ? (
                            <BetCountChart data={this.props?.turnOver} />
                          ) : (
                            <Loading
                              isLoader={this.props?.betLoader}
                              isNoData={
                                !this.props?.betLoader &&
                                this.props?.turnOver?.length === 0
                              }
                            />
                          )}
                          {this.props?.turnOver?.length === 0 ? (
                            ""
                          ) : (
                            <Timestampmsg />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.state.isGameWrox && (
                    <div className="col-6">
                      <div className="CMS-box CMS-graph">
                        <div className="CMS-box__header">
                          <div className="CMS-box__header-left">
                            <div className="CMS-box__title">
                              {DASBOARD_CONSTANTS.graph_staleVsRevenue}
                            </div>
                          </div>
                          <div className="CMS-box__header-right">
                            <div className="CMS-box__actionIcons"></div>
                            <div className="CMS-select">
                              <select
                                id="stakeVSrevenue"
                                name="stakeVsRevenue"
                                onChange={(event) =>
                                  this.handleChangeRange(event)
                                }
                                value={this.props.stakeVsRevenueRange}
                                className="pointer"
                              >
                                <option value="THIS_MONTH">{DASBOARD_CONSTANTS.ddlOpt_ThisMonth}</option>
                                <option value="LAST_MONTH">
                                  {DASBOARD_CONSTANTS.ddlOpt_PrevMonth}
                                </option>
                                <option value="THIS_WEEK">{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                                <option value="TODAY">{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                                <option value="YESTERDAY">{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="CMS-graph-content">
                          <div id="cashStakes-revenue">
                            {Array.isArray(this.props?.stackRevnuGraph) &&
                              this.props?.stackRevnuGraph?.length > 0 ? (
                              <StackRevnueChart
                                data={this.props?.stackRevnuGraph}
                              />
                            ) : (
                              <Loading
                                isLoader={this.props?.stackLoader}
                                isNoData={
                                  !this.props?.stackLoader &&
                                  this.props?.stackRevnuGraph?.length === 0
                                }
                              />
                            )}
                            {this.props?.stackRevnuGraph?.length === 0 ? (
                              ""
                            ) : (
                              <Timestampmsg />
                            )}
                          </div>
                          {/* <!-- <img src="images/graphs/small-graph.JPG" alt="Graph"> --> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="CMS-box CMS-graph">
                      <div className="CMS-box__header">
                        <div className="CMS-box__header-left">
                          <div className="CMS-box__title CMS-black">
                            {DASBOARD_CONSTANTS.graph_sportsCashRevenue}
                          </div>
                        </div>
                        <div className="CMS-box__header-right">
                          <div className="CMS-box__actionIcons"></div>
                          <div className="CMS-select">
                            <select
                              id="cashrevenueSports"
                              name="cashRevenueBySports"
                              onChange={(e) => this.handleChangeRange(e)}
                              value={this.props.cashRevenueBySportRange}
                              className="pointer"
                            >
                              <option value="THIS_MONTH">{DASBOARD_CONSTANTS.ddlOpt_ThisMonth}</option>
                              <option value="LAST_MONTH">{DASBOARD_CONSTANTS.ddlOpt_PrevMonth}</option>
                              <option value="THIS_WEEK">{DASBOARD_CONSTANTS.ddlOpt_ThisWeek}</option>
                              <option value="TODAY">{DASBOARD_CONSTANTS.ddlOpt_Today}</option>
                              <option value="YESTERDAY">{DASBOARD_CONSTANTS.ddlOpt_Yesterday}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="CMS-graph-content">
                        <div id="cashRevenue-sport">
                          {Array.isArray(this.props?.revenuSportData) &&
                            this.props?.revenuSportData?.length > 0 ? (
                            <CashRevenuChart
                              data={this.props?.revenuSportData}
                              isGameWrox={this.state.isGameWrox}
                            />
                          ) : (
                            <Loading
                              isLoader={this.props?.cashLoader}
                              isNoData={
                                !this.props?.cashLoader &&
                                this.props?.revenuSportData?.length === 0
                              }
                            />
                          )}
                          {this.props?.revenuSportData?.length === 0 ? (
                            ""
                          ) : (
                            <Timestampmsg />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="CMS-page-content__right">
                {sideTableData.map((item, ind) => {
                  const props = this.props;
                  const apiData = [
                    this.props?.profitSportData,
                    this.props?.highestTurnover,
                    this.props?.winnerData,
                    this.props?.looserData,
                  ];
                  const loaders = [
                    this.props?.mostLoader,
                    this.props?.highLoader,
                    this.props?.winnerLoader,
                    this.props?.looserLoader,
                  ];
                  const rangeValues = [
                    props.profitSportsRange,
                    props.turnoverSportsRange,
                    props.topWinnersRange,
                    props.topLosersRange,
                  ];
                  return (
                    <DS__Table
                      key={item.id}
                      ids={item.ids}
                      cmpData={item}
                      seasonDropdown={rangeValues[ind]}
                      tableData={apiData[ind]}
                      name={item.name}
                      loader={loaders[ind]}
                      styles={item.styles}
                      handleChange={(data) => this.handleChangeDdl(data)}
                      isGameWrox={this.state.isGameWrox}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.dashboard.userData,
    winnerData: state.dashboard.winnerData,
    looserData: state.dashboard.looserData,
    open: state.appslice.open,
    profitSportData: state.dashboard.profitSportData,
    revenuBrandData: state.dashboard.revenuBrandData,
    revenuSportData: state.dashboard.revenuSportData,
    brandData: state.dashboard.brandData,
    seasonDropdown: state.dashboard.seasonDropdown,
    turnOver: state.dashboard.turnover,
    highestTurnover: state.dashboard.highestTurnover,
    revenuStackFactor: state.dashboard.revenuStackFactor,
    stackRevnuGraph: state.dashboard.stackRevnuGraph,
    loader: state.appslice.loader,
    mostLoader: state.appslice.mostLoader,
    highLoader: state.appslice.highLoader,
    winnerLoader: state.appslice.winnerLoader,
    looserLoader: state.appslice.looserLoader,
    revenuLoader: state.appslice.revenuLoader,
    sportLoader: state.appslice.sportLoader,
    betLoader: state.appslice.betLoader,
    stackLoader: state.appslice.stackLoader,
    cashLoader: state.appslice.cashLoader,
    activeTab: state.dashboard.activeTab,
    totalRevenue: state.dashboard.totalRevenue,
    deafaultPage: state.appslice.deafaultPage,
    revenueByStakeFactorRange: state.dashboard.revenueByStakeFactorRange,
    revenueBySportRange: state.dashboard.revenueBySportRange,
    betCountRange: state.dashboard.betCountRange,
    stakeVsRevenueRange: state.dashboard.stakeVsRevenueRange,
    cashRevenueBySportRange: state.dashboard.cashRevenueBySportRange,
    profitSportsRange: state.dashboard.profitSportsRange,
    turnoverSportsRange: state.dashboard.turnoverSportsRange,
    topWinnersRange: state.dashboard.topWinnersRange,
    topLosersRange: state.dashboard.topLosersRange,

  };
}

export default connect(mapStateToProps)(Dashboard);
