import React, { Component } from "react";
import { connect } from "react-redux";
import { tabManager } from "../sidebar/sidebarSlice";
import { Link } from "react-router-dom";
import {
  fetchTagsData,
  setRecordsShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisibile,
  uploadAssignTags,
} from "./AssignTagSlice";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Pagination from "react-js-pagination";
import { fetchTagDetails } from "../PlayerTagging/PlayerTaggingSlice";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { CSVLink } from "react-csv";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import { COMMON_CONSTANTS, TAG_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { Tagging_Segmentation } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import sampleCsvFile from "../../assets/sample_file.csv";
import Input from "../Common/common_inputs/Input";
class AssignTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagType: "",
      tagName: "",
      tagDescription: "",
      itemsperpage: "",
      document: "",
      tagId: 0,
      file: '',
      fileErr: false,
      tagErr: false,
      freezButton: false,
    };
    this.onChangeItemperpage = this.onChangeItemperpage.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.onChangeTagName = this.onChangeTagName.bind(this);
    this.tabsChange = this.tabsChange.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
  }

  handlePageChange(pageNumber) {
    this.props.dispatch(setPaginationFirstValue(pageNumber));
    this.callApi(pageNumber);
  }

  callApi(pageNumber) {
    const userID = localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : this.props.userId;
    if (userID !== "undefined") {
      this.props.dispatch(setIsVisibile(false));
      this.props.dispatch(
        fetchTagsData(
          this.props.paginationFirstValue - 1,
          this.props.paginationSecondValue,
          userID
        )
      );
      this.recordsShow(pageNumber, this.props.paginationSecondValue);
    }
  }
  recordsShow(pageNumber, itemsPerPage) {
    const itemsperpage = Number(itemsPerPage);
    var recordsFrom = "";
    var recordsTo = "";
    if (pageNumber === 1) {
      if (this.props.tableData.totalRecords < pageNumber * itemsperpage) {
        recordsTo = this.props.tableData.totalRecords - 1;
      }
    }
    if (pageNumber == 0) {
      recordsFrom = 1;
      recordsTo = itemsPerPage;
    } else {
      recordsFrom = itemsPerPage * (pageNumber - 1) + 1;
      recordsFrom = itemsPerPage * (pageNumber - 1) + 1;
      if (this.props.tableData.totalRecords < pageNumber * itemsPerPage) {
        recordsTo = this.props.tableData.totalRecords;
      } else {
        recordsTo = itemsPerPage * pageNumber;
      }
    }
    this.props.dispatch(setRecordsShow([recordsFrom, recordsTo]));
  }

  onChangeItemperpage(e) {
    const value = Number(e.target.value);
    this.setState({ itemsperpage: value });
    this.props.dispatch(setPaginationSecondValue(value));
    this.props.dispatch(setIsVisibile(false));
    this.props.dispatch(
      fetchTagsData(
        this.props.paginationFirstValue - 1,
        value,
        localStorage.getItem("userId")
      )
    );
    this.props.dispatch(
      fetchTagDetails(
        this.props.paginationFirstValue - 1,
        value,
        {},
        this.updateTableToDocs
      )
    );
    if (this.props.tableData.totalRecords > value) {
      this.recordsShow(this.props.paginationFirstValue, value);
    } else {
      this.recordsShow(
        this.props.paginationFirstValue,
        this.props.tableData.totalRecords
      );
    }
  }
  updateTableToDocs() { }

  reloadPage() {
    this.callApi(this.props.paginationFirstValue);
  }

  onChangeTagName(e) {
    const { name } = e.target
    if (name === "tagName") {
      this.setState({ tagErr: false })
      this.setState({ tagName: e.target.value });
      this.setState({ tagId: e.target.value });
    }
  }

  componentDidMount() {
    this.callApi(this.props.paginationFirstValue);
    this.props.dispatch(
      fetchTagDetails(
        this.props.paginationFirstValue - 1,
        this.props.paginationSecondValue,
        {},
        this.updateTableToDocs
      )
    );
  }
  tabsChange(item) {
    this.props.dispatch(setUserId(item));
    this.props.dispatch(tabManager(+item, this.props.tabStack));

    localStorage.setItem("userId", item);
  }
  myDocument(e) {
    this.setState({ document: e.target.value });
  }
  exportPDF = () => {
    const title = "Assign tags report";
    const headers = [["Tag Name", "Customer ID", "Creation Date"]];
    const data =
      this.props.tableData.data &&
      this.props.tableData.data.map((item) => [
        item.tagName,
        item.playerId,
        getDateTimeFormate(item.appliedOn),
      ]);
    exportPDFC(title, headers, data);
  };

  handleUpload(e) {
    const { name } = e.target
    if (name === "uploadTag") {
      this.setState({ fileErr: false })
      this.setState({ file: e.target.files[0] })
    }
  }

  errorCheck() {
    let fileErr = false;
    let tagErr = false;
    if (this.state.file === '') {
      fileErr = true;
    }
    if (this.state.tagName === "") {
      tagErr = true;
    }
    this.setState({ fileErr, tagErr });
    return !(fileErr || tagErr);
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ freezButton: true });
    const noError = this.errorCheck()
    const formData = new FormData();
    formData.append("file", this.state.file);
    formData.append("customTagId", this.state.tagId)
    if (noError) {
      this.props.dispatch(uploadAssignTags(formData))
    }
    setTimeout(() => {
      this.setState({ freezButton: false });
    }, 3000);
  }

  resetForm() {
    this.setState({
      fileErr: false,
      tagErr: false,
      tagName: "",
      tagId: "",
      file: "",
    });
  }

  render() {
    const userId = this.props.userId
      ? this.props.userId
      : localStorage.getItem("userId");
    var pagenum =
      this.props.tableData != undefined
        ? this.props.tableData.totalRecords / this.props.paginationSecondValue
        : 0;
    var pageValue = Math.ceil(pagenum);

    const pageFirstVal =
      this.props.tableData.totalRecords == 0 ? 0 : this.props.recordsShow[0];

    const pageSecondVal =
      this.props.tableData != undefined &&
        this.props.tableData.totalRecords >
        this.props.paginationFirstValue * this.props.paginationSecondValue
        ? this.props.paginationFirstValue * this.props.paginationSecondValue
        : this.props.tableData.totalRecords;
    const firstRecord =
      Object.keys(this.props.tableData).length > 0 &&
        this.props.tableData.data.length > 0
        ? pageFirstVal
        : 0;

    const secondRecord =
      Object.keys(this.props.tableData).length > 0 &&
        this.props.tableData.data.length > 0
        ? pageSecondVal
        : 0;
    const TotalRecords =
      Object.keys(this.props.tableData).length > 0 &&
        this.props.tableData.data.length > 0
        ? this.props.tableData.totalRecords
        : 0;
    const isVisible = this.props.isVisible;
    return (
      <React.Fragment>
        <div className='CMS-tab-panel active' id='CMS-betting'>
          <div className='CMS-tabContent'>
            <form onSubmit={(e) => this.onSubmit(e)}>
              <div className='CMS-box CMS-box-content'>
                {/* <!-- <div class="CMS-box__header">
                                                <div class="CMS-box__title">Create Player Tag</div>

                                            </div> --> */}
                <div className='row '>
                  {/* <div class="col-md-6 col-lg-4 col-xl-2"></div> */}
                  {/* <div class="col-md-6 col-lg-4 col-xl-4">
                              <div class="CMS-formGroup">
                                <div class="CMS-formLabel">Upload Tag</div>

                                <div class="CMS-formControl">
                                  <input
                                    type="file"
                                    id=""
                                    name="uploadTag"
                                    placeholder="Upload Tag"
                                    // value={this.state.tagName}
                                    // onChange={this.changeHandler}
                                  />
                                </div>
                              </div>
                            </div> */}
                  {/* <div className='col-md-6 col-lg-4 col-xl-4'>
                    <div className='CMS-formGroup'> */}
                  <div className='col-lg-4 col-xl-4 ml-20'>
                    <Input
                      title={TAG_CONSTANTS.header_uploadTag}
                      placeholder={"upload file"}
                      name={"uploadTag"}
                      type={"file"}
                      ids={"uploadTag"}
                      show={true}
                      info={Tagging_Segmentation.Upload_Tags}
                      accept=".csv"
                      setValue={(e) => this.handleUpload(e)}
                      errorMsg={this.state.fileErr && "Please choose proper file"}
                    />
                  </div>
                  {/* <div className='CMS-formLabel'>
                        {TAG_CONSTANTS.header_uploadTag}
                        &nbsp;&nbsp;
                        <Tooltips info={Tagging_Segmentation.Upload_Tags} />
                      </div>

                      <div className='CMS-formControl'>
                        <input
                          type='file'
                          id='UploadTag'
                          name='uploadTag'
                          placeholder='Tag Name'
                          accept='.csv'
                          onChange={(e) => this.handleUpload(e)}
                        // value={this.state.tagName}
                        // onChange={this.changeHandler}
                        />
                      </div>
                      <div className='CMS-form-errorMsg'>{this.state.fileErr ? " Please choose proper file " : ""}</div> */}
                  {/* </div>
                  </div> */}
                  <div className='col-md-6 col-lg-4 col-xl-4'>
                    <div className='CMS-formGroup'>
                      <div className='CMS-formLabel'>
                        {TAG_CONSTANTS.header_tagName}
                        &nbsp;&nbsp;
                        <Tooltips info={Tagging_Segmentation.Assign_tagName} />
                      </div>
                      <div className='CMS-dropdown CMS-formControl'>
                        <div className='CMS-select'>
                          <select
                            name='tagName'
                            id='assignTag'
                            value={this.state.tagName}
                            onChange={this.onChangeTagName}
                            className="pointer"
                          // name={this.state.itemId}
                          >
                            <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                            {this.props?.tagData?.data &&
                              Object.keys(this.props.tagData).length > 0 &&
                              this.props?.tagData?.data &&
                              Array.isArray(this.props?.tagData?.data) &&
                              this.props?.tagData?.data?.map((item, index) => {
                                // const tagId = item.id
                                return (
                                  <option
                                    key={index}
                                    value={item.id}
                                    id={item.id}
                                    name={item.id}
                                  // onSelect={() => this.handleOptionChange(item.id) }
                                  >
                                    {item.tagName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className='CMS-form-errorMsg'>{this.state.tagErr ? "Please select proper tag name " : ""}</div>
                        {/* <div className="text-danger">
                          {this.state.controlError &&
                            "Please select tag type"}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-20'></div>

                <div className='row'>
                  <ButtonGroup
                    groupNames={["Upload", "Reset"]}
                    spclButtons={
                      [
                        "Sample File",
                        {
                          fileName: "sample file.csv",
                          filePath: sampleCsvFile,
                        },
                      ]
                    }
                    idsearch={"assigntagUpload"}
                    idreset={"assintagreset"}
                    idspecial={"sampleDownload"}
                    submit={(e) => this.submitForm(e)}
                    reset={(e) => this.resetForm(e)}
                    freezButton={this.state.freezButton}
                  />
                </div>
              </div>
            </form>
            {/* <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <div className='CMS-pagination-container'>
                  {this.props?.tableData?.data != undefined && (
                    <div
                      className='CMS-pagination-list'
                      style={{
                        visibility:
                          [0, 1].includes(pageValue) || TotalRecords == 0
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      <Pagination
                        totalItemsCount={this.props.tableData.totalRecords}
                        itemsCountPerPage={this.props.paginationSecondValue}
                        pageRangeDisplayed={2}
                        itemClass='active'
                        activePage={this.props.paginationFirstValue}
                        onChange={this.handlePageChange}
                        activeLinkClass='active'
                        // hideFirstLastPages={true}
                        firstPageText={
                          <span
                            className='material-icons'
                            data-icon='first_page'
                          ></span>
                        }
                        prevPageText={
                          <span
                            className='material-icons'
                            data-icon='navigate_before'
                          ></span>
                        }
                        nextPageText={
                          <span
                            className='material-icons'
                            data-icon='navigate_next'
                          ></span>
                        }
                        lastPageText={
                          <span
                            className='material-icons'
                            data-icon='last_page'
                          ></span>
                        }
                      // hideDisabled={true}
                      />
                    </div>
                  )}

                  <div className='CMS-page-slection invisible'>
                    <div className='CMS-number-of-files CMS-select'>
                      <select
                        id='country'
                        name='File'
                        value={this.state.itemsperpage}
                        onChange={this.onChangeItemperpage}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                      </select>
                    </div>
                    <div className='CMS-file-type CMS-select'>
                      <select
                        id='country'
                        name='File'
                        value={this.state.document}
                        onChange={this.myDocument}
                      >
                        <option value='PDF'>PDF</option>
                        <option value='CSV'>CSV</option>
                      </select>
                    </div>
                    <div className='CMS-download-icon pointer'>
                      {this.state.document === "CSV" ? (
                        <CSVLink
                          style={{ textDecoration: "none" }}
                          data={this.props.tagData.data.map((p) => ({
                            "Tag Name": p.tagName,
                            CustomerId: p.playerId,
                            "Creation Date": getDateTimeFormate(p.appliedOn),
                          }))}
                          filename={"create Tag.csv"}
                          target='_blank'
                        >
                          <a>
                            <span
                              className='material-icons'
                              data-icon='file_download'
                            ></span>
                          </a>
                        </CSVLink>
                      ) : (
                        <a onClick={() => this.exportPDF()}>
                          <span
                            className='material-icons'
                            data-icon='file_download'
                          ></span>
                        </a>
                      )}
                    </div>
                  </div>

                  <div className='CMS-page-results'>
                    Results {isVisible && firstRecord}-
                    {isVisible && secondRecord} of
                    {isVisible && TotalRecords}
                    <div className='refresh-page'>
                      <span
                        onClick={this.reloadPage}
                        className='material-icons md-36 pointer'
                        data-icon='replay_circle_filled'
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='CMS-box CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th _msthash='1745536' _msttexthash='84955'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{TAG_CONSTANTS.header_tagName}</p>
                          <Tooltips info={"Showing Tag Name"} />
                        </div>
                      </th>
                      <th _msthash='1745666' _msttexthash='135707'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{COMMON_CONSTANTS.header_CID}</p>
                          <Tooltips info={"Showing Customer Id"} />
                        </div>
                      </th>
                      <th _msthash='1745796' _msttexthash='127452'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{COMMON_CONSTANTS.creationDate}</p>
                          <Tooltips info={"Showing Creation Date"} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.props.tableData).length > 0 &&
                      this.props.tableData.data.length > 0 ? (
                      this.props.tableData.data.map((item, index) => {
                        return (
                          <tr key={item}>
                            <td _msthash='1752998' _msttexthash='68991'>
                              {item.tagName}
                            </td>
                            <td _msthash='1753128' _msttexthash='117338'>
                              <Link
                                to={`/customerid/${item.playerId}`}
                                onClick={() => this.tabsChange(item.playerId)}
                              >
                                {item.playerId}
                              </Link>
                            </td>
                            <td _msthash='1753258' _msttexthash='183508'>
                              {getDateTimeFormate(item.appliedOn)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={3} />
                    )}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userId: state.playersearch.userId,
    tableData: state.assignTagSlice.tableData,
    paginationFirstValue: state.assignTagSlice.paginationFirstValue,
    paginationSecondValue: state.assignTagSlice.paginationSecondValue,
    recordsShow: state.assignTagSlice.recordsShow,
    tagData: state.playertagging.tagData,
    isVisible: state.assignTagSlice.isVisible,
    tabStack: state.sidebar.tabStack
  };
}
export default connect(mapStateToProps)(AssignTag);
