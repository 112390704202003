import React, { useState, useEffect } from 'react'
import { RefDateInput, playerReferralData } from '../Common/data/mapData'
import Input from '../Common/common_inputs/Input'
import Datepicker from '../Common/common_inputs/DatePicker'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import { MomentUTCDateTime, MomentUTCDateTimeEnd, MomentUTCDateTimeToday, cleanObject, formatMoney, getFormattedDate, getUTCDate } from '../../sharedfiles/helper'
import { useDispatch, useSelector } from 'react-redux'
import {
    getReferralData,
    setIsVisible,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setPrevFormData,
    setDocsData,
    setPdfData,

} from './PlayerRefSlice'
import Table from '../Common/table/Table'
import Tooltips from '../Common/Tooltip'
import No__Data__Found from '../Common/table/No__Data__Found'
import { Link } from "react-router-dom";
import Vpagination from '../../sharedfiles/Vpagination'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Skelton from '../Common/skelton/Skelton'

const PlayerReferrals = () => {
    const dispatch = useDispatch()
    const [dateError, setDateError] = useState('')
    const [csvData, setCsvData] = useState([])
    const [pdfBody, setPdfBody] = useState([])
    const listData = useSelector((p) => p.PlayerRefSlice.referralData)
    const paginationFirstValue = useSelector((p) => p.PlayerRefSlice.paginationFirstValue)
    const paginationSecondValue = useSelector((p) => p.PlayerRefSlice.paginationSecondValue)
    const recordsToShow = useSelector((p) => p.PlayerRefSlice.recordsShow)
    const isVisible = useSelector((p) => p.PlayerRefSlice.isVisible)
    const prevFormData = useSelector((p) => p.PlayerRefSlice.prevFormData)
    const docsData = useSelector((p) => p.PlayerRefSlice.docsData)
    const pdfData = useSelector((p) => p.PlayerRefSlice.pdfData)

    const [formData, setFormData] = useState({
        cid: "",
        refCode: "",
        FromDate: "",
        ToDate: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        formDataToSliceSetter(name, value)
        const updatedFormData = { ...prevFormData, [name]: value }
        dispatch(setPrevFormData(updatedFormData))
    }

    const setDateHandler = (value, name) => {
        formDataToSliceSetter(name, value);
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        if (name === "FromDate" && value !== '') {
            setDateError('')
        }
    };

    const getDefaultDateRange = () => {
        const startDate = getFormattedDate(7);
        const endDate = new Date();
        return { startDate, endDate };
    };

    function validator() {
        let err = false;

        if (formData.ToDate !== "" && (formData.FromDate === '' || formData.FromDate === null)) {
            setDateError("Start Date is required");
            err = true;
        }
        if (formData.ToDate === null && formData.FromDate === null) {
            setDateError("");
            err = false;
        }

        return err;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const isError = validator();
        if (isError) return;
        const data = {
            customerId: formData.cid,
            endDate: formData.ToDate ? MomentUTCDateTimeEnd(formData.ToDate) : "",
            referralCode: formData.refCode,
            startDate: formData.FromDate ? MomentUTCDateTimeToday(formData.FromDate) : ""
        }
        dispatch(getReferralData(0, 25, data))
        dispatch(setRecordsShow([1, 25]))
        dispatch(setIsVisible(false))
    }

    const tHeader =
        [
            [
                "User ID",
                "Number of Referrals",
                "Users Registered",
                "Number of Conversions",
                "Referral Code",
                "Total Deposits",
                "Number of Bets",
                "Total Stakes",
                "Total Payouts",
                "Total Liability"
            ]
        ]

    const header = [
        "User ID",
        "Number of Referrals",
        "Users Registered",
        "Number of Conversions",
        "Referral Code",
        "Total Deposits",
        "Number of Bets",
        "Total Stakes",
        "Total Payouts",
        "Total Liability"
    ]

    const info = [
        "Showing User ID",
        "Showing Number of Referrals",
        "Showing Users Registered",
        "Showing Number of Conversions",
        "Showing Referral Code",
        "Showing Total Deposits",
        "Showing Number of Bets",
        "Showing Total Stakes",
        "Showing Total Payouts",
        "Showing Total Liability"
    ]

    const update = () => {
        const tbody = listData &&
            Array.isArray(listData.data) ?
            listData.data.map((item) =>
                [
                    item.referrerId,
                    item.numberOfRegistrations,
                    item.users,
                    item.numberOfConversions,
                    item.userRefCode,
                    formatMoney(item.totalDeposits),
                    item.numberOfBets,
                    formatMoney(item.totalStakes),
                    formatMoney(item.totalPayouts),
                    formatMoney(item.potentialPayouts),

                ]) : []
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tHeader[0], ...tbody] : [tHeader[0], []];
        setCsvData(data);
        dispatch(setDocsData(data));
        setPdfBody(tbody);
        dispatch(setPdfData(tbody));

    }
    const tableStyles = {
        0: "text-center",
    };

    // useEffect(() => {
    //     dispatch(setIsVisible(true));
    //     dispatch(getReferralData(paginationFirstValue - 1, paginationSecondValue, {}))
    // }, [])

    useEffect(() => {
        update();
    }, [listData])

    const exportPDF = () => {
        const title = "Referral List Report";
        const styles = {
            4: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, tHeader, pdfBody, styles);
    }

    const callPageApi = (pageNumber, itemsPerPage) => {
        const data = {
            customerId: formData.cid,
            endDate: formData.ToDate ? MomentUTCDateTime(formData.ToDate) : "",
            referralCode: formData.refCode,
            startDate: formData.FromDate ? MomentUTCDateTime(formData.FromDate) : ""
        }
        dispatch(setIsVisible(false));
        dispatch(getReferralData(pageNumber - 1, itemsPerPage, data))
    }

    useEffect(() => {
        const { startDate, endDate } = getDefaultDateRange();

        const systemPayload = {
            startDate: MomentUTCDateTimeToday(startDate),
            endDate: MomentUTCDateTimeEnd(endDate)
        }

        const dataExist = Object.keys(listData).length > 0
        if (!dataExist) {
            dispatch(setIsVisible(false));
            dispatch(setPrevFormData({
                ...prevFormData,
                FromDate: startDate,
                ToDate: endDate,
            }))
            setFormData({
                FromDate: startDate,
                ToDate: endDate,
            })
            dispatch(
                getReferralData(paginationFirstValue - 1, paginationSecondValue, systemPayload)
            )
        } else {
            setPdfBody(pdfData);
            setCsvData(docsData);
            setFormData({
                cid: "",
                refCode: "",
                FromDate: "",
                ToDate: "",
            })
        }
        stateSetter();

    }, [])


    const stateSetter = () => {
        const isFormDataExist = Object.keys(prevFormData).length
        if (isFormDataExist) {
            Object.entries(prevFormData).map(([key, value]) => setFormData((prevState) => {
                return {
                    ...prevState,
                    [key]: value,
                }
            }))
        }
    }

    const formDataToSliceSetter = (key, val) => {
        const newEntry = { ...prevFormData, [key]: val }
        dispatch(setPrevFormData(newEntry));
    }
    const resetButton = () => {
        setFormData({
            cid: "",
            refCode: "",
            FromDate: "",
            ToDate: ""
        })
        setDateError('')
        dispatch(setPrevFormData({}));
        dispatch(getReferralData(0, 25, {}))
        dispatch(setPaginationFirstValue(1))
        dispatch(setPaginationSecondValue(25))
        dispatch(setRecordsShow([1, 25]))
        dispatch(setIsVisible(false))
    };
    return (
        <div className='CMS-tab-panel active' id='CMS-betting'>
            <div className='CMS-tabContent'>
                <div className='CMS-box CMS-box-content'>
                    <div className='CMS-box__header'>
                        <div
                            className='CMS-box__title'
                        >
                            REFERRALS
                        </div>
                    </div>
                    <div className='mt-20'></div>
                    <div className='row'>
                        {
                            playerReferralData.map((item, ind) => {
                                return (
                                    <>
                                        <div className='col-3 custom'>
                                            <Input
                                                title={item.title}
                                                name={item.name}
                                                value={formData[item.name]}
                                                placeholder={item.placeholder}
                                                setValue={(e) => handleChange(e)}
                                                regex={item.regex}
                                                maxLen={item.maxLength}
                                                ids={item.ids}
                                                info={item.info}
                                                show={true}
                                            />
                                        </div>

                                    </>
                                )
                            })
                        }

                        {RefDateInput.map((item, ind) => {
                            const minDate = [null, formData.FromDate];
                            const max = formData.ToDate
                                ? formData.ToDate
                                : new Date();
                            const maxDate = [max, new Date()];
                            const values = [formData.FromDate, formData.ToDate]
                            return (
                                <React.Fragment key={item.id}>
                                    <div className='col-3 custom' >
                                        <Datepicker
                                            title={item.title}
                                            name={item.name}
                                            value={formData[item.name]}
                                            setValue={(date, value) =>
                                                setDateHandler(date, value)
                                            }
                                            errorMsg={ind === 0 ? dateError : ""}
                                            minDate={minDate[ind]}
                                            maxDate={maxDate[ind]}
                                            ids={item.ids}
                                            info={item.info}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className='mt-20'></div>

                    <div className='row'>
                        <ButtonGroup
                            groupNames={["Search", "Reset"]}
                            idsearch={"PlayerReferral"}
                            idreset={"PlayerReferralsReset"}
                            submit={(e) => onSubmit(e)}
                            reset={(e) => resetButton(e)}
                        />
                        {/* <div className='CMS-btnContainer col-12'>
                            <button
                                onClick={(e) => onSubmit(e)}
                                className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                                id={"refSearch"}
                            >
                                Search
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className='CMS-filter-result' id='result'>
                <div className="CMS-pagination">
                    <Vpagination
                        data={listData.data}
                        totalRecords={listData.totalRecords}
                        paginationFirstValue={paginationFirstValue}
                        paginationSecondValue={paginationSecondValue}
                        setPaginationFirstValue={setPaginationFirstValue}
                        setPaginationSecondValue={setPaginationSecondValue}
                        recordsShow={recordsToShow}
                        setRecordsShow={setRecordsShow}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        csvData={csvData}
                        exportPDF={exportPDF}
                        callApi={callPageApi}
                        csvFileName={"Referral List Report"}
                        reloadRequired={false}
                    />
                </div>
            </div>
            <div className='CMS-box CMS-box-content'>
                <div className='CMS-box__header'>
                    <div
                        className='CMS-box__title'
                        style={{ textAlign: "center" }}
                    >
                        REFERRALS LIST
                    </div>
                </div>
                {/* <Table tableData={tableData} styles={tableStyles} linkIndex={0} /> */}
                {!isVisible ? (<Skelton vLen={5} hLen={7} />) : (
                    <div className='CMS-box'>
                        <div className='CMS-table CMS-table-triped'>
                            <table>
                                <thead>
                                    <tr>
                                        {header.map((item, index) => (
                                            <th>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <p className='mx-3'>{item}</p>
                                                    <Tooltips info={`${info[index]}`} />
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData && listData?.data && listData.data.length > 0
                                        ? (
                                            listData.data.map((item, index) => {
                                                const userNumbers = item.users.split(',').map(number => number.trim());
                                                return (
                                                    <tr>
                                                        <td>
                                                            {" "}
                                                            <Link
                                                                to={`/customerid/${item.referrerId}`}
                                                                style={{ color: "#2d91fc" }}
                                                            >
                                                                {item.referrerId}
                                                            </Link>
                                                        </td>
                                                        <td>{item.numberOfRegistrations}</td>
                                                        <td> {userNumbers.map((number, index) => (
                                                            <React.Fragment key={index}>
                                                                {index > 0 && ", "}
                                                                <Link
                                                                    to={`/customerid/${number}`}
                                                                    style={{ color: "#2d91fc" }}
                                                                >
                                                                    {number}
                                                                </Link>
                                                            </React.Fragment>
                                                        ))}
                                                        </td>
                                                        <td>{item.numberOfConversions}</td>
                                                        <td>{item.userRefCode}</td>
                                                        <td>{formatMoney(item.totalDeposits)}</td>
                                                        <td>{item.numberOfBets}</td>
                                                        <td>{formatMoney(item.totalStakes)}</td>
                                                        <td>{formatMoney(item.totalPayouts)}</td>
                                                        <td>{formatMoney(item.potentialPayouts)}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <No__Data__Found colspan={50} />
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>)}

            </div>
        </div>
    )
}

export default PlayerReferrals