import React from 'react'
import Tooltips from '../../Common/Tooltip'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChart = ({ title, chartData }) => {
    const sportsData = chartData.map(obj => obj?.sportsData)
    const casinoData = chartData.map(obj => obj?.casinoData)
    const neGameData = chartData.map(obj => obj?.neGamesData)
    const overallData = chartData.map(obj => obj?.overallData)

    const sportPercent = chartData.map(obj => obj?.sportsDataPercentage)
    const extracted_sportPercent = sportPercent[0]

    const casinoPercent = chartData.map(obj => obj?.casinoDataPercentage)
    const extracted_casinoPercent = casinoPercent[0]

    const neGamePercent = chartData.map(obj => obj?.neGamesDataPercentage)
    const extracted_neGamePercent = neGamePercent[0]

    const chartOptions = {
        chart: {
            type: 'pie',
            height: 280
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        tooltip: {
            valueSuffix: ''
        },
        subtitle: {
            text: ''
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,

            },

            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: false,
                    distance: 0
                }, {
                    enabled: false,
                    distance: 0,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                },
                ]
            }
        },
        series: [
            {
                name: 'Percentage',
                colorByPoint: true,
                data: [
                    {
                        name: 'Sports',
                        y: extracted_sportPercent,
                        sliced: false,
                        color: '#2fc56b'
                    },
                    {
                        name: 'Casino',
                        sliced: true,
                        selected: true,
                        y: extracted_casinoPercent,
                        sliced: false,
                        color: '#f44e3f'
                    },
                    {
                        name: 'NE Games',
                        y: extracted_neGamePercent,
                        sliced: false,
                        color: '#a06cd5'
                    },

                ]
            }
        ]
    }
    return (
        <>
            <div className="CMS-box-graphItem">
                <div className="CMS-box-graphTitle">{title}</div>
                <Tooltips info={"info"} />
                <div className="CMS-graph CMS-highCharts-activeUsers">
                    {/* <div id="CMS-highCharts-activeUsers" className="CMS-highCharts-activeUsers"></div> */}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps={{ id: 'CMS-highCharts-registration' }}
                    />
                    <div className="CMS-active-users">
                        <ul>
                            <li>
                                <div className="CMS-active-userCountList">
                                    <div className="CMS-active-userCountBorder"></div>
                                    <div className="CMS-active-userCountTitle">Sports</div>
                                    <div className="CMS-active-userCount">{sportsData}</div>
                                </div>
                            </li>
                            <li>
                                <div className="CMS-active-userCountList">
                                    <div className="CMS-active-userCountBorder CMS-casinoCountBorder"></div>
                                    <div className="CMS-active-userCountTitle">Casino</div>
                                    <div className="CMS-active-userCount">{casinoData}</div>
                                </div>
                            </li>
                            <li>
                                <div className="CMS-active-userCountList">
                                    <div className="CMS-active-userCountBorder CMS-negamesCountBorder"></div>
                                    <div className="CMS-active-userCountTitle">NE Games</div>
                                    <div className="CMS-active-userCount">{neGameData}</div>
                                </div>
                            </li>
                            <li>
                                <div className="CMS-active-userCountList">
                                    <div className="CMS-active-userCountBorder CMS-userCount-TotalBorder"></div>
                                    <div className="CMS-active-userCountTitle">Overall</div>
                                    <div className="CMS-active-userCount">{overallData}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PieChart