import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import { useDispatch, connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { downlaodCsvFile } from "./docsDowloader";
import { Currency } from "./helper";
import Tooltips from "../components/Common/Tooltip";

const Vpagination = (props) => {
  const { hideDocs, enableAll, reportsLoader, allRecordsData, searchIniMount, searchHandler = () => { }, onclickSearch = () => { }, disableButton, isSearchClicked = false, handlerSyndicate = () => { }, focusAction = () => { }, showSubAffiliate, isChecked, onCheckboxChange } = props;
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [documentType, setDocumentType] = useState("PDF");
  const [csvData, setCsvData] = useState([]);
  const [processRegulator, setProcessRegulator] = useState(true);
  const [masking, setMasking] = useState(false)
  const [searchData, setSearchData] = useState("");
  const location = useLocation();
  // const reportsLoader = useSelector(st => st.paymentSearchSlice.reportsLoader);
  // const reportDocs = useSelector(st => st.paymentSearchSlice.reportDocs);
  const handlePageChange = (pageNumber) => {
    dispatch(props.setPaginationFirstValue(pageNumber));
    props.callApi(
      pageNumber,
      props.paginationSecondValue,
      props.payloadData && props.payloadData
    );
    recordsShow(pageNumber, props.paginationSecondValue);
    props.resetCsvData && props.resetCsvData();
  };

  const recordsShow = (pageNumber, itemsPerPage) => {
    const itemsperpage = Number(itemsPerPage);
    var recordsFrom = "";
    var recordsTo = "";
    if (pageNumber === 1) {
      if (props.totalRecords < pageNumber * itemsperpage) {
        recordsTo = props.totalRecords - 1;
      }
    }
    if (pageNumber == 0) {
      recordsFrom = 1;
      recordsTo = itemsPerPage;
    } else {
      recordsFrom = itemsPerPage * (pageNumber - 1) + 1;
      recordsFrom = itemsPerPage * (pageNumber - 1) + 1;
      if (props.totalRecords < pageNumber * itemsPerPage) {
        recordsTo = props.totalRecords;
      } else {
        recordsTo = itemsPerPage * pageNumber;
      }
    }
    dispatch(props.setRecordsShow([recordsFrom, recordsTo]));
  };

  const onChangeItemperpage = (e) => {
    const value = Number(e.target.value);
    setItemsPerPage(value);
    dispatch(props.setPaginationSecondValue(value));
    dispatch(props.setIsVisible(false));
    if (props.totalRecords < props.paginationFirstValue * value) {
      dispatch(props.setPaginationFirstValue(1));
      props.callApi(1, value, props.payloadData);
      recordsShow(1, value);
    } else {
      props.callApi(props.paginationFirstValue, value, props.payloadData);
      recordsShow(props.paginationFirstValue, value);
    }
    props.resetCsvData && props.resetCsvData();
  };

  const reloadPage = () => {
    props.callApi(props.paginationFirstValue, props.paginationSecondValue);
    recordsShow(props.paginationFirstValue, props.paginationSecondValue);
  };


  var pagenum =
    props.totalRecords != undefined
      ? props.totalRecords / props.paginationSecondValue
      : 0;
  var pageValue = Math.ceil(pagenum);
  const pageFirstVal =
    props.totalRecords == 0 &&
      props.data &&
      Object.keys(props.data).length > 0 &&
      props.data.length > 0
      ? 0
      : props.recordsShow?.length > 0 ? props.recordsShow[0] : {};

  const pageSecondVal =
    props.data != undefined &&
      props.data &&
      Object.keys(props.data).length > 0 &&
      props.data.length > 0 &&
      props.totalRecords >
      props.paginationFirstValue * props.paginationSecondValue
      ? props.paginationFirstValue * props.paginationSecondValue
      : props.totalRecords;
  const firstRecord =
    props.data && Object.keys(props.data).length > 0 && props.data.length > 0
      ? pageFirstVal
      : 0;

  const secondRecord =
    props.data && Object.keys(props.data).length > 0 && props.data.length > 0
      ? pageSecondVal
      : 0;
  const TotalRecords =
    props.data && Object.keys(props.data).length > 0 && props.data.length > 0
      ? props.totalRecords
      : 0;
  const isVisible = props.isVisible;

  const pageResultsStyle = {
    // minWidth: "0px",
    textAlign: "right",
  };
  useEffect(() => {
    setCsvData(props.csvData);
    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      setMasking(true)
    } else {
      setMasking(false)
    }
  }, [props.csvData]);

  const handleSearch = (value) => {
    setSearchData(value);
    if (!isSearchClicked) {
      props.onDataSearch(value);
    }
  }

  const onFocus = () => {
    focusAction()
  }

  const docsDownloadHandler = (allRecords) => {
    if (documentType === "PDF") {
      props.exportPDF(allRecords);
    } else {
      const headers = props.csvData[0];
      const bodyData = allRecords ? allRecordsData.csv : props.csvData;
      const body = bodyData.slice(1, bodyData.length);
      const fileName = `${props.csvFileName}.csv`;

      downlaodCsvFile(headers, body, fileName);
    }
  }

  const downloadAllDocsHandler = () => {
    const dataExist = allRecordsData && Array.isArray(allRecordsData.csv) && allRecordsData.csv.length > 0
    if (!dataExist) {
      dispatch(reportsLoader.setLoader(true));
      props.callApi(
        1,
        TotalRecords,
        props.payloadData || {},
        true
      );
    } else {
      docsDownloadHandler(true);
    }
  }

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      let dataExist = allRecordsData && Array.isArray(allRecordsData.csv) && allRecordsData.csv.length > 0
      if (dataExist) {
        docsDownloadHandler(true);
      }
    }

    return () => mounting = false;
  }, [allRecordsData]);

  // useEffect(() => {
  //   let mounting = true;

  //   if (mounting) {
  //     dispatch(props.setPaginationFirstValue(1))
  //     dispatch(props.setPaginationSecondValue(25));
  //     setDocumentType("PDF");
  //   }
  //   return () => mounting = false;

  // }, [location.pathname])


  function handleClickSearch() {
    onclickSearch(searchData)
  }

  return (
    <>
      <div className='CMS-pagination-container'>
        {props.data != undefined ? (
          <div
            className='CMS-pagination-list'
            style={{
              visibility:
                [0, 1].includes(pageValue) || TotalRecords == 0
                  ? "hidden"
                  : "visible",
            }}
          >
            <Pagination
              totalItemsCount={TotalRecords}
              itemsCountPerPage={props.paginationSecondValue}
              pageRangeDisplayed={2}
              itemClass='active'
              activePage={props.paginationFirstValue}
              onChange={handlePageChange}
              activeLinkClass='active'
              // hideFirstLastPages={true}
              firstPageText={
                <span
                  className={
                    props.paginationFirstValue == 1
                      ? "material-icons disable-button"
                      : "material-icons"
                  }
                  id='firstPage'
                  data-icon='first_page'
                ></span>
              }
              prevPageText={
                <span
                  className={
                    props.paginationFirstValue == 1
                      ? "material-icons disable-button"
                      : "material-icons"
                  }
                  id='prevPage'
                  data-icon='navigate_before'
                ></span>
              }
              nextPageText={
                <span
                  className={
                    props.paginationFirstValue == pageValue
                      ? "material-icons disable-button"
                      : "material-icons"
                  }
                  id='nextPage'
                  data-icon='navigate_next'
                ></span>
              }
              lastPageText={
                <span
                  className={
                    props.paginationFirstValue == pageValue
                      ? "material-icons disable-button"
                      : "material-icons"
                  }
                  id='lastPage'
                  data-icon='last_page'
                ></span>
              }
            // hideDisabled={true}
            />
          </div>
        ) : (
          <div className='CMS-pagination-list' style={{ width: "250px" }}></div>
        )}
        {
          props.totalAmount && props.showAmount &&
          <div className='d-flex'>
            <b className="ml-80">
              {`Total Amount (${Currency()})`}
            </b>
            &nbsp;
            <p><Tooltips info={props.info} /></p>
            &nbsp;
            <b>:{props.sumAmount}</b>
          </div>
        }
        {hideDocs ? null : TotalRecords !== 0 &&
          <div className='CMS-page-slection'>
            <div className='CMS-number-of-files CMS-select'>
              <select
                id='selectItems'
                name='itemsPerPage'
                value={props.paginationSecondValue}
                onChange={onChangeItemperpage}
                className="pointer"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
              </select>
            </div>
            {hideDocs ? null : (
              <>
                <div className={`CMS-file-type CMS-select ${masking ? "disable-button disabled_events" : ""}`}>
                  <select
                    name='File'
                    id='selectFile'
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    className="pointer"
                  >
                    <option value='PDF'>PDF</option>
                    <option value='CSV'>CSV</option>
                  </select>
                </div>
                {/* <div className={`CMS-download-icon pointer ${masking ? "disable-button disabled_events" : ""}`}>
                  <a onClick={() => docsDownloadHandler(false)}>
                    <span
                      className='material-icons'
                      data-icon='file_download'
                    ></span>
                  </a>
                </div> */}
                <div className={`CMS-download-icon pointer ${masking ? "disable-button disabled_events" : ""}`}>
                  {documentType === "CSV" ? (
                    <CSVLink
                      style={{ textDecoration: "none" }}
                      // data={props?.csvData.length > 0 ? props.csvData : []}
                      data={props.csvData}
                      filename={`${props.csvFileName}.csv`}
                      target='_blank'
                    >
                      <span
                        className='material-icons'
                        data-icon='file_download'
                      ></span>
                    </CSVLink>
                  ) : (
                    <a onClick={() => props.exportPDF()}>
                      <span
                        className='material-icons'
                        data-icon='file_download'
                      ></span>
                    </a>
                  )}
                </div>
                {
                  enableAll &&
                  <div className={`CMS-btnContainer download_btn_all_wrapper ${masking ? "disable-button disabled_events" : ""}`}>
                    <button
                      className='CMS-btn CMS-btnQuaternary active CMS-btnMedium download_btn_all'
                    >
                      <a onClick={() => downloadAllDocsHandler()}>
                        {reportsLoader.loader ? "Downloading..." : "Download All"}
                      </a>
                    </button>
                  </div>
                }
              </>
            )}
          </div>}
        <div className='CMS-checkbox d-flex justify-content-center align-items-center mx_center'>
          {showSubAffiliate && (
            <>
              <input
                onChange={onCheckboxChange}
                checked={isChecked}
                style={{ zoom: 1.5 }}
                id='checkboxAff'
                type='checkbox'
              />
              <label className="ml-10" htmlFor='PE'>
                <span style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                  <p>{"Include Sub Affiliate"}</p>
                </span>
              </label>
            </>
          )}
        </div>

        {props.isSearch && <div className="d-flex" style={{ marginLeft: "auto" }}>
          <input
            type="text"
            placeholder={props.placeholder}
            id="myInput"
            name="searchData"
            value={searchData}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={() => onFocus()}
          />
          <span
            className={`material-icons search search-icon-AFF pointer ${disableButton && searchIniMount ? 'disable-button' : ''}`}
            onClick={handleClickSearch}
          >search</span>
        </div>}
        <div className='CMS-page-results ' style={{ minWidth: "120px" }}>
          <span
            className=''
            style={{
              ...pageResultsStyle,
              marginLeft: "0px",
            }}
          >
            Results :{" "}
          </span>
          <span className='' style={pageResultsStyle}>
            {isVisible && `${firstRecord} - ${secondRecord}`}
          </span>
          <span className='mx-2' style={{ margin: "0 5px" }}>
            of
          </span>
          <span className='' style={pageResultsStyle}>
            {isVisible && TotalRecords}
          </span>
          {/* Results {isVisible && firstRecord}-{isVisible && secondRecord} of
          {isVisible && TotalRecords} */}
          {props.reloadRequired && (
            <div className='refresh-page'>
              <span
                onClick={reloadPage}
                className='material-icons md-36 pointer'
                data-icon='replay_circle_filled'
              ></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    resData: state.affiliateSlice.resData,

  }
}
export default connect(mapStateToProps)(Vpagination);
