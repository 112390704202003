import React,{useState} from 'react'

const Timestampmsg = () => {
     var today = new Date(),
     date = today.getDate()  + '.' + (today.getMonth() + 1) + '.' + today.getFullYear()+ '.' + today.getHours() + '.' + today.getMinutes() ;
     const [currentTime, setCurrentTime] = useState(date)
     return (
    <div className='text-center'>The Generated report is based on the latest conversion rate set on {currentTime}</div>
  )
}

export default Timestampmsg
