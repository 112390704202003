import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { tabManager } from "../sidebar/sidebarSlice";
import { Link } from "react-router-dom";
import {
  fetchPaymentTickerTableData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
} from "./paymentTickerSlice";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
} from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import Loader from "../loading/Loader";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
import { betTickerInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
const PaymentTicker = (props) => {
  const [data, setData] = useState([]);
  const [arrow, setArrow] = useState(true);
  const [from, setFrom] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const dispatch = useDispatch();

  const pageFirstVal = useSelector(
    (state) => state.paymentticker.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.paymentticker.paginationSecondValue
  );
  const recordsToShow = useSelector(
    (state) => state.paymentticker.recordsToShow
  );
  const listPaymentTicker = useSelector(
    (state) => state.paymentticker.paymentTickerTableData
  );

  const callApi = () => {
    dispatch(
      fetchPaymentTickerTableData(
        props.paginationFirstValue - 1,
        props.paginationSecondValue,
        from,
        props.paymentTickerTableData
      )
    );
  };


  useEffect(() => {
    if (props.paymentTickerTableData.data !== undefined) {
      setFrom(props?.paymentTickerTableData?.data[0].currentTime);
      setData(props?.paymentTickerTableData?.data[0].paymentTicker);
    }
  }, [props.paymentTickerTableData]);

  useEffect(() => {
    callApi();
  }, []);

  const headers = [
    [
      "Customer ID",
      "Transaction ID",
      `Amount ${Currency()}`,
      "Status",
      "Payment Description",
      "Payment Method",
      "Transaction Ref",
      "Transaction Type",
      "Transaction Date",
    ],
  ];

  const exportPDF = () => {
    const title = "Payment Ticker Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (props.paymentTickerTableData && props.paymentTickerTableData?.data !== undefined) {
      const paymentData = props.paymentTickerTableData &&
        props.paymentTickerTableData?.data !== undefined &&
        Array.isArray(props.paymentTickerTableData?.data) &&
        props.paymentTickerTableData?.data[0].paymentTicker.map((p) => [
          p.userId,
          p.id,
          formatMoney(p.amount),
          p.status,
          p.paymentDesc,
          p.modePaymentClient,
          p.transactionRef,
          p.paymentType,
          p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
        ]);

      setPdfBody(paymentData);
      setCsvData([headers[0], ...paymentData]);
    }
  }, [props.paymentTickerTableData]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));

    dispatch(
      fetchPaymentTickerTableData(
        pageNumber - 1,
        itemsPerPage,
        from,
        props.paymentTickerTableData
      )
    );
  };
  const tabsChange = (item) => {
    dispatch(setUserId(item));
    dispatch(tabManager(+item, props.tabStack));
    localStorage.setItem("userId", item);
  };
  useEffect(() => {
    const userId = props.userId ? props.userId : localStorage.getItem("userId");
  }, [props.userId]);

  return (
    <React.Fragment>
      <div className='CMS-tabs-panel'>
        <div className='CMS-tabs-content'>
          <div className='CMS-tabContent'>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination '>
                <div className='CMS-pagination-container d-flex justify-content-end'>
                  <div className='refresh-page' style={{ cursor: "pointer" }}>
                    <span
                      // style={reloadStyle}
                      onClick={() => callApi()}
                      className='material-icons md-36'
                      data-icon='replay_circle_filled'
                    ></span>
                  </div>
                </div>
              </div>
              <div className='CMS-filter-result' id='result'>
                <div className='CMS-pagination'>
                  <Vpagination
                    data={data}
                    totalRecords={listPaymentTicker?.totalRecords}
                    paginationFirstValue={pageFirstVal}
                    paginationSecondValue={pageSecondVal}
                    setPaginationFirstValue={setPaginationFirstValue}
                    setPaginationSecondValue={setPaginationSecondValue}
                    recordsShow={recordsToShow}
                    setRecordsShow={setRecordsShow}
                    isVisible={props.isVisible}
                    setIsVisible={setIsVisible}
                    csvData={csvData}
                    exportPDF={exportPDF}
                    callApi={callPageApi}
                    csvFileName={"Payment Ticker Report"}
                  />
                </div>
              </div>
              {
                <div className='CMS-box '>
                  <div className='CMS-table CMS-table-triped'>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.header_CID}</p>
                              <Tooltips info={betTickerInfo.cid} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionId}</p>
                              <Tooltips info={betTickerInfo.TID} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{COMMON_CONSTANTS.th_amount}</p>
                              <Tooltips info={betTickerInfo.Amount} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{KYC_CONSTANTS.header_status}</p>
                              <Tooltips info={betTickerInfo.Status} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentDesc}</p>
                              <Tooltips info={betTickerInfo.PaymentDesc} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentMethod}</p>
                              <Tooltips info={betTickerInfo.PaymentMethod} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionRef}</p>
                              <Tooltips info={betTickerInfo.TransRef} />
                            </div>
                          </th>

                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionType}</p>
                              <Tooltips info={betTickerInfo.TransType} />
                            </div>
                          </th>

                          <th onClick={() => setArrow(!arrow)}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionDate}</p>
                              <Tooltips info={betTickerInfo.TransDatee} />
                              <span
                                className='material-icons md-18'
                                data-icon='south'
                                style={
                                  arrow ? {} : { transform: "rotate(180deg)" }
                                }
                              ></span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!props.loader && data && Array.isArray(data) && data.length > 0 ? (
                          data.map((item, index) => {
                            return (
                              <tr key={`${index}_${item.userId}`}>
                                <td>
                                  <Link
                                    to={`/customerid/${item.userId}`}
                                    onClick={() => tabsChange(item.userId)}
                                  >
                                    {item.userId}
                                  </Link>
                                </td>

                                <td>
                                  <Link
                                    to={{
                                      pathname: "/paymentsearch",
                                      state: {
                                        id: item.id,
                                      },
                                    }}
                                  >
                                    {item.id}
                                  </Link>
                                </td>

                                <td className='text-right'>
                                  {formatMoney(item.amount)}
                                </td>

                                <td
                                  style={{
                                    backgroundColor:
                                      item.transactionRef === "MANUAL_PAYMENT"
                                        ? "#FFBF00"
                                        : null,
                                  }}
                                >
                                  {item.status}
                                </td>

                                <td>{item.paymentDesc}</td>

                                <td>{item.modePaymentClient}</td>

                                <td>{item.transactionRef}</td>

                                <td>{item.paymentType}</td>

                                <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="no_data_screen">
                            <td colSpan={22}>
                              {
                                !props.loader &&
                                  data !== undefined &&
                                  data.length === 0 ?
                                  "No Data Found" : <Loader />
                              }
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.paymentSearchSlice.userData,
    paginationFirstValue: state.paymentticker.paginationFirstValue,
    paginationSecondValue: state.paymentticker.paginationSecondValue,
    paymentticker: state.paymentticker.paymentTickerData,
    paymentTickerTableData: state.paymentticker.paymentTickerTableData,
    userId: state.playersearch.userId,
    loader: state.paymentticker.loader,
    isVisible: state.paymentticker.isVisible,
    tabStack: state.sidebar.tabStack
  };
}

export default connect(mapStateToProps)(PaymentTicker);
