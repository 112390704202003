import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { Combisuggestions } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const combiSlice = createSlice({
    name: "combiSlice",
    initialState: {
        allConfigData: [],
        viewConfigData: [],
        viewUserList: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
    },
    reducers: {
        setAllConfigData: (state, action) => {
            state.allConfigData = action.payload
        },
        setViewConfigData: (state, action) => {
            state.viewConfigData = action.payload
        },
        setViewUserList: (state, action) => {
            state.viewUserList = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
    }
})

export const {
    setAllConfigData,
    setViewConfigData,
    setViewUserList,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,

} = combiSlice.actions

export const createConfig = (params) => (dispatch) => {
    let url = Combisuggestions.createAccumulatorConfig
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            toaster.success(messageEnhancer(res.msg))
        }
    }).catch((err) => {
        console.log(err, "err");
    })
}

// export const getAllConfigList = (pageNum, itemPerpage) => (dispatch) => {
//     let url = Combisuggestions.getAccumulator(pageNum, itemPerpage)
//     apiCaller(url, "GET", {}).then((res) => {
//         if (res.status === 200) {
//             dispatch(setAllConfigData(res.data))
//         }
//     })
// }

export const getConfigIdPopUp = (id) => (dispatch) => {
    let url = Combisuggestions.getConfigByID(id)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setViewConfigData(res.data))
        }
    })
}

export const updateConfigEdit = (param) => (dispatch) => {
    let url = Combisuggestions.configEdit
    apiCaller(url, "PUT", param).then((res) => {
        if (res.status === 200) {
            toaster.success(messageEnhancer(res.msg))
        }
    }).catch((err) => {
        console.log(err, "err");
    })
}

export const searchConfig = (pagenum, itemPerPage, param) => (dispatch) => {
    let url = Combisuggestions.searchConfig + `?pageNumber=${pagenum}&itemsPerPage=${itemPerPage}`

    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setAllConfigData(res))
            dispatch(setIsVisible(true));
        }
    }).catch((err) => {
        console.log(err, "err");
        dispatch(setIsVisible(true));
    })
}

export const viewUser = (accumulatorId) => (dispatch) => {
    let url = Combisuggestions.viewUser(accumulatorId)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setViewUserList(res.data))
        }
    })
}

export default combiSlice.reducer