import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
import { getPendingNotifications } from "../PendingWithrawals/pendingWithrawalSlice";
export const PendingDepositSlice = createSlice({
  name: "pendingDeposit",
  initialState: {
    recordsToShow: [1, 25],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    pendingDeposit: [],
    isVisible: false,
    docsData: [],
    statusType: "INIT",
    totalNotificationCount: 0
  },
  reducers: {
    setPendingDeposit: (state, action) => {
      state.pendingDeposit = action.payload;
    },
    setRecordsToShow: (state, action) => {
      state.recordsToShow = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setStatusType: (state, action) => {
      state.statusType = action.payload;
    },
    setTotalNotificationCount: (state, action) => {
      state.totalNotificationCount = action.payload;
    },
  },
});
export const {
  setPendingDeposit,
  setRecordsToShow,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setIsVisible,
  setDocsData,
  setStatusType,
  setTotalNotificationCount
} = PendingDepositSlice.actions;

export const fetchPendingDepositData =
  (pagenumbervalue, itemperpagevalue, status) => (dispatch) => {
    if (status) {
      var url =
        `${paymentSearchApi.getPendingDeposit}` +
        "?status=" +
        status +
        "&pageNumber=" +
        pagenumbervalue +
        "&itemsPerPage=" +
        itemperpagevalue;
    }

    apiCaller(url, "GET").then((response) => {
      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(setPendingDeposit(response.data));
          dispatch(setIsVisible(true));
          dispatch(getPendingNotifications())
        }
      }
    });
  };
//dev.platform-ups.sportsit-tech.net:443/player/payment/image/77
export const updatePendingDeposit = (params) => (dispatch) => {
  if (params.id) {
    let obj = {
      amount: params.amount,
      message: params.message,
      status: params.status,
    };
    var url = `${paymentSearchApi.getUpdateDeposit}` + "/" + params.id;
    apiCaller(url, "PUT", obj)
      .then((response) => {
        dispatch(fetchPendingDepositData(0, 25, "INIT"));
        dispatch(setIsVisible(true));
        toaster.success(params.status == "CONFIRMED" ? "SUCCESS" : "DECLINED");
      })
      .catch((err) => {
        toaster.error("FAILED");
      });
  }
};





export default PendingDepositSlice.reducer;
