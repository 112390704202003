import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { colorArray, Currency } from "../../sharedfiles/helper";
const HiLoGraph = (props) => {
  useEffect(() => {
    if (props.data && Array.isArray(props.data) && props.data.length > 0) {
      loadGraph();
    }
  }, []);

  const graphData = () => {
    const arrayData = [...props.data];
    const chartData = [];
    arrayData.map((sport, ind) => {
      props.isGameWrox ? chartData.push({
        name: sport.betSport,
        y: sport.revenue,
        color: colorArray[ind],
      })
        : chartData.push({
          name: sport.betSport,
          y: sport.revenue / 100,
          color: colorArray[ind],
        });
    });
    return chartData;
  };

  const loadGraph = () => {
    let stack = [];
    let betSport = [];
    // const graphData = [];
    if (props.data) {
      props.data.forEach((c) => {
        if (!betSport.includes(c.betSport)) {
          betSport.push(c.betSport);
        }
        if (!stack.includes(c.turnover)) {
          stack.push(c.turnover);
        }
      });
    }

    Highcharts.chart("revenuebrand", {
      title: {
        text: "",
      },
      credits: {
        enabled: true,
      },
      xAxis: {
        categories: betSport,
        dateTimeLabelFormats: {
          day: "%e. %b",
        },
      },
      yAxis: {
        title: {
          text: "Revenue",
          style: {
            fontSize: "11px",
            fontWeight: "bold",
            letterSpacing: "1px",
          },
        },
      },
      legend: {
        enabled: true,
      },

      series: [
        {
          name: "Sports",
          data: graphData(),
          tooltip: {
            valuePrefix: Currency(),
            valueDecimals: 2,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
        ],
      },
    });
  };

  return <div id='revenuebrand'></div>;
};

export default HiLoGraph;
