import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { tagApi } from "../../sharedfiles/EndpointConfig";
import { cleanObject } from "../../sharedfiles/helper";
export const PlayerActivitySlice = createSlice({
  name: "playeractivity",
  initialState: {
    playerActivityData: [],
    activePlayerData: {
      totalRecords: 0,
      data: []
    },
    paginationFirstValue: 1,
    activePaginationFirstValue: 1,
    paginationSecondValue: 25,
    activePaginationSecondValue: 25,
    recordsShow: [1, 25],
    activeRecordsShow: [1, 25],
    getTagsListData: [],
    isVisible: false,
    isActiveVisible: false,
    formData: {},
    docsData: [],
    segmentValidator: "",
    tagTypeError: "",
    prevFormData: {},
    prevPayload: {}
  },
  reducers: {
    setPlayerActivityData: (state, action) => {
      state.playerActivityData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    getTagsList: (state, action) => {
      state.getTagsListData = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setSegmentValidator: (state, action) => {
      state.segmentValidator = action.payload;
    },
    setTagTypeError: (state, action) => {
      state.tagTypeError = action.payload;
    },
    setActivePlayerData: (state, action) => {
      state.activePlayerData = action.payload;
    },
    setActivePaginationFirstValue: (state, action) => {
      state.activePaginationFirstValue = action.payload;
    },
    setActivePaginationSecondValue: (state, action) => {
      state.activePaginationSecondValue = action.payload;
    },
    setActiveRecordsShow: (state, action) => {
      state.activeRecordsShow = action.payload;
    },
    setIsActiveVisible: (state, action) => {
      state.isActiveVisible = action.payload;
    },
    setPrevFormData: (state, action) => {
      state.prevFormData = action.payload;
    },
    setPrevPayload: (state, action) => {
      state.prevPayload = action.payload;
    },
  },
});
export const {
  setPlayerActivityData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  getTagsList,
  setIsVisible,
  setPrevFormData,
  setPrevPayload,
  setFormData,
  setDocsData,
  setSegmentValidator,
  tagTypeError,
  setTagTypeError,
  setPrevState,
  setActivePlayerData,
  setActiveRecordsShow,
  setIsActiveVisible,
  setActivePaginationFirstValue,
  setActivePaginationSecondValue
} = PlayerActivitySlice.actions;

export const getPlayerActivity =
  (pagenumbervalue, itemperpagevalue, params, from) =>
    (dispatch) => {
      var obj = {
        // segmentLevel: [params.playerSegement],
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        playerTags: params.playerTags,
        userName: params.userName,
        systemTags: params.systemTags,
        role: params.role,
        maxBetCount: params.maxBetCount,
        minBetCount: params.minBetCount,
      };
      const hydratedFields = cleanObject(obj);
      var url =
        `${from === "activePlayer" ? playerapi.getActivePlayer : playerapi.getPlayerActivity}` +
        "?itemsPerPage=" +
        itemperpagevalue +
        "&pageNumber=" +
        pagenumbervalue;
      apiCaller(url, "POST", hydratedFields).then((response) => {
        if (response !== null && response) {
          if (response.status == 200) {
            if (from !== "activePlayer") dispatch(setPlayerActivityData(response.data));
            else dispatch(setActivePlayerData(response.data));
            dispatch(setIsVisible(true));
            dispatch(setIsActiveVisible(true));
          }
        }
      }).catch(err => {
        if (from !== "activePlayer") dispatch(setPlayerActivityData([]));
        else dispatch(setActivePlayerData({
          totalRecords: 0,
          data: []
        }));
        dispatch(setIsVisible(true));
        dispatch(setIsActiveVisible(true));
      });
    };
export const fetchTagsList = (pageNumber, itemsPerPage) => (dispatch) => {
  const getUrl = `${tagApi.getTag}?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;

  apiCaller(getUrl, "GET", {})
    .then((response) => dispatch(getTagsList(response.data)))
    .catch((err) => console.log(err));
};

export default PlayerActivitySlice.reducer;
