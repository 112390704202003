import React, { useEffect, useRef, useState } from "react";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import Input from "../Common/common_inputs/Input";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { Link } from "react-router-dom";
import {
  betSettlementFromData,
  betStatusCyrus,
  betTypeOptions,
  sportCyrus,
  BetSettlementInfo,
  BetSettlementTransaction,
  BetSettlementTableHeader,
  csvInfo,
  BetSettlementMainHeader,
  TransactionListHeader,
  ViewAstraHeader,
  ViewAstraTooltips,
} from "./CyrusUiCommon";
import Tooltips from "../Common/Tooltip";
import {
  getBetSettlementData,
  setBetSettlementStates,
  setIsvisible,
  setPage,
  setPageCount,
  setRecordShow,
  viewTransaction,
  setCsvData,
  setPdfBody,
  SyncBet,
  setTicketId,
  setPrevPayload,
  viewAstra,
} from "./CyrusUiSlice";
import { useDispatch, useSelector } from "react-redux";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import {
  MomentUTCDateTimeToday,
  formatMoney,
  getDateTimeFormateInvalidDate,
  regexType,
} from "../../sharedfiles/helper";
import Skelton from "../Common/skelton/Skelton";
import No__Data__Found from "../Common/table/No__Data__Found";

const BetSettlement = () => {
  const {
    betSettlementData,
    page,
    pageCount,
    recordShow,
    prevPayload,
    betSettlementStates,
    isVisible,
    viewTransactionData,
    csvData,
    pdfBody,
    isTransactionVisible,
    viewAstraData,
    viewAstraLoading,
  } = useSelector((state) => state.cyrusuislice);
  const sportByBrand = useSelector(p => p.BoostedOddsSlice.sportByBrand)
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    betType: [],
    betId: "",
    shortBId: "",
    betFold: 0,
    evnetId: 0,
    eventName: "",
    placedOn: "",
    settledOn: "",
    betStatus: [],
    sports: "",
  });
  const buttonRef = useRef(null);
  const [openTransactiion, setOpenTransactiion] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [openSettlement, setOpenSettlement] = useState(false);

  const onCloseViewAstra = () => {
    setOpenSettlement(false);
  };

  const openViewAstra = (id) => {
    setOpenSettlement(true);
    dispatch(viewAstra({ betId: id }))
  };

  const onCloseTransaction = () => {
    setOpenTransactiion(false);
  };

  const openTransaction = (ticketId) => {
    setOpenTransactiion(true);
    dispatch(viewTransaction(ticketId));
  };

  const formDataToSliceSetter = (key, val) => {
    const newEntry = { ...betSettlementStates, [key]: val };
    dispatch(setBetSettlementStates(newEntry));
  };

  function stateSetter(e) {
    const { name, value } = e.target;

    if (name === "betType" || name === "betStatus") {
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: [value],
        };
      });
    } else {
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
    formDataToSliceSetter(name, value);
    const updatedFormData = { ...betSettlementStates, [name]: value };
    dispatch(setBetSettlementStates(updatedFormData));
  }

  const handleDateRange = (value, name) => {
    formDataToSliceSetter(name, value);
    setFormData({ ...formData, [name]: value });
  };

  function stateHolder() {
    const isFormDataExist = Object.keys(betSettlementStates).length;
    if (isFormDataExist) {
      Object.entries(betSettlementStates).map(([key, value]) =>
        setFormData((prevState) => {
          return {
            ...prevState,
            [key]: value,
          };
        })
      );
    }
  }

  useEffect(() => {
    dispatch(setBetSettlementStates(betSettlementStates));
    if (betSettlementData?.length === 0) {
      dispatch(
        getBetSettlementData({ page: page - 1, pageCount: 25, params: {} })
      );
    } else formTableData(betSettlementData);
    stateHolder();
    // dispatch(getAllSportsByBrand())
  }, []);

  useEffect(() => {
    formTableData(betSettlementData);
  }, [betSettlementData]);

  function formTableData(rawData = { totalRecords: "", bets: [] }) {
    const bodyData =
      Object.keys(rawData).length > 0 &&
        Array.isArray(rawData.bets) &&
        rawData?.bets?.length > 0
        ? rawData?.bets?.map((item) => {
          return [
            item.tId,
            item.oddsChangeType,
            item.betType,
            item.betCount,
            item.currency,
            item.odds,
            item.stakeBonus,
            item.stakeReal,
            item.bonusWinnings,
            (item.bonusType && item.bonusType !== "null") ? item.bonusType : '-',
            item.isFreeBet ? item.isFreeBet : '-',
            item.freeBetId,
            item.status,
            item.system,
            item.potentialPayout,
            item.bonusWinnings,
            item.bonusPercentage,
            item.maxWinPercentage,
            item.maxWinAmount,
            item.payout,
            item.cashoutStatus,
            !!!item.createdDate
              ? getDateTimeFormateInvalidDate(item.createdDate)
              : "-",
            !!!item.resultedDate
              ? getDateTimeFormateInvalidDate(item.resultedDate)
              : "-",
            item.cashOutAmount,
            item?.isPrinted ? "true" : "false",
          ];
        })
        : [];

    dispatch(setPdfBody(bodyData));
    const CsvData = [BetSettlementTableHeader[0], ...bodyData];
    dispatch(setCsvData(CsvData));
  }

  function exportPDF() {
    const title = "Bet Settlement Report";
    exportPDFC(title, BetSettlementTableHeader, pdfBody, {});
  }
  function createPayload(formData) {
    return {
      betTypes: formData.betType,
      tId: formData.betId,
      ticketShortId: formData.shortBId,
      betCount: parseInt(formData.betFold),
      competitionId: parseInt(formData.evnetId),
      competitionName: formData.eventName,
      placedOn: MomentUTCDateTimeToday(formData.placedOn),
      settledOn: MomentUTCDateTimeToday(formData.settledOn),
      status: formData.betStatus,
      sportName: formData.sports,
    };
  }

  function callApi(page, pageCount) {
    dispatch(setIsvisible(false));
    dispatch(
      getBetSettlementData({
        page: page - 1,
        pageCount: pageCount,
        params: prevPayload,
      })
    );
  }

  const onSubmit = () => {
    const systemPayload = createPayload(formData);
    dispatch(setPrevPayload(systemPayload));
    dispatch(
      getBetSettlementData({ page: 0, pageCount: 25, params: systemPayload })
    );
    dispatch(setRecordShow([1, 25]));
    dispatch(setIsvisible(false));
  };

  const handleReset = () => {
    setFormData({
      betType: [],
      betId: "",
      shortBId: "",
      betFold: 0,
      evnetId: 0,
      eventName: "",
      placedOn: "",
      settledOn: "",
      betStatus: [],
      sports: "",
    });
    dispatch(setPrevPayload({}));
    dispatch(setIsvisible(false));
    dispatch(setPage(1));
    dispatch(setPageCount(25));
    dispatch(setRecordShow([1, 25]));
    dispatch(setBetSettlementStates({}));
    dispatch(
      getBetSettlementData({ page: page - 1, pageCount: 25, params: {} })
    );
  };

  function handleSync(ticket) {
    setIsButtonDisabled(true);
    dispatch(SyncBet(ticket));
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  }
  // const newSportsArray = sportByBrand.map(sport => ({
  //   id: sport.sportId,
  //   label: sport.sportName,
  //   value: sport.sportId
  // }));
  // const sportArray = newSportsArray?.map((obj) => {
  //   if (obj?.id == formData.sports) {
  //     return obj?.label;
  //   }
  // })
  // const filteredsportName = sportArray.filter(name => name !== undefined);
  // const sportName = filteredsportName[0];

  return (
    <>
      <Dialog__Main
        title={"TRANSACTION LIST"}
        dOpen={openTransactiion}
        dClose={onCloseTransaction}
        width={"700px"}
      >
        {!isTransactionVisible ? (
          <Skelton vLen={5} hLen={7} />
        ) : (
          <div className="CMS-table CMS-table-triped">
            <table>
              <thead>
                <tr>
                  {TransactionListHeader?.map((item, index) => (
                    <th key={index}>
                      <div className="d-flex justify-content-center align-items-center">
                        <p style={{ whiteSpace: "nowrap" }} className="mx-3">
                          {item}
                        </p>
                        <Tooltips info={BetSettlementTransaction[index]} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {viewTransactionData !== null &&
                  viewTransactionData !== undefined &&
                  Array.isArray(viewTransactionData?.ticketTransactions) &&
                  viewTransactionData?.ticketTransactions.length > 0 ? (
                  viewTransactionData?.ticketTransactions?.map((value, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{value.tId}</td>
                        <td>{value.status}</td>
                        <td>{value.payout}</td>
                        <td>
                          {getDateTimeFormateInvalidDate(value.transactionDate)}
                        </td>
                        <td>{value?.updatedBy ? value?.updatedBy : '-'}</td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Dialog__Main>
      <Dialog__Main
        title={"VIEW ASTRA"}
        dOpen={openSettlement}
        dClose={() => onCloseViewAstra()}
        width={"700px"}
      >
        {!viewAstraLoading ? (<Skelton vLen={5} hLen={7} />) : (<div className="CMS-table CMS-table-triped">
          <table>
            <thead>
              <tr>
                {ViewAstraHeader.map((val, ind) => (
                  <th key={ind}>
                    <div className="d-flex justify-content-center align-items-center">
                      <p style={{ whiteSpace: "nowrap" }} className="mx-3">
                        {val}
                      </p>
                      <Tooltips info={ViewAstraTooltips[ind]} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {viewAstraData !== null &&
                viewAstraData !== undefined &&
                Array.isArray(viewAstraData.data) &&
                viewAstraData?.data?.length > 0 ? (
                viewAstraData?.data?.map((item, ind) => {
                  return (<tr key={ind}>
                    <td>{item?.betId}</td>
                    <td>{item?.betStatus ? item?.betStatus : '-'}</td>
                    <td>{item?.rejectionCode ? item?.rejectionCode : '-'}</td>
                    <td>{item?.betType}</td>
                    <td>{item?.odds}</td>
                    <td>{formatMoney(item?.stake)}</td>
                    <td>{item?.placeBetType}</td>
                    <td>{item?.bonusPayout ? formatMoney(item?.bonusPayout) : '-'}</td>
                    <td>{item?.bonustype ? item?.bonustype : '-'}</td>
                    <td>{formatMoney(item?.potentialPayout)}</td>
                    <td>{formatMoney(item?.payout)}</td>
                    <td>{getDateTimeFormateInvalidDate(item?.createdDate)}</td>
                    <td>{getDateTimeFormateInvalidDate(item?.transactionSettledDate)}</td>
                    <td>{formatMoney(item?.totalPayout)}</td>
                    <td>{formatMoney(item?.totalPayoutWithBonus)}</td>
                    <td>{formatMoney(item?.bonusMaxWinAmount)}</td>
                    <td>{item?.bonusMaxWinPercentage}</td>
                  </tr>
                  );
                })
              ) : (
                <No__Data__Found colspan={50} />
              )}
            </tbody>
          </table>
        </div>)}
      </Dialog__Main>

      <div className="CMS-tabContent">
        <div className="CMS-tabs-content">
          <div className="CMS-tab-panel active" id="CMS-codedBonus">
            <div className="CMS-box CMS-box-content">
              <div className="CMS-formGroup">
                <div className="CMS-formControl row d-flex justify-content-around">
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Select
                      title={"Bet Type"}
                      name={"betType"}
                      value={formData?.betType || ""}
                      setValue={(e) => stateSetter(e)}
                      options={betTypeOptions}
                      ids={"PlayerCurrency"}
                      info={BetSettlementInfo.bet_type}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Input
                      title="Bet Id"
                      name="betId"
                      value={formData?.betId || ""}
                      placeholder="Bet Id"
                      setValue={(e) => stateSetter(e)}
                      ids={"betID"}
                      info={BetSettlementInfo.bet_id}
                      show={true}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Input
                      title="Short Bet Id"
                      name="shortBId"
                      value={formData?.shortBId || ""}
                      placeholder="Short Bet Id"
                      setValue={(e) => stateSetter(e)}
                      ids={"ShortBetId"}
                      info={BetSettlementInfo.short_bet_id}
                      show={true}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Input
                      title={"Bet Fold"}
                      name={"betFold"}
                      value={formData?.betFold || ""}
                      setValue={(e) => stateSetter(e)}
                      placeholder="Bet Fold"
                      // options={betFoldCyrus}
                      ids={"betFold"}
                      regex={regexType.numeric}
                      show={true}
                      info={BetSettlementInfo.bet_fold}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Input
                      title="Event Id"
                      name={"evnetId"}
                      value={formData?.evnetId || ""}
                      placeholder="Event Id"
                      setValue={(e) => stateSetter(e)}
                      ids={"evnetId"}
                      info={BetSettlementInfo.event_id}
                      regex={regexType.numeric}
                      show={true}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Input
                      title="Event Name"
                      name="eventName"
                      value={formData?.eventName || ""}
                      placeholder="Event Name"
                      setValue={(e) => stateSetter(e)}
                      ids={"eventName"}
                      info={BetSettlementInfo.event_name}
                      show={true}
                    />
                  </div>
                </div>
                <div className="row">
                  {betSettlementFromData.map((item, ind) => {
                    const minDate = [null, formData.placedOn];
                    const max = formData.settledOn
                      ? formData.settledOn
                      : new Date();
                    const maxDate = [max, new Date()];
                    return (
                      <React.Fragment key={item.ind}>
                        <div className="col-md-4 col-lg-3 col-xl-2">
                          <Datepicker
                            title={item.title}
                            name={item.name}
                            value={formData[item.name]}
                            // errorMsg={formErrors[item.name]}
                            minDate={minDate[ind]}
                            maxDate={maxDate[ind]}
                            setValue={(date, name) =>
                              handleDateRange(date, name)
                            }
                            ids={item.ids}
                            info={item.info}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Select
                      title={"Bet Status"}
                      name={"betStatus"}
                      value={formData?.betStatus || ""}
                      setValue={(e) => stateSetter(e)}
                      options={betStatusCyrus}
                      // onSubmit={(e) => this.onSubmit(e)}
                      ids={"PlayerCurrency"}
                      info={BetSettlementInfo.bet_status}
                    />
                  </div>
                  <div className="col-md-4 col-lg-3 col-xl-2">
                    <Select
                      title={"Sport"}
                      name={"sports"}
                      value={formData?.sports || ""}
                      setValue={(e) => stateSetter(e)}
                      options={sportCyrus}
                      // onSubmit={(e) => this.onSubmit(e)}
                      ids={"PlayerCurrency"}
                      info={BetSettlementInfo.sport}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"SearchBet"}
                  idreset={"ResetBet"}
                  submit={(e) => onSubmit(e)}
                  reset={(e) => handleReset(e)}
                />
              </div>
            </div>
            <div className="CMS-filter-result" id="result">
              <div className="CMS-pagination">
                <Vpagination
                  data={betSettlementData?.bets}
                  totalRecords={betSettlementData?.totalRecords}
                  paginationFirstValue={page}
                  paginationSecondValue={pageCount}
                  setPaginationFirstValue={setPage}
                  setPaginationSecondValue={setPageCount}
                  recordsShow={recordShow}
                  setRecordsShow={setRecordShow}
                  isVisible={isVisible}
                  setIsVisible={setIsvisible}
                  csvData={csvData}
                  exportPDF={exportPDF}
                  callApi={callApi}
                  csvFileName={"Bet Settlement Report"}
                />
              </div>

              {!isVisible ? (
                <Skelton vLen={5} hLen={7} />
              ) : (
                <div className="CMS-box">
                  <div className="CMS-table CMS-table-triped">
                    <table>
                      <thead>
                        <tr>
                          {BetSettlementMainHeader.map((val, ind) => (
                            <th key={ind}>
                              <div
                                style={{ whiteSpace: "nowrap" }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <p className="mx-3">{val}</p>
                                <Tooltips info={csvInfo[ind]} />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {betSettlementData !== null &&
                          betSettlementData !== undefined &&
                          Array.isArray(betSettlementData.bets) &&
                          betSettlementData?.bets?.length > 0 ? (
                          betSettlementData?.bets?.map((val, ind) => {
                            return (
                              <tr key={ind}>
                                <td
                                  onClick={() => {
                                    localStorage.setItem(
                                      "bet_settlement_tid",
                                      val?.tId
                                    );
                                    dispatch(setTicketId(val?.tId));
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname: `/betdetails`,
                                      state: {
                                        id: val?.tId,
                                      },
                                    }}
                                  >
                                    {val?.tId}
                                  </Link>
                                </td>
                                <td>{val?.shortBetId}</td>
                                <td>{val?.oddsChangeType}</td>
                                <td>{val?.betType}</td>
                                <td>{val?.betCount}</td>
                                <td>{val?.currency}</td>
                                <td>{val?.odds}</td>
                                <td>{val?.stakeBonus}</td>
                                <td>{val?.stakeReal}</td>
                                <td>{val?.bonusWinnings}</td>
                                <td>{!val?.bonusType || val?.bonusType === 'null' ? "-" : val?.bonusType}</td>
                                <td>{val?.isFreeBet ? val?.isFreeBet : "-"}</td>
                                <td>{val?.freeBetId ? val?.freeBetId : '-'}</td>
                                <td>{val?.status}</td>
                                <td>{val?.systems ? val?.systems.toString() : "-"}</td>
                                <td>{val?.potentialPayout}</td>
                                <td>{val?.bonusWinnings}</td>
                                <td>{val?.bonusPercentage}</td>
                                <td>{val?.maxWinPercentage}</td>
                                <td>{val?.maxWinAmount}</td>
                                <td>{val?.payout}</td>
                                <td>{val?.cashoutStatus}</td>
                                <td>
                                  {val && val.createdDate
                                    ? getDateTimeFormateInvalidDate(
                                      val.createdDate
                                    )
                                    : "-"}
                                </td>
                                <td>
                                  {val && val.resultedDate
                                    ? getDateTimeFormateInvalidDate(
                                      val.resultedDate
                                    )
                                    : "-"}
                                  {/* {
                                    val?.resultedDate ? '-' : getDateTimeFormateInvalidDate(val?.resultedDate)
                                  } */}
                                </td>
                                <td>{val?.cashOutAmount ? val.cashOutAmount : '-'}</td>
                                <td>{val?.isPrinted ? "true" : "false"}</td>
                                {/* <td>{ }</td> */}
                                <td>
                                  <button
                                    onClick={() => openViewAstra(val?.tid)}
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                  >
                                    {/* <Link
                                      className='a-link'
                                      to={{
                                        pathname: "/betHistory",
                                        state: {
                                          id: val?.tId,
                                        },
                                      }}
                                    >
                                    </Link> */}
                                    View Astra
                                  </button>
                                  <button
                                    onClick={() => openTransaction(val?.tId)}
                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer mt-10"
                                  >
                                    View Transaction
                                  </button>
                                  <button
                                    ref={buttonRef}
                                    disabled={isButtonDisabled}
                                    onClick={() => handleSync(val?.ticketId)}
                                    className="CMS-btn  active CMS-btnSmall pointer mt-10 CMS-btnStatus CMS-statusActive"
                                  >
                                    Sync
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <No__Data__Found colspan={50} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BetSettlement;
