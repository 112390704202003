import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi } from "../../sharedfiles/EndpointConfig";

export const BonusSlice = createSlice({
  name: "bonusSlice",
  initialState: {
    bonusData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    userid: "",
    recordsShow: [1, 25],
    isVisible: false,
    bonusTransactionData: [],
  },
  reducers: {
    setBonusData: (state, action) => {
      state.bonusData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setBonusTransactionData: (state, action) => {
      state.bonusTransactionData = action.payload;
    },
  },
});
export const {
  setBonusData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setErrorMessage,
  setRecordsShow,
  setIsVisible,
  setBonusTransactionData,
} = BonusSlice.actions;

export const getBonusTableList =
  (pagenumbervalue, itemperpagevalue, id) => (dispatch) => {
    var url =
      `${bonusApi.getPlayerBonus}` +
      id +
      "/user-bonus" +
      "?itemsPerPage=" +
      itemperpagevalue +
      "&pageNumber=" +
      pagenumbervalue;

    if (id !== "" || id !== null || id !== undefined) {
      apiCaller(url, "GET").then((response) => {
        if (response !== null && response) {
          if (response.status == "200") {
            dispatch(setBonusData(response.data));
            dispatch(setIsVisible(true));
          }

          dispatch(setErrorMessage(response.message));
        }
      });
    }
  };

//dev.platform-pbs1.sportsit-tech.net:443/bonus/1000042/transactions?pageNumber=0&itemsPerPage=25
//dev.platform-pbs1.sportsit-tech.net/bonus/1000210/transactions&pageNumber=0?itemsPerPage=25
export const bonusTransaction =
  (pageNumber, itemsPerPage, id) => (dispatch) => {
    const url =
      `${bonusApi.getPlayerBonus}` +
      id +
      "/transactions?" +
      "pageNumber=" +
      pageNumber +
      "&itemsPerPage=" +
      itemsPerPage;

    if (id !== null || id !== "" || id !== undefined) {
      apiCaller(url, "GET")
        .then((response) => {
          dispatch(setBonusTransactionData(response.data));
          dispatch(setIsVisible(true));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

export default BonusSlice.reducer;
