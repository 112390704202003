import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi, kycApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const kycuserSlice = createSlice({
    name: "kycusers",
    initialState: {
        kycUserData: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
    },
    reducers: {
        setKycUserData: (state, action) => {
            state.kycUserData = action.payload;
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
    },
});

export const {
    setKycUserData,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible
} = kycuserSlice.actions;

export const getKycUserList = (itemsPerPage, pageNum, params) => (dispatch) => {
    var obj = params;
    const arr = [];
    for (let key in obj) {
        if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
            arr.push(obj[key]);
        }
    }
    const isTrue = arr.length >= 2;
    const pageNumber = isTrue ? 0 : itemsPerPage;
    var url = `${playerapi.getPlayerSearch}` +
        "?itemsPerPage=" +
        pageNum +
        "&pageNumber=" +
        pageNumber;
    apiCaller(url, "POST", obj).then((res) => {
        if (res !== null && res) {
            dispatch(setKycUserData(res.data))
            dispatch(setIsVisible(true))
        }

    }).catch(err => {
        dispatch(setKycUserData([]))
        dispatch(setIsVisible(false))
    })

}

export const kycApprovalAll = (status, id) => (dispatch) => {
    const url = kycApi.getKycApproval
    const data = {
        "status": status,
        "userId": id,
    }
    apiCaller(url, "POST", data).then((res) => {
        if (res.status === 200) {
            toaster.success("SUCCESS");
            dispatch(getKycUserList(0, 25, { kycStatus: "SUBMITTED" }))
        }
        else {
            toaster.error("FAILED");
        }
    }).catch((err) => toaster.error("FAILED"));

}

export const kycRejectAll = (status, id, comments) => (dispatch) => {
    const url = kycApi.getkycRejected
    const data = {
        "status": status,
        "userId": id,
        "comments": comments
    }
    apiCaller(url, "POST", data).then((res) => {
        if (res.status === 200) {
            toaster.success("SUCCESS");
            dispatch(getKycUserList(0, 25, { kycStatus: "SUBMITTED" }))
        }
        else {
            toaster.error("FAILED");
        }
    }).catch((err) => toaster.error("FAILED"));

}

export default kycuserSlice.reducer;