import React, { useState, useEffect } from 'react'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { 
    getArbitageBet, 
    getDupeCids, 
    setdisableBtnRiskMgt,
    setArbitagePageOneValue,
    setArbitageSecondValue,
    setArbitageIsVisible,
    setArbitageShowRecord
 } from './RiskmgmtSlice'
import No__Data__Found from '../Common/table/No__Data__Found'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import Table from '../Common/table/Table'
import Tooltips from '../Common/Tooltip'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'
import Vpagination from '../../sharedfiles/Vpagination'

const ArbitrageBet = () => {
    const [openDialog, setOpenDialog] = useState(false)
    const [tableData, setTableData] = useState('')
    const [searchAbtData, setsearchAbtData] = useState('')
    const dispatch = useDispatch()
    const getInfo = useSelector((state) => state.riskManagement.arbitageData)
    const playerInfo = useSelector((state) => state.riskManagement.dupeCids)
    const { isPrevUserResp, disableBtnRiskMgt,arbitagePageOneVal,arbitageSecondVal,arbitageIsVisible,arbitageRecordShow } = useSelector((state) => state.riskManagement);
    const [mounted, setMounted] = useState(false);
    const [csvData, setCsvData] = useState([])
    const [pdfBody, setPdfBody] = useState([])
    
    useEffect(() => {
        dispatch(getArbitageBet(0, 25,{}))
    }, [])


    const tHead = [
        COMMON_CONSTANTS.header_firstname,
        COMMON_CONSTANTS.header_lastname,
        COMMON_CONSTANTS.header_email,
        COMMON_CONSTANTS.countryCode,
        COMMON_CONSTANTS.phone_Number,
        COMMON_CONSTANTS.count,
    ]
    const tHeadPagination = [
        [
        COMMON_CONSTANTS.header_firstname,
        COMMON_CONSTANTS.header_lastname,
        COMMON_CONSTANTS.header_email,
        COMMON_CONSTANTS.countryCode,
        COMMON_CONSTANTS.phone_Number,
        COMMON_CONSTANTS.count,
    ]
    ]

    const theadInfo = [
        "Showing First Name",
        "Showing Last Name",
        "Showing Email Id",
        "Showing Country Code",
        "Showing Phone Number",
        "Showing Count"
    ]

    const tHeader = [
        [
            "First Name",
            "Last Name",
            "Email Id",
            "Country Code",
            "Phone Number",
            "User Id"
        ]
    ]
    const popInfo = [
        "Showing First Name",
        "Showing Last Name",
        "Showing Email Id",
        "Showing Country Code",
        "Showing Phone Number",
        "Showing User Id"
    ]

    const onOpen = (code, email, firstname, lastname, phnNum) => {
        setOpenDialog(true)
        dispatch(getDupeCids(code, email, firstname, lastname, phnNum))
    }

    const onClose = () => {
        setOpenDialog(false)
    }

    const update = () => {
        const tbody = playerInfo &&
            playerInfo.length > 0 ? playerInfo.map((item) => [item.firstName, item.lastName, item.email, item.countryCode, item.phoneNumber, item.userId]) : []
        setTableData([tHeader[0], popInfo, ...tbody])

    }
  const arbitageUpdate = () => {
    const tbody =getInfo && getInfo.data &&
    getInfo.data.length > 0 ? getInfo.data.map((item) => [item.firstName, item.lastName, item.email, item.countryCode, item.phoneNumber, item.count]) : []
        setPdfBody(tbody)
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tHeadPagination[0], ...tbody] : [tHeadPagination[0], []];
        setCsvData(data);
  }
  useEffect(() => {
    arbitageUpdate()
    dispatch(setArbitageIsVisible(true))
  },[getInfo])

    const exportPDF = () => {
        const title = "Arbitage Betting Report"
        const styles = {
            marginTop: "10px"
        };
        exportPDFC(title, tHeadPagination, pdfBody, styles);

    }
 const callPageApi = (pageNumber, itemsPerPage,data) => {
    dispatch(setArbitageIsVisible(true))
    const payload = {
        userName : searchAbtData
    }
    dispatch(getArbitageBet(pageNumber - 1, itemsPerPage, searchAbtData ? payload:{} ))
 }
    const tableStyles = {
        0: "text-center",
    };

    useEffect(() => {
        update();
    }, [playerInfo])

    function handleAbtSearch(value) {
        setsearchAbtData(value);
    }
    useEffect(() => {
        var btnTimer;
        if (disableBtnRiskMgt) {
            btnTimer = setTimeout(() => {
                dispatch(setdisableBtnRiskMgt(false))
                setMounted(true)
            }, 2500)
        };
        return () => clearTimeout(btnTimer);
    }, [disableBtnRiskMgt]);

    function handleClickAbtSearch(value) {
        if (isPrevUserResp && isPrevUserResp === value) return;
        const payload = {
            userName : searchAbtData
        }
        dispatch(getArbitageBet(0,25,payload))
    }

    return (
        <>
            <Dialog__Main
                title={"CUSTOMERS WITH SAME PROFILE"}
                dOpen={openDialog}
                dClose={() => onClose()}
                width={"700px"}
            // dHeight={"280px"}
            >
                <Table tableData={tableData} linkIndex={5} styles={tableStyles} />
            </Dialog__Main>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <input
                        className='mt-20'
                        style={{ width: 200 }}
                        type="text"
                        placeholder="Search"
                        id="myInput"
                        name="searchData"
                        value={searchAbtData}
                        onChange={(e) => handleAbtSearch(e.target.value)}
                    />
                    <span className={`material-icons search search-icon-AFF-arbitrage pointer ${disableBtnRiskMgt && mounted ? 'disable-button' : ''}`}
                        onClick={(value)=>handleClickAbtSearch(value)}
                    >search</span>

<div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={getInfo.data}
                            totalRecords={getInfo.totalRecords}
                            paginationFirstValue={arbitagePageOneVal}
                            paginationSecondValue={arbitageSecondVal}
                            setPaginationFirstValue={setArbitagePageOneValue}
                            setPaginationSecondValue={setArbitageSecondValue}
                            recordsShow={arbitageRecordShow}
                            setRecordsShow={setArbitageShowRecord}
                            isVisible={arbitageIsVisible}
                            setIsVisible={setArbitageIsVisible}
                            csvData={csvData}
                            exportPDF={exportPDF}
                            callApi={callPageApi}
                            csvFileName={"Arbitage Betting Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>

                    <div className='CMS-tabContent'>
                        <div className='CMS-filter-result' id='result'>
                            <div className='CMS-box '>
                                <div className='CMS-table CMS-table-triped'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {tHead.map((item, index) => (
                                                    <th>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <p className='mx-3'>{item}</p>
                                                            <Tooltips info={`${theadInfo[index]}`} />
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getInfo && getInfo.data
                                                && getInfo.data.length > 0 ? (
                                                getInfo.data.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.firstName}</td>
                                                            <td>{item.lastName}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.countryCode}</td>
                                                            <td>{item.phoneNumber}</td>
                                                            <td className='link_style pointer'
                                                                onClick={() => onOpen(item.countryCode, item.email, item.firstName, item.lastName, item.phoneNumber)}
                                                            >
                                                                {item.count}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <No__Data__Found colspan={50} />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArbitrageBet