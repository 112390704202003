import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { userApi } from "../../sharedfiles/EndpointConfig";
import { getFromLocalStorage, messageEnhancer, toaster } from "../../sharedfiles/helper";
import { getAffiliateTable } from "../Affiliate/AffiliateSlice";
export const CreateUserSlice = createSlice({
  name: "createuserSlice",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    isVisible: false,
    responseData: [],
    allUsersList: [],
    rolesList: [],
    userRolesList: [],
    prevFormData: {},
    prevBrandId: "",
    prevBrandIdErr: false,
    docsData: [],
    pdfData: [],
    formErrors: [],
    userId: "",
    selectedUser: {},
    successMsg: "",
    errorMsg: ""
  },
  reducers: {
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setResponseData: (state, action) => {
      state.responseData = action.payload;
    },
    setAllUsersList: (state, action) => {
      state.allUsersList = action.payload;
    },
    setRolesList: (state, action) => {
      state.rolesList = action.payload;
    },
    setUserRolesList: (state, action) => {
      state.userRolesList = action.payload;
    },
    setPrevFormData: (state, action) => {
      state.prevFormData = action.payload;
    },
    setPrevBrandId: (state, action) => {
      state.prevBrandId = action.payload;
    },
    setPrevBrandIdErr: (state, action) => {
      state.prevBrandIdErr = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setPdfData: (state, action) => {
      state.pdfData = action.payload;
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setSuccessMsg: (state, action) => {
      state.successMsg = action.payload;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
});
export const {
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setResponseData,
  setAllUsersList,
  setRolesList,
  setUserRolesList,
  setPrevFormData,
  setPrevBrandId,
  setPrevBrandIdErr,
  setDocsData,
  setPdfData,
  setFormErrors,
  setUserId,
  setSelectedUser,
  setSuccessMsg,
  setErrorMsg,
} = CreateUserSlice.actions;

export const createUser = (data) => (dispatch) => {
  // /platform/user
  const url = `${userApi.createUser}`;
  const body = {
    // clientId: data.clientId,
    firstName: data.firstName,
    lastName: data.lastName,
    loginPin: data.loginPin,
    role: "ADMIN",
    userName: data.userName,
    // btag: data.btag,
  };
  apiCaller(url, "POST", body)
    .then((res) => {
      dispatch(setResponseData(res.data));
      res.status === 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(res.status) &&

        toaster.error(
          res.msg === "USER_NAME_ALREADY_EXIST" ? res.msg : "FAILED"
        );

    })
    .catch((err) => {
      const response = err.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};

export const getAllUsers = (brandId) => (dispatch) => {
  const url = `${userApi.getAllRoles(brandId)}`;
  apiCaller(url, "GET", {})
    .then((rep) => {
      dispatch(setRolesList(rep.data));
      const data = [];
      rep.data.map((item) => {
        data.push({
          id: item.id,
          name: item.roleName,
          value: item.id,
          roleIds: item.permissionId,
        });
      });
      dispatch(setUserRolesList(data.sort((a, b) => a.id - b.id)));
    })
    .catch((err) => toaster.error(err.msg));
};

export const getUserTable = (pageNumber, itemPerPage) => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net:443/platform/user/list?pageNumber=0&itemsPerPage=25
  const url =
    `${userApi.getAdminUser}` +
    `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
  apiCaller(url, "GET", {})
    .then((rep) => dispatch(setAllUsersList(rep.data)), dispatch(setIsVisible(true)))
    .catch((err) => {
      const response = err.response
      const hydratedText =
        response &&
        response
          .split('_')
          .map((word) =>
            word[0].toUpperCase().concat(word.slice(1).toLowerCase()),
          )
          .join(' ')
      toaster.error(hydratedText);
    });
};

// export const getAffiliateTable = (pageNumber, itemPerPage) => (dispatch) => {
//   //dev.platform-ups.sportsit-tech.net:443/platform/user/list?pageNumber=0&itemsPerPage=25
//   const url =
//     `${userApi.getAdminUser}` +
//     `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`;
//   apiCaller(url, "GET", {})
//     .then((rep) => dispatch(setAllUsersList(rep.data)))
//     .catch((err) => dispatch(setErrorMessage("Failed")));
// };

export const deleteUser = (id) => (dispatch) => {
  const url = `${userApi.createUser}/${id}`;
  apiCaller(url, "DELETE", {})
    .then((response) => {
      response.status === 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
      !response && toaster.error("FAILED");
    })
    .catch((error) => toaster.error("FAILED"));
};

export const getUserDetails = (userId) => dispatch => {
  const url = userApi.updateUserDetails(userId);
  apiCaller(url, "GET").then(res => {
    dispatch(setSelectedUser(res.data));
  })
}

export const updateUserDetails = (payload, id, page) => (dispatch, getState) => {
  const url = userApi.updateUserDetails(id);
  const { createuserSlice, affiliateSlice } = getState();
  // create user //
  const pageNumber = createuserSlice.paginationFirstValue;
  const itemsPerPage = createuserSlice.paginationSecondValue;
  // create user //

  // affiliate //
  const affPage = affiliateSlice.paginationFirstValue;
  const affItems = affiliateSlice.paginationSecondValue;
  // affiliate //

  // subAffiliate //
  const page_suboff = affiliateSlice.page_suboff;
  const items_suboff = affiliateSlice.items_suboff;
  // subAffiliate //

  apiCaller(url, "PUT", payload).then(res => {
    if (page === "createUser") dispatch(getUserTable(pageNumber - 1, itemsPerPage));
    if (page === "affiliate") dispatch(getAffiliateTable(affPage - 1, affItems));
    if (page === "subAffiliate") {
      const parentAffiliateId = getFromLocalStorage("AffliateUserId");
      dispatch(getAffiliateTable(page_suboff - 1, items_suboff, parentAffiliateId))
    }
    toaster.success(messageEnhancer(res.msg));
  }).catch(err => {
    console.log("err: ", err);
    toaster.error(messageEnhancer(err.response));
  })
}

export default CreateUserSlice.reducer;
