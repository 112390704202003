import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { generateKey } from "../../../sharedfiles/helper";
import { tabManager } from "../../sidebar/sidebarSlice";
import { setTableShouldView } from "../../Reports/reportSlice";
import { getPlayer, setIsPlayerExist } from "../../playerTable/playerSearchTableSlice";
function NavigationTabs(props) {
  const { isMainPage } = props;
  const [activePath, setActivePath] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const sidebarTabs = useSelector((st) => st.sidebar.sidebarTabs);
  const tableShouldView = useSelector((st) => st.reports.tableShouldView);
  const tabStack = useSelector((st) => st.sidebar.tabStack);
  const activeTab = useSelector((st) => st.sidebar.activeTab);

  const deleteTab = (item, isSubTab = false) => {
    dispatch(tabManager(item.subtitle, tabStack, true, history, isSubTab));
  };
  useEffect(() => {
    let mounting = true;

    if (mounting) {
      const path = location.pathname;
      const pathArray = path.split("/");
      const pathName = pathArray.includes("customerid") ? parseInt(pathArray[2]) : path;

      if (Array.isArray(tabStack) && tabStack.length > 0) {
        const tabExist = tabStack.some(tab => tab.path === path);
        if (!tabExist) {
          if (pathArray.includes("customerid")) {
            dispatch(getPlayer(pathName, history));
          } else {
            dispatch(tabManager(pathName, tabStack, false, history));
          }
        } else {
          dispatch(tabManager(pathName, tabStack));
        }
      }
    }

    return () => mounting = false;

  }, [location.pathname]);

  // REPORT PAGE PART //

  function undateReportTabs(reportPage, reportKey) {
    const subTitles =
      Array.isArray(sidebarTabs) && sidebarTabs.length > 0
        ? sidebarTabs.map((v) => v.subtitle)
        : [];
    if (subTitles.length > 0 && tableShouldView.length > 0) {
      if (
        !subTitles.includes(reportPage) &&
        tableShouldView.includes(reportKey)
      ) {
        const removeReportTable = tableShouldView.filter(
          (v) => v !== reportKey
        );
        dispatch(setTableShouldView(removeReportTable));
      }
    }
  }
  useEffect(() => {
    const reports = [
      "SPORTS OVERVIEW",
      "CUSTOMER OVERVIEW",
      "PAYMENT OVERVIEW",
      "MANUAL ADJUSTMENT",
      "ACTIVE PLAYERS",
      "INDIVIDUAL PLAYER",
      "MULTIPLE DEPOSIT",
    ];
    const reportKeys = [
      "SPORT",
      "CUSTOMER",
      "PAYMNET",
      "MANULAPAYMENT",
      "ACTIVE",
      "INDIVIDUAL",
      "multipleDeposit",
    ];
    reports.map((v, i) => undateReportTabs(v, reportKeys[i]));
  }, [sidebarTabs]);

  // REPORT PAGE PART //

  const handleChangeTab = (item) => {
    dispatch(setIsPlayerExist(item.subtitle));
    dispatch(tabManager(item.subtitle, tabStack));
  }

  return (
    <React.Fragment>
      <div className='CMS-page-tabs'>
        <ul>
          {tabStack.length > 0 &&
            tabStack.map((item, ind) => {
              return (
                <li
                  key={generateKey(ind)}
                  className={item.subtitle == activeTab ? "active" : ""}
                >
                  <Link
                    to={item.path}
                    onClick={() => handleChangeTab(item)}
                  >
                    {item.subtitle}
                  </Link>
                  {/* </div> */}
                  <span className='close'>
                    <span
                      onClick={() => deleteTab(item, item.isSubItem ? true : false)}
                      className='material-icons md-18'
                      data-icon='close'
                    ></span>
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </React.Fragment>
  );
}

export default NavigationTabs;
