import { handleError } from "./apiUtil";
import { loginapi, brandApi } from "../sharedfiles/EndpointConfig";
import axios from "axios";
import { refreshTokenApi } from "../sharedfiles/helper";

export function apiCaller(url, method, params, token, errMsg) {
  var options = {};

  options.method = method;

  var headers = {
    ...(url === `${loginapi.getLogin}`
      ? {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
      : url === `${brandApi.getBrand}`
        ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorisation: token || localStorage.getItem("token"),
        }
        : {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorisation: token || localStorage.getItem("token"),
          MerchantId: localStorage.getItem("merchantkey")
            ? localStorage.getItem("merchantkey")
            : localStorage.getItem("merchantId"),
        }),
  };
  var query = "";
  if (params && (Object.keys(params).length > 0 || method === 'POST' || method === 'PUT' || method === 'DELETE')) {
    if (method === "POST" || method === "PUT" || method === "DELETE") {
      options.headers = headers;
      options.body = JSON.stringify(params);
    } else {
      query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
    }
  }

  return new Promise((resolve, reject) => {
    let requestConfig = {
      headers,
      method: options.method,
    };

    if (options.body) {
      requestConfig.data = options.body;
    }

    axios
      .request(url, requestConfig)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else if (response.status === 400) {
          reject(response.data);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "INVALID_AUTHORIZATION_TOKEN_OR_EXPIRED"
        ) {
          refreshTokenApi()
            .then(() => {
              // Retry the original request after token refresh
              axios.request(url, requestConfig).then((response) => {
                resolve(response.data);
              });
            })
            .catch((refreshError) => {
              reject({
                response: refreshError.response?.data?.msg,
                statusCode: refreshError.response?.status,
                statusText: refreshError.response?.statusText,
              });
            });
        } else {
          reject({
            response: errMsg ? error?.response?.data.message : error?.response?.data?.msg,
            statusCode: error?.response?.status,
            statusText: error?.response?.statusText,
          });
        }
      });
  });
}
