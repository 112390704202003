import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setOpenDialog } from "../BetHistory/betHistorySlice";
import { useDispatch } from "react-redux";
import {
  getDateTimeFormate,
  fixedDigit,
  formatMoney,
} from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Table from "../Common/table/Table";
import { betIdHeaderInfo, betIdHeaders } from "../Common/data/BettingHistory/BettinghistoryData";
const BetId = (props) => {
  const { openDialog, setBack, potentialPayout, ...rest } = props;
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState([]);
  const onDialogClose = () => {
    setBack && dispatch(setBack([]));
    dispatch(setOpenDialog(false));
  };
  const updateDataToDialog = () => {
    const tBody =
      props.data !== undefined &&
        props.data !== null &&
        Object.keys(props.data).length > 0 &&
        Array.isArray(props.data) &&
        props.data.length > 0
        ? props.data.map((item) => [
          item.betSelectionId,
          item.fixtureName,
          getDateTimeFormate(item.fixtureStartDate),
          item.fixtureStatus,
          item.leagueName,
          item.marketName,
          fixedDigit(item.odds, 2),
          item.sportName,
          item.selectionName,
          item.betSelectionStatus,
          item.score,
        ])
        : [];
    const tableData = [betIdHeaders, betIdHeaderInfo, ...tBody];
    setTableData(tableData);
  };
  useEffect(() => {
    let timer = null;
    if (props.data.length > 0) {
      updateDataToDialog();
    } else {
      timer = setTimeout(() => setTableData([]), 100);
    }

    return () => clearTimeout(timer);
  }, [props.data]);
  return (
    <>
      <Dialog__Main
        title={"Bet Selections"}
        dOpen={openDialog}
        dClose={() => onDialogClose()}
        width={"xl"}
      >
        <Table tableData={tableData} hLen={4} vLen={2} />
        <div className="col-4 ml-350">
          <div className="CMS-alert alert-primary">
            <div className="row">
              <div className="col-6 vh_laign_center alert_conetnet">
                Potential Payout
              </div>
              <div className="col-6 vh_laign_center">
                <b>{potentialPayout}</b>
              </div>
            </div>
          </div>
        </div>
      </Dialog__Main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    openDialog: state.betHistorySliceReducer.openDialog,
  };
}
export default connect(mapStateToProps)(BetId);
