import downloadCsv from 'download-csv';

export function downlaodCsvFile(rawHeaders, rawData, fileName) {

    let headers = {};
    rawHeaders.map(key => {
        headers = { ...headers, [key]: key }
    });


    let bodyData = rawData.map(item => {
        let itemObject = {};
        for (let key in item) {
            itemObject = { ...itemObject, [rawHeaders[key]]: item[key] }
        }
        // bodyData.push(item)
        return itemObject;
    })

    downloadCsv(bodyData, headers, fileName);
}