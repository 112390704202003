import React, { useState } from "react";
import { exportPdf } from "../../sharedfiles/jsPdf";
import {
  formatMoney,
  Currency,
  Country,
} from "../../sharedfiles/helper";
import ReportsTable from "../Common/table/ReportsTable";
const SportsTable = (props) => {
  const { dates } = props;
  const [fileType, setFileType] = useState("");

  const overAllValues = () => {
    const isHavingData = Array.isArray(props?.sportData?.sportsOverview) && props?.sportData?.sportsOverview.length > 0 ? true : false;
    if (isHavingData) {
      let totalBets = 0;
      let totalStake = 0;
      let totalProfit = 0;
      let totalMargin = 0;
      props.sportData.sportsOverview.map((item, index) => {
        totalBets += item.bets;
        totalStake += item.cashStake;
        totalProfit += item.profit;
        totalMargin += item.margin;
      });
      return [
        "",
        "Total",
        totalBets,
        formatMoney(totalStake),
        formatMoney(totalProfit),
        totalMargin?.toFixed(2),
      ];
    } else {
      return [];
    }
  };
  const headers = [
    [
      "Sport",
      "Customers",
      "Bets",
      `Cash Staked ${Currency()}`,
      `Profit ${Currency()}`,
      "Margin",
    ],
  ];
  const data =
    Object.keys(props?.sportData).length > 0 &&
    Array.isArray(props?.sportData?.sportsOverview) &&
    props?.sportData?.sportsOverview.length > 0 &&
    props?.sportData?.sportsOverview?.map((item) => [
      item.betSport,
      item.customers,
      item.bets,
      formatMoney(item?.cashStake),
      formatMoney(item?.profit),
      item.margin,
    ]);

  const csvData =
    data && data.length > 0
      ? [...headers, ...data, overAllValues()]
      : [];

  const title = props.reportPage;
  const subtitle = `${Country()}`;
  const searchDate = {
    fromDate: dates.dateFrom,
    toDate: dates.dateTo,
  };

  const body =
    Object.keys(props?.sportData).length > 0 &&
    Array.isArray(props?.sportData?.sportsOverview) &&
    props?.sportData?.sportsOverview.length > 0
      ? [...data, overAllValues()]
      : [];
  const styles = {
    3: {
      valign: "middle",
      halign: "right",
    },
    4: {
      valign: "middle",
      halign: "right",
    },
  };
  const firstTable = {
    subtitle,
    headers,
    body,
    styles,
    alignments: ["3", "4"],
  };

  const tableData = [firstTable];
  const printPdf = () => {
    exportPdf(title, searchDate, tableData, styles);
  };

  return (
    <>
      <ReportsTable
        tableData={tableData}
        fileType={fileType}
        setFileType={(value) => setFileType(value)}
        csvData={csvData}
        fileName={title}
        printPdf={() => printPdf()}
      />
    </>
  );
};

export default SportsTable;
