import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { generateKey } from "../../sharedfiles/helper";
import Skelton from "../Common/skelton/Skelton";
import { COMMON_CONSTANTS, FRAUDSCRUB_CONSTANTS } from "../../sharedfiles/Constants";
export const MaxipDialog = (props) => {

  return (
    <>
      <Dialog open={props.openMaxipDialog}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
            width: "100%",
          }}
        >
          <DialogTitle style={{ width: "550px" }}>
            <div className='d-flex justify-content-between align-items-center'>
              <ArrowCircleLeftIcon
                onClick={() => props.handleRevertBack()}
                fontSize='large'
                sx={{
                  color: "#00b3ff",
                  "& > :hover": {
                    color: "#2bc0ff",
                    cursor: "pointer",
                  },
                }}
              />
              <div className='d-flex justify-content-between align-items-center'>
                <span>{FRAUDSCRUB_CONSTANTS.th_ip} - {props.ip.ip}</span>
                <span style={{ marginLeft: "50px" }}>
                  {FRAUDSCRUB_CONSTANTS.count} - {props.ip.count}
                </span>
              </div>
            </div>
          </DialogTitle>
          <IconButton
            style={{ color: "white" }}
            key='close'
            arial-label='close'
            onClick={() => props.handleRevertBack()}
          >
            x
          </IconButton>
        </div>
        <DialogContent>
          {props.fraudUsers &&
            props.fraudUsers !== undefined &&
            Array.isArray(props.fraudUsers) &&
            props.fraudUsers.length > 0 ?
            <div className="CMS-box">
              <div
                className='CMS-table CMS-table-triped'
                style={{ maxHeight: "300px" }}
              >
                <table className='text-center'>
                  <thead>
                    <tr>
                      <th>{COMMON_CONSTANTS.header_cid}</th>
                      <th>{COMMON_CONSTANTS.header_userName}</th>
                      <th>{COMMON_CONSTANTS.header_firstname}</th>
                      <th>{COMMON_CONSTANTS.header_lastname}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.fraudUsers &&
                      props.fraudUsers !== undefined &&
                      Array.isArray(props.fraudUsers) &&
                      props.fraudUsers.length > 0 ? (
                      props.fraudUsers.map((item, ind) => (
                        <tr key={generateKey(ind)}>
                          <td>{item.userId}</td>
                          <td>{item.userName}</td>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className='CMS-msgBox'>
                        <td className='CMS-msgBox-container text-center' colSpan={4}>No results found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div> : <Skelton hLen={4} vLen={3} />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MaxipDialog;
