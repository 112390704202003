import React, { useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ConfirmPaymentModal from "./confirmPaymentModal";
import { useDispatch } from "react-redux";
import { updatePendingWithdraw } from "../PendingWithrawals/pendingWithrawalSlice";
import { updatePendingDeposit } from "../PendingDeposits/pendingDepositSlice";
import { formatMoney } from "../../sharedfiles/helper";
import { style } from "@mui/system";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
const PaymentMainModal = (props) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [emptyFeildError, setEmptyFeildError] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();

  const handlePopUpOpen = (e) => {
    const paymentType = props.paymentType.toUpperCase();
    userComment == "" && setEmptyFeildError(true);
    if (paymentType === "DECLINE" && userComment === "") {
      setEmptyFeildError(true);
    }
    if (userComment !== "") {
      setConfirmationModal(!confirmationModal);
      if (paymentType === "DECLINE") {
        setStatus("DECLINED");
      } else {
        setStatus("CONFIRMED");
      }
    }
  };

  // const updateRecord = (e) => {
  //   let params = {
  //     id: props.data.id,
  //     userId: props.data.userId,
  //     comment: props.data.paymentDesc,
  //     status: e != "decline" ? "APPROVED" : "DECLINED",
  //   };
  //   if (props.data !== null && props.type === "withrawal") {
  //     console.log("withrawal");
  //     dispatch(updatePendingWithdraw(params));
  //     //   handlePopUpClose();
  //   } else {
  //     // let params = { message: userComment, status: status, id: props.data.id };
  //     // dispatch(updatePendingDeposit(params));
  //     // let item = {
  //     //   amount: 0,
  //     //   paymentType: "DEPOSIT",
  //     //   userId: props.data.userId,
  //     //   status: status,
  //     // };
  //     // props.makemanual(item)
  //   }
  // };

  const handleComment = (e) => {
    if (e.target.value === "") {
      setEmptyFeildError(true);
    } else {
      setUserComment(e.target.value);
      setEmptyFeildError(false);
      props.handleFeeback(e);
    }
  };
  // useEffect(() => {
  //   if (props.submitForm) {
  //     let params = {
  //       amount: props.data.amount,
  //       message: userComment,
  //       status: status,
  //       id: props.data.id,
  //     };
  //     dispatch(updatePendingDeposit(params));
  //     let item = {
  //       amount: 0,
  //       paymentType: "DEPOSIT",
  //       userId: props.data.userId,
  //       status: status,
  //     };
  //   }
  // }, [props.submitForm]);

  return (
    <>
      {confirmationModal ? (
        <ConfirmPaymentModal
          popUp={confirmationModal}
          handlePopUpOpen={handlePopUpOpen}
          handlePopUpClose={props.handlePopUpClose}
          modalTitle='Are you sure you want to continue?'
          data={props.data}
          message={userComment}
          status={status}
          type={props.type}
          paymentType={props.paymentType}
        // updateRecord={props.updateRecord ? props.updateRecord : updateRecord}
        />
      ) : null}
      <Dialog open={props.popUp} onClose={() => props.handlePopUpClose()}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            backgroundColor: "#192239",
            color: "#fff",
          }}
        >
          <DialogTitle>{props.modalTitle}</DialogTitle>
          <IconButton
            style={{ color: "white" }}
            key='close'
            arial-label='close'
            onClick={() => props.handlePopUpClose()}
          >
            x
          </IconButton>
        </div>
        <DialogContent>
          <div className='CMS-msgBox'>
            <div className='CMS-msgBox-container'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>{PAYMENT_CONSTANTS.th_txnId}</th>
                      {/* {props.price !== undefined ? <th>Amount</th> : null} */}
                      <th>{PAYMENT_CONSTANTS.th_amount}</th>
                      <th>{PAYMENT_CONSTANTS.th_comments}</th>

                      <th>{KYC_CONSTANTS.header_action}</th>
                      {/* {props.price === undefined ? <th></th> : null} */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props.transactionId}</td>
                      {/* {props.price !== undefined ? (
                        <td>{props.price}</td>
                      ) : null} */}
                      <td>
                        {formatMoney(props?.price)}
                        {/* {props.price} */}
                      </td>
                      <td>
                        <input
                          type='text'
                          name='notes'
                          value={props.modelFeedback}
                          placeholder='Mention your comments here'
                          onChange={(e) => handleComment(e)}
                        />
                        {emptyFeildError ? (
                          <p style={{ color: "red" }}>
                            {PAYMENT_CONSTANTS.comment_ErrMsg}
                          </p>
                        ) : null}
                      </td>
                      {props.price !== undefined ? (
                        <td>
                          {/* <button onClick={() => handlePopUpOpen("CONFIRMED")}>
                          <p
                            style={{
                              backgroundColor: "#36B37E",
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            Accept
                          </p>
                        </button>
                        <div className='mb-10'></div> */}
                          <button
                            onClick={() => handlePopUpOpen()}

                            className={
                              props.paymentType === "Accept"
                                ? "CMS-btnStatus CMS-statusActive pointer"
                                : "CMS-btnStatus CMS-statusClosed pointer"

                            }
                          >
                            {props.paymentType}
                          </button>
                        </td>
                      ) : (
                        <td>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "grid",
                            }}
                          >
                            <button
                              onClick={() => handlePopUpOpen("DECLINED")}
                              className='CMS-btnStatus CMS-statusClosed'
                            >
                              {COMMON_CONSTANTS.okBtn}
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </DialogContent>
        {/* <div className="CMS-formGroup">
                <div className="CMS-formLabel">Notes</div>
                <div className="CMS-formControl">
                <input
                    type="text"
                    name="notes"
                    value={this.state.modelFeedback}
                    placeholder="notes"
                    onChange={(e) => this.handleFeeback(e)}
                />
                </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="CMS-btnContainer">
                <button
                    onClick={(e) => this.handlePopUpClose()}
                    className="CMS-btn CMS-btnSecondary active CMS-btnMedium"
                    type="submit"
                >
                    Ok 
                </button>
                <button
                    className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                    type="button"
                    onClick={(e) => this.handlePopUpClose()}
                >
                    Cancel
                </button>
                </div>
            </div> */}
      </Dialog>
    </>
  );
};

export default PaymentMainModal;
