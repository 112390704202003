import React from "react";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import AccessDenied from "./Denied.jpg"
export const Forbidden = (props) => {
    const confirmLogout = () => {
        localStorage.clear();
        // props.history.push("/login");
        window.location.pathname = "/login";
    }

    return (
        <>
            <Dialog__Main title={"Access Denied - Forbidden"} dOpen={true} closable={false}>
                <div className="CMS-msgBox CMS-error"
                    style={{
                        fontSize: "18px",
                    }}>
                    <div className="CMS-msgBox-container">
                        <img src={AccessDenied} style={{ width: '250px', height: '250px' }} alt="Access Denied" />
                        <div>
                            <b>You Don't have the required permission to perform the selected operation !</b>
                        </div>
                    </div>
                </div>
                <div className='CMS-btnContainer mb-10 mt-10'>
                    <button
                        className='CMS-btn CMS-btnTertiary active CMS-btnMedium'
                        onClick={() => confirmLogout()}
                    >
                        OK
                    </button>
                </div>
            </Dialog__Main>
        </>
    );
};

export default Forbidden