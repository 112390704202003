import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


const VipRepo = () => {
    const { reportName } = useParams();
    const vipBiReportData = useSelector((p) => p.bireportSlice.vipBiReportData)


    const matchDashboardReport = vipBiReportData[0]?.Reports?.filter(
        (report) => report.name.toLowerCase().replaceAll(" ", "-") === reportName
    )

    let loadDashboardUrl = ""
    if (matchDashboardReport[0].url) {
        loadDashboardUrl = matchDashboardReport[0].url
    } else {
        console.log("not found");
    }

    return (
        <div>
            <div className='mt-20'></div>
            <iframe title="BackOffice-DailySummary" width={"100%"} height={"800px"}
                src={loadDashboardUrl}
                frameborder="0" allowFullScreen="true"></iframe>
        </div>
    )
}

export default VipRepo