import jsPDF from "jspdf";
import "jspdf-autotable";
import { font } from "./DejaVuSans-Bold-bold";
import { ImageTwo } from "./pdfImages";
export const exportPDFC = (title, headers, data, styles, customHeader, size = "A4") => {
  const unit = "pt";
  const orientation = "landscape";
  const tableSize = headers[0].length;
  // const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  const totalWidth = doc.internal.pageSize.getWidth();
  // const totalHeight = doc.internal.pageSize.getHeight();
  doc.setFontSize(25);
  doc.setFont("helvetica");
  const titleHead = title.toUpperCase();
  const xOffset =
    doc.internal.pageSize.width / 2 -
    (doc.getStringUnitWidth(titleHead) * doc.internal.getFontSize()) / 2;
  // const lineWidth = (input) => doc.getStringUnitWidth(input) * 25 + xOffset;
  const fontRupee = font;
  doc.addFileToVFS("DejaVuSans-Bold-bold.ttf", fontRupee);
  doc.addFont("DejaVuSans-Bold-bold.ttf", "DejaVuSans-Bold", "normal");
  doc.setFont("DejaVuSans-Bold");
  doc.addImage(ImageTwo, "JPEG", 0, 0, totalWidth, 85);
  doc.setFont("Helvetica", "bold");
  doc.setTextColor(255, 255, 255);
  doc.text(titleHead, xOffset, 50);
  doc.setLineWidth(1.5);
  // doc.line(xOffset, 55, lineWidth(titleHead), 55);
  const getFontSize = () => {
    let tfFont = 0;
    tableSize === 3 && (tfFont = 10);
    tableSize === 4 && (tfFont = 9);
    tableSize === 5 && (tfFont = 8);
    tableSize === 6 && (tfFont = 7);
    tableSize === 7 && (tfFont = 6);
    tableSize === 8 && (tfFont = 5);
    tableSize === 9 && (tfFont = 5);
    tableSize > 9 && (tfFont = 4);
    return tfFont;
  };
  const defaultColumnStyles = {
    halign: "center",
    valign: "middle",
  };
  let content = {
    startY: 93,
    head: headers,
    body: data,
    setFont: "DejaVuSans-Bold",
    theme: "striped",
    tableWidth: "auto",
    pageBreak: "auto",
    tableLineColor: [210, 227, 234],
    tableLineWidth: 0.75,
    addFont: "DejaVuSans-Bold",
    styles: {
      font: "DejaVuSans-Bold",
    },
    autoResize: true,
    headStyles: {
      halign: "center",
      fontSize: customHeader ? getFontSize() + customHeader : getFontSize() + 1,
      valign: "middle",
      fillColor: [141, 53, 150],
      textColor: [210, 227, 234],
      tableLineColor: [210, 227, 234],
      tableLineWidth: 0.75,
      // 6:{
      //   halign:"right",
      // },
      // 10:{
      //   halign:"right",
      // },
    },
    bodyStyles: {
      // 6:{
      //     halign:"right",
      //   },
      //   10:{
      //     halign:"right",
      //   },
      halign: "center",
      valign: "middle",
      fontSize: customHeader ? getFontSize() + customHeader : getFontSize(),
    },
    columnStyles: styles ? styles : defaultColumnStyles,
    // columnStyles: getStyles(),
    // alternateRowStyles: { fillColor: [211, 110, 255] },
  };

  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};
