import React from "react";
import { connect } from "react-redux";
const Loading = (props) => {

  return (
    <div className='d-flex justify-content-center'>
      {props.isNoData && 
        <div className='CMS-msgBox'>
          <div className='CMS-msgBox-container'>
            No Data Found
          </div>
        </div> 
      }
      {props.isLoader && (
        <div className='lds-roller'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    totalRevenue: state.dashboard.totalRevenue,
  };
}
export default connect(mapStateToProps)(Loading);
