import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setPaginationSecondValue,
  setPaginationFirstValue,
  setRecordsShow,
  setIsVisible,
  getBonusTableList,
} from "./BonusSlice.js";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Table from "../Common/table/Table";

const Bonus = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [payload, setPayload] = useState({});
  const id = props.userId ? props.userId : localStorage.getItem("userId");

  const callApi = (pageNumber, itemsPerPage) => {
    dispatch(setIsVisible(false));
    dispatch(getBonusTableList(pageNumber - 1, itemsPerPage, localStorage.getItem("userId")));
    setCsvData([]);
  };

  const headers = [
    [
      "ID",
      "Bonus Type",
      "Bonus Code",
      "Locked Bonus",
      "Unlocked Bonus",
      "Rollover Times",
      "Bonus Criteria",
      "Max Bonus Winning Amount",
      "Remaining Bonus Winning Amount ",
      "Max Winning Amount Used",
      "Max win Percentage",
      "Bonus Status",
      "RC Applicable Type",
      "RC Odds Threshold",
      "Added Date",
      "Expiry Date",
      "Updated Date",
      "Created By",
    ],
  ];

  const info = [
    "Showing ID",
    "Showing the Bonus Type",
    "Showing Bonus Code",
    "Showing Locked Bonus",
    "Showing Unlocked Bonus",
    "Showing Rollover Times",
    "Showing Bonus Criteria",
    "Showing Max Bonus Winning Amount",
    "Showing Remaining Bonus Winning Amount ",
    "Showing Max Winning Amount Used",
    "Showing Max win Percentage",
    "Showing Bonus Status",
    "Showing Release Criteria Applicable Type",
    "Showing Rrlease Criteria Odds Threshold",
    "Showing Added Date",
    "Showing Expiry Date",
    "Showing Updated Date",
    "Showing Created By",
  ]

  const exportPDF = () => {
    const title = "Bonus Report";

    exportPDFC(title, headers, pdfBody);
  };

  useEffect(() => {
    updateTableData();
  }, [props.bonusData]);

  const updateTableData = () => {
    if (Object.keys(props.bonusData).length > 0) {
      const data = props.bonusData &&
        props.bonusData !== undefined &&
        Object.keys(props.bonusData).length > 0 &&
        props.bonusData.data &&
        Array.isArray(props.bonusData.data) &&
        props.bonusData.data.map((p) => [
          p.userBonusId,
          p.bonusType,
          p.bonusCode,
          p.lockedBonus,
          p.unlockedBonus,
          p.rollOverMultiplier,
          p.bonusCriteria,
          p.maxWinAmount,
          p.maxWinAmountRemaining,
          p.maxWinAmountUsed,
          p.maxWinPercentage,
          p.status,
          p.unlockApplicableType,
          p.unlockOddsThreshold,
          p.addedDate ? getDateTimeFormate(p.addedDate) : "-",
          p.bonusExpiry ? getDateTimeFormate(p.bonusExpiry) : "-",
          p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
          p.addedBy,
        ]);
      const updatedData = data && data.length > 0 ? [headers[0], info, ...data] : [headers[0], info, []]
      setCsvData(updatedData);
      setPdfBody(data);
    }
  }


  useEffect(() => {
    let mounting = true;

    if (mounting) {
      const paylaod = {
        pageNumber: props.activePages,
        itemsPerPage: props.paginationSecondValue,
      };
      setPayload(paylaod);
      callApi(1, 25);
    }
    return () => mounting = false;
  }, []);

  useEffect(() => {
    let mounting = true;
    const id = location.pathname.split("/")[2];
    const isToCall = props.userId !== id
    if (mounting && isToCall) {
      // callApi(1, 25);
      dispatch(getBonusTableList(0, 25, id))
    }
    return () => mounting = false;
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-bonuses'>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={props.bonusData?.data}
                  totalRecords={props.bonusData.totalRecords}
                  paginationFirstValue={props.paginationFirstValue}
                  paginationSecondValue={props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={csvData}
                  exportPDF={exportPDF}
                  callApi={callApi}
                  csvFileName={"Bonus Report"}
                  payloadData={payload}
                />
              </div>
              <Table tableData={csvData} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    bonusData: state.bonusSlice.bonusData,
    activePages: state.bonusSlice.activePages,
    paginationFirstValue: state.bonusSlice.paginationFirstValue,
    paginationSecondValue: state.bonusSlice.paginationSecondValue,
    userId: state.playersearch.userId,
    recordsShow: state.bonusSlice.recordsShow,
    isVisible: state.bonusSlice.isVisible,
  };
}
export default connect(mapStateToProps)(Bonus);
