import React, { Component } from "react";
import Highcharts from "highcharts";
import {
  getDateFormate,
  Currency,
} from "../../sharedfiles/helper";
class StackVsRevnueChart extends Component {
  componentDidMount() {
    const isClear = this.props?.data && Array.isArray(this.props.data) && this.props.data?.length > 0 ? true : false;
    if (isClear) {
      this.loadGraph();
    }
  }

  loadGraph() {
    let stack = [];
    let revenue = [];
    let dates = [];
    let arrayData = [...this.props.data];
    if (this.props.data) {
      let sortedData = arrayData.sort(
        (a, b) =>
          new Date(...a.date.split("/").reverse()) -
          new Date(...b.date.split("/").reverse())
      );
      for (var item of sortedData) {
        if (!dates.includes(item.date)) {
          stack.push(item.turnOver / 100);
          revenue.push(item.ggr / 100);
          dates.push(getDateFormate(item.date));
        }
      }
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    Highcharts.chart("stackvsrevnue", {
      title: {
        text: "",
      },
      lang: {
        thousandsSep: ",",
      },
      credits: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: "",
        },
        tickAmount: 10,
      },

      xAxis: {
        type: "datetime",
        categories: dates,
        dateTimeLabelFormats: {
          day: "%e. %b",
        },
      },
      legend: {
        enabled: true,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          // pointStart: Date.UTC(2021, 6, 1),
          // pointInterval: 24 * 3600 * 1000 * 1
        },
      },

      series: [
        {
          color: "#FF0000",
          name: "Cash Stakes",
          data: stack,
          dashStyle: "dot",
          tooltip: {
            valuePrefix: Currency(),
            valueDecimals: 2,
          },
        },
        {
          color: "#2F5497",
          name: "Cash Revenue",
          data: revenue,
          dashStyle: "dot",
          tooltip: {
            valuePrefix: Currency(),
            valueDecimals: 2,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
        ],
      },
    });
  }
  render() {
    return <div id='stackvsrevnue'></div>;
  }
}

export default StackVsRevnueChart;
