import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  generateKey,
  getDateTimeFormate,
  setToLocalStorage,
  formatMoney,
} from "../../sharedfiles/helper";
import Skelton from "../Common/skelton/Skelton";
import { tabManager } from "../sidebar/sidebarSlice";
import { AFFILIATE_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { CampaignInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
function CampaignTable(props) {
  const { data, viewTable } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const tabStack = useSelector((st) => st.sidebar.tabStack);
  const navigateToURL = (campaignId) => {
    setToLocalStorage("campaignId", campaignId);
    dispatch(tabManager("/campaignUrls", tabStack));
    history.push("/campaignUrls");
  };

  const headers = [
    "Id",
    "Campaign Name",
    "Created Date",
    "Updated Date",
    "View Campaign",
    "Total Registrations",
    "Deposited Customers",
    "Total Deposits",
    "New Customers",
    "Total Stakes",
    "Total Payout",
    "GGR",
    "Total Bonus Amount",
    "Total Number of Bets",
    "Total Withdrawals",
    "URL Hits Count",
  ]
  return viewTable ? (

    <React.Fragment>
      <div className='CMS-box'>
        <div className='CMS-table CMS-table-triped'>
          <table>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{item}</p>
                      <Tooltips info={`${CampaignInfo[index]}`} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data && Array.isArray(data) && data.length > 0 ? (
                data.map((item) => {
                  return (
                    <tr key={generateKey(item.id)}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                      <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                      <td>
                        <button
                          className='CMS-btn CMS-btnSecondary active CMS-btnSmall pointer'
                          onClick={() => navigateToURL(item.id)}
                        >
                          View
                        </button>
                      </td>
                      <td>{item.totalRegistration}</td>
                      <td>{item.depositedCustomers}</td>
                      <td>{item.totalDeposit ? formatMoney(item.totalDeposit) : "0"}</td>
                      <td>{item.newCustomers}</td>
                      <td>{item.totalStake ? formatMoney(item.totalStake) : "0"}</td>
                      <td>{item.totalPayout ? formatMoney(item.totalPayout) : "0"}</td>
                      <td>{item.totalGgr ? formatMoney(item.totalGgr) : "0"}</td>
                      <td>{item.totalBonus ? item.totalBonus : "0"}</td>
                      <td>{item.betCount}</td>
                      <td>{item.totalWithdraw ? formatMoney(item.totalWithdraw) : "0"}</td>
                      <td>{item.campaignClickCount}</td>
                    </tr>
                  );
                })
              ) : (
                <No__Data__Found colspan={15} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Skelton />
  );
}

export default CampaignTable;
