import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../CreateUser/createUserSlice";
import { changeNewPassword } from "../../Login/loginSlice";
import MultiSelectDDL from "../../../sharedfiles/MultiSelectDDL";
import { getAssignRole } from "../../AdminUser/adminuserslice";
import Tooltips from '../Tooltip';
import { Admin } from '../../../sharedfiles/tooltipMsgs';

const formFieldData = [
    {
        id: 1,
        label: "First Name",
        name: "firstName",
        placeholder: "First Name",
        errorMsg: "First Name cannot be empty",
        regex: "",
        info: "Need to give Users First Name"
    },
    {
        id: 2,
        label: "Last Name",
        name: "lastName",
        placeholder: "Last Name",
        errorMsg: "Last Name cannot be empty",
        regex: "",
        info: "Need to give Users Last Name"
    },
    {
        id: 3,
        label: "New Password",
        name: "password",
        placeholder: "Password",
        errorMsg: "Password cannot be empty",
        regex: "",
        info: "Need to set a new password "
    },
]




function EditUser(props) {
    const { open, close, userDetails, rolesRequired, page } = props;
    const dispatch = useDispatch();
    const selectedUserDetails = useSelector(state => state.createuserSlice.selectedUser);
    const permissionToEditUserDetails = useSelector(state => state.dashboard.permissionToEditUserDetails);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        password: "",
    })
    const [formErrors, setFormErrors] = useState({
        firstName: "",
        lastName: "",
        password: "",
    })
    const [totpSwitch, setTotpSwitch] = useState(false);
    const [forcePinChange, setForcePinChange] = useState(false);
    const [ids, setIds] = useState([]);
    const [selected, setSelected] = useState([]);
    const [clearAll, setClearAll] = useState(false);
    const [permissionError, setPermissionError] = useState("");
    const [existingRoles, setExistingRoles] = useState([]);
    const [totpState, setTotpState] = useState("");
    useEffect(() => {
        let mounting = true;

        if (mounting && rolesRequired) {

            if (ids.length === 0) {
                setPermissionError("Please select a proper Role")
            } else {
                setPermissionError("");
            }
        }
        return () => mounting = false;
    }, [ids])


    function formSetter(name, value) {
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    function errorSetter(name, errorMsg) {
        setFormErrors((prevState) => {
            return {
                ...prevState,
                [name]: errorMsg
            }
        })
    }

    function validator(name, value) {
        let err = true;
        if (value === "") {
            let errName = name === "firstName" ? "First Name" : name === "lastName" ? "Last Name" : name;
            errorSetter(name, `${errName} cannot be empty`);
            err = false
        } else {
            errorSetter(name, "");
            err = true;
        }
        if (name === "password") {
            if (value === "") {
                errorSetter(name, "");
                err = true;
            }
            else if (value.length <= 7) {
                err = false;
                errorSetter(name, "Must be atleast 8 chanracters!")
            }
            else if (!value.match(/^(?=.*?[A-Z])/)) {
                err = false;
                errorSetter(name, "Must contain atleast 1 Letter in Capital Case!")
            }
            else if (!value.match(/^(?=.*?[a-z])/)) {
                err = false;
                errorSetter(name, "Must contain atleast 1 Letter in Small Case!")
            }
            else if (!value.match(/^(?=.*?[0-9])/)) {
                err = false;
                errorSetter(name, "Must contain atleast 1 number")
            }
            else if (!value.match(/^(?=.*?[-_@#$%^&*.])/)) {
                err = false;
                errorSetter(name, "Must contain atleast 1 Special Character!")
            } else {
                err = true;
            }
        }
        return err;

    }
    const handleFormData = (e) => {
        const { name, value } = e.target;
        e.preventDefault();

        if (["firstName", "lastName"].includes(name)) {
            if ((/^[a-zA-Z]*$/).test(value)) {
                formSetter(name, value);
                validator(name, value);
            }
        } else {
            if (!(/\s/.test(value))) {
                formSetter(name, value);
                validator(name, value);
            }
        }

    }

    function checkRolesDiff() {
        let driver = false;
        if (ids.length > 0) driver = true;
        if (existingRoles.length === ids.length && existingRoles?.every(role => ids.includes(role))) driver = false;
        return driver;
    }

    function submitForm(e) {
        e.preventDefault();
        const errs = Object.entries(formData).map(([name, value]) => validator(name, value));
        const clearTOGo = permissionToEditUserDetails ? errs.every(err => err === true) : true;
        const isToDisable = totpState === "Disable";
        const userDetailsPayload = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            forceChangePin: forcePinChange,
            forceTotp: totpSwitch ? isToDisable ? false : true : selectedUserDetails.forcedTotp,
            enableTotp: totpSwitch ? false : selectedUserDetails.totpEnable,
        }
        const passwordPayload = {
            password: formData.password,
            enableForceChangePin: true
        }
        const rolesPayload = {
            userId: props.userId,
            roles: ids,
        };

        if (clearTOGo) {
            permissionToEditUserDetails && dispatch(updateUserDetails(userDetailsPayload, props.userId, page));
            permissionToEditUserDetails && formData.password !== "" && dispatch(changeNewPassword(passwordPayload, props.userId));

            if (rolesRequired && checkRolesDiff()) {
                dispatch(getAssignRole(rolesPayload, true));
                close(false);
            }
            permissionToEditUserDetails && close(false);
        }
    }

    useEffect(() => {
        let mounting = true;

        if (mounting && rolesRequired) {
            const existingRoles = Array.isArray(userDetails.roles) && userDetails.roles.length > 0 ? userDetails.roles.map(role => role.roleId) : [];
            setExistingRoles(existingRoles);
            setIds(existingRoles);
        }

        return () => mounting = false;
    }, [userDetails])

    useEffect(() => {
        let mounting = true;
        if (mounting) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    firstName: selectedUserDetails.firstName,
                    lastName: selectedUserDetails.lastName,
                }
            });
            // setTotpSwitch(selectedUserDetails.forcedTotp);
            setForcePinChange(selectedUserDetails.forceChangePin);


        }
        if (!selectedUserDetails.forceTotp && !selectedUserDetails.totpEnable) {
            setTotpState("Enable")
        }
        if (!selectedUserDetails.forceTotp && selectedUserDetails.totpEnable) {
            setTotpState("Disable")
        }

        return () => mounting = false;
    }, [selectedUserDetails]);
    return (
        <React.Fragment>
            <div className='CMS-box CMS-box-content custom_box_size'>
                <div className='row'>
                    {permissionToEditUserDetails &&
                        formFieldData.map((item, ind) => {
                            return (
                                <div className="col-12" key={item.id}>
                                    <div className="fields">
                                        <div className="totp_code label">
                                            {item.label}
                                            &nbsp;&nbsp;
                                            <Tooltips info={item.info} />
                                        </div>
                                        <input
                                            type="text"
                                            id="totp_code"
                                            name={item.name}
                                            value={formData[item.name] || ""}
                                            placeholder={item.placeholder}
                                            className="totp_input text"
                                            onChange={handleFormData}
                                        />
                                        <div className="CMS-form-errorMsg err_msg">{formErrors[item.name]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        rolesRequired &&
                        <div className="col-12">
                            <div className="fields">
                                <div className="totp_code label">
                                    Assign Role
                                    &nbsp;&nbsp;
                                    <Tooltips info={Admin.Assign_Role} />
                                </div>
                            </div>
                            <MultiSelectDDL
                                options={props.rolesList || []}
                                setSelected={setSelected || []}
                                setIds={setIds || []}
                                ids={ids || []}
                                selectedOnce={selected || []}
                                clearAll={clearAll || false}
                                setClear={setClearAll}
                                deafaultValues={existingRoles || []}
                                name="roleAssign"
                            />
                            <div className="fields">
                                <div className="CMS-form-errorMsg err_msg">{permissionError}</div>
                            </div>
                        </div>
                    }
                    {permissionToEditUserDetails &&
                        <div className="col-12">
                            <FormGroup>
                                {!selectedUserDetails?.forcedTotp &&
                                    <div>
                                        <Switch
                                            checked={totpSwitch || false}
                                            onChange={() => setTotpSwitch(prev => !prev)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            name="totpSwitch"
                                        />
                                        <span className="fs-16">Do you want to {totpState} TOTP</span>
                                    </div>
                                }
                                <div>
                                    <Switch
                                        checked={forcePinChange || false}
                                        onChange={(e) => setForcePinChange(prev => !prev)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        name="forcePin"
                                        // size="small"
                                        label="Do you want to Force reset Password"
                                    />
                                    <span className="fs-16">
                                        Do you want to Enable Force reset Password
                                    </span>
                                </div>


                            </FormGroup>
                        </div>
                    }
                    <div className="col-12">
                        <div className='totp_buttons'>
                            <button
                                className="totp_btn_enable"
                                type={"button"}
                                onClick={submitForm}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditUser
