import React from 'react';
import { Link } from "react-router-dom";
import { setBiReportData } from './bireportSlice';
import { useDispatch } from 'react-redux';

const BiTable = ({ category, reportName, dashboard, vip }) => {
    const dispatch = useDispatch();
    const reportData = reportName.map((report) => ({ name: report.name, url: report.url }));

    return (
        <div className='CMS-tab-panel active' id='CMS-reports'>
            <div className='mt-20'></div>
            <div className='CMS-box CMS-box-content'>
                <div className='CMS-box__header'>
                    <div className='CMS-box__title ml-680'>
                        {category}
                    </div>
                </div>
                <div className='CMS-tabContent'>
                    <div className='CMS-box'>
                        <div className='CMS-table CMS-table-triped'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Report Name</th>
                                        <th className='details-of-reports'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.name}</td>
                                            <td>
                                                {dashboard ? (
                                                    <Link
                                                        to={`/bidashboard/${data.name.toLowerCase().replaceAll(" ", "-")}`}
                                                        className='a-link'
                                                    >
                                                        <button
                                                            className='CMS-btn CMS-btnDefault CMS-btnSecondary active CMS-btnMedium'
                                                            type='button'
                                                        >
                                                            Details of Report
                                                        </button>
                                                    </Link>
                                                ) : vip ? (
                                                    <Link
                                                        to={`/vip-reports/${data.name.toLowerCase().replaceAll(" ", "-")}`}
                                                        className='a-link'
                                                    >
                                                        <button
                                                            className='CMS-btn CMS-btnDefault CMS-btnSecondary active CMS-btnMedium'
                                                            type='button'
                                                        >
                                                            Details of Report
                                                        </button>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to={`/bireports/${category.toLowerCase().replaceAll(" ", "-")}/${data.name.toLowerCase().replaceAll(" ", "-")}`}
                                                        className='a-link'
                                                    >
                                                        <button
                                                            className='CMS-btn CMS-btnDefault CMS-btnSecondary active CMS-btnMedium'
                                                            type='button'
                                                        >
                                                            Details of Report
                                                        </button>
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiTable;
