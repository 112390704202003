import React from "react";
import { connect, useDispatch } from "react-redux";
import Reports from "../Reports/Reports";
const OperationsReport = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <Reports title={"opTable"} />
    </>
  );
};

export default OperationsReport;
