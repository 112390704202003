import React from "react";
import Skeleton from "@mui/material/Skeleton";
// import Stack from "@mui/material/Stack";
// import Box from "@mui/material/Box";
const Skelton = (props) => {
  const { hLen, vLen, width } = props;
  return (
    <div className="CMS-box" style={{width: width || "100%"}}>
      <div className='CMS-table CMS-table-triped'>
        <table>
          <thead>
            <tr>
              {[...Array(hLen ? hLen : 6)].map((_, i) => (
                <th key={i}>
                  <Skeleton variant='text' animation='wave' height={30} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(vLen ? vLen : 6)].map((_, i) => {
              return (
                <tr key={i}>
                  {[...Array(hLen ? hLen : 6)].map((_, j) => (
                    <td key={j} valign='middle' align='center'>
                      <Skeleton
                        variant='text'
                        animation='wave'
                        width={150}
                        height={25}
                        style={{ margin: "0 auto" }}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Skelton;
