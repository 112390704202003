import React from 'react'

const ReportDetails = () => {
    const brandId = localStorage.getItem('brandId');
    const reportId = localStorage.getItem('reportId');
    const token = localStorage.getItem("token");
    return (
        <div className='hell'><iframe height={"800px"} width={"100%"} src={`https://powerbi-reports.sportsit-tech.net/Home/EmbedReport?reportid=${reportId}&brandid=${brandId}&accessToken=${token}`} /></div>
    )
}

export default ReportDetails