import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { newDashboardApis } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const NewDashboardSlice = createSlice({
    name: "NewDashboardSlice",
    initialState: {
        overallReviewReport: [],
        sportsBetReport: [],
        sportCasinoNeGameData: [],
        bonusingData: [],
        paymentData: [],
        userActivity: [],
        activeTimeData: [],
        topTenWithdrwableUsers: [],
        topWinnerLooserList: [],
        topTenSportsNeGamesCasino: [],
        prevFormData: {},
    },
    reducers: {
        setOverallReviewReport: (state, action) => {
            state.overallReviewReport = action.payload
        },
        setSportsBetReport: (state, action) => {
            state.sportsBetReport = action.payload
        },
        setSportCasinoNeGameData: (state, action) => {
            state.sportCasinoNeGameData = action.payload
        },
        setBonusingData: (state, action) => {
            state.bonusingData = action.payload
        },
        setPaymentData: (state, action) => {
            state.paymentData = action.payload
        },
        setUserActivity: (state, action) => {
            state.userActivity = action.payload
        },
        setActiveTimeData: (state, action) => {
            state.activeTimeData = action.payload
        },
        setTopTenWithdrwableUsers: (state, action) => {
            state.topTenWithdrwableUsers = action.payload
        },
        setTopWinnerLooserList: (state, action) => {
            state.topWinnerLooserList = action.payload
        },
        setTopTenSportsNeGamesCasino: (state, action) => {
            state.topTenSportsNeGamesCasino = action.payload
        },
        setPrevFormData: (state, action) => {
            state.prevFormData = action.payload;
        },
    },
})

export const {
    setOverallReviewReport,
    setSportsBetReport,
    setSportCasinoNeGameData,
    setBonusingData,
    setPaymentData,
    setUserActivity,
    setActiveTimeData,
    setTopTenWithdrwableUsers,
    setTopWinnerLooserList,
    setTopTenSportsNeGamesCasino,
    setPrevFormData
} = NewDashboardSlice.actions;

export const getOverallReviewReportByPeriod = () => (dispatch) => {
    let url = newDashboardApis.getOverallReviewReportByPeriod
    apiCaller(url, "GET").then((res) => {
        if (res.status === 200) {
            dispatch(setOverallReviewReport(res.data))
        }
    })
}

export const getSportsBetsReport = (param) => (dispatch) => {
    let url = newDashboardApis.getSportsBetsReport
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setSportsBetReport(res.data))
        }
    })
}

export const getBetReportByGameType = (param) => (dispatch) => {
    let url = newDashboardApis.getBetReportByGameType
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setSportCasinoNeGameData(res.data))
        }
    })
}

export const getBonusReport = (param) => (dispatch) => {
    let url = newDashboardApis.getBonusReport
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setBonusingData(res.data))
        }
    })
}

export const getPaymnetReport = (param) => (dispatch) => {
    let url = newDashboardApis.getPaymnetReport
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setPaymentData(res.data))
        }
    })
}

export const getUserActivityReport = (param) => (dispatch) => {
    let url = newDashboardApis.getUserActivityReport
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setUserActivity(res.data))
        }
    })
}

export const getUserActiveTimesReportByType = (param, type) => (dispatch) => {
    let url = newDashboardApis.getUserActiveTimesReportByType(type)
    apiCaller(url, "POST", param).then((res) => {
        if (res.status === 200) {
            dispatch(setActiveTimeData(res.data))
        }
    })
}

export const getTopTenWithdrawableUsers = () => (dispatch) => {
    let url = newDashboardApis.getTopTenWithdrawableUsers
    apiCaller(url, "GET").then((res) => {
        if (res.status === 200) {
            dispatch(setTopTenWithdrwableUsers(res.data))
        }
    })
}

export const getTopTenWinnerLooser = () => (dispatch) => {
    let url = newDashboardApis.getTopTenWinnerLooser
    apiCaller(url, "GET").then((res) => {
        if (res.status === 200) {
            dispatch(setTopWinnerLooserList(res.data))
        }
    })
}

export const getTopTenSportsNeGamesCasino = () => (dispatch) => {
    let url = newDashboardApis.getTopSportsGamesReport
    apiCaller(url, "GET").then((res) => {
        if (res.status === 200) {
            dispatch(setTopTenSportsNeGamesCasino(res.data))
        }
    })
}


export default NewDashboardSlice.reducer