import React, { useEffect } from "react";
import Table from "./Table";
import { formatMoney, Currency, roundDecimalnum } from "../../../sharedfiles/helper";
function DS__Table(props) {
  const { title, headers, options, ids, info } = props.cmpData;

  const [csvData, setCsvData] = React.useState([]);
  const view = props.isGameWrox

  function setTableData() {
    const isProfit = title === "Most Profitable Sports" ? true : false;
    const data = props.tableData &&
      Object.keys(props.tableData).length > 0 &&
      props.tableData !== undefined &&
      props.tableData !== null &&
      props.tableData.length > 0 &&
      props.tableData.map((item, ind) => [
        item.betSport == "casinos" ? "Casinos" : item.betSport,
        view ? Currency() + roundDecimalnum(isProfit ? item.profit : item.turnover) : Currency() + formatMoney(isProfit ? item.profit : item.turnover), //₹
        `${item.margin}%`,
      ]);
    const output = props.tableData &&
      props.tableData !== undefined &&
      props.tableData !== null &&
      props.tableData.length > 0 &&
      data.length > 0
      ? [headers, info, ...data]
      : [headers, info, []];
    setCsvData(output);
  }

  function topTepData() {
    const isWon = title === "Top 10 Winners" ? true : false;
    const data =
      props?.tableData && Object.keys(props.tableData) &&
      Object.keys(props.tableData).length > 0 &&
      props.tableData !== undefined &&
      props.tableData !== null &&
      props.tableData.length > 0 &&
      props.tableData.map((item, ind) => [
        item.userName,
        view ? `${Currency()}${roundDecimalnum(isWon ? item.win : item.loss)}` : `${Currency()}${formatMoney(isWon ? item.win : item.loss)}`, //₹
        item.brand === "starpunter" ? "StarPunter" : item.brand,
      ]);
    const output = props.tableData &&
      props.tableData !== undefined &&
      props.tableData !== null &&
      props.tableData.length > 0 &&
      data.length > 0
      ? [headers, info, ...data]
      : [headers, info, []];
    setCsvData(output);
  }

  const callFunc = () => {
    if (title !== undefined && title !== null) {
      if (
        title === "Most Profitable Sports" ||
        title === "Highest Turnover Sports"
      ) {
        setTableData();
      } else {
        topTepData();
      }
    }
  };

  useEffect(() => {
    callFunc();
    callFunc();
  }, [props.tableData]);

  useEffect(() => {
    callFunc();
  }, []);

  return (
    <div className='CMS-box'>
      <div className='CMS-box__header'>
        <div className='CMS-box__title'>{title}</div>
        <div className='CMS-select'>
          <select name={props.name} onChange={(e) => props.handleChange(e)} id={ids} value={props.seasonDropdown} className="pointer">
            {options.map((item, ind) => {
              return (
                <option
                  key={item.id}
                  value={item.value}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {/* <div className='CMS-table CMS-table-triped'> */}
      <Table tableData={csvData} loader={props.loader} styles={props.styles} hLen={2} vLen={6} skeltonWidth={"318px"} />
      {/* </div> */}
    </div>
  );
}

export default DS__Table;
