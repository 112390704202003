import React from 'react'
import { useSelector } from 'react-redux';
import BiTable from '../Reports/BiReports/BiTable';
const BiDashboard = () => {
    const biDashboardData = useSelector((p) => p.bidashboardSlice.biDashboardData)
    return (
        <>
            {biDashboardData.map((report, index) => (
                <BiTable
                    key={index}
                    category={report.ReportCatagory}
                    reportName={report.Reports}
                    dashboard
                />
            ))}
        </>
    )
}

export default BiDashboard