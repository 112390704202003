import React from "react";
import { REPORTS_CONSTANTS } from "../../sharedfiles/Constants";
import Datepicker from "../Common/common_inputs/DatePicker";
import Tooltips from "../Common/Tooltip";

function SearchReport(props) {
  const { reportTitle, dateFrom, dateTo, errors, setValue, submit } = props;
  return (
    <React.Fragment>
      <div className='CMS-box CMS-box-content'>
        <div className='CMS-box__header'>
          <div className='CMS-box__title'>{reportTitle}</div>
        </div>
        <div className='row no-gutters d-flex justify-content-center'>
          <div className='col-md-6 col-lg-4 col-xl-4'>
            <div className='CMS-formGroup formGroup-inline d-flex align-items-start'>
              <div className='col-4'>
                <div className='CMS-formLabel'>
                  {REPORTS_CONSTANTS.report_dateFrom}
                  &nbsp;&nbsp;
                  <Tooltips info={REPORTS_CONSTANTS.report_dateFrom} />
                </div>
              </div>
              <div className='col-8'>
                <Datepicker
                  name={"dateFrom"}
                  value={dateFrom}
                  setValue={(date, name) => setValue(date, name)}
                  maxDate={dateTo || new Date()}
                  inline={true}
                  errorMsg={errors?.dateFrom}
                  ids={"datefrom"}
                // errorMsg={"please select Date From"}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-4 col-xl-4'>
            <div className='CMS-formGroup formGroup-inline d-flex align-items-start'>
              <div className='col-4'>
                <div className='CMS-formLabel'>
                  {REPORTS_CONSTANTS.report_dateTo}
                  &nbsp;&nbsp;
                  <Tooltips info={REPORTS_CONSTANTS.report_dateTo} />
                </div>
              </div>
              <div className='col-8'>
                <Datepicker
                  name={"dateTo"}
                  value={dateTo}
                  setValue={(date, name) => setValue(date, name)}
                  maxDate={new Date()}
                  minDate={dateFrom}
                  inline={true}
                  errorMsg={errors?.dateTo}
                  ids={"dateTo"}
                // errorMsg={"please select Date To"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-20'></div>

        <div className='row'>
          <div className='col-md-12 col-lg-12 col-xl-12'>
            <div className='CMS-btnContainer'>
              <button
                onClick={(e) => submit(e)}
                className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                type='button'
                id="submitbtn"
              >
                {REPORTS_CONSTANTS.report_viewBtn}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchReport;
