/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import {
  getNewPlayer,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setFormData,
  setDocsData
} from "./NewPlayerSlice";
import {
  formatMoney,
  getDateTimeFormate,
  Currency,
  cleanObject,
  hideMobile,
  hideData,
  sortingFilter,
  getUTCDate,
  getFormattedDate,
  MomentUTCDateTime,
  MomentUTCDateTimeToday,
  MomentUTCDateTimeEnd,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Table from "../Common/table/Table";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import { inputFieldsData, DateInputFieldsData, newplayerInfo } from "../Common/data/mapData";
import Input from "../Common/common_inputs/Input";
import Datepicker from "../Common/common_inputs/DatePicker";
import { npSotables, roleData } from "../Common/data/Players/PlayersData";
import { PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
import Select from "../Common/common_inputs/Select_DDL";
class newPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.dropDownClose = React.createRef();
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      customerId: "",
      referCode: "",
      affiliateBTAG: "",
      affiliateName: "",
      FromDate: "",
      ToDate: '',
      country: "",
      registrationdate: "",
      currency: "",
      //brand: "",
      brandsList: [],
      firstdepositAmount: "",
      AccountSatus: "",
      betCount: "",
      data: [],
      open: false,
      pageNumber: this.props.paginationFirstValue,
      document: "",
      errors: ["", "", "", ""],
      sendData: false,
      showPges: false,
      newPlayerData: {},
      viewTable: false,
      payloadData: {},
      totalPages: "",
      itemsperpage: "",
      csvData: [],
      pdfBody: [],
      dateError: "",
      masking: false,
      sortFields: {
        "Registration Date": false,
        "Affiliate Btag": false,
        "Affiliate Name": false,
        "Referred By": false,
        "Brand": false,
        "Country": false,
        [`First Deposit Amount ${Currency()}`]: false,
        "Bet Count": false,
        "Account Status": false,
        key: "",
        direction: "",
        keyType: "",
        role: ''
      }
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.callApi = this.callApi.bind(this);
    // this.apiCall = this.apiCall.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.resetCsvData = this.resetCsvData.bind(this);
    this.newPlayerData = this.newPlayerData.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  onChangeHandler(e) {
    const { name, value } = e.target
    this.setState({ [name]: value });
    this.formDataToSliceSetter(name, value)
  }


  setDateHandler(value, name) {
    this.setState({ [name]: value });
    let payload = this.newPlayerData();
    if (name === "FromDate") {
      payload["registrationDateFrom"] =
        MomentUTCDateTimeToday(value);
      this.setState({ dateError: "" });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": "" });
    } else {
      payload["registrationDateTo"] =
        MomentUTCDateTimeEnd(value);
      const dateErr = this.state.FromDate === "" ? "Registration Date from is required" : "";
      this.setState({ dateError: dateErr, });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": dateErr });
    }
  }

  // brandHandler = (e) => {
  //   this.setState({ brand: e.target.value });
  //   this.state.brandsList.push(e.target.value);
  //   this.setState({ open: false });
  // };

  handleClickDropdown = () => {
    this.setState({ open: !this.state.open });
  };

  headers = [
    [
      "Customer ID",
      "UserName",
      "FirstName",
      "LastName",
      "Role",
      "Registration Date",
      "Affiliate Btag",
      "Affiliate Name",
      "Referred By",
      "Brand",
      "Country",
      `First Deposit Amount ${Currency()}`,
      "Bet Count",
      "Account Status",
    ],
  ];

  tableStyles = {
    10: "text-right",
  };
  exportPDF() {
    const title = "New Player Report";

    exportPDFC(title, this.headers, this.state.pdfBody);
  }

  myDocument(e) {
    this.setState({ document: e.target.value });
  }

  // pagination functions //

  // callApi(pageNumber, itemsPerPage, data) {
  //   this.props.dispatch(setIsVisible(false));
  //   this.props.dispatch(
  //     getNewPlayer(pageNumber - 1, itemsPerPage, data)
  //   );
  // }


  callApi(pageNumber, itemsPerPage, data) {
    const { ...filterData } = data;

    const fromDate = this.state.FromDate ? MomentUTCDateTimeToday(this.state.FromDate) : '';
    const toDate = this.state.ToDate ? MomentUTCDateTimeEnd(this.state.ToDate) : '';

    // Construct the payload including both pagination and filter data
    const payload = {
      ...filterData,
      // registrationDateFrom: fromDate,
      // registrationDateTo: toDate,
    };

    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(
      getNewPlayer(pageNumber - 1, itemsPerPage, payload)
    );
  }


  emptyState() {
    this.setState({
      username: "",
      firstName: "",
      lastName: "",
      customerId: "",
      affiliateBTAG: "",
      affiliateName: "",
      referCode: "",
      country: "",
      registrationFromdate: "",
      registrationTodate: "",
      //brand: "",
      currency: "",
      errors: ["", "", "", "", ""],
      sendData: false,
      showPges: true,
      viewTable: false,
      // payloadData: {},
      itemsperpage: "",
      FromDate: '',
      ToDate: '',
      csvData: [],
      pdfBody: [],
      dateError: "",
      role: ''
    });
    this.setState({ brandsList: [] });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }
  resetButton(e) {
    this.emptyState();
    this.props.dispatch(setFormData({}))
    this.setState({
      payloadData: {},
      sortFields: {
        "Registration Date": false,
        "Affiliate Btag": false,
        "Affiliate Name": false,
        "Referred By": false,
        "Brand": false,
        "Country": false,
        [`First Deposit Amount ${Currency()}`]: false,
        "Bet Count": false,
        "Account Status": false,
        key: "",
        direction: "",
        keyType: ""
      }
    });

    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setRecordsShow([1, 25]));
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(setFormData(new Object()));
    this.props.dispatch(getNewPlayer(0, 25, {}));

  }
  newPlayerData() {
    return {
      userName: this.state.username,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      referralCode: this.state.referCode,
      affiliateBtag: this.state.affiliateBTAG,
      affiliateName: this.state.affiliateName,
      registrationDateFrom:
        this.state.FromDate
          ? MomentUTCDateTimeToday(this.state.FromDate)
          : "",
      registrationDateTo:
        this.state.ToDate
          ? MomentUTCDateTimeEnd(this.state.ToDate)
          : "",
      role: this.state.role,
      // brand: this.state.brand,
      // country: this.state.country,
      // currency: this.state.currency,
    };
  }


  onSubmit(e) {
    if (this.state.ToDate !== "" && this.state.FromDate === "" || this.state.FromDate === null) {
      this.setState({ dateError: "Registration Date from is required" });
      return;
    }
    this.setState({ showPges: true, viewTable: true });
    const payloadData = cleanObject(this.newPlayerData());
    this.setState({ payloadData: payloadData });

    if (Object.keys(payloadData).length > 0) {
      if (!this.state.dateError) {
        const { paginationFirstValue, paginationSecondValue } = this.props;
        this.props.dispatch(setIsVisible(false));

        this.callApi(paginationFirstValue, paginationSecondValue, payloadData);

        this.props.dispatch(setRecordsShow([paginationFirstValue, paginationSecondValue]));
        this.setState({ csvData: [], pdfBody: [] });
      }
    }
  }

  handleClickOutside(e) {
    if (
      this.dropDownClose.current &&
      !this.dropDownClose.current.contains(e.target)
    ) {
      this.setState({ open: false });
    }
  }
  updateTableToDocs(key = "", sortType = "", keyType = "") {
    setTimeout(() => {
      const data = this.props?.newPlayerData &&
        Object.keys(this.props?.newPlayerData).length > 0 &&
        this.props?.newPlayerData?.data &&
        Array.isArray(this.props?.newPlayerData?.data) &&
        sortingFilter(this.props?.newPlayerData?.data, { key, sortType, keyType }).map((p) => [
          p.userId,
          this.state.masking && Number(p.userName) ? hideMobile(p.userName) : p.userName,
          this.state.masking && Number(p.firstName) ? hideMobile(p.firstName) : p.firstName,
          this.state.masking && Number(p.lastName) ? hideMobile(p.lastName) : p.lastName,
          p.role,
          p.registrationDate ? getDateTimeFormate(p.registrationDate) : "-",
          p.affiliateBtag,
          p.affiliateName,
          p.refCode,
          p.brand,
          this.state.masking ? hideData(p.country) : p.country,
          formatMoney(p.firstDeposit),
          p.betCount,
          p.accountStatus,
        ]);
      this.setState({ pdfBody: data });
      const csvData = data.length > 0 ? [this.headers[0], newplayerInfo, ...data] : [this.headers[0], newplayerInfo, []];
      this.setState({
        csvData: csvData

      });
      this.props.dispatch(setDocsData(csvData));
    }, 300);
  }
  resetCsvData() {
    this.setState({
      csvData: [],
      pdfBody: [],
    });
  }
  keyDownHandler(event) {
    if (event.keyCode === 13) {
      this.onSubmit(event);
    }
  }
  getDefaultDateRange = () => {
    const startDate = getFormattedDate(7);
    const endDate = new Date();
    return { startDate, endDate };
  };
  componentDidMount() {
    const { startDate, endDate } = this.getDefaultDateRange();
    const dataExist = Object.keys(this.props?.newPlayerData).length > 0 && this.props?.newPlayerData?.data?.length > 0;
    const systemPayload = {
      registrationDateFrom: MomentUTCDateTimeToday(startDate),
      registrationDateTo: MomentUTCDateTimeEnd(endDate),
    };
    if (!dataExist) {
      this.setState({ FromDate: startDate, ToDate: endDate })
      this.props.dispatch(setFormData({
        ...this.props.formData,
        FromDate: startDate,
        ToDate: endDate,
      }))
      this.callApi(
        this.props.paginationFirstValue,
        this.props.paginationSecondValue,
        systemPayload
      );
    } else {
      const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0;
      this.setState({ FromDate: this.props.formData?.FromDate || '', ToDate: this.props.formData?.ToDate || '' })
      let pdfData = [];
      if (csvDataExist) {
        pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
      }
      this.setState({
        csvData: this.props.docsData,
        pdfBody: pdfData,
      });
    }

    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      this.setState({ masking: true });
    } else {
      this.setState({ masking: false });
    }

    this.stateSetter();
    document.addEventListener("keydown", this.keyDownHandler);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newPlayerData !== this.props.newPlayerData) {
      this.updateTableToDocs();
    }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
    document.removeEventListener('keydown', this.keyDownHandler, false);
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }
  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val }
    const newPayload = multiEntries ? { ...this.props.formData, ...multiEntries } : newEntry
    this.props.dispatch(setFormData(newPayload));
  }


  handleSortFields(item, key) {
    const intializing = {
      "Registration Date": false,
      "Affiliate Btag": false,
      "Affiliate Name": false,
      "Referred By": false,
      "Brand": false,
      "Country": false,
      [`First Deposit Amount ${Currency()}`]: false,
      "Bet Count": false,
      "Account Status": false,
      key: "",
      direction: "",
      keyType: ""
    }
    const direction = !this.state.sortFields[key] ? "ASCENDING" : "DESCENDING";

    this.setState({
      sortFields: {
        ...intializing,
        [key]: !this.state.sortFields[key],
        key: item.key,
        direction: direction,
        keyType: item.type,
      }
    });
    this.updateTableToDocs(item.key, direction, item.type);
  }

  render() {
    return (
      <React.Fragment>
        <div className='CMS-tab-panel active' id='CMS-betting'>
          <div className='CMS-tabContent'>
            <div className='CMS-box CMS-box-content'>
              <div className='row'>

                {inputFieldsData.map((item, ind) => {
                  return (
                    <React.Fragment key={item.id} >
                      <div className='col-3' >
                        <Input
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.onChangeHandler(e)}
                          setKey={(e) => this.handleKeyDown(e)}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
                {DateInputFieldsData.map((item, ind) => {
                  const minDate = [null, this.state.FromDate];
                  const max = this.state.ToDate
                    ? this.state.ToDate
                    : new Date();
                  const maxDate = [max, new Date()];
                  return (
                    <React.Fragment key={item.id}>
                      <div className='col-3' >
                        <Datepicker
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          setValue={(date, value) =>
                            this.setDateHandler(date, value)
                          }
                          errorMsg={ind === 0 ? this.state.dateError : ""}
                          minDate={minDate[ind]}
                          maxDate={maxDate[ind]}
                          ids={item.ids}
                          info={item.info}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className='row'>
                <div className='col-3' >
                  <Select
                    title={"Role"}
                    name={"role"}
                    value={this.state.role}
                    setValue={(val) => this.onChangeHandler(val)}
                    options={roleData}
                    info={PLAYER_MODULE.roles}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"NewplayerRole"}
                  />
                </div>
              </div>
              <div className='mt-20'></div>

              <div className='row'>
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"newPlayersearch"}
                  idreset={"newPlayerreset"}
                  submit={(e) => this.onSubmit(e)}
                  reset={(e) => this.resetButton(e)}
                />
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.newPlayerData.data}
                  totalRecords={this.props.newPlayerData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"New player report"}
                  payloadData={this.state.payloadData}
                  resetCsvData={this.resetCsvData}
                />
              </div>
              <Table
                tableData={this.state.csvData}
                linkIndex={0}
                styles={this.tableStyles}
                sortables={npSotables}
                handleSortFields={(item, key) => this.handleSortFields(item, key)}
                sortingOrder={this.state.sortFields}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    paginationFirstValue: state.newplayer.paginationFirstValue,
    paginationSecondValue: state.newplayer.paginationSecondValue,
    newPlayerData: state.newplayer.newPlayerData,
    activePage: state.newplayer.activePage,
    recordsShow: state.newplayer.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.newplayer.isVisible,
    formData: state.newplayer.formData,
    docsData: state.newplayer.docsData,
  };
}
export default connect(mapStateToProps)(newPlayer);
