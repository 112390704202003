import React from "react";
import { connect } from "react-redux";
import {
  formatMoney,
  cleanObject,
  getDateTimeFormate,
  Currency,
  generateKey,
  hideMobile,
  hideData,
  getFormattedDate,
  MomentUTCDateTimeEnd,
  MomentUTCDateTimeToday,
} from "../../sharedfiles/helper";
import "react-datepicker/dist/react-datepicker.css";
import {
  getPlayerActivity,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  fetchTagsList,
  setIsVisible,
  getTagsList,
  setFormData,
  setDocsData,
  setSegmentValidator,
  setTagTypeError
} from "./playerActivitySlice";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Table from "../Common/table/Table";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import { DateFieldData, plActivityInfo } from "../Common/data/mapData";
import {
  TagType,
  SubType,
  SystemTags,
} from "../Common/data/PlayerActivityData/ActivityData";
import { COMMON_CONSTANTS, TAG_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { COMMON_MSGS, PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
import { roleData } from "../Common/data/Players/PlayersData";

class playerActivity extends React.Component {
  constructor(props) {
    super(props);
    this.brandDropDown = React.createRef();
    this.countryDropDown = React.createRef();
    this.segmentDropDown = React.createRef();

    this.state = {
      countryList: [],
      startDate: "",
      endDate: "",
      playerSegement: "",
      playerSegementList: [],
      open: false,
      countryCondition: false,
      segmentLevel: false,
      itemsperpage: "",
      searchData: {},
      pageNumber: this.props.activePage,
      closeDropdown: false,
      startdateError: false,
      endDateError: false,
      subType: "",
      tagValider: false,
      document: "",
      tagName: "",
      payloadData: {},
      id: "",
      tagType: "",
      hideField: true,
      username: "",
      csvData: [],
      pdfBody: [],
      dateError: "",
      errorsPack: {
        tagType: "",
      },
      masking: "",
      systemTags: "",
      role: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.brandHandler = this.brandHandler.bind(this);
    this.countryHandler = this.countryHandler.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.callApi = this.callApi.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.tagTypeHandle = this.tagTypeHandle.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.resetCsvData = this.resetCsvData.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.activitySearchData = this.activitySearchData.bind(this);
    this.hadleEnterKey = this.hadleEnterKey.bind(this);
    this.formDataToSliceSetterForId = this.formDataToSliceSetterForId.bind(this);
  }

  emptyState() {
    this.setState({
      username: "",
      firstName: "",
      lastName: "",
      country: "",
      countryList: [],
      startDate: '',
      endDate: '',
      brand: "",
      playerSegement: "",
      playerSegementList: [],
      isChecked: false,
      searchData: {},
      open: false,
      countryCondition: false,
      segmentLevel: false,
      segmentValider: false,
      subType: "",
      document: "",
      itemsperpage: "",
      tagName: "",
      // payloadData: {},
      id: "",
      tagType: "",
      csvData: [],
      pdfBody: [],
      dateError: "",
      errorsPack: {
        tagType: "",
      },
      systemTags: "",
      role: ''
    });
  }
  resetButton() {
    this.emptyState();
    this.setState({ payloadData: {} });
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(getPlayerActivity(0, 25, {}));
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.myDocument = this.myDocument.bind(this);
    // this.exportPDF = this.exportPDF.bind(this);
    // this.recordsShow(1, 25);
    this.props.dispatch(setRecordsShow([1, 25]));
    this.props.dispatch(getTagsList([]));
    this.props.dispatch(setFormData({}));
  }

  setDateHandler(value, name) {
    this.setState({ [name]: value });
    // let payload = this.activitySearchData();
    if (name === "startDate") {
      // payload["dateFrom"] = MomentUTCDateTimeToday(value);
      this.setState({ startDate: value, dateError: "" });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": "" });
    } else {
      // payload["dateTo"] = getUTCDate(value, "end");
      const dateErr = this.state.startDate === "" ? "Start Date is required" : ""
      this.setState({
        endDate: value,
        dateError: dateErr,
      });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": dateErr });
    }
  }

  tagTypeHandle(e) {
    const { value } = e.target;
    this.formDataToSliceSetter("tagType", value);
    if (value === "System") {
      this.setState({ hideField: false });
    } else {
      this.setState({ hideField: true });
    }
    this.setState({ tagType: value });
    value !== "" &&
      this.setState({ hideField: true }) &&
      this.setState({ tagValider: false });
    value === "Custom" && this.getTags();
    // this.setState({ subType: value });
    if (value !== "") {
      const msg = { ...this.state.errorsPack, ["tagType"]: "" };
      this.setState({
        errorsPack: msg,
      });
      this.props.dispatch(setTagTypeError(msg));
    } else {
      const msg = {
        ...this.state.errorsPack,
        ["tagType"]: "please select Tag Type",
      }
      this.setState({
        errorsPack: msg,
      });
      this.props.dispatch(setTagTypeError(msg));
    }
  }

  subTypeHandle(e) {
    this.setState({ subType: e.target.value });
    this.formDataToSliceSetter("subType", e.target.value);
  }

  systemTagHandle(e) {
    this.setState({ systemTags: e.target.value })
    this.formDataToSliceSetter("systemTags", e.target.value);
    // this.setState({ segmentValider: false })
  }

  myDocument(e) {
    this.setState({ document: e.target.value });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.formDataToSliceSetter(name, value);
  };

  brandHandler(e) {
    this.setState({ brand: e.target.value });
    this.setState({ open: false });
  }

  handleDropdown = () => {
    this.setState({ open: !this.state.open });
  };
  headers = [
    [
      "Customer Id",
      "User Name",
      "Role",
      "Brand",
      "Country",
      "Currency",
      "Registration date",
      `Turnover ${Currency()}`,
      "GGR",
      "Margin %",
      "Bet Count",
      `Average Bet Stake ${Currency()}`,
      "Segment",
      "Age Of Account",
    ],
  ];
  tableStyles = {
    10: "text-right",
    6: "text-right",
  };

  exportPDF() {
    const title = "Player Activity Report";

    const styles = {
      6: {
        halign: "right",
        valign: "middle",
      },
      10: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, this.headers, this.state.pdfBody, styles);
  }

  updateTableToDocs() {
    setTimeout(() => {
      const data =
        this.props?.playerActivityData &&
        this.props?.playerActivityData?.data &&
        Array.isArray(this.props?.playerActivityData?.data) &&
        this.props?.playerActivityData?.data?.map((elt) => [
          elt.userId,
          this.state.masking && Number(elt.userName) ? hideMobile(elt.userName) : elt.userName,
          elt.role,
          elt.brand,
          this.state.masking ? hideData(elt.country) : elt.country,
          elt.currency,
          elt.registrationDate ? getDateTimeFormate(elt.registrationDate) : "-",
          formatMoney(elt.turnOver),
          formatMoney(elt.ggr),
          elt.margin,
          elt.betCount,
          formatMoney(elt.stakeAverage),
          elt.playerSegment,
          elt.accountAge,
        ]);
      this.setState({ pdfBody: data });
      const csvData = data ? [this.headers[0], plActivityInfo, ...data] : [this.headers[0], plActivityInfo, []];
      this.setState({
        csvData: csvData
      });
      this.props.dispatch(setDocsData(csvData));
    }, 200);
  }

  countryHandler(e) {
    this.setState({ country: e.target.value });
    if (!this.state.country.includes(e.target.value)) {
      this.state.countryList.push(e.target.value);
    }
    this.setState({ countryCondition: false });
  }

  handleCountryDropdown = () => {
    this.setState({ countryCondition: !this.state.countryCondition });
  };

  getTags() {
    this.props.dispatch(
      fetchTagsList(
        0, 1000
      )
    );
  }

  callApi(pageNumber, itemsPerPage, data) {
    const { ...filterData } = data;
    const payload = {
      ...filterData,
      dateFrom: this.state.startDate
        ? MomentUTCDateTimeToday(this.state.startDate)
        : "",
      dateTo: this.state.endDate
        ? MomentUTCDateTimeEnd(this.state.endDate)
        : "",
    }
    this.props.dispatch(setIsVisible(false));
    this.props.dispatch(
      getPlayerActivity(
        pageNumber - 1,
        itemsPerPage,
        payload,
      )
    );
  }
  resetCsvData() {
    this.setState({ csvData: [], pdfBody: [] });
  }
  activitySearchData() {
    return {
      startdatepicker: MomentUTCDateTimeToday(this.state.startDate),
      enddatepicker: MomentUTCDateTimeEnd(this.state.endDate),
      // brand: this.state.brand,
      playerSegement: this.state.playerSegement,
      playerTags: [this.state.id],
      userName: this.state.username,
      // playerSegmentList: this.state.playerSegementList,
      // country: this.state.country,
      // countryList
      systemTags: this.state.systemTags,
      dateFrom: this.state.startDate
        ? MomentUTCDateTimeToday(this.state.startDate)
        : "",
      dateTo: this.state.endDate
        ? MomentUTCDateTimeEnd(this.state.endDate)
        : "",
      role: this.state.role
    };

  }
  validator(payloadData) {
    const tagType = this.state.tagType;
    const tagName = this.state.tagName;
    const systemTag = this.state.systemTags;
    if (tagType !== "") {
      const segV = tagName === "" ? true : false
      this.setState({ segmentValider: segV });
      this.props.dispatch(setSegmentValidator(segV));
    }
    if (systemTag !== "") {
      this.setState({ segmentValider: false });
    }
    const tagTypeErr =
      Object.keys(payloadData).length === 0 && this.state.tagType === ""
        ? "please select Tag Type"
        : "";
    const ePack = { ...this.state.errorsPack, ["tagType"]: tagTypeErr }
    this.setState({
      errorsPack: ePack
    });
    this.props.dispatch(setTagTypeError(ePack));
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.endDate !== "" && this.state.startDate === "" || this.state.startDate === null) {
      this.setState({ dateError: "Start Date is required" })
      return;
    }
    const payloadData = cleanObject(this.activitySearchData());
    const systemPayload = {
      playerSegement: this.state.playerSegement,
      userName: this.state.username,
      systemTags: this.state.systemTags,
      dateFrom: this.state.startDate
        ? MomentUTCDateTimeToday(this.state.startDate)
        : "",
      dateTo: this.state.endDate
        ? MomentUTCDateTimeEnd(this.state.endDate)
        : "",
      role: this.state.role
    }

    const systemData = cleanObject(systemPayload)
    this.validator(payloadData);

    if (!this.state.dateError && !this.state.segmentValider) {
      if (this.state.tagType === "System") {
        this.props.dispatch(
          getPlayerActivity(0, 25, systemData)
        );
        this.setState({ payloadData: systemData });
      } else {
        this.props.dispatch(
          getPlayerActivity(0, 25, payloadData)
        );
        this.setState({ payloadData: payloadData });
      }
      this.props.dispatch(setIsVisible(false));
      this.props.dispatch(setPaginationFirstValue(1))
      this.props.dispatch(setPaginationSecondValue(25))
      this.props.dispatch(setRecordsShow([1, 25]))
    }

  }


  hadleEnterKey(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }
  getDefaultDateRange = () => {
    const startDate = getFormattedDate(7);
    const endDate = new Date();
    return { startDate, endDate };
  };
  componentDidMount() {
    const { startDate, endDate } = this.getDefaultDateRange();
    const dataExist = Object.keys(this.props?.playerActivityData).length > 0
    if (!dataExist) {
      this.setState({ startDate, endDate })
      this.props.dispatch(setFormData({ ...this.props.formData, startDate, endDate }))
      this.props.dispatch(
        getPlayerActivity(
          this.props.paginationFirstValue - 1,
          this.props.paginationSecondValue,
          {
            dateFrom: MomentUTCDateTimeToday(startDate),
            dateTo: MomentUTCDateTimeEnd(endDate),
          }
        )
      );
    } else {
      const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0
      this.setState({ startDate: this.props.formData.startDate, endDate: this.props.formData.endDate })
      let pdfData = [];
      if (csvDataExist) {
        pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
      }
      this.setState({
        csvData: this.props.docsData,
        pdfBody: pdfData,
      })
    }
    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      this.setState({ masking: true })
    } else {
      this.setState({ masking: false })
    }
    this.stateSetter();
    this.setState({ errorsPack: this.props.tagTypeError, segmentValider: this.props.segmentValidator })
    // document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.hadleEnterKey);
  }

  formDataToSliceSetterForId(tagName, id) {
    const newEntry = { ...this.props.formData, tagName, id };
    this.props.dispatch(setFormData(newEntry));
  }

  changeHandler(e) {
    const { value } = e.target;
    this.setState({ tagName: value });

    if (value === "") {
      this.setState({ id: value });
      this.formDataToSliceSetterForId("", "", "");
    } else {
      const id = this.props?.getTagsList &&
        Object.keys(this.props.getTagsList).length > 0 &&
        this.props?.getTagsList?.data.filter((item) =>
          item.tagName === value
        );
      if (id.length > 0) {
        this.setState({ id: id[0].id });
        this.formDataToSliceSetterForId(value, id[0].id);
      }
    }
    const segV = value === "" ? true : false;
    this.setState({ segmentValider: segV });
    this.props.dispatch(setSegmentValidator(segV));
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.onSubmit(event);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.hadleEnterKey, false);
  }
  handleClickDropdown = () => {
    this.setState({ open: !this.state.open });
  };
  // CLOSE DROPDOWN HANDLER //

  componentDidUpdate(prevProps) {
    if (prevProps.playerActivityData !== this.props.playerActivityData) {
      this.updateTableToDocs();
    }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }
  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val }
    const newPayload = multiEntries ? { ...this.props.formData, ...multiEntries } : newEntry
    this.props.dispatch(setFormData(newPayload));
  }

  render() {
    return (
      <React.Fragment>
        <div className='CMS-tab-panel active' id='CMS-playerActivity'>
          <div className='CMS-tabContent'>
            <div className='CMS-box CMS-box-content'>
              <div className='row'>
                {DateFieldData.map((item, index) => {
                  const maxDate = this.state.endDate
                    ? new Date(this.state.endDate)
                    : new Date();
                  const minDate = this.state.startDate;
                  const maxDateArr = [maxDate, new Date()];
                  const minDateArr = [null, minDate];
                  return (
                    <div className='col' key={generateKey(index)}>
                      <Datepicker
                        title={item.title}
                        name={item.name}
                        ids={item.ids}
                        value={this.state[item.name]}
                        setValue={(date, value) =>
                          this.setDateHandler(date, value)
                        }
                        maxDate={maxDateArr[index]}
                        minDate={minDateArr[index]}
                        errorMsg={index === 0 ? this.state.dateError : null}
                        info={COMMON_MSGS.Start_Date}
                      />
                    </div>
                  );
                })}
                <div className='col'>
                  <Input
                    title={"User Name"}
                    name={"username"}
                    value={this.state.username}
                    placeholder={"User Name"}
                    setValue={(e) => this.handleChange(e)}
                    ids={"playerActivityUserName"}
                    info={COMMON_MSGS.User_Name}
                    show={true}
                  />
                </div>
              </div>
              <div className='row'>

                <div className='col'>
                  <Select
                    title={"Tag Type"}
                    name={"tagType"}
                    value={this.state.tagType}
                    setValue={(val) => this.tagTypeHandle(val)}
                    options={TagType}
                    onSubmit={(e) => this.onSubmit(e)}
                    errorMsg={this.state.errorsPack.tagType}
                    ids={"PlayerActivityTagType"}
                    info={PLAYER_MODULE.Tag_type}
                  />
                </div>

                <div
                  className='col'
                  style={{
                    display:
                      this.state.tagType === "System" ? "none" : "block",
                  }}
                >
                  <Select
                    title={"Sub Type"}
                    name={"subType"}
                    value={this.state.subType}
                    setValue={(val) => this.subTypeHandle(val)}
                    options={SubType}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"PlayerActivitySubTag"}
                    info={PLAYER_MODULE.Sub_type}
                  />
                </div>

                {this.state.tagType === "System" ? <div className='col'>
                  <Select
                    title={"Tag Name"}
                    name={"systemTags"}
                    value={this.state.systemTags}
                    setValue={(val) => this.systemTagHandle(val)}
                    options={SystemTags}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"PlayerActivitySubTag"}
                    errorMsg={
                      this.state.segmentValider
                        ? "Please enter a valid Tag type"
                        : ""
                    }
                    info={PLAYER_MODULE.Tag_Name_plActivity}
                  />  </div>
                  :
                  <div className='col'>
                    <div className='CMS-formGroup'>
                      <div className='CMS-formLabel'>
                        {TAG_CONSTANTS.header_tagName}
                        &nbsp;&nbsp;
                        <Tooltips info={PLAYER_MODULE.Tag_Name_plActivity} />
                      </div>
                      <div className='CMS-dropdown CMS-formControl'>
                        <div className='CMS-select'>
                          <select
                            name='tagName'
                            value={this.state.tagName}
                            onChange={this.changeHandler}
                            onKeyPress={this.onKeyUp}
                            id={"PlayerActivityTag"}
                            className="pointer"
                          >
                            <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                            {this.props?.getTagsList &&
                              Object.keys(this.props?.getTagsList).length > 0 &&
                              Array.isArray(this.props?.getTagsList?.data) &&
                              this.props?.getTagsList?.data?.length > 0 &&
                              this.props?.getTagsList?.data
                                .filter((item) => {
                                  if (this.state.subType !== "") {
                                    return item.tagType.includes(
                                      this.state.subType.toUpperCase()

                                    );

                                  } else {
                                    return item;
                                  }
                                })
                                .map((item, index) => {
                                  return (
                                    <option key={generateKey(index)} value={item.tagName}>
                                      {item.tagName}
                                    </option>
                                  );
                                })}
                          </select>
                        </div>
                        {this.state.segmentValider && (
                          <div className='text-danger'>
                            {TAG_CONSTANTS.tagType_ErrMsg}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}
              </div>
              <div className='row'>
                <div className='col-4'>
                  <Select
                    title={"Role"}
                    name={"role"}
                    value={this.state.role}
                    setValue={(val) => this.handleChange(val)}
                    options={roleData}
                    info={PLAYER_MODULE.roles}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"playerActivityRole"}
                  />
                </div>
              </div>
              <div className='mt-20'></div>
              <div className='row'>
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"PlayerActivitysearch"}
                  idreset={"PlayerActivityreset"}
                  submit={(e) => this.onSubmit(e)}
                  reset={(e) => this.resetButton(e)}
                />
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.playerActivityData.data}
                  totalRecords={this.props.playerActivityData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"Player Activity Report"}
                  payloadData={this.state.payloadData}
                  resetCsvData={this.resetCsvData}
                />
              </div>
              <Table
                tableData={this.state.csvData}
                linkIndex={0}
                styles={this.tableStyles}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    paginationFirstValue: state.playeractivity.paginationFirstValue,
    paginationSecondValue: state.playeractivity.paginationSecondValue,
    activePage: state.playeractivity.activePage,
    playerActivityData: state.playeractivity.playerActivityData,
    getTagsList: state.playeractivity.getTagsListData,
    recordsShow: state.playeractivity.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.playeractivity.isVisible,
    formData: state.playeractivity.formData,
    docsData: state.playeractivity.docsData,
    segmentValidator: state.playeractivity.segmentValidator,
    tagTypeError: state.playeractivity.tagTypeError,
  };
}
export default connect(mapStateToProps)(playerActivity);
