import { Currency } from "./helper"

export const COMMON_CONSTANTS = {
    yesBtn: "YES",
    noBtn: "NO",
    okBtn: "Ok",
    createdOn: "Created On",
    updatedOn: "Updated On",
    creationDate: "Creation Date",
    createdDate: "Created Date",
    updatedDate: "Updated Date",
    createdBy: "Created By",
    updatedBy: "Updated By",
    save: "Save",
    cancel: "Cancel",
    searchBtn: "Search",
    resetBtn: "Reset",
    header_name: "Name",
    header_firstname: "First Name",
    header_lastname: "Last Name",
    header_Email: "Email Address",
    header_dob: "Date of Birth",
    header_notification: "Push Notifications",
    header_promoEmail: "Promo Emails",
    header_sms: "SMS",
    header_VIP: "VIP",
    header_phnCalls: "Phone Calls",
    keepLevelSticky: "Keep Level Sticky",
    auditLogBtn: "Audit Log",
    ddlOpt_select: "Select",
    header_userName: "User Name",
    header_cid: "Customer Id",
    header_CID: "Customer ID",
    search_icon: "search",
    th_amount: `Amount ${Currency()}`,
    view_btn: "View",
    header_mobileNo: "Mobile Number",
    header_email: "Email",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    span_saveIcon: "save",
    span_editIcon: "edit_note",
    span_addIcon: "add",
    span_deleteIcon: "delete",
    update_Btn: "Update",
    deposit: "DEPOSIT",
    withDraw: "WITHDRAW",
    MV_DEPOSIT_TO_WTH_AMT: "MV_DEPOSIT_TO_WTH_AMT",
    header_comment: "Comment",
    th_userId: "User Id",
    th_state: "State",
    details: "Details",
    phone_Number: "Phone Number",
    whatsapp_Number: "Whatsapp Number",
    gender: "Gender",
    location_city: "Location City",
    activate: "Activate",
    Deactivate: "Deactivate",
    id: "Id",
    submit: "Submit",
    value: "Value",
    create: "Create",
    inactivate: "Inactivate",
    Upload: "Upload",
    count: "Count",
    countryCode: "Country Code",
    view_Player: "View Players",
    view_ArtWork: "View Artwork",
    actions: "Actions",
    status: "Status",
    active: "Active",
    deactive: "Deactive"
}

export const DASBOARD_CONSTANTS = {

    betsboard_turnover: "Turnover",
    betsboard_margin: "Margin",
    betsboard_ggr: "GGR",
    betsboard_freebetCost: "Free Bet Cost",
    betsboard_ngr: "NGR",
    betsboard_totalReg: "Total Registrations",
    betsboard_totalDepo: "Total Amount Deposited",
    betsboard_totalWithdraw: "Total Withdrawals",
    betsboard_totalStake: "Total Stake",
    betsboard_totalPayout: "Total Payout",
    betsboard_overall: "Overall",
    betsboard_freebet: "Freebet Cost",
    dashboard: "Dashboard",

    graph_stakeFactor: "Revenue by Stake Factor",
    graph_sportRevenue: "Revenue by Sport",
    graph_betCount: "Bet Count",
    graph_staleVsRevenue: "Stakes Vs Revenue",
    graph_sportsCashRevenue: "Cash Revenue by Sports",
    operation_summary: "Operations Summary",

    ddlOpt_allBets: "All Bets",
    ddlOpt_preMatch: "Pre-Match",
    ddlOpt_InPlay: "In-Play",
    ddlOpt_Casino: "Casino",
    ddlOpt_NEgames: "NE Games",
    ddlOpt_Settled: "Settled",

    ddlOpt_ThisMonth: "This Month",
    ddlOpt_PrevMonth: "Previous Month",
    ddlOpt_ThisWeek: "This Week",
    ddlOpt_Today: "Today",
    ddlOpt_Yesterday: "Yesterday",

    quaterly_Review: "Quarterly Review",
    monthly_Review: "Monthly Review",
    sportsBets: "Sports Bets",
    userActivity: "User Activity",
    Total_Rejected_bets: "The total number of rejected bets for the chosen time frame",
    Total_Rejected_bets_Amount: "The total number of Rejected bets Stake amount for the chosen time frame",
    Total_Cancelled_bets: "The total number of Cancelled/Void/Init bets for the chosen time frame",
    Total_Cancelled_bets_Amount: "The total number of Cancelled/Void/Init bets Stake amount for the chosen time frame",
    Total_Open_bets: "The total number of Open bets for the chosen time frame",
    Total_Open_bets_Amount: "The total number of Open bets Stake amount for the chosen time frame",
    Total_Cashed_out_bets: "The total number of Cashed out bets for the chosen time frame",
    Total_Cashedout_bets_Amount: "The total number of Cashed Out bets amount for the chosen time frame",

    Total_Stake: "The Total Stake for the settled bets for the chosen time frame",
    Total_Payout: "The Total Payout of Settled bets for the chosen time frame",
    Total_Payout_with_Cashbonus: "The Total Payout of Settled bets during the selected time period",
    Bonus_Stake: "Total Stakes of settled bets during the selected time period placed with bonus amount",
    Average_Stake: "Average of all the settled stakes in that period (Total Stake/Number of bets)",
    Margin: "Profit margin for all the settled bets for the chosen time frame",
    Total_Liability: "Total Potential Payout for all the bets in running state for the chosen time frame",
    bet_count: "Total Bet Count"

}

export const REPORTS_CONSTANTS = {
    report_name: "Report Name",
    report_detailBtn: "Details of Report",
    report_viewBtn: "View Report",
    report_dateFrom: "Date From",
    report_dateTo: "Date To",

    // PAYMENT OVERVIEW REPORT
    payment_deposit: "DEPOSITS",
    payment_withdraw: "WITHDRAWALS",
    deposit_summary: "DEPOSIT SUMMARY",
    withdraw_summary: "WITHDRAWAL SUMMARY",
    acc_balance: "ACCOUNT BALANCE",
    customer_netDepo: "CUSTOMER NET DEPOSIT",
    header_currency: "Currency",
    header_count: "Payment Count",
    header_pmValue: `Payment Value ${Currency()}`,
    header_noCustomer: "Number Of Customers",
    header_netDepo: `Net Deposits ${Currency()}`,
    header_revenueTime: "Revenue In Period",
    header_freeBetCost: `Free Bet Costs ${Currency()}`,
    header_userName: "User Name",
    header_allDepo: `All Deposits ${Currency()}`,
    header_profitloss: "All Profit & Loss",
    header_betCount: "Bet Count",
    header_cashRevenue: `Cash Revenue ${Currency()}`,
    header_deposit: `Deposits ${Currency()}`,
    header_firstDepo: "First Deposit",
    // PAYMENT OVERVIEW REPORT

    //MULTIPLE DEPOSIT REPORT
    multipleOverView_deposit: "Deposit Type",
    multipleOverView_ErrMsg: "Please select Deposit Type"
}

export const LOGIN_CONSTANTS = {
    header_Login: "Login",
    input_userName: "Username",
    input_password: "Password",
    login_btn: "Login",
    logout_btn: "Logout"
}

export const PRIMARYPLAYER_CONSTANTS = {
    player_deactiveBtn: "DEACTIVATE",
    player_activeBtn: "ACTIVATE",
    player_kyc: "KYC",
    kyc_upload: "UPLOAD KYC",
    kyc_verifyMsg: "Do you want to verify KYC?",
    editPlayerBtn: "EDIT PLAYER DETAILS",
    add_to_watchlist: "ADD TO WATCHLIST",
    header_Financials: "Financials",
    header_Deposits: "Deposits",
    header_Withdrawals: "Withdrawals",
    header_lastDepoAmount: "Last Deposited Amount",
    header_withdrawableAmnt: "Withdrawable Amount",
    header_pendingWithdraw: "Pending Withdrawals",
    header_stakeAfterlastDepo: "Stake After Last Deposit",
    header_manualDeposits: "Manual Deposits",
    header_withdrawals: "Manual Withdrawals",
    header_lastManualDepo: "Last Manual Deposit Amount",
    header_betting: "Betting",
    header_balance: "Balance",
    th_real: "Real",
    th_sports: "Sports",
    th_casino: "Casino",
    th_common: "Common",
    th_total: "Total",
    ne_games: "NE Games",
    th_locked: "Locked",
    th_unLocked: "Unlocked",

    header_risk: "Risk",
    th_openBets: "Open Bets",
    th_risk: "Risk",
    th_singleBets: "SingleBets",
    th_comboBets: "ComboBets",

    header_bonuses: "Bonuses",
    th_bonusType: "Bonus Type",
    th_currentOffer: "Current Offer",
    th_appliedOn: "Applied On",
    th_sport: "Sport",
    th_casino: "Casino",
    header_communicationPreference: "Communication Preferences",
    kyc_type: "Kyc Type",
}

export const kycOptions = [
    { id: 1, label: "ADDRESS", value: "ADDRESS" },
    { id: 2, label: "BOTH", value: "BOTH" },
    { id: 3, label: "IDENTITY", value: "IDENTITY" },
];

export const KYC_CONSTANTS = {
    identity_docs: "IDENTITY DOCUMENTS",
    address_docs: "ADDRESS DOCUMENTS",
    header_id: "ID",
    header_updatedDate: "Updated Date",
    header_docs: "Document",
    header_action: "Action",
    viewDoc_btn: "View Document",
    header_dob: "DOB",
    header_status: "Status",
    header_confirm: "Confirm",
    header_reject: "Reject",
    nokycDocfound: "No KYC Document Found",
    header_documentId: "Document Id",
    header_approve: "Approve"
}

export const PASSWORD_CONSTANTS = {
    header_newPassword: "New Password",
    header_confirmPassword: "Confirm Password",
    validPassword_ErrMsg: "Please enter valid password",
    PasswordnotMatch_ErrMsg: "Password does not match",
    confirmPassword_ErrMsg: "Please enter confirm password",
    resetPassBtn: "RESET PASSWORD"
}

export const BANKDETAIL_CONSTANTS = {
    th_ahn: "AHN",
    th_bankName: "Bank Name",
    th_branchName: "Branch Name",
    th_accountNo: "Account Number",
    th_ifsc: "Bank Code",
}

export const BETHISTORY_CONSTANTS = {
    header_unsettledBetCount: "Unsettled Bet Count",
    header_unsettledStake: "Unsettled Stakes",
    header_unsetleMaxPayout: "Unsettled Max Payout",
    header_settleNetPosition: "Settled Net Position",
    header_settleBetCount: "Settled Bet Count",
    header_settleStake: "Settled Stakes",
    header_settlePayout: "Settled Payout",

    header_betStatus: "Bet Status",
    ddlOpt_won: "WON",
    ddlOpt_halfwon: "HALF WON",
    ddlOpt_halfLost: "HALF LOST",
    ddlOpt_lost: "LOST",
    ddlOpt_rejected: "REJECTED",
    ddlOpt_cancelled: "CANCELLED",
    ddlOpt_void: "VOID",
    ddlOpt_cashedOut: "CASHED OUT",
    ddlOpt_running: "RUNNING",
}

export const PYMENTHISTORY_CONSTANTS = {
    header_cashBalance: "Cash Balance:",
    header_payments: "Payments:",
    header_depoLimits: "Deposit Limit:",
    header_max: "Max:",
    header_creditTotal: "Credit Total:",
    header_debitTotal: "Debit Total:",

    ddlOpt_auto: "Auto",
    ddlOpt_manual: "Manual",
    ddlOpt_all: "All",
    ddlOpt_withDrwal: "Withdrawals",
    ddlOpt_deposit: "Deposits",
    ddlOpt_init: "INIT",
    ddlOpt_approved: "APPROVED",
    ddlOpt_confirmed: "CONFIRMED",
    ddlOpt_decline: "DECLINE",
    ddlOpt_cancelled: "CANCELLED",
    ddlOpt_verified: "VERIFIED",
    ddlOpt_hold: "HOLD",
    manualAdjustmentBtn: "Make Manual Adjustment",
}

export const FRAUDSCRUB_CONSTANTS = {
    maxUserIp: "MaxUsersPerIP",
    count: "Count",
    th_ip: "IP",
}

export const TAG_CONSTANTS = {
    header_tagType: "Tag Type",
    header_tagName: "Tag Name",
    header_uploadTag: "Upload Tag",
    ddlOpt_content: "Content",
    ddlOpt_customerSupport: "Customer Support",
    ddlOpt_payments: "Payments",
    ddlOpt_promotion: "Promotion",
    ddlOpt_vip: "VIP",
    ddlOpt_marketing: "Marketing",
    th_tagType: "Tag Type",
    th_appliedOn: "Applied On",
    status: "Status",
    action: "Action",
    tagType_ErrMsg: "Please enter a valid Tag type"
}

export const NOTE_CONSTANTS = {
    notes: "Notes",
    noteErr_Msg: "Please enter appropriate notes",
    addNote: "Add Note"
}

export const BONUS_CONSTANTS = {
    bonusCode_ErrMsg: "Please Enter Valid BonusCode",
    createBonus_btn: "Create Bonus",
    header_sport: "Sport",
    header_affilate: "Affiliate UserName",
    header_casino: "Aggregator",
    gamename: "Game Name",
    unlockApplicable_sports: "Unlock Applicable Sports",
    sportDdl_ErrMsg: "Sports is required",
    bonus_code: "Bonus Code",
    bonus_type: "Bonus Type",
    start_Date: "Start Date",
    valid_Until: "Valid Until",
    wager_Bonus: "Wager Bonus",
    instant_Bonus: "Instant Bonus",
    deposit_Rel: "Deposit Rel %",
    deposit_Criteria: "Deposit Criteria",
    wager_Criteria: "Wager Criteria",
    max_Bonus: "Max Bonus",
    min_Deposit: "Min Deposit",
    bonus: "Bonus",
    Opt_In: "Opt-In",
    bonus_description: "Bonus Description",
    bonus_sports: "Bonus Sports",
    odds_Threshold: "Odds Threshold",
    applicable_To: "Applicable To",
    Tags: "Tags",
    apply_Expiry: "Apply Expiry",
    Brand: "Brand",
    bonus_Status: "Bonus Status",
    number_of_Legs: "Number Of Legs",
    rc_numOfLegs: "RC Number Of Legs",
    min_stake_Amount: "Min Stake Amount",
    bonus_Payout: "Bonus Payout %",
    referrer: "Referrer %",
    referee: "Referee %",
    recurring_referrer: "Recurring Referrer %",
    sportDdl_ErrMsg: "Please select at least one sport",
    provider_ErrMsg: "Please select at least one Provider",
    gameName_ErrMsg: "Please select at least one Game Name",
    bonusAppFor: "Please select bonus applicable for",
    bettingType: "Please select betting Type",
    cashbackFrequency: "Please select Cashback Frequency",
    cashbackType: "Please select Cashback Type",
    affBtags: "Affiliate User Names",
}

export const PAYMENT_CONSTANTS = {
    th_txnId: "TXN ID",
    th_amount: "Amount",
    th_comments: "Comments",
    th_transactionId: "Transaction Id",
    th_paymentDesc: "Payment Description",
    th_bankName: "Bank Name",
    th_paymentMethod: "Payment Method",
    th_transactionRef: "Transaction Ref",
    th_transactionType: "Transaction Type",
    th_transactionDate: "Transaction Date",
    th_paymentStatus: "Payment Status",
    th_UTR_No: "UTR Number",
    ddlOpt_pending: "Pending",
    ddlOpt_confirmed: "Confirmed",
    ddlOpt_declined: "Declined",
    comment_ErrMsg: "Please mention your comments",
    audit_btn: "Audit",
    available_Bonus: "Available Bonus",

    //PAYMENT LIMITS
    pf_tax_waiveOff: "PF & Tax Waive off",
    paymentType: "Payment Type",
    ddlOpt_depositLimit: "Deposit Limits",
    ddlOpt_withdrawLimit: "Withdrawal Limits",
    ddlOpt_tds: "TDS",
    ddlOpt_platformFeeTax: "Platform Fee & Tax",
    ddlOpt_paymentMethod: "Payment Methods",
    ddlOpt_stakeTax: "Stake Tax",
    ddlOpt_winningTax: "Winning Tax",
    ddlOpt_gameTax: "Betting Tax",
    paymentType_ErrMsg: "Select Proper PaymentType",
    tds_amount: `TDS Amount ${Currency()}`,
    tds_percentage: "TDS Percentage",
    th_method: "Method",
    th_paymentType: "Payment Type",
    th_paymentDesc: "Payment Description",
    th_referenceId: "Reference ID",

    //DEPOSIT PROOF
    pending_withdraw_Amnt: "Pending Withdrawal Amount",
    request_Date: "Withdrawal Request Date",
    depoProof_docs: "Deposit Proof Document View",
    payment_reference: "Payment Reference"
}

export const MULTIBETCONFIG_CONSTANTS = {
    multibetConfig: "Multibet Configuration",
    oddThresholdLimit: "Odds Threshold Limit",
    oddEmpty_ErrMsg: "Odds cannot be empty",
    bonusRequirement: "Bonus Requirement",
    No_Events_On_Betslip: "Number of Events on betslip",
    bonus_Percentage: "Bonus %",
}

export const MULTIBETACC_CONSTANTS = {
    header_MBBbonusAmount: "Disbursement of the MBB Bonus Amount",
    ddlOpt_multiBet: "Multibet",
    ddlOpt_oneCut: "One Cut",
    ddlOpt_twoCut: "Two Cut",
    totalMBB_Disbursed: "Total MBB Disbursed",
    totalOneCut_Disbursed: "Total One Cut Disbursed",
    totalTwoCut_Disbursed: "Total Two Cut Disbursed",
    th_betId: "Bet ID",
    th_amountAwarded: "Amount Awarded",
    th_betStatus: "Bet Status (Won/Rollback)",
}

export const MANUALDIALOG_CONSTANTS = {
    operation: "Operation",
    wallet: "Wallet",
    ddlOpt_realBalance: "Real Balance",
    ddlOpt_bonusBalance: "Bonus Balance",
    bonus_allocation: "Bonus Allocation",
    ddlOpt_unlockedBonus: "Unlocked Bonus",
    ddlOpt_lockedBonus: "Locked Bonus",
    header_type: "Type",
    add_withdrwable_balance: "Add to Withdrawable balance",
    role: "Role",
    btag: "Btag",
    edit_user: "Edit User",
    pay: 'Pay'
}

export const CREATEROLE_CONSTANTS = {
    brandId: "Brand Id",
    permission_name: "Permission Name",
    header_role: "Role",
    brand_ErrMsg: "Please select proper brand",
    permission_ErrMsg: "select proper permission",
    emptyPermission_ErrMsg: "Permisson cannot be empty",
    input_permissionErrMsg: "Please provide proper permission name",
    role_ErrMsg: "please select proper role",
    roleExist_ErrMsg: "role already exist",
    th_id: "ID",
    th_roleName: "Role Name",
    th_cletntId: "Client ID",
    Btn_createPermission: "Create Permission"
}

export const AFFILIATE_CONSTANTS = {
    header_affiliateType: "Affiliate Type",
    header_payoutCycle: "Payout Cycle",
    header_setUpCost: "Setup Cost %",
    header_commission: "Commission %",
    total_registrations: "Total Registrations",
    deposited_customers: "Deposited Customers",
    total_deposits: "Total Deposits",
    total_stakes: "Total Stakes",
    total_payout: "Total Payout",
    header_GGR: "GGR",
    total_bonusAmount: "Total Bonus Amount",
    total_numberOfBets: "Total Number of Bets",
    total_withdrawals: "Total Withdrawals",
    header_media: "Media",
    campaign: "Campaign",
    channel: "Channel",
    addChannel: "Add Channel",
    addCampaign: "Add Campaign",
    campaignName: "Campaign Name",
    view_campaign: "View Campaign",
    new_customers: "New Customers",
    header_channelName: "Channel Name",
    header_URL: "URL",
    header_QRcode: "QR CODE",
    copy_URL: "Copy URL",
    affiliate_Name: "Affiliate Name",
    affiliate_Btag: "Affiliate Btag",
    total_Reg: "Total Registration",
    total_Bet: "Total Bets",
    total_Deposited_Customer: "Total Deposited Customers",
    total_Deposits: "Total Deposits",
    total_stake: "Total Stakes",
    total_Payouts: "Total Payouts",
    total_GGR: "Total GGR",
    total_Bonus_Amnt: "Total Bonus Amount",
    total_Deposit_Amnt: "Total Deposit Amounts",
    total_Withdrawal_Amnt: "Total Withdrawal Amounts",
    ngr: "Total NGR",
    firstTimeDepositedCustomers: "First Time Deposited Customers",
    setup_cost: "Setup Cost Percentage",
    setup_cost_percentage: "Setup Cost percentage",
    setup_cost: "Setup Cost",
    gross_commission: "Gross Commission",
    net_commission: "Net Commission",
    clicks_count: "URL Hits Count",
}

export const CURRENCY_CONVERT_CONSTANTS = {
    header_currencyFrom: "Currency From",
    header_currencyTo: "Currency To",
    header_conversionRate: "Conversion Rate",
    currency_ErrMsg: "select proper Currency",
    EUR: "EUR",
}

export const BRAND_CONFIG_CONSTANTS = {
    addValue_Btn: "ADD VALUE",
    header_configKey: "Config Key",
    header_configValue: "Config Value",
    header_brandName: "Brand Name",
    header_clientId: "Client Id",
    header_clientName: "Client Name",
    header_BrandCurrency: "Brand Currency",
    header_brandID: "Brand ID",
    createClient_ErrMsg: "Client Name is Required",
    brandCurrency_required: "Brand Currency is Required",
    clientName_required: "select proper Client Name",
    brandName_required: "Brand Name is Required",
    no_brand_found: "No Brands Found"
}

export const BRAND_KEY = {
    apikey: "Api Key",
    keyName: "Key Name",
    added_by: "Added By ",
    api_Secret_key: "Api Secret Key",
    key_Status: "Key Status",
    empty_keyErr_msg: "Please select key Status",
    empty_keyName_msg: "Please provide proper key name"
}

export const RISK_MANAGEMENT_CONSTANTS = {
    ipAddress: "IP ADDRESS",
    macAddress: "MAC ADDRESS",
    no_User: "NUMBER OF USERS",
    cid_FromIp: "CUSTOMERS FROM IP",
    cid: "CUSTOMER ID",
    betId: "BET ID",
    event_name: "EVENT NAME",
    selection_name: "SELECTION NAME",
    market_name: "MARKET NAME",
    created_date: "CREATED DATE",
    date_of_event: "DATE OF THE EVENT"
}
