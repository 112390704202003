import React from "react";
import { useSelector } from "react-redux";
import { setSportsDates } from "../reportSlice";
import ReportsWrapper from "../ReportsWrapper";
import SportsTable from "../SportsTable";

function SportsReport(props) {
  const { sportsReportData, sportsDates } = useSelector((st) => st.reports);
  return (
    <React.Fragment>
      <ReportsWrapper
        reportTitle={"SPORTS OVERVIEW"}
        currentTab={"SPORTS OVERVIEW"}
        pageKey={"SPORT"}
        setDateToStore={setSportsDates}
        persistDate={sportsDates}
        sportData={sportsReportData}
        ReportTable={() => (
          <SportsTable
            dates={sportsDates}
            sportData={sportsReportData}
            reportPage={"sports Report"}
          />
        )}
      />
    </React.Fragment>
  );
}

export default SportsReport;
