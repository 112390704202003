import React, { useState } from 'react'
import Input from '../Common/common_inputs/Input'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDisableBtn, updateUserStake } from './UserStakeSlice'

const UserStake = () => {
    const [disable, setDisable] = useState(false)
    const [saveDisable, setSaveDiable] = useState(false)
    const [focus, setFocus] = useState(false)
    const dispatch = useDispatch()
    const userId = localStorage.getItem("userId")

    const stakeData = useSelector((p) => p.UserStakeSlice.userStakes)
    const [stakes, setStakes] = useState(stakeData.stakePreference)
    const disableBtn = useSelector((p) => p.UserStakeSlice.disableBtn)


    const handleInput = (index, value) => {
        const newStakes = [...stakes];
        newStakes[index] = +value;
        setStakes(newStakes);
    };

    const onEdit = () => {
        setDisable(false)
        setFocus(true)
    }
    const onCancel = () => {
        setFocus(false)
        setDisable(true)
    }

    const onSave = () => {
        let newStakes = [...stakes];
        if (newStakes.includes(0)) {
            newStakes = stakeData.stakePreference;
            const payload = {
                stakePreference: newStakes,
                userId: userId,
            };
            dispatch(updateUserStake(userId, payload));
            setSaveDiable(true);
            setDisable(true);
        } else {
            const payload = {
                stakePreference: stakes,
                userId: userId,
            }
            dispatch(updateUserStake(userId, payload))
            setSaveDiable(true)
            setDisable(true)
        }
    }


    useEffect(() => {
        if (stakeData && stakeData.stakePreference && stakeData.stakePreference.length >= 4) {
            setDisable(true)
        }
    }, [stakeData])
    useEffect(() => {
        var btnTimer;
        if (disableBtn) {
            btnTimer = setTimeout(() => {
                dispatch(setDisableBtn(false))
                setSaveDiable(false)
            }, 3000)
        }
        return () => clearTimeout(btnTimer);
    }, [disableBtn])

    return (
        <div className='CMS-tabContent'>
            <div className='CMS-box-content'>
                <div className='CMS-box CMS-box-content'>
                    <div className='row'>
                        {
                            stakes?.map((stake, index) => (

                                <div className={index === 0 ? 'col-xl-1 ml-50' : 'col-xl-1'}>
                                    <Input
                                        title={index === 0 ? "Stakes" : ''}
                                        name={"Stakes"}
                                        ids={"Stakes"}
                                        value={stake === 0 ? "" : stake}
                                        setValue={(e) => handleInput(index, e.target.value)}
                                        disabled={disable}
                                        isTofocus={focus}
                                    />
                                </div>
                            ))
                        }
                        <button
                            className="CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium mt-30 ml-50"
                            onClick={() => onEdit()}
                        >
                            <span className='material-icons edit'>{COMMON_CONSTANTS.span_editIcon}</span>
                            &nbsp;{COMMON_CONSTANTS.edit}
                        </button>

                    </div>
                    <div className='row'>
                        <div className='CMS-btnContainer' style={{ marginInlineEnd: "950px" }}>
                            <button
                                className={`CMS-btn CMS-btnStatus CMS-statusActive pointer CMS-btnMedium ${saveDisable ? 'disable-button' : ''}`}
                                onClick={(e) => onSave(e)}
                            >
                                {COMMON_CONSTANTS.save}
                            </button>
                            <button
                                className={"CMS-btn CMS-btnStatus CMS-statusClosed pointer CMS-btnMedium"}
                                onClick={() => onCancel()}
                            >
                                {COMMON_CONSTANTS.cancel}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserStake