import { Currency } from "../../../../sharedfiles/helper"

export const option = [
  {
    id: 1,
    label: "Auto",
    value: "ONLINE",
  },
  {
    id: 2,
    label: "Manual",
    value: "MANUAL",
  },
]

export const transactionType = [
  { id: 1, label: "All", value: "ALL" },
  { id: 2, label: "Deposit", value: "DEPOSIT" },
  { id: 3, label: "Withdraw", value: "WITHDRAW" },
  { id: 4, label: "Deposit to Withdrawal", value: "MV_DEPOSIT_TO_WTH_AMT" },
]

export const paymentTypes = [
  { id: 1, label: "Bank", value: "BANK" },
  { id: 2, label: "Bank Transfer", value: "BANK_TRANSFER" },
  { id: 3, label: "Barter", value: "BARTER" },
  { id: 4, label: "Card", value: "CARD" },
  { id: 5, label: "Coins", value: "COINS" },
  { id: 6, label: "Opay Walleting", value: "OPAYWALLETNG" },
  { id: 7, label: "Opay Walleting QR", value: "OPAYWALLETNGQR" },
  { id: 8, label: "Paga", value: "PAGA" },
  { id: 9, label: "Pay Attitude", value: "PAYATTITUDE" },
  { id: 10, label: "QR Code", value: "QR_CODE" },
  { id: 11, label: "USSD", value: "USSD" },
  { id: 12, label: "Opay_App", value: "OPAY_APP" },
]


export const transactionStatus = [
  { id: 1, label: "All", value: "ALL" },
  { id: 2, label: "Confirmed", value: "CONFIRMED" },
  { id: 3, label: "INIT", value: "INIT" },
  { id: 4, label: "Failed", value: "FAILED" },
  { id: 5, label: "Declined", value: "DECLINED" },
  { id: 6, label: "Cancelled", value: "CANCELLED" },
  { id: 7, label: "Approved", value: "APPROVED" },
  { id: 8, label: "Hold", value: "HOLD" },
  { id: 9, label: "Verified", value: "VERIFIED" },
]


export const adjustmentOption = [

  {
    id: 1,
    label: "MANUAL SETTLEMENT",
    value: "MANUAL_SETTLEMENT",

  },
  {
    id: 2,
    label: "SPORTSBET ADJUSTMENT",
    value: "SPORTS_BET_ADJUSTMENT",

  },
  {
    id: 3,
    label: "PAYMENTS RECONCILIATION",
    value: "PAYMENTS_RECONCILIATION ",

  },
  {
    id: 4,
    label: "GOODWILL GESTURE",
    value: "GOODWILL_GESTURE",

  },
  {
    id: 5,
    label: "BALANCE RESET-FRAUD",
    value: "BALANCE_RESET_FRAUD",

  },
  {
    id: 6,
    label: "BALANCE RESET-MANUAL SETTLEMENT",
    value: "MANUAL_SETTLEMENT ",

  },
  {
    id: 7,
    label: "SPORTSBET ADJUSTMENT",
    value: "SPORTS_BET_ADJUSTMENT",

  },
  {
    id: 8,
    label: "PAYMENTS RECONCILIATION",
    value: "PAYMENTS_RECONCILIATION",

  },
]

export const paymentSearchHeaders = [
  "Transaction ID",
  "Customer ID",
  "Username",
  "Role",
  "Payment Description",
  "Payment Methods",
  "Payment Type",
  "Adjustment Type",
  "Transaction Ref",
  "ActionBy",
  "Transaction Type",
  "Transaction Date",
  "Updated Date",
  "Status",
  `Amount ${Currency()}`,
  "Transaction IP Address",
  "Updated By",
  "Actions",
  "Payment Logs",
];

export const paymentsrchInfo = [
  "Showing Transaction ID",
  "Showing Customer ID",
  "Showing Username",
  "Showing Role of the User",
  "Showing Payment Description",
  "Showing Payment Methods",
  "Showing Payment Type",
  "Showing Adjustment Type",
  "Showing Transaction Ref",
  "Showing ActionBy",
  "Showing Transaction Type",
  "Showing Transaction Date",
  "Showing Updated Date",
  "Showing the payment Status",
  `Showing Amount`,
  "Showing Transaction IP Address",
  "Showing Updated By",
  "Showing Action to be taken",
  "Showing All Payment Details"
]

export const paymentSortables = {
  "Customer ID": {
    key: "userId",
    type: "num"
  },
  "Username": {
    key: "userName",
    type: "num"
  },
  "ActionBy": {
    key: "addedBy",
    type: "str"
  },
  "Transaction Type": {
    key: "paymentType",
    type: "str"
  },
  "Transaction Date": {
    key: "createdDate",
    type: "date"
  },
  "Updated Date": {
    key: "updatedDate",
    type: "date"
  },
  "Status": {
    key: "status",
    type: "str"
  },
  [`Amount ${Currency()}`]: {
    key: "amount",
    type: "num"
  }
}

export const viewLogInfo = [
  "Showing Transaction ID",
  "Showing Payment Description",
  "Showing Event Type",
  "Showing Reference",
  "Showing Created",
  "Showing Payment Methods",
  "Showing Payment Type",
  "Showing Request/Response",
]
