import { regexType } from "../../../../sharedfiles/helper"

export const options = [
  { id: 1, label: "Deposit Limits", value: "deposit" },
  { id: 2, label: "Withdrawal Limits", value: "withdrawal" },
  { id: 3, label: "TDS", value: "TDS" },
  { id: 4, label: "Platform Fee & Tax", value: "PFT" },
]

export const depositLimitData = [
  {
    id: 1,
    ids: "depositMinLimit",
    title: "Min Deposit Limit",
    name: "depositMinLimit",
    type: "text",
    placeholder: "Min Deposit Limit",
    regex: regexType.validNumeric,
    errorMsg: "min deposit limit is required",
  },
  {
    id: 2,
    ids: "depositMaxLimit",
    title: "Max Deposit Limit",
    name: "depositMaxLimit",
    type: "text",
    placeholder: "Max Deposit Limit",
    regex: regexType.validNumeric,
    errorMsg: "max deposit limit is required",
  },
]
export const withdrawLimitData = [
  {
    id: 1,
    ids: "withdrawMinLimit",
    title: "Min Withdrawal Limit",
    name: "withdrawMinLimit",
    type: "text",
    placeholder: "Min Withdrawal Limit",
    regex: regexType.validNumeric,
    errorMsg: "min withdrawal limit is required",
  },
  {
    id: 2,
    ids: "withdrawMaxLimit",
    title: "Max Withdrawal Limit",
    name: "withdrawMaxLimit",
    type: "text",
    placeholder: "Max Withdrawal Limit",
    regex: regexType.validNumeric,
    errorMsg: "max withdrawal limit is required",
  },
]