import React from "react";
import {Currency} from "../.././../sharedfiles/helper";

function PrimaryTable(props) {
const {title, data,...rest} = props;
    return(
        <>
       
            <div className='CMS-table CMS-table-triped'>
             <table>
                              <tbody>
                                <tr>
                                  <th className='textLeft'>{data[0].title}</th>
                                  <td className='textLeft'>
                                    {Currency()}
                                    {data[0].value}
                                  </td>
                                  <th className='textLeft'>{data[1].title}</th>
                                  <td className='textLeft'>
                                    {Currency()}
                                    {data[1].value}
                                  </td>
                                  <th className='textLeft'>{data[2].title}</th>
                                  <td className='textLeft'>
                                  {data[2].value}
                                  </td>
                                </tr>
                                <tr>
                                  <th className='textLeft'>{data[3].title}</th>
                                  <td className='textLeft'>
                                   {Currency()}
                                   {data[3].value}
                                  </td>
                                  <th className='textLeft'>{data[4].title}</th>
                                  <td className='textLeft'>
                                   {Currency()}
                                   {data[4].value}
                                  </td>
                                  <th className='textLeft'>{data[5].title}</th>
                                  <td className='textLeft'>
                                  {data[5].value}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
            </div>
      
        </>
    )
}

export default PrimaryTable;