import React from 'react'
import Dialog__Main from "../Common/Dialog/Dialog__Main"
export const AuditLog = (props) => {

  const close = () => {
    props.setOpenAuditLog(false)
  }

  return (
    <React.Fragment>
      <Dialog__Main
        dOpen={props.openAuditLog}
        dClose={close}
        title={"Audit Log"}
      >
        <div className="CMS-box CMS-table CMS-table-triped">
          <table className="text-center">
            <thead>
              <tr>
                <th>Created Date</th>
                <th>Updated Date</th>
                <th>Created By</th>
                <th>Updated By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Dialog__Main>
    </React.Fragment>
  )
}

export default AuditLog;
