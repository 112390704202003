import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { bonusApi } from "../../sharedfiles/EndpointConfig";

export const BetTickerSlice = createSlice({
    name: "BetTickerSlice",
    initialState: {
        recordsToShow: [1, 25],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        bonusTickerData: [],
        bonusTickerTableData: [],
        isVisible: false,
        loader: true
    },
    reducers: {
        setBonusTickerData: (state, action) => {
            state.bonusTickerData = action.payload;
        },
        setBonusTickerTableData: (state, action) => {
            state.bonusTickerTableData = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsToShow = action.payload;
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        }
    },
})

export const {
    setBonusTickerData,
    setBonusTickerTableData,
    setRecordsShow,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setIsVisible,
    setLoader
} = BetTickerSlice.actions

export const getBonusTicker = (pageNumber, itemPerPage, from, bonusTickerData) => (dispatch) => {
    var obj = { from: from };
    dispatch(setBonusTickerData([]))
    dispatch(setLoader(true))
    var url =
        `${bonusApi.bonusTicker}` +
        "?pageNumber=" + pageNumber + "&itemsPerPage=" + itemPerPage
    apiCaller(url, "POST", obj).then((response) => {

        if (response !== null && response) {
            if (response.status == 200) {
                dispatch(setLoader(false))
                dispatch(setIsVisible(true))
                // dispatch(setBonusTickerData(response.data))
                console.log("response.data: ", response.data);

                // dispatch(setPaymentTickerTableData(response.data));
                if (from) {
                    if (
                        Array.isArray(response.data.data[0].bonusTicker) &&
                        response.data.data[0].bonusTicker.length > 0
                    ) {
                        let copyBonusTickerData = [
                            ...bonusTickerData.data[0].bonusTicker,
                        ];
                        for (let obj of response.data.data[0].bonusTicker) {
                            copyBonusTickerData.unshift(obj);
                        }
                        let getCurrentTime = response.data.data[0].currentTime;
                        let getNewRecords = response.data.totalRecords;
                        let getExistingRecords = bonusTickerData.totalRecords;
                        let updatedRecords =
                            Number(getNewRecords) + Number(getExistingRecords);
                        let formBonusTickerData = {
                            data: [
                                {
                                    bonusTicker: response.data.data[0].bonusTicker,
                                    currentTime: getCurrentTime,
                                },
                            ],
                            totalRecords: response.data.totalRecords,
                        };
                        dispatch(setBonusTickerData(formBonusTickerData));

                    } else {
                        let formBonusTickerData = {
                            data: [
                                {
                                    bonusTicker: response.data.data[0].bonusTicker,
                                    currentTime: response.data.data[0].currentTime,
                                },
                            ],
                            totalRecords: bonusTickerData.totalRecords,
                        };
                        dispatch(setBonusTickerData(formBonusTickerData));
                    }
                } else {
                    dispatch(setBonusTickerData(response.data));
                }
            }
        }
    }).catch(err => {
        dispatch(setBonusTickerData([]));
        dispatch(setLoader(false))
        dispatch(setIsVisible(true))
    });
}

export default BetTickerSlice.reducer