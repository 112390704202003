import React, { useState, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import CreateUserTable from "./CreateUserTable";
import {
  createUser,
  getUserTable,
  setPaginationFirstValue,
  setIsVisible,
  setPaginationSecondValue,
  setRecordsShow,
  setPrevFormData,
  setDocsData,
  setPdfData,
  setFormErrors,
} from "./createUserSlice";
import { cleanObject, getDateTimeFormate } from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import { getBrandConfigKey } from "../Brand config/BrandConfigSlice";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import { CreateUserData } from "../Common/data/mapData";
import { Admin } from "../../sharedfiles/tooltipMsgs";
const CreateUser = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [formData, setFormData] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    loginPin: "",
  });
  const pageFirstVal = useSelector(
    (state) => state.createuserSlice.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.createuserSlice.paginationSecondValue
  );
  const recordsToShow = useSelector(
    (state) => state.createuserSlice.recordsShow
  );
  const UserList = useSelector((state) => state.createuserSlice.allUsersList);
  const [passawordValidation, setPasswordValidation] = useState("");
  const filedsHandler = (e) => {
    const { name, value } = e.target;
    const newError = error.filter((err) => name !== err);
    const updator = () => {
      setError(newError);
      formErrorsSetter(newError);
      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      formDataToSliceSetter(name, value);
    }
    if (name !== "loginPin") {
      updator();

      if (value === "") {
        setError([...error, name]);
        formErrorsSetter([...props.formErrors, name]);
      }

    } else {
      if (!(/\s/.test(value))) {
        updator();
        validator(name, value);
      }
    }
  };
  const validator = (name, value) => {
    if (name === "loginPin") {
      if (value === "") {
        setPasswordValidation("Password is Required")
      }
      else if (!value.match(/^(?=.*?[A-Z])/)) {
        setPasswordValidation("Password must contain 1 Letter in Capital Case!");
      }
      else if (!value.match(/^(?=.*?[a-z])/)) {
        setPasswordValidation("Password must contain 1 Letter in Small Case!");
      }
      else if (!value.match(/^(?=.*?[0-9])/)) {
        setPasswordValidation("Password must contain atleast 1 Number!");
      }
      else if (!value.match(/^(?=.*?[-_@#$%^&*.])/)) {
        setPasswordValidation("Password must contain 1 special charecter");
      }
      else if (value.length <= 7) {
        setPasswordValidation("Password must be atleast 8 characters!");
      }
      else {
        setPasswordValidation("")
      }
    }
  }

  const fieldsValidator = () => {
    validator("loginPin", formData.loginPin);
    const inputValue = Object.keys(formData);
    const err = [];
    inputValue.map((value) => formData[value] === "" && err.push(value));
    formErrorsSetter(err);
    setError(err);

    const isDataAquired = Object.keys(cleanObject({ ...formData })).length === 4;
    return isDataAquired;
  }


  const onSubmit = (e) => {
    e.preventDefault();

    if (fieldsValidator() && passawordValidation === "") {
      dispatch(createUser(formData));
      setTimeout(() => {
        dispatch(
          getUserTable(
            props.paginationFirstValue - 1,
            props.paginationSecondValue
          )
        );
        resetButton();
      }, 500);
    }
  };

  const headers = [
    [
      "User Id",
      "User Name ",
      "First Name",
      "Last Name",
      "Created Date",
      "Updated Date",
      "Created By",
      "Updated By",
      "Role",
      "Btag",
    ],
  ];

  const exportPDF = () => {
    const title = "Create User Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (Object.keys(props.allUsersList).length > 0) {
      const data =
        props.allUsersList &&
        props.allUsersList.data.map((p) => [
          p.userId,
          p.userName,
          p.firstName,
          p.lastName,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
          p.createdBy,
          p.updatedBy,
          p.roles.map((value, index) => {
            return value.roleName + `,`;
          }),
          p.btag,
        ]);
      const pdfData = data && Array.isArray(data) && data.length > 0 ? data : []
      setPdfBody(pdfData);
      dispatch(setPdfData(pdfData));
      const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []];
      setCsvData(csvData);
      dispatch(setDocsData(csvData));
    }
  }, [props.allUsersList]);

  const resetButton = (e) => {
    setFormData({
      userName: "",
      firstName: "",
      lastName: "",
      loginPin: "",
    });
    setPasswordValidation("")
    setError([]);
    dispatch(setPrevFormData({}));
    dispatch(setDocsData([]));
    dispatch(setPdfData([]));
    dispatch(setFormErrors([]));
  };

  useEffect(() => {
    // dispatch(getAdminUser(0, 25));
    dispatch(setPrevFormData(formData));
    const dataExist = Object.keys(props?.allUsersList).length > 0
    if (!dataExist) {
      dispatch(
        getUserTable(props.paginationFirstValue - 1, props.paginationSecondValue)
      )
    } else {
      setPdfBody(props.pdfData);
      setCsvData(props.docsData);
    }
    const brandKeyExist = props.brandData && Array.isArray(props.brandData) && props.brandData.length > 0
    if (!brandKeyExist) {
      dispatch(getBrandConfigKey());
    }

    stateSetter();

  }, []);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(getUserTable(pageNumber - 1, itemsPerPage));
  };

  const stateSetter = () => {
    const isFormDataExist = Object.keys(props.prevFormData).length
    if (isFormDataExist) {
      Object.entries(props.prevFormData).map(([key, value]) => setFormData((prevState) => {
        return {
          ...prevState,
          [key]: value,
        }
      }))
    }
    setError(props.formErrors);
  }

  const formDataToSliceSetter = (key, val) => {
    const newPayload = { ...props.prevFormData, [key]: val }
    dispatch(setPrevFormData(newPayload));
  }
  const formErrorsSetter = (errors) => {
    dispatch(setFormErrors(errors));
  }
  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-playerActivity'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row'>
              {CreateUserData.map((item, ind) => {
                return (
                  <React.Fragment key={item.ids}>
                    <div className='col'>
                      <Input
                        title={item.title}
                        name={item.name}
                        ids={item.ids}
                        value={formData[item.name]}
                        placeholder={item.placeholder}
                        setValue={filedsHandler}
                        regex={item.regex}
                        errorMsg={
                          error.length > 0 &&
                          error.includes(item.name) && item.errorMsg
                        }
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
              <div className="col">
                <Input
                  title={"Password"}
                  name={"loginPin"}
                  ids={"createuserpassword"}
                  value={formData.loginPin}
                  placeholder={"Password"}
                  setValue={filedsHandler}
                  maxLen={16}
                  // regex={item.regex}
                  errorMsg={passawordValidation}
                  info={Admin.Password}
                  show={true}
                />
              </div>
            </div>


            <div className='mt-20'></div>
            <div className='row'>
              <ButtonGroup
                groupNames={[" Create User", "Reset"]}
                idsearch={"Createuser"}
                idreset={"CreateuserReset"}
                submit={(e) => onSubmit(e)}
                reset={(e) => resetButton(e)}
              />
            </div>
          </div>

          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={UserList?.data}
                totalRecords={UserList?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Create User Report"}
              />
            </div>
            <CreateUserTable Table allUsersList={props.allUsersList} />
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    paginationFirstValue: state.createuserSlice.paginationFirstValue,
    paginationSecondValue: state.createuserSlice.paginationSecondValue,
    recordsShow: state.createuserSlice.recordsShow,
    brandData: state.dashboard.brandData,
    allUsersList: state.createuserSlice.allUsersList,
    brandConfig: state.brandconfig.brandConfig,
    brandData: state.dashboard.brandData,
    isVisible: state.createuserSlice.isVisible,
    prevFormData: state.createuserSlice.prevFormData,
    docsData: state.createuserSlice.docsData,
    pdfData: state.createuserSlice.pdfData,
    formErrors: state.createuserSlice.formErrors,
  };
}

export default connect(mapStateToProps)(CreateUser);
