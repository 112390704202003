import React from 'react'
import Vpagination from '../../sharedfiles/Vpagination'

const ReportPagination = (props) => {
    return (
        <div className='CMS-pagination'>
            {
                props.isVisible && props.paginationRequired ?
                    <Vpagination
                        data={props.withdrawalData?.data}
                        totalRecords={props.withdrawalData?.totalRecords}
                        paginationFirstValue={props.pageNumber}
                        paginationSecondValue={props.itemsPerPage}
                        setPaginationFirstValue={props.setPaginationFirstValue}
                        setPaginationSecondValue={props.setPaginationSecondValue}
                        recordsShow={props.recordsShow}
                        setRecordsShow={props.setRecordsShow}
                        isVisible={props.visible}
                        setIsVisible={props.setVisible}
                        callApi={props.apicall}
                        hideDocs={true}
                    /> : null

            }
            {
                props.isVisible && props.depositPagination ?
                    <Vpagination
                        data={props.depositData?.data}
                        totalRecords={props.depositData?.totalRecords}
                        paginationFirstValue={props.pageNumber}
                        paginationSecondValue={props.itemsPerPage}
                        setPaginationFirstValue={props.setPaginationFirstValue}
                        setPaginationSecondValue={props.setPaginationSecondValue}
                        recordsShow={props.recordsShow}
                        setRecordsShow={props.setRecordsShow}
                        isVisible={props.visible}
                        setIsVisible={props.setVisible}
                        callApi={props.apicall}
                        hideDocs={true}
                    /> : null

            }
            {
                props.isVisible && props.placeBetPagination ?
                    <Vpagination
                        data={props.placeBetData?.data}
                        totalRecords={props.placeBetData?.totalRecords}
                        paginationFirstValue={props.pageNumber}
                        paginationSecondValue={props.itemsPerPage}
                        setPaginationFirstValue={props.setPaginationFirstValue}
                        setPaginationSecondValue={props.setPaginationSecondValue}
                        recordsShow={props.recordsShow}
                        setRecordsShow={props.setRecordsShow}
                        isVisible={props.visible}
                        setIsVisible={props.setVisible}
                        callApi={props.apicall}
                        hideDocs={true}
                    /> : null

            }
            {
                props.isVisible && props.eventPagination ?
                    <Vpagination
                        data={props.eventLevelData?.data}
                        totalRecords={props.eventLevelData?.totalRecords}
                        paginationFirstValue={props.pageNumber}
                        paginationSecondValue={props.itemsPerPage}
                        setPaginationFirstValue={props.setPaginationFirstValue}
                        setPaginationSecondValue={props.setPaginationSecondValue}
                        recordsShow={props.recordsShow}
                        setRecordsShow={props.setRecordsShow}
                        isVisible={props.visible}
                        setIsVisible={props.setVisible}
                        callApi={props.apicall}
                        hideDocs={true}
                    /> : null

            }

            {
                props.isVisible && props.marketLevelPagination ?
                    <Vpagination
                        data={props.marketLevelData?.data}
                        totalRecords={props.marketLevelData?.totalRecords}
                        paginationFirstValue={props.pageNumber}
                        paginationSecondValue={props.itemsPerPage}
                        setPaginationFirstValue={props.setPaginationFirstValue}
                        setPaginationSecondValue={props.setPaginationSecondValue}
                        recordsShow={props.recordsShow}
                        setRecordsShow={props.setRecordsShow}
                        isVisible={props.visible}
                        setIsVisible={props.setVisible}
                        callApi={props.apicall}
                        hideDocs={true}
                    /> : null

            }
        </div>
    )
}

export default ReportPagination
