import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import { useDispatch } from 'react-redux'
import { getAffArtwork, setArtworkItems, setArtworkRecords, setArtworkVisible, setArtwork_page } from './AffiliateSlice'
import No__Data__Found from '../Common/table/No__Data__Found';
import { exportPDFC } from '../../sharedfiles/jsPdfCommon';
import Vpagination from '../../sharedfiles/Vpagination';
import Tooltips from '../Common/Tooltip';
import { getDateTimeFormate } from '../../sharedfiles/helper';

const ArtworkPopup = (props) => {
    const dispatch = useDispatch()
    const [csvData, setCsvData] = useState([])
    const [pdfBody, setPdfBody] = useState([])
    const [image, setImage] = useState("")
    const [imagePopup, setImagePopup] = useState(false)
    const artworlList = useSelector((p) => p.affiliateSlice.getUploadFiles)

    const paginationFirstValue = useSelector((p) => p.affiliateSlice.artwork_page)
    const paginationSecondValue = useSelector((p) => p.affiliateSlice.artworkItems)
    const recordsToShow = useSelector((p) => p.affiliateSlice.artworkRecords)
    const isVisible = useSelector((p) => p.affiliateSlice.artworkVisible)

    useEffect(() => {
        dispatch(getAffArtwork(0, 25))
    }, [])
    const tHeader = [
        [
            "S.No",
            "Artwork Name",
            "Updated On",
            "Updated By"
        ]
    ]
    const tableHeader = [
        "S.No",
        "Artwork Name",
        "Updated On",
        "Updated By",
        "Action Items"
    ]
    const artworkInfo = [
        "Showing S.No",
        "Showing Artwork Name",
        "Showing Updated On",
        "Showing Updated By",
        "Showing Action Items"
    ]
    const update = () => {
        const tBody = artworlList &&
            artworlList.data &&
            Array.isArray(artworlList.data)
            ? artworlList.data.map((p) => [p.id, p.docName?.split(".")[0], getDateTimeFormate(p.updated_date), p.updatedBy])
            : [];
        setPdfBody(tBody);
        const data = Array.isArray(tBody) && tBody.length > 0 ? [tHeader[0], ...tBody] : [tHeader[0], []];
        setCsvData(data);

    }
    useEffect(() => {
        update()
    }, [artworlList])
    const exportPDF = () => {
        const title = "Artwork Report";
        const styles = {
            4: {
                halign: "right",
                valign: "middle",
            },
        };
        exportPDFC(title, tHeader, pdfBody, styles);
    }
    const callPageApi = (pageNumber, itemsPerPage) => {
        dispatch(setArtworkVisible(true));
        dispatch(getAffArtwork(pageNumber - 1, itemsPerPage))
    }

    const onViewBtn = (img) => {
        console.log(img);
        setImagePopup(true)
        setImage(img)
    }
    return (
        <>
            <Dialog__Main
                dOpen={imagePopup}
                dClose={() => setImagePopup(false)}
                title={"Artwork"}
                width={"sm"}
            >
                <div
                    style={
                        {
                            height: "400px",
                            msOverflow: "scroll",
                            userSelect: "text",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }
                    }
                >
                    <img src={image} />
                </div>
            </Dialog__Main>
            <Dialog__Main
                dOpen={props.openArtwork}
                dClose={props.setOpenArtwork}
                title={"Artwork Details"}
                width={'700px'}
            >
                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={artworlList.data}
                            totalRecords={artworlList.totalRecords}
                            paginationFirstValue={paginationFirstValue}
                            paginationSecondValue={paginationSecondValue}
                            setPaginationFirstValue={setArtwork_page}
                            setPaginationSecondValue={setArtworkItems}
                            recordsShow={recordsToShow}
                            setRecordsShow={setArtworkRecords}
                            isVisible={isVisible}
                            setIsVisible={setArtworkVisible}
                            csvData={csvData}
                            exportPDF={exportPDF}
                            callApi={callPageApi}
                            csvFileName={"Artwork Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <div className='CMS-tab-panel active' id='CMS-playerActivity'>
                    <div className='CMS-tabContent'>
                        <div className='CMS-filter-result' id='result'>
                            <div className='CMS-box'>
                                <div className='CMS-table CMS-table-triped'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    tableHeader.map((item, index) => (
                                                        <th>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <p className='mx-3'>{item}</p>
                                                                <Tooltips info={`${artworkInfo[index]}`} />
                                                            </div>
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {artworlList &&
                                                artworlList.data && artworlList.data.length > 0 ? (
                                                artworlList.data.map((item, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.id}</td>
                                                            <td>{item.docName?.split(".")[0]}</td>
                                                            <td>{getDateTimeFormate(item.updated_date)}</td>
                                                            <td>{item.updatedBy}</td>
                                                            <td>
                                                                <button
                                                                    className="CMS-btn CMS-btnSecondary active CMS-btnSmall pointer"
                                                                    id="onArtworkViewBtn"
                                                                    onClick={() => onViewBtn(item.docPath)}
                                                                >
                                                                    <p>View</p>
                                                                </button>
                                                                <a className="artwork-dnld" href={item.docPath}>
                                                                    <span
                                                                        className='material-icons'
                                                                        data-icon='file_download'
                                                                    ></span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            ) : (
                                                <No__Data__Found colspan={20} />
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog__Main>
        </>
    )
}

export default ArtworkPopup