import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { cleanObject } from "../../sharedfiles/helper";

export const PlayerRefSlice = createSlice({
    name: "PlayerRefSlice",
    initialState: {
        referralData: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
        prevFormData: {},
        docsData: [],
        pdfData: [],
    },
    reducers: {
        setReferralData: (state, action) => {
            state.referralData = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setPrevFormData: (state, action) => {
            state.prevFormData = action.payload;
        },
        setDocsData: (state, action) => {
            state.docsData = action.payload;
        },
        setPdfData: (state, action) => {
            state.pdfData = action.payload;
        },
    }
})

export const {
    setReferralData,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,
    setPrevFormData,
    setDocsData,
    setPdfData,

} = PlayerRefSlice.actions

export const getReferralData = (pageNumber, itemPerPage, params) => (dispatch) => {
    const url = `${playerapi.playerReferral}` + `?pageNumber=${pageNumber}&itemsPerPage=${itemPerPage}`
    const obj = cleanObject(params)
    apiCaller(url, "POST", params).then((res) => {
        if (res !== null && res) {
            dispatch(setReferralData(res.data))
            dispatch(setIsVisible(true));
        }
    }).catch((err) => {
        console.log(err);
        // dispatch(setReferralData([]));
        dispatch(setIsVisible(false));

    })

}

export default PlayerRefSlice.reducer