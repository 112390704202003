import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DonutChart = ({ title, chatData }) => {
    const userData = chatData.find(item => item?.channelType === "desktop");
    const retailData = userData ? userData.loginPercentage : '';

    const userDataMob = chatData.find(item => item?.channelType === "mobile");
    const mobileData = userDataMob ? userDataMob.loginPercentage : '';

    const userDataWeb = chatData.find(item => item?.channelType === "web");
    const webData = userDataWeb ? userDataWeb.loginPercentage : '';

    function getLoginPercent(channelType) {
        const userData = chatData.find(item => item?.channelType === channelType);
        return userData ? userData.loginPercentage : 0;
    }

    function getUserCount(channelType) {
        const userData = chatData.find(item => item?.channelType === channelType);
        return userData ? userData.userCount : 0;
    }
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
            height: 305,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false
        },
        subtitle: {
            enabled: false
        },
        plotOptions: {
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal'
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    useHTML: true,
                },
                showInLegend: true,
            },
            series: {
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '200px',
                innerSize: '55%',
                dataLabels: {
                    enabled: true,
                    crop: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    connectorWidth: 0
                }
            },
        },
        colors: ['#FCE700', '#F8C4B4', '#f6e1ea'],
        series: [
            {
                type: 'pie',
                dataLabels: {
                    enabled: false,
                },
                data: [
                    {
                        name: `Web <span>${getLoginPercent('desktop')}%</span>`,
                        y: getUserCount('desktop'),
                        color: '#0766AD',
                    },
                    {
                        name: `Mobile <span>${getLoginPercent('mobile')}%</span>`,
                        y: getUserCount('mobile'),
                        color: '#29ADB2',
                    },
                    // {
                    //     name: `Retail <span>${getLoginPercent('retail')}%</span>`,
                    //     y: getUserCount('retail'),
                    //     color: '#C5E898',
                    // },
                ],
            },
        ],
    });

    useEffect(() => {
        const newChartOptions = {
            chart: {
                type: 'pie',
                height: 305,
            },
            title: {
                text: '',
            },
            credits: {
                enabled: false
            },
            subtitle: {
                enabled: false
            },
            plotOptions: {
                legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'horizontal'
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                        useHTML: true,
                    },
                    showInLegend: true,
                },
                series: {
                    borderWidth: 0,
                    colorByPoint: true,
                    type: 'pie',
                    size: '200px',
                    innerSize: '55%',
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        style: {
                            fontWeight: 'bold',
                            fontSize: '16px'
                        },
                        connectorWidth: 0
                    }
                },
            },
            colors: ['#FCE700', '#F8C4B4', '#f6e1ea'],
            series: [
                {
                    type: 'pie',
                    dataLabels: {
                        enabled: false,
                    },
                    data: [
                        {
                            name: `Web <span>${getLoginPercent('desktop')}%</span>`,
                            y: getUserCount('desktop'),
                            color: '#0766AD',
                        },
                        {
                            name: `Mobile <span>${getLoginPercent('mobile')}%</span>`,
                            y: getUserCount('mobile'),
                            color: '#29ADB2',
                        },
                        // {
                        //     name: `Retail <span>${getLoginPercent('retail')}%</span>`,
                        //     y: getUserCount('retail'),
                        //     color: '#C5E898',
                        // },
                    ],
                },
            ],
        };

        setChartOptions(newChartOptions);
    }, [chatData, retailData, mobileData, webData]);


    return (
        <>
            <div className="CMS-box-graphItem">
                <div className="CMS-box-graphTitle">{title}</div>
                <div className="CMS-graph CMS-highCharts-registration">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps={{ id: 'CMS-highCharts-registration' }}
                    />
                </div>
            </div>
        </>
    );
};

export default DonutChart;
