import React, { useEffect, useRef, useState } from 'react'
import { AFFILIATE_CONSTANTS, COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { getAffiliateSummary } from './AffSummarySlice'
import { getAffArtwork, setAffiliateUsers, setArtworkVisible, setSelected_AffiliateId } from '../Affiliate/AffiliateSlice'
import ArtworkPopup from '../Affiliate/ArtworkPopup'
import { MomentUTCDateTimeEnd, MomentUTCDateTimeToday, formatMoney, getUTCDate, setToLocalStorage } from '../../sharedfiles/helper'
import { getPlayerSearchList, setBtagKey, setPlayerSearch, setLoader, setIsVisible as playersLoader } from '../playerTable/playerSearchTableSlice'
import Datepicker from '../Common/common_inputs/DatePicker';
import { affiliateDates } from '../Common/data/mapData';
import ButtonGroup from '../Common/Buttons/ButtonGroup';

const AffiliateSummary = () => {
    const dispatch = useDispatch()
    const affSummaryData = useSelector((p) => p.AffiliateSummarySlice.affSummaryData)
    const affGgrNgrPermission = useSelector((p) => p.dashboard.affiliate_GGR_NGR)
    const errMsg = useSelector((p) => p.AffiliateSummarySlice.errMsg)
    const affCommissionPermission = useSelector((p) => p.dashboard.affCommission)
    const urlRefs = useRef([])
    const [openArtwork, setOpenArtwork] = useState(false)
    const [dateErr, setDateErr] = useState("")
    const [dateFields, setDateFields] = useState({
        regDateFrom: '',
        regDateTo: '',
    })
    const history = useHistory()
    useEffect(() => {
        dispatch(getAffiliateSummary({}))
    }, [])

    const copyText = (btag, curentBtn) => {
        navigator.clipboard.writeText(btag);
        if (curentBtn !== null && curentBtn !== undefined) {
            curentBtn.style.display = "block";
            setTimeout(() => (curentBtn.style.display = "none"), 1000);
        }
    };



    const AffNavigateHandler = (title, page, btag) => {
        setToLocalStorage("AffUserBtag", btag);
        dispatch(setSelected_AffiliateId(btag));
        dispatch(setAffiliateUsers(btag))
        const payload = {
            affiliateBtag: btag,
        };
        dispatch(playersLoader(false));
        dispatch(setLoader(true));
        dispatch(setPlayerSearch([]));
        dispatch(getPlayerSearchList(0, 25, payload));
        dispatch(setBtagKey(true));
        history.push(`/${page}`);
    }


    const handleArtwork = () => {
        dispatch(setArtworkVisible(true));
        setOpenArtwork(true)
        dispatch(getAffArtwork(0, 25))
        console.log("click");
    }
    function setDateHandler(value, name) {
        setDateFields((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        if (name === "regDateFrom" && value !== '') {
            setDateErr('')
        }
    };
    function onSearch() {
        const payload = {
            endDate: MomentUTCDateTimeEnd(dateFields.regDateTo),
            startDate: MomentUTCDateTimeToday(dateFields.regDateFrom)
        }
        if (dateFields.regDateFrom === '' || dateFields.regDateFrom === null) {
            setDateErr("Start Date is required");
        } else if (dateFields.regDateFrom !== '') {
            setDateErr('');
            dispatch(getAffiliateSummary(payload))
        }

    }
    function onResetBtn() {
        setDateFields({
            regDateFrom: '',
            regDateTo: ''
        })
        setDateErr('')
        dispatch(getAffiliateSummary({}))
    }
    return (
        <>
            <ArtworkPopup
                openArtwork={openArtwork}
                setOpenArtwork={() => setOpenArtwork(false)}
            />
            {affSummaryData &&
                affSummaryData.data ? (
                affSummaryData.data.map((item, ind) => {
                    return (
                        <div>
                            <div className="CMS-tab-panel active" id="CMS-betting">
                                <div className="CMS-tabContent">
                                    <div className="CMS-box CMS-box-content">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="CMS-formGroup">
                                                    <div className="CMS-formLabel">{COMMON_CONSTANTS.header_userName}</div>
                                                    <div className="CMS-formControl">
                                                        <div className="CMS-formControlLabel">{item.userName}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="CMS-formGroup">
                                                    <div className="CMS-formLabel">{AFFILIATE_CONSTANTS.affiliate_Name}</div>
                                                    <div className="CMS-formControl">
                                                        <div className="CMS-formControlLabel">{item.firstName} {item.lastName}</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-4 col-lg-3 col-xl-2">
                                                <div className="CMS-formGroup">
                                                    <div className="CMS-formLabel">{AFFILIATE_CONSTANTS.affiliate_Btag}</div>
                                                    <div className="CMS-formControl copyButton">
                                                        <input className="CMS-formControl-input" value={item.btag} readonly />
                                                        <span className="material-icons copyButtonBg"
                                                            data-icon="content_copy"
                                                            onClick={() =>
                                                                copyText(
                                                                    item.btag,
                                                                    urlRefs.current[ind]
                                                                )
                                                            }
                                                        >
                                                        </span>
                                                        <span
                                                            className='ip_textCopied'
                                                            ref={(element) =>
                                                                urlRefs.current.push(element)
                                                            }
                                                        >
                                                            Copied
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-20"></div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 col-xl-12">
                                                <div className="CMS-btnContainer">
                                                    <button
                                                        id="btnBonusCreate"
                                                        className="CMS-btn CMS-btnSecondary active CMS-btnMedium"
                                                        type="button"
                                                        onClick={() =>
                                                            AffNavigateHandler(
                                                                "PLAYER SEARCH",
                                                                "playersearch",
                                                                item.btag
                                                            )
                                                        }
                                                    >
                                                        {COMMON_CONSTANTS.view_Player}
                                                    </button>
                                                    <button
                                                        onClick={() => handleArtwork()}
                                                        className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                                                        type="button"
                                                    >
                                                        {COMMON_CONSTANTS.view_ArtWork}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="CMS-box CMS-box-content">
                                <div className='row'>
                                    {affiliateDates.map((item, ind) => {
                                        const minDate = [null, dateFields.regDateFrom];
                                        const max = dateFields.regDateTo ? dateFields.regDateTo : new Date();
                                        const maxDate = [max, new Date()];
                                        const values = [dateFields.regDateFrom, dateFields.regDateTo];
                                        return (
                                            <div className='col-3' key={ind}>
                                                <Datepicker
                                                    title={item.title}
                                                    name={item.name}
                                                    value={values[ind]}
                                                    setValue={(date, value) => setDateHandler(date, value)}
                                                    errorMsg={ind === 0 ? dateErr : ""}
                                                    minDate={minDate[ind]}
                                                    maxDate={maxDate[ind]}
                                                    ids={item.ids}
                                                    info={item.info}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="ml-15"></div>
                                    <ButtonGroup
                                        groupNames={["Search", "Reset"]}
                                        idsearch={"searchAffiliateSummery"}
                                        idreset={"affiliateSummeryReset"}
                                        submit={(e) => onSearch(e)}
                                        reset={(e) => onResetBtn(e)}
                                    />
                                </div>
                            </div>
                            <div className="CMS-tabs-content">
                                <div className="CMS-tab-panel active" id="CMS-betting">
                                    <div className="CMS-tabContent">
                                        <div className="CMS-affilateSummary-list">
                                            <ul>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-RegistrationCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Reg}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.totalRegistrations}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-BetsCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Bet}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.totalBets}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-CustomersCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Deposited_Customer}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.depositedUserCounts}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-stakesCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_stake}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalStake)}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-payOutCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Payouts}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalPayout)}
                                                        </div>
                                                    </div>
                                                </li>
                                                {affGgrNgrPermission ? <li>
                                                    <div className="CMS-affilateSummary-item CMS-GGRCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_GGR}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalGgr)}
                                                        </div>
                                                    </div>
                                                </li> : ''}
                                                {affGgrNgrPermission ? <li>
                                                    <div className="CMS-affilateSummary-item CMS-ngrAmount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.ngr}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalNgr)}
                                                        </div>
                                                    </div>
                                                </li> : ''}
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-bonusCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Bonus_Amnt}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.totalBonusAmounts}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-DepositAmountCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Deposit_Amnt}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalDepositAmounts)}

                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-withdrawlAmountCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.total_Withdrawal_Amnt}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.totalWithdrawalAmount)}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-firstTimeDepositedCustomers">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.firstTimeDepositedCustomers}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.firstTimeDepositedCustomers}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-hitsCount">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.clicks_count}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item?.affliateClickCount}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="CMS-affilateSummary-item CMS-setupCostPercentage">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.setup_cost_percentage}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {item.setupCostPercentage}
                                                        </div>
                                                    </div>
                                                </li>
                                                {affCommissionPermission ? <li>
                                                    <div className="CMS-affilateSummary-item CMS-setupCost">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.setup_cost}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.setupCost)}
                                                        </div>
                                                    </div>
                                                </li> : ''}
                                                {affCommissionPermission ? <li>
                                                    <div className="CMS-affilateSummary-item CMS-grossCommission">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.gross_commission}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.grossCommission)}
                                                        </div>
                                                    </div>
                                                </li> : ''}
                                                {affCommissionPermission ? <li>
                                                    <div className="CMS-affilateSummary-item CMS-netCommission">
                                                        <div className="CMS-affilateSummary-itemLabel">
                                                            {AFFILIATE_CONSTANTS.net_commission}
                                                        </div>
                                                        <div className="CMS-affilateSummary-itemCount ">
                                                            {formatMoney(item.netCommission)}
                                                        </div>
                                                    </div>
                                                </li> : ''}
                                            </ul>
                                            <div className="mt-20"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) :
                (
                    <div className='CMS-tab-panel active' id='CMS-playerActivity'>
                        <div className='CMS-tabContent'>
                            <div className='CMS-filter-result' id='result'>
                                <div className='CMS-box'>
                                    <div className='CMS-msgBox'>
                                        <div className='CMS-msgBox-container'>
                                            {errMsg}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default AffiliateSummary