import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RISK_MANAGEMENT_CONSTANTS } from '../../sharedfiles/Constants'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import No__Data__Found from '../Common/table/No__Data__Found'
import Table from '../Common/table/Table'
import { getFraudIPAndMacAddress, getFraudIPAndMacByUsers, setFraudMacIpIsvisible, setFraudMacIpPageOneValue, setFraudMacIpPageSecondValue, setFraudMacIpRecordShow, setUserMacIpIsVisible, setUserMacIpPageOneValue, setUserMacIpSecondValue, setUserMacIpShowRecord } from './RiskmgmtSlice'
import { RiskMgmnt } from '../../sharedfiles/tooltipMsgs'
import Tooltips from '../Common/Tooltip'
import Vpagination from '../../sharedfiles/Vpagination'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'

const UsersMacIP = () => {
    const [openDialog, setOpenDialog] = useState(false)
    const [tableData, setTableData] = useState('')
    const [macIpPdfData, setMacIpPdfData] = useState([])
    const [macIpCsvData, setMacIpCsvData] = useState([])
    const [userPdfData, setUserPdfData] = useState([])
    const [userCsvData, setUserCsvData] = useState([])
    const [ip, setIp] = useState('')
    const [mac, setMac] = useState('')
    const dispatch = useDispatch()
    const fraudIpMac = useSelector((state) => state.riskManagement.fraudIpMacAdress)
    const fraudIpMacByUser = useSelector((state) => state.riskManagement.fraudIpMacByUsers)

    const macIpPageOne = useSelector((state) => state.riskManagement.fraudMacIpPageOneValue)
    const macIpSecondOne = useSelector((state) => state.riskManagement.fraudMacIpPageSecondValue)
    const macIpIsvisible = useSelector((state) => state.riskManagement.fraudMacIpIsvisible)
    const macIpRecordShow = useSelector((state) => state.riskManagement.fraudMacIpRecordShow)

    const userPageOne = useSelector((state) => state.riskManagement.userMacIpPageOneValue)
    const userSecondOne = useSelector((state) => state.riskManagement.userMacIpSecondValue)
    const userIsVisible = useSelector((state) => state.riskManagement.userMacIpIsVisible)
    const userShowRecord = useSelector((state) => state.riskManagement.userMacIpShowRecord)

    const ipOpen = (ip, mac) => {
        dispatch(setUserMacIpIsVisible(true))
        setOpenDialog(true)
        dispatch(getFraudIPAndMacByUsers(ip, mac, 0, 25))
        setIp(ip)
        setMac(mac)
    }

    const onClose = () => {
        setOpenDialog(false)
    }

    const tHeader = [
        [
            "CUSTOMERS FROM IP"
        ],
    ];

    const info = [
        RiskMgmnt.Cid_frmIp
    ]

    const infoheader = [
        RISK_MANAGEMENT_CONSTANTS.ipAddress,
        RISK_MANAGEMENT_CONSTANTS.macAddress,
        RISK_MANAGEMENT_CONSTANTS.no_User
    ]

    const infoHeader = [
        RiskMgmnt.IP_Address,
        RiskMgmnt.Mac_Address,
        RiskMgmnt.No_Users,
    ]
    //USER POP CSVPDF DATA//
    const update = () => {
        const tbody = fraudIpMacByUser && fraudIpMacByUser.data &&
            fraudIpMacByUser.data.length > 0 ? fraudIpMacByUser.data.map((item) => [item.custIds]) : []
        const tbdata = [tHeader, info, ...tbody]
        setTableData(tbdata)
        setUserPdfData(tbody)
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tHeader[0], ...tbody] : [tHeader[0], []];
        setUserCsvData(data);
    }

    const UserexportPDF = () => {
        const title = "Customer From Ip Report";
        const styles = {
            marginTop: "10px"
        };
        const customHeader = 12
        exportPDFC(title, tHeader, userPdfData, styles, customHeader)
    }

    const callPagePopApi = (pageNumber, itemsPerPage) => {
        dispatch(setUserMacIpIsVisible(true))
        dispatch(getFraudIPAndMacByUsers(ip, mac, pageNumber - 1, itemsPerPage))
    }
    //USER POP CSVPDF DATA//

    const tableStyles = {
        0: "text-center",
    };

    useEffect(() => {
        dispatch(setFraudMacIpIsvisible(true))
        dispatch(getFraudIPAndMacAddress(0, 25))
    }, [])

    useEffect(() => {
        update();
    }, [fraudIpMacByUser])

    useEffect(() => {
        onMacIpUpdate();
    }, [fraudIpMac])

    //MAC IP CSVPDF DATA//
    const tbHeader = [
        [
            "IP ADDRESS",
            "MAC ADDRESS",
            "NUMBER OF USERS"
        ]
    ]
    const onMacIpUpdate = () => {
        const tbody = fraudIpMac && fraudIpMac.data &&
            Array.isArray(fraudIpMac.data) ? fraudIpMac.data.map((item) => [item.ip, item.macAddress, item.count]) : []
        setMacIpPdfData(tbody);
        const data = Array.isArray(tbody) && tbody.length > 0 ? [tbHeader[0], ...tbody] : [tbHeader[0], []];
        setMacIpCsvData(data);
    }
    const macIpExportPDF = () => {
        const title = 'Users with same MAC & IP Address Report'
        exportPDFC(title, tbHeader, macIpPdfData)
    }
    const callPageApi = (pageNumber, itemsPerPage) => {
        dispatch(setFraudMacIpIsvisible(true))
        dispatch(getFraudIPAndMacAddress(pageNumber - 1, itemsPerPage))
    }
    //MAC IP CSVPDF DATA//

    return (
        <>
            <Dialog__Main
                title={"CUSTOMERS FROM IP"}
                dOpen={openDialog}
                dClose={() => onClose()}
                width={'700px'}
            >
                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={fraudIpMacByUser.data}
                            totalRecords={fraudIpMacByUser.totalRecords}
                            paginationFirstValue={userPageOne}
                            paginationSecondValue={userSecondOne}
                            setPaginationFirstValue={setUserMacIpPageOneValue}
                            setPaginationSecondValue={setUserMacIpSecondValue}
                            recordsShow={userShowRecord}
                            setRecordsShow={setUserMacIpShowRecord}
                            isVisible={userIsVisible}
                            setIsVisible={setUserMacIpIsVisible}
                            csvData={userCsvData}
                            exportPDF={UserexportPDF}
                            callApi={callPagePopApi}
                            csvFileName={"Customer From Ip Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <Table tableData={tableData} linkIndex={0} styles={tableStyles} />
            </Dialog__Main>
            <div className='CMS-tabs-panel'>
                <div className='CMS-tabs-content'>
                    <div className='CMS-tabContent'>
                        <div className='CMS-filter-result' id='result'>
                            <div className="CMS-pagination">
                                <Vpagination
                                    data={fraudIpMac.data}
                                    totalRecords={fraudIpMac.totalRecords}
                                    paginationFirstValue={macIpPageOne}
                                    paginationSecondValue={macIpSecondOne}
                                    setPaginationFirstValue={setFraudMacIpPageOneValue}
                                    setPaginationSecondValue={setFraudMacIpPageSecondValue}
                                    recordsShow={macIpRecordShow}
                                    setRecordsShow={setFraudMacIpRecordShow}
                                    isVisible={macIpIsvisible}
                                    setIsVisible={setFraudMacIpIsvisible}
                                    csvData={macIpCsvData}
                                    exportPDF={macIpExportPDF}
                                    callApi={callPageApi}
                                    csvFileName={"Users With Same MAC & IP Address Report"}
                                    reloadRequired={false}
                                />
                            </div>
                            <div className='CMS-box '>
                                <div className='CMS-table CMS-table-triped'>
                                    <table>
                                        <thead>
                                            <tr>
                                                {infoheader.map((item, index) => (
                                                    <th>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <p className='mx-3'>{item}</p>
                                                            <Tooltips info={`${infoHeader[index]}`} />
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fraudIpMac && fraudIpMac.data
                                                && fraudIpMac.data.length > 0 ? (
                                                fraudIpMac.data.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.ip}</td>
                                                            <td>{item.macAddress}</td>
                                                            <td className='link_style pointer' onClick={() => ipOpen(item.ip, item.macAddress)}>{item.count}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <No__Data__Found colspan={20} />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersMacIP