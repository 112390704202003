import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { Currency } from '../../../sharedfiles/helper';

// Initialize the solid-gauge module
highchartsMore(Highcharts);
solidGauge(Highcharts);

const SpeedGaugeGraph = ({ containerId, title, Period, Overall, ratio, noCurrency, noPeriodOverall }) => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'solidgauge',
            backgroundColor: 'rgba(0,0,0,0)',
            height: 135


        },
        title: null,

        pane: {
            center: ['50%', '85%'],
            size: '140%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            stops: [
                [0.1, '#55BF3B'],
                [0.5, '#DDDF0D'],
                [0.9, '#DF5353']
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: -70
            },
            labels: {
                enabled: false
            }
        },

        credits: {
            enabled: false,
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                }
            },
        }
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setChartOptions((prevOptions) => {
                const newVal = Math.round((Math.random() - 0.5) * 100);
                const newOptions = {
                    ...prevOptions,
                    yAxis: {
                        min: 0,
                        max: 200,
                        title: {
                            text: null
                        }
                    },
                    series: [{
                        name: 'Speed',
                        data: [ratio],
                        dataLabels: noPeriodOverall ? {
                            format:
                                '<div style="text-align:center">' +
                                `<span style="font-size:20px">${ratio}%</span><br/>`
                                +
                                '</div>'
                        } : {
                            format:
                                '<div style="text-align:center">' +
                                `<span style="font-size:20px">${ratio}%</span><br/>` +
                                '<span style="font-size:11px;opacity:0.4">Period Vs Overall</span>' +
                                '</div>'
                        },
                        tooltip: {
                            valueSuffix: 'km/h'
                        }
                    }]
                };
                return newOptions;
            });
        }, 2000);

        return () => clearInterval(intervalId);
    }, [ratio]);

    return (
        <>
            <div className="CMS-box-graphItem">
                <div className="CMS-box-graphTitle">{title}</div>
                <div className="CMS-graph CMS-gaugeCharts">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps={{ id: "CMS-gaugeChart-view", className: "CMS-guageGraph" }}
                    />
                </div>
                <div className="CMS-box-graphValue">
                    {
                        !noPeriodOverall ? (
                            <span>
                                {noCurrency ? Period : Currency() + Period}
                                {Overall ? `/${Overall}` : ""}
                            </span>
                        ) : (
                            ''
                        )
                    }
                </div>
                {/* <div className="CMS-btnContainer">
                    <button className="CMS-btn CMS-btnSecondary active CMS-btnMedium" type="button">View Report</button>
                </div> */}
            </div>
        </>
    );
}

export default SpeedGaugeGraph;
