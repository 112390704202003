import React, { useEffect, useState } from 'react'
import Datepicker from '../Common/common_inputs/DatePicker';
import { failedReportsDate } from '../Common/data/mapData';
import { getFailedData } from './FailedReportSlice';
import { getFormattedDate, MomentUTCDateTimeEnd, MomentUTCDateTimeToday } from '../../sharedfiles/helper';
import { useDispatch, useSelector } from 'react-redux';



const FailedReports = () => {
    const dispatch = useDispatch()
    const { failedData } = useSelector((p) => p.FailedReportSlice)
    const getDefaultDateRange = () => {
        const startDate = getFormattedDate(1);
        const endDate = new Date();
        return { startDate, endDate };
    };
    const { startDate, endDate } = getDefaultDateRange();

    const [dateFields, setDateFields] = useState({
        fromDate: startDate,
        toDate: endDate
    })
    const [dateErr, setDateErr] = useState("")


    useEffect(() => {
        dispatch(getFailedData({
            fromDate: MomentUTCDateTimeToday(startDate),
            toDate: MomentUTCDateTimeEnd(endDate)
        }))
    }, []);
    function setDateHandler(value, name) {
        setDateFields((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        if (name === "fromDate" && value !== '') {
            setDateErr('')
        }
    };
    function onSearch() {
        const payload = {
            fromDate: MomentUTCDateTimeToday(dateFields.fromDate),
            toDate: MomentUTCDateTimeEnd(dateFields.toDate)
        }
        if (dateFields.fromDate === '' || dateFields.fromDate === null) {
            setDateErr("Start Date is required");
        } else if (dateFields.fromDate !== '') {
            setDateErr('');
            dispatch(getFailedData(payload))
        }

    }
    return (
        <div className='CMS-tab-panel active' id='CMS-playerActivity'>
            <div className='CMS-tabContent'>
                <div className="CMS-box CMS-box-content">
                    <div className='row'>
                        {failedReportsDate.map((item, ind) => {
                            const minDate = [null, dateFields.fromDate];
                            const max = dateFields.toDate ? dateFields.toDate : new Date();
                            const maxDate = [max, new Date()];
                            const values = [dateFields.fromDate, dateFields.toDate];
                            return (
                                <div className='col-3' key={ind}>
                                    <Datepicker
                                        title={item.title}
                                        name={item.name}
                                        value={values[ind]}
                                        setValue={(date, value) => setDateHandler(date, value)}
                                        errorMsg={ind === 0 ? dateErr : ""}
                                        minDate={minDate[ind]}
                                        maxDate={maxDate[ind]}
                                        ids={item.ids}
                                        info={item.info}
                                    />
                                </div>
                            );
                        })}
                        <div className="ml-15"></div>
                    </div>
                    <div className='row mt-20 d-flex justify-content-center'>
                        <button
                            onClick={(e) => onSearch(e)}
                            className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                            type={"button"}
                            id={"addCampaign"}
                        >
                            {"Search"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="CMS-tabs-content">
                <div className="CMS-tab-panel active" id="CMS-betting">
                    <div className="CMS-tabContent">
                        <div className="CMS-affilateSummary-list">
                            <ul>
                                {failedData?.betDetails?.map((item, index) => (
                                    <li key={`bet-${index}`}>
                                        <div className="CMS-affilateSummary-item CMS-RegistrationCount">
                                            <div className="CMS-affilateSummary-itemLabel">{"Failed Bet Count"}</div>
                                            <div className="CMS-affilateSummary-itemCount ">{item.failedBetCount}</div>
                                        </div>
                                    </li>
                                ))}
                                {failedData?.depositDetails?.map((item, index) => (
                                    <li key={`deposit-${index}`}>
                                        <div className="CMS-affilateSummary-item CMS-BetsCount">
                                            <div className="CMS-affilateSummary-itemLabel">{"Failed Deposit Count"}</div>
                                            <div className="CMS-affilateSummary-itemCount ">{item.failedDepositCount}</div>
                                        </div>
                                    </li>
                                ))}
                                {failedData?.withdrawDetails?.map((item, index) => (
                                    <li key={`withdraw-${index}`}>
                                        <div className="CMS-affilateSummary-item CMS-CustomersCount">
                                            <div className="CMS-affilateSummary-itemLabel">{"Failed Withdraw Count"}</div>
                                            <div className="CMS-affilateSummary-itemCount ">{item.failedWithdrawCount}</div>
                                        </div>
                                    </li>
                                ))}
                                {failedData?.registrationDetails?.map((item, index) => (
                                    <li key={`withdraw-${index}`}>
                                        <div className="CMS-affilateSummary-item CMS-payOutCount">
                                            <div className="CMS-affilateSummary-itemLabel">{"Failed Registration Count"}</div>
                                            <div className="CMS-affilateSummary-itemCount ">{item.failedRegistrationCount}</div>
                                        </div>
                                    </li>
                                ))}
                                {failedData?.loginDetails?.map((item, index) => (
                                    <li key={`withdraw-${index}`}>
                                        <div className="CMS-affilateSummary-item CMS-GGRCount">
                                            <div className="CMS-affilateSummary-itemLabel">{"Failed Login Count"}</div>
                                            <div className="CMS-affilateSummary-itemCount ">{item.failedLoginCount}</div>
                                        </div>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FailedReports