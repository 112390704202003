import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const AddNoteSlice = createSlice({
  name: "addNotesSlice",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    userData: [],
    notesData: [],
    addnoteid: 0,
    recordsShow: [1, 25],
    isVisible: false,
  },
  reducers: {
    setNotes: (state, action) => {
      state.notesData = action.payload;
    },
    setAddNoteValue: (state, action) => {
      state.addnoteid = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});
export const {
  setNotes,
  setAddNoteValue,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setMessage,
  resetNotesData,
  setRecordsShow,
  setIsVisible,
} = AddNoteSlice.actions;

export const getAddNotestableList = (id, params) => (dispatch, getState) => {
  var obj = {
    category: params.category,
    note: params.note,
  };
  var url = `${playerapi.getAddNotes}` + id + "/note";
  const { addNotesSlice } = getState();
  console.log("I'm callig in adding notes to DB caller");
  apiCaller(url, "POST", obj)
    .then((response) => {
      // if (response !== null && response) {
      dispatch(getNotesList(addNotesSlice.paginationFirstValue - 1, addNotesSlice.paginationSecondValue))
      console.log("I'm callig in adding notes to DB caller at line -78");
      if (response.status == 200) {
        toaster.success("NOTE ADDED");
      }
      // }
    })
    .catch((error) => {
      console.log("error in  adding note: ", error);
      toaster.error("FAILED TO ADD NOTE");
    });
};

//dev.platform-ups.sportsit-tech.net:443/player/notes?pageNumber=0&itemsPerPage=25
export const getNotesList = (pagenumbervalue, itemperpagevalue) => (dispatch) => {
  const id = localStorage.getItem("userId")
  var url =
    `${playerapi.getNotes}` +
    "?pageNumber=" +
    pagenumbervalue +
    "&itemsPerPage=" +
    itemperpagevalue;

  apiCaller(url, "POST", { playerId: id }).then((response) => {
    if (response !== null && response) {
      if (response.status == 200) {
        dispatch(setNotes(response.data));
      }
      dispatch(setIsVisible(true));
    }
  });
};
export default AddNoteSlice.reducer;
