import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPaymentSearchtableList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setViewTable,
  setIsVisible,
  setFormData,
  setDocsData,
  setReportDocs,
  setReportsLoader,
  getPaymetProviders,
  getPaymentLogs,
  cancelWithdrawal
} from "./paymentSearchSlice";
import {
  getDateTimeFormate,
  formatMoney,
  cleanObject,
  Currency,
  sortingFilter,
  hideMobile,
  hideData,
  replaceWithSpace,
  getUTCDate,
  getBrowserDate,
  divideAmountBy100,
  getFormattedDate,
  MomentUTCDateTimeToday,
  MomentUTCDateTimeEnd,
  extractedDate,
} from "../../sharedfiles/helper";
import PaymentSearchTable from "./paymentSearchTable";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import {
  PaymentsearchData1,
  PaymentsearchData2,
  PaymentsearchData3,
  PaymentsearchData4,
  PaymentsearchUser,
} from "../Common/data/mapData";
import {
  transactionType,
  transactionStatus,
  option,
  adjustmentOption,
  paymentSearchHeaders,
  paymentSortables,
  paymentsrchInfo,
  paymentTypes,
  viewLogInfo,

} from "../Common/data/Payments/PaymentsearchData";
import Skelton from "../Common/skelton/Skelton";
import { COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import { PLAYER_MODULE, Payments } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Table from "../Common/table/Table";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import KycCommentBox from "../KycUsers/KycCommentBox";
import AmountRangeIp from "../Common/common_inputs/AmountRangeIp";
import { roleData } from "../Common/data/Players/PlayersData";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";
class PaymentTicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      createDate: "",
      pageNumber: 1,
      isActive: "1",
      document: "",
      transactionId: "",
      startDate: "",
      endDate: "",
      customerId: "",
      customerName: "",
      amount: "",
      transactionIP: "",
      transactionRef: "",
      transactionType: "",
      status: "",
      popUp: false,
      modalTitle: "",
      modalFeedback: "",
      modalTransactionId: "5e9b0bc1-54ae-4e3e-a7ad-2333cac9d8a4",
      payloadData: {},
      ipAddress: "",
      itemsperpage: "",
      csvData: [],
      pdfData: [],
      dateError: "",
      option: "",
      adjustmentType: "",
      reportDocs: {
        pdf: [],
        csv: []
      },
      masking: false,
      sortFields: {
        "Customer ID": false,
        "Customer Name": false,
        "Action by": false,
        "Transaction Type": false,
        "Transaction Date": false,
        "Updated Date": false,
        "Status": false,
        [`Amount ${Currency()}`]: false,
        key: "",
        direction: "",
        keyType: ""
      },
      paymentMethods: "",
      openDetails: false,
      selectedTxnId: null,
      paymentType: "",
      headers: [
        "Transaction ID",
        "Payment Description",
        "Event Type",
        "Reference",
        "Created",
        "Payment Methods",
        "Payment Type",
        "Request/Response"
      ],
      dialogData: [],
      btnStatus: '',
      declineBox: false,
      txnId: '',
      amountMax: '',
      amountMin: '',
      affliateBtag: '',
      rangeErr: '',
      role: '',
      clearAllPayment: false,
      paymentSelected: [],
      paymentId: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.myDocument = this.myDocument.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.onChangetransactionType = this.onChangetransactionType.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onChangeAdjustmentType = this.onChangeAdjustmentType.bind(this);
    this.callApi = this.callApi.bind(this);
    this.updatePdfCsv = this.updatePdfCsv.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetCsvData = this.resetCsvData.bind(this);
    this.setDateHandler = this.setDateHandler.bind(this);
    this.searchUserData = this.searchUserData.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.onViewBtn = this.onViewBtn.bind(this)
  }
  setDateHandler(value, name) {
    this.setState({ [name]: value });
    let payload = this.searchUserData();
    // this.formDataToSliceSetter(name, value);
    if (name === "startDate") {
      payload["startDate"] = value ? MomentUTCDateTimeToday(value) : '';
      this.setState({ dateError: "" });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": "" });
    } else {
      payload["endDate"] = value ? MomentUTCDateTimeEnd(value) : '';
      const dateErr = this.state.startDate === "" ? "Start Date is required" : "";
      this.setState({
        dateError: dateErr,
      });
      this.formDataToSliceSetter("", "", { [name]: value, "dateError": dateErr });
    }
  }

  onChangetransactionType(e) {
    if (e.target.value === "ALL") {
      this.setState({ adjustmentType: "" });
    }
    this.setState({ transactionType: e.target.value });
    this.formDataToSliceSetter("transactionType", e.target.value)
  }
  onChangeOption(e) {
    if (e.target.value === "ONLINE") {
      this.setState({ adjustmentType: "" });
    }
    this.setState({ option: e.target.value });
    this.formDataToSliceSetter("option", e.target.value)
  }
  onChangeStatus(e) {

    this.setState({ status: e.target.value });
    this.formDataToSliceSetter("status", e.target.value)
  }
  onchangePaymentType(e) {
    this.setState({ paymentType: e.target.value });
    this.formDataToSliceSetter("paymentType", e.target.value)
  }
  onChangeAdjustmentType(e) {
    this.setState({ adjustmentType: e.target.value });
    this.formDataToSliceSetter("adjustmentType", e.target.value)
  }


  emptyState(exceptions) {
    const checkExist = (key) => exceptions && Array.isArray(exceptions) && exceptions.length > 0 ? exceptions.includes(key) : false;
    this.setState({
      transactionId: "",
      paymentSelected: [],
      paymentId: [],
      startDate: '',
      endDate: MomentUTCDateTimeEnd(''),
      customerId: "",
      clearAllPayment: true,
      customerName: "",
      amount: "",
      transactionIp: "",
      transactionRef: "",
      ...(!checkExist("transactionType") && { transactionType: "" }),
      status: "",
      // payloadData: {},
      ipAddress: "",
      itemsperpage: "",
      csvData: [],
      pdfData: [],
      dateError: "",
      adjustmentType: "",
      paymentMethods: "",
      paymentType: "",
      role: '',
      ...(!checkExist("option") && { option: "" })
    });
    this.props.dispatch(setFormData({}));
    setTimeout(() => {
      this.setState({ clearAllPayment: false })
    }, 1000);
  }

  handleReset(e) {
    this.emptyState();
    this.setState({
      payloadData: {},
      reportDocs: {
        pdf: [],
        csv: []
      },
      sortFields: {
        "Customer ID": false,
        "Customer Name": false,
        "Action by": false,
        "Transaction Type": false,
        "Transaction Date": false,
        "Updated Date": false,
        "Status": false,
        [`Amount ${Currency()}`]: false,
        key: "",
        direction: "",
        keyType: ""
      },
      amountMax: '',
      amountMin: '',
      affliateBtag: '',
      rangeErr: '',
    });
    this.setState({
      startDate: '',
      endDate: '',
    })
    // this.handlePageChange(1);
    this.props.dispatch(setViewTable(false));
    // this.props.dispatch(setIsVisible(false));
    this.props.dispatch(
      getPaymentSearchtableList(0, 25, {

      })
    );
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setRecordsShow([1, 25]));
    this.props.dispatch(setFormData({}));
    this.props.dispatch(setDocsData([]));
  }


  callApi(pageNumber, itemsPerPage, data, activeAll) {
    !activeAll && this.props.dispatch(setIsVisible(false));
    const { ...filterData } = data;
    const payload = {
      ...filterData,
      startDate: this.state.startDate ? MomentUTCDateTimeToday(this.state.startDate) : '',
      endDate:
        this.state.endDate ? MomentUTCDateTimeEnd(this.state.endDate) : '',
    }
    // this.props.dispatch(setViewTable(false));
    this.props.dispatch(
      getPaymentSearchtableList(
        pageNumber - 1,
        itemsPerPage,
        payload,
        activeAll
      )
    );
  }
  searchUserData() {
    const endData = extractedDate(this.state.endDate)
    const startDate = extractedDate(this.state.startDate)

    return {
      transactionId: this.state.transactionId,
      startDate:
        this.state.startDate
          ? MomentUTCDateTimeToday(this.state.startDate)
          : "",
      endDate:
        this.state.endDate ? MomentUTCDateTimeEnd(this.state.endDate) : "",
      userId: this.state.customerId,
      userName: this.state.customerName,
      amount:
        this.state.amount !== "" ? this.state.amount * 100 : this.state.amount,
      transactionRef: this.state.transactionRef,
      paymentType: this.state.transactionType.toUpperCase(),
      status: this.state.status.toUpperCase(),
      ipAddress: this.state.ipAddress,
      manualAdjustment: this.state.adjustmentType,
      paymentApprovalType: this.state.option,
      modePaymentClient: this.state.paymentMethods,
      paymentMethodType: this.state.paymentSelected,
      amountMax: this.state.amountMax !== '' ? this.state.amountMax * 100 : this.state.amountMax,
      amountMin: this.state.amountMin !== '' ? this.state.amountMin * 100 : this.state.amountMin,
      affliateBtag: this.state.affliateBtag,
      role: this.state.role
    };
  }
  // onFormSubmit(e) {
  //   e.preventDefault();
  // }

  onPaymentFilter(e) {
    this.setState({ paymentMethods: e.target.value });
    this.formDataToSliceSetter("paymentMethods", e.target.value)
  }
  onSubmit(e) {
    e.preventDefault();
    const data = cleanObject(this.searchUserData());
    console.log("data: ", data);
    this.setState({ payloadData: data });
    if (Object.keys(data).length > 0) {
      if (!this.state.dateError && !this.state.rangeErr) {
        this.props.dispatch(setIsVisible(false));
        this.props.dispatch(
          getPaymentSearchtableList(0, 25, data)
        );
        this.props.dispatch(setPaginationFirstValue(1))
        this.props.dispatch(setPaginationSecondValue(25))
        this.props.dispatch(setRecordsShow([1, 25]))
        this.setState({ csvData: [] });
      }

    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.formDataToSliceSetter(event.target.name, event.target.value)
  }

  rangeHandle(e) {
    const { name, value } = e.target;

    if (name === "amountMin") {
      this.setState((prevState) => ({
        amountMin: value.trim(),
        rangeErr: prevState.amountMax !== '' && parseInt(value) > parseInt(prevState.amountMax)
          ? 'Max amount should not be less than min amount'
          : '',
      }));
    }

    if (name === "amountMax") {
      this.setState((prevState) => ({
        amountMax: value.trim(),
        rangeErr: prevState.amountMin !== '' && parseInt(prevState.amountMin) > parseInt(value)
          ? 'Max amount should not be less than min amount'
          : '',
      }));
    }

    this.formDataToSliceSetter(name, value);
  }

  myDocument(e) {
    this.setState({ document: e.target.value });
  }
  tableStyles = {
    10: "text-right",
  };
  exportPDF(totalRecords) {
    const title = "Payment Search Report";

    const styles = {
      10: {
        halign: "right",
        valign: "middle",
      },
    };
    const pdfData = totalRecords ? this.props.reportDocs?.pdf : this.state.pdfBody
    exportPDFC(title, [paymentSearchHeaders], pdfData, styles);
  }

  handleFeeback(e) {
    this.setState({
      modalFeedback: e.target.value,
    });
  }
  resetCsvData() {
    this.setState({
      csvData: [],
      pdfBody: [],
    });
  }
  searchResults(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }
  getDefaultDateRange = () => {
    const startDate = getFormattedDate(1);
    const endDate = new Date();
    return { startDate, endDate };
  }
  componentDidMount() {
    const { startDate, endDate } = this.getDefaultDateRange();
    document.addEventListener("keydown", this.searchResults);
    this.props.dispatch(setViewTable(false));
    if (this.props.location.state !== undefined) {
      this.setState({ transactionId: this.props.location.state.id });
      this.props.dispatch(
        getPaymentSearchtableList(
          this.props.paginationFirstValue - 1,
          this.props.paginationSecondValue,
          {
            transactionId: this.props.location.state.id,
          },
        )
      );
      this.props.dispatch(getPaymetProviders())
    } else {
      // this.callApi(this.props.paginationFirstValue);
      const dataExist = Object.keys(this.props?.userData).length > 0
      if (!dataExist) {
        const systemPayload = {
          startDate: MomentUTCDateTimeToday(startDate),
          endDate: MomentUTCDateTimeEnd(endDate),
        };
        this.setState({ startDate, endDate })
        this.props.dispatch(setFormData({ ...this.props.formData, startDate, endDate }))
        this.props.dispatch(
          getPaymentSearchtableList(
            this.props.paginationFirstValue - 1,
            this.props.paginationSecondValue,
            systemPayload,
          )
        );
        this.props.dispatch(getPaymetProviders())
      } else {
        const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0
        let pdfData = [];
        if (csvDataExist) {
          pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
        }
        this.setState({
          csvData: this.props.docsData,
          pdfBody: pdfData,
          reportDocs: this.props.reportsData
        })
      }
    }
    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      this.setState({ masking: true })
    } else {
      this.setState({ masking: false })
    }
    this.stateSetter()
  }
  onViewBtn = (txnId) => {
    this.setState({
      openDetails: true,
      dialogData: [],
    });
    this.props.dispatch(getPaymentLogs(txnId));
  }

  onDialogClose() {
    this.setState({ openDetails: false });
  }

  updatePdfCsv(allRecords, key = "", sortType = "", keyType = "") {
    setTimeout(() => {
      let props = this.props;
      let reportsData = props.reportsData;
      let userData = props.userData;
      let inputData = [];
      if (allRecords) {
        inputData = reportsData && Array.isArray(reportsData) && reportsData.length > 0 ? reportsData : [];
      } else {
        inputData = userData && Object.keys(userData).length > 0 && userData?.data && Array.isArray(userData?.data) ? userData.data : [];
      }
      const data = sortingFilter(inputData, { key, sortType, keyType }).map((elt) => [
        elt.id,
        elt.userId,
        this.state.masking && Number(elt.userName) ? hideMobile(elt.userName) : elt.userName,
        elt.role,
        divideAmountBy100(elt.paymentDesc),
        elt.modePaymentClient,
        elt.paymentMethodType,
        replaceWithSpace(elt.manualAdjustment),
        elt.transactionRef,
        elt.addedBy,
        elt.paymentType,
        elt.createdDate ? getDateTimeFormate(elt.createdDate) : "-",
        elt.updatedDate ? getDateTimeFormate(elt.updatedDate) : "-",
        elt.status,
        formatMoney(elt.amount),
        this.state.masking ? hideData(elt.ipAddress) : elt.ipAddress,
        elt.updatedByUserName || '-',
        this.setState({ txnId: elt.id }),
        this.state.openDetails ? this.onViewBtn(elt.id) : ""
      ]);

      const csvData = data && Array.isArray(data) && data.length > 0 ? [paymentSearchHeaders, paymentsrchInfo, ...data] : [paymentSearchHeaders, paymentsrchInfo, []]
      if (allRecords) {
        let packet = {
          pdf: data,
          csv: csvData
        }
        packet = JSON.parse(JSON.stringify(packet));
        this.setState({
          reportDocs: packet
        })
        this.props.dispatch(setReportDocs(packet));
      } else {
        this.setState({ pdfBody: JSON.parse(JSON.stringify(data)) });
        this.setState({ csvData: JSON.parse(JSON.stringify(csvData)) });
        this.props.dispatch(setDocsData(JSON.parse(JSON.stringify(csvData))));
      }
    }, 300);
  }

  downloadAllRecords() {
    this.updatePdfCsv(true);
  }
  reportsCaller(pages) {
    this.callApi(1, pages, this.state.payloadData, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData !== this.props.userData) {
      this.updatePdfCsv();
      // this.reportsCaller(this.props.userData.totalRecords);
    }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
    if (prevProps.reportsData !== this.props.reportsData) {
      this.downloadAllRecords();
    }
    if (prevProps.paymentLogs !== this.props.paymentLogs) {
      this.updateDialogData()
    }
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }
  formDataToSliceSetter(key, val, multiEntries) {
    const newEntry = { ...this.props.formData, [key]: val }
    const newPayload = multiEntries ? { ...this.props.formData, ...multiEntries } : newEntry
    this.props.dispatch(setFormData(newPayload));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.searchResults, false);
  }


  handleSortFields(item, key) {
    const intializing = {
      "Customer ID": false,
      "Customer Name": false,
      "Action by": false,
      "Transaction Type": false,
      "Transaction Date": false,
      "Updated Date": false,
      "Status": false,
      [`Amount ${Currency()}`]: false,
      key: "",
      direction: "",
      keyType: ""
    }
    const direction = !this.state.sortFields[key] ? "ASCENDING" : "DESCENDING";

    this.setState({
      sortFields: {
        ...intializing,
        [key]: !this.state.sortFields[key],
        key: item.key,
        direction: direction,
        keyType: item.type,
      }
    });
    this.updatePdfCsv(false, item.key, direction, item.type);
  }

  updateDialogData() {
    const headers = this.state.headers
    const data =
      this.props.paymentLogs !== null &&
      this.props.paymentLogs !== undefined &&
      this.props.paymentLogs &&
      Array.isArray(this.props.paymentLogs) &&
      this.props.paymentLogs.map((item, ind) => [
        item.transaction_id,
        item.description,
        item.eventType,
        item.reference,
        getBrowserDate(item.createdAt),
        item.paymentMethod,
        item.paymentMethodType,
        item.data
      ])

    const tableData = data.length > 0 ? [headers, viewLogInfo, ...data] : [headers, viewLogInfo, []];
    this.setState({ dialogData: tableData });
  }

  onCancel(id) {
    this.setState({ declineBox: true })
    this.setState({ txnId: id })
  }

  onCloseCancelPopup() {
    this.setState({ declineBox: false })
  }

  handleReject(msg) {
    const data = {
      comments: msg
    }
    this.props.dispatch(cancelWithdrawal(this.state.txnId, data))
    this.props.dispatch(getPaymentSearchtableList(0, 25))
  }
  setClearPayment() {
    this.setState({ clearAllPayment: false })
  }
  setPaymentSelected(payment) {
    this.setState({ paymentSelected: payment })
    this.formDataToSliceSetter("paymentSelected", payment)
  }

  // setPaymentIds(value) {
  //   this.setState({ paymentId: value })
  //   this.formDataToSliceSetter("paymentSelected", value)
  // }

  render() {

    return (
      <React.Fragment>
        {this.state.openDetails && (
          <Dialog__Main
            title={"Transaction Details"}
            dOpen={this.state.openDetails}
            dClose={() => this.onDialogClose()}
            width={"xl"}
          >
            <Table tableData={this.state.dialogData} hLen={4} vLen={2} />
          </Dialog__Main>
        )}
        <Dialog__Main title={"Action Needed"} dOpen={this.state.declineBox} dClose={() => this.onCloseCancelPopup()}>
          <KycCommentBox
            transactionId={this.state.txnId}
            finish={(msg) => this.handleReject(msg)}
            close={() => this.onCloseCancelPopup()}
            cancelBtn
          />
        </Dialog__Main>
        <div className='CMS-tab-panel active' id='CMS-betting'>
          <div className='CMS-tabContent'>
            <div className='CMS-box CMS-box-content'>
              <div className='row'>

                <div className='col-md-4 col-xl'>
                  <Select
                    title={"Opt-In"}
                    name={"option"}
                    value={this.state.option}
                    setValue={(val) => this.onChangeOption(val)}
                    options={option}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"option"}
                    info={Payments.Opt_In}
                  />
                </div>
                <div className='col-md-4 col-xl'>
                  <Select
                    title={"Transaction Type"}
                    name={"transactionType"}
                    value={this.state.transactionType}
                    setValue={(val) => this.onChangetransactionType(val)}
                    options={this.state.option === "MANUAL" ? transactionType.slice(0, 4) : transactionType.slice(0, 3)}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"transactionType"}
                    info={Payments.Transaction_Type}
                  />
                </div>
                {this.state.option != "ONLINE" && this.state.transactionType != "ALL" &&
                  <div className='col-md-4 col-xl'>
                    <Select
                      title={"Adjustment Type"}
                      name={"adjustmentType"}
                      value={this.state.adjustmentType}
                      setValue={(val) => this.onChangeAdjustmentType(val)}
                      options={this.state.transactionType === "DEPOSIT" ? adjustmentOption.slice(0, 4) : adjustmentOption.slice(4, 8)}
                      onSubmit={(e) => this.onSubmit(e)}
                      ids={"adjustmentType"}
                      info={Payments.Adjustment_Type}
                    />
                  </div>}
                <div className='col-md-4 col-xl'>
                  <Select
                    title={"Status"}
                    name={"status"}
                    value={this.state.status}
                    setValue={(val) => this.onChangeStatus(val)}
                    options={transactionStatus}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"PaymentStatus"}
                    info={Payments.Status}
                  />
                </div>
                <div className='col-md-4 col-xl'>
                  <AmountRangeIp
                    title={"Amount Range"}
                    amountMin={this.state.amountMin}
                    amountMax={this.state.amountMax}
                    rangeHandle={(e) => this.rangeHandle(e)}
                    rangeErr={this.state.rangeErr}
                    minName={"amountMin"}
                    maxName={"amountMax"}
                    info={Payments.amountRange}
                  />
                </div>

              </div>
              <div className='row'>
                {PaymentsearchData1.map((item, ind) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className='col-md-4 col-xl'>
                        <Input
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.handleChange(e)}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}

                <div className='col-md-4 col-xl'>
                  <Datepicker
                    title={"Start Date of Transaction"}
                    name={"startDate"}
                    value={this.state.startDate}
                    setValue={(d, n) => this.setDateHandler(d, n)}
                    maxDate={
                      this.state.endDate ? this.state.endDate : new Date()
                    }
                    errorMsg={this.state.dateError}
                    ids={"paymentsearchStartDate"}
                    info={Payments.start_date}
                  />
                </div>
                <div className='col-md-4 col-xl'>
                  <Datepicker
                    title={"End Date of Transaction"}
                    name={"endDate"}
                    value={this.state.endDate}
                    setValue={(d, n) => this.setDateHandler(d, n)}
                    minDate={this.state.startDate}
                    maxDate={new Date()}
                    ids={"paymentsearchEndDate"}
                    info={Payments.end_date}
                  />
                </div>
                {PaymentsearchData2.map((item, ind) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className='col-md-4 col-xl'>
                        <Input
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.handleChange(e)}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
                {PaymentsearchUser.map((item, ind) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className='col-md-4 col-xl'>
                        <Input
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.handleChange(e)}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

              <div className='row'>
                {PaymentsearchData4.map((item, ind) => {
                  return (
                    <>
                      <div className='col-md-3' style={{ maxWidth: "20%" }} key={item.id}>
                        <Input
                          title={item.title}
                          name={item.name}
                          value={this.state[item.name]}
                          placeholder={item.placeholder}
                          setValue={(e) => this.handleChange(e)}
                          regex={item.regex}
                          ids={item.ids}
                          info={item.info}
                          show={true}
                        />
                      </div>
                    </>
                  );
                })}

                <div className='col-md-3' style={{ maxWidth: "20%" }} >
                  <div className='CMS-formGroup'>
                    <div className='CMS-formLabel'>
                      Payment Methods
                      &nbsp;&nbsp;
                      <Tooltips info={Payments.payment_method} />
                    </div>
                    <div className='CMS-dropdown CMS-formControl'>
                      <div className='CMS-select'>
                        <select
                          name='paymentMethods'
                          value={this.state.paymentMethods}
                          onChange={(e) => this.onPaymentFilter(e)}
                          className="pointer"
                        >
                          <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                          {Object.keys(this.props.paymentProvider).length >
                            0 &&
                            this.props.paymentProvider
                              .map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.payment_provider_id}
                                  >
                                    {item.payment_provider_id}
                                  </option>
                                );
                              })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3' style={{ maxWidth: "20%" }}>
                  <div className="CMS-formLabel">
                    {Payments.paymentTypes}
                    &nbsp;&nbsp;
                    <Tooltips info={Payments.paymentType} />
                  </div>
                  <MultipleSelectDDL
                    multiName={["payment_type"]}
                    options={this.props.paymentsOption}
                    onSelect={(payment) => this.setPaymentSelected(payment)}
                    shouldReset={this.state.clearAllPayment}
                    initialSelected={this?.props?.formData?.paymentSelected || []}
                  />
                  {/* <MultiSelectDDL
                    options={this.props.paymentsOption}
                    setSelected={(payment) =>
                      this.setPaymentSelected(payment)
                    }
                    ids={this.state.paymentSelected}
                    setIds={(payment) =>
                      this.setPaymentSelected(payment)}
                    selectedOnce={this.state.paymentSelected}
                    clearAll={this.state.clearAllPayment}
                    setClear={() => this.setClearPayment()}
                    betDDL
                  /> */}
                  {/* <Select
                    title={"Payment Type"}
                    name={"paymentType"}
                    value={this.state.paymentType}
                    setValue={(val) => this.onchangePaymentType(val)}
                    options={paymentTypes}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"PaymentType"}
                    info={Payments.paymentType}
                  /> */}
                </div>
                <div className='col-md-3' style={{ maxWidth: "20%" }}>
                  <Input
                    title={"Affiliate BTag"}
                    name={"affliateBtag"}
                    value={this.state.affliateBtag}
                    placeholder={"BTag"}
                    setValue={(e) => this.handleChange(e)}
                    ids={"psearchBtag"}
                    info={Payments.psearchBtag}
                    show={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-3' style={{ maxWidth: "20%" }}>
                  <Select
                    title={"Role"}
                    name={"role"}
                    value={this.state.role}
                    setValue={(val) => this.handleChange(val)}
                    options={roleData}
                    info={PLAYER_MODULE.roles}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"paymentSearchRole"}
                  />
                </div>
              </div>
              <div className='mt-20'></div>
              <div className='row'>
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"Paymentsearch"}
                  idreset={"Paymentreset"}
                  submit={(e) => this.onSubmit(e)}
                  reset={(e) => this.handleReset(e)}
                />
              </div>
            </div>
            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.userData.data}
                  totalRecords={this.props.userData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.isVisible}
                  setIsVisible={setIsVisible}
                  csvData={this.state.csvData}
                  exportPDF={(val) => this.exportPDF(val)}
                  callApi={this.callApi}
                  csvFileName={"Payment Search Report"}
                  payloadData={this.state.payloadData}
                  resetCsvData={this.resetCsvData}
                  enableAll={false}
                  allRecordsData={this.props.reportDocs}
                  reportsLoader={{ loader: this.props.reportsLoader, setLoader: setReportsLoader }}
                  totalAmount={true}
                  sumAmount={formatMoney(this.props.userData.sumAmount)}
                  info={this.state.transactionType === "WITHDRAW" ? Payments.totalWithdraw : Payments.totalDepo}
                  showAmount={(this.state.transactionType === "DEPOSIT" || this.state.transactionType === "WITHDRAW") && this.state.status === "CONFIRMED"}
                />
              </div>
              {!this.props.isVisible ? (
                <Skelton />
              ) : (
                <PaymentSearchTable
                  response={this.props?.userData?.data}
                  onSubmit={this.onSubmit}
                  masking={this.state.masking}
                  tableData={this.state.csvData}
                  linkIndex={1}
                  styles={this.tableStyles}
                  sortables={paymentSortables}
                  handleSortFields={(item, key) => this.handleSortFields(item, key)}
                  sortingOrder={this.state.sortFields}
                  onViewBtn={(txId) => this.onViewBtn(txId)}
                  onCancel={(id) => this.onCancel(id)}
                  btnStatus={this.state.btnStatus}
                />
              )}

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.paymentSearchSlice.userData,
    viewTable: state.paymentSearchSlice.viewTable,
    paginationFirstValue: state.paymentSearchSlice.paginationFirstValue,
    paginationSecondValue: state.paymentSearchSlice.paginationSecondValue,
    errorMessage: state.paymentSearchSlice.errorMessage,
    recordsShow: state.paymentSearchSlice.recordsShow,
    userId: state.playersearch.userId,
    isVisible: state.paymentSearchSlice.isVisible,
    formData: state.paymentSearchSlice.formData,
    docsData: state.paymentSearchSlice.docsData,
    reportsData: state.paymentSearchSlice.reportsData,
    reportDocs: state.paymentSearchSlice.reportDocs,
    reportsLoader: state.paymentSearchSlice.reportsLoader,
    paymentProvider: state.paymentSearchSlice.paymentProvider,
    paymentLogs: state.paymentSearchSlice.paymentLogs,
    paymentLogs: state.paymentSearchSlice.paymentLogs,
    paymentsOption: state.paymentSearchSlice.paymentsOption,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTicker);
