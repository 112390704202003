import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getMultibetAccount } from "./MultibetSlice";
import Vpagination from "../../sharedfiles/Vpagination";
import {
  setPage,
  setItemsPerPage,
  setRecordsShow,
  setVisible,
} from "../AppSlice";
import { getDecimalNumber } from "../../sharedfiles/helper";
import { COMMON_CONSTANTS, MULTIBETACC_CONSTANTS, PRIMARYPLAYER_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { Betting } from "../../sharedfiles/tooltipMsgs";
function MultibetAccount(props) {
  const dispatch = useDispatch();
  const [bonusType, setbonusType] = useState("MULTIBET_BONUS");
  function callApi(page, items, data) {
    dispatch(getMultibetAccount(page - 1, items, bonusType));
  }
  useEffect(() => {
    dispatch(setPage(1));
    dispatch(setItemsPerPage(25));
    dispatch(setRecordsShow([1, 25]));
    dispatch(getMultibetAccount(0, 25, bonusType));
  }, []);

  const handleTypeChange = (e) => {
    setbonusType(e.target.value);
    dispatch(getMultibetAccount(0, 25, e.target.value));
    dispatch(setPage(1));
    dispatch(setItemsPerPage(25));
    dispatch(setRecordsShow([1, 25]));
  };
  const tbHeaders = [
    "Customer Id",
    "Bet ID",
    "Amount Awarded",
    "Bet Status (Won/Rollback)"
  ]
  const headerInfo = [
    "Showing Customer Id",
    "Showing Bet ID",
    "Showing Amount Awarded",
    "Showing the Bet Status (Won/Rollback)"
  ]
  return (
    <div className="CMS-tab-panel active" id="CMS-betting">
      <div className="CMS-tabContent">
        <div className="CMS-box CMS-box-content">
          <div className="row">
            <div className="col">
              <div className="CMS-box__header">
                <div className="CMS-box__title">
                  {MULTIBETACC_CONSTANTS.header_MBBbonusAmount}
                </div>
              </div>
            </div>
          </div>
          <div className="row  text-center">
            <div className="col-md-6 col-lg-4 col-xl-5 mb-20">
              <div className="CMS-formGroup formGroup-inline">
                <div>
                  <div style={{ padding: "12px" }}>
                    {PRIMARYPLAYER_CONSTANTS.th_bonusType}
                    &nbsp;&nbsp;
                    <Tooltips info={Betting.multi_Bonus_type} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="CMS-formControl"></div>
                  <div className="CMS-number-of-files CMS-select">
                    <select
                      id="bonusType"
                      name="bonusType"
                      value={bonusType}
                      onChange={(e) => handleTypeChange(e)}
                      className="pointer"
                    >
                      <option value="MULTIBET_BONUS">{MULTIBETACC_CONSTANTS.ddlOpt_multiBet}</option>
                      <option value="ONECUT_BONUS">{MULTIBETACC_CONSTANTS.ddlOpt_oneCut}</option>
                      <option value="TWOCUT_BONUS">{MULTIBETACC_CONSTANTS.ddlOpt_twoCut}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="CMS-alert alert-primary">
                <div className="row">
                  <div className="col-6 vh_laign_center alert_conetnet">
                    {bonusType === "MULTIBET_BONUS" ? (
                      <span>{MULTIBETACC_CONSTANTS.totalMBB_Disbursed}</span>
                    ) : bonusType === "ONECUT_BONUS" ? (
                      <span>{MULTIBETACC_CONSTANTS.totalOneCut_Disbursed}</span>
                    ) : (
                      <span>{MULTIBETACC_CONSTANTS.totalTwoCut_Disbursed}</span>
                    )}
                  </div>
                  <div className="col-6 vh_laign_center">
                    <b>{getDecimalNumber(props.multibetAccount.totalAmount)}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CMS-filter-result" id="result">
            <div className="CMS-pagination">
              <Vpagination
                data={props.multibetAccount?.data}
                totalRecords={props.multibetAccount?.totalRecords}
                paginationFirstValue={props.page}
                paginationSecondValue={props.items}
                setPaginationFirstValue={setPage}
                setPaginationSecondValue={setItemsPerPage}
                recordsShow={props.records}
                setRecordsShow={setRecordsShow}
                isVisible={props.visible}
                setIsVisible={setVisible}
                hideDocs={true}
                callApi={callApi}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="CMS-table CMS-table-triped">
                <table>
                  <thead>
                    <tr>
                      {
                        tbHeaders.map((item, ind) => (
                          <th>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{item}</p>
                              <Tooltips info={headerInfo[ind]} />
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  </thead>
                  {Object.keys(props.multibetAccount) &&
                    props.multibetAccount?.data &&
                    Array.isArray(props.multibetAccount.data) &&
                    props.multibetAccount?.data?.length > 0 ? (
                    props.multibetAccount.data.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td className="text-center">{item.userId}</td>
                            <td>{item.transactionReference}</td>
                            <td>{getDecimalNumber(item.transactionAmount)}</td>
                            <td>{item.status}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <No__Data__Found colspan={22} />
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    multibetAccount: state.multibetconfig.multibetAccount,
    page: state.appslice.page,
    items: state.appslice.itemsPerPage,
    records: state.appslice.recordsShow,
    visible: state.appslice.visible,
  };
};
export default connect(mapStateToProps)(MultibetAccount);
