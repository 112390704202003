import React, { useEffect, useState } from 'react'
import { COMMON_MSGS, PLAYER_MODULE, communication_module } from '../../sharedfiles/tooltipMsgs'
import Input from '../Common/common_inputs/Input'
import Select from '../Common/common_inputs/Select_DDL'
import { messageFormatType } from '../Common/data/mapData'
import Tooltips from '../Common/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { getNotyTemplateByType, updateWelcomeNotyTemplate } from '../CreateAccountMsg/TemplateSlice'
import MultipleSelectDDL from '../Common/common_inputs/MultipleSelectDDL'

const WelcomeMsg = (props) => {
    const dispatch = useDispatch()
    const [title, settitle] = useState('')
    const [titleErr, setTitleErr] = useState(false)
    const [msgFormatType, setMsgFormatType] = useState('')
    const [typeErr, setTypeErr] = useState(false)
    const [banner, setbanner] = useState('')
    const [fileErr, setFileErr] = useState('')
    const [shortMsg, setShortMsg] = useState('')
    const [shortMsgErr, setShortMsgErr] = useState(false)
    const [longMsg, setLongMsg] = useState('')
    const [longMsgErr, setLongMsgErr] = useState(false)
    const [showBanner, setShowbanner] = useState('')
    const [actionBtnName, setActionBtnName] = useState('')
    const [actionLink, setActionBtnLink] = useState('')
    const [shouldReset, setShouldReset] = useState(false)

    const templateData = useSelector((p) => p.TemplateSlice.templateData)


    const inputHandle = (e) => {
        const { name, value } = e.target
        if (name === "title") {
            settitle(value)
            setTitleErr(false)
        }
        if (name === "shortMsg") {
            setShortMsg(value)
            setShortMsgErr(false)
        }
        if (name === "longMsg") {
            setLongMsg(value)
            setLongMsgErr(false)
        }
        if (name === "actionBtnName") {
            setActionBtnName(value)
        }
        if (name === "actionLink") {
            setActionBtnLink(value)
        }
    }


    const uploadIp = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setbanner(file)
        if (name === "banner" && !file) {
            setFileErr('Please choose proper file');
        } else if (file && file.size > 100 * 1024) {
            setFileErr('File size exceeds 100KB limit')
        } else {
            setFileErr('');

            try {
                const dataURL = await fileToDataURL(file);
                setShowbanner(dataURL);
            } catch (error) {
                console.error('Error converting file to data URL:', error);
            }
        }
    };


    const fileToDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    };



    const onClear = () => {
        settitle('')
        setTitleErr('')
        setMsgFormatType('')
        setTypeErr('')
        setFileErr('')
        setShortMsg('')
        setShortMsgErr('')
        setLongMsg('')
        setLongMsgErr('')
        setActionBtnName('')
        setActionBtnLink('')
        setShouldReset(true)
        setbanner('')
        const fileField = document.getElementById("banner")
        if (fileField) {
            fileField.value = ""
            setbanner("")
        }
    }

    const validation = () => {
        let isValid = true;

        if (title === "") {
            setTitleErr(true);
            isValid = false;
        }
        if (msgFormatType === "") {
            setTypeErr(true);
            isValid = false;
        }
        if (banner === "") {
            setFileErr('Please choose proper file');
            isValid = false;
        }
        if (shortMsg === "") {
            setShortMsgErr(true);
            isValid = false;
        }
        if (longMsg === "") {
            setLongMsgErr(true);
            isValid = false;
        }
        return isValid;
    }

    const onSubmitData = () => {
        if (validation() && !fileErr) {
            const data = {
                "actionButtonLabels": actionBtnName ? actionBtnName : [],
                "actionButtonUrls": actionLink ? actionLink : [],
                "bannerName": "",
                "bannerPath": "",
                "description": shortMsg,
                "htmlTemplate": longMsg,
                "id": templateData?.id,
                "message": longMsg,
                "messageType": msgFormatType,
                "notificationPriority": "HIGH",
                "notificationType": "REGISTRATION_SUCCESS",
                "title": title
            }

            const fetchFormData = new FormData();
            fetchFormData.append("file", banner);
            fetchFormData.append("templateDetails", JSON.stringify(data));
            dispatch(updateWelcomeNotyTemplate(fetchFormData));
            setTimeout(() => {
                const payload = {
                    notificationType: "REGISTRATION_SUCCESS"
                }
                dispatch(getNotyTemplateByType(payload))
            }, 500)
        }
    }

    useEffect(() => {
        // if (props.selected === "welcome") {
        settitle(templateData.title);
        setShortMsg(templateData.description);
        setLongMsg(templateData.message);
        setActionBtnName(templateData.actionButtonLabels);
        setActionBtnLink(templateData.actionButtonUrls);
        setMsgFormatType(templateData?.messageType)
        setbanner(templateData.bannerPath)
        // }

    }, [templateData])

    const handleSelectedValues = (selected) => {
        setMsgFormatType(selected)
        setTypeErr(false)
        setShouldReset(false)
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className='col-3 ml-15'>
                    <Input
                        title={"Title (50 Characters)"}
                        name={"title"}
                        value={title}
                        setValue={(e) => inputHandle(e)}
                        placeholder={"Enter..."}
                        ids={"systemMsgtitle"}
                        info={communication_module.promo_title}
                        show={true}
                        maxLen={50}
                        errorMsg={titleErr && "Promo Title Required"}
                    />
                </div>
            </div>
            <div className="row">
                <div className='col-3 ml-15'>
                    <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                            {"Message Format Type"}
                            &nbsp;&nbsp;
                            <Tooltips info={communication_module.msgformat_type} />
                        </div>
                        <MultipleSelectDDL
                            options={messageFormatType}
                            onSelect={(value) => handleSelectedValues(value)}
                            shouldReset={shouldReset}
                            initialSelected={templateData?.messageType}
                        />
                        {typeErr && (
                            <div className='CMS-form-errorMsg'>
                                {"Please select your Message format Type"}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-3 ml-15'>
                    <Input
                        title={"Banner"}
                        placeholder={"upload file"}
                        name={"banner"}
                        type={"file"}
                        ids={"banner"}
                        show={true}
                        info={communication_module.promoBanner}
                        accept={"image/jpeg,image/png"}
                        setValue={(e) => uploadIp(e)}
                        errorMsg={fileErr}
                    />
                </div>
            </div>
            <div className="row">
                <div className='col-3 ml-10'>
                    {props.selected === 'welcome' && banner ? (
                        <img src={typeof banner === 'string' ? banner : URL.createObjectURL(banner)} alt="Uploaded promo banner" />
                    ) : (
                        <img src={showBanner} alt="Uploaded promo banner" />
                    )}
                </div>
            </div>

            <div className="row">
                <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                    <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                            {communication_module.shortMsg}
                            &nbsp;&nbsp;
                            <Tooltips info={communication_module.shortMsg} />
                        </div>
                        <div className='CMS-formControl-group'>
                            <div className='CMS-formControl'>
                                <textarea
                                    rows='2'
                                    cols='50'
                                    name='shortMsg'
                                    id='shortMsg'
                                    value={shortMsg}
                                    onChange={(e) => inputHandle(e)}
                                    maxlength='144'
                                ></textarea>
                            </div>
                            {shortMsgErr && (
                                <div className='CMS-form-errorMsg'>
                                    {"Short Message required"}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                    <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                            {communication_module.longMsgIp}
                            &nbsp;&nbsp;
                            <Tooltips info={communication_module.longMsg} />
                        </div>
                        <div className='CMS-formControl-group'>
                            <div className='CMS-formControl'>
                                <textarea
                                    rows='2'
                                    cols='50'
                                    name='longMsg'
                                    id='longMsg'
                                    value={longMsg}
                                    maxlength='320'
                                    onChange={(e) => inputHandle(e)}
                                ></textarea>
                            </div>
                            {longMsgErr && (
                                <div className='CMS-form-errorMsg'>
                                    {"Long Message Required"}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-3 ml-15'>
                    <Input
                        title={"Action Button Name"}
                        name={"actionBtnName"}
                        value={actionBtnName}
                        setValue={(e) => inputHandle(e)}
                        placeholder={"Enter..."}
                        ids={"actionBtnName"}
                        info={communication_module.actionbtn_Name}
                        show={true}
                    />
                </div>
                <div className='col-3'>
                    <Input
                        title={"Action Link"}
                        name={"actionLink"}
                        value={actionLink}
                        setValue={(e) => inputHandle(e)}
                        placeholder={"Enter..."}
                        ids={"actionLink"}
                        info={communication_module.action_Link}
                        show={true}
                    />
                </div>
            </div>
            <div className='mt-20'></div>
            <div className='col-12'>
                <div className="CMS-btnContainer">
                    <button
                        className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                        onClick={() => onClear()}
                    >
                        Clear All
                    </button>
                    <button
                        className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
                        onClick={() => onSubmitData()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WelcomeMsg