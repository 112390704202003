import React from 'react'
import { getDateTimeFormate } from '../../sharedfiles/helper';

const PromoPreview = (props) => {
    const extractedDate = (date) => {
        const propDate = new Date(date);
        const day = propDate.getDate()
        const month = propDate.getMonth()
        const year = propDate.getFullYear()

        const finalDate = `${day}/${month + 1}/${year}`
        return finalDate
    }
    function formatDateTime(date) {
        if (!(date instanceof Date && !isNaN(date))) {
            return ''; // Return an empty string for invalid or undefined dates
        }
        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        return `${formattedDate}, ${formattedTime}`;
    }
    return (
        <>
            <div className='CMS-box CMS-box-content'>
                <div className='d-flex'>
                    <b>{props.systemMsg ? "Title" : "Promo Title"}</b>
                    <div className='ml-50'></div>
                    <b>Message Format Type</b>
                    <div className='ml-50'></div>
                    <b>Short Message</b>
                </div>
                <div className="d-flex">
                    <p className='CMS-formLabel'> {props.promoTitle}</p>
                    <p className='CMS-formLabel'> {props.msgFormatType}</p>
                    <div className='pl-61'></div>
                    <p className='CMS-formLabel'> {props.shortMsg}</p>
                </div>

                <div className='mt-20'></div>
                <div>
                    <b>{props.systemMsg ? "Banner" : "Promo Banner"}</b>
                </div>
                <div>
                    <p>{props.promoBanner && (
                        <img src={props.promoBanner} />
                    )}</p>
                    <p>{props.apiPromoBanner && (
                        <img src={props.apiPromoBanner} />
                    )}</p>
                </div>

                <div className='mt-20'></div>
                <div>
                    <b>Long Message</b>
                </div>
                <div>
                    <p className='CMS-formLabel'>{props.longMsg}</p>
                </div>

                <div className='mt-20'></div>
                <div className='d-flex'>
                    {props.userTag ? <b>User Tag</b> : ''}
                    {props.userTag ? <div className='pl-66'></div> : ''}
                    <b>Start Date/Time</b>
                    <div className='pl-80'></div>
                    <b>End Date/Time</b>
                </div>
                <div className="d-flex">
                    {props.userTag ? <p className='CMS-formLabel'>{props.userTag}</p> : ''}
                    <p className='CMS-formLabel'> {formatDateTime(props.startDate)}</p>
                    <div className='pl-49'></div>
                    <p className='CMS-formLabel ml-20'> {formatDateTime(props.endDate)}</p>
                </div>

                <div className='mt-20'></div>
                <div className='col-12'>
                    <div className="CMS-btnContainer">
                        <button
                            className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                            onClick={() => props.setOpenPromo()}
                        >
                            Cancel
                        </button>
                        <button
                            className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
                            onClick={() => props.onSubmitData()}
                        >
                            Send Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromoPreview