import React from "react";
import { connect } from "react-redux";
import PaymentMainModal from "./paymentMainModal";
import { tabManager } from "../sidebar/sidebarSlice";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import Table from "../Common/table/Table";

class betHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      popUp: false,
      userNote: "",
      data: [],
    };
    this.handleFeeback = this.handleFeeback.bind(this);
    this.handlePopUpClose = this.handlePopUpClose.bind(this);
    this.handlePopUpOpen = this.handlePopUpOpen.bind(this);
    this.tabsChange = this.tabsChange.bind(this);
  }

  handleUserNote(e) {
    this.setState({
      userNote: e.target.value,
    });
  }
  tabsChange(item) {
    this.props.dispatch(setUserId(item));
    this.props.dispatch(tabManager(+item, this.props.tabStack));
    localStorage.setItem("userId", item);
  }

  handlePopUpOpen(item) {
    this.setState({
      popUp: !this.state.popUp,
      data: item,
    });
  }

  handlePopUpClose() {
    this.setState({
      popUp: false,
    });
  }

  handleFeeback(e) {
    this.setState({
      userComment: e.target.value,
    });
    //  this.state.data.modalFeedback = e.target.value
  }

  render() {
    return (
      <>
        {this.state.popUp ? (
          <PaymentMainModal
            popUp={this.state.popUp}
            handlePopUpClose={this.handlePopUpClose}
            modalTitle='Action needed'
            price={this.state.data.amount}
            transactionId={this.state.data.id}
            modalFeedback={this.state.data.modalFeedback}
            handleFeeback={this.handleFeeback}
          />
        ) : null}
        <Table
          tableData={this.props.tableData}
          linkIndex={this.props.linkIndex}
          styles={this.props.styles}
          sortables={this.props.sortables}
          handleSortFields={this.props.handleSortFields}
          sortingOrder={this.props.sortingOrder}
          viewBtn={18}
          onViewBtn={(id) => this.props.onViewBtn(id)}
          BtnName={"View Logs"}
          CancelbtnName={"Cancel"}
          onCancel={(sts) => this.props.onCancel(sts)}
          cancelBtn={17}
          btnStatus={this.props.btnStatus}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.paymentSearchSlice.userData,
    paginationFirstValue: state.paymentSearchSlice.paginationFirstValue,
    paginationSecondValue: state.paymentSearchSlice.paginationSecondValue,
    errorMessage: state.paymentSearchSlice.errorMessage,
    errorMessage: state.paymentSearchSlice.errorMessage,
    tabStack: state.sidebar.tabStack
  };
}
export default connect(mapStateToProps)(betHistoryTable);
