import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AFFILIATE_CONSTANTS } from "../../sharedfiles/Constants";
import {
  getDateTimeFormate,
  getFromLocalStorage,
  formatMoney,
  cleanObject,
  MomentUTCDateTimeEnd,
  MomentUTCDateTimeToday,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import {
  AddChannel,
  listChannels,
  setChanelVisible,
  setChannelItems,
  setChannelRecords,
  setChannel_page,
  setDocsData_channel,
  setChannelField,
  setChannelPayload,
  setDateValue,
} from "../Affiliate/AffiliateSlice";
import Input from "../Common/common_inputs/Input";
import Table from "../Common/table/Table";
import { channelInfo } from "../../sharedfiles/tooltipMsgs";
import { affiliateDates, chanelDates } from "../Common/data/mapData";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Datepicker from "../Common/common_inputs/DatePicker";
function Channel(props) {
  const dispatch = useDispatch();
  const [channel, setChannel] = useState("");
  const [channelErr, setChannelErr] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    startDate: "",
    endDate: ""
  });
  const [dateErr, setDateErr] = useState("")
  const channelList = useSelector((st) => st.affiliateSlice.channelList?.data);
  const platformId = useSelector(st => st.affiliateSlice.selected_AffiliateId);
  const { channelPayload, dateValue, isAffChannel } = useSelector(st => st.affiliateSlice);
  // const channelId = localStorage.getItem("subAffUserId")
  // const userId = channelId || platformId
  // const userId = platformId || getFromLocalStorage("AffliateUserId");
  // const userId = getFromLocalStorage("AffliateUserId") || localStorage.getItem("subAffUserId")
  const userId = isAffChannel ? localStorage.getItem("subAffUserId") : getFromLocalStorage("AffliateUserId")
  const headers = [
    "Id",
    "Channel Name",
    "AffiliateId",
    "Created Date",
    "Updated Date",
    "Total Registrations",
    "Deposited Customers",
    "Total Deposits",
    "New Customers",
    "Total Stakes",
    "Total Payout",
    "GGR",
    "Total Bonus Amount",
    "Total Number of Bets",
    "Total Withdrawals",
    "URL Hits Count",
  ];
  const filedsHandler = (e) => {
    const { name, value } = e.target;
    const channelErr = value === ""
    setChannel(value);
    setChannelErr(channelErr);
    dispatch(setChannelField({ value: value, error: channelErr }))
  };
  const submit = (e) => {
    e.preventDefault();
    const payload = {
      userId: userId,
      body: { channelName: channel },
    };
    const isValid = channel === "" ? true : false;
    setChannelErr(isValid);
    dispatch(setChannelField({ value: channel, error: isValid }))
    if (!isValid && userId) {
      dispatch(AddChannel(payload));

      setTimeout(() => {
        callApi(1, 25, userId);
        setChannel("");
      }, 500);
    }
  };
  const exportPDF = () => {
    const title = "Channel Report";
    exportPDFC(title, [headers], pdfBody);
  };

  const callApi = (page, items, userId) => {
    let payload = {
      userId: +userId,
      startDate: dateValue.startDate !== "" ? MomentUTCDateTimeToday(dateValue.startDate) : "",
      endDate: dateValue.endDate !== "" ? MomentUTCDateTimeEnd(dateValue.endDate) : ""
    }
    const filterdData = cleanObject(payload)
    dispatch(setChannelPayload(payload))
    dispatch(listChannels(page - 1, items, filterdData));
  };
  useEffect(() => {
    const dataExist = Object.keys(props?.channelList).length > 0 && props.channelList?.data?.length > 0
    if (!dataExist) {
      callApi(1, 25, userId);
      dispatch(setChannelField(channel));
      setDateFilters(dateValue)
    }
    else {
      const csvDataExist = props?.docsData_channel && Array.isArray(props.docsData_channel) && props.docsData_channel.length > 0
      let pdfData = [];
      if (csvDataExist) {
        pdfData = props?.docsData_channel.slice(1, props?.docsData_channel?.length);
      }
      setTableData(props.docsData_channel);
      setPdfBody(pdfData);
      setChannel(props.channelField.value);
      setChannelErr(props.channelField.error);
      setDateFilters({
        startDate: dateValue?.startDate,
        endDate: dateValue?.endDate
      })
    }
  }, []);

  const updateTableData = () => {
    let tbData = [];
    if (Array.isArray(channelList) && channelList.length > 0) {
      const data = channelList.map((item) => [
        item.id,
        item.name,
        item.affiliateId,
        item.createdDate ? getDateTimeFormate(item.createdDate) : "-",
        item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-",
        item.totalRegistration,
        item.depositedCustomers,
        item.totalDeposit ? formatMoney(item.totalDeposit) : "0",
        item.newCustomers,
        item.totalStake ? formatMoney(item.totalStake) : "0",
        item.totalPayout ? formatMoney(item.totalPayout) : "0",
        item.totalGgr ? formatMoney(item.totalGgr) : "0",
        item.totalBonus ? item.totalBonus : "0",
        item.betCount,
        item.totalWithdraw ? formatMoney(item.totalWithdraw) : "0",
        item.channelClickCount,
      ]);
      tbData = [headers, channelInfo, ...data];
      setPdfBody(data);
    }
    else {
      tbData = [headers, channelInfo, []];
      setPdfBody([]);
    }
    setTableData(tbData);
    dispatch(setDocsData_channel(tbData))
  };
  useEffect(() => {
    channelList && updateTableData();
  }, [channelList]);
  const dateHandler = (value, name) => {
    setDateFilters((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (name === "startDate" && value !== '') {
      setDateErr('')
    }
    const dummyObj = { ...dateFilters, [name]: value };
    dispatch(setDateValue(dummyObj))

  }
  function onSearchClick() {
    if (dateFilters.startDate === '' || dateFilters.startDate === null) {
      setDateErr("Start Date is required");
    } else if (dateFilters.startDate !== '') {
      setDateErr('');
      callApi(1, 25, userId)
    }

  }
  function onResetClick() {
    let payload = {
      userId: +userId,
      startDate: "",
      endDate: ""
    }
    const cleanPayload = cleanObject(payload);
    dispatch(setDateValue({
      startDate: "",
      endDate: "",
    }))
    setDateFilters({
      startDate: "",
      endDate: "",
    })
    setDateErr('');
    dispatch(setChannel_page(1))
    dispatch(setChannelItems(25))
    dispatch(setChannelRecords([1, 25]))
    dispatch(listChannels(0, 25, cleanPayload));
    dispatch(setChanelVisible(false))

  }

  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-playerActivity'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row'>
              <div className='col-3'>
                <Input
                  title={"Channel Name"}
                  name={"channel"}
                  ids={"channel"}
                  value={channel}
                  placeholder={"Channel Name"}
                  setValue={(e) => filedsHandler(e)}
                  //   regex={item.regex}
                  errorMsg={channelErr && "Channel Name is required"}
                  info={"Provide Channel Name"}
                  show={true}
                />
              </div>
            </div>
            {/* <div className='mt-20'></div> */}
            <div className='row mt-20 d-flex justify-content-center'>
              <button
                onClick={(e) => submit(e)}
                className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                type={"button"}
                id={"addChannel"}
              >
                {AFFILIATE_CONSTANTS.addChannel}
              </button>
            </div>
          </div>
          <div className="CMS-box CMS-box-content">
            <div className='row'>
              {chanelDates.map((item, ind) => {
                const minDate = [null, dateFilters.startDate];
                const max = dateFilters.endDate ? dateFilters.endDate : new Date();
                const maxDate = [max, new Date()];
                const values = [dateFilters.startDate, dateFilters.endDate];
                return (
                  <div className='col-3' key={ind}>
                    <Datepicker
                      title={item.title}
                      name={item.name}
                      value={values[ind]}
                      setValue={(date, value) => dateHandler(date, value)}
                      errorMsg={ind === 0 ? dateErr : ""}
                      minDate={minDate[ind]}
                      maxDate={maxDate[ind]}
                      ids={item.ids}
                      info={item.info}
                    />
                  </div>
                );
              })}
              <div className="ml-15"></div>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={"searchAffiliate"}
                idreset={"affiliateReset"}
                submit={(e) => onSearchClick(e)}
                reset={(e) => onResetClick(e)}
              />
            </div>
          </div>
          {/*  */}
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              {Array.isArray(channelList) && channelList.length > 0 && (
                <Vpagination
                  data={channelList}
                  totalRecords={props.channelList?.totalRecords}
                  paginationFirstValue={props.page}
                  paginationSecondValue={props.items}
                  setPaginationFirstValue={setChannel_page}
                  setPaginationSecondValue={setChannelItems}
                  recordsShow={props.visibleRecords}
                  setRecordsShow={setChannelRecords}
                  isVisible={props.isToShow}
                  setIsVisible={setChanelVisible}
                  csvData={tableData}
                  exportPDF={exportPDF}
                  payloadData={userId}
                  callApi={callApi}
                  csvFileName={"Channel Report"}
                />
              )}
            </div>
            <Table tableData={tableData} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    channelList: state.affiliateSlice.channelList,
    page: state.affiliateSlice.channel_page,
    items: state.affiliateSlice.channelItems,
    visibleRecords: state.affiliateSlice.channelRecords,
    isToShow: state.affiliateSlice.chanelVisible,
    docsData_channel: state.affiliateSlice.docsData_channel,
    channelField: state.affiliateSlice.channelField,
  };
};

export default connect(mapStateToProps)(Channel);
