import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDateTimeFormate,
  hideInfo,
  hideMobile,
  getDob,
  hideData,
  hideEmail,
} from "../../sharedfiles/helper";
import VerifiedIcon from "@mui/icons-material/Verified";
import { COMMON_CONSTANTS, PRIMARYPLAYER_CONSTANTS } from "../../sharedfiles/Constants"

export const DetailsWrapper = (props) => {
  const { title, children } = props;
  return (
    <div className='col-md-6 col-lg-6 col-xl-4'>
      <div className='CMS-userDetails'>
        <div className='col-6'>
          <div className='userLabel'>{title}</div>
        </div>
        <div className='col-6'>
          {children}
        </div>
      </div>
    </div>
  )
}

const GeneralDetails = (props) => {
  const [masking, setMasking] = useState(false);

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      if (localStorage.getItem("isMasking") === "DATA_MASKING") {
        setMasking(true)
      } else {
        setMasking(false)
      }
    }

    return () => mounting = false;
  }, []);

  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-generalDetails'>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='row no-gutters'>
              <DetailsWrapper title={"Customer ID"}>
                <div className='userData' type='text'>
                  {props.playerData.userId}
                </div>
              </DetailsWrapper>
              <DetailsWrapper title={"Affiliate Site ID / Name"}>
                {props.playerData?.userOtherInfo != undefined ? (
                  <div className='userData'>
                    {props.playerData?.userOtherInfo?.affliateSiteName}
                  </div>
                ) : null}
              </DetailsWrapper>
              <DetailsWrapper title={"Registration IP"}>
                {props.playerData.userLoginDetails != undefined ? (
                  <div className='userData'>
                    {masking ? hideData(
                      props.playerData.userLoginDetails.signupIp,
                      false
                    ) : props.playerData.userLoginDetails.signupIp}
                  </div>
                ) : null}
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Date of Birth"}>
                {
                  masking ? null :
                    <div className='userData'>
                      {props.playerData.userLoginDetails != undefined &&
                        hideInfo(getDob(props.playerData.dob), 1, true)}
                    </div>
                }
              </DetailsWrapper>
              <DetailsWrapper title={"User Name"}>
                <div className='userData'>
                  {masking && props.playerData.userLoginDetails != undefined &&
                    Number(props.playerData.nickName) ? hideMobile(props.playerData.nickName) : props.playerData.nickName}
                </div>
              </DetailsWrapper>
              <DetailsWrapper title={"Surname"}>
                <div className='userData'>
                  {props.playerData.surName}
                </div>
              </DetailsWrapper>

            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Phone Number"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div
                    className='userData'
                    style={{ justifyContent: "normal" }}
                  >
                    {masking ? hideMobile(props.playerData.userOtherInfo.countryCode + props.playerData.userOtherInfo.phone, false) :
                      props.playerData.userOtherInfo.countryCode + props.playerData.userOtherInfo.phone}

                    {props.playerData.userOtherInfo.phone != null &&
                      props.playerData.userOtherInfo.mobileVerified ? (
                      <VerifiedIcon color='success' />
                    ) : (
                      props.playerData.userOtherInfo.phone != null && (
                        <span className='close' style={{ color: "red" }}>
                          <span
                            className='material-icons md-13'
                            data-icon='close'
                          ></span>
                        </span>
                      )
                    )}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Last Visit"}>
                {props.playerData.userLoginDetails?.lastLoginTime != undefined && (
                  <div className='userData'>
                    {getDateTimeFormate(
                      props.playerData.userLoginDetails.lastLoginTime
                    )}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Last Visit IP"}>
                <div className='userData'>
                  {props.playerData.userLoginDetails != undefined && (
                    <span>
                      {masking ? hideData(
                        props.playerData.userLoginDetails.lastLoginIp,
                        false
                      ) : props.playerData.userLoginDetails.lastLoginIp}
                    </span>
                  )}
                </div>
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Email Address"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div
                    className='userData'
                    style={{ justifyContent: "normal" }}
                  >
                    {masking ? hideEmail(
                      props.playerData.userOtherInfo.email,
                      false,
                    ) : props.playerData.userOtherInfo.email}

                    {props.playerData.userOtherInfo.email != null &&
                      props.playerData.userOtherInfo.emailVerified ? (
                      <VerifiedIcon color='success' />
                    ) : (
                      props.playerData.userOtherInfo.email != null && (
                        <span className='close' style={{ color: "red" }}>
                          <span
                            className='material-icons md-13'
                            data-icon='close'
                          ></span>
                        </span>
                      )
                    )}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Referred By"}>
                <div className='userData'>
                  {props.playerData.userLoginDetails != undefined && (
                    <div className='userData'>
                      {props.playerData.userLoginDetails.referralCode}
                    </div>
                  )}
                </div>
              </DetailsWrapper>
              <DetailsWrapper title={"Affiliate Btag"}>
                <div className='userData'>
                  {props.playerData?.userOtherInfo != undefined && (
                    <div className='userData'>
                      {props.playerData.userOtherInfo.affliateBtag}
                    </div>
                  )}
                </div>
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Logout Time"}>
                {props.playerData.userLoginDetails?.lastLogout != undefined && (
                  <div className='userData'>
                    {getDateTimeFormate(
                      props.playerData.userLoginDetails.lastLogout
                    )}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Address"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div className='userData'>
                    {props.playerData.userOtherInfo.address}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"State"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div className='userData'>
                    {props.playerData.userOtherInfo.state}
                  </div>
                )}
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Country"}>
                {props.financialData.playerSummaryVw != undefined && (
                  <div className='userData'>
                    {masking ? hideData(props?.financialData?.playerSummaryVw &&
                      props?.financialData?.playerSummaryVw.country, false) : props?.financialData?.playerSummaryVw &&
                    props?.financialData?.playerSummaryVw.country}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Registered Date"}>
                {props.financialData.playerSummaryVw != undefined && (
                  <div className='userData'>
                    {props.financialData.playerSummaryVw &&
                      getDateTimeFormate(
                        props.financialData.playerSummaryVw
                          .registeredDate)}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Login Device Details"}>
                {props.financialData.betDetails != undefined && (
                  <div className='userData'>
                    {props.financialData.betDetails &&
                      props.financialData.betDetails.primaryChannel.replace(
                        /^\w/,
                        (c) => c.toUpperCase()
                      )}
                  </div>
                )}
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Stake Factor"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div className='userData'>
                    {props.playerData &&
                      props.playerData?.userOtherInfo?.stakeFactor
                      ? props.playerData?.userOtherInfo?.stakeFactor
                      : "-"}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Campaign Id"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div className='userData'>
                    {props.playerData &&
                      props.playerData?.userOtherInfo?.campaignId
                      ? props.playerData?.userOtherInfo?.campaignId
                      : " "}
                  </div>
                )}
              </DetailsWrapper>
              <DetailsWrapper title={"Channel Id"}>
                {props.playerData.userOtherInfo != undefined && (
                  <div className='userData'>
                    {props.playerData &&
                      props.playerData?.userOtherInfo?.channelId
                      ? props.playerData?.userOtherInfo?.channelId
                      : " "}
                  </div>
                )}
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Player Loyalty Level"}>
                <div className='userData'>
                </div>
              </DetailsWrapper>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='userLabel'>{COMMON_CONSTANTS.header_VIP}</div>
                  </div>
                  <div className='col-2 d-flex justify-content-between' style={{
                    alignItems: "center",
                    top: "15px",
                    left: "25px",
                    transform: "translate(-50%, -50%)"
                  }}>
                    <input
                      id='vip'
                      type='checkbox'
                      defaultChecked={props.playerData?.vip}
                      checked={props.playerData?.vip}
                      // disabled={true}
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='userLabel'>{COMMON_CONSTANTS.keepLevelSticky}</div>
                  </div>
                  <div className='col-2 d-flex justify-content-start p-0' style={{
                    alignItems: "center",
                    top: "15px",
                    left: "25px",
                    transform: "translate(-50%, -50%)"
                  }}>
                    <input
                      id=' KeepLevelSticky'
                      type='checkbox'
                      defaultChecked={
                        props.playerData?.userOtherInfo?.keepLevelSticky
                      }
                      checked={props.playerData?.userOtherInfo?.keepLevelSticky}
                      // disabled={true}
                      style={{ pointerEvents: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters'>
              <DetailsWrapper title={"Terms and Conditions"}>
                <div className='userData'>
                  {
                    props.userCommonPrefrence?.termsAndConditions ?
                      "Verified" : "Not Verified"
                  }
                </div>
              </DetailsWrapper>
              <DetailsWrapper title={"User Type"}>
                <div className='userData'>
                  {
                    props.playerData?.userType
                  }
                </div>
              </DetailsWrapper>
              <DetailsWrapper title={"Gender"}>
                <div className='userData'>
                  {
                    props.playerData?.gender
                  }
                </div>
              </DetailsWrapper>
            </div>
            <div className='row no-gutters'>
              <DetailsWrapper title={"Referral Code"}>
                <div className='userData'>
                  {props.playerData.userOtherInfo != undefined &&
                    props.playerData.userRefCode}
                </div>
              </DetailsWrapper>
            </div>
            {/* ===break=== */}
            <div className='row no-gutters general-detailsFooter'>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='userLabel'>
                      {PRIMARYPLAYER_CONSTANTS.header_communicationPreference}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='userData'></div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='CMS-form-group'>
                      <div className='CMS-checkbox'>
                        {props.userCommonPrefrence != undefined && (
                          <input
                            id='PE'
                            type='checkbox'
                            checked={
                              props.userCommonPrefrence.promotionalEmail
                            }
                            disabled={true}
                          />
                        )}
                        <label htmlFor='PE'></label>
                        <span>
                          <strong>{COMMON_CONSTANTS.header_promoEmail}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='CMS-form-group'>
                      <div className='CMS-checkbox'>
                        {props.userCommonPrefrence != undefined && (
                          <input
                            id='PN'
                            type='checkbox'
                            checked={
                              props.userCommonPrefrence?.pushNotificationPhone
                            }
                            disabled={true}
                          />
                        )}
                        <label htmlFor='PN'></label>
                        <span>
                          <strong>{COMMON_CONSTANTS.header_notification}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-4'>
                <div className='CMS-userDetails'>
                  <div className='col-6'>
                    <div className='CMS-form-group'>
                      <div className='CMS-checkbox'>
                        {props.userCommonPrefrence != undefined && (
                          <input
                            id='SMS'
                            type='checkbox'
                            checked={props.userCommonPrefrence.sms}
                            disabled={true}
                          />
                        )}
                        <label htmlFor='SMS'></label>
                        <span>
                          <strong>{COMMON_CONSTANTS.header_sms}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='CMS-form-group'>
                      <div className='CMS-checkbox'>
                        {props.userCommonPrefrence != undefined && (
                          <input
                            id='PC'
                            type='checkbox'
                            checked={
                              props.userCommonPrefrence.phoneCalls
                            }
                            disabled={true}
                          />
                        )}
                        <label htmlFor='PC'></label>
                        <span>
                          <strong>{COMMON_CONSTANTS.header_phnCalls}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    playerData: state.generaldetail?.playerData,
    financialData: state.primaryplayer.financialData,
    addnoteid: state.addNotesSlice.addnoteid,
    userCommonPrefrence: state.generaldetail.userCommonPrefrence,
  };
}
export default connect(mapStateToProps)(GeneralDetails);
