import React, { useEffect } from "react";
import Vpagination from "../../sharedfiles/Vpagination";
import Table from "../Common/table/Table";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import {
  ActivePlActivityInfo,
  DateInputFieldsData,
  activePlayerDateFields,
} from "../Common/data/mapData";
import Datepicker from "../Common/common_inputs/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayerActivity,
  setActivePaginationFirstValue,
  setActivePaginationSecondValue,
  setActiveRecordsShow,
  setIsActiveVisible,
  setPrevFormData,
  setPrevPayload
} from "../PlayerActivity/playerActivitySlice";
import Input from "../Common/common_inputs/Input";
import { COMMON_MSGS, PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
import { Currency, MomentUTCDateTime, MomentUTCDateTimeEnd, MomentUTCDateTimeToday, formatMoney, getDateTimeFormate, getFormattedDate, getUTCDate, regexType } from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Skelton from "../Common/skelton/Skelton";

const ActivePlayer = (props) => {
  const [ActiveCsvData, setActiveCsvData] = useState([]);
  const [activePdfBody, setactivePdfBody] = useState([]);
  const {
    activePlayerData,
    activePaginationFirstValue,
    activePaginationSecondValue,
    activeRecordsShow,
    isActiveVisible,
    prevFormData,
    prevPayload,
  } = useSelector((state) => state.playeractivity);
  const [formData, setFormData] = useState({
    FromDate: "",
    ToDate: "",
    maxAmount: "",
    minAmount: "",
  });

  const [formErrors, setFormErrors] = useState({
    FromDate: "",
    ToDate: "",
    maxAmount: "",
    minAmount: "",
  });
  const dispatch = useDispatch();
  const tHeader = [
    [
      "Customer Id",
      "UserName",
      "Role",
      "Registration Date",
      `Real Balance ${Currency()}`,
      `Turnover ${Currency()}`,
      `GGR ${Currency()}`,
      "Margin%",
      "Bet Count",
      `Average Bet Stake ${Currency()}`,
      "Segment",
    ],
  ];

  const tableStyles = {
    0: "text-center",
  };

  const getDefaultDateRange = () => {
    const startDate = getFormattedDate(7);
    const endDate = new Date();
    return { startDate, endDate };
  };
  
  // useEffect(() => {
  // dispatch(setIsActiveVisible(true))
  // dispatch(getPlayerActivity(
  //   activePaginationFirstValue - 1,
  //   activePaginationSecondValue,
  //   {
  //     dateFrom: MomentUTCDateTimeToday(formData.FromDate),
  //     dateTo: MomentUTCDateTimeEnd(formData.ToDate),
  //   },
  //   "activePlayer"));
  // }, []);


  useEffect(() => {
    if(Object.keys(prevFormData).length > 0){
      setFormData(prevFormData);
      dispatch(getPlayerActivity(
        activePaginationFirstValue - 1,
        activePaginationSecondValue,
        prevPayload,
        "activePlayer"));
        return;
    }
    const { startDate, endDate } = getDefaultDateRange();
    const systemPayload = {
      dateFrom: MomentUTCDateTimeToday(startDate),
      dateTo: MomentUTCDateTimeEnd(endDate)
    }
    if (activePlayerData.data?.length === 0) {
      setFormData({
        FromDate: startDate,
        ToDate: endDate,
      })
      dispatch(setPrevFormData({
        ...formData,
        FromDate: startDate,
        ToDate: endDate,
      }))
      dispatch(getPlayerActivity(
        activePaginationFirstValue - 1,
        activePaginationSecondValue,
        systemPayload,
        "activePlayer"));
    } else {
      formTableData(activePlayerData)
    };
    stateHolder()
  }, []);

  useEffect(() => {
    formTableData(activePlayerData);
  }, [activePlayerData]);

  function formTableData(rawData = { totalRecords: "", data: [] }) {
    const bodyData =
      Object.keys(rawData).length > 0 &&
        Array.isArray(rawData.data) &&
        rawData.data?.length > 0
        ? rawData.data?.map((item) => [
          item.userId,
          item.userName,
          item.role,
          item.registrationDate ? getDateTimeFormate(item.registrationDate) : '-',
          formatMoney(item.realBalance),
          formatMoney(item.turnOver),
          formatMoney(item.ggr),
          item.margin,
          item.betCount,
          formatMoney(item.stakeAverage),
          item.playerSegment ? item.playerSegment : '-',
        ])
        : [];

    setactivePdfBody(bodyData);
    const csvData = [tHeader[0], ActivePlActivityInfo, ...bodyData];
    setActiveCsvData(csvData);
  }

  function stateSetter(name, value) {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function errorSetter(name, value) {
    setFormErrors((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }
  function stateHolder() {
    const isFormDataExist = Object.keys(prevFormData).length
    if (isFormDataExist) {
      Object.entries(prevFormData).map(([key, value]) => setFormData((prevState) => {
        return {
          ...prevState,
          [key]: value,
        }
      }))
    }
  }
  function formHandler(e) {
    const { name, value } = e.target;
    if (name === "minAmount" && +value === 0 && !!value) {
      return;
    }
    if (name === "maxAmount" && +value === 0 && !!value) {
      return;
    }
    stateSetter(name, value);
    let errorMessage = "";
    if (
      name === "minAmount" &&
      formData?.maxAmount &&
      +value > formData?.maxAmount
    ) {
      errorMessage = "Max amount should not be less than min amount";
    } else if (
      name === "maxAmount" &&
      formData?.minAmount &&
      +value < formData?.minAmount
    ) {
      errorMessage = "Max amount should not be less than min amount";
    } else errorMessage = "";

    errorSetter("maxAmount", errorMessage);
    formDataToSliceSetter(name, value)
    const updatedFormData = { ...prevFormData, [name]: value }
    dispatch(setPrevFormData(updatedFormData))
  }

  function dateHandler(value, name) {
    formDataToSliceSetter(name, value)
    if (name === "FromDate" && value !== "") {
      errorSetter("FromDate", "")
    }
    if (name === "ToDate" && value !== "") {
      errorSetter("ToDate", "")
    }
    // const dateErr = formData.FromDate === "" ? "Start Date is required" : "";
    // setActiveDateError(dateErr);
    stateSetter(name, value);
  }
  const formDataToSliceSetter = (key, val) => {
    const newEntry = { ...prevFormData, [key]: val }
    dispatch(setPrevFormData(newEntry));
  }

  function resetButton() {
    setFormData({
      FromDate: "",
      ToDate: "",
      maxAmount: "",
      minAmount: "",
    });
    setFormErrors({
      FromDate: "",
      ToDate: "",
      maxAmount: "",
      minAmount: "",
    });
    dispatch(setPrevPayload({}))
    dispatch(getPlayerActivity(0, 25, {}, "activePlayer"));
    dispatch(setActivePaginationFirstValue(1))
    dispatch(setActivePaginationSecondValue(25))
    dispatch(setActiveRecordsShow([1, 25]))
    dispatch(setPrevFormData({}))
    dispatch(setIsActiveVisible(false))
  }

  function validator() {
    let error = false;
    if (formData.FromDate !== "" && (formData.ToDate === "" || formData.ToDate === null)) {
      errorSetter("ToDate", "Registration To Date is required");
      error = true;
    }
    if (formData.ToDate !== "" && (formData.FromDate === "" || formData.FromDate === null)) {
      errorSetter("FromDate", "Registration From Date is required");
      error = true;
    }
    return error;
  }

  function onSubmit(e) {
    e.preventDefault();
    const isError = validator();
    if (isError) return;
    const systemPayload = {
      dateFrom: formData.FromDate ? MomentUTCDateTimeToday(formData.FromDate) : '',
      dateTo: formData.ToDate ? MomentUTCDateTimeEnd(formData.ToDate) : '',
      maxBetCount: formData.maxAmount,
      minBetCount: formData.minAmount
    };
    dispatch(setPrevPayload(systemPayload))

    dispatch(
      getPlayerActivity(
        0,
        activePaginationSecondValue,
        systemPayload,
        "activePlayer"
      )
    );
    dispatch(setActivePaginationFirstValue(1))
    dispatch(setActivePaginationSecondValue(25))
    dispatch(setActiveRecordsShow([1, 25]))
    dispatch(setIsActiveVisible(false))
  }

  function exportPDF() {
    const title = "Active Player Report";
    exportPDFC(title, tHeader, activePdfBody, {});
  }

  function callApi(pageNum, itemsPerPage,) {
    dispatch(setIsActiveVisible(false))
    const payload = {
      ...prevPayload,
      dateFrom: formData.FromDate ? MomentUTCDateTimeToday(formData.FromDate) : "",
      dateTo: formData.ToDate ? MomentUTCDateTimeEnd(formData.ToDate) : ""
    }
    dispatch(
      getPlayerActivity(
        pageNum - 1,
        itemsPerPage,
        payload,
        "activePlayer"
      )
    );
  }


  return (
    <div>
      <React.Fragment>
        <div className="CMS-tab-panel active" id="CMS-betting">
          <div className="CMS-tabContent">
            <div className="CMS-box CMS-box-content">
              <div className="row">
                {activePlayerDateFields.map((item, ind) => {
                  const minDate = [null, formData.FromDate];
                  const max = formData.ToDate ? formData.ToDate : new Date();
                  const maxDate = [max, new Date()];

                  return (
                    <React.Fragment key={item.id}>
                      <div className="col-3">
                        <Datepicker
                          title={item.title}
                          name={item.name}
                          value={formData[item.name]}
                          errorMsg={formErrors[item.name]}
                          minDate={minDate[ind]}
                          maxDate={maxDate[ind]}
                          setValue={(date, name) => dateHandler(date, name)}
                          ids={item.ids}
                          info={item.info}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
                <div className="col-3">
                  <Input
                    title={"Min Bet count Range"}
                    name={"minAmount"}
                    value={formData.minAmount}
                    placeholder={"Min Bet count "}
                    setValue={(e) => formHandler(e)}
                    ids={"playerActivityUserName"}
                    info={PLAYER_MODULE.minCount}
                    show={true}
                    regex={regexType.notZero}
                  />
                </div>
                <div className="col-3">
                  <Input
                    title={"Max Bet count Range"}
                    name={"maxAmount"}
                    value={formData.maxAmount}
                    placeholder={"Max Bet count "}
                    setValue={(e) => formHandler(e)}
                    ids={"playerActivityUserName"}
                    info={PLAYER_MODULE.maxCount}
                    show={true}
                    errorMsg={formErrors.maxAmount}
                    regex={regexType.notZero}
                  />
                </div>
              </div>
              <div className="mt-20"></div>

              <div className="row">
                <ButtonGroup
                  groupNames={["Search", "Reset"]}
                  idsearch={"newPlayersearch"}
                  idreset={"newPlayerreset"}
                  submit={(e) => onSubmit(e)}
                  reset={(e) => resetButton(e)}
                />
              </div>
            </div>
            <div className="CMS-filter-result" id="result">
              <div className="CMS-pagination">
                <Vpagination
                  data={activePlayerData.data}
                  totalRecords={activePlayerData.totalRecords}
                  paginationFirstValue={activePaginationFirstValue}
                  paginationSecondValue={activePaginationSecondValue}
                  setPaginationFirstValue={setActivePaginationFirstValue}
                  setPaginationSecondValue={setActivePaginationSecondValue}
                  recordsShow={activeRecordsShow}
                  setRecordsShow={setActiveRecordsShow}
                  isVisible={isActiveVisible}
                  setIsVisible={setIsActiveVisible}
                  csvData={ActiveCsvData}
                  exportPDF={exportPDF}
                  callApi={callApi}
                  csvFileName={"New player report"}
                  payloadData={prevPayload}
                // resetCsvData={resetCsvData}
                />
              </div>
              {!isActiveVisible ? <Skelton vLen={5} hLen={7} /> : <Table
                tableData={ActiveCsvData}
                linkIndex={0}
                styles={tableStyles}
              />}
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default ActivePlayer;
