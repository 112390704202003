import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AFFILIATE_CONSTANTS } from "../../sharedfiles/Constants";
import { getFromLocalStorage } from "../../sharedfiles/helper";
import { getCampaignURL } from "../Affiliate/AffiliateSlice";
import No__Data__Found from "../Common/table/No__Data__Found";
import QRpopUp from "./QRpopUp";
function CampaignURL(props) {
  const [open, setOpen] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const dispatch = useDispatch();
  const urlRefs = useRef([]);
  const listURL = useSelector((st) => st.affiliateSlice.campaignURLlist?.urls);
  const campaignId = getFromLocalStorage("campaignId");
  useEffect(() => {
    dispatch(getCampaignURL(campaignId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const copyText = (url, curentBtn) => {
    navigator.clipboard.writeText(url);
    if (curentBtn !== null && curentBtn !== undefined) {
      curentBtn.style.display = "block";
      setTimeout(() => (curentBtn.style.display = "none"), 1000);
    }
  };

  const viewQRcode = (qrcode) => {
    setQrCode(qrcode)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <QRpopUp
        QRcode={qrCode}
        Open={open}
        Close={onClose}
      />
      <div className='CMS-tab-panel active' id='CMS-playerActivity'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>{AFFILIATE_CONSTANTS.header_channelName}</th>
                      <th>{AFFILIATE_CONSTANTS.header_URL}</th>
                      <th>{AFFILIATE_CONSTANTS.header_QRcode}</th>
                      <th>{AFFILIATE_CONSTANTS.copy_URL}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listURL &&
                      Array.isArray(listURL) &&
                      listURL.length > 0 ? (
                      listURL?.map((item, ind) => {
                        return (
                          <tr
                            key={item.url}
                            className='conatiner_ref'
                          >
                            <td>{item.channelName}</td>
                            <td>
                              {item.url}
                            </td>
                            <td className="text-center">
                              <a href={item?.qrcode} download >
                                <span className='link_style'>
                                  Download QR Code
                                </span>
                              </a>
                              <span className="ml-10"></span>
                              <span><b className='pointer' onClick={() => viewQRcode(item?.qrcode)}>
                                View QR Code</b></span>
                            </td>
                            <td className="text-center">
                              <span
                                className='material-icons pointer'
                                data-icon={"content_copy"}
                                // ref={urlRefs.current[ind]}

                                onClick={() =>
                                  copyText(
                                    item.url,
                                    urlRefs.current[ind]
                                  )
                                }
                              ></span>
                              <span
                                className='btn_textCopied'
                                ref={(element) =>
                                  urlRefs.current.push(element)
                                }
                              >
                                Copied
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={20} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CampaignURL;
