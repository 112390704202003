import { createSlice } from "@reduxjs/toolkit";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const UserStakeSlice = createSlice({
    name: "UserStakeSlice",
    initialState: {
        userStakes: [],
        updatedStake: [],
        disableBtn: false,
    },
    reducers: {
        setUserStakes: (state, action) => {
            state.userStakes = action.payload
        },
        setUpdatedStake: (state, action) => {
            state.updatedStake = action.payload
        },
        setDisableBtn: (state, action) => {
            state.disableBtn = action.payload;
        },

    }
})

export const {
    setUserStakes,
    setUpdatedStake,
    setDisableBtn
} = UserStakeSlice.actions

export const getUserStakes = (id) => (dispatch) => {
    const url = playerapi.getUserStakePreference(id)
    apiCaller(url, "GET", {}).then((response) => {
        dispatch(setUserStakes(response.data));
    });
};

export const updateUserStake = (id, param) => (dispatch) => {
    const url = playerapi.saveUserStake(id)
    const obj = {
        stakePreference: param.stakePreference,
        userId: param.userId
    }
    apiCaller(url, "POST", obj).then((res) => {
        dispatch(setUpdatedStake(res.data))
        dispatch(getUserStakes(id))
        dispatch(setDisableBtn(true));
        toaster.success("SUCCESS")
    }).catch((err) => {
        dispatch(setDisableBtn(true));
        const res = err.response
        toaster.error(messageEnhancer(res));
    });
}

export default UserStakeSlice.reducer