import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { pages } from "../../componentRoutes";
import Loader from '../loading/Loader';
import Forbidden from './Forbidden';

const ProtectedRoute = ({ userPermissions }) => {
    const token = localStorage.getItem("token") && localStorage.getItem("merchantId");
    const requiredPermissions = pages.map((page) => page.permissionName);

    if (userPermissions.length > 0) {
        return (
            <Switch>
                {pages.map((page) => (
                    <Route
                        key={page.path}
                        path={page.path}
                        exact
                        render={(props) => {
                            if (userPermissions.includes(page.permissionName)) {
                                return <page.Component {...props} token={token} />;
                            } else {
                                return <Forbidden />;
                            }
                        }
                        }
                    />
                ))}
                <Route
                    path="/:anything"
                    exact
                    render={() => (!token ? <Redirect to="/login" /> : null)}
                />
            </Switch>
        );
    } else if (userPermissions.length === 0) {
        return <Loader colspan />
    }
    else {
        return <Loader colspan />
    }
};

export default ProtectedRoute;
