import React, { useState } from "react";
import { handleResponse } from "../../../api/apiUtil";

const ButtonGroup = (props) => {
  const { groupNames, submit, reset, type, spclButtons, idsearch, idreset, idspecial, freezButton } = props;
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const handleUploadClick = async (e) => {
    setUploadButtonDisabled(true);
    if (submit) {
      await submit(e);
    }
    setTimeout(() => {
      setUploadButtonDisabled(false);
    }, 3000);
  };

  return (
    <>
      <div className='col-12'>
        <div className='CMS-btnContainer'>
          <button
            onClick={freezButton ? handleUploadClick : (submit ? (e) => submit(e) : null)}
            // className={freezButton ? `CMS-btn CMS-btnSecondary active CMS-btnMedium ${uploadButtonDisabled ? 'disable' : ''}` : 'CMS-btn CMS-btnSecondary active CMS-btnMedium'}
            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${freezButton ? 'disable-button' : ''}`}

            type={type ? type : "button"}
            id={idsearch}
          // disabled={freezButton}
          // disabled={freezButton ? uploadButtonDisabled : false}
          >
            {groupNames[0]}
          </button>
          <button
            onClick={() => reset()}
            className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
            type='reset'
            id={idreset}
          >
            {groupNames[1]}
          </button>
          {spclButtons && (
            <button
              className='CMS-btn CMS-btnQuaternary active CMS-btnMedium'
              style={{
                backgroundColor: "#ffbb00",
                border: "none",
              }}
              id={idspecial}
            >
              <a
                href={spclButtons[1].filePath}
                download={spclButtons[1].fileName}
                className='d-flex justify-content-center align-items-center'
              >
                <span
                  className='material-icons mr-5'
                  data-icon='download'
                ></span>
                {spclButtons[0]}
              </a>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ButtonGroup;
