import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import {
  getLoginAccess,
  setErrorMessage,
  setSuccessMessage,
} from './loginSlice'
import { setDefaultPage } from '../AppSlice'
import { setActiveTab, setOpenTabs, setSidebarTabs, setTabStack } from "../sidebar/sidebarSlice"
import { LOGIN_CONSTANTS } from '../../sharedfiles/Constants'
const Login = (props) => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [usernameControl, setUsernameControl] = useState(false)
  const [passwordControl, setPasswordControl] = useState(false)
  const [errorConditon, setErrorConditon] = useState(false)
  const [successConditon, setSuccessConditon] = useState(false)
  const dispatch = useDispatch()
  const userNameHandler = (e) => {
    e.target.value.length > 0 && dispatch(setErrorMessage(''))
    e.target.value.length > 0 && setErrorConditon(false)

    setUserName(e.target.value)
    if (e.target.value.length > 0) {
      setUsernameControl(false)
    }
  }
  const passwordHandler = (e) => {

    e.target.value.length > 0 && dispatch(setErrorMessage(''))
    e.target.value.length > 0 && setErrorConditon(false)

    setPassword(e.target.value)
    if (e.target.value.length > 0) {
      setPasswordControl(false)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    var logindata = {
      password: password,
      userName: userName,
    }
    if (userName !== '' || password !== '') {
      dispatch(getLoginAccess(logindata, props))
    }
    if (userName === '') {
      setUsernameControl(true)
      setErrorConditon(false)
    }

    if (password === '') {
      setPasswordControl(true)
      setErrorConditon(false)
    }
  }
  useEffect(() => {
    let mounting = true
    if (mounting) {
      dispatch(setErrorMessage(''))
      dispatch(setSuccessMessage(''))
      dispatch(setDefaultPage(''))

      const validSession = localStorage.getItem('token') && localStorage.getItem('merchantId');
      if (!validSession) {
        props.cleanStore();
        dispatch(setSidebarTabs([]));
        dispatch(setOpenTabs(["MANAGEMENT REPORTING"]));
        dispatch(setTabStack([]));
        dispatch(setActiveTab("DASHBOARD"));
      }
    }
    return () => {
      mounting = false;
    }
  }, []);

  useEffect(() => {
    if (props.errorMessage !== '') {
      setPasswordControl(false)
      setUsernameControl(false)
      setErrorConditon(true)
    }
    if (password != '' && userName == '') {
      setUsernameControl(true)
      setErrorConditon(false)
    }
    if (password == '' && userName != '') {
      setPasswordControl(true)
      setErrorConditon(false)
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage !== '') {
      setPasswordControl(false)
      setUsernameControl(false)
      setErrorConditon(false)
      setSuccessConditon(true)
    }
  }, [props.successMessage])

  return (
    <>
      <div className="CMS-page CMS-login">
        <div className="CMS-page-content">
          <div className="CMS-box CMS-box-content">
            <div className="CMS-page-header">{LOGIN_CONSTANTS.header_Login}</div>
            <div className="row">
              <div className="col-12">
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">{LOGIN_CONSTANTS.input_userName}</div>
                    <div className="CMS-formControl">
                      <input
                        type="text"
                        name="userName"
                        value={userName}
                        placeholder="Username"
                        onChange={userNameHandler}
                      />
                      <div className="CMS-form-errorMsg">
                        {usernameControl && 'Please input a valid Username'}
                        {/* {errorConditon &&
                          props.errorMessage === 'INVALID_USER_NAME'
                          ? 'Please input a valid Username'
                          : ''} */}
                      </div>
                    </div>
                  </div>
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">{LOGIN_CONSTANTS.input_password}</div>
                    <div className="CMS-formControl">
                      <input
                        type="password"
                        value={password}
                        name="password"
                        placeholder="Password"
                        onChange={passwordHandler}
                      />
                      <div
                        className={'CMS-form-errorMsg'}
                      >
                        {passwordControl && 'Please input a valid Password'}
                        {errorConditon &&
                          props.errorMessage === 'INVALID_USER_NAME_OR_PASSWORD'
                          ? 'Please input a valid username/password'
                          : ''}
                        {successConditon && props.successMessage}
                        {!['INVALID_USER_NAME_OR_PASSWORD'].includes(
                          props.errorMessage,
                        ) && props.errorMessage === 'Account De Activated'
                          ? 'Account Deactivated'
                          : ''}
                      </div>
                    </div>
                  </div>

                  <div className="CMS-btnContainer mt-20 mb-20">
                    <button
                      type="submit"
                      className="CMS-btn CMS-btnSecondary active CMS-btnMedium"
                    >
                      {LOGIN_CONSTANTS.login_btn}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
function mapStateToProps(state) {
  return {
    userData: state.loginSlice.userData,
    message: state.loginSlice.message,
    successMessage: state.loginSlice.successMessage,
    errorMessage: state.loginSlice.errorMessage,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
