import { Avatar, CircularProgress } from '@mui/material'
import React from 'react'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import { useState } from 'react'
import { totpLogin } from '../Login/loginSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { regexType } from '../../sharedfiles/helper'
function TotpLogin(props) {
  const [authCode, setAuthCode] = useState('')
  const [authErr, setAuthErr] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const totpLoginStatus = useSelector(
    (state) => state.loginSlice.totpLoginStatus,
  )
  const loginToken = localStorage.getItem('totpToken')
  const handleAuthCode = (e) => {
    const { value } = e.target
    if (value === '' || regexType.numeric.test(value)) {
      validator(value)
      setAuthCode(value)
    }
  }
  const validator = (value, checker) => {
    let errMsg = ''
    if (value === '') errMsg = 'Authenticator TOTP cannot be empty'
    else errMsg = ''
    if (checker && value !== '') {
      if (authCode.length !== 6) {
        errMsg = 'Invalid TOTP Pin'
      }
    }
    setAuthErr(errMsg)
    return errMsg === ''
  }
  const onSubmit = (e) => {
    const payload = {
      totpPin: authCode,
      totpToken: loginToken,
    }
    if (validator(authCode, true)) {
      setLoading(true)
      dispatch(totpLogin(payload, loginToken, props))
    }
  }

  const userName = localStorage.getItem('userName');
  function handleCall(event) {
    if (event?.keyCode === 13) {
      onSubmit()
    }
  }

  useEffect(() => {
    let mounting = true
    if (mounting) {
      !loginToken && history.push('login')
      window.addEventListener("keydown", handleCall)
    }
    return () => {
      window.removeEventListener("keydown", handleCall, true)
      mounting = false;
    }
  }, [])

  useEffect(() => {
    if (totpLoginStatus && Object.keys(totpLoginStatus).length > 0) {
      if (!totpLoginStatus.status) {
        setAuthErr('Invalid TOTP Pin')
      }
      setLoading(false)
    }
  }, [totpLoginStatus])

  return (
    <React.Fragment>
      <div className="CMS_totp_container">
        {/* <div className="CMS-page-content"> */}
        <div className="CMS_totp_wrapper">
          <div className="CMS_totp_box">
            <div className="totp_user_avatar">
              <Avatar className="avatar">
                {userName ? userName[0].toUpperCase() : 'V'}
              </Avatar>
            </div>
            <div className="totp_id">
              <p>{userName || 'VM1423'}</p>
            </div>
            <div>
              <div className="totp_input_box">
                <ShieldOutlinedIcon className="totp_input_icon" />
                <input
                  type="text"
                  value={authCode}
                  className="totp_input_auth"
                  placeholder="Authenticator TOTP"
                  maxLength={6}
                  onChange={handleAuthCode}
                />
              </div>
              <div className="CMS-form-errorMsg err_msg">{authErr}</div>
            </div>
            <div className="totp_note_msg">
              <span>
                Use your mobile authenticator app to generate a 6 digit TOTP
              </span>
            </div>
            <div className="totp_btn_confirm">
              <button onClick={onSubmit}>
                {loading ? (
                  <CircularProgress
                    className="totp_circular_progress"
                    size={26}
                    thickness={4}
                  />
                ) : (
                  'Continue'
                )}
              </button>
              {/* <span className="totp_forgot_link">Forgot 2FA?</span> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TotpLogin
