import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanObject, getFormateDate, getUTCDate } from "../../sharedfiles/helper";
import ReportPagination from "./ReportsPagination";
import {
  fetchReportData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setVisible,
} from "./reportSlice";
import SearchReport from "./SearchReport";

function ReportsWrapper(props) {
  const {
    withdrawalData,
    depositData,
    placeBetData,
    eventLevelData,
    marketLevelData,
    recordsShow,
    visible
  } = useSelector(state => state.reports)
  const {
    reportTitle,
    pageKey,
    setDateToStore,
    persistDate,
    ReportTable,
    api,
    paginationRequired,
    depositPagination,
    placeBetPagination,
    eventPagination,
    marketLevelPagination,
    pageNumber,
    itemsPerPage,
  } = props;
  const [dates, setDates] = useState({
    dateFrom: "",
    dateTo: "",
  });
  const [errors, setErrors] = useState({
    dateFrom: "",
    dateTo: "",
  });
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const tableShouldView = useSelector((st) => st.reports.tableShouldView);

  useEffect(() => {
    handlePersist();
  }, [tableShouldView]);

  useEffect(() => {
    handlePersist();
  }, []);

  function handlePersist() {
    const isExist = Array.isArray(tableShouldView) &&
      tableShouldView?.length > 0 && tableShouldView?.some((v) => v === pageKey);
    setIsVisible(isExist);
    if (!isExist) {
      setDates({ dateFrom: "", dateTo: "" });
      dispatch(setDateToStore({ dateFrom: "", dateTo: "" }));
    } else {
      setDates(persistDate);
    }
  }
  const setValue = (date, name) => {
    dispatch(setDateToStore({ ...persistDate, [name]: date }));
    setDates((prevState) => {
      return {
        ...prevState,
        [name]: date,
      };
    });
    if (date) {
      setError(name, "");
    }
  };
  const setError = (key, val) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [key]: val,
      };
    });
  };

  const validateFields = (dates) => {
    if (dates.dateFrom === "") {
      setError("dateFrom", "Please select date from");
    } else {
      setError("dateFrom", "");
    }
    if (dates.dateTo === "") {
      setError("dateTo", "Please select date to");
    } else {
      setError("dateTo", "");
    }
  };

  const submit = (e) => {
    e.preventDefault();
    validateFields(dates);
    let form = {
      from: getFormateDate(dates.dateFrom),
      to: getFormateDate(dates.dateTo),
      reportType: pageKey,
    };
    const isValid = Object.keys(cleanObject({ ...dates })).length;
    // dispatch(setDateToStore(dates));
    if (isValid === 2) {
      dispatch(api ? api(form, pageNumber - 1, itemsPerPage) : fetchReportData(form, tableShouldView));
    }
  };

  const callApi = (pageNum, items, formData) => {
    let data = {
      from: getUTCDate(dates.dateFrom),
      to: getUTCDate(dates.dateTo),
    }
    dispatch(setVisible(false))
    dispatch(api(data, pageNum - 1, items))
  }
  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-reports'>
        <div className='CMS-tabContent'>
          <SearchReport
            reportTitle={reportTitle}
            dateFrom={dates.dateFrom}
            dateTo={dates.dateTo}
            errors={errors}
            setValue={(date, name) => setValue(date, name)}
            submit={(e) => submit(e)}
          />

          <div className='mt-20'></div>
          <div className='CMS-filter-result' id='result'>
            <ReportPagination
              isVisible={isVisible}
              paginationRequired={paginationRequired}
              depositPagination={depositPagination}
              placeBetPagination={placeBetPagination}
              eventPagination={eventPagination}
              marketLevelPagination={marketLevelPagination}
              withdrawalData={withdrawalData}
              depositData={depositData}
              placeBetData={placeBetData}
              eventLevelData={eventLevelData}
              marketLevelData={marketLevelData}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              setPaginationFirstValue={setPaginationFirstValue}
              setPaginationSecondValue={setPaginationSecondValue}
              recordsShow={recordsShow}
              setRecordsShow={setRecordsShow}
              visible={visible}
              setVisible={setVisible}
              apicall={(p, i, f) => callApi(p, i, f)}
            />
            {
              isVisible && <ReportTable />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReportsWrapper;
