/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { tabManager } from "../sidebar/sidebarSlice";
import { Link } from "react-router-dom";
import {
  fetchPendingDepositData,
  setPaginationSecondValue,
  setPaginationFirstValue,
  setRecordsToShow,
  setIsVisible,
  setDocsData,
  setStatusType,
  updatePendingDeposit
} from "./pendingDepositSlice";
import {
  getDateTimeFormate,
  formatMoney,
  Currency,
  sortingFilter,
} from "../../sharedfiles/helper";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import VButtonGroup from "../Common/Buttons/VButtonGroup";
import AcceptActionBox from "./AcceptDialog";
import PaymentsAuditor from "../Common/Dialog/PaymentsAuditor";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { COMMON_MSGS, Payment, Payments } from "../../sharedfiles/tooltipMsgs";

const sortState = {
  "userId": false,
  "createdDate": false,
  "status": false,
  "amount": false,
  key: "",
  sortType: "",
  keyType: "",
}


const PendingDeposits = (props) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [imagePopUp, setImagePopUp] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const pageFirstVal = useSelector((state) => state.pendingDeposit.paginationFirstValue);
  const pageSecondVal = useSelector((state) => state.pendingDeposit.paginationSecondValue);
  const recordsToShow = useSelector((state) => state.pendingDeposit.recordsToShow);
  const depositsData = useSelector((state) => state.pendingDeposit.pendingDeposit);
  const statusType = useSelector((state) => state.pendingDeposit.statusType);
  const [status, setStatus] = useState(statusType);
  const [sortables, setSortables] = useState({
    ...sortState
  })
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);

  const callApi = (pageNumber, itemsPerPage) => {
    dispatch(setIsVisible(false));
    dispatch(fetchPendingDepositData(pageNumber - 1, itemsPerPage, status));
  };

  useEffect(() => {
    if (status !== "" && status !== statusType) {
      callApi(pageFirstVal, pageSecondVal);
    }
  }, [status]);


  const handleStatusChange = (e) => {
    const state = e.target.value;
    setStatus(e.target.value);
    dispatch(setPaginationFirstValue(1));
    dispatch(setPaginationSecondValue(25));
    dispatch(setRecordsToShow([1, 25]));
    setTimeout(() => dispatch(setStatusType(state)), 1000);
  };

  const acceptDialogHandler = (item, val) => {
    setData(item);
    if (val === "accept") {
      setAcceptDialog(true);
    } else {
      setDeclineDialog(true);
    }
  };

  // ===image handlers === //
  const handleImageView = (value) => {
    setImagePopUp(true);
    setImageUrl(value);
  };
  const handleImagePopUp = () => {
    setImagePopUp(false);
    setImageUrl("");
  };
  // ===image handlers === //

  const headers = [
    [
      "Customer ID",
      "Creation Date",
      "Payment Status",
      "UTR Number",
      `Amount ${Currency()}`,
      "Created By",
      "Updated By",
      "comment",
    ],
  ];

  const exportPDF = () => {
    const title = "Pending Deposit Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };

  const updateTableToDocs = (key = "", sortType = "", keyType = "") => {
    if (Object.keys(props.pendingDeposit).length > 0) {
      const data =
        props.pendingDeposit &&
        props.pendingDeposit?.data &&
        Array.isArray(props.pendingDeposit?.data) &&
        sortingFilter(props.pendingDeposit?.data, { key, sortType, keyType }).map((p) => [
          p.userId,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.status,
          p.transactionId,
          formatMoney(p.amount),
          p.addedBy,
          p.updatedBy,
          p.msg,
        ]);

      setPdfBody(data);
      const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []]
      setCsvData(csvData);
      dispatch(setDocsData(csvData))
    }
  }
  useEffect(() => {
    updateTableToDocs();
  }, [props.pendingDeposit]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(fetchPendingDepositData(pageNumber - 1, itemsPerPage, status));
  };

  const tabsChange = (item) => {
    props.dispatch(setUserId(item));
    props.dispatch(tabManager(+item, props.tabStack));
    localStorage.setItem("userId", item);
  };


  useEffect(() => {
    setStatus(statusType);

    const dataExist = Object.keys(props?.pendingDeposit).length > 0
    if (!dataExist) {
      callApi(pageFirstVal, pageSecondVal);
    } else {
      setCsvData(props.docsData);
    }
  }, [])

  const handleSortFields = (key, keyType) => {
    const intializing = {
      ...sortState
    }

    const sortType = !sortables[key] ? "ASCENDING" : "DESCENDING"

    setSortables((prevState) => {
      return {
        ...intializing,
        [key]: !prevState[key],
        key: key,
        sortType: sortType,
        keyType: keyType
      }
    });
    updateTableToDocs(key, sortType, keyType);

  }
  const sortKeys = {
    key: sortables.key,
    sortType: sortables.sortType,
    keyType: sortables.keyType
  }

  const auditActionHandler = (message) => {
    let params = {
      id: data?.id,
      message: message,
      status: "DECLINED",
      amount: data?.amount,
    };
    dispatch(updatePendingDeposit(params));
  }

  return (
    <>
      <Dialog__Main title={"Manual Payment"} dOpen={declineDialog} dClose={() => setDeclineDialog(false)}>
        <PaymentsAuditor btnType={["Decline"]} data={data} finish={(msg) => auditActionHandler(msg)} close={() => setDeclineDialog(false)} amountKey={"amount"} />
      </Dialog__Main>

      {imagePopUp ? (
        <Dialog__Main dOpen={imagePopUp} dClose={handleImagePopUp} title={"View"} width={"lg"}>
          <div
            style={{
              height: "500px",
              msOverflow: "scroll",
              userSelect: "text",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imageUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                margin: "auto",
              }}
            />
          </div>
        </Dialog__Main>
      ) : null}

      <Dialog__Main dOpen={acceptDialog} dClose={() => setAcceptDialog(false)} title={"Manual Payment"} >
        <AcceptActionBox data={data} closeDialog={() => setAcceptDialog(false)} />
      </Dialog__Main>
      <div className='CMS-tab-panel active'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result' id='result'>
            <div className='col-md-6 col-lg-4 col-xl-4 mb-20'>
              <div className='CMS-formGroup formGroup-inline'>
                <div>
                  <div style={{ padding: "12px" }}>
                    {KYC_CONSTANTS.header_status}
                    &nbsp;&nbsp;
                    <Tooltips info={Payments.payment_status} />
                  </div>
                </div>
                <div className='col-6'>
                  <div className='CMS-formControl'></div>
                  <div className='CMS-number-of-files CMS-select'>
                    <select
                      id='Depositstatus'
                      name='status'
                      value={status}
                      onChange={(e) => handleStatusChange(e)}
                      className="pointer"
                    >
                      <option value='INIT'>{PAYMENT_CONSTANTS.ddlOpt_pending}</option>
                      <option value='CONFIRMED'>{PAYMENT_CONSTANTS.ddlOpt_confirmed}</option>
                      <option value='DECLINED'>{PAYMENT_CONSTANTS.ddlOpt_declined}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='CMS-pagination'>
              <Vpagination
                data={depositsData?.data}
                totalRecords={depositsData?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsToShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Pending Deposit Report"}
              />
            </div>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th className="pointer" onClick={() => handleSortFields("userId", "num")}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{COMMON_CONSTANTS.header_CID}</p>
                          <Tooltips info={COMMON_MSGS.cid} />
                          <span
                            className='material-icons md-18'
                            data-icon={
                              sortables.userId ? "north" : "south"
                            }
                          ></span>
                        </div>
                      </th>
                      <th className="pointer" onClick={() => handleSortFields("createdDate", "date")}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'> {COMMON_CONSTANTS.creationDate}</p>
                          <Tooltips info={COMMON_MSGS.createdDate} />
                          <span
                            className='material-icons md-18'
                            data-icon={
                              sortables.createdDate ? "north" : "south"
                            }
                          ></span>
                        </div>
                      </th>
                      {status === "CONFIRMED" || status === "DECLINED" ? (
                        <th className="pointer" onClick={() => handleSortFields("updatedDate", "date")}>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'> {COMMON_CONSTANTS.updatedDate}</p>
                            <Tooltips info={COMMON_MSGS.updatedDate} />
                            <span
                              className='material-icons md-18'
                              data-icon={
                                sortables.updatedDate ? "north" : "south"
                              }
                            ></span>
                          </div>
                        </th>
                      ) : null}

                      <th className="pointer" onClick={() => handleSortFields("status", "str")}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentStatus}</p>
                          <Tooltips info={Payment.paymentSts} />
                          <span
                            className='material-icons md-18'
                            data-icon={
                              sortables.status ? "north" : "south"
                            }
                          ></span>
                        </div>
                      </th>

                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{PAYMENT_CONSTANTS.th_UTR_No}</p>
                          <Tooltips info={Payment.UTR_no} />
                        </div>
                      </th>

                      <th className="pointer" onClick={() => handleSortFields("amount", "num")}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'> {`Amount ${Currency()}`}</p>
                          <Tooltips info={COMMON_MSGS.Amount} />
                          <span
                            className='material-icons md-18'
                            data-icon={
                              sortables.amount ? "north" : "south"
                            }
                          ></span>
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{COMMON_CONSTANTS.createdBy}</p>
                          <Tooltips info={COMMON_MSGS.createdBy} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{COMMON_CONSTANTS.updatedBy}</p>
                          <Tooltips info={COMMON_MSGS.updatedBy} />
                        </div>
                      </th>
                      {status === "INIT" ?
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                            <Tooltips info={COMMON_MSGS.Action} />
                          </div>
                        </th>
                        :
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{PAYMENT_CONSTANTS.th_comments}</p>
                            <Tooltips info={COMMON_MSGS.comment} />
                          </div>
                        </th>}

                    </tr>
                  </thead>
                  <tbody>
                    {props.pendingDeposit &&
                      props.pendingDeposit?.totalRecords &&
                      props.pendingDeposit?.totalRecords > 0 ? (
                      props.pendingDeposit?.data &&
                      Array.isArray(props.pendingDeposit?.data) &&
                      sortingFilter(props.pendingDeposit?.data, { ...sortKeys }).map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link
                                to={`/customerid/${item.userId}`}
                                onClick={() => tabsChange(item.userId)}
                              >
                                {item.userId}
                              </Link>
                            </td>
                            <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>

                            {status === "CONFIRMED" || status === "DECLINED" ? (
                              <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                            ) : null}
                            <td>
                              <div>
                                {item.status}
                              </div>
                              <div>
                                <button
                                  className='CMS-btn CMS-btnSecondary active CMS-btnSmall CMS-btnView'
                                  type='button'
                                  id='DepositViewbtn'
                                  onClick={() => handleImageView(item.url)}
                                >
                                  {COMMON_CONSTANTS.view_btn}
                                </button>
                              </div>
                            </td>
                            <td>{item.transactionId}</td>
                            <td className='text-right'>
                              {formatMoney(item.amount)}
                            </td>
                            <td>{item.addedBy}</td>
                            <td>{item.updatedBy}</td>
                            {status === "INIT" ? (
                              <td>
                                <VButtonGroup
                                  buttons={["Accept", "Decline"]}
                                  accept={() => acceptDialogHandler(item, "accept")}
                                  decline={() => acceptDialogHandler(item, "decline")}
                                />
                              </td>
                            ) : (
                              <td>{item.msg}</td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={22} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    userData: state.paymentSearchSlice.userData,
    snackbar: state.paymentHistorySlice.snackbar,
    pendingDeposit: state.pendingDeposit.pendingDeposit,
    userId: state.playersearch.userId,
    isVisible: state.pendingDeposit.isVisible,
    docsData: state.pendingDeposit.docsData,
    tabStack: state.sidebar.tabStack
  };
}

export default connect(mapStateToProps)(PendingDeposits);
