import React, { useEffect, useState } from "react";
import Input from "../Common/common_inputs/Input";
import Tooltips from "../Common/Tooltip";
import Select from "../Common/common_inputs/Select_DDL";
import { sportApplicable } from "../Common/data/createBonus/createBonusData";
import { BONUS_CONSTANTS } from "../../sharedfiles/Constants";
import { Bonusing } from "../../sharedfiles/tooltipMsgs";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";

const AllocationComponent = (props) => {
  const { data,
    fieldClass,
    errors,
    setValues,
    state,
    titleShow,
    showDdl,
    applicableType,
    sportsList,
    ids,
    deafaultValues,
    setIds,
    selectedOnce,
    setSelectedRC,
    clearAll,
    setClear,
    unlockSportErr,
    wagerAppTypeErr,
    rollApplicableType,
    rollOver,
    rollSportids,
    rollDeafaultValues,
    rollSetIds,
    rollSelectedOnce,
    rollSetSelected,
    rollClearAll,
    rollSetClear,
    rollOverSportErr,
    rollAppTypeErr,
    ...rest
  } = props;
  const RCtypeErrMsg = "RC Applicable Type Required"
  const [releaseData, setReleaseData] = useState({ fields: [] });
  useEffect(() => {
    if (rest.applicableSports === "CASINO" || rest.applicableSports === "NE_GAMES") {
      let filteredFields = data.fields.filter(
        (field) => field.name !== "unlockNumberOfLegs" && field.name !== "unlockOddsThreshold"
      );
      if (rollOver) {
        filteredFields = filteredFields.filter(
          (field) => field.name !== "rollNumberOfLegs" && field.name !== "rollOddsThreshold"
        );
      }
      setReleaseData({ ...data, fields: filteredFields });
    } else {
      setReleaseData(data);
    }
  }, [rest.applicableSports, data, rollOver]);

  const getFilteredOptions = (applicableType) => {
    if (applicableType === "ALL" || applicableType === "GLOBAL") {
      return sportApplicable;
    }
    return sportApplicable.filter(option => option.value === applicableType);
  };

  return (
    <React.Fragment>
      <div class="col-lg-6 col-xl-6">
        <div class="CMS-cardBox bg-primary-light">
          <div class="row">
            <div class="col-12">
              <div class="CMS-formLabel justify-content-center mb-10">
                {data.title}
                &nbsp;&nbsp;
                <Tooltips info={data.info} show={true} />
              </div>
            </div>

            {releaseData &&
              releaseData?.fields?.map((field, index) => {
                return (
                  <div
                    // className="col-xl-8"
                    className={fieldClass}
                    // style={{ height: "65px" }}
                    key={field.id}
                  >
                    <div className="CMS-formGroup">
                      <div className="CMS-formControl">
                        <Input
                          title={titleShow ? field.tittle : ""}
                          type={field.type}
                          ids={field.ids}
                          name={field.name}
                          placeholder={field.placeholder}
                          regex={field.regex}
                          maxLen={field.maxLength}
                          errorMsg={
                            errors.includes(field.name) && field.errorMsg
                          }
                          disabled={field.disabled}
                          value={state[field.name]}
                          maxValue={field.maxValue}
                          setValue={(event) => setValues(event)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {showDdl &&
            <div className='row'>
              <div className='col-4'>
                <Select
                  title={"RC Applicable Type"}
                  name={rollOver ? "rollOverApplicableType" : "unlockApplicableType"}
                  value={rollOver ? rollApplicableType : applicableType}
                  setValue={(event) => setValues(event)}
                  // options={sportApplicable}
                  options={getFilteredOptions(rest.applicableSports)}
                  ids={"unlockApplicableType"}
                  info={"Select Applicable Type  Eg: Sports/Casino/All"}
                  errorMsg={rollOver ? (rollAppTypeErr && RCtypeErrMsg) : (wagerAppTypeErr && RCtypeErrMsg)}
                />
              </div>
              {
                rollApplicableType === "SPORTS" ?
                  <div className='col-4'>
                    <div className='CMS-formGroup'>
                      <div className='CMS-formLabel'>
                        {BONUS_CONSTANTS.unlockApplicable_sports}
                        &nbsp;&nbsp;
                        <Tooltips info={Bonusing.sports} />
                      </div>
                      <MultiSelectDDL
                        name="unlocksportsSelected"
                        options={sportsList}
                        deafaultValues={rollDeafaultValues}
                        ids={rollSportids}
                        setIds={(value) => rollSetIds(value)}
                        selectedOnce={rollSelectedOnce}
                        setSelected={rollSetSelected}
                        clearAll={rollClearAll}
                        setClear={() => rollSetClear()}
                        sportDDL
                      />
                      {rollOverSportErr && (
                        <div
                          className="CMS-form-errorMsg"
                          style={{ marginTop: "-5px" }}
                        >
                          {BONUS_CONSTANTS.sportDdl_ErrMsg}
                        </div>
                      )}
                    </div>
                  </div> : ''
              }
              {applicableType === "SPORTS" ?
                <div className="col-4">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {BONUS_CONSTANTS.unlockApplicable_sports}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.sports} />
                    </div>
                    <MultiSelectDDL
                      name="unlocksportsSelected"
                      options={sportsList}
                      deafaultValues={deafaultValues}
                      ids={ids}
                      setIds={(value) => setIds(value)}
                      selectedOnce={selectedOnce}
                      setSelected={setSelectedRC}
                      clearAll={clearAll}
                      setClear={() => setClear()}
                      sportDDL
                    />
                    {unlockSportErr && (
                      <div
                        className="CMS-form-errorMsg"
                        style={{ marginTop: "-5px" }}
                      >
                        {BONUS_CONSTANTS.sportDdl_ErrMsg}
                      </div>
                    )}
                  </div>
                </div> : ''
              }
              {(applicableType === "CASINO" || applicableType === "NE_GAMES") &&
                <div className="col-4">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {BONUS_CONSTANTS.header_casino}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.provider} />
                    </div>
                    <MultipleSelectDDL
                      options={rest.providerOptions}
                      onSelect={rest.onSelectProvider}
                      shouldReset={rest.shouldProviderFalse}
                      initialSelected={rest.initialSelectedProvider}
                    />
                    {errors.includes("releaseProviders") && (
                      <div
                        className="CMS-form-errorMsg"
                        style={{ marginTop: "-5px" }}
                      >
                        {BONUS_CONSTANTS.provider_ErrMsg}
                      </div>
                    )}
                  </div>
                </div>
              }
              {(applicableType === "CASINO" || applicableType === "NE_GAMES") &&
                rest.initialSelectedProvider && rest.initialSelectedProvider.length > 0 &&
                <div className="col-4">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {BONUS_CONSTANTS.gamename}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.game_name} />
                    </div>
                    <MultipleSelectDDL
                      options={rest.releaseGameName}
                      onSelect={rest.selectReleaseGamename}
                      shouldReset={rest.releaseGameNameFalse}
                      initialSelected={rest.initiallyGameName}
                    />
                    {errors.includes("releaseGameName") && (
                      <div
                        className="CMS-form-errorMsg"
                        style={{ marginTop: "-5px" }}
                      >
                        {BONUS_CONSTANTS.gameName_ErrMsg}
                      </div>
                    )}
                  </div>
                </div>
              }

              {(rollApplicableType === "CASINO" || rollApplicableType === "NE_GAMES") &&
                <div className="col-4">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {BONUS_CONSTANTS.header_casino}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.provider} />
                    </div>
                    <MultipleSelectDDL
                      options={rest.providerOptions}
                      onSelect={rest.selectRolloverProvider}
                      shouldReset={rest.rollOverProviderReset}
                      initialSelected={rest.initialRolloverProvider}
                    />
                    {errors.includes("rollOverProvider") && (
                      <div
                        className="CMS-form-errorMsg"
                        style={{ marginTop: "-5px" }}
                      >
                        {BONUS_CONSTANTS.provider_ErrMsg}
                      </div>
                    )}
                  </div>
                </div>
              }
              {(rollApplicableType === "CASINO" || rollApplicableType === "NE_GAMES") &&
                rest.initialRolloverProvider && rest.initialRolloverProvider.length > 0 &&
                <div className="col-4">
                  <div className="CMS-formGroup">
                    <div className="CMS-formLabel">
                      {BONUS_CONSTANTS.gamename}
                      &nbsp;&nbsp;
                      <Tooltips info={Bonusing.game_name} />
                    </div>
                    <MultipleSelectDDL
                      options={rest.releaseGameName}
                      onSelect={rest.selectRolloverGameName}
                      shouldReset={rest.rollOverGameNameReset}
                      initialSelected={rest.initialRolloverGameName}
                    />
                    {errors.includes("rollOverGameName") && (
                      <div
                        className="CMS-form-errorMsg"
                        style={{ marginTop: "-5px" }}
                      >
                        {BONUS_CONSTANTS.gameName_ErrMsg}
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>}
        </div>
      </div>
    </React.Fragment >
  );
};

export default AllocationComponent;
