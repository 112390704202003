import { handleError } from "./apiUtil";
export function fileCallerApi(url, multipartData) {
  var myHeaders = new Headers();
  myHeaders.append("accept", "*/*");
  myHeaders.append("Authorisation", localStorage.getItem("token"));
  myHeaders.append(
    "MerchantId",
    localStorage.getItem("merchantkey")
      ? localStorage.getItem("merchantkey")
      : localStorage.getItem("merchantId")
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: multipartData,
    redirect: "follow",
  };


  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            resolve(json);
          });
        } else if (response.status === 400) {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            response.json().then((json) => {
              reject(json);
            }).catch((error) => {
              console.error('Error parsing JSON:', error);
              reject({ error: 'Invalid JSON response' });
            });
          } else {
            reject({ error: 'Invalid response format' });
          }
        }
      })
      .catch(handleError);
  });

  // return new Promise((resolve, reject) => {
  //   fetch(url, requestOptions)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         response.json().then((json) => {
  //           return resolve(json);
  //         });
  //       }
  //       if (response.status === 400) {
  //         response.json().then((json) => {
  //           reject(json);
  //         });
  //       }
  //     })
  //     .catch(handleError);
  // });
}

export function fileCallerPUTApi(url, multipartData) {
  var myHeaders = new Headers();
  myHeaders.append("accept", "*/*");
  myHeaders.append("Authorisation", localStorage.getItem("token"));
  myHeaders.append(
    "MerchantId",
    localStorage.getItem("merchantkey")
      ? localStorage.getItem("merchantkey")
      : localStorage.getItem("merchantId")
  );

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: multipartData,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            return resolve(json);
          });
        }
        if (response.status === 400) {
          response.json().then((json) => {
            reject(json);
          });
        }
      })
      .catch(handleError);
  });
}
