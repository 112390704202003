import React from "react";
import Dialog__Main from "../Common/Dialog/Dialog__Main";

export const ForceLogout = (props) => {

  const confirmLogout = () => {
    localStorage.clear();
    // props.history.push("/login");
    window.location.pathname = "/login";
  }

  return (
    <>
      <Dialog__Main title={"Alert"} dOpen={true} closable={false}>
        <div className="CMS-msgBox CMS-error"
          style={{
            fontSize: "18px",
          }}>
          <div className="CMS-msgBox-container">Please contact to the Administrator for Permission</div>
        </div>
        <div className='CMS-btnContainer mb-10 mt-10'>
          <button
            className='CMS-btn CMS-btnTertiary active CMS-btnMedium'
            onClick={confirmLogout}
          >
            OK
          </button>
        </div>
      </Dialog__Main>
    </>
  );
};

export default ForceLogout;
