import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  getBrandConfig,
  getConfigValues,
  addConfig,
  setDialogOpen,
  getSmsProvideList,
  getProviderList,
} from "./BrandConfigSlice";
import { useDispatch } from "react-redux";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import No__Data__Found from "../Common/table/No__Data__Found";
import { BRAND_CONFIG_CONSTANTS, COMMON_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import { BrandData } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import { regexType } from "../../sharedfiles/helper"
const BrandConfig = (props) => {
  const dispatch = useDispatch();
  const [configName, setConfigName] = useState("");
  const [configDefaultValue, setConfigDefaultValue] = useState("");
  const [configDropValue, setConfigDropValue] = useState("");
  const [falseCondition, setFalseCondition] = useState(true);
  const [disable, setDisable] = useState(false)
  const [otpProvider, setOtpProvider] = useState('')
  const smsProviderList = useSelector((p) => p.brandconfig.smsProviderList)
  useEffect(() => {

    const dataExist = Object.keys(props.brandData).length > 0
    if (!dataExist) {
      dispatch(getBrandConfig());
    }
    const configExist = Object.keys(props?.configValue).length > 0
    if (!configExist) {
      dispatch(getConfigValues());
    }
  }, []);

  useEffect(() => {
    dispatch(getSmsProvideList())
  }, [])

  // const userNameReg = /^[a-z](?:_?[a-z0-9]+)*$/i;
  const configNamehandler = (e) => {
    setConfigDropValue("");
    setConfigName(e.target.value);
    const defaultValue =
      props.brandData !== undefined &&
      props.brandData !== null &&
      Object.keys(props.brandData).length > 0 &&
      props.brandData.filter((item) => item.key === e.target.value);

    const defaultKeyValue =
      props.brandData !== undefined &&
      props.brandData !== null &&
      Object.keys(props.brandData).length > 0 &&
      defaultValue.length > 0 &&
      defaultValue[0].value;

    if (defaultKeyValue == false) {
      setFalseCondition(false);
      setConfigDefaultValue("");
      setConfigDropValue("");
    }
    if (!false) {
      setFalseCondition(true);
    }

    setConfigDefaultValue(defaultKeyValue);
  };

  const OTPProviderHandle = (e) => {
    const { name, value } = e.target
    setOtpProvider(value)
    dispatch(getProviderList(value))
  }

  const configDropValuehandler = (e) => {
    const { name, value } = e.target;
    const numberRegex = /^[0-9\b]+$/;
    const regex = /^[1-9][0-9]*$/

    if (name == "string") {
      if (value) {
        setConfigDefaultValue(value);
        setConfigDropValue(value);
        setDisable(false)

      }
      if (!value) {
        setConfigDefaultValue("");
        setConfigDropValue("");
        setDisable(false)

      }
    }
    if (name == "number") {
      if (regex.test(value)) {
        setConfigDefaultValue(value);
        setConfigDropValue(value);
        setDisable(true)

      }
      if (!regex.test(value)) {
        setConfigDefaultValue("");
        setConfigDropValue("");
        setDisable(true)
      }
      if (value === "") {
        setDisable(true)
      }
      else if (Number(value) === 0) {
        setDisable(true)
      } else if (value > 0) {
        setDisable(false)
      }
    }
    if (name == "select") {
      if (value) {
        setConfigDefaultValue(value);
        setConfigDropValue(value);
        setDisable(false)
      }
      if (!value) {
        setConfigDefaultValue("");
        setConfigDropValue("");
      }
      if (value === "") {
        setDisable(true)
      }
    }
  };

  const onDialogClose = () => {
    props.dispatch(setDialogOpen(false));
  };

  const handlOpenDialog = () => {
    props.dispatch(setDialogOpen(!props.dialogOpen));
  };
  const handleAdd = () => {
    var data = {
      key: configName,
      value: configDropValue,
    };
    dispatch(addConfig(data));
    setTimeout(() => {
      dispatch(getBrandConfig());
    }, 500);
    setConfigDropValue("");
    setConfigName("");
    dispatch(setDialogOpen(false));
  };
  const headers = [
    "Name",
    "Message Type",
    "Value",
    "Action"
  ]
  const info = [
    "Provide config keys to be choosen",
    "Provide message Type to be choosen",
    "Provide config values",
    "Showing action to be taken"
  ]
  return (
    <React.Fragment>
      <Dialog__Main
        dOpen={props.dialogOpen}
        dClose={onDialogClose}
        title={"Add Value"}
      >
        <div className='dialog-box3'>
          <div className='CMS-box CMS-box-content'>
            <div className='CMS-box CMS-table CMS-table-triped'>
              <table>
                <thead>
                  <tr>
                    {headers.map((item, index) => (
                      configName === "OTP_PROVIDER_TYPE" || index !== 1 ? (
                        <th key={index}>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item}</p>
                            <Tooltips info={`${info[index]}`} />
                          </div>
                        </th>
                      ) : null
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='CMS-file-type CMS-select'>
                        <select
                          id='configDDL'
                          value={configName}
                          onChange={configNamehandler}
                          className="pointer"
                        >
                          <option value=''>Select</option>
                          {props?.configValue &&
                            Array.isArray(props?.configValue) &&
                            Object.keys(props?.configValue).length > 0 &&
                            props?.configValue.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.name}
                                  name={item.type}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </td>
                    {configName === "OTP_PROVIDER_TYPE" &&
                      <td>
                        <div className='CMS-file-type CMS-select'>
                          <select
                            id='configDDL'
                            value={otpProvider}
                            onChange={(e) => OTPProviderHandle(e)}
                            className="pointer"
                          >
                            <option value=''>Select</option>
                            <option value='SMS'>SMS</option>
                            <option value='EMAIL'>EMAIL</option>

                          </select>
                        </div>
                      </td>}
                    <td>
                      {configName !== "" ? (
                        props?.configValue &&
                        Array.isArray(props?.configValue) &&
                        Object.keys(props?.configValue).length > 0 &&
                        props?.configValue
                          ?.filter((item) => item.name === configName)
                          .map((value, index) => {
                            if (value.type === "DROPDOWN") {
                              return (
                                <React.Fragment key={index}>
                                  <div className='CMS-file-type CMS-select' >
                                    <select
                                      name='select'
                                      value={
                                        configDropValue !== ""
                                          ? configDropValue
                                          : configDefaultValue
                                      }
                                      onChange={configDropValuehandler}
                                    >
                                      <option value=''>Select</option>
                                      {
                                        value.name === "OTP_PROVIDER_TYPE" && otpProvider !== '' ? props.providerList && props.providerList?.map((ddValue, ddInd) => (
                                          <option value={ddValue.smsProviderId} key={ddInd}>
                                            {ddValue.smsProviderId}
                                          </option>
                                        )) : value.name === "OTP_PROVIDER_TYPE" && otpProvider === '' ? props.smsProviderList && props.smsProviderList?.map((ddValue, ddInd) => (
                                          <option value={ddValue.smsProviderId} key={ddInd}>
                                            {ddValue.smsProviderId}
                                          </option>
                                        ))
                                          : (value?.dropDownValue?.map((ddValue, ddInd) => (
                                            <option value={ddValue} key={ddInd}>
                                              {ddValue}
                                            </option>
                                          )))}
                                    </select>
                                  </div>
                                </React.Fragment>
                              );
                            }
                            if (value.type === "NUMBER") {
                              return (
                                <React.Fragment key={index}>
                                  <div className='CMS-formControl'>
                                    <input
                                      type='text'
                                      placeholder='Number'
                                      name='number'
                                      value={
                                        configDropValue !== ""
                                          ? configDropValue
                                          : configDefaultValue
                                      }
                                      onChange={configDropValuehandler}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            }
                            if (value.type === "STRING") {
                              return (
                                <React.Fragment key={index}>
                                  <div className='CMS-formControl'>
                                    <input
                                      type='text'
                                      placeholder='url'
                                      name='string'
                                      value={
                                        configDropValue !== ""
                                          ? configDropValue
                                          : configDefaultValue
                                      }
                                      onChange={configDropValuehandler}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })
                      ) : (
                        <div className='CMS-formControl'>
                          <input
                            type='text'
                            placeholder='Select a Name'
                            id='configvalue'
                            name='string'
                            disabled
                            value={""}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <button
                        className={`CMS-btn CMS-btnStatus CMS-statusActive CMS-btnMedium ${disable ? "disable-button" : ""}`}
                        id='brandconfigbtn'
                        style={{
                          padding: "0px 40px",
                          cursor: "pointer",
                          margin: "0 auto"
                        }}
                        onClick={() => handleAdd()}
                      >
                        {COMMON_CONSTANTS.save}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog__Main>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <button
            className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
            id='addValue'
            onClick={() => handlOpenDialog()}
          >
            {BRAND_CONFIG_CONSTANTS.addValue_Btn}
          </button>
          <div className='mt-20'></div>
          <div className='CMS-filter-result'>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{BRAND_CONFIG_CONSTANTS.header_configKey}</p>
                          <Tooltips info={BrandData.Config_Key} />
                        </div>
                      </th>
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{BRAND_CONFIG_CONSTANTS.header_configValue}</p>
                          <Tooltips info={BrandData.Config_Value} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.brandData &&
                      props.brandData !== undefined &&
                      props.brandData !== null &&
                      Array.isArray(props.brandData) &&
                      Object.keys(props.brandData).length > 0 &&
                      props.brandData.length > 0 ? (
                      props.brandData.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{item.id}</td> */}
                            <td>{item.key}</td>
                            <td>{item.value}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={5} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    brandData: state.brandconfig.brandData,
    configValue: state.brandconfig.configValue,
    dialogOpen: state.brandconfig.dialogOpen,
    providerList: state.brandconfig.providerList,
    smsProviderList: state.brandconfig.smsProviderList
  };
};
export default connect(mapStateToProps)(BrandConfig);
