import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";

export const LoginHistorySlice = createSlice({
  name: "loginHistorySlice",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    userid: "",
    userData: [],
    recordsShow: [1, 25],
    isVisible: false,
  },
  reducers: {
    setLoginHistorySearch: (state, action) => {
      state.userData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});
export const {
  setLoginHistorySearch,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
} = LoginHistorySlice.actions;



export const getaddLoginHistory =
  (id, pagenumbervalue, itemperpagevalue, params) =>
    (dispatch) => {
      const clean = (obj) => {
        for (var propName in obj) {
          if (
            obj[propName] == null ||
            obj[propName] === undefined ||
            obj[propName] === ""
          ) {
            delete obj[propName];
          }
        }
        return obj;
      };
      let obj = {};
      if (params) {
        obj = clean(params);
      }
      const url =
        `${playerapi.getAddLoginHistory}` +
        id +
        "/login/activity" +
        "?pageNumber=" +
        pagenumbervalue +
        "&itemsPerPage=" +
        itemperpagevalue;

      apiCaller(url, "POST", obj).then((response) => {
        if (response !== null && response) {
          dispatch(setLoginHistorySearch(response.data));
          dispatch(setIsVisible(true));
        }
      });
    };
export default LoginHistorySlice.reducer;
