import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBrand } from "../Dashboard/dashboardSlice";
import { editRole } from "./createRoleSlice";
import MultiSelectDDL from "../../sharedfiles/MultiSelectDDL";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import { CREATEROLE_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { Admin } from "../../sharedfiles/tooltipMsgs";

export const Edit = (props) => {
  const [roleEdit, setRoleEdit] = useState("");
  const [brandId, setBrandId] = useState("");
  const dispatch = useDispatch();
  const [brandValues, setBrandValues] = useState({});
  const [openconfirmModal, setOpenConfirmModal] = useState(false);
  const alreadySelected =
    Object.keys(props.defaultData).length > 0 && props.defaultData.permissionId;
  const [selected, setSelected] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [ids, setIds] = useState([]);
  const [brandError, setBrandError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);

  const handlebrandId = (e) => {
    e.target.value != "" ? setBrandError(false) : setBrandError(true);
    const selectedBrand = props.brandData.find(
      (item) => item.merchantKey == e.target.value
    );
    if (e.target.value.length > 0) {
      setBrandValues({
        clientId: selectedBrand.clientId,
        brandId: selectedBrand.brandId,
      });
    }
    setBrandId(e.target.value);
  };

  useEffect(() => {
    let mounting = true;
    if (mounting) {
      // setting existing paermissions data
      setIds((prev) => {
        return [...new Set([...prev, ...props.item.permissionId])]
      });

      // setting existing brand Id data
      const clientId = props.item.clientId;
      const brand = props.brandData?.find(brand => brand.clientId === clientId);
      setBrandValues({
        clientId: brand.clientId,
        brandId: brand.brandId,
      });
      setBrandId(brand.merchantKey);

    }
    return () => mounting = false
  }, [])


  const handleroleEdit = (e) => {
    setRoleEdit(e.target.value);
  };

  const onSave = () => {
    const payLoad = {
      id: props.defaultData.id,
      brandId: [brandValues.brandId],
      clientId: brandValues.clientId,
      roleName: roleEdit !== "" ? roleEdit : props.defaultData.roleName,
      permissionId: [...ids],
    };
    dispatch(editRole(payLoad));

    dispatch(getBrand());
    setBrandValues({});
    setBrandId("");
    props.setOpenEditDialog(false);
    close();
  };
  const setSelectedIds = (ids) => {
    ids != "" ? setPermissionError(false) : setPermissionError(true);
    setIds(ids);
  };

  const cancelHandler = () => {
    props.setOpenEditDialog(false);
  };
  const close = () => {
    setOpenConfirmModal(false);
  };
  const handleProceed = () => {
    Object.keys(brandValues).length == 0
      ? setBrandError(true)
      : setBrandError(false);
    ids.length == 0 ? setPermissionError(true) : setPermissionError(false);
    if (Object.keys(brandValues).length > 0 && ids.length > 0) {
      setOpenConfirmModal(true);
    }
  };
  return (
    <React.Fragment>
      <div className='dialog-box3'>
        <div className='CMS-box CMS-box-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {CREATEROLE_CONSTANTS.brandId}
                  &nbsp;&nbsp;
                  <Tooltips info={Admin.Brand_Id} />
                </div>
                <div className='col-9'>
                  <div className='CMS-formControl'>
                    <div className='CMS-number-of-files CMS-select'>
                      <select
                        id='betStatus'
                        name='betStatus'
                        value={brandId}
                        onChange={handlebrandId}
                        className="pointer"
                      >
                        <option value=''>select</option>
                        {props.brandData?.map((brandId, index) => {
                          return (
                            <option key={index} value={brandId.merchantKey}>
                              {brandId.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {brandError && (
                      <div className='text-danger'>
                        {CREATEROLE_CONSTANTS.brand_ErrMsg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {CREATEROLE_CONSTANTS.permission_name}
                  <Tooltips info={Admin.Permission_Name} />
                </div>
                <div className='col-9'>
                  <MultiSelectDDL
                    options={props.permissions}
                    setSelected={setSelected}
                    setIds={setSelectedIds}
                    ids={ids}
                    selectedOnce={selected}
                    clearAll={clearAll}
                    setClear={setClearAll}
                    deafaultValues={alreadySelected}
                  />
                  {permissionError && (
                    <div className='text-danger'>{CREATEROLE_CONSTANTS.emptyPermission_ErrMsg}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <div className='CMS-formGroup formGroup-inline'>
                <div className='CMS-formLabel'>
                  {CREATEROLE_CONSTANTS.header_role}
                  &nbsp;&nbsp;
                  <Tooltips info={Admin.role} />
                </div>
                <div className='col-9'>
                  <div className='CMS-formControl'>
                    <input
                      type='string'
                      placeholder='Role'
                      name='role'
                      value={
                        roleEdit ? roleEdit : props.defaultData.roleName
                      }
                      onChange={handleroleEdit}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonGroup
        groupNames={["Save", "Cancel"]}
        submit={(e) => handleProceed(e)}
        reset={() => cancelHandler()}
      />
      <ConfirmDialog
        title={"Do you want to continue?"}
        verify={() => onSave()}
        cancel={() => close()}
        open={openconfirmModal}
        close={() => setOpenConfirmModal(false)}
        key={"editUserDetais"}
        width={"sm"}
      />
    </React.Fragment>
  );
};

export default Edit;
