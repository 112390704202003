import React from "react";
import { connect } from "react-redux";
import {
  formatMoney,
  getBrowserDate,
  getDateTimeFormate,
  divideAmountBy100
} from "../../sharedfiles/helper";
import { getEventDetails } from "./paymentHistoryTableSlice";
import Table from "../Common/table/Table";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import { COMMON_CONSTANTS, KYC_CONSTANTS, PAYMENT_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { COMMON_MSGS, Payments, paymentHistoryInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import { viewLogInfo } from "../Common/data/Payments/PaymentsearchData";
import KycCommentBox from "../KycUsers/KycCommentBox";
import { cancelWithdrawal, getPaymentSearchtableList } from "../PaymentSearch/paymentSearchSlice";
class PaymentHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // recordDetails: {},
      asceending: true,
      headers: [
        "Transaction ID",
        "Payment Description",
        "Event Type",
        "Reference",
        "Created",
        "Payment Methods",
        "Payment Type",
        "Request/Response"
      ],
      dialogData: [],
      openDetails: false,
      declineBox: false,
      txnId: ''
    };
    this.dateSorting = this.dateSorting.bind(this);
    this.callEventDetails = this.callEventDetails.bind(this);
  }

  dateSorting() {
    const getchedData = [...this.props.data];
    const sortData = getchedData.sort((a, b) => {
      const c = new Date(a.transaction_date);
      const d = new Date(b.transaction_date);
      return c - d;
    });
    return this.state.asceending ? sortData : sortData.reverse();
  }
  onDialogClose = () => {
    // this.props.dispatch(setSnackbar(false));
    this.setState({ openDetails: false });
    if (
      this.props.playerDetails !== null &&
      this.props.playerDetails !== undefined &&
      Object.keys(this.props.playerDetails).length > 0
    ) {
      this.setState({ dialogData: [] });
    }
  };
  callEventDetails = (id) => {
    if (id !== 0) {
      //  this.props.dispatch(setSnackbar(true));
      this.props.dispatch(getEventDetails(id));
      this.setState({ openDetails: true });
    }
  };

  updateDialogData() {
    const headers = this.state.headers;
    const data =
      this.props.playerDetails !== null &&
        this.props.playerDetails !== undefined &&
        this.props.playerDetails !== "" &&
        Object.keys(this.props.playerDetails).length > 0 &&
        this.props.playerDetails.length > 0
        ? this.props.playerDetails.map((item, ind) => [
          item.transaction_id,
          item.description,
          item.eventType,
          item.reference,
          getBrowserDate(item.createdAt),
          item.paymentMethod,
          item.paymentMethodType,
          item.data
        ])
        : [];
    const tableData = data.length > 0 ? [headers, viewLogInfo, ...data] : [headers, viewLogInfo, []];
    this.setState({ dialogData: tableData });
  }

  componentDidUpdate(prevProps) {
    if (this.props.playerDetails !== prevProps.playerDetails) {
      this.updateDialogData();
    }
  }

  onCancel(id) {
    this.setState({ declineBox: true })
    this.setState({ txnId: id })
  }
  onCloseCancelPopup() {
    this.setState({ declineBox: false })
  }

  handleReject(msg) {
    const data = {
      comments: msg
    }
    this.props.dispatch(cancelWithdrawal(this.state.txnId, data))
    this.props.dispatch(getPaymentSearchtableList(0, 25, { userId: localStorage.getItem("userId") }))
  }


  render() {
    return (
      <>
        {this.state.openDetails && (
          <Dialog__Main
            title={"Event Detail"}
            dOpen={this.state.openDetails}
            dClose={() => this.onDialogClose()}
            width={"xl"}
          >
            <Table tableData={this.state.dialogData} hLen={4} vLen={2} />
          </Dialog__Main>
        )}
        <Dialog__Main title={"Action Needed"} dOpen={this.state.declineBox} dClose={() => this.onCloseCancelPopup()}>
          <KycCommentBox
            transactionId={this.state.txnId}
            finish={(msg) => this.handleReject(msg)}
            close={() => this.onCloseCancelPopup()}
            cancelBtn
          />
        </Dialog__Main>
        <div className='CMS-box '>
          <div className='CMS-table CMS-table-triped'>
            <table>
              <thead>
                <tr>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionId}</p>
                      <Tooltips info={paymentHistoryInfo.txId} />
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      this.setState({ asceending: !this.state.asceending });
                    }}
                  >
                    <div className='d-flex justify-content-center align-items-center'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-3'>{COMMON_CONSTANTS.createdOn}{" "}</p>
                        <Tooltips info={COMMON_MSGS.createdDate} />
                        <span
                          className='material-icons md-18'
                          data-icon={
                            this.state.asceending === true ? "south" : "north"
                          }
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      this.setState({ asceending: !this.state.asceending });
                    }}
                  >
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.updatedOn}{" "}</p>
                      <Tooltips info={COMMON_MSGS.updatedDate} />
                      <span
                        className='material-icons md-18'
                        data-icon={
                          this.state.asceending === true ? "south" : "north"
                        }
                      ></span>
                    </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.createdBy}</p>
                      <Tooltips info={COMMON_MSGS.createdBy} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.updatedBy}</p>
                      <Tooltips info={COMMON_MSGS.updatedBy} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{COMMON_CONSTANTS.th_amount}</p>
                      <Tooltips info={COMMON_MSGS.Amount} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{KYC_CONSTANTS.header_status}</p>
                      <Tooltips info={Payments.Status} />
                    </div>
                  </th>
                  {this.props.tdsExist ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.tds_amount}</p>
                      <Tooltips info={paymentHistoryInfo.tdsAmount} />
                    </div></th> : ""}
                  {this.props.tdsExist ? <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.tds_percentage}</p>
                      <Tooltips info={paymentHistoryInfo.tdspercent} />
                    </div></th> : ""}
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.th_method}</p>
                      <Tooltips info={paymentHistoryInfo.method} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentType}</p>
                      <Tooltips info={paymentHistoryInfo.paymentType} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentMethod}</p>
                      <Tooltips info={paymentHistoryInfo.method} />
                    </div></th>
                  <th> <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{PAYMENT_CONSTANTS.th_paymentDesc}</p>
                    <Tooltips info={paymentHistoryInfo.paymentDesc} />
                  </div>
                  </th>
                  <th> <div className='d-flex justify-content-center align-items-center'>
                    <p className='mx-3'>{PAYMENT_CONSTANTS.th_bankName}</p>
                    <Tooltips info={paymentHistoryInfo.bankName} />
                  </div>
                  </th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{PAYMENT_CONSTANTS.th_transactionRef}</p>
                      <Tooltips info={paymentHistoryInfo.transRef} />
                    </div></th>
                  <th>
                    <div className='d-flex justify-content-center align-items-center'>
                      <p className='mx-3'>{KYC_CONSTANTS.header_action}</p>
                      <Tooltips info={paymentHistoryInfo.action} />
                    </div></th>
                </tr>
              </thead>

              <tbody>
                {this.props.data && Array.isArray(this.props.data) && this.props.data.length > 0 ? (
                  this.dateSorting().map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{getDateTimeFormate(item.createdDate)}</td>
                        <td>{getDateTimeFormate(item.updatedDate)}</td>
                        <td>{item.addedBy}</td>
                        <td>{item.updatedByUserName || '-'}</td>
                        <td className='text-right'>
                          {formatMoney(item.amount ? item.amount : "0")}
                        </td>
                        <td>{item.status}</td>
                        {this.props.tdsExist ? <td>{item.tdsAmount ? formatMoney(item.tdsAmount) : "0"}</td> : ""}
                        {this.props.tdsExist ? <td> {item.tdsPercentage}</td> : ""}
                        <td>{item.paymentMethodType}</td>
                        <td>{item.paymentType}</td>
                        <td>{item.modePaymentClient}</td>
                        <td>{divideAmountBy100(item.paymentDesc)}</td>
                        <td>{item.bankName}</td>
                        <td>{item.transactionRef}</td>
                        <td>
                          <button
                            className='CMS-btn CMS-btnSecondary active CMS-btnSmall btnShow '
                            type='button'
                            id='paymentDetails'
                            onClick={() => this.callEventDetails(item.id)}
                          >
                            {COMMON_CONSTANTS.details}
                          </button>
                          <div className='mt-10'></div>

                          <button
                            className={`${item.paymentType === "WITHDRAW" && item.status === "INIT" ||
                              item.status === "APPROVED" ?
                              'CMS-btn CMS-btnSecondary active CMS-btnSmall btnShow' : 'disable-button'} 
                              CMS-btn CMS-btnSecondary active CMS-btnSmall btnShow`}
                            type='button'
                            id='paymentDetails'
                            onClick={() => this.onCancel(item.id)}
                          >
                            {COMMON_CONSTANTS.cancel}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <No__Data__Found colspan={22} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    paymentData: state.paymentHistorySlice.paymentData,
    paginationFirstValue: state.paymentHistorySlice.paginationFirstValue,
    paginationSecondValue: state.paymentHistorySlice.paginationSecondValue,
    manauldata: state.paymentHistorySlice.manauldata,
    playerDetails: state.paymentHistorySlice.playerDetails,
  };
}
export default connect(mapStateToProps)(PaymentHistoryTable);
