import React from "react";
import Skelton from "../skelton/Skelton";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import No__Data__Found from "./No__Data__Found";
import Timestampmsg from "../../Dashboard/Timestampmsg"
import { tabManager } from "../../sidebar/sidebarSlice";
import { useDispatch } from "react-redux";
const ReportsTable = (props) => {
  const dispatch = useDispatch();
  const tabStack = useSelector(state => state.sidebar.tabStack);
  const tabsChange = (item) => {
    dispatch(tabManager(+item, tabStack));
    localStorage.setItem("userId", item);
  };

  return (
    <>
      {!props.viewTable && <Skelton />}

      {props.viewTable &&
        props?.tableData !== undefined &&
        props?.tableData !== null &&
        props?.tableData?.length > 0 &&
        props?.tableData.map((currentTable, ind) => {
          return (
            <React.Fragment key={currentTable.subtitle}>
              <div className='CMS-box CMS-box-content'>
                <div className='CMS-box__header'>
                  <div
                    className='CMS-box__title'
                    style={{ textAlign: "center" }}
                  >
                    {currentTable.subtitle}
                  </div>
                </div>
                <div className='row no-gutters g-0'>
                  <div className='col-md-12'>
                    <div className='CMS-box'>
                      <div className='CMS-table CMS-table-triped'>
                        {currentTable !== null && currentTable !== undefined && (
                          <table>
                            <thead>
                              <tr>
                                {currentTable?.headers[0]?.map((item, ind) => (
                                  <th
                                    key={ind}
                                    style={{ whiteSpace: "nowrap" }}
                                    className='text-center'
                                  >
                                    {item}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {currentTable &&
                                currentTable?.body !== null &&
                                currentTable?.body !== undefined &&
                                Array.isArray(currentTable?.body) &&
                                currentTable?.body?.length > 0 ? (
                                currentTable?.body?.map((item, ind) => {
                                  return (
                                    <tr key={ind}>
                                      {item?.map((item, ind) => {
                                        return (
                                          <td
                                            className={
                                              currentTable?.alignments?.includes(
                                                `${ind}`
                                              )
                                                ? "text-right"
                                                : "text-center"
                                            }
                                            key={ind}
                                          >
                                            {
                                              currentTable.linkIndex === ind ?
                                                <Link
                                                  to={`/customerid/${item}`}
                                                  onClick={() => tabsChange(item)}
                                                  className={
                                                    currentTable?.alignments?.includes(
                                                      `${ind}`
                                                    )
                                                      ? "text-right"
                                                      : "text-center"
                                                  }
                                                  key={ind}
                                                >
                                                  {item}
                                                </Link>
                                                :
                                                item
                                            }
                                          </td>
                                        )
                                      })}
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className='CMS-msgBox'>
                                  <td colSpan={100} className='CMS-msgBox-container text-center'>No Data Found</td>
                                </tr>
                              )}

                              {props.tableData?.length - 1 === ind &&
                                props?.tableData !== undefined &&
                                props?.tableData !== null &&
                                Array.isArray(props?.tableData) &&
                                props?.tableData?.length > 0 &&
                                currentTable?.body?.length > 0 &&
                                currentTable?.body?.slice(
                                  0,
                                  currentTable?.body?.length
                                )[0] !== undefined &&
                                currentTable?.body.slice(
                                  0,
                                  currentTable?.body?.length
                                )[0] !== null &&
                                currentTable?.body.slice(
                                  0,
                                  currentTable?.body?.length
                                )[0].length > 0 && (
                                  <tr>
                                    <td
                                      colSpan={
                                        props.tableData?.length - 1 === ind &&
                                        currentTable?.headers[0]?.length
                                      }
                                    >
                                      <div
                                        className='CMS-filter-result'
                                        id='result'
                                      >
                                        <div className='CMS-pagination'>
                                          <div className='CMS-pagination-container d-flex justify-content-center'>
                                            <div className='CMS-page-slection '>
                                              <div className='CMS-file-type CMS-select select-width'>
                                                <select
                                                  id='country'
                                                  name='File'
                                                  onChange={(event) =>
                                                    props?.setFileType(
                                                      event.target.value
                                                    )
                                                  }
                                                  className="pointer"
                                                >
                                                  <option value='PDF'>
                                                    PDF
                                                  </option>
                                                  <option value='CSV'>
                                                    CSV
                                                  </option>
                                                </select>
                                              </div>
                                              <div className='CMS-download-icon'>
                                                {props?.fileType === "CSV" ? (
                                                  <CSVLink
                                                    style={{
                                                      color: "#fff",
                                                    }}
                                                    data={props?.csvData}
                                                    filename={`${props?.fileName}.csv`}
                                                  >
                                                    <span
                                                      className='material-icons'
                                                      data-icon='file_download'
                                                    ></span>
                                                  </CSVLink>
                                                ) : (
                                                  <Link
                                                    to={{}}
                                                    onClick={() =>
                                                      props?.printPdf()
                                                    }
                                                    style={{
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    <span
                                                      className='material-icons'
                                                      data-icon='file_download'
                                                    ></span>
                                                  </Link>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Timestampmsg />
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-20'></div>
            </React.Fragment>
          );
        })}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    viewTable: state.reports.viewTable,
  };
};
export default connect(mapStateToProps)(ReportsTable);
