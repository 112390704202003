import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  AddClient,
  getClients,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
} from "./createclientslice";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Table from "../Common/table/Table";
import { BRAND_CONFIG_CONSTANTS, COMMON_CONSTANTS } from "../../sharedfiles/Constants";
import Tooltips from "../Common/Tooltip";
import { BrandConfig } from "../../sharedfiles/tooltipMsgs";
const CreateClient = (props) => {
  const [clientName, setClientName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const dispatch = useDispatch();
  const pageFirstVal = useSelector(
    (state) => state.createclient.paginationFirstValue
  );
  const pageSecondVal = useSelector(
    (state) => state.createclient.paginationSecondValue
  );
  const recordsToShow = useSelector((state) => state.createclient.recordsShow);
  const listClient = useSelector((state) => state.createclient.clientList);

  const handleChangeClientName = (e) => {
    if (e.target.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
    setClientName(e.target.value);
  };

  useEffect(() => {
    const dataExist = Object.keys(props.clientList).length > 0
    if (!dataExist) {
      props.dispatch(
        getClients(props.paginationFirstValue - 1, props.paginationSecondValue)
      );
    }

  }, []);

  const submitClient = () => {
    if (clientName != "") {
      const clientData = {
        clientName: clientName,
      };
      props.dispatch(AddClient(clientData));
      setTimeout(() => {
        props.dispatch(
          getClients(
            props.paginationFirstValue - 1,
            props.paginationSecondValue
          )
        );
      }, 500);
    }
    if (clientName === "") {
      setNameError(true);
    }
  };

  const headers = [["Client Name", "Client ID", "Created On", "Updated On", "Created By", "Updated By"]];
  const Info = ["Showing Client Name", "Showing Client ID", "Showing Created On", "Showing Updated On", "Showing Created By", "Showing Updated By"]
  const exportPDF = () => {
    const title = "Create Client Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (Object.keys(props.clientList).length > 0) {
      const data =
        props.clientList &&
        props.clientList.data.map((p) => [
          p.name,
          p.id,
          p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
          p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
          p.addedBy,
          p.updatedBy,
        ]);

      setPdfBody(data);
      setCsvData([headers[0], Info, ...data]);
    }
  }, [props.clientList]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(getClients(pageNumber - 1, itemsPerPage));
  };
  return (
    <>
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-12'>
                <div className='CMS-cardBox mb-0'>
                  <div className='row'>
                    <div className='col-lg-2 col-xl-2'>
                      <div className='CMS-formGroup'>
                        <div className='CMS-formLabel'>
                          {BRAND_CONFIG_CONSTANTS.header_clientName}
                          &nbsp;&nbsp;
                          <Tooltips info={BrandConfig.Client_Name} />
                        </div>
                        <div className='CMS-formControl'>
                          <input
                            type='text'
                            name='clientName'
                            id='clientname'
                            value={clientName}
                            onChange={handleChangeClientName}
                          />

                          {nameError && (
                            <div className='text-danger'>
                              {BRAND_CONFIG_CONSTANTS.createClient_ErrMsg}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-20'></div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 col-xl-12'>
                <div className='CMS-btnContainer'>
                  <button
                    id='btnBonusCreate'
                    style={{ width: "200px" }}
                    className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                    type='button'
                    onClick={submitClient}
                  >
                    {COMMON_CONSTANTS.create}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={listClient?.data}
                totalRecords={listClient?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={recordsToShow}
                setRecordsShow={setRecordsShow}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Create Client Report"}
              />
            </div>
            <Table tableData={csvData} />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    clientList: state.createclient.clientList,
    paginationFirstValue: state.createclient.paginationFirstValue,
    paginationSecondValue: state.createclient.paginationSecondValue,
    isVisible: state.createclient.isVisible,
  };
};
export default connect(mapStateToProps)(CreateClient);
